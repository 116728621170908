
import Colors from "../../../utils/Colors";
import imageToStyle from "../../../utils/ImageToolkit";
import UiUtil from "../../../utils/UiUtil";
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";

/**
 * ProjectName: MindMap
 * Created by tony on 2/13/21
 * Copyright(c) 2020 mindyushu.com
 */

class MindAddDataView {
    constructor(fatherDom, size, delegate, direction = FlowChartDirection.RIGHT) {
        this.lineHeight = new UiUtil().dip2px(2);
        this.lineView;
        this.line;
        this.size = size;
        this.fatherDom = fatherDom;
        this.delegate = delegate;
        this.mousdownFunction;
        this.selectId;
        this.isMoveDom = false;
        this.timeInterval;
        this.left = 0;
        this.top = 0;
        this.direction = direction;
        this.data = null;
        this.isMousdown = false
        this.initView();
    }

    initView() {
        this.lineView = document.createElement("div");
        this.lineView.style.position = 'absolute';
        this.lineView.style.zIndex = 31;
        this.fatherDom.appendChild(this.lineView);
        this.lineView.style.width = this.size.width + 'px';
        this.lineView.style.height = this.size.height + 'px';
        this.lineView.style.border = '0px'
        this.lineView.style.backgroundColor = Colors.getUiColor(Colors.white);
        this.lineView.style.borderRadius = '50%';
        this.lineView.style.cursor = 'pointer';
        this.lineView.style.userSelect = "none"
        this.lineView.style.overflow = "hidden"

        this.line = document.createElement("div");
        this.lineView.appendChild(this.line);
        this.line.style.position = 'absolute';
        this.line.style.left = "50%";
        this.line.style.top = "50%";
        this.line.style.transform = "translate(-50%,-50%)";
        this.line.style.width = '100%';
        this.line.style.height = '100%';
        this.line.style.lineHeight = this.size.height + 'px';
        this.line.style.textAlign = 'center';
        this.line.style.color = Colors.getUiColor(Colors.black50); 
        this.line.classList.add("iconfont-add");
        this.lineView.style.display = 'none';
        this.line.innerHTML = "&#xe606;";

        this.line.style.fontSize = '18px';
        this.line.style.fontWeight = 700;
        this.line.style.textAlign = 'center'
        this.line.style.lineHeight = this.size.height + 'px';

        this.bundling();
    }

    bundling() {        
        this.mousdownFunction = (ev) => {
            this.isMousdown = true
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.delegate.mouseDownAddNodeNode(this.data, new CGPoint(ev.clientX, ev.clientY));
            // this.hideDom()
        }

        this.mousupFunction = (ev) => {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            if (this.isMousdown) {
                let addData = { type: 'smallIcon' }            
                addData.direction = this.direction;
                this.delegate.onSelected(this.data, new CGPoint(-1, -1), addData);
            }
            this.isMousdown = false
            // this.hideDom()
        }

        this.lineView.addEventListener('mousedown', this.mousdownFunction)
        this.lineView.addEventListener('mouseup', this.mousupFunction)
    }

    setColor(bg, line) {
        if (Colors.getUiColor(bg) == Colors.getUiColor(line)) {
            if (Colors.isDarkColor(bg)) {
                line = Colors.white;
            } else {
                line = Colors.black80;
            }
        }
        this.lineView.style.border = '0px'; 
        this.lineView.style.backgroundColor = Colors.getUiColor(bg);
        this.line.style.color = Colors.getUiColor(line);
    }

    setPosition(obj) {
        if(obj==undefined) {
            return;
        }
        clearInterval(this.timeInterval);
        this.lineView.style.display = 'block';
        this.lineView.style.left = obj.left + 'px';
        this.lineView.style.top = obj.top + 'px';
        this.left = obj.left;
        this.top = obj.top;
    }

    getRect() {
        if (this.lineView == null || this.lineView.style.display != 'block') {
            return new CGRect(0, 0, 0, 0)
        }
        return new CGRect(this.left, this.top, this.size.width, this.size.height)
    }

    checkId(id, data) {
        this.selectId = id;
        this.data = data;
    }

    hideDom() {
        this.lineView.style.display = 'none';
    }

    movePoint(data, isDelay = true) {
        let that = this;
        clearInterval(this.timeInterval);
        if (isDelay) {
            this.timeInterval = setInterval(() => {
                that.hideDom()
                clearInterval(this.timeInterval);
            },3000)
        } else {
            that.hideDom()
        }
    }
}

export default MindAddDataView