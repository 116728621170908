import HttpServerConfig from "../../../common/HttpServerConfig";
import tools from "../../../common/commonFuntion";
import Util from "../../../utils/Util";
import LoadLocalXJMind from "../../../viewmodel/core/tools/mapfile/LoadLocalXJMind";
import MindMapZipConstant from "../../../viewmodel/core/tools/mapfile/MindMapZipConstant";
import UserFont from "../dataformat/font/UserFont";
import Config from "./Config";

class FontLoad {
    constructor() {
        this.userFontFileName = "content.json"
        this.userFont = new UserFont("")  
        this.isLoadUserFont = false
        this.waitingLoadNames = new Array()
        this.loadedToAppFonts = new Array()
    }

    loadUserContent() {
        let that = this;
        return new Promise(async resolve => {            
            this.createFolder(this.getLoaclFontRootPath())
            let contentPath = this.getLoaclFontPath(this.userFontFileName)
            if (this.isExistsSync(contentPath)) {
                this.loadJson(contentPath).then(function(content) {
                    if (content == null || content.length == 0) {
                        resolve(true)
                    } else {
                        let fonts = new UserFont("")
                        fonts.format(content)
                        that.userFont = fonts
                        that.isLoadUserFont = true
                        resolve(true)
                        if (that.waitingLoadNames.length > 0) {
                            that.load(that.waitingLoadNames)
                        }
                    }
                })
            } else {
                resolve(true)
            }
        })
    }

    load(names) {
        let that = this;
        return new Promise(async resolve => {
            if (names == null || names.length == 0) {
                resolve(false)
            }
            for (let index = 0; index < names.length; index++) {
                const name = names[index];
                var fileName = "";
                var fontName = "";
                
                for (let j = 0; j < Config.FontFamilys.length; j++) {
                    const cell = Config.FontFamilys[j];
                    if (cell.name == name) {
                        fileName = cell.file;
                        fontName = cell.class;
                        break;
                    }
                }
                if (fileName == "" && fontName == "") {
                    if (!this.isLoadUserFont) {
                        this.waitingLoadNames.push(name)
                    }
                    for (let j = 0; j < that.userFont.list.length; j++) {
                        const cell = that.userFont.list[j];
                        if (cell.name == name) {
                            fileName = cell.file;
                            fontName = cell.class;
                            break;
                        }
                    }
                }
                if (fileName != null && fontName != null && 
                    fileName.length > 0 && fontName.length > 0) {
                    if (this.loadedToAppFonts.indexOf(name) == -1) {
                        this.loadedToAppFonts.push(name)
                        let loaded = await that.exe(fontName, fileName)
                        if (!loaded) {
                            console.log("load font error [" + fontName + "]");
                        }
                    }
                }
            }
            resolve(true)
        })
    }

    exe(fontName, fileName) {
        return new Promise(resolve => {
            try {
                let url = null
                if (fileName.startsWith("http:") || fileName.startsWith("https:")) {
                    url = fileName
                } else {
                    if (tools.isElectron()) {
                        // url = require("../../../assets/font/" + fileName)
                        url = this.getLoaclFontPath(fileName)
                        
                        if (!this.isExistsSync(url)) {
                            url = "https://r.mindyushu.com/r/mindyushu.oss-cn-shanghai.aliyuncs.com/mindmap/attachment/font/" + fileName;
                            this.loadResourceData(url, fileName).then(function(loadFace) {
                            });
                        } else {
                            url = HttpServerConfig.getLocalPathPrefix(url , "")  

                            this.loadResourceDataFormatBuf(url, fileName).then(function(data) {
                                if (data) {
                                    let myFonts = new FontFace(fontName, data);
                                    // console.log("myFonts--", myFonts, data);
                                    myFonts.load().then(function(loadFace) {
                                        document.fonts.add(loadFace);
                                        resolve(true)
                                    });
                                } else {
                                    resolve(false)
                                }
                            })  
                            url = null          
                        }
                    } else {
                        url = "https://r.mindyushu.com/r/mindyushu.oss-cn-shanghai.aliyuncs.com/mindmap/attachment/font/" + fileName;
                        // url = require("../../../assets/font/" + fileName)
                    }
                }
                if (url != null) {
                    // console.log("url - ", url, encodeURIComponent(url));
                    // let type = Util.getUrlType(url)
                    let myFonts = new FontFace(fontName, 'url(' + url + ')');
                    myFonts.load().then(function(loadFace) {
                        document.fonts.add(loadFace);
                        resolve(true)
                    });
                }                
            } catch (error) {
                console.log(error);
                resolve(false)
            }
        });
    }

    loadResourceData(url, name, isSave = true) {
        var that = this
        return new Promise(async resolve => {
            let blob = await LoadLocalXJMind.loadResourceData(url);
            if (blob == null) {
                resolve(false);
                return
            }
            let buf = await that.loadFileArrayBuffer(blob, name);
            
            if (buf != null) {
                if (isSave) {
                    that.saveLocalContent(name, buf)
                }
                resolve(buf);
            } else {
                resolve(false);
            }            
        });
    }

    loadResourceDataFormatBuf(url, name) {
        var that = this
        return new Promise(async resolve => {            
            if (!url.startsWith("xjmm")) {
                url = HttpServerConfig.getLocalPathPrefix(url , "")//"xjmm://" + url
            }
            let blob = await LoadLocalXJMind.asyncLoadLocalFile(url);
            if (blob == null) {
                resolve(false);
                return
            }
            let buf = await that.loadFileArrayBuffer(blob, name);
            if (buf != null) {
                resolve(buf);
            } else {
                resolve(false);
            }            
        });
    }

    getLoaclFontPath(name) {
        if (name == null || name.length == 0) {
            return ""
        } else {
           return this.getLoaclFontRootPath() + MindMapZipConstant.FileSeparator + name 
        }
    }

    getLoaclFontRootPath() {
        return LoadLocalXJMind.appPath + MindMapZipConstant.FileSeparator + "Mind+" + 
                    MindMapZipConstant.FileSeparator + "font";
    }

    saveLocalContent(name, content) {
        let that = this;
        let path = this.getLoaclFontPath(name)
        return new Promise(resolve => {
            if (path == null) {
                resolve(false)
                return 
            }
            this.createFolder(this.getLoaclFontRootPath())
            var fs = window.require('fs');
            fs.writeFile(path, content, function (err) {
                if (err != null) {
                    resolve(false)
                } else {
                    resolve(true)
                }
            });
        })
    }

    saveUserLocalFont(name, content) {
        return new Promise(resolve => {
            if (name == null || name.length == 0 || name.indexOf(".") == -1 || content == null) {
                resolve(null)
                return
            }
            let tath = this
            this.loadFileArrayBuffer(content, name).then(function(data) {
                if (data != null) {
                    tath.saveLocalContent(name, data).then(function(success) {
                        if (success) {
                            let fontName = Util.getNameByUrl(tath.getLoaclFontPath(name))
                            if (fontName == "") {
                                fontName = name
                            }
                            let className = fontName
                            let cell = tath.userFont.push(fontName, className, name)
                            tath.saveMyFonts()
                            resolve(cell)
                        } else {
                            resolve(null)
                        }                
                    })
                } else {
                    resolve(null)
                }
            })
            
        })
    }

    saveMyFonts() {
        return new Promise(resolve => {
            if (this.userFont == null || this.userFont.list.length == 0) {
                resolve(true)
            }
            let content = this.userFont.toJSONString()
            if (content != null && content.length > 0) {
                this.saveLocalContent(this.userFontFileName, content)
            }
        })
    }

    createFolder(dirpath, dirname) {
        var fs = window.require('fs');
        var path = window.require('path');
        if (typeof dirname === "undefined") {
          if (fs.existsSync(dirpath)) {
          } else {
            this.createFolder(dirpath, path.dirname(dirpath));
          }
        } else {
          if (dirname !== path.dirname(dirpath)) {
            this.createFolder(dirpath);
            return;
          }
          if (fs.existsSync(dirname)) {
            fs.mkdirSync(dirpath,{recursive:true})
          } else {
            this.createFolder(dirname, path.dirname(dirname));
            fs.mkdirSync(dirpath,{recursive:true});
          }
        }
    }

    isExistsSync(localPath) {
        var fs = window.require('fs');
        return fs.existsSync(localPath)
    }

    loadFileArrayBuffer(blob, name) {
        let that = this
        return new Promise(async resolve => {
            if (blob == null) {
                resolve(null)
                return
            }
            var fr = new FileReader();
            fr.readAsArrayBuffer(blob);
            fr.addEventListener("loadend",(e) => {
                var buf = e.target.result;
                if (buf != null && buf.byteLength > 10) {
                    try {
                        let data = null
                        if (name.indexOf(".txt") > 0 ||
                            name.indexOf(".doc") > 0 ||
                            name.indexOf(".xls") > 0) {
                            data = new Uint8Array(buf)
                        } else {
                            data = new Int8Array(buf)
                        }
                        resolve(data)
                    } catch (error) {
                        resolve(null)
                    }
                } else {
                    resolve(null)
                }
            },false);
        })
    }


    loadJson(jsonpath) {
        let that = this
        return new Promise(async resolve => {
            try {
                if (!jsonpath.startsWith("xjmm")) {
                    jsonpath = HttpServerConfig.getLocalPathPrefix(jsonpath , "")//  "xjmm://" + jsonpath
                }
                const xhr = new XMLHttpRequest();
                xhr.open('GET', jsonpath, true);
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        var fr = new FileReader();
                        fr.readAsText(xhr.response, "utf-8");
                        fr.addEventListener("loadend",(e) => {
                            var json = e.target.result;
                            resolve(json)
                        },false);
                    } else {
                        console.log("xhr.response xhr.status - ", xhr.status);
                        resolve("")
                    }
                };
                xhr.onerror = (error) => {
                    console.log("xhr.response error - ", error);
                    resolve("")
                };
                xhr.send();
            } catch (error) {
                console.log("loadJson error: ", error);
                resolve("")
            }
            
        });
    }
}

export default new FontLoad()