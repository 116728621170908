<template>
<div class="help-contact-us" v-if="contactShow" @click="showContactModal(false)">
    <div class="Help-box">
      <div class="Help-content">
        <div class="Help-seach">
          <div class="hblang">
            <div class="contact hb">
              <h3>{{getString(Strings.Setting_Up)}}</h3>
              <ul>
                <li class="con-li">
                  <p class="conimg con-emailimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/emails947.png"
                      alt=""
                    />
                  </p>
                  <p class="conzi">
                    yushu_mind@sina.com
                    <br />
                    yushu_mind_support@yeah.net
                  </p>
                </li>
                <li class="con-li">
                  <p class="conimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/weibos948.png"
                      alt=""
                    />
                  </p>
                  <p class="conzi"><a href="https://weibo.com/u/7264879276" target="_self" style="color:;">https://weibo.com/u/7264879276</a></p>
                </li>
                <li class="con-li">
                  <p class="conimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/QQ1381.png"
                      alt=""
                    />
                  </p>
                  <p class="conzi">QQ：&nbsp;2677633026</p>
                </li>
                <div class="con-div">
                  <div class="condimg">
                    <p class="offi-cial conzi">{{getString(Strings.Contact_Us_No_Public)}}:</p>
                    <img
                      src="../../assets/img/user/Iitem0selected/bjnews.png"
                      alt=""
                    />
                  </div>
                  <div class="condimg">
                    <p class="offi-cial conzi">{{getString(Strings.Contact_Us_QQ_Group)}}:</p>
                    <img
                      src="../../assets/img/user/Iitem0selected/c8219403ab24c589d4b8587981ef58c.png"
                      alt=""
                    />
                  </div>
                </div>
              </ul>
            </div>
            <div class="message-board hb">
              <h3>{{getString(Strings.Contact_Us_Message_Board)}}</h3>
              <div>
                <a-form :form="sendLeaveNote" @submit="handleSendLeaveNote">
                  <div class="shuang em">
                    <a-input-group size="large">
                      <a-col :span="12">
                        <a-form-item>
                          <a-input
                            ref="userNameInput"
                            :placeholder="getString(Strings.Contact_Us_Username)"
                            class="sleft"
                            v-decorator="[
                              'name',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: getString(Strings.Input_Username_isNull),
                                  },
                                ],
                              },
                            ]"
                          >
                            <span
                              class="iconfont2 iconfont-dark-color"
                              slot="prefix"
                              >&#xe605;</span
                            >
                          </a-input>
                        </a-form-item>
                      </a-col>
                      <a-col :span="12" class="sright">
                        <a-form-item>
                          <a-input
                            ref="userEmailInput"
                            :placeholder="getString(Strings.Contact_Us_Email)"
                            v-decorator="[
                              'email',
                              {
                                rules: [
                                  {
                                    type: 'email',
                                    required: true,
                                    message: getString(Strings.Input_Email_isNull),
                                  },
                                ],
                              },
                            ]"
                          >
                            <span
                              class="iconfont2 iconfont-dark-color"
                              slot="prefix"
                              >&#xe62e;</span
                            >
                          </a-input>
                        </a-form-item>
                      </a-col>
                    </a-input-group>
                  </div>
                  <div class="guestbook em">
                    <span class="iconfont2 iconfont-dark-color" slot="prefix"
                      >&#xe657;</span
                    >
                    <a-form-item>
                      <a-textarea
                        :placeholder="getString(Strings.Contact_Us_Voicemail)"
                        :rows="12"
                        style="resize: none;text-indent:30px;"
                        v-decorator="[
                          'send',
                          {
                            rules: [
                              {
                                required: true,
                                message: getString(Strings.Input_Voicemail_isNull),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>

                  <a-form-item>
                    <div class="send em">
                      <a-button html-type="submit">{{getString(Strings.Contact_Us_Send_Email)}}</a-button>
                    </div>
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <div @click="showContactModal(false)" class="big-help-box-center-heade-back">
              <a-icon type="close" />
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import unifildStyle from "../../assets/css/help/unifildStyle.less";

import { postBaseSendLeaveNote } from "../../common/netWork/base_api";
import PageCheckBox from "../common/PageCheckBox.vue";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
export default {
  components: {
    PageCheckBox,
  },
  props: [
    "contactShow"
  ],
  data() {
    return {
      sendLeaveNote: this.$form.createForm(this),
      Strings:Strings
    };
  },
  created() {},
  mounted() {},
  methods: {
    showContactModal(val) {
            // if( !val ) {
                // let req = {}
                // req.show = val;
                // req.isVersion = isVersion;
                this.$emit('showContactModal',val);
            // } 
    },
    getString(i){
     return getString(i)
    },
    //提交内容
    handleSendLeaveNote(e) {
      e.preventDefault();
      this.sendLeaveNote.validateFields(
        ["name", "email", "send"],
        { first: true },
        (err, values) => {
          if (!err) {
            postBaseSendLeaveNote(
              {
                username: values.name,
                email: values.email,
                content: values.send,
              },
              (res) => {
                this.$message.success(getString(Strings.Message_Tips_Submit_Success));
                this.sendLeaveNote.setFieldsValue({
                  name: "",
                  email: "",
                  send: "",
                });
              },
              (error) => {
                this.$message.success(error.desc);
              }
            );
          }
        }
      );
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
.help-contact-us {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.1);
  user-select: none;
}
ul li {
  list-style: none;
}
.Help-box{
  width: 967px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Help-bottom .hbb-ul {
  padding: 1.5em 3em;
  margin-bottom: unset;
}
.Help-bottom .hb-li {
  display: flex;
}
.Help-bottom .hb-li img {
  width: 26px;
}
.Help-seach {
  background: #ffffff;
  margin-top: 3em;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
}
.Help-seach .hblang {
  width: 100%;
  height: 100%;
  padding: 2em 5em;
  display: flex;
}
.Help-seach .hblang .hb {
  width: 50%;
}
.big-help-box-center-heade-back {
  position: absolute;
  right: 20px;
  transform: translate(0, -50%);
  cursor: pointer;
}
.Help-seach .hblang p {
  margin-bottom: unset;
}
.hblang h3 {
  font-size: 18px;
  color: #666666;
  margin-bottom: 1.5em;
}
.contact ul {
  padding-left: unset;
  width: 95%;
}
.contact h2 {
  color: #666;
}
.contact .con-li {
  display: flex;
  margin-bottom: 2em;
      align-items: center;
}
.contact .conimg {
  width: 11%;
}
.contact .con-emailimg {
  line-height: 58px;
}
.contact .conzi {
  padding-left: 10px;
  line-height: 35px;
  font-size: 16px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #666;
  opacity: 1;user-select: text;
}
.contact .conzi a{
  color: #666;
}
.contact .con-div {
  display: flex;
}
.contact .con-div .condimg {
  margin-left: 11%;
  display: flex;
}
.contact .con-div .condimg .offi-cial {
  line-height: unset;
  margin-right: 1em;
}
.em {
  margin-bottom: 2em;
}
.shuang .sright {
  float: right;
}

.shuang .sleft input {
  height: 52px;
  border: 1px solid #d9d9d9;
  opacity: 1;
  border-radius: 4px;
}
.guestbook span {
  z-index: 5;
  padding-left: 20px;
  padding-top: 5px;
  position: absolute;
}
.ant-input:placeholder-shown {
  z-index: 2;
}
.send button {
  width: 49%;
  height: 48px;
  background: #fd492b;
  font-size: 18px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 25px;
  color: #ffffff;
  opacity: 1;
  border: none;
  border-radius: 37px;
}

@font-face {
  font-family: "iconfont2";
  src: url("../../assets/font-icon/font_6d57ta7yli4/iconfont.eot");
  src: url("../../assets/font-icon/font_6d57ta7yli4/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.svg#iconfont")
      format("svg");
}

.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  /*font-weight: bold;*/
  font-style: normal;
  color: #e5e5e5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconfont-dark-color {
  color: #d9d9d9;
}
</style>

