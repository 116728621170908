// package mind.yushu.com.mindmap.core.calcule;

const { default: CGPoint } = require("../../../viewmodel/core/base/basedata/CGPoint");
const { default: UiUtil } = require("../../../utils/UiUtil");
const { default: CGRect } = require("../../../viewmodel/core/base/basedata/Rect");
import Util from "../../../utils/Util"
import Point from "../../../viewmodel/core/base/Point";
import MindElementType from "../../../viewmodel/datatype/MindElementType";

// import android.graphics.Point;

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.utils.Util;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/10
 * Copyright(c) 2020 mindyushu.com
 */

class NodeConnectLineHit {

    lineHit(point, data) {
        if (data.isEmpty() || data.lineContent == null) {
            return false;
        }
        if (data.type == MindElementType.NODE_CONNECT_LINE) {
            if (data.lineContent.isContainText() &&
                point.x >= data.x + data.lineContent.textContent.x &&
                point.x <= data.x + data.lineContent.textContent.x + data.lineContent.textContent.width &&
                point.y >= data.y + data.lineContent.textContent.y &&
                point.y <= data.y + data.lineContent.textContent.y + data.lineContent.textContent.height) {
                return true;
            }
            switch (data.lineContent.connectLineType) {
                case ConnectLineType.STRAIGHT_ARROW_LINE:
                case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                    return this.straightArrowHit(point, data);
                case ConnectLineType.CURVE_LINE:
                    return this.curveHit(point, data);
                case ConnectLineType.RIGHT_ANGLE_LINE:
                    return this.rightAngleHit(point, data);
                default:
                    return this.straightArrowHit(point, data);
            }
        } else if (data.type == MindElementType.LAYOUT_CUSTOM_LINE) {
            return false;//this.customStraightArrowHit(point, data);
        } else {
            return false
        }
        
    }

    customStraightArrowHit(point, data) {
        if (data.isEmpty() || data.lineContent == null ||
            data.type != MindElementType.LAYOUT_CUSTOM_LINE) {
            return false;
        }
        let startPoint = data.lineContent.startPoint();
        let endPoint = data.lineContent.endPoint();

        return this.straightAndPointHit(point, [new CGPoint(startPoint.x + data.x, startPoint.y + data.y),
            new CGPoint(endPoint.x + data.x, endPoint.y + data.y)]);
    }

    straightArrowHit(point, data) {
        if (data.isEmpty() || data.lineContent == null ||
            data.type != MindElementType.NODE_CONNECT_LINE) {
            return false;
        }
        let startPoint = data.lineContent.startPoint();
        let endPoint = data.lineContent.endPoint();

        return this.straightAndPointHit(point, [new CGPoint(startPoint.x + data.x, startPoint.y + data.y),
            new CGPoint(endPoint.x + data.x, endPoint.y + data.y)]);
    }

    straightAndPointHit(point, line) {
        if (line.length != 2) {
            return false;
        }
        let space = new UiUtil().dip2px(10);
        let startPoint = line[0];
        let endPoint = line[1];

        let checkRect = new CGRect((startPoint.x < endPoint.x ? startPoint.x : endPoint.x) - space,
            (startPoint.y < endPoint.y ? startPoint.y : endPoint.y) - space,
            Math.abs(startPoint.x - endPoint.x) + space * 2,
            Math.abs(startPoint.y - endPoint.y) + space * 2);
        if (point.x < checkRect.x ||
            point.x > checkRect.x + checkRect.width() ||
            point.y < checkRect.y ||
            point.y > checkRect.y + checkRect.height()) {
            return false;
        }
        let horizontalSpaceing = Util.getPointAndLineHorizontalSpaceing(point, line[0], line[1]);
        let verticalSpaceing = Util.getPointAndLineVerticalSpaceing(point, line[0], line[1]);
        return horizontalSpaceing != -1 && horizontalSpaceing < space || verticalSpaceing != -1 && verticalSpaceing < space;
    }

    curveHit(point, data) {
        if (data.isEmpty() || data.lineContent == null ||
            data.type != MindElementType.NODE_CONNECT_LINE) {
            return false;
        }
        let startPoint = data.lineContent.startPoint();
        let endPoint = data.lineContent.endPoint();

        let startPointG = new CGPoint(startPoint.x + (data.x), startPoint.y + (data.y));
        let endPointG = new CGPoint(endPoint.x + (data.x), endPoint.y + (data.y));

        let precision = Math.abs(startPoint.x - endPoint.x) > new UiUtil().dip2px(10) || Math.abs(startPoint.y - endPoint.y) > new UiUtil().dip2px(10) ? new UiUtil().dip2px(10) : new UiUtil().dip2px(6);
        let list = new Array();
        list.push(startPointG);
        list.push(data.lineContent.getStartControlPoint());
        list.push(data.lineContent.getEndControlPoint());
        list.push(endPointG);
        let points = this.bezierCalculate(list, precision);
        if (points.length > 0) {
            for (let index = 0; index < points.length - 1; index++) {
                let v = this.straightAndPointHit(point, [points[index], points[index + 1]]);
                if (v) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * @param poss      贝塞尔曲线控制点坐标
     * @param precision 精度，需要计算的该条贝塞尔曲线上的点的数目
     * @return 该条贝塞尔曲线上的点（二维坐标）
     */
    bezierCalculate(poss, precision) {
        let result = new Array();
        //维度，坐标轴数（二维坐标，三维坐标...）
        let dimersion = 2;
        //贝塞尔曲线控制点数（阶数）
        let number = poss.length;
        //控制点数不小于 2 ，至少为二维坐标系
        if ((number < 3 || dimersion < 2)) {
            return result;
        }
        //计算杨辉三角
        let mi = new Array(number)
        mi[0] = mi[1] = 1;

        for (let i = 3; i <= number; i++) {

            let t = new Array(i -1)
            for (let j = 0; j < t.length; j++) {
                t[j] = mi[j];
            }

            mi[0] = mi[i - 1] = 1;
            for (let j = 0; j < i - 2; j++) {
                mi[j + 1] = t[j] + t[j + 1];
            }
        }

        //计算坐标点
        for (let i = 0; i < precision; i++) {
            let t = i / precision;
            let p = new Point(0, 0);
            if (dimersion >= 1) {
                for (let j = 0; j < dimersion; j++) {
                    let temp = 0.0;
                    if (number >= 1) {
                        for (let k = 0; k < number; k++) {
                            temp += Math.pow((1 - (t)), (number - k) - 1) * (j == 0 ? poss[k].x : poss[k].y) * Math.pow((t), (k)) * (mi[k]);
                        }
                    }
                    if (j == 0) {
                        p.x = temp;
                    } else {
                        p.y = temp;
                    }
                }
            }
            result.push(p);
        }

        return result;
    }

    rightAngleHit(point, data) {
        if (data.isEmpty() || data.lineContent == null ||
            data.type != MindElementType.NODE_CONNECT_LINE) {
            return false;
        }
        let startPoint = data.lineContent.startPoint();
        let endPoint = data.lineContent.endPoint();
        let connectPoint = data.lineContent.rightAnglePoints;
        if (connectPoint.length > 0) {
            for (let index = 0; index < connectPoint.length; index++) {
                let hit = false;
                if (index == 0) {
                    hit = this.straightAndPointHit(point,
                        [new CGPoint(startPoint.x + data.x, startPoint.y + data.y),
                            new CGPoint((connectPoint[index].x + data.x), (connectPoint[index].y + data.y))
                        ]);
                } else {
                    hit = this.straightAndPointHit(point,
                        [new CGPoint((connectPoint[index - 1].x + data.x), (connectPoint[index - 1].y + data.y)),
                            new CGPoint((connectPoint[index].x + data.x), (connectPoint[index].y + data.y))
                        ]);
                }
                if (hit) {
                    return true;
                }
            }
            let hit = this.straightAndPointHit(point, [
                new CGPoint((connectPoint[connectPoint.length - 1].x + data.x), (connectPoint[connectPoint.length - 1].y + data.y)),
                new CGPoint(endPoint.x + (data.x), endPoint.y + (data.y))
            ]);
            return hit;
        } else {
            return this.straightAndPointHit(point, [
                new CGPoint(startPoint.x + (data.x), startPoint.y + (data.y)),
                new CGPoint(endPoint.x + (data.x), endPoint.y + (data.y))
            ]);
        }
    }

}
export default NodeConnectLineHit
