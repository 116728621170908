/**
 * Created by tony on 2020/5/26
 */

let  MindElementIconCellType =  {
    ICON:1,
    LINK:2,
    REMARKS:3,
    CONNECT_MAP:4,
    MORE:5,
    RESOURCE:6,

    // private final int value;


    from(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.ICON;
    }

    // public int getValue() {
    //     return value;
    // }
}

export default MindElementIconCellType