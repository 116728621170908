/**
 * ProjectName: MindMap
 * Created by tony on 7/14/21
 * Copyright(c) 2020 mindyushu.com
 */

let LinePointShape = {

    NORMAL:1,             //直线
    ARROW:2,              //实心箭头
    BAMBOO_ARROW:3,       //斜边实心箭头
    SIMPLE_ARROW:4,       //斜边空箭头
    CIRCULAR:5,           //实心圆
    CIRCULAR_RADIATION:6, //实心辐射圆
    CIRCULAR_HOLLOW:7,    //空心圆
    T_SHAPED:8,           //丁子线
    DIAMOND:9,           //实心菱形


    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.Unknown;
    }

}

export default LinePointShape