<template>
  <div id="user-share-data">
    <div class="user-header">
      <img src="../../../../static/img/userData/collection_log.png" alt="" />
      <span @click="collectionToUserModel">{{ getString(strings.User_My_Collection) }}</span>
      <span @click="collectionProfileToMore" style="margin-left: 10px">{{
        initTitleAccout.name
      }}</span>
      <span style="margin-left: 10px">{{ menuName }}</span>
      <div
        v-if="!initCollectionDataNull && initCollectionContent"
        class="user-share-more"
        @click="getUserShareLink"
      >
        <span style="font-size: 14px">{{getString(strings.User_More_Message)}}</span>
        <img src="../../../../static/img/userData/look_more_mind.png" alt="" />
      </div>
    </div>

    <!-- 分类 -->
    <div v-if="initTitleAccout.accout" class="share-mind-header">
      <div
        class="share-mind-and-link"
        v-for="(item, index) in shareTitle"
        :key="index"
        :class="shareTileAccout == index ? 'set-title-style' : ''"
        @click="setTitleStyle(index)"
      >
        {{ getString(item) }}
      </div>
    </div>

    <!-- 外部展示 -->
    <div class="user-share" v-if="routerName == 0">
      <div class="share-content">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData.collectedItems"
          :key="index"
          @click="moreShareOrcollectionToLink(item)"
        >
          <div
            class="share-pc-cover"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-text">
            <div class="share-mind-titel-look">
              <div class="share-mind-titel-text">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 更多模板 -->
    <transition name="slide-fade">
      <div class="share-content share-detail" v-if="routerName == 1">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData"
          :key="index"
          @click="moreShareOrcollectionToLink(item)"
        >
          <div
            class="share-collection-profile"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-text">
            <div class="share-mind-titel">
              <span>{{ item.title }}</span>
            </div>
            <div class="share-look-data">
              <div class="share-look-time">
                {{ timestampToTimeDHM(item.createTime) }}
              </div>
              <div class="share-look-count" v-if="shareTileAccout != 0">
                <img
                  src="../../../../static/img/userData/user_mind_lookcount.png"
                  alt=""
                />
                85
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 更多链接 -->
    <transition name="slide-fade">
      <div class="share-content share-detail" v-if="routerName == 2">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData"
          :key="index"
          @click="moreShareOrcollectionToLink(item)"
        >
          <div
            class="share-collection-profile"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img src="../../../../static/img/userData/link_logo.png" alt="" />
          </div>
          <div class="share-text">
            <div class="share-mind-titel">
              <span>{{ item.title }}</span>
            </div>
            <div class="share-look-data">
              <div class="share-look-time">
                {{ timestampToTimeDHM(item.createTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 风暴 -->
    <transition name="slide-fade">
      <div class="share-potato-chips" v-if="routerName == 3">
        <div
          class="share-pc-box"
          v-for="(item, index) in initUserCollectionData"
          :key="index"
          @click="moreShareOrcollectionToLink(item)"
        >
          <div
            class="share-pc-cover"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-pc-text">
            <div class="share-pc-title">
              <span>{{ item.title }}</span>
            </div>
            <div class="share-pc-text-content">
              <span>{{ item.subtitle }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 收藏夹列表页 -->
    <transition name="slide-fade">
      <div class="share-content share-detail" v-if="routerName == 4">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData.collections"
          :key="index"
        >
          <div
            class="share-collection-profile"
            @click="collectionToMoreDetial(item.id, item.name)"
          >
            <img
              src="../../../../static/img/userData/collection_proflie_logo.png"
              alt=""
            />
          </div>
          <div class="share-text">
            <div class="share-mind-titel">
              <div>{{ item.name }}</div>
              <a-popover
                v-model="item.visible"
                placement="bottomLeft"
                trigger="click"
              >
                <template slot="content">
                  <div
                    class="share-delete-content"
                    @click="collectionFile(item.id, index)"
                  >
                    <img
                      style="width: 18px"
                      src="../../../../static/img/userData/collection_logo.svg"
                      alt=""
                    />
                    <span>{{getString(strings.User_Collection)}}</span>
                  </div>
                </template>
                <a-button
                  ><img
                    src="../../../../static/img/userData/details_logo.png"
                    alt=""
                /></a-button>
              </a-popover>
            </div>
            <div class="share-look-data">
              <div class="share-look-time">
                {{ timestampToTimeDHM(item.createTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 暂无数据 -->
    <div
      :class="
        routerName == 0
          ? 'user-collection-data-null-page'
          : 'user-collection-data-null'
      "
      v-if="initCollectionDataNull"
    >
      <div class="data-null">
        <img
          style="width: 120px;"
          src="../../../../static/img/userData/user_collection_null_logo.png"
          alt=""
        />
        <p>{{getString(strings.User_Collction_Is_Null)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  postUserQueryCollections,
  postUserQueryCollectItems,
  postUserSaveCollection,
} from "../../../common/netWork/base_api";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import dataTimeToFormats from "../../../utils/timestampToTime";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

export default {
  data() {
    return {
      shareTitle: [strings.TEMPLATE, strings.User_Message_Link, strings.Storm],
      shareTileAccout: 0,
      initUserCollectionData: {},
      initCollectionDataNull: false,
      initCollectionContent: false,
      collectionMore: false,
      routerName: 0,
      menuName: "",
      nowElectron: false,
      colorList: [
        "#9db2c4",
        "#9179c3",
        "#e18995",
        "#ebc185",
        "#82d0dc",
        "#60a5a2",
        "#6d6a8c",
        "#f1cecc",
      ],
      strings:strings
    };
  },
  props: {
    initUserData: {
      defualt() {
        return {};
      },
    },
    initTitleAccout: {
      defualt() {
        return "";
      },
    },
  },
  created() {},
  mounted() {
    this.nowElectron = this.$tools.isElectron();
  },
  methods: {
    //语言包
    getString(i) {
      return getString(i);
    },
    openMind(item) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item);
    },
    //添加背景颜色
    addBackgroundColor(array) {
      if (array.length > this.colorList.length) {
        for (let index = 0; index < array.length; index++) {
          if (array.length != this.colorList.length) {
            this.colorList.push(this.colorList[index]);
          }
        }
      }
    },
    //title
    setTitleStyle(index) {
      this.shareTileAccout = index;
      if (index == 0 || index == 1 || index == 2) {
        this.routerName = 4;
        this.postUserQueryCollections();
      } 
    },
    //是否浏览更多
    morePageHttp(data) {
      this.routerName = 4;
      this.postUserQueryCollections();
    },
    //请求收藏夹列表
    postUserQueryCollections() {
      // console.log(this.shareTileAccout);
      // console.log(this.userModelData.userId);
      postUserQueryCollections(
        {
          userId: this.userModelData.userId, //5fc35fad3f78d7b84d0368e3
          type: this.shareTileAccout,
          lastCollectionId: "",
        },
        (collectionsData) => {
          this.initCollectionContent = false;
          if (collectionsData.collections.length > 0) {
            this.initUserCollectionData = []
            // console.log(this.initUserCollectionData);
            this.initUserCollectionData = collectionsData;
            // console.log(this.initUserCollectionData);
            this.initCollectionDataNull = false;
          } else {
            this.initUserCollectionData = [];
            this.initCollectionDataNull = true;
          }
          this.addBackgroundColor(collectionsData.collections);
        }
      );
    },
    //根据点击收藏夹请求
    collectionToMoreDetial(id, name) {
      if (name != undefined) {
        this.templateCollectionId = { id, name };
      }
      postUserQueryCollectItems(
        {
          collectionId: id,
          lastId: "",
        },
        (initUserCollectionData) => {
          // 0-模板收藏夹 1-链接收藏夹 2-风暴收藏夹
          const httpType = this.shareTileAccout;
          switch (httpType) {
            case 0:
              this.routerName = 1;
              break;
            case 1:
              this.routerName = 2;
              break;
            case 2:
              this.routerName = 3;
              break;
            default:
              break;
          }
          if (initUserCollectionData.collectItems.length > 0) {
            this.initCollectionDataNull = false;
            let collectItemsLength = initUserCollectionData.collectItems.length;
            let collectItems = initUserCollectionData.collectItems;

            for (let i = 0; i < collectItemsLength; i++) {
              var cover = collectItems[i].cover;
              initUserCollectionData.collectItems[i].cover = httpImageToPrefix(
                cover
              );
            }

            var that = this;
            this.initUserCollectionData = collectItems.filter(function (
              element,
              index,
              self
            ) {
              return self[index].type == that.shareTileAccout;
            });
          } else {
            this.initUserCollectionData = [];
            this.initCollectionDataNull = true;
          }
          //title菜单
          this.menuName = " > " + name;
        }
      );
    },
    //获取主页信息
    getUserCollectionData() {
      if (this.initUserCollectionData.collectedItems != null) {
        this.initCollectionDataNull = false;
        this.initCollectionContent = true;
        let collectedItemsLength = this.initUserCollectionData.collectedItems
          .length;
        let collectedItems = this.initUserCollectionData.collectedItems;
        for (let i = 0; i < collectedItemsLength; i++) {
          var cover = collectedItems[i].cover;
          this.initUserCollectionData.collectedItems[
            i
          ].cover = httpImageToPrefix(cover);
        }
      } else {
        this.initCollectionDataNull = true;
        this.initCollectionContent = false;
      }
    },
    //回到个人主页
    collectionToUserModel() {
      this.routerName = 0;
      this.menuName = "";
      this.postUserQueryCollections();
      this.$emit("userShareDetailShow", true);
    },
    //回到更多
    collectionProfileToMore() {
      this.menuName = "";
      const titleId = this.shareTileAccout;
      switch (titleId) {
        case 0:
          this.routerName = 4;
          this.postUserQueryCollections();
          break;
        case 1:
          this.routerName = 4;
          this.postUserQueryCollections();
          break;
        case 2:
          this.menuName = "";
          this.routerName = 4;
          this.postUserQueryCollections();
          break;
        default:
          break;
      }
    },
    //菜单
    getUserShareLink() {
      this.menuName = "";
      this.$emit("userCollectionDetail", {
        accout: false,
        name: " > " + getString(strings.User_More_Message),
      });
    },
    //收藏文件夹
    collectionFile(id, index) {
      postUserSaveCollection({ collectionId: id }, (res) => {
        this.$message.success(getString(strings.Message_Tips_Collction_Success));
        this.initUserCollectionData.collections[index].visible = false;
      });
    },
    //时间转化
    timestampToTimeDHM(timestamp) {
      return dataTimeToFormats.timestampToTimeDHM(timestamp);
    },
    //跳转模板0 链接1 风暴2
    moreShareOrcollectionToLink(item) {
      // console.log(item);
      if (this.nowElectron) {
        if (item.type == 0) {
          this.openMind("/template/?id=" + item.itemId);
        } else if (item.type == 1) {
          this.openMind("/s/" + item.itemId);
        } else if (item.type == 2) {
          this.openMind("/c/" + item.itemId);
        }
      } else {
        if (item.type == 0) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/template/?id=" + item.itemId,
          });
          newWindow.location = routerData.href;
        } else if (item.type == 1) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/s/" + item.itemId,
          });
          newWindow.location = routerData.href;
        } else if (item.type == 2) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/c/" + item.itemId,
          });
          newWindow.location = routerData.href;
        }
      }
    },
  },
  computed: {
    ...mapState({
      userModelData: (state) => state.userModelData,
    }),
  },
  watch: {
    initUserData(newCollection) {
      this.initUserCollectionData = newCollection;
      this.getUserCollectionData();
    },
    initTitleAccout(newCollection) {
      if (newCollection.accout) {
        this.morePageHttp();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
#user-share-data {
  .user-header {
    background: #f0f2f8;
    height: 48px;
    display: flex;
    align-items: center;
    img {
      margin: 20px 10px 20px 20px;
    }
    span {
      font-size: 18px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      color: #666666;
      opacity: 1;
      cursor: pointer;
    }
    .user-share-more {
      position: absolute;
      right: 120px;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      span {
        transition: 0.3s;
      }
    }
    .user-share-more:hover {
      // span {
      //   color: #fd492b;
      //   transition: 0.3s;
      // }
      // img {
      //   width: 14px;
      //   height: 15px;
      //   filter: drop-shadow(#fd492b 100px 0);
      //   transform: translateX(-100px);
      //   overflow: hidden;
      // }
    }
  }
  // 分类
  .share-mind-header {
    display: flex;
    border-bottom: 3px solid #f0f2f8;
    height: 40px;
    margin-top: 20px;
    .share-mind-and-link {
      font-size: 16px;
      height: 38px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      opacity: 1;
      text-align: center;
      cursor: pointer;
      margin: 0 30px;
    }
    .set-title-style {
      border-bottom: 3px solid #fd492b;
      color: #fd492b;
    }
  }
  .share-content {
    margin-top: 10px;
    display: flex;
    min-width: 246px;
    max-height: 562px;
    overflow: hidden;
    .share-cover {
      width: 180px;
      height: 222px;
      border-radius: 4px 4px 0px 0px;
      background: rgba(0, 0, 0, 0);
      box-shadow: 0px 3px 6px rgba(80, 80, 80, 0.16);
      margin: 12px 10px;
      cursor: pointer;
      transition: 0.5s;
      .share-pc-cover {
        min-width: 140px;
        width: 180px;
        height: 160px;
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          transition: 0.5s;
        }
        img:hover {
          transition: 0.5s;
          transform: scale(1.1);
        }
      }
      .share-collection-profile {
        width: 180px;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;
        img {
          transition: 0.5s;
        }
        img:hover {
          transition: 0.5s;
          transform: scale(1.1);
        }
      }
      .share-text {
        width: 180px;
        height: 62px;
        border-radius: 4px 4px 0px 0px;
        .share-mind-titel {
          margin: 10px 10px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          div {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
          .share-mind-delete {
            width: 20px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
          }
          button {
            border: none;
            height: 20px;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
        .share-look-data {
          margin: 10px;
          display: flex;
          justify-content: space-between;
          align-content: center;
        }
        .share-mind-titel-look {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          .share-mind-titel-text {
            overflow: hidden;
            width: 146px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
    .share-cover:hover {
      box-shadow: 5px 8px 13px rgba(80, 80, 80, 0.16);
      background-color: rgba(0, 0, 0, 0.06);
      transition: 0.5s;
      img {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }
  .share-detail {
    display: flex;
    flex-wrap: wrap;
    width: 1018px;
    // justify-content: space-between;
    // align-items: center;
    text-align: justify;
    overflow: auto;
  }
  //空数据
  .user-collection-data-null-page {
    display: flex;
    justify-content: center;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        // margin-bottom: 30px;
      }
    }
  }
  .user-collection-data-null {
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      img {
        margin-bottom: 30px;
      }
    }
  }
  .share-potato-chips {
    width: 100%;
    margin: 20px 20px;
    overflow: auto;
    height: 590px;
    .share-pc-box {
      display: flex;
      margin-top: 20px;
      .share-pc-cover {
        min-width: 140px;
        width: 140px;
        height: 126px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0);
        box-shadow: 0px 3px 6px #bebebe;
        opacity: 1;
        border-radius: 10px;
        cursor: pointer;
      }
      .share-pc-cover img {
        transition: 0.5s;
      }
      .share-pc-cover img:hover {
        transform: scale(1.1);
        transition: 0.5s;
      }
      .share-pc-text {
        width: 100%;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .share-pc-title {
          min-width: 162px;
          height: 25px;
          font-size: 18px;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 25px;
          color: #333333;
          opacity: 1;
          margin-top: 8px;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            -webkit-transition: 0.3s;
            transition: 0.3s;
          }
        }
        .share-pc-text-content {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
          opacity: 1;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            cursor: pointer;
          }
        }
        .share-pc-bottom {
          width: 500px;
          font-size: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
          opacity: 1;
          display: flex;
          position: relative;
          align-items: center;
          .share-pc-time {
            position: absolute;
            left: 0;
          }
          .share-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            right: 0;
            width: 288px;
            .share-details-single {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                margin-right: 10px;
              }
            }
            button {
              border: none;
              background-color: transparent;
            }
          }
        }
        .share-pc-right-detail {
          position: absolute;
          right: 30px;
          cursor: pointer;
          button {
            border: none;
            height: 20px;
            padding: 0;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
.share-delete-button {
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 18px 23px;
}
.share-delete-content {
  display: flex;
  align-items: center;
  height: 40px;
  width: 146px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
}
</style>