class Definition {
    constructor() {
        this.NAME = "APP_Definition_";
        this.ACTION_MAIN_TAB_CURRENT_INDEX = this.NAME + "ACTION_MAIN_TAB_CURRENT_INDEX";

        this.ACTION_RECEIVE_STYLE_CLOSE_THEME_TAB = this.NAME + "ACTION_RECEIVE_STYLE_CLOSE_THEME_TAB";
        this.ACTION_RECEIVE_STYLE_OPEN_THEME_TAB = this.NAME + "ACTION_RECEIVE_STYLE_OPEN_THEME_TAB";

        this.ACTION_RECEIVE_STYLE_CHANGE_STARTINDEX_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_STARTINDEX_VALUE";
        this.ACTION_RECEIVE_STYLE_CHANGE_ENDINDEX_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENDINDEX_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_COLOR = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_COLOR";
        this.ACTION_RECEIVE_STYLE_CHANGE_COLOR_TYPE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_COLOR_TYPE";
        this.ACTION_RECEIVE_STYLE_CHANGE_COLOR_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_COLOR_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_LINE_WIDTH = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_LINE_WIDTH";
        this.ACTION_RECEIVE_STYLE_CHANGE_LINE_WIDTH_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_LINE_WIDTH_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_SHAPE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_SHAPE";
        this.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_SHAPE_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_SHAPE_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_LINE_LENGTH = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_LINE_LENGTH";
        this.ACTION_RECEIVE_STYLE_CHANGE_LINE_LENGTH_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_LINE_LENGTH_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BORDER_WIDTH = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_BORDER_WIDTH";
        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BORDER_WIDTH_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_BORDER_WIDTH_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_SIZE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_SIZE";
        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_SIZE_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_SIZE_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD";
        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS";
        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH";
        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ALIGNMENT = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_ALIGNMENT";
        this.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ALIGNMENT_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TEXT_ALIGNMENT_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_TOOL_TYPE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TOOL_TYPE";
        this.ACTION_RECEIVE_STYLE_CHANGE_TOOL_TYPE_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TOOL_TYPE_VALUE";
        this.ACTION_RECEIVE_STYLE_CHANGE_TOOL_INSERT_STATISCS_TYPE_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_TOOL_INSERT_STATISCS_TYPE_VALUE";

        this.ACTIVE_HOT_SEARCH_AND_TAGS = this.NAME + "ACTIVE_HOT_SEARCH_AND_TAGS";
        this.ACTIVE_HOT_SEARCH_KEY = this.NAME + "ACTIVE_HOT_SEARCH_KEY";
        this.ACTIVE_HOT_TAGS_KEY = this.NAME + "ACTIVE_HOT_TAGS_KEY";

        this.ACTIVE_CREATE_MIND_MAP_EVENT = this.NAME + "ACTIVE_CREATE_MIND_MAP_EVENT";

        this.ACTIVE_TAB_CLICK_EVENT = this.NAME + "ACTIVE_TAB_CLICK_EVENT";
        this.ACTIVE_TAB_CLICK_KEY = this.NAME + "ACTIVE_TAB_CLICK_KEY";

        this.INTENT_KEY_WEBLOAD_TITLE = this.NAME + "INTENT_KEY_WEBLOAD_TITLE";
        this.INTENT_KEY_WEBLOAD_DYNAMIC_TITLE = this.NAME + "INTENT_KEY_WEBLOAD_DYNAMIC_TITLE";
        this.IS_NEED_OUT_LOAD = this.NAME + "IS_NEED_OUT_LOAD";
        this.INTENT_KEY_WEBLOAD_URL = this.NAME + "INTENT_KEY_WEBLOAD_URL";

        this.ACTION_OPEN_TEMPLATE_ID = this.NAME + "ACTION_OPEN_TEMPLATE_ID";

        this.OPEN_MINDMAP_BY_DETAILS_ID = this.NAME + "OPEN_MINDMAP_BY_DETAILS_ID";
        this.OPEN_MINDMAP_BY_PREVIEW_ID = this.NAME + "OPEN_MINDMAP_BY_PREVIEW_ID";
        this.OPEN_MINDMAP_BY_TYPE = this.NAME + "OPEN_MINDMAP_BY_TYPE";
        this.OPEN_MINDMAP_BY_LAYOUT = this.NAME + "OPEN_MINDMAP_BY_LAYOUT";
        this.OPEN_MINDMAP_BY_LINE_LAYOUT = this.NAME + "OPEN_MINDMAP_BY_LINE_LAYOUT";
        this.OPEN_MINDMAP_BY_STYLE_INDEX = this.NAME + "OPEN_MINDMAP_BY_STYLE_INDEX";
        this.OPEN_MINDMAP_BY_FILE_ID = this.NAME + "OPEN_MINDMAP_BY_FILE_ID";
        this.OPEN_MINDMAP_BY_MIND_DISPLAY_TYPE = this.NAME + "OPEN_MINDMAP_BY_MIND_DISPLAY_TYPE";
        this.OPEN_MINDMAP_BY_MIND_EMPTY_TYPE = this.NAME + "OPEN_MINDMAP_BY_MIND_EMPTY_TYPE";
        this.OPEN_MINDMAP_BY_MIND_AUDIO_SECOND = this.NAME + "OPEN_MINDMAP_BY_MIND_AUDIO_SECOND";

        this.OPEN_MINDMAP_BY_MIND_MAP_ID = this.NAME + "OPEN_MINDMAP_BY_MIND_MAP_ID";
        this.OPEN_MINDMAP_BY_MIND_MAP_TITLE = this.NAME + "OPEN_MINDMAP_BY_MIND_MAP_TITLE";
        this.OPEN_MINDMAP_BY_MIND_MAP_SUBTITLE = this.NAME + "OPEN_MINDMAP_BY_MIND_MAP_SUBTITLE";

        this.OPEN_MINDMAP_BY_WIDGET_ID = this.NAME + "OPEN_MINDMAP_BY_WIDGET_ID";



        this.OPEN_MINDMAP_BY_CONTENT_VALUE = this.NAME + "OPEN_MINDMAP_BY_CONTENT_VALUE";
        //    this.OPEN_MINDMAP_BY_SHARE_GROUP_MIND_ID = this.NAME + "OPEN_MINDMAP_BY_SHARE_GROUP_MIND_ID";
        this.OPEN_MINDMAP_BY_SHARE_GROUP_GMAP_ID = this.NAME + "OPEN_MINDMAP_BY_SHARE_GROUP_GMAP_ID";
        this.OPEN_MINDMAP_BY_SHARE_GROUP_ID = this.NAME + "OPEN_MINDMAP_BY_SHARE_GROUP_ID";
        this.OPEN_MINDMAP_BY_DEFAULT_EDIT_MODE = this.NAME + "OPEN_MINDMAP_BY_DEFAULT_EDIT_MODE";

        this.OPEN_MINDMAP_BY_SHEET_ID = this.NAME + "OPEN_MINDMAP_BY_SHEET_ID";
        this.OPEN_MINDMAP_BY_SHEET_MODE = this.NAME + "OPEN_MINDMAP_BY_SHEET_MODE";
        this.OPEN_MINDMAP_BY_CONNECT_MIND_SHEET_ID = this.NAME + "OPEN_MINDMAP_BY_CONNECT_MIND_SHEET_ID";


        this.ACTION_RECEIVE_STYLE_CHANGE_SHAPE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_SHAPE";
        this.ACTION_RECEIVE_STYLE_CHANGE_SHAPE_TYPE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_SHAPE_TYPE";

        this.ACTION_RECEIVE_STYLE_CHANGE_LINE_DOTTED = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_LINE_DOTTED";
        this.ACTION_RECEIVE_STYLE_CHANGE_LINE_DOTTED_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_LINE_DOTTED_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_PADDING = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_PADDING";
        this.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_PADDING_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_PADDING_VALUE";

        this.ACTION_RECEIVE_STYLE_ADD_ICON = this.NAME + "ACTION_RECEIVE_STYLE_ADD_ICON";
        this.ACTION_RECEIVE_STYLE_ADD_ICON_KEY = this.NAME + "ACTION_RECEIVE_STYLE_ADD_ICON_KEY";
        this.ACTION_RECEIVE_STYLE_ADD_ICON_STYLECOLOR = this.NAME + "ACTION_RECEIVE_STYLE_ADD_ICON_STYLECOLOR";
        this.ACTION_RECEIVE_STYLE_ADD_ICON_TINTCOLOR = this.NAME + "ACTION_RECEIVE_STYLE_ADD_ICON_TINTCOLOR";

        this.ACTION_RECEIVE_CHANGE_ICON_STYLE = this.NAME + "ACTION_RECEIVE_CHANGE_ICON_STYLE";
        this.ACTION_RECEIVE_CHANGE_ICON_STYLE_SIZE = this.NAME + "ACTION_RECEIVE_CHANGE_ICON_STYLE_SIZE";
        this.ACTION_RECEIVE_CHANGE_ICON_STYLE_LAYOUTE_TYPE = this.NAME + "ACTION_RECEIVE_CHANGE_ICON_STYLE_LAYOUTE_TYPE";

        this.ACTION_RECEIVE_CHANGE_IMAGE_STYLE = this.NAME + "ACTION_RECEIVE_CHANGE_IMAGE_STYLE";
        this.ACTION_RECEIVE_CHANGE_IMAGE_STYLE_SIZE_WIDTH = this.NAME + "ACTION_RECEIVE_CHANGE_IMAGE_STYLE_SIZE_WIDTH";
        this.ACTION_RECEIVE_CHANGE_IMAGE_STYLE_SIZE_HEIGHT = this.NAME + "ACTION_RECEIVE_CHANGE_IMAGE_STYLE_SIZE_HEIGHT";
        this.ACTION_RECEIVE_CHANGE_IMAGE_STYLE_LAYOUTE_TYPE = this.NAME + "ACTION_RECEIVE_CHANGE_IMAGE_STYLE_LAYOUTE_TYPE";
        this.ACTION_RECEIVE_CHANGE_IMAGE_STYLE_SHAPE_TYPE = this.NAME + "ACTION_RECEIVE_CHANGE_IMAGE_STYLE_SHAPE_TYPE";

        this.ACTION_RECEIVE_CHANGE_REMARKS = this.NAME + "ACTION_RECEIVE_CHANGE_REMARKS";
        this.ACTION_RECEIVE_CHANGE_REMARKS_ID = this.NAME + "ACTION_RECEIVE_CHANGE_REMARKS_ID";
        this.ACTION_RECEIVE_CHANGE_REMARKS_TEXT = this.NAME + "ACTION_RECEIVE_CHANGE_REMARKS_TEXT";

        this.ACTION_RECEIVE_CHANGE_LINK = this.NAME + "ACTION_RECEIVE_CHANGE_LINK";
        this.ACTION_RECEIVE_CHANGE_LINK_ID = this.NAME + "ACTION_RECEIVE_CHANGE_LINK_ID";
        this.ACTION_RECEIVE_CHANGE_LINK_ADD_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINK_ADD_VALUE";
        this.ACTION_RECEIVE_CHANGE_LINK_REMOVE_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINK_REMOVE_VALUE";

        this.ACTION_RECEIVE_CHANGE_TEXT_SHADOW_THICKEN = this.NAME + "ACTION_RECEIVE_CHANGE_TEXT_SHADOW_THICKEN";
        this.ACTION_RECEIVE_CHANGE_TEXT_SHADOW_THICKEN_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_TEXT_SHADOW_THICKEN_VALUE";

        this.ACTION_RECEIVE_CHANGE_TEXT_NODE_BACKGROUN_IMAGE = this.NAME + "ACTION_RECEIVE_CHANGE_TEXT_NODE_BACKGROUN_IMAGE";
        this.ACTION_RECEIVE_CHANGE_TEXT_NODE_BACKGROUN_IMAGE_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_TEXT_NODE_BACKGROUN_IMAGE_VALUE";

        this.ACTION_RECEIVE_CHANGE_LINE_THICKEN = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_THICKEN";
        this.ACTION_RECEIVE_CHANGE_LINE_THICKEN_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_THICKEN_VALUE";
        this.ACTION_RECEIVE_CHANGE_LINE_END_THICKEN_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_END_THICKEN_VALUE";

        this.ACTION_RECEIVE_EDIT_MINDMAP_HIDDEN_MENU = this.NAME + "ACTION_RECEIVE_EDIT_MINDMAP_HIDDEN_MENU";

        this.ACTION_CHANGE_STATUS_BAR_COLOR = this.NAME + "ACTION_CHANGE_STATUS_BAR_COLOR";
        this.ACTION_CHANGE_STATUS_BAR_COLOR_VALUE = this.NAME + "ACTION_CHANGE_STATUS_BAR_COLOR_VALUE";

        this.OPEN_MOVE_MIND_TO_FILE_TARGET_ID = this.NAME + "OPEN_MOVE_MIND_TO_FILE_TARGET_ID";
        this.OPEN_MOVE_MIND_TO_FILE_TARGET_PARENTID = this.NAME + "OPEN_MOVE_MIND_TO_FILE_TARGET_PARENTID";
        this.OPEN_MOVE_MIND_TO_FILE_TARGET_TYPE = this.NAME + "OPEN_MOVE_MIND_TO_FILE_TARGET_TYPE";

        this.DELETED_MINDMAP_CELL_LONG_CLICK = this.NAME + "DELETED_MINDMAP_CELL_LONG_CLICK";


        this.SELECT_CONNECT_MAP_TARGET_MINDMAP_ID = this.NAME + "SELECT_CONNECT_MAP_TARGET_MINDMAP_ID";
        this.SELECT_CONNECT_MAP_TARGET_GROUP_ID = this.NAME + "SELECT_CONNECT_MAP_TARGET_GROUP_ID";

        this.SELECT_CONNECT_MAP_TARGET_MAP_ID = this.NAME + "SELECT_CONNECT_MAP_TARGET_MAP_ID";
        this.SELECT_CONNECT_MAP_TARGET_NODE_ID = this.NAME + "SELECT_CONNECT_MAP_TARGET_NODE_ID";
        this.SELECT_CONNECT_MAP_SELECT_MAP_ID = this.NAME + "SELECT_CONNECT_MAP_SELECT_MAP_ID";
        this.SELECT_CONNECT_MAP_SELECT_MAP_SERVER_ID = this.NAME + "SELECT_CONNECT_MAP_SELECT_MAP_SERVER_ID";
        this.SELECT_CONNECT_MAP_SELECT_MAP_SHEET_ID = this.NAME + "SELECT_CONNECT_MAP_SELECT_Map_SHEET_ID";

        this.INTENT_KEY_STRING = "INTENT_KEY_STRING";

        this.USER_DEFAULT_DEVIDE_UUID_KEY = "USER_DEFAULT_DEVIDE_UUID_KEY";
        this.LOGIN_COMPLETE_GOTO_BUY_PAGE = this.NAME + "LOGIN_COMPLETE_GOTO_BUY_PAGE";
        this.USER_DEFAULT_HISTORY_COLOR_KEY = this.NAME + "USER_HISTORY_COLOR_KEY";
        this.USER_DEFAULT_CHECK_UPDATA_TIME_KEY = this.NAME + "USER_DEFAULT_CHECK_UPDATA_TIME_KEY";
        this.USER_DEFAULT_USER_LIST_KEY = this.NAME + "USER_DEFAULT_USER_LIST_KEY";
        this.USER_DEFAULT_CLICK_PREVIEW_IMAGE_KEY = this.NAME + "USER_DEFAULT_CLICK_PREVIEW_IMAGE_KEY";

        this.ACTION_RECEIVE_GOTO_LOGIN = this.NAME + "ACTION_RECEIVE_GOTO_LOGIN";

        /**列表刷新*/
        this.ACTION_REFRESH_MY_MIND_LIST_DATA = this.NAME + "ACTION_REFRESH_MY_MIND_LIST_DATA";
        /**列表子数据更新*/
        this.ACTION_MY_MIND_MAIN_LIST_ITEM_UPDATE = this.NAME + "ACTION_MY_MIND_MAIN_LIST_ITEM_UPDATE";

        this.USER_NETWORK_ENVIRONMENT_VALUE_KEY = this.NAME + "USER_NETWORK_ENVIRONMENT_VALUE_KEY";

        this.PRICE_PRICE_ID_VALUE_KEY = this.NAME + "PRICE_PRICE_ID_VALUE_KEY";
        this.SCANNER_CODE_LOGIN_URL_VALUE_KEY = this.NAME + "SCANNER_CODE_LOGIN_URL_VALUE_KEY";

        this.SELECT_SHARE_TEMPLATE_IMAGE_PATH = this.NAME + "SELECT_SHARE_TEMPLATE_IMAGE_PATH";
        this.ACTION_OPEN_HTTP_TEMPLATE_ID = this.NAME + "ACTION_OPEN_HTTP_TEMPLATE_ID";

        this.SHARE_MIND_MAP_CONTENT_URL = this.NAME + "SHARE_MIND_MAP_CONTENT_URL";
        this.SHARE_MIND_MAP_CONTENT_VALUE = this.NAME + "SHARE_MIND_MAP_CONTENT_VALUE";
        this.SHARE_MIND_MAP_CONTENT_VALUE_MIND_TYPE = this.NAME + "SHARE_MIND_MAP_CONTENT_VALUE_MIND_TYPE";
        this.SHARE_MIND_MAP_CONTENT_VALUE_LINE_LAYOUT = this.NAME + "SHARE_MIND_MAP_CONTENT_VALUE_LINE_LAYOUT";
        this.SHARE_MIND_MAP_CONTENT_USER_AVATAR = this.NAME + "SHARE_MIND_MAP_CONTENT_USER_AVATAR";
        this.SHARE_MIND_MAP_CONTENT_USER_ID = this.NAME + "SHARE_MIND_MAP_CONTENT_USER_ID";
        this.SHARE_MIND_MAP_CONTENT_USER_NAME = this.NAME + "SHARE_MIND_MAP_CONTENT_USER_NAME";
        this.SHARE_MIND_MAP_CONTENT_VIEW_COUNT = this.NAME + "SHARE_MIND_MAP_CONTENT_VIEW_COUNT";

        this.SHARE_MIND_MAP_CONTENT_ROOT_ID = this.NAME + "SHARE_MIND_MAP_CONTENT_ROOT_ID";

        this.SHARE_MIND_MAP_DISPLAY_TYPE = this.NAME + "SHARE_MIND_MAP_DISPLAY_TYPE";
        this.SHARE_MIND_MAP_PASSWORD = this.NAME + "SHARE_MIND_MAP_PASSWORD";
        this.SHOW_USER_PROFILE_USER_ID = this.NAME + "SHOW_USER_PROFILE_USER_ID";
        this.SHOW_USER_PROFILE_X_FROM = this.NAME + "SHOW_USER_PROFILE_X_FROM";
        this.SHOW_USER_PROFILE_FROM_ID = this.NAME + "SHOW_USER_PROFILE_FROM_ID";
        this.SHOW_USER_PROFILE_USER_NAME = this.NAME + "SHOW_USER_PROFILE_USER_NAME";
        this.SHOW_USER_PROFILE_SHOW_TYPE = this.NAME + "SHOW_USER_PROFILE_SHOW_TYPE";

        this.COLLECTION_FOLDER_ID = this.NAME + "COLLECTION_FOLDER_ID";
        this.COLLECTION_FOLDER_NAME = this.NAME + "COLLECTION_FOLDER_NAME";

        this.COLLECTION_LOAD_MY_FOLDER_MENU_TYPE = this.NAME + "COLLECTION_LOAD_MY_FOLDER_MENU_TYPE";
        this.COLLECTION_MAP_ID = this.NAME + "COLLECTION_MAP_ID";

        this.ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_ROOT_FONT_SIZE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_ROOT_FONT_SIZE";
        this.ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_SUBJECT_FONT_SIZE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_SUBJECT_FONT_SIZE";
        this.ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_SON_SUBJECT_FONT_SIZE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_SON_SUBJECT_FONT_SIZE";

        this.CRISPS_ITEM_ID = this.NAME + "CRISPS_ITEM_ID";

        //    this.CRISPS_ITEM_CONTENT_VALUE  = this.NAME + "CRISPS_ITEM_CONTENT_VALUE";


        this.CRISPS_MIND_MAP_CONTENT_VALUE_MIND_TYPE = this.NAME + "CRISPS_MIND_MAP_CONTENT_VALUE_MIND_TYPE";
        this.CRISPS_MIND_MAP_CONTENT_USER_AVATAR = this.NAME + "CRISPS_MIND_MAP_CONTENT_USER_AVATAR";
        this.CRISPS_MIND_MAP_CONTENT_USER_ID = this.NAME + "CRISPS_MIND_MAP_CONTENT_USER_ID";
        this.CRISPS_MIND_MAP_CONTENT_USER_NAME = this.NAME + "CRISPS_MIND_MAP_CONTENT_USER_NAME";
        this.CRISPS_MIND_MAP_CONTENT_VIEW_COUNT = this.NAME + "CRISPS_MIND_MAP_CONTENT_VIEW_COUNT";

        this.CRISPS_MIND_MAP_IS_USER_USABLE = this.NAME + "CRISPS_MIND_MAP_IS_USER_USABLE";
        this.CRISPS_MIND_MAP_M_BEANS_REQUIRED = this.NAME + "CRISPS_MIND_MAP_mbeansRequired";


        this.CRISPS_ID = this.NAME + "CRISPS_ID";
        this.CRISPS_TAGS_SEARCH_TAG_NAME = this.NAME + "CRISPS_TAGS_SEARCH_TAG_NAME";
        this.CRISPS_TAGS_SEARCH_TAG_ID = this.NAME + "CRISPS_TAGS_SEARCH_TAG_ID";

        this.COMMENT_ID = this.NAME + "COMMENT_ID";
        this.REPORT_TYPE = this.NAME + "REPORT_TYPE";

        //风暴标签搜索历史
        this.SEARCH_CRISPS_TAG_HISTORY = this.NAME + "SEARCH_CRISPS_TAG_HISTORY";
        //首次打开风暴的标记
        this.IS_FIRST_OPEN_STORM = this.NAME + "IS_FIRST_OPEN_STORM";

        //保存用户分享动作的Key
        this.KEY_SAVE_USER_SHARE_ACTION = "KEY_SAVE_USER_SHARE_ACTION";


        //保存用户存储空间最后一次升级日期
        this.KEY_SAVE_STORE_UPGRADE_DATE = "KEY_SAVE_STORE_UPGRADE_DATE";

        //共享组标签
        this.KEY_SAVE_SHARE_GROUP_TAGS = "KEY_SAVE_STORE_UPGRADE_DATE";

        //共享组标签
        this.KEY_SAVE_SHARE_GROUP_MAIN_TAB_LIST = "KEY_SAVE_SHARE_GROUP_MAIN_TAB_LIST";


        this.INPUT_BOTTOM_OPERATE_SHOW_KEY = "INPUT_BOTTOM_OPERATE_SHOW_KEY";


        //显示引导
        this.KEY_DISPLAY_GUIDE = "KEY_DISPLAY_GUIDE";

        this.ACTION_RECEIVE_STYLE_TIME_NODE_TYPE_KEY = this.NAME + "ACTION_RECEIVE_STYLE_TIME_NODE_TYPE_KEY";
        this.ACTION_RECEIVE_STYLE_TIME_NODE_TYPE_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_TIME_NODE_TYPE_VALUE";

        this.ACTION_RECEIVE_CHANGE_LINE_ARROW = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_ARROW";
        this.ACTION_RECEIVE_CHANGE_LINE_ARROW_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_ARROW_VALUE";

        this.ACTION_RECEIVE_CHANGE_FREENODE_ADSORBENT = this.NAME + "ACTION_RECEIVE_CHANGE_FREENODE_ADSORBENT";
        this.ACTION_RECEIVE_CHANGE_FREENODE_ADSORBENT_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_FREENODE_ADSORBENT_VALUE";

        this.ACTION_RECEIVE_CHANGE_BRANCH_NODE_FREE_LAYOUT = this.NAME + "ACTION_RECEIVE_CHANGE_BRANCH_NODE_FREE_LAYOUT";
        this.ACTION_RECEIVE_CHANGE_BRANCH_NODE_FREE_LAYOUT_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_BRANCH_NODE_FREE_LAYOUT_VALUE";

        this.USER_DEFAULT_SETTING_KEY = this.NAME + "USER_DEFAULT_SETTING_KEY";

        this.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_SHAPE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_SHAPE";
        this.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_SHAPE_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_SHAPE_VALUE";

        this.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_START_POINT_SHAPE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_START_POINT_SHAPE";
        this.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_START_POINT_SHAPE_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_START_POINT_SHAPE_VALUE";

        this.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_END_POINT_SHAPE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_END_POINT_SHAPE";
        this.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_END_POINT_SHAPE_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_END_POINT_SHAPE_VALUE";

        this.ACTION_RECEIVE_CHANGE_NODE_TASK_DELETE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_DELETE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_START_TIME = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_START_TIME";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_START_TIME_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_START_TIME_VALUE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_END_TIME = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_END_TIME";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_END_TIME_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_END_TIME_VALUE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_PRIORITY = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_PRIORITY";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_PRIORITY_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_PRIORITY_VALUE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_PROGRESS = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_PROGRESS";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_PROGRESS_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_PROGRESS_VALUE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_ADD_PEOPLES = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_ADD_PEOPLES";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_ADD_PEOPLES_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_ADD_PEOPLES_VALUE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_ADD_PEOPLES_ID_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_ADD_PEOPLES_ID_VALUE";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_CLEAR_PEOPLES_FOR_ADD = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_CLEAR_PEOPLES_FOR_ADD";
        this.ACTION_RECEIVE_CHANGE_NODE_TASK_OPEN_THEME = this.NAME + "ACTION_RECEIVE_CHANGE_NODE_TASK_OPEN_THEME";

        this.ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_HORIZONTAL = this.NAME + "ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_HORIZONTAL";
        this.ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_HORIZONTAL_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_HORIZONTAL_VALUE";

        this.ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_HORIZONTAL = this.NAME + "ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_HORIZONTAL";
        this.ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_HORIZONTAL_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_HORIZONTAL_VALUE";

        this.ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_VERTICAL = this.NAME + "ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_VERTICAL";
        this.ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_VERTICAL_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_SUBECT_LINE_SPACE_VERTICAL_VALUE";

        this.ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_VERTICAL = this.NAME + "ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_VERTICAL";
        this.ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_VERTICAL_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_SON_SUBECT_LINE_SPACE_VERTICAL_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_SHAPE_DOT = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_SHAPE_DOT";
        this.ACTION_RECEIVE_STYLE_CHANGE_SHAPE_DOT_VALUE = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_SHAPE_DOT_VALUE";

        this.ACTION_RECEIVE_CHANGE_LINE_DOT = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_DOT";
        this.ACTION_RECEIVE_CHANGE_LINE_DOT_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_DOT_VALUE";

        this.ACTION_RECEIVE_CHANGE_LINE_RAINBOW_COLORS = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_RAINBOW_COLORS";
        this.ACTION_RECEIVE_CHANGE_LINE_RAINBOW_COLORS_VALUE = this.NAME + "ACTION_RECEIVE_CHANGE_LINE_RAINBOW_COLORS_VALUE";

        this.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_TITLE_LAYOUT  = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_TITLE_LAYOUT";
        this.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_TITLE_LAYOUT_VALUE  = this.NAME + "ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_TITLE_LAYOUT_VALUE";
    }
}
export default new Definition()