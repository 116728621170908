
import BaseInputView from "./BaseInputView";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class GeneralizationView extends BaseInputView {

    constructor(viewCanvs, data, vuethis) {
        super(viewCanvs, data, vuethis);
    }

}

export default GeneralizationView