//生成随机数
class randomNumber {
    random(max, min) {
        return Math.round(Math.random() * (max - min) + min);
    }
    randomStr() {
        var strData = "";
        for (var i = 0; i < 4; i++) {
            var num = this.random(0, 9)//生成0-9随机数
            var az = String.fromCharCode(this.random(97, 122));//小写 a-z
            var AZ = String.fromCharCode(this.random(65, 90));  //大写 A-Z
            strData = strData + num + az + AZ;//拼接
        }
        var str = "";
        for (var j = 0; j < 4; j++) {
            str += strData[this.random(0,strData.length-1)]
        }
        return str  
    }
}
export default randomNumber