import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode"
import MindElementData from "../../mindelementdata/MindElementData"
import BaseLayout from "./BaseLayout"
import FreeTreeLeftLayout from "./FreeTreeLeftLayout"
import FreeTreeRightLayout from "./FreeTreeRightLayout"
import HashMap from "../../../viewmodel/core/base/HashMap"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import Point from "../base/Point"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import RectsMergeCalculation from "./RectsMergeCalculation"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import LeftRightLayout from "./LeftRightLayout"


/**
 * ProjectName: MindMap
 * Created by tony on 2023/3/30
 * Copyright(c) 2023 mindyushu.com
 */

class FreeTreeLayout extends LeftRightLayout {
    constructor() {
        super();
        this.leftLayout = new FreeTreeLeftLayout();
        this.rightLayout = new FreeTreeRightLayout();
        this.leftLayout.mindLayoutSelect = this.mindLayoutSelect
        this.rightLayout.mindLayoutSelect = this.mindLayoutSelect

        this.leftRightRootTreeNode = new LineMindTypeNode();
        this.leftRightGeneralizationLineMindElementDataDictInt = new HashMap;
        this.leftRightGeneralizationMindElementDataDict = new HashMap;

        this.leftRootTreeNode = new LineMindTypeNode();
        this.leftLineMindElementDataDict = new HashMap;//主体线字典， Int为线ID data为line
        this.leftMainMindElementDataDict = new HashMap;//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.leftTextElementLineMindElementDataDict = new HashMap;//主体节点对应的线，Int为节点ID，data为line
        this.leftGeneralizationLineMindElementDataDict = new HashMap;//概要线字典， Int为线ID data为line
        this.leftGeneralizationMindElementDataDict = new HashMap;//概要字典， Int为概要ID data为概要节点
        this.leftEncircleMindElementDataDict = new HashMap;

        this.rightRootTreeNode = new LineMindTypeNode();
        this.rightLineMindElementDataDict = new HashMap;//主体线字典， Int为线ID data为line
        this.rightMainMindElementDataDict = new HashMap;//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.rightTextElementLineMindElementDataDict = new HashMap;//主体节点对应的线，Int为节点ID，data为line
        this.rightGeneralizationLineMindElementDataDict = new HashMap;//概要线字典， Int为线ID data为line
        this.rightGeneralizationMindElementDataDict = new HashMap;//概要字典， Int为概要ID data为概要节点
        this.rightEncircleMindElementDataDict = new HashMap;
    }

    initData() {
        this.leftLayout.delegate = null;
        this.rightLayout.delegate = null;
        this.leftLayout = new FreeTreeLeftLayout();
        this.rightLayout = new FreeTreeRightLayout();
        this.leftRightRootTreeNode = new LineMindTypeNode();
        this.leftRootTreeNode = new LineMindTypeNode();
        this.leftLineMindElementDataDict = new HashMap;//主体线字典， Int为线ID data为line
        this.leftMainMindElementDataDict = new HashMap;//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.leftTextElementLineMindElementDataDict = new HashMap;//主体节点对应的线，Int为节点ID，data为line
        this.leftGeneralizationLineMindElementDataDict = new HashMap;//概要线字典， Int为线ID data为line
        this.leftGeneralizationMindElementDataDict = new HashMap;//概要字典， Int为概要ID data为概要节点
        this.leftEncircleMindElementDataDict = new HashMap;
        this.rightRootTreeNode = new LineMindTypeNode();
        this.rightLineMindElementDataDict = new HashMap;
        this.rightMainMindElementDataDict = new HashMap;//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.rightTextElementLineMindElementDataDict = new HashMap;//主体节点对应的线，Int为节点ID，data为line
        this.rightGeneralizationLineMindElementDataDict = new HashMap;//概要线字典， Int为线ID data为line
        this.rightGeneralizationMindElementDataDict = new HashMap;//概要字典， Int为概要ID data为概要节点
        this.rightEncircleMindElementDataDict = new HashMap;
        this.leftRightGeneralizationLineMindElementDataDictInt = new HashMap;
        this.leftRightGeneralizationMindElementDataDict = new HashMap;
    }

    onLayout(isChange) {
        if (this.rightLayout != null && this.rightLayout.rootTreeNode != null) {
            this.rightLayout.layout(isChange);
        }
        if (this.leftLayout != null && this.leftLayout.rootTreeNode != null &&
            this.leftLayout.rootTreeNode.children.length > 0) {
            this.leftLayout.layout(true);
        }
    }

    callbackLeftAndRightLayoutResetDatas(
        rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict) {

        let leftNodes = new Array();
        rootTreeNode.children.forEach(node => {
            if (this.leftMainMindElementDataDict.containsKey(node.value.id)) {
                leftNodes.push(node);
            } else {
                this.leftRightRootTreeNode.children.push(node);
            }
        })

        if (!leftNodes.isEmpty()) {
            let leftNodesCount = leftNodes.length;
            for (let index = 0; index < leftNodesCount; index++) {
                this.leftRightRootTreeNode.children.push(leftNodes[leftNodesCount - index - 1]);
            }
        }

        this.rootTreeNode = this.leftRightRootTreeNode;
        lineMindElementDataDict.keys().forEach(key => {
            this.lineMindElementDataDict.put(key, lineMindElementDataDict.get(key));
        });
        mainMindElementDataDict.keys().forEach(key => {
            this.mainMindElementDataDict.put(key, mainMindElementDataDict.get(key));
        });
        textElementLineMindElementDataDict.keys().forEach(key => {
            this.textElementLineMindElementDataDict.put(key, textElementLineMindElementDataDict.get(key));
        });
        this.mainMindElementDataDict.put(this.leftRightRootTreeNode.value.id, this.rootTreeNode);

        generalizationMindElementDataDict.keys().forEach(key => {
            let node = generalizationMindElementDataDict.get(key);
            this.leftRightGeneralizationMindElementDataDict.put(node.value.id, node);
        });
        generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = generalizationLineMindElementDataDict.get(key);
            this.leftRightGeneralizationLineMindElementDataDictInt.put(line.id, line);
        });
        this.generalizationMindElementDataDict = this.leftRightGeneralizationMindElementDataDict;
        this.generalizationLineMindElementDataDict = this.leftRightGeneralizationLineMindElementDataDictInt;
        this.explainMindElementDataDict = explainMindElementDataDict;
        // this.setEncirclePoint();
    }

    shuntData(isChange) {
        this.leftRightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        if (this.rootTreeNode.children.length <= 1) {
            this.rightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
            this.rightMainMindElementDataDict.put(this.rootTreeNode.value.id, this.rightRootTreeNode);
            for (let index = 0; index < this.rootTreeNode.children.length; index++) {
                this.rightRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setRightMainNode(this.rootTreeNode.children[index]);
            }
            this.lineMindElementDataDict.keys().forEach(key => {
                let line = this.lineMindElementDataDict.get(key);
                this.rightLineMindElementDataDict.put(line.id, line);
                this.rightTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            this.generalizationMindElementDataDict.keys().forEach(key => {
                let item = this.generalizationMindElementDataDict.get(key);
                if (this.rightMainMindElementDataDict.containsKey(item.value.generalizationContent.targetIds[0])) {
                    item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, item.value.layout);
                    this.rightGeneralizationMindElementDataDict.put(item.value.id, item);
                    for (var index = 0; index < item.children.length; index++) {
                        this.setRightMainNode(item.children[index]);
                    }
                }
            });
    
            this.generalizationLineMindElementDataDict.keys().forEach(key => {
                let line = this.generalizationLineMindElementDataDict.get(key);
                 this.rightGeneralizationLineMindElementDataDict.put(line.id, line);
            });
            this.rightLayout.originalRootTreeNode = this.rootTreeNode;
            this.rightLayout.setDatas(
                this.rootTreeNode,
                this.lineMindElementDataDict,
                this.mainMindElementDataDict,
                this.textElementLineMindElementDataDict,
                this.generalizationLineMindElementDataDict,
                this.generalizationMindElementDataDict, 
                this.explainMindElementDataDict,
                this.mindMapStyleColor, 
                this.mindBGColor,
                this.settingData);
            this.rightLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
            this.rightLayout.globalLayout = this.globalLayout;
            return;
        }

        this.leftRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        this.rightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        this.leftMainMindElementDataDict.put(this.rootTreeNode.value.id, this.leftRootTreeNode);
        this.rightMainMindElementDataDict.put(this.rootTreeNode.value.id, this.rightRootTreeNode);

        let rightCount = Math.ceil(this.rootTreeNode.children.length / 2);
        let leftNodes = new Array();

        for (let index = 0; index < this.rootTreeNode.children.length; index++) {
            let subjectNode = this.rootTreeNode.children[index]
            
            if (this.settingData.branchNodeFreelayout.isValue() && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT &&
                subjectNode.value.isFreeLayoutNode && subjectNode.value.x > 0 && subjectNode.value.y > 0) {
                    if (subjectNode.value.x + subjectNode.value.width/2 > this.rootTreeNode.value.x + this.rootTreeNode.value.width/2) {
                        this.rightRootTreeNode.children.push(subjectNode);
                        this.setRightMainNode(subjectNode);
                        continue
                    } else {
                        this.leftRootTreeNode.children.push(subjectNode);
                        this.setLeftMainNode(subjectNode);
                        continue
                    }
            }
            
            if (index < rightCount) {
                this.rightRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setRightMainNode(this.rootTreeNode.children[index]);
            } else {
                leftNodes.push(this.rootTreeNode.children[index]);
            }
        }

        if (!leftNodes.isEmpty()) {
            let leftNodesCount = leftNodes.length;
            for (let index = 0; index < leftNodesCount; index++) {
                this.leftRootTreeNode.children.push(leftNodes[leftNodesCount - index - 1]);
                this.setLeftMainNode(leftNodes[leftNodesCount - index - 1]);
            }
        }

        this.generalizationMindElementDataDict.keys().forEach(key => {
            let item = this.generalizationMindElementDataDict.get(key);
            if (this.rightMainMindElementDataDict.containsKey(item.value.generalizationContent.targetIds[0])) {
                item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, item.value.layout);
                this.rightGeneralizationMindElementDataDict.put(item.value.id, item);
                for (var index = 0; index < item.children.length; index++) {
                    this.setRightMainNode(item.children[index]);
                }
            } else {
                item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, item.value.layout);
                this.leftGeneralizationMindElementDataDict.put(item.value.id, item);
                for (var index = 0; index < item.children.length; index++) {
                    this.setLeftMainNode(item.children[index]);
                }
            }
        });

        this.generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = this.generalizationLineMindElementDataDict.get(key);
            if (this.leftGeneralizationMindElementDataDict.containsKey(line.parentNodeId)) {
                this.leftGeneralizationLineMindElementDataDict.put(line.id, line);
            } else {
                this.rightGeneralizationLineMindElementDataDict.put(line.id, line);
            }
        });

        this.lineMindElementDataDict.keys().forEach(key => {
            let line = this.lineMindElementDataDict.get(key);
            if (this.leftMainMindElementDataDict.containsKey(line.lineContent.targetId)) {
                this.leftLineMindElementDataDict.put(line.id, line);
                this.leftTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            } else {
                this.rightLineMindElementDataDict.put(line.id, line);
                this.rightTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            }
        });
        this.rightLayout.originalRootTreeNode = this.rootTreeNode;
        this.rightLayout.setDatas(
            this.rightRootTreeNode,
            this.rightLineMindElementDataDict,
            this.rightMainMindElementDataDict,
            this.rightTextElementLineMindElementDataDict,
            this.rightGeneralizationLineMindElementDataDict,
            this.rightGeneralizationMindElementDataDict, 
            this.explainMindElementDataDict,
            this.mindMapStyleColor, this.mindBGColo,
            this.settingData);
        this.rightLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.rightLayout.globalLayout = this.globalLayout;

        this.leftLayout.originalRootTreeNode = this.rootTreeNode;
        this.leftLayout.setDatas(
            this.leftRootTreeNode,
            this.leftLineMindElementDataDict,
            this.leftMainMindElementDataDict,
            this.leftTextElementLineMindElementDataDict,
            this.leftGeneralizationLineMindElementDataDict,
            this.leftGeneralizationMindElementDataDict, 
            this.explainMindElementDataDict,
            this.mindMapStyleColor, this.mindBGColor,
            this.settingData);
        this.leftLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.leftLayout.globalLayout = this.globalLayout;
    }
}

export default FreeTreeLayout
