import Colors from "../../../utils/Colors";
import Strings from "../../../utils/Strings";
import ResourceType from "../../datatype/ResourceType";
import MindElementContent from "./MindElementContent";

/**
 * ProjectName: MindMap
 * Created by tony on 11/4/21
 * Copyright(c) 2020 mindyushu.com
 */
class ResourceContent extends MindElementContent {
    constructor(url="", width, height, styleColor = Colors.white, tintColor = Colors.clear, type = ResourceType.WORD,size = 0,name = "") {
        super(width, height);
        this.url = url;
        this.styleColor = styleColor;
        this.tintColor = tintColor;
        this.type = type;
        this.size = size;
        this.name = name;
    }

    copy() {
        let data = new ResourceContent(this.url, this.width, this.height, this.styleColor, this.tintColor, this.size, this.name);
        data.x = this.x;
        data.y = this.y;
        data.type = this.type;
        return data;
    }

    equal( data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.styleColor != this.styleColor;
        result = result || data.type != this.type;
        result = result || data.tintColor != this.tintColor;
        result = result || data.size != this.size;
        result = result || data.name != this.name;
        result = result ||data.url != this.url;

        return !result;
    }
}

export default ResourceContent