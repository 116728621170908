import MindType from "../../../viewmodel/datatype/MindType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import HashMap from "../../../viewmodel/core/base/HashMap"
import TimeNodeLayoutType from "../../../viewmodel/datatype/TimeNodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 8/10/21
 * Copyright(c) 2020 mindyushu.com
 */

class MindNodeConutCalculation {
    constructor() {
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
    }

    setData(mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
    }
    getConut() {
        let conut = 0;

        let mainMindNodeUnit = this.mainMindNodeUnit;
        if (mainMindNodeUnit != null && 
            mainMindNodeUnit.rootTreeNode != null && 
            !mainMindNodeUnit.rootTreeNode.isEmpty()) {
            conut += this.getNodeCount(mainMindNodeUnit.rootTreeNode);
            let generalizationMindElementDataDictLen = mainMindNodeUnit.generalizationMindElementDataDict.keys().length;
            for (let index = 0; index < generalizationMindElementDataDictLen; index++) {
                let key = mainMindNodeUnit.generalizationMindElementDataDict.keys()[index];
                let node = mainMindNodeUnit.generalizationMindElementDataDict.get(key);
                conut += this.getNodeCount(node);
            }
        }

        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            if (unit != null &&
                unit.rootTreeNode != null &&
                !unit.rootTreeNode.isEmpty()) {
                conut += this.getNodeCount(unit.rootTreeNode);
                let keys = unit.generalizationMindElementDataDict.keys()
                let mainMindElementDataDictArrLen = keys.length;
                for (let index = 0; index < mainMindElementDataDictArrLen; index++) {
                    let key = keys[index];
                    let node = unit.generalizationMindElementDataDict.get(key);
                    conut += this.getNodeCount(node);
                }
            }
        }

        if (this.nodeConnectLineDataDict != null && !this.nodeConnectLineDataDict.isEmpty()) {
            conut += this.nodeConnectLineDataDict.length;
        }

        let doubleBubbleMindNodeUnit = this.doubleBubbleMindNodeUnit;
        if (doubleBubbleMindNodeUnit != null &&
            doubleBubbleMindNodeUnit.rootTreeNode != null &&
            !doubleBubbleMindNodeUnit.rootTreeBrotherNode.isEmpty()) {
            conut += this.getNodeCount(doubleBubbleMindNodeUnit.rootTreeNode);
            conut += this.getNodeCount(doubleBubbleMindNodeUnit.rootTreeBrotherNode);
        }

        let timeMindTypeNodeUnit = this.timeMindTypeNodeUnit;
        if (timeMindTypeNodeUnit != null &&
            timeMindTypeNodeUnit.title != null &&
            !timeMindTypeNodeUnit.title.isEmpty() &&
            timeMindTypeNodeUnit.baseLine != null &&
            !timeMindTypeNodeUnit.baseLine.isEmpty()) {
            let timeMindTypeNodeUnitMainMindElementDataDictLen = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys().length;
            for (let index = 0; index < timeMindTypeNodeUnitMainMindElementDataDictLen; index++) {
                switch (timeMindTypeNodeUnit.baseLine.timeLineContent.timeNodeLayoutType) {
                    case TimeNodeLayoutType.NORMAL:
                    case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                        conut += 3;
                        break;
                    case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                        conut += 2;
                        break;
                    case TimeNodeLayoutType.TITLE_BOTTOM:
                        conut += 1;
                        break;
                    default:
                        conut += 3;
                }
            }
        }
        return conut;
    }

    getNodeCount(node) {
        let conut = node.isEmpty() ? 0 : 1;
        let nodeChildrenLen = node.children.length;
        for (let index = 0; index < nodeChildrenLen; index++) {
            conut += this.getNodeCount(node.children[index]);
        }
        return conut;
    }

}

export default MindNodeConutCalculation