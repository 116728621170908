
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import HashMap from "../../../viewmodel/core/base/HashMap";
import MindType from "../../../viewmodel/datatype/MindType";
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit";
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit";


/**
 * ProjectName: MindMap
 * Created by tony on 2/19/21
 * Copyright(c) 2020 mindyushu.com
 */

class CalculSelectedNodesByRect {
    constructor() {
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);


    }
    setData(mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
    }
    hit(rect) {
        let list = new Array();
        let values = this.mainMindNodeUnit.mainMindElementDataDict.values();
        let length = values.length;
        for (let i = 0; i < length; i++) {
            const node = values[i];
            if (node != null && this.matrixHit(rect, node.value)) {
                list.push(node.value);
            }
        }


        for (let i = 0; i < this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys().length; i++) {
            const key = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()[i];
            let node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
            if (node != null && this.matrixHit(rect, node.value)) {
                list.push(node.value);
            }
        }
        for (let i = 0; i < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; i++) {
            let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[i];
            if (data != null && this.matrixHit(rect, data)) {
                list.push(data);
            }
        }

        for (let i = 0; i < this.mainMindNodeUnit.generalizationMindElementDataDict.keys().length; i++) {
            const key = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()[i];
            let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
            if (node != null && this.matrixHit(rect, node.value)) {
                list.push(node.value);
            }
        }

        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            for (let i = 0; i < unit.mainMindElementDataDict.keys().length; i++) {
                const key = unit.mainMindElementDataDict.keys()[i];
                let node = unit.mainMindElementDataDict.get(key);
                if (node != null && this.matrixHit(rect, node.value)) {
                    list.push(node.value);
                }
            }

            for (let i = 0; i < unit.generalizationMindElementDataDict.keys().length; i++) {
                const key = unit.generalizationMindElementDataDict.keys()[i];
                let node = unit.generalizationMindElementDataDict.get(key);
                if (node != null && this.matrixHit(rect, node.value)) {
                    list.push(node.value);
                }
            }

            for (let i = 0; i < unit.encircleMindElementDataDict.keys().length; i++) {
                const key = unit.encircleMindElementDataDict.keys()[i];
                let line = unit.encircleMindElementDataDict.get(key);
                if (line != null && this.matrixHit(rect, line)) {
                    list.push(line);
                }
            }
        }

        for (let i = 0; i < this.nodeConnectLineDataDict.keys().length; i++) {
            const key = this.nodeConnectLineDataDict.keys()[i];
            let line = this.nodeConnectLineDataDict.get(key);
            if (this.matrixHit(rect, line)) {
                list.push(line);
            }
        }

        for (let i = 0; i < this.mainMindNodeUnit.encircleMindElementDataDict.keys().length; i++) {
            const key = this.mainMindNodeUnit.encircleMindElementDataDict.keys()[i];
            let line = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
            if (line != null && this.matrixHit(rect, line)) {
                list.push(line);
            }
        }

        for (let i = 0; i < this.timeMindTypeNodeUnit.mainMindElementDataDict.keys().length; i++) {
            const key = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys()[i];
            let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHit(rect, node.head)) {
                list.push(node.head);
            }
            if (this.matrixHit(rect, node.title)) {
                list.push(node.title);
            }
            if (this.matrixHit(rect, node.desc)) {
                list.push(node.desc);
            }
        }

        if (this.timeMindTypeNodeUnit.title != null && this.matrixHit(rect, this.timeMindTypeNodeUnit.title)) {
            list.push(this.timeMindTypeNodeUnit.title);
        }
        if (this.timeMindTypeNodeUnit.baseLine != null && this.timeMindTypeNodeUnit.baseLine.timeLineContent != null &&
            this.matrixHit(rect, this.timeMindTypeNodeUnit.baseLine)) {
            list.push(this.timeMindTypeNodeUnit.baseLine);
        }
        return list;
    }

    matrixHit(rect, data) {
        if (data.isHidden) {
            return false;
        }
        let crossArea = Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width,  data.height));
        return crossArea >= new UiUtil().dip2px(5);
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        for (let i = 0; i < freeNodes.length; i++) {
            const unit = freeNodes[i];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.keys().containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }
}

export default CalculSelectedNodesByRect