import MindElementContent from './MindElementContent.js';

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from "../../../utils/Colors"
// import mind.yushu.com.mindmap.viewmodel.datatype.IconLayoutType;
import IconLayoutType from '../../datatype/IconLayoutType'

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class LinkElementContent extends MindElementContent {
    constructor(links = new Array(), width, height, styleColor = Colors.white, tintColor = Colors.clear){
        super(width,height)
        this.links = links;
        this.styleColor = styleColor;
        this.tintColor = tintColor;
        this.layoutType = IconLayoutType.LAYOUT_LEFT;
        this.LinkElementContent(links, width, height, styleColor, tintColor)
    }


    // public LinkElementContent() {
    //     super();
    // }
    
    // public LinkElementContent(ArrayList<String> links, int width, int height) {
    //     this(links, width, height, Colors.white, Colors.clear);
    // }
    
    LinkElementContent(links, width, height, styleColor, tintColor) {
        // super();
        this.links = links;
        this.width = width;
        this.height = height;
        this.styleColor = styleColor;
        this.tintColor = tintColor;
    }

    copy() {
        let data = new LinkElementContent(this.links, this.width, this.height, this.styleColor, this.tintColor);
        data.x = this.x;
        data.y = this.y;
        data.layoutType = this.layoutType;
        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.styleColor != this.styleColor;
        result = result || data.layoutType != this.layoutType;
        result = result || data.tintColor != this.tintColor;
        result = result || data.links.length != this.links.length;
        return !result;
    }
}

export default LinkElementContent