<template>
  <div
    id="LatexList"
    class="element-menu-list"
    v-show="showLaTeXMenuList"
    v-clickoutside="outsideCloseIconCompoenntAccout"
    :style="{ left: newMenuX + 'px', top: newMenuY + 'px' }"
  >
    <template v-if="MindLaTeXMenuType == 'edit'">
      <div
        v-for="(item, index) in showEditDataMenu"
        :key="index"
        class="element-menu-children-list"
      >
        <div
          v-for="each in item"
          :key="each.id"
          @click="checkMenuElement(each.name)"
        >
          <div v-if="each.show" class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name">{{getString(each.name)}}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from '../../../common/lang/language';
import strings from '../../../common/lang/strings';
import Template from '../../../views/template/Template.vue';
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuNodeQuickStyle from "../ComplieRightMenuChild/ComplieChildMenuNodeQuickStyle";

export default {
  name: "ComplieLeftContentMenu",
  props: [
    "showLaTeXMenuList",
    "newMenuY",
    "newMenuX",
    "MindLaTeXMenuType",
    "fullHeight",
    "fullWidth",
  ],
  data() {
    return {
      showEditDataMenu: [
        [
          {
            id: 1,
            name: strings.Mind_Edit_Latex,//"编辑LaTeX"
            iconUrl: require("../../../assets/img/canvasMenu/editLatex.svg"),
            type: "edit",
            show: true
          },
          {
            id: 2,
            name: strings.Mind_Detele_Latex,//"删除LaTeX"
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
            type: "edit",
            show: true
          },
        ],
      ],
      strings:strings,
      muneShapeLeft:160,
      muneShapeTop:0,
      muneStyleLeft:160,
      muneStyleTop:0,
    };
  },
  created(){
    // this.insertPicturesIsNeedMembers()
  },
  mounted(){
    this.getMenuLocation();
  },
  components: {
    ComplieChildMenuForm,
    ComplieChildMenuNodeQuickStyle
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl"]),

    getMenuLocation(){
      if(this.fullWidth - this.newMenuX > 268){
        this.muneLeft = 160;
      }
    },

    getString(i){
      return getString(i)
    },
    outsideCloseIconCompoenntAccout() {
      let obj = {name:'colse'};
      this.$emit("checkMenuLaTeX", obj);
    },
    checkMenuElement(name) {
      let obj = {};
      obj.name = name;
      this.$emit("checkMenuLaTeX", obj);
    },
  },
  watch: {
    showLaTeXMenuList(newVal, old) {
      // console.log('改变了菜单状态',newVal)
    },
    newMenuX:{
      handler(newVal){
        let root = document.getElementById("canvas-father")
        let scrollLeft = root.scrollLeft;
        newVal = newVal - scrollLeft
        if(this.fullWidth - newVal > 428){
          this.muneShapeLeft = 160;
        }else{
          this.muneShapeLeft = -268;
        }
        if(this.fullWidth - newVal > 358){
          this.muneStyleLeft = 230;
        }else{
          this.muneStyleLeft = -128;
        }
      },
      deep:true
    },
    newMenuY:{
      handler(newVal){
        let root = document.getElementById("canvas-father")
        let scrollTop = root.scrollTop;
        newVal = newVal - scrollTop
        if(this.fullHeight - (118 + newVal) >= 581){
          this.muneShapeTop = 0;
        }else{
          this.muneShapeTop = this.fullHeight - (118 + newVal + 581);
        }
        // this.muneStyleTop = 0;
      },
      deep:true
    }
  },
};
</script>

<style lang='less' scoped>
/* 元素菜单列表开始 */
.element-menu-list {
  // width: 150px;
  // height: 300px;
  position: absolute;
  left: 200px;
  top: 300px;
  z-index: 999;
  background-color: #fff;
  user-select: "none";
  box-shadow: 0px 3px 10px rgba(80, 80, 80, 0.16);
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
  .hiddenImgInput {
    display: none;
  }

  .element-menu-children-list {
    border-bottom: 1px solid #ddd8d8;
    .each-menu {
      display: flex;
      align-items: center;
      width: 130px;;
      height: 32px;
      cursor: pointer;
      position: relative;
      .checkMindShape {
        display: none;
        position: absolute;
        background-color: #fff;
      }

      .each-icon {
        width: 17px;
        height: 17px;
        display: block;
        margin-left: 20px;
      }
      .each-icon-name {
        margin-left: 11px;
        font-size: 13px;
        color: #666;
        white-space: nowrap;
      }
      .each-icon-triange {
        // width: 12px;
        // height: 12px;
        display: block;
        margin-left: 140px;
        margin-top: 3px;
        position: absolute;
      }
      .need-member{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: #e0e0e0;
      }
      &:hover .checkMindShape {
        display: block;
        z-index: 999;
      }
    }

    &:last-child {
      border-bottom: transparent;
    }
  }

  .off-chart {
    border-bottom: 1px solid #eee;
  }
}
/* 元素菜单列表结束 */
</style>