<template>
  <div class="task_edit_bg">
  
    <div class="task_edit_content" id="taskEditBg"
    :style="{left:(fullWidth-420)+'px'}"
    v-show="showFindText">
        <div class="find_edit_content_content">          
          <div class="task_edit_content_name">
            <div class="find_edit_content_name_input">
              <a-input @focus="focusInput" @blur="blurInput" @change="findChange" @keyup.enter="findInputKeyEnter" 
               v-model="findText" style="width: 193px;" :placeholder="getString(this.strings.Global_Text_Find)" />
              <div class="find_result_tips" >{{findResultTips}}</div>              
              <a-icon class="find_result_up" type="arrow-up" @click.stop="onFindUp" @focus="focusInput" @blur="blurInput" @keyup.enter="onFindUp"/>
              <a-icon class="find_result_down" type="arrow-down" @click.stop="onFindNext" @focus="focusInput" @blur="blurInput"  @keyup.enter="onFindNext"/>
              <a-icon class="find_close" type="close-circle" @click.stop="onClose"/>
            </div>
            <a-icon class="text_swap" type="swap" @click.stop="onSwap"/>
            <div class="replace_edit_content_name_input">
              <a-input @focus="focusInput" @blur="blurInput" v-model="replaceText" style="width: 193px;" :placeholder="getString(this.strings.Global_Text_Replace)" />
              <div class="replace_edit_content_replace" @click.stop="onreplaceText">{{getString(this.strings.Global_Text_Replace)}}</div>
              <div class="replace_edit_content_replace_all" @click.stop="onreplaceAllText">{{getString(this.strings.Global_Text_Replace_All)}}</div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from '../../../common/lang/language';
import strings from '../../../common/lang/strings';
import Template from '../../../views/template/Template.vue';
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuNodeQuickStyle from "../ComplieRightMenuChild/ComplieChildMenuNodeQuickStyle";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import dataTimeToFormats from '../../../utils/timestampToTime';
import Util from '../../../utils/Util';
import People from '../../../viewmodel/mindelementdata/mindcontent/People';

export default {
  props: [
    "showFindText",
    "dataNode",
    "fullHeight",
    "fullWidth",
  ],
  data() {
    return {
      strings:strings,
      taskWeight:"1",
      taskCompletion:0,
      taskStartTime:dataTimeToFormats.timestampToTime(Util.getCurrentTimeInsecond()),
      taskEndTime:'',
      findText:'',
      replaceText:'',
      dataNodeTask:null,
      nodeName:'',
      node:null,
      findResultTips:'无结果',
      findTextDatas: [],
      findTextIndex: 0
    };
  },
  created(){
    // this.insertPicturesIsNeedMembers()
    this.findResultTips = getString(this.strings.Global_Text_Find_Empty)
  },
  mounted(){
    this.dragDown1();
  },
  components: {
    ComplieChildMenuForm,
    ComplieChildMenuNodeQuickStyle
  },
  methods: {
    ...mapMutations(["setIsShowEditTask","useShortcut", "setIsShowFindTextView"]),
    getString(i){
      return getString(i)
    },
    onClose(){
      this.setIsShowFindTextView(false);
    },
    focusInput(){
      document.getElementById("taskEditBg").removeEventListener("mousedown",this.dragDown);
      this.useShortcut(false);
      // document.onmousemove = null;
      // document.onmouseup = null
    },
    blurInput(){
      this.dragDown1();
      this.useShortcut(true);
    },

    findInputKeyEnter(){
      if (this.findTextDatas == null || this.findTextDatas.length == 0) {
        this.findChange(this.findText)
        return;
      } else {
        this.onFindNext()
      }
    },

    onSwap() {
      let text = this.replaceText;
      this.replaceText = this.findText;
      this.findText = text;
      this.findChange(this.findText)
    },

    findChange(value){
      // var sVal = value.target.value
      let findDatas = EditMindmapVM.findText(this.findText)
      if (findDatas == null || findDatas.length == 0) {
        this.findResultTips = getString(this.strings.Global_Text_Find_Empty)
        this.findTextDatas = []
        this.findTextIndex = 0;
      } else {
        let tips = getString(this.strings.Global_Text_Find_Result)
        tips = tips.replace("&", "1")
        tips = tips.replace("@", findDatas.length)
        this.findResultTips = tips;
        this.findTextIndex = 0;
        this.findTextDatas = findDatas
        EditMindmapVM.onSelectedNode(this.findTextDatas[this.findTextIndex])
      }
    },

    onreplaceText() {
      if (this.findTextDatas == null || this.findTextDatas.length == 0) {
        return
      }
      let selectedNode = EditMindmapVM.getSelectedNode()
      if (this.findTextIndex >= 0 && this.findTextIndex < this.findTextDatas.length) {
        let data = this.findTextDatas[this.findTextIndex]
        if (data.id == selectedNode.value.id) {
          EditMindmapVM.onReplaceNodeText(this.findText, this.replaceText, data.id)
          this.findTextDatas.splice(this.findTextIndex, 1)
          this.setSelectedStatus()
        } else if (!selectedNode.isEmpty()) {
          let isSelected = false;
          for (let index = 0; index < this.findTextDatas.length; index++) {
            let cell = this.findTextDatas[index]
            if (cell.id == selectedNode.value.id) {
              this.findTextIndex = index;
              EditMindmapVM.onReplaceNodeText(this.findText, this.replaceText, cell.id)
              this.findTextDatas.splice(this.findTextIndex, 1)
              this.setSelectedStatus()
              isSelected = true;
              break
            }
          }
          if (!isSelected) {
            EditMindmapVM.onReplaceNodeText(this.findText, this.replaceText, data.id)
            this.findTextDatas.splice(this.findTextIndex, 1)
            this.setSelectedStatus()
          }
        } else {
          EditMindmapVM.onReplaceNodeText(this.findText, this.replaceText, data.id)
          this.findTextDatas.splice(this.findTextIndex, 1)
          this.setSelectedStatus()
        }
      }
      
    },

    onreplaceAllText(value){
      if (this.findText == "" || this.findTextDatas == null || this.findTextDatas.length == 0) {
        return
      }
      EditMindmapVM.onAllReplaceNodeText(this.findText, this.replaceText)
      this.findTextDatas = []
      this.setSelectedStatus()
    },

    onFindNext() {
      if (this.findText != null && this.findText.length > 0 && 
          (this.findTextDatas == null || this.findTextDatas.length == 0)) {
          this.findChange(this.findText)
          return
      }
      if (this.findTextDatas == null || this.findTextDatas.length == 0) {
        this.findResultTips = getString(this.strings.Global_Text_Find_Empty)        
        return
      }
      this.findTextIndex += 1;
      this.setSelectedStatus()
    },

    setSelectedStatus() {
      if (this.findText != null && this.findText.length > 0 && 
          (this.findTextDatas == null || this.findTextDatas.length == 0)) {
          this.findChange(this.findText)
          return
      }
      if (this.findTextDatas == null || this.findTextDatas.length == 0) {
        this.findResultTips = getString(this.strings.Global_Text_Find_Empty)
        return
      }
      if (this.findTextIndex >= this.findTextDatas.length) {
        this.findTextIndex = 0; 
      } else if (this.findTextIndex < 0) {
        this.findTextIndex = this.findTextDatas.length - 1; 
      }
      let tips = getString(this.strings.Global_Text_Find_Result)
      tips = tips.replace("&", this.findTextIndex + 1)
      tips = tips.replace("@", this.findTextDatas.length)
      this.findResultTips = tips;

      EditMindmapVM.onSelectedNode(this.findTextDatas[this.findTextIndex])
    },

    onFindUp() {
      if (this.findTextDatas == null || this.findTextDatas.length == 0) {
        this.findResultTips = getString(this.strings.Global_Text_Find_Empty)
        return
      }
      this.findTextIndex -= 1;
      this.setSelectedStatus()
    },

    dragDown1(){
      document.getElementById("taskEditBg").addEventListener("mousedown",this.dragDown);
    },
    dragDown:function(e){
      window.event ? window.event.cancelBubble = true : e.stopPropagation();
      let oDiv = document.getElementById("taskEditBg");
      let disX = e.clientX - oDiv.offsetLeft;
      let disY = e.clientY - oDiv.offsetTop;
      document.onmousemove = (el)=>{
        let left = el.clientX - disX;
        let top = el.clientY - disY;
        oDiv.style.left = left + 'px';
        oDiv.style.top = top + 'px';
      }

      document.onmouseup = (e)=>{
        document.onmousemove = null;
        document.onmouseup = null;
      }
    },
  },
  watch: {    
    showFindText(newVal){
      if(newVal){
        // this.useShortcut(false);
        if (this.findText != null && this.findText.length > 0) {
          this.findChange(this.findText)
        }
      }else{
        // this.useShortcut(true);
        
      }
    }
  },
};
</script>

<style lang='less' scoped>
.task_edit_bg{
  position: fixed;
  z-index: 999;
}

/* 元素菜单列表开始 */
  .task_edit_content{
    z-index: 999;
    position: absolute;
    width: 400px;
    height: 95px;
    float: right;
    top: 0px;
    left: 20px;
    background-color: rgb(245, 245, 245);
    border-radius: 0 0 6px 6px;
    box-sizing: border-box;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    user-select: none;
    box-shadow: 0px 2px 5px #999;

    .find_edit_content_content{
      position: relative;
      width: 400px;
      height: 85px;
      .find_result_tips {
        width: 99px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2e2e2e;
        position: absolute;
        left: 203px;
        top: 0px;
      }
      .find_result_up{
        position: absolute;
        left: 300px;
        top: 0px;
        top: 4px;
        font-size: 22px;
        color: #222222;
      }
      .find_result_down{
        position: absolute;
        left: 328px;
        top: 4px;
        font-size: 22px;
        color: #222222;
      }
      .find_close{
        position: absolute;
        left: 364px;
        top: 6px;
        font-size: 18px;
        color: #222222;
      }
      .task_edit_content_weight{
        position: relative;
        width: 400px;
        height: 30px;
        margin-top: 14px;
        .task_edit_content_weight_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_weight_select{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_completion{
        position: relative;
        width: 400px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_completion_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_completion_slider{
          width: 179px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
        .task_edit_content_completion_number{
          position: absolute;
          height: 30px;
          line-height: 30px;
          width: 30px;
          margin-left: 276px;
        }
      }
      .task_edit_content_start{
        position: relative;
        width: 400px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_start_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_start_date{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_end{
        position: relative;
        width: 400px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_end_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_end_date{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_name{
        position: relative;
        width: 400px;
        height: 77px;
        margin-top: 8px;
        .task_edit_content_name_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .find_edit_content_name_input{
          width: 380px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 10px;
        }
        .text_swap {
          position: absolute;
          left: 100px;
          top: 33px;
          font-size: 12px;
          color: #666666;
          transform: rotate(90deg);
        }
        .replace_edit_content_name_input{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 10px;
          margin-top: 45px;

          .replace_edit_content_replace {
            width: 45px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 700;
            color: #232323;
            position: absolute;
            left: 203px;
            top: 0px;
            cursor: pointer;
          }

          .replace_edit_content_replace_all {
            width: 80px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 700;
            color: #232323;
            position: absolute;
            left: 253px;
            top: 0px;
            cursor: pointer;
          }
        }
      }
      .task_edit_content_btn{
        position: relative;
        width: 400px;
        height: 30px;
        margin-top: 19px;
        .task_edit_content_btn1{
          width: 68px;
          height: 30px;
          border: 1px solid #EEEEEE;
          opacity: 1;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 30px;
          color: #999999;
          text-align: center;
          position: absolute;
          margin-left: 61px;
          cursor: pointer;
        }
        .task_edit_content_btn1:hover{
          color: #ff7354;
          border-color: #ff7354;
        }
        .task_edit_content_btn2{
          width: 68px;
          height: 30px;
          background: #FD492B;
          opacity: 1;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
          position: absolute;
          margin-left: 179px;
          cursor: pointer;
        }
        .task_edit_content_btn2:hover{
          color: #fff;
          background-color: #ff7354;
        }
      }
    }

  }
/* 元素菜单列表结束 */
</style>