import { render, staticRenderFns } from "./ComplieAddModelRemark.vue?vue&type=template&id=de12a03e&scoped=true"
import script from "./ComplieAddModelRemark.vue?vue&type=script&lang=js"
export * from "./ComplieAddModelRemark.vue?vue&type=script&lang=js"
import style0 from "./ComplieAddModelRemark.vue?vue&type=style&index=0&id=de12a03e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de12a03e",
  null
  
)

export default component.exports