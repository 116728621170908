
import Config from "../../../core/core/calcule/Config"
import HashMap from "../base/HashMap";
import LineLayout from "../../datatype/LineLayout";
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import IdGenerator from "../base/IdGenerator"
import MindElementData from "../../mindelementdata/MindElementData"
import MindElementType from "../../datatype/MindElementType"
import MindDataLayoutDelegate from "../../defer/MindDataLayoutDelegate"
import MindMapStyleColor from "../base/MindMapStyleColor";
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import BoldData from "../../mindelementdata/mindcontent/BoldData";
import SettingData from "../minddata/SettingData";
import NodeLayoutType from "../../datatype/NodeLayoutType"
import BaseLayout from "./BaseLayout";
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation";
import Point from "../base/Point";
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent";
import EncircleShapeType from "../../datatype/EncircleShapeType";
import RectsMergeCalculation from "./RectsMergeCalculation";
import Size from "../base/Size";
import Colors from "../../../utils/Colors";
import CGPoint from "../base/basedata/CGPoint";
import LineOrientation from "../../datatype/LineOrientation";
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType";
import MindElementShapeType from "../../datatype/MindElementShapeType";
/**
 * MindDataLayoutDelegate
 * Created by tony on 2019/12/26
 */
 class RadiateLayout extends BaseLayout {
    constructor() {
        super();
        this.lineSpcase = new UiUtil().dip2px(4);
    }

    initConfig() {
        super.initConfig();

        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee);        
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeHorizontalSpacee);
        this.NodeHorizontalSpacee = this.addSonSubjectSpcaeVertical(Config.NodeHorizontalSpacee * 0.5 + this.getSubjectLineWidth());
    }

    onLayout( isChange) {
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
    }

    setElementsPoint( isChange) {
        if ((!isChange || this.rootTreeNode.value.y <= -1 || this.rootTreeNode.value.x <= -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (this.UiUtil.getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = this.UiUtil.dip2px(10) + left;
        }
        this.rootTreeNode.value.layout = NodeLayoutType.LAYOUT_RADIATE;
        this.rootTreeNode.value.checkSetTextColor()
        this.initConfig();
        let nodeCenterPoint = new Point(this.rootTreeNode.value.x + this.rootTreeNode.value.width / 2, 
                                        this.rootTreeNode.value.y + this.rootTreeNode.value.height + this.lineSpcase);
        let radius = (this.rootTreeNode.value.width + this.NodeHorizontalSpacee)/2
        this.setElementLine(this.rootTreeNode, nodeCenterPoint, isChange, radius);
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setCenterLine();
    }

    setGeneralizationElementsPoint( isChange) {
    }

    setGeneralizationElementLine( node, isChange) {
    }

    getNodeSize( node) {
        
    }

    setNodeElementsPoint( node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }

    setDataPoint( data, isChange) {
        if (data.isHidden || data.id == this.rootTreeNode.value.id) {
            return;
        }
        if (Colors.isClear(data.backgroundColor)) {
            data.backgroundColor = this.mindBGColor;
        }
        let node = this.getNodeById(data.id);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        this.pushCurrentLayoutNode(node);
        data.layout = NodeLayoutType.LAYOUT_RADIATE;

        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);

        if (this.isRootNode(parentNode)) {
            data.y = parentNodeData.y + parentNodeData.height - data.height - this.lineSpcase;
            let preNode = null;
            if (allSiblingsNodesPosition == 0) {
                data.x = parentNodeData.x + parentNodeData.width + this.NodeHorizontalSpacee;
                preNode = parentNode;
            } else {
                preNode = this.getNodeById(siblingsNodeDatas[allSiblingsNodesPosition - 1].id);
                data.x = preNode.value.x + preNode.value.width + this.NodeHorizontalSpacee;
            }
            let textEdgeInsets = this.getTextEdgeInsets(parentNodeData)

            let dataRadius = (data.x + data.width + this.NodeHorizontalSpacee/2 - parentNodeData.x + this.NodeHorizontalSpacee/2) / 2;
            let circleCenterPoint = new CGPoint(parentNodeData.x - this.NodeHorizontalSpacee/2 + dataRadius, 
                                    parentNodeData.y + parentNodeData.height + this.lineSpcase - textEdgeInsets.bottom * 0.8)
            let perDataRadius = (preNode.value.x + preNode.value.width + this.NodeHorizontalSpacee/2 - parentNodeData.x + this.NodeHorizontalSpacee/2) / 2;
            let perCircleCenterPoint = new CGPoint(parentNodeData.x - this.NodeHorizontalSpacee/2 + perDataRadius, 
                                    parentNodeData.y + parentNodeData.height + this.lineSpcase - textEdgeInsets.bottom * 0.8)

            let nodeVTopSpace = this.SonNodeVerticalSpacee;
            let nodeVBottomSpace = this.SonNodeVerticalSpacee;
            let topChildHeight = 0;
            let bottomChildHeight = 0;
            let topNumber = 0;
            let bottomNumber = 0;
            let topSeparaIndex = node.children.length / 2;
            if (topSeparaIndex < Math.ceil(topSeparaIndex)) {
                topSeparaIndex = Math.ceil(topSeparaIndex) - 1
            }
            let list = [];
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index]
                list.push(cell);
                if (topSeparaIndex > index) {//上方
                    topChildHeight = topChildHeight + cell.value.height;
                    topNumber += 1;
                    if (index + 1 >= topSeparaIndex) {
                        list.reverse()
                    }
                } else {//下方
                    bottomChildHeight = bottomChildHeight + cell.value.height;
                    bottomNumber += 1;
                }
            }

            let minSpcae = this.UiUtil.dip2px(20)
            if (allSiblingsNodesPosition == 0) {
                minSpcae = this.UiUtil.dip2px(1)
            } else if (allSiblingsNodesPosition == 1) {
                minSpcae = this.UiUtil.dip2px(4)
            } else if (allSiblingsNodesPosition == 2) {
                minSpcae = this.UiUtil.dip2px(20)
            } else if (allSiblingsNodesPosition == 3) {
                minSpcae = this.UiUtil.dip2px(40)
            } else if (allSiblingsNodesPosition == 4) {
                minSpcae = this.UiUtil.dip2px(60)
            } else if (allSiblingsNodesPosition == 5) {
                minSpcae = this.UiUtil.dip2px(80)
            } else {
                minSpcae = this.UiUtil.dip2px(100)
            }
            nodeVTopSpace = Math.min((dataRadius - topChildHeight - data.height - this.lineSpcase) / (topNumber + 1), minSpcae);
            nodeVBottomSpace = Math.min((dataRadius - bottomChildHeight) / (bottomNumber + 1), minSpcae);
            let lastTopY = data.y
            let lastBottomY = circleCenterPoint.y + this.lineSpcase + 2 
            let perMove = -1
            let perBottomMove = -1
            for (let index = 0; index < list.length; index++) {
                let cell = list[index]
                if (topSeparaIndex > index) {//上方
                    cell.value.y = lastTopY - nodeVTopSpace - this.getNodeHeight(cell.value)
                    lastTopY = cell.value.y
                    let angle = 360 - Util.asind((circleCenterPoint.y - cell.value.y) / dataRadius)
                    let point = Util.getCirclePoint(circleCenterPoint, angle, dataRadius)
                    if (perDataRadius < circleCenterPoint.y - cell.value.y - cell.value.height) {
                        let startAngle = 180 + Util.asind((circleCenterPoint.y - cell.value.y) / dataRadius)
                        let startPoint = Util.getCirclePoint(circleCenterPoint, startAngle, dataRadius)
                        if (perMove > -1 && list[index - 1].value.x - perMove > startPoint.x) {
                            cell.value.x = list[index - 1].value.x - perMove
                        } else {
                            cell.value.x = startPoint.x + ((point.x - startPoint.x) - cell.value.width) / 2
                        }
                    } else {
                        let perAngle = 360 - Util.asind((perCircleCenterPoint.y - cell.value.y - cell.value.height) / perDataRadius)
                        let perPoint = Util.getCirclePoint(perCircleCenterPoint, perAngle, perDataRadius)
                        cell.value.x = perPoint.x + ((point.x - perPoint.x) - cell.value.width) / 2

                        if (index > 0) {
                            perMove = list[index - 1].value.x - cell.value.x;
                        }
                    }                    
                } else {//下方
                    cell.value.y = lastBottomY + nodeVBottomSpace
                    lastBottomY = cell.value.y + this.getNodeHeight(cell.value)

                    let angle = Util.asind((cell.value.y + cell.value.height - circleCenterPoint.y) / dataRadius)
                    let point = Util.getCirclePoint(circleCenterPoint, angle, dataRadius)

                    if (perDataRadius < cell.value.y - circleCenterPoint.y) {
                        let startAngle = 180 - Util.asind((cell.value.y + cell.value.height - circleCenterPoint.y) / dataRadius)
                        let startPoint = Util.getCirclePoint(circleCenterPoint, startAngle, dataRadius)
                        if (perBottomMove > -1 && (list[index - 1].value.x - perBottomMove > startPoint.x &&
                            list[index - 1].value.x - perBottomMove < point.x)) {
                            cell.value.x = list[index - 1].value.x - perBottomMove
                        } else {
                            cell.value.x = startPoint.x + ((point.x - startPoint.x) - cell.value.width) / 2
                        }
                    } else {
                        let perAngle = Util.asind((cell.value.y - perCircleCenterPoint.y) / perDataRadius)
                        let perPoint = Util.getCirclePoint(perCircleCenterPoint, perAngle, perDataRadius)
                        cell.value.x = perPoint.x + ((point.x - perPoint.x) - cell.value.width) / 2

                        if (index > 1) {
                            perBottomMove = list[index - 1].value.x - cell.value.x;
                        }
                    }
                }
                if (Colors.isClear(data.backgroundColor)) {
                    if (Colors.isDarkColor(this.mindBGColor)) {
                        if (Colors.isDarkColor(cell.value.textContent.textColor)) {
                            cell.value.textContent.textColor = Colors.white
                        }
                    } else if (!Colors.isDarkColor(cell.value.textContent.textColor)) {
                        cell.value.textContent.textColor = Colors.black70
                    }
                } else if (Colors.isDarkColor(data.backgroundColor)) {
                    if (Colors.isDarkColor(cell.value.textContent.textColor)) {
                        cell.value.textContent.textColor = Colors.white
                    }
                } else if (!Colors.isDarkColor(cell.value.textContent.textColor)) {
                    cell.value.textContent.textColor = Colors.black70
                }
            }   
            if (Colors.isClear(data.backgroundColor)) {
                if (Colors.isDarkColor(this.mindBGColor)) {
                    if (Colors.isDarkColor(data.textContent.textColor)) {
                        data.textContent.textColor = Colors.white
                    }
                } else if (!Colors.isDarkColor(data.textContent.textColor)) {
                    data.textContent.textColor = Colors.black70
                }
            } else if (Colors.isDarkColor(data.backgroundColor)) {
                if (Colors.isDarkColor(data.textContent.textColor)) {
                    data.textContent.textColor = Colors.white
                }
            } else if (!Colors.isDarkColor(data.textContent.textColor)) {
                data.textContent.textColor = Colors.black70
            }   
            this.setElementLine(node, circleCenterPoint, isChange, dataRadius);
        } else {
            if (!parentNode.isEmpty()) {
                let grandpa = this.getNodeById(parentNode.value.parentNodeId)
                if (!grandpa.isEmpty() && !this.isRootNode(grandpa)) {
                    node.value.isHidden = true
                }
            }
            this.setElementLineHidde(node)
        }
       
    }

    setElementLineHidde(node) {
        let lineData = this.getLineData(node);
        if (!lineData.isEmpty()) {
            lineData.isHidden = true;
        }
    }

    setElementLine(node, nodeCenterPoint, isChange, radius) {
        if (node.value.isHidden) {
            return;
        }
        let data = node.value;
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (this.isGeneralizatioData(data)) {
            return;
        }
        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        let cornerRadius = radius;
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_RADIATE;
        if (data.type == MindElementType.SUBJECT ||
            data.type == MindElementType.MAIN_SUBJECT ||
            data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            lineData.y = nodeCenterPoint.y - cornerRadius;
            lineData.x = nodeCenterPoint.x - cornerRadius;
            lineData.height = cornerRadius * 2;
            lineData.width = cornerRadius * 2;
            let startPoint = new Point((lineData.width), (lineData.height));
            let endPoint = new Point(0, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0xff333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
    
            lineData.lineContent.orientation = LineOrientation.LEFT;
            lineData.lineContent.lineLayout = lineLayout;
            lineData.lineContent.cornerRadius = cornerRadius;
        } else {
            lineData.type = MindElementType.SON_LINE;

            lineData.y = nodeCenterPoint.y - cornerRadius;
            lineData.x = nodeCenterPoint.x - cornerRadius;
            lineData.height = cornerRadius * 2;
            lineData.width = cornerRadius * 2;

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            curveStartPoint = new Point(lineData.width, lineData.height);
            curveEndPoint = new Point(0,  0);
            straightEndPoint = new Point(0, 0);

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
            lineData.lineContent.orientation = LineOrientation.LEFT;
            lineData.lineContent.lineLayout = lineLayout;
            lineData.lineContent.cornerRadius = cornerRadius;
        }
        
        
        if (lineData.lineContent.strokeColor == Colors.black60) {
            lineData.lineContent.strokeColor = lineData.lineContent.color
        }
        
        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineNodeContent.strokeColor = referenceLine.lineContent.strokeColor;
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (referenceLine.lineContent.lineWidth == 0 &&
                        !grandpaNode.isEmpty() &&
                        grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                } else {
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                }
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.strokeColor = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                    parentNode = this.originalRootTreeNode;
                }
                // for (var index = 0; index < parentNode.children.length; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % this.settingData.lineColor[index];
                //         lineNodeContent.strokeColor = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.clear;
            lineData.borderColor = Colors.clear;
            lineData.borderWidth = 0;
        }
        if (Colors.isClear(node.value.backgroundColor)) {
            lineData.lineContent.color = this.mindBGColor;
            if (Colors.isClear(lineData.lineContent.strokeColor)) {
                if (Colors.isDarkColor(lineData.lineContent.color)) {
                    lineData.lineContent.strokeColor = Colors.white
                } else {
                    lineData.lineContent.strokeColor = Colors.black95
                }
            }
        } else {
            lineData.lineContent.color = node.value.backgroundColor;
        }
        this.setLineThicken(lineData);
    }

    setCenterLine() {
        let data = this.rootTreeNode.value;
        if (data.isHidden) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getCustomLineData(this.rootTreeNode);

        lineData.parentNodeId = this.rootTreeNode.value.parentNodeId;
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(this.rootTreeNode.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_RIGHT;
        lineData.type = MindElementType.LAYOUT_CUSTOM_LINE;
        lineData.isFreeTreeNode = false
        let textEdgeInsets = this.getTextEdgeInsets(this.rootTreeNode.value)
        lineData.y = this.rootTreeNode.value.y + this.rootTreeNode.value.height + this.lineSpcase - textEdgeInsets.bottom * 0.8;

        lineData.x = this.rootTreeNode.value.x - this.NodeHorizontalSpacee / 2;
        // let rect = new NodesRectCalculation().calcule(this.rootTreeNode);
        let lineWidth = this.getTreeHeight(this.rootTreeNode)

        lineData.width = lineWidth;
        lineData.height = 1;
        let startPoint = new Point(0, 0);
        let endPoint = new Point(lineData.width, 0);

        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        lineData.lineContent.lineLayout = LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE;

        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;
            let childLine = new MindElementData()
            if (this.rootTreeNode.children.length > 0) {
                childLine = this.getNodeLine(this.rootTreeNode.children[0].value.id);
            }
            if (!childLine.isEmpty() && childLine.lineContent != null) {
                lineNodeContent.color = childLine.lineContent.strokeColor;
                lineNodeContent.lineWidth = childLine.lineContent.lineWidth * 1.5;
            } else {
                lineNodeContent.color = this.rootTreeNode.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth() * 1.5
                }                
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        }
        
        this.setLineThicken(lineData);
    }

    getTreeHeight(node) {
        if ((node.value.id == IdGenerator.INVALID_ID) || node.value.isHidden) {
            return 0;
        }
        if (this.isRootNode(node)) {
            let width = this.NodeHorizontalSpacee/2 + node.value.width + this.NodeHorizontalSpacee/2;
            for (let index = 0; index < node.children.length; index++) {
                const element = node.children[index];
                width = width + this.NodeHorizontalSpacee/2 + element.value.width + this.NodeHorizontalSpacee/2
            }
            return width;
        }
        let parentNode = this.getNodeById(node.value.parentNodeId)
        if (this.isRootNode(parentNode)) {
            let height = node.value.height + this.lineSpcase;
            if (node.children.length > 1) {
                height = height + this.lineSpcase;
            }
            for (let index = 0; index < node.children.length; index++) {
                const element = node.children[index];
                height = height + element.value.height + this.SonNodeVerticalSpacee
            }
            return height;
        } else {
            return node.value.height;
        }
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }

        if (this.isRootNode(node)) {
            return this.getTreeHeight(node);
        }
        return node.value.height;
    }

    changeLayoutFromRadiate(node) {
        if (!node.isEmpty()) {
            if (node.value.mindElementShape != node.value.mindElementOriginalShape) {
                node.value.mindElementShape = node.value.mindElementOriginalShape;
            }
            node.value.checkSetTextColor(this.mindBGColor)
            MindElementCalculation.set(node.value).caluleTextForData().calcule();
        }
        let lineKeys = this.lineMindElementDataDict.keys();
        let length = lineKeys.length;
        for (let index = 0; index < length; index++) {
            let key = lineKeys[index]
            let data = this.lineMindElementDataDict.get(key);
            if (data.layout == NodeLayoutType.LAYOUT_RADIATE) {
                data.lineContent.color = data.lineContent.strokeColor
            }
        }
        let nodeCount = node.children.length;
        for(var index = 0; index < nodeCount; index++){
            let item = node.children[index];
            if (item.value.isHidden && node.value.hiddenNumber == 0) {
                item.value.isHidden = false
            }
            this.changeLayoutFromRadiate(item);
        }
    }

    changeLayoutToRadiate(node) {
        if (!node.isEmpty()) {
            node.value.mindElementOriginalShape = node.value.mindElementShape;
            node.value.mindElementShape = MindElementShapeType.Corner;
            if (node.value.mindElementOriginalShape != MindElementShapeType.Corner) {
                MindElementCalculation.set(node.value).calcule();
            }
        }
        let childrenLen = node.children.length
        for (let index = 0; index < childrenLen; index++) {
            let item = node.children[index]
            this.changeLayoutToRadiate(item);
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_RADIATE;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default RadiateLayout