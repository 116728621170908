// package mind.yushu.com.mindmap.main.book.data;

import Colors from "../../../utils/Colors";
import Strings from "../../../utils/Strings";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";

// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.utils.Strings;
// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.utils.Util;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindHistoryCellType;

/**
 * ProjectName: MindMap
 * Created by tony on 6/23/21
 * Copyright(c) 2020 mindyushu.com
 */

class BookData {
    constructor () {
        // this.UiUtil = new UiUtil();
        // this.width = this.UiUtil.dip2px(95);
        // this.height = this.UiUtil.dip2px(117);
        // this.color = Colors.red;
        // this.url = "";
        // this.sheetNumber = 2;
        // this.name = "超级记忆术";
        // this.subtitle = "超级记忆术，脑开发";
        // this.time = Util.getCurrentTime();
        // this.id = "";


        this.UiUtil = new UiUtil();
        this.width = this.UiUtil.dip2px(95);
        this.height = this.UiUtil.dip2px(117);
        this.color = Colors.red;
        this.url = "";
        this.sheetNumber = 0;
        this.name = "";
        this.subtitle = "";
        this.time = Util.getCurrentTime();
        this.id = "";
    }


    isEmpty() {
         return new Strings().isEmpty(this.id);
    }
}

export default BookData
