// package mind.yushu.com.mindmap.core.dataformat;

// import com.google.gson.Gson;

// import java.util.ArrayList;
// import java.util.HashMap;
// import java.util.List;
// import java.util.Map;

// import mind.yushu.com.mindmap.core.basemode.GsonDataTypeAdaptor;
// import mind.yushu.com.mindmap.core.calcule.Config;
// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.viewmodel.core.base.IdGenerator;
// import mind.yushu.com.mindmap.viewmodel.datatype.DeviceType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindBGStripesType;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNodeUnit;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.TimeMindTypeNode;
import GsonDataTypeAdaptor from "../../core/basemode/GsonDataTypeAdaptor"
import Config from "../../core/calcule/Config"
import Colors from "../../../utils/Colors"
import IdGenerator from "../../../viewmodel/core/base/IdGenerator"
import DeviceType from "../../../viewmodel/datatype/DeviceType"
import MindBGStripesType from "../../../viewmodel/datatype/MindBGStripesType"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import TimeMindTypeNode from "../../../viewmodel/mindelementdata/TimeMindTypeNode"

import HashMap from "../../../viewmodel/core/base/HashMap"
import MindBaseFormat from "./MindBaseFormat"
import MindFormatCheck from "./MindFormatCheck"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import MindDateFormat from "./MindDateFormat"
import { getMachineId } from "../../../common/netWork/base"
import OrientationType from "../../../viewmodel/datatype/OrientationType"
import SettingData from "../../../viewmodel/core/minddata/SettingData"
import BoldData from "../../../viewmodel/mindelementdata/mindcontent/BoldData"
import LatexContent from "../../../viewmodel/mindelementdata/mindcontent/LatexContent"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/24
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineMindDateFormat extends MindBaseFormat {
    constructor() {
        super()
        this.title; //顶部title node
        this.baseLine; //基础线 data
        this.mainMindElement = new Array();
        this.mindBGColor = Colors.white;
        this.settingData = new SettingData();
        this.nodeConnectLinet = new Array();//节点连接线
        this.mindBGStripesType = MindBGStripesType.NonExistent;
        this.freeNodes = new Array();//自由节点
        this.globalLayout = NodeLayoutType.LAYOUT_VERTICAL;
    }


    setDatas(title,
        baseLine,
        mainMindElementDataDict,
        freeNodes,
        layout) {
        this.title = title;
        this.baseLine = baseLine;
        this.globalLayout = layout;
        this.mainMindElement = mainMindElementDataDict.values();
        this.freeNodes = freeNodes;
        this.uuid = getMachineId();
        return this;
    }

    toJSONString() {
        this.deviceType = DeviceType.WEB;
        this.nodeConnectLineControlPointMap = {};
        this.mindOriginDataRelativeRootInfo = {};
        this.freeNodes.forEach(unit => {
            unit.nodeConnectLineControlPointMap = {};
            unit.mindOriginDataRelativeRootInfo = {};
        });
        let nodeStr = JSON.stringify(this); 
        return nodeStr;
    }

    format(vaule, originalDataMap) {
        let mind = JSON.parse(vaule) //vaule.toJsonString();
        let timeLineMindDateFormat = this.deserializationData(mind)
        if (timeLineMindDateFormat != null && timeLineMindDateFormat.title != null) {
            timeLineMindDateFormat.checkDate(originalDataMap);
            return timeLineMindDateFormat;
        }
        return new TimeLineMindDateFormat();
    }

    setNodeToDictStructure(checkData, node) {
        this.resetDataInCheckData(checkData, node.value);
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeToDictStructure(checkData, node.children[index]);
        }
    }

    resetDataInCheckData(checkData, data) {
        checkData.checkData(data);
    }

    resetData(checkData) {
        this.resetDataInCheckData(checkData, this.title);
        this.resetDataInCheckData(checkData, this.baseLine);
        for (let index = 0; index < this.mainMindElement.length; index++) {
            this.resetDataInCheckData(checkData, this.mainMindElement[index].head);
            this.resetDataInCheckData(checkData, this.mainMindElement[index].title);
            this.resetDataInCheckData(checkData, this.mainMindElement[index].desc);
        }

        for (let index = 0; index < this.freeNodes.length; index++) {
            this.setNodeToDictStructure(checkData, this.freeNodes[index].rootNode);
            let unit = this.freeNodes[index]
            for (let index = 0; index < unit.rootLine.length; index++) {
                this.resetDataInCheckData(checkData, unit.rootLine[index]);
            }
            for (let index = 0; index < unit.generalizationLine.length; index++) {
                this.resetDataInCheckData(checkData, unit.generalizationLine[index]);
            }
            for (let index = 0; index < unit.generalizationNode.length; index++) {
                this.resetDataInCheckData(checkData, unit.generalizationNode[index].value);
            }

            for (let index = 0; index < unit.encircle.length; index++) {
                this.resetDataInCheckData(checkData, unit.encircle[index]);
            }
            unit.explainData.forEach(data => {
                this.resetDataInCheckData(checkData, data);
            });
        }
        for (let index = 0; index < this.nodeConnectLinet.length; index++) {
            this.resetDataInCheckData(checkData, this.nodeConnectLinet[index]);
        }
    }

    checkDate(originalDataMap) {
        if (this.deviceType == DeviceType.UNKNOWN) {
            this.deviceDensity = 2;
        }
        let checkData = new MindFormatCheck(this.deviceType, this.deviceDensity, 
            new LineMindTypeNode(this.title), 
            this.nodeConnectLinet, this.version, this.freeNodes, this.uuid, this.settingData, originalDataMap);
        checkData.setIsOriginCutValue(true);
        this.resetData(checkData);
        this.checkBaseLine();
        this.nodeConnectLineControlPointMap = checkData.nodeConnectLineControlPointMap;
        this.mindOriginDataRelativeRootInfo = checkData.mindOriginDataRelativeRootInfo;

        checkData.setIsOriginCutValue(false);
        this.resetData(checkData);
    }

    getMindDateFormat() {
        return new MindDateFormat();
    }

    deserializationTimeLineMindDateFormat(mind) {
        let data = new TimeLineMindDateFormat();
        for (var key in mind) {
            let keyValue = mind[key]
            data[key] = keyValue
            if (key == "title") {
                let title = this.deserializationLineMindElementData(keyValue)
                data[key] = title
            } else if (key == "baseLine") {
                let baseLine = this.deserializationLineMindElementData(keyValue)
                data[key] = baseLine
            } else if (key == "nodeConnectLinet" && keyValue != null) {
                let nodeConnectLinet = new Array();
                let nodeConnectLinetSize = keyValue.length
                for (let index = 0; index < nodeConnectLinetSize; index++) {
                    nodeConnectLinet.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = nodeConnectLinet
            } else if (key == "nodeConnectLineControlPointMap") {
                data[key] = new HashMap()
            } else if (key == "mainMindElement" && keyValue != null) {
                let mainMindElement = new Array()
                let mainMindElementSize = keyValue.length
                for (let index = 0; index < mainMindElementSize; index++) {
                    let timeMindTypeNode = new TimeMindTypeNode();
                    let timeMindTypeNodeData = keyValue[index];
                    for (var timeMindTypeNodeDataKey in timeMindTypeNodeData) {
                        let timeMindTypeNodeDataKeValue = timeMindTypeNodeData[timeMindTypeNodeDataKey]
                        timeMindTypeNode[timeMindTypeNodeDataKey] = timeMindTypeNodeDataKeValue
                        if (timeMindTypeNodeDataKey == "head" || 
                            timeMindTypeNodeDataKey == "title" ||
                            timeMindTypeNodeDataKey == "desc") {
                            timeMindTypeNode[timeMindTypeNodeDataKey] = this.deserializationLineMindElementData(timeMindTypeNodeDataKeValue)
                        }
                    }
                    mainMindElement.push(timeMindTypeNode)
                }
                data[key] = mainMindElement
            } else if((key == "mindBGStripesType" || key == "globalLayout")&& keyValue !== null && typeof keyValue == "string" ){
                data[key] = parseInt(keyValue)
            } else if (key == "latexContent" && keyValue != null) {
                let latexContent = new LatexContent();
                for (var latexContentKey in keyValue) {
                    let latexContentValue = keyValue[latexContentKey];
                    latexContent[latexContentKey] = latexContentValue;

                }
                data[key] = latexContent
            } else if (key == "settingData" && keyValue != null) {
                let settingData = new SettingData()
                let settingDataValue = keyValue
                for (var keyDataValueKey in settingDataValue) {
                    if (keyDataValueKey == "freeNodeMoveAdsorbent" || 
                        keyDataValueKey == "branchNodeFreelayout") {
                            let  boldData = new BoldData()
                            let boldDataValue = settingDataValue[keyDataValueKey]
                            for (var boldDataKey in boldDataValue) {
                                boldData[boldDataKey] = boldDataValue[boldDataKey]
                            }
                            settingData[keyDataValueKey] = boldData
                    } else {
                        settingData[keyDataValueKey] = settingDataValue[keyDataValueKey]
                    }
                }
                data[key] = settingData
            } else if (key == "explainData") {
                let explainData = new Array();
                let explainDataSize = keyValue.length
                for (let index = 0; index < explainDataSize; index++) {
                    explainData.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = explainData
            }
        }
        return data
    }

    deserializationData(mind) {
        let data = this.deserializationTimeLineMindDateFormat(mind);
        let freeNodes = this.deserializationFreeNodes(mind)
        data.freeNodes = freeNodes;
        return data
    }

    checkBaseLine() {
        if (this.baseLine == null ||
            this.baseLine.isEmpty() ||
            this.baseLine.timeLineContent == null) {
            return;
        }
        if (this.version < 4 && this.baseLine.timeLineContent.orientationType == OrientationType.HORIZONTAL_S) {
            this.baseLine.timeLineContent.isShowArrow = true;
        }
    }
}
export default TimeLineMindDateFormat
