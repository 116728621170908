import strings from "../../../common/lang/strings";
import Colors from "../../../utils/Colors";
import Strings from "../../../utils/Strings";
import UiUtil from "../../../utils/UiUtil";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import Config from "./Config";
import StatisticsCalculation from "./elementCalculation/StatisticsCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/10/19
 * Copyright(c) 2020 mindyushu.com
 */

class OutlineMindElementCalculation {
    constructor(data) {
        this.maxHeight = new UiUtil().dip2px(800);//定义最大高度
        this.minHeight = 20;//定义最小高度
        this.maxWidth = new UiUtil().getScreenWidth();//定义最大宽度
        this.minWidth = 10;//定义最小宽度

        this.data = data;
        this.imageSpace = new UiUtil().dip2px(8);
        this.imageWidht = Config.MindOutlineImageSize;
        this.imageHeight = Config.MindOutlineImageSize;
    }

    // OutlineMindElementCalculation(data) {
    //     this.data = data;
    // }

    calcule() {
        let iconElementContents = this.data.iconElementContents;
        let imageContent = this.data.imageContent;
        let isIncludeLink = this.data.isContainLinkContent();
        let isIncludeRemarks = this.data.isContainRemarksContent();
        let isIncludeIcon = iconElementContents != null && iconElementContents.length > 0;
        let isIncludeImage = imageContent != null;
        let isStatisticsContent = this.data.isStatisticsContent();
        
        let iconCount = 0;
        if (isIncludeIcon) {
            iconCount += iconElementContents.length;
        }
        if (isIncludeLink) {
            iconCount += 1;
        }
        if (isIncludeRemarks) {
            iconCount += 1;
        }

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        if (textMindElementContent == null) {
            return;
        }
        textMindElementContent.text = new Strings().lineFeed(textMindElementContent.text)
        let size = new CGRect(0, 0, iconCount * this.imageWidht, this.imageHeight);
        let contentWidth = size.width();
        let contentHeight = size.height();
        if (this.data.type != MindElementType.MAIN_SUBJECT) {
            contentWidth = contentWidth + (this.data.level - 1) * this.imageWidht;
        }
        if (isStatisticsContent) {
            new StatisticsCalculation(this.data).calculate();
        } else if (isIncludeImage) {
            let imageHeight = new UiUtil().getScreenWidth() - contentWidth - this.imageSpace * 2;
            this.data.width = new UiUtil().getScreenWidth();
            this.data.height = imageHeight + this.imageSpace + textMindElementContent.height;
            if (this.data.type == MindElementType.MAIN_SUBJECT) {
                imageContent.x = (this.data.width - imageHeight) / 2;
            } else {
                imageContent.x = contentWidth;
            }
            imageContent.y = this.imageSpace;
            textMindElementContent.x = contentWidth;
            textMindElementContent.y = (this.data.height - textMindElementContent.height) - this.imageSpace / 2;
        } else {
            this.data.width = new UiUtil().getScreenWidth();
            this.data.height = Math.max(contentHeight, textMindElementContent.height);

            textMindElementContent.x = contentWidth;
            textMindElementContent.y = (this.data.height - textMindElementContent.height) / 2;
        }
    }


    caluleText() {
        let iconElementContents = this.data.iconElementContents;
        let imageContent = this.data.imageContent;
        let isIncludeLink = this.data.isContainLinkContent();
        let isIncludeRemarks = this.data.isContainRemarksContent();
        let isIncludeIcon = iconElementContents != null && iconElementContents.length > 0;
        let isIncludeImage = imageContent != null;
        let iconCount = 0;
        if (isIncludeIcon) {
            iconCount += iconElementContents.length;
        }
        if (isIncludeImage) {
            iconCount += 1;
        }
        if (isIncludeLink) {
            iconCount += 1;
        }
        if (isIncludeRemarks) {
            iconCount += 1;
        }

        let text = "";
        let textStrikethrough = false;
        let textBold = false;
        let textFontSize = 18;
        let textColor = Colors.black60;
        let textItalics = false;
        let textMaxWidht =  9999999 //Integer.MAX_VALUE;
        let textMaxHeight =  9999999 //Integer.MAX_VALUE;
        if (this.data.type == MindElementType.MAIN_SUBJECT) {
            textMaxHeight = 24;
        } else {
            textMaxWidht = (new OutlineMindElementCalculation().maxWidth - this.imageWidht - (iconCount) * this.imageWidht - (this.data.level - 1) * this.imageWidht - Config.MindInputText_lineFragmentPadding * 2 - Config.MindOutlineRowRightMargin) - this.getTextEdgeInsets().left - this.getTextEdgeInsets().right;
        }

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
            textStrikethrough = this.data.textContent.textStrikethrough;
            textFontSize = this.data.type == MindElementType.MAIN_SUBJECT ? Config.OutlineMainTextSize : Config.OutlineTextSize;
            textColor = this.data.textContent.textColor;
            textItalics = this.data.textContent.textItalics;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
            textStrikethrough = this.data.generalizationContent.textStrikethrough;
            textFontSize = Config.OutlineGeneralizationTextSize;
            textColor = this.data.generalizationContent.textColor;
            textItalics = this.data.generalizationContent.textItalics;
        }
        return this;
        
    }

    getTextTop() {
       
    }

    getTextEdgeInsets() {
        if (this.data.type == MindElementType.MAIN_SUBJECT) {
            return Config.MainOutlineInputUIEdgeInsets;
        } else if (this.data.type == MindElementType.CONTENT_GENERALIZATION) {
            return Config.GeneralizationOutlineInputUIEdgeInsets;
        } else {
            return Config.OutlineInputUIEdgeInsets;
        }
    }

    getTextLeft() {
        return this.getTextEdgeInsets().left;
    }

    getTextRectSize(text, fontSize, textBold, size) {
        
    }

    caluleTextCGRect() {
        let text = "";
        let textStrikethrough = false;
        let textBold = false;
        let textFontSize = 18;
        let textColor = Colors.black60;
        let textItalics = false;

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
            textStrikethrough = this.data.textContent.textStrikethrough;
            textBold = this.data.textContent.textBold;
            textColor = this.data.textContent.textColor;
            textItalics = this.data.textContent.textItalics;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
            textStrikethrough = this.data.generalizationContent.textStrikethrough;
            textBold = this.data.generalizationContent.textBold;
            textFontSize = this.data.generalizationContent.textFontSize;
            textColor = this.data.generalizationContent.textColor;
            textItalics = this.data.generalizationContent.textItalics;
        }
        text = "AbcdefjJG";
        if (textMindElementContent == null) {
            return new CGRect(0, 0, 0, 0);
        }
    }
}
export default OutlineMindElementCalculation
