
import BaseElementView from "./BaseElementView";
import Point from "../../../viewmodel/core/base/Point";
import Size from "../../../viewmodel/core/base/Size";
import HashMap from '../../../viewmodel/core/base/HashMap'
import TextCalculation from '../../../core/core/calcule/elementCalculation/TextCalculation'
import MindElementIconCellType from './delegate/MindElementIconCellType'
import Colors from "../../../utils/Colors";
import Strings from "../../../utils/Strings"
import UiUtil from "../../../utils/UiUtil"
import IconLayoutType from "../../../viewmodel/datatype/IconLayoutType";
import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import IconElementContent from "../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import TextContent from "../../../viewmodel/mindelementdata/mindcontent/TextContent"
import MindElementIconCell from "./MindElementIconCell" //生成和设置img图标
//图片类型枚举
import ImageShapeType from '../../../viewmodel/datatype/ImageShapeType'
import ShapeImageView from './ShapeImageView'
import LinePath from "../../../viewmodel/core/base/LinePath";   //用来拼接path中d标签的线
import Config from "../../../core/core/calcule/Config";
import imageToStyle from "../../../utils/ImageToolkit";
import HttpServerConfig from "../../../common/HttpServerConfig";
import MindNodeUpNumberView from "./MindNodeUpNumberView";
import MindElementIconOutlineCell from "./MindElementIconOutlineCell";
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import ExplainLabelView from "./ExplainLabelView";
import MindElementAlignmentType from "../../../viewmodel/datatype/MindElementAlignmentType";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import indexDataStorage from "../../../core/store/index";
import TaskLabelView from "./TaskLabelView";
import katex from "katex"
import ResourceType from "../../../viewmodel/datatype/ResourceType";
import MindCopyManager from "../../../core/core/common/MindCopyManager";
import Util from "../../../utils/Util";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import LoadLocalXJMind from "../../../viewmodel/core/tools/mapfile/LoadLocalXJMind";
import { setTimeout } from "core-js";
import MindType from "../../../viewmodel/datatype/MindType";
import FlowChartDirection from "../../../viewmodel/datatype/FlowChartDirection";

class BaseInputView extends BaseElementView {
    constructor(viewCanvs, data, vuethis) {
        super(viewCanvs, data, vuethis);
        this.mindOrderContent;
        this.orderTextView; //序号
        this.inputTextView; //包裹文字
        this.mindContent; //包裹文字还有背景等的foreignObject
        this.explainLabelView;
        this.inputGesture;
        this.subScript; //下标的双击事件。
        this.titleDoubleClick;
        this.onClickListener;
        this.onLongClickListener;
        this.imgClick;  //点击添加图片的函数
        this.addNodeDown;  
        this.imgDblclick; //双击添加图片函数
        this.addNodeClickTime = 0
        this.rightClick; //右击显示菜单事件
        this.completionClick;//点击任务进度相关事件

        this.CLICK_INTERVAL_TIME = 200;
        this.mindNodeUpNumberViewWidth = new UiUtil().dip2px(24); //隐藏子节点的数字按钮的直径
        this.mindNodeUpNumberView;  //展示子节点的按钮
        this.moreTap;
        this.icons;
        this.linkIcon;
        this.remarksIcon;
        this.moreIcon;//更多链接图标附件等
        this.resourceIcon;//附件
        this.isShowMore = false;//是否显示更多图标
        this.connectMapIcon;
        this.deletage;
        this.selectedView = null;
        this.imageViewTap;  //保存图片的foreignObject
        this.imageView;     //图片的img标签
        this.imageVideoViewTap;  //保存图片的foreignObject
        this.imageVideoView;     //图片的img标签
        this.playIconImg;   //播放按钮的img标签
        this.videoPlayButton;//按钮
        this.lastClickTime = 0;
        this.mindMoveFunctin;
        this.UiUtil = new UiUtil();

        this.taskLabelView;//任务相关

        this.latexView;//latex公式相关
        this.lastTextContent = null;
        this.mouseoutHiddenIcon = null;
        this.mouseoverShowIcon = null;
        this.titleMindContent = null;
        this.titleInputTextView = null;
    }

    setBackgroundView() {
        if (this.backgroundView == null) {
            return;
        }
    }

    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.duration = duration;
        if (this.mindNodeBackgroundView != null) {
            this.mindNodeBackgroundView.setAnimation(animation, duration, 1)
        }
        this.isAnimation = false;
    }

    initView() {
        super.initView();
        if (this.mindElementData == null) {
            return;
        }
        this.mindContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        this.mindContent.setAttribute("class", "textMindElementViewContent")

        this.initExplainLabelView();
        this.initInput();
        this.setText();        
        this.setIcons();
        this.setImage();
        this.setLinkIcon();
        this.setRemarksIcon();
        this.setConnectMapIcon();
        this.setMindNodeUpNumberView();
        this.setSelected(false);
        // this.setAddIcon();
        this.mousemoveMind();
        this.setTaskLabelView();

        this.setLatext();

        this.setMoreIcon();
        this.setResourceIcon();


        // this.setVideoImage();

    }

    refresh() {
        if (!this.isRefresh && !this.isSetHitStyle && !this.isClearText) {
            return;
        }
        this.initExplainLabelView();
        this.setOrderText();
        this.setText()
        this.setTitleInput()
        this.setImage();
        this.setIcons();
        this.setLinkIcon();
        this.setRemarksIcon();
        this.setConnectMapIcon();
        this.setMindNodeUpNumberView();
        this.setTaskLabelView();
        this.setLatext();
        this.setMoreIcon();
        this.setResourceIcon();
        this.refreshLayout();
        this.setAddIcon();
        super.refresh();
        this.isClearText = false;
    }

    refreshLayout() {
        if (this.mindElementData == null || this.context == null) {
            return;
        }

        this.setViewRect(this.mindElementData.width, this.mindElementData.height,
            this.mindElementData.x, this.mindElementData.y);
        this.setText();
        this.refreshBackgroundLayout();
        this.setIconsViewLayout();
        this.setImageViewLayout();
        this.setLinkIconsViewLayout();
        this.setRemarksIconsViewLayout();
        this.setConnectMapIconsViewLayout();
        this.setOrderTextViewLayout();
        this.setInputTextViewLayout();
        this.setTitleInputTextViewLayout();
        this.setSelectedLayer(this.mindElementData.width, this.mindElementData.height);
        this.setMindNodeUpNumberViewLayout();
        this.setExplainLabelViewLayout();
        this.setTaskContentViewLayout();
        this.setLatextViewLayout();

        this.setMoreIconsViewLayout();
        this.setResourceIconViewLayout();

    }

    setLatextViewLayout() {
        if (this.latexView == null || !this.mindElementData.isContainLatextContent()) {
            return;
        }
        let latexContent = this.mindElementData.latexContent;

        let width = latexContent.width;
        let height = latexContent.height;

        this.latexView.setAttribute('width', width);
        this.latexView.setAttribute('height',height);
        this.latexView.innerHTML = "";
        
        let latexViewDiv = document.createElement("div");
        // latexViewDiv.style.width = width+"px"
        latexViewDiv.setAttribute('id',latexContent.text+this.mindElementData.id);
        latexViewDiv.style.lineHeight = height+"px"
        latexViewDiv.style.display = "block";
        latexViewDiv.style.position = "absolute";
        latexViewDiv.style.fontSize = Config.latexFontSize+"px";
        // latexViewDiv.setAttribute('v-katex:display',latexContent.text);
        // latexViewDiv.innerText = "$"+latexContent.text+"$";
        latexViewDiv.innerHTML = "";
        
        let html = katex.renderToString(latexContent.text, {
            throwOnError: false
        });
        
        latexViewDiv.innerHTML = html;
        this.latexView.appendChild(latexViewDiv);

        let topMargin;
        let leftMargin;
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            const textMindElementContent = this.getTextContent();
            let textY = 0;
            let textHeight = 0;
            if (textMindElementContent != null) {
                textHeight = textMindElementContent.height;
                textY = textMindElementContent.y;
                let latexHeight = this.mindElementData.latexContent == null ? 0 : this.mindElementData.latexContent.height;
                if ((this.mindElementData.iconElementContents == null || this.mindElementData.iconElementContents.length == 0) &&
                this.mindElementData.imageContent == null) {
                    textY = (this.mindElementData.height - latexHeight - textHeight) / 2;
                }
            }
            topMargin = textY + textHeight;
        } else {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                if (Colors.isClear(this.mindElementData.borderColor)) {
                    topMargin = this.mindElementData.height - height;
                } else {
                    topMargin = this.mindElementData.height - height - this.UiUtil.dip2px(this.mindElementData.borderWidth) / 2;
                }
            } else {
                topMargin = latexContent.y;
            }
        }
        this.latexView.setAttribute('y',topMargin);

        if (this.mindElementData.mindElementShape == MindElementShapeType.Circular ||
            this.mindElementData.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring2 ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring2_UP ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring3 ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ellipse ||
            this.mindElementData.mindElementShape == MindElementShapeType.Diamond ||
            this.mindElementData.mindElementShape == MindElementShapeType.InvertedTriangle ||
            this.mindElementData.mindElementShape == MindElementShapeType.Heart ||
            this.mindElementData.mindElementShape == MindElementShapeType.Star ||
            this.mindElementData.mindElementShape == MindElementShapeType.Octagonal ||
            this.mindElementData.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.mindElementData.mindElementShape == MindElementShapeType.Book ||
            this.mindElementData.mindElementShape == MindElementShapeType.Gemstone ) {
            leftMargin = (this.mindElementData.width - width) / 2;
        } else {
            leftMargin = latexContent.x;
        }
        this.latexView.setAttribute('x', leftMargin);
        // latexView.setLayoutParams(params);

        // latexView.getLastError().clear();
        // latexView.setFontSize(MTMathView.Companion.convertDpToPixel(latexContent.textFontSize));
        let bgColor = 0;
        if (!Colors.isClear(this.mindElementData.backgroundColor)) {
            bgColor = this.mindElementData.backgroundColor;
        } else {
            bgColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor;
        }
        let isDarkColor = Colors.isDarkColor(bgColor);
        if (isDarkColor) {
            latexViewDiv.style.color = Colors.getUiColor(Colors.white);
        } else {
            latexViewDiv.style.color =  Colors.getUiColor(Colors.black55);
        }
        // this.mindTextSizeChangedDelegate.latexRefresh(latexViewDiv);
        //将公式转为图片,用以保存图片使用
            // this.mindTextSizeChangedDelegate.testDomtoimage(latexContent.text+this.mindElementData.id);
    }

    setLatext() {
        let latexContent = this.mindElementData.latexContent;
        if (!this.mindElementData.isContainLatextContent()) {
            if (this.latexView != null) {
                this.latexView.innerHTML = "";
                this.latexView=null; 
            }
            return;
        }
        if (this.latexView != null) {
            return;
        }
        this.latexView = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.latexView.setAttribute('width', latexContent.width);
        this.latexView.setAttribute('height', latexContent.height);
        this.latexView.setAttribute('x', latexContent.x);
        this.latexView.setAttribute('y', latexContent.y);
        this.context.appendChild(this.latexView);
        
        let latexViewDiv = document.createElement("div");
        latexViewDiv.style.width = latexContent.width+"px"
        latexViewDiv.style.height = latexContent.height+"px"
        latexViewDiv.style.lineHeight = latexContent.height+"px"
        // latexViewDiv.style.textAlign = "center";
        latexViewDiv.style.fontSize = Config.latexFontSize+"px";
        // latexViewDiv.setAttribute('v-katex:display',latexContent.text);
        // latexViewDiv.innerText = "$"+latexContent.text+"$";
        latexViewDiv.innerHTML = "";
        // if (window.MathJax.texReset == null) {
        //     latexViewDiv.innerText = "$"+latexContent.text+"$";
        // } else {
        //     window.MathJax.texReset();
        //     var options = window.MathJax.getMetricsFor(latexViewDiv,true);
        //     options.display = true;
        //     let svgHtml =  window.MathJax.tex2svg(latexContent.text);
        //     latexViewDiv.appendChild(svgHtml);
        //     svgHtml.style.margin = 0+'em';
        // }
        let html = katex.renderToString(latexContent.text, {
            throwOnError: false
        });

        latexViewDiv.innerHTML = html;

        this.latexView.appendChild(latexViewDiv);
        this.latexView.addEventListener('mouseup', (ev)=>{
            if (ev.button == 2) {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1), { type: 'latexEditClick' }, ev);
 
            }
        });
    }


    setSelectedLayer(mindElementDataWidth, mindElementDataHeight, paintingAddX = 0, paintingAddY = 0) {    //用来画选中的元素的带圆角的矩形边框, paintingAddX 是x轴坐标增加量
        if (this.selectedView != null && this.selectedView.parentNode != null) {
            this.removeClickListener()
        }
        if (this.mindElementData == null) {
            return;
        }
        if (this.isSelected) {
            this.addSelectedView()
            let space = this.getSelectedSpace();
            let board = this.computedBoardSize(mindElementDataWidth, mindElementDataHeight);
            let width = board.width;
            let height = board.height;

            let linePath = new LinePath();
            let r = this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : 4;
            //x轴坐标增加的量

            //左布局
            if (this.mindElementData.hiddenNumber > 0 && 
                (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT
                )) {
                this.mindNodeUpNumberViewWidth = this.UiUtil.dip2px(24);
                paintingAddX = - this.mindNodeUpNumberViewWidth;
            }

            //上布局
            if (this.mindElementData.hiddenNumber > 0 && this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP) {
                this.mindNodeUpNumberViewWidth = this.UiUtil.dip2px(24);
                paintingAddY = - this.mindNodeUpNumberViewWidth;
            }

            linePath.moveTo(- space + r + paintingAddX, - space + paintingAddY);
            linePath.lineTo(width - space - r + paintingAddX, - space + paintingAddY);
            linePath.circle(r, r, 0, 0, 1, width - space + paintingAddX, - space + r + paintingAddY)    //这里都是小圆
            linePath.lineTo(width - space + paintingAddX, height - space - r + paintingAddY);
            linePath.circle(r, r, 0, 0, 1, width - space - r + paintingAddX, height - space + paintingAddY)    //这里都是小圆
            linePath.lineTo(- space + r + paintingAddX, height - space + paintingAddY);
            linePath.circle(r, r, 0, 0, 1, - space + paintingAddX, height - space - r + paintingAddY)    //这里都是小圆
            linePath.lineTo(- space + paintingAddX, - space + r + paintingAddY);
            linePath.circle(r, r, 0, 0, 1, - space + r + paintingAddX, - space + paintingAddY)    //这里都是小圆


            linePath.moveTo(width - space - r + paintingAddX, - space + paintingAddY);
            linePath.circle(r, r, 0, 0, 1, width - space - r + paintingAddX, - space + paintingAddY)

            linePath.moveTo(width - space + paintingAddX, height - space - r + paintingAddY);
            linePath.circle(r, r, 0, 1, 1, width - space + paintingAddX, height - space - r + paintingAddY)

            linePath.moveTo(- space + r + paintingAddX, height - space + paintingAddY);
            linePath.circle(r, r, 0, 1, 1, - space + r + paintingAddX, height - space + paintingAddY)

            linePath.moveTo(- space + paintingAddX, - space + r + paintingAddY);
            linePath.circle(r, r, 0, 1, 1, - space + paintingAddX, - space + r + paintingAddY)

            this.renderCanvas(this.setRect, linePath)
            // this.setAddIcon();
        } else {
            this.renderCanvas(this.setRect, "")
            this.removeSelectedView()
        }
    }

    setSelectedHoverLayer(value) {
        if (this.isSelected) {
            return
        }
        if (this.mindElementData == null) {
            return;
        }
        if (this.isHover == value) {
            return;
        }
        this.isHover = value;

        if (value) {
            this.addSelectedView()
            let mindElementDataWidth = this.mindElementData.width
            let mindElementDataHeight = this.mindElementData.height
            let space = this.getSelectedSpace();
            let board = this.computedBoardSize(mindElementDataWidth, mindElementDataHeight);
            let width = board.width;
            let height = board.height;
            let x = board.x;
            let y = board.y;

            let linePath = new LinePath();
            let r = this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : 8;
            //x轴坐标增加的量
    
    
            linePath.moveTo(x + r + 0, y + 0);
            linePath.lineTo(width + x - r + 0, y + 0);
            linePath.circle(r, r, 0, 0, 1, width + x + 0, y + r + 0)    //这里都是小圆
            linePath.lineTo(width + x + 0, height + y - r + 0);
            linePath.circle(r, r, 0, 0, 1, width + x - r + 0, height + y + 0)    //这里都是小圆
            linePath.lineTo(x + r + 0, height + y + 0);
            linePath.circle(r, r, 0, 0, 1, x + 0, height + y - r + 0)    //这里都是小圆
            linePath.lineTo(x + 0, y + r + 0);
            linePath.circle(r, r, 0, 0, 1, x + r + 0, y + 0)    //这里都是小圆
            this.renderHoverCanvas(this.setRect, linePath)
        } else {
            this.renderCanvas(this.setRect, "")
            this.removeSelectedView()
        }
    }

    setSelectedBorder(mindElementDataWidth, mindElementDataHeight, paintingAddX = 0) {
        this.addSelectedView()
        let space = this.getSelectedSpace();
        let board = this.computedBoardSize(mindElementDataWidth, mindElementDataHeight);
        let width = board.width;
        let height = board.height;

        let linePath = new LinePath();
        let r = this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : 8;
        //x轴坐标增加的量

        if (this.mindElementData.hiddenNumber > 0 && this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT) {
            this.mindNodeUpNumberViewWidth = this.UiUtil.dip2px(24);
            paintingAddX = - this.mindNodeUpNumberViewWidth;
        }
        linePath.moveTo(- space + r + paintingAddX, - space);
        linePath.lineTo(width - space - r + paintingAddX, - space);
        linePath.circle(r, r, 0, 0, 1, width - space + paintingAddX, - space + r)    //这里都是小圆
        linePath.lineTo(width - space + paintingAddX, height - space - r);
        linePath.circle(r, r, 0, 0, 1, width - space - r + paintingAddX, height - space)    //这里都是小圆
        linePath.lineTo(- space + r + paintingAddX, height - space);
        linePath.circle(r, r, 0, 0, 1, - space + paintingAddX, height - space - r)    //这里都是小圆
        linePath.lineTo(- space + paintingAddX, - space + r);
        linePath.circle(r, r, 0, 0, 1, - space + r + paintingAddX, - space)    //这里都是小圆
        this.renderCanvas(this.setRect, linePath)
    }

    computedBoardSize(mindElementDataWidth, mindElementDataHeight) {   //选中边框的大小,自己拆出来的
        let space = this.getSelectedSpace();
        let width;
        let height;
        let x = -space;
        let y = -space;
        width = mindElementDataWidth + space * 2;
        height = mindElementDataHeight + space * 2;
        this.mindNodeUpNumberViewWidth = this.UiUtil.dip2px(24); //不知道为什么this.mindNodeUpNumberViewWidth为undefined，所以重新赋值
        if (this.mindElementData.hiddenNumber > 0) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT
                ) {
                x = -space - this.mindNodeUpNumberViewWidth;
                y = -space;
                width = mindElementDataWidth + space * 2 + this.mindNodeUpNumberViewWidth;
                height = mindElementDataHeight + space * 2;
            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {

                x = -space;
                y = -space;
                width = mindElementDataWidth + space * 2 + this.mindNodeUpNumberViewWidth;
                height = mindElementDataHeight + space * 2;
            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM) {
                x = -space;
                y = -space;
                width = mindElementDataWidth + space * 2;
                height = mindElementDataHeight + space * 2 + this.mindNodeUpNumberViewWidth;

            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP) {
                x = -space - this.mindNodeUpNumberViewWidth;
                y = -space;
                width = mindElementDataWidth + space * 2;
                height = mindElementDataHeight + space * 2 + this.mindNodeUpNumberViewWidth;
            }

        } else {
            x = -space;
            y = -space;
            width = mindElementDataWidth + space * 2;
            height = mindElementDataHeight + space * 2;
        }

        return {
            x: x,
            y: y,
            width: width,
            height: height
        }
    } 

    mousemoveMind() {
        if (this.mindMoveFunctin == null) {
            this.mindMoveFunctin = () => {
                this.initAddIcon()
                if (!this.mindTextSizeChangedDelegate.isEditStatus()) {
                    return
                }
                let canvasColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor;
                let isDarkColor;
                let color = "";
                let backgroundColor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)
                if (canvasColor == '' || canvasColor == null) {    //如果颜色值为空直接用灰色。
                    isDarkColor = true;
                } else {
                    isDarkColor = Colors.isLightColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
                }
                
                if (Colors.isClear(this.mindElementData.borderColor) || this.mindElementData.borderWidth == 0) {
                    //如果没有设置线条颜色，隐藏图标的颜色就取画布的相反颜色。
                    if (isDarkColor) {
                        color = Colors.getUiColor(Colors.black80)
                    } else {
                        color = Colors.getUiColor(Colors.white)
                    }
                } else {
                    color = Colors.getUiColor(this.mindElementData.borderColor)
                }
                if (this.removeHideAddNodeIcon != null && this.removeHideAddNodeIcon > -1) {
                    clearTimeout(this.removeHideAddNodeIcon)
                    this.removeHideAddNodeIcon = -1
                }
                
                this.setAddIcon()
                if (this.addChildIconImg != null) {
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        this.addChildIconImg.style.color = Colors.getUiColor(this.getTextContent().textColor)           
                        this.addChildIconImg.style.backgroundColor = Colors.getUiColor(Colors.clear)
                    } else {
                        this.addChildIconImg.style.color = color
                        this.addChildIconImg.style.backgroundColor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)
                    }
                }
                this.setSelectedHoverLayer(true)
            }
        }

        if (this.mindMoveOverFunctin == null) {

        }
        this.context.addEventListener('mouseover', this.mindMoveFunctin);

    }
    //自己添加的一个函数
    renderCanvas(ele, path) {
        if (ele == null) {
            return
        }
        if (path == "") {
            ele.setAttribute("d", "")
        } else {
            ele.setAttribute("d", path.getLine())
        }
        ele.setAttribute("stroke", 'rgb(253 113 90)')
        ele.setAttribute("stroke-width", 2)
        if (this.mindElementData.layout != NodeLayoutType.LAYOUT_RADIATE && 
            this.mindElementData.layout != NodeLayoutType.LAYOUT_FISH_RIGHT &&
            this.mindElementData.layout != NodeLayoutType.LAYOUT_FISH_LEFT &&
            this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor != null) {
            ele.setAttribute("fill", Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor, 0.65))
        } else {
            ele.setAttribute("fill", "none")
        }
    }

    //自己添加的一个函数
    renderHoverCanvas(ele, path) {
        if (ele == null) {
            return
        }
        if (path == "") {
            ele.setAttribute("d", "")
        } else {
            ele.setAttribute("d", path.getLine())
        }
        ele.setAttribute("stroke", 'rgb(251, 188, 178)')
        ele.setAttribute("stroke-width", 2)
        if (this.mindElementData.layout != NodeLayoutType.LAYOUT_RADIATE && 
            this.mindElementData.layout != NodeLayoutType.LAYOUT_FISH_RIGHT && 
            this.mindElementData.layout != NodeLayoutType.LAYOUT_FISH_LEFT && 
            this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor != null) {
            ele.setAttribute("fill", Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor, 0.65))
        } else {
            ele.setAttribute("fill", "none")
        }
    }

    addSelectedView() {
        if (this.setRect == null) {
            this.setRect = document.createElementNS('http://www.w3.org/2000/svg', "path")
            if (this.clipPathBox != null && this.clipPathBox.parentNode != null) {
                this.context.insertBefore(this.setRect, this.clipPathBox)
            } else if (this.mindContent != null && this.mindContent.parentNode != null) {
                this.context.insertBefore(this.setRect, this.mindContent)
            } else {
                this.context.appendChild(this.setRect)
            }
            
        }
    }

    setLinkIconsViewLayout() {
        if (this.linkIcon == null || this.linkIcon.data == null || !this.mindElementData.isContainLinkContent()) {
            return;
        }

        let linkElementContent = this.mindElementData.linkElementContent;
        this.linkIcon.data.setIconElementContentData(linkElementContent.width, linkElementContent.height, this.getTextColor(), this.getTextColor())
        this.linkIcon.setSize(linkElementContent.width, linkElementContent.height);
        let iconY = linkElementContent.y;

        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            iconY = (this.mindElementData.height - taskHeight - linkElementContent.height) / 2;
        }

        this.linkIcon.setPoint(linkElementContent.x, iconY);
        this.linkIcon.setIconTintColor();
    }

    setRemarksIconsViewLayout() {
        if (this.remarksIcon == null || this.remarksIcon.data == null || !this.mindElementData.isContainRemarksContent()) {
            return;
        }
        let remarksElementContent = this.mindElementData.remarksElementContent;
        this.remarksIcon.data.setIconElementContentData(remarksElementContent.width, remarksElementContent.height, this.getTextColor(), this.getTextColor())
        this.remarksIcon.setSize(remarksElementContent.width, remarksElementContent.height);
        let iconY = remarksElementContent.y;
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            iconY = (this.mindElementData.height - taskHeight - remarksElementContent.height) / 2;
        }
        this.remarksIcon.setPoint(remarksElementContent.x, iconY);
        this.remarksIcon.setIconTintColor();
    }

    setMoreIconsViewLayout() {
        if (this.moreIcon == null || this.moreIcon.data == null || !this.getIsShowMore()) {
            return;
        }
        let moreContent = this.mindElementData.moreContent;
        this.moreIcon.data.setIconElementContentData(moreContent.width, moreContent.height, this.getTextColor(), this.getTextColor())
        this.moreIcon.setSize(moreContent.width, moreContent.height);
        let iconY = moreContent.y;
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            iconY = (this.mindElementData.height - taskHeight - moreContent.height) / 2;
        }
        this.moreIcon.setPoint(moreContent.x, iconY);
        this.moreIcon.setIconTintColor();
    }

    setResourceIconViewLayout() {
        if (this.resourceIcon == null || this.resourceIcon.data == null || !this.mindElementData.isContainResourceContent()) {
            return;
        }
        let resourceContent = this.mindElementData.resourceContent;
        this.resourceIcon.data.setIconElementContentData(resourceContent.width, resourceContent.height, this.getTextColor(), this.getTextColor(),this.getResourceIconName(resourceContent.type))
        this.resourceIcon.setSize(resourceContent.width, resourceContent.height);
        let iconY = resourceContent.y;
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            iconY = (this.mindElementData.height - taskHeight - resourceContent.height) / 2;
        }
        this.resourceIcon.setPoint(resourceContent.x, iconY);
        this.resourceIcon.setIconTintColor();
    }

    setConnectMapIconsViewLayout() {
        if (this.connectMapIcon == null || this.connectMapIcon.data == null || !this.mindElementData.isContainConnectMapContent()) {
            return;
        }
        let connectMapElementContent = this.mindElementData.connectMapElementContent;
        this.connectMapIcon.data.setIconElementContentData(connectMapElementContent.width, connectMapElementContent.height, this.getTextColor(), this.getTextColor())
        this.connectMapIcon.setSize(connectMapElementContent.width, connectMapElementContent.height);
        let iconY = connectMapElementContent.y;
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            iconY = (this.mindElementData.height - taskHeight - connectMapElementContent.height) / 2;
        }
        this.connectMapIcon.setPoint(connectMapElementContent.x, iconY);
        this.connectMapIcon.setIconTintColor();
    }

    setImageViewLayout() {
        if (this.imageView == null || this.imageView.parentNode == null || this.mindElementData.imageContent == null) {
            return;
        }
        let imageContent = this.mindElementData.imageContent;
        let padding = Colors.isClear(this.mindElementData.borderColor) ? 0 : this.mindElementData.borderWidth / 2
        let width = imageContent.width - padding * 2
        let height = imageContent.height - padding * 2
        let x = imageContent.x + padding
        let y = imageContent.y + padding

        this.imageViewTap.photoForeignObject.setAttribute('width', width);
        this.imageViewTap.photoForeignObject.setAttribute('height', height);
        this.imageViewTap.photoForeignObject.setAttribute('x', x);
        this.imageViewTap.photoForeignObject.setAttribute('y', y);

        this.imageViewTap.photoShade.setAttribute('width', width);
        this.imageViewTap.photoShade.setAttribute('height', height);
        this.imageViewTap.photoShade.setAttribute('x', x);
        this.imageViewTap.photoShade.setAttribute('y', y);

        this.imageView.style.width = width + 'px';
        this.imageView.style.height = height + 'px';

        this.imageView.style.borderRadius = 0;
        this.imageView.style.clipPath = "";
        if (imageContent.shapeType == ImageShapeType.Circular) {  //圆
            this.imageView.style.borderRadius = '50%';
        } else if (imageContent.shapeType == ImageShapeType.Diamond) { //菱
            this.imageView.style.clipPath = "polygon(50% 0,100% 50%,50% 100%,0 50%)";
        } else {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                this.imageView.style.borderRadius = '0px';
            } else {
                this.imageView.style.borderRadius = this.UiUtil.dip2px(6) + 'px';
                if (this.mindElementData.type == MindElementType.SUBJECT || 
                    this.mindElementData.type == MindElementType.EXPLAIN ||
                    this.mindElementData.type == MindElementType.CONTENT_GENERALIZATION) {
                    this.imageView.style.borderRadius = this.UiUtil.dip2px(6) + 'px';
                } else if (this.mindElementData.type == MindElementType.SON_SUBJECT) {
                    this.imageView.style.borderRadius = this.UiUtil.dip2px(5) + 'px';
                }
            }
        }

        if (this.videoPlayButton != null) {
            this.videoPlayButton.style.width = width+"px"
            this.videoPlayButton.style.height = height+"px"
            this.videoPlayButton.style.lineHeight = height+"px"
            this.videoPlayButton.style.textAlign = "center";

            let videoPlayButtonSize = Math.min(imageContent.width/4, imageContent.height/4);
            if (videoPlayButtonSize > this.UiUtil.dip2px(150)) {
                videoPlayButtonSize = this.UiUtil.dip2px(150);
            } else if (videoPlayButtonSize < this.UiUtil.dip2px(30)) {
                videoPlayButtonSize = this.UiUtil.dip2px(30);
            }

            
            if(this.playIconImg != null){
                if (Math.min(imageContent.width, imageContent.height) > 100) {
                    this.playIconImg.style.width = "50px"
                } else if (Math.min(imageContent.width, imageContent.height) < 40) {
                    this.playIconImg.style.width = "30px"
                } else {
                        
                    this.playIconImg.style.width = "40px"
                }
            }
        }
    }

    setImageSize(width, height) {
        if (this.imageView == null || this.imageView.parentNode == null || this.mindElementData.imageContent == null) {
            return;
        }
        let imageContent = this.mindElementData.imageContent;
        this.imageViewTap.photoForeignObject.setAttribute('width', width);
        this.imageViewTap.photoForeignObject.setAttribute('height', height);
        this.imageViewTap.photoForeignObject.setAttribute('x', imageContent.x);
        this.imageViewTap.photoForeignObject.setAttribute('y', imageContent.y);

        this.imageViewTap.photoShade.setAttribute('width', width);
        this.imageViewTap.photoShade.setAttribute('height', height);
        this.imageViewTap.photoShade.setAttribute('x', imageContent.x);
        this.imageViewTap.photoShade.setAttribute('y', imageContent.y);

        this.imageView.style.width = width + 'px';
        this.imageView.style.height = height + 'px';

        this.imageView.style.borderRadius = 0;
        this.imageView.style.clipPath = "";
        if (imageContent.shapeType == ImageShapeType.Circular) {  //圆
            this.imageView.style.borderRadius = '50%';
        } else if (imageContent.shapeType == ImageShapeType.Diamond) { //菱
            this.imageView.style.clipPath = "polygon(50% 0,100% 50%,50% 100%,0 50%)";
        } else {
            this.imageView.style.borderRadius = 5 + 'px';
        }
    }

    setIconsViewLayout() {
        if (this.icons == null || this.icons.length == 0 || this.mindElementData.iconElementContents == null) {
            return;
        }
        let iconElementContents = this.mindElementData.iconElementContents;
        
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconElementContent = iconElementContents[index];
            let key = iconElementContent.key;
            if (!this.icons.keys().includes(key)) {
                continue;
            }
            let iconView = this.icons.get(key);
            let iconY = iconElementContent.y;
            if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                (iconElementContent.layoutType == IconLayoutType.LAYOUT_LEFT ||
                    iconElementContent.layoutType == IconLayoutType.LAYOUT_RIGHT)) {
                let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
                iconY = (this.mindElementData.height - taskHeight - iconElementContent.height) / 2;
            }
            iconView.setSize(iconElementContent.width, iconElementContent.height);
            iconView.setPoint(iconElementContent.x, iconY);

            if (iconView.data != null) {
                iconView.data.width = iconElementContent.width;
                iconView.data.height = iconElementContent.height;
                iconView.refreshSize();
                //由于刷新的时候也调用了setPoint，但因为没有this.mindElementData.height，所以表格模式下计算不准。所以要重新设置一下setPoint
                iconView.setPoint(iconElementContent.x, iconY);
            }
        }
    }

    setIconPointComputed() {  //计算设置icon图标的位置，主要是为了解决表格模式下图标左布局或右布局的问题。
        if (data.layout == NodeLayoutType.LAYOUT_FORM) {    //如果是表格布局


        }
    }

    initInput() {
        var textContent = this.getTextContent();
        if (textContent == null) {
            return;
        }
        this.inputTextView = document.createElement("div")
        this.inputTextView.setAttribute("class", "textMindElementView")
        this.mindContent.appendChild(this.inputTextView)
        if (this.mindElementData.textContent != null) {
            this.inputTextView.style.fontSize = this.mindElementData.textContent.textFontSize + 'px';
            this.inputTextView.style.lineHeight = Config.textLineHeight
        } else if (this.mindElementData.generalizationContent != null) {
            this.inputTextView.style.fontSize = this.mindElementData.generalizationContent.textFontSize + 'px';
            this.inputTextView.style.lineHeight = this.mindElementData.generalizationContent.textFontSize * (Config.textLineHeight) + 'px';
        }

        this.inputTextView.innerHTML = this.getContentText();
        this.context.appendChild(this.mindContent)
        let edgeInsets = this.getTextUIEdgeInsets()
        this.inputTextView.style.color = Colors.getUiColor(Colors.black50)
        this.inputTextView.style.paddingLeft = 0 + 'px'
        this.inputTextView.style.paddingTop = edgeInsets.top + 'px'
        this.inputTextView.style.paddingRight = 0 + 'px'
        this.inputTextView.style.paddingBottom = edgeInsets.bottom + 'px'
        this.inputTextView.style.fontFamily = Config.textFontFamily // "SimSun" //"Noto Sans SC"
        this.inputTextView.style.whiteSpace = "pre-wrap"
        this.inputTextView.style.wordBreak = "break-word"

        this.inputTextView.style.WebkitUserSelect = 'none'
        this.inputTextView.style.userSelect = 'none'
        if (textContent.textBold) { //字体是否加粗
            this.inputTextView.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.inputTextView.style.fontWeight = Config.textNormalFontWeight
        }

        if (this.inputTextView.style.textItalics) {    //设置为斜体
            this.inputTextView.fontStyle = 'italic'
        } else {
            this.inputTextView.fontStyle = 'normal'
        }
        //在此处修改一下数据中的padding
        textContent.contentInsetBottom = edgeInsets.bottom
        textContent.contentInsetTop = edgeInsets.top
        textContent.contentInsetRight = edgeInsets.right
        textContent.contentInsetLeft = edgeInsets.left

    }

    setTitleInput() {
        if (this.mindElementData == null) {
            return
        }
        if (!this.mindElementData.isCardContent()) {
            if (this.titleInputTextView != null && this.titleInputTextView.parentNode != null) {
                this.titleInputTextView.parentNode.removeChild(this.titleInputTextView);                
            }
            if (this.titleMindContent != null && this.titleMindContent.parentNode != null) {
                this.titleMindContent.parentNode.removeChild(this.titleMindContent);                
            }
            this.titleMindContent = null;
            this.titleInputTextView = null;
            return;
        }
        var titleContent = this.mindElementData.titleContent;
        if (this.titleMindContent == null) {
            this.titleMindContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
            this.titleMindContent.setAttribute("class", "textMindElementTitleViewContent")

            this.titleInputTextView = document.createElement("div")
            this.titleInputTextView.setAttribute("class", "textMindElementTitleView")
            this.titleMindContent.appendChild(this.titleInputTextView)
    
            this.titleInputTextView.style.fontSize = titleContent.textFontSize + 'px';
            this.titleInputTextView.style.lineHeight = Config.textLineHeight
            this.context.appendChild(this.titleMindContent)
            if (this.titleDoubleClick != null) {
                this.titleMindContent.addEventListener('dblclick', this.titleDoubleClick);
            }
        }        
        this.titleInputTextView.innerHTML = this.getContentText(titleContent);
       
        let edgeInsets = this.getTextUIEdgeInsets()
        this.titleInputTextView.style.color = Colors.getUiColor(titleContent.textColor)
        this.titleInputTextView.style.paddingLeft = 0 + 'px'
        this.titleInputTextView.style.paddingTop = edgeInsets.top + 'px'
        this.titleInputTextView.style.paddingRight = 0 + 'px'
        this.titleInputTextView.style.paddingBottom = edgeInsets.bottom + 'px'
        this.titleInputTextView.style.fontFamily = Config.textFontFamily // "SimSun" //"Noto Sans SC"
        this.titleInputTextView.style.whiteSpace = "pre-wrap"
        this.titleInputTextView.style.wordBreak = "break-word"
        this.titleInputTextView.style.fontSize = titleContent.textFontSize + 'px';
        
        this.titleInputTextView.style.WebkitUserSelect = 'none'
        this.titleInputTextView.style.userSelect = 'none'
        if (titleContent.textBold) { //字体是否加粗
            this.titleInputTextView.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.titleInputTextView.style.fontWeight = Config.textNormalFontWeight
        }

        if (this.titleInputTextView.style.textItalics) {    //设置为斜体
            this.titleInputTextView.fontStyle = 'italic'
        } else {
            this.titleInputTextView.fontStyle = 'normal'
        }
        //在此处修改一下数据中的padding
        titleContent.contentInsetBottom = edgeInsets.bottom
        titleContent.contentInsetTop = edgeInsets.top
        titleContent.contentInsetRight = edgeInsets.right
        titleContent.contentInsetLeft = edgeInsets.left

        let textFontFamily = titleContent.fontFamily
        if (textFontFamily == null || textFontFamily == "") {
            textFontFamily = Config.textFontFamily
        }

        this.titleInputTextView.style.fontFamily = textFontFamily
        this.setTitleInputTextViewLayout()     
    }

    initExplainLabelView() {
        if (this.mindElementData == null ||
            !this.mindElementData.isContainExplainContent()) {
            return;
        }
        if (this.explainLabelView != null) {
            this.explainLabelView.setData(this.mindElementData);
            return;
        }
        this.explainLabelView = new ExplainLabelView(this.context, this.mindElementData);
        if (EditMindmapVM.mindDisplayType != MindDisplayType.Outline) {
            this.explainLabelView.foreignObjectBox.addEventListener('dblclick', this.subScript);
        }

    }

    
    setTaskLabelView() {
        if (this.mindElementData == null ||
                this.mindElementData.taskContent == null ||
                new Strings().isEmpty(this.mindElementData.taskContent.getContentText())) {
            return;
        }
        if (this.taskLabelView != null) {
            return;
        }
        this.taskLabelView = new TaskLabelView(this.context, this.mindElementData,this.mindTextSizeChangedDelegate);
        // if (EditMindmapVM.mindDisplayType != MindDisplayType.Outline) {
        //     this.taskLabelView.taskProgressView.paint.addEventListener('mousedown', this.completionClick);
        // }
    }

    setExplainLabelViewLayout() {
        if (this.explainLabelView == null ||
            this.mindElementData.explain == null ||
            !this.mindElementData.isContainExplainContent() ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            if (this.explainLabelView != null) {
                this.explainLabelView.setPosition(0, 0, 0, 0);
            }
            return;
        }


        let size = MindElementCalculation.setWidthCaluleText(this.mindElementData.explain, Config.NodeExplainFontSize, false, this.mindElementData.width - Config.NodeExplainLeftAndRightPadding * 2);
        let height = size.getHeight() + Config.NodeExplainPadding * 2;
        let width = size.getWidth() + Config.NodeExplainLeftAndRightPadding * 2 + 6;
        if (width > this.mindElementData.width) {
            width = this.mindElementData.width;
        }

        let topMargin;
        let leftMargin;
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
            if (Colors.isClear(this.mindElementData.borderColor)) {
                topMargin = this.mindElementData.height - Config.NodeExplainTop - height;
            } else {
                topMargin = this.mindElementData.height - Config.NodeExplainTop - height - this.UiUtil.dip2px(this.mindElementData.borderWidth) / 2;
            }
        } else {
            if (Colors.isClear(this.mindElementData.borderColor)) {
                topMargin = this.mindElementData.height + Config.NodeExplainTop;
            } else {
                topMargin = this.mindElementData.height + Config.NodeExplainTop + this.UiUtil.dip2px(this.mindElementData.borderWidth) / 2;
            }
        }
        if (this.mindElementData.explainAlignmentType() == MindElementAlignmentType.MIDDLE) {
            leftMargin = (this.mindElementData.width - width) / 2;
        } else {
            leftMargin = this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE ? this.UiUtil.dip2px(20) : 0;
        }

        if (this.mindElementData.explain == "") {
            this.explainLabelView.setPosition(0, 0, 0, 0);
        } else {
            this.explainLabelView.setPosition(width, height, leftMargin, topMargin);
        }

        this.explainLabelView.refreshView();
    }

    setTaskContentViewLayout() {
        if (this.mindElementData.taskContent == null ) {
            if(this.taskLabelView !=null){
                this.taskLabelView.remove();
            }
            this.taskLabelView = null;
            return;
        }
        if ((this.taskLabelView == null &&
                this.mindElementData.taskContent != null)) {
            this.taskLabelView = new TaskLabelView(this.context, this.mindElementData,this.mindTextSizeChangedDelegate);
            return;
        }
        let height = this.mindElementData.taskContent.height;
        let width = this.mindElementData.taskContent.width + 2;

        let topMargin;
        let leftMargin;
        
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let textMindElementContent = this.getTextContent();
            let textHeight = textMindElementContent.height;
            let textY = textMindElementContent.y;
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            //如果没有图标且没有图片时
            if ((this.mindElementData.iconElementContents == null || 
                this.mindElementData.iconElementContents.length == 0) &&
                this.mindElementData.imageContent == null) {
                textY = (this.mindElementData.height - taskHeight - textHeight) / 2;
            }
            //如果有图标时
            if (this.mindElementData.iconElementContents != null && this.mindElementData.iconElementContents.length > 0) {
                //如果图标为左布局或右布局
                if (this.mindElementData.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_LEFT ||
                    this.mindElementData.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_RIGHT) {
                    textY = (this.mindElementData.height - taskHeight - textHeight) / 2;
                }
            }
            topMargin = textY + textHeight
        } else {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                if (Colors.isClear(this.mindElementData.borderColor)) {
                    topMargin = this.mindElementData.height - height;
                } else {
                    topMargin = this.mindElementData.height - height - new UiUtil().dip2px(this.mindElementData.borderWidth) / 2;
                }
            } else {
                topMargin = this.mindElementData.taskContent.y;
            }
        }
    
        if (this.mindElementData.mindElementShape == MindElementShapeType.Circular ||
            this.mindElementData.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring2 ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring2_UP ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring3 ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ellipse ||
            this.mindElementData.mindElementShape == MindElementShapeType.Diamond ||
            this.mindElementData.mindElementShape == MindElementShapeType.InvertedTriangle ||
            this.mindElementData.mindElementShape == MindElementShapeType.Heart ||
            this.mindElementData.mindElementShape == MindElementShapeType.Star ||
            this.mindElementData.mindElementShape == MindElementShapeType.Octagonal ||
            this.mindElementData.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.mindElementData.mindElementShape == MindElementShapeType.Book ||
            this.mindElementData.mindElementShape == MindElementShapeType.Gemstone ) {
            leftMargin = (this.mindElementData.width - width) / 2;
        } else {
            leftMargin = this.mindElementData.taskContent.x;
        }
        if (this.mindElementData.taskContent == "") {
            this.taskLabelView = null;
        } else {
            this.taskLabelView.refreshView(this.mindElementData,width, height, leftMargin, topMargin);
        }
        // taskLabelView.setLayoutParams(taskLabelViewParams);
        // UiUtil.setViewVisible(taskLabelView);
        // taskLabelView.refreshView(this.mindElementData);
    }

    setText() {
        if (this.lastTextContent && this.mindElementData.textContent != null &&
            this.mindElementData.textContent.equal(this.lastTextContent)) {
            return;
        } else if (this.lastTextContent && this.mindElementData.generalizationContent != null &&
            this.mindElementData.generalizationContent.equal(this.lastTextContent)) {
            return;
        }
        let textContent = this.getContentText();
        if (this.inputTextView.innerHTML != textContent) {
            this.inputTextView.innerHTML = textContent;
        }
        let textFontFamily = "";
        if (this.mindElementData.textContent != null) {
            textFontFamily = this.mindElementData.textContent.fontFamily
        } else if (this.mindElementData.generalizationContent != null) {
            textFontFamily = this.mindElementData.generalizationContent.fontFamily
        }
        if (textFontFamily == null || textFontFamily == "") {
            textFontFamily = Config.textFontFamily
        }
        this.inputTextView.style.fontFamily = textFontFamily
        this.setInputTextViewLayout();
        if (this.mindElementData.textContent != null) {
            this.lastTextContent = this.mindElementData.textContent.copy();
        } else if (this.mindElementData.generalizationContent != null) {
            this.lastTextContent = this.mindElementData.generalizationContent.copy();
        }
    }

    clearText() {
        if (this.inputTextView != null) {
            this.inputTextView.innerHTML = "";
            this.isClearText = true;
            this.lastTextContent = null;
        } 
    }

    setOrderText() {
        if (this.mindElementData.orderContent == null || 
            this.mindElementData.orderContent.order == null || 
            this.mindElementData.orderContent.order.length == 0 ||
            this.mindElementData.textContent == null) {
            if (this.mindOrderContent != null) {
                this.mindOrderContent.parentNode.removeChild(this.mindOrderContent)
                this.mindOrderContent = null;
                this.orderTextView = null;
            }
            return;
        }
        if (this.orderTextView == null) {
            this.mindOrderContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
            this.mindOrderContent.setAttribute("class", "orderTextMindElementViewContent")

            this.orderTextView = document.createElement("div")
            this.orderTextView.setAttribute("class", "orderTextMindElementView")
            this.mindOrderContent.appendChild(this.orderTextView)
            this.context.appendChild(this.mindOrderContent)
        }
        this.orderTextView.style.fontSize = this.mindElementData.textContent.textFontSize + 'px';
        this.orderTextView.style.lineHeight = Config.textLineHeight

        let text = this.mindElementData.orderContent.order;
        let textShadow = this.getTextShadow();
        let bgColor = this.getTextBgColor();

        let fontSize = this.getTextFontSize();
        let color = Colors.getUiColor(this.getTextColor());
        let bold = this.getTextBold();
        let italics = this.getTextItalics();
        let strikethrough = this.getTextStrikethrough();
        
                
        let tag = "<div><span id='0' style='font-size:" + fontSize + "px;letter-spacing: 1px;line-height: " + Config.textLineHeight + "; " + Config.TextWritingModeVertical_LR;
        if (bgColor != -10000 && !Colors.isClear(bgColor)) {
            tag = tag +  "background-color:" + Colors.getUiColor(bgColor) + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;";
        }
        if (textShadow != -10000 && !Colors.isClear(textShadow)) {
            let offset = 1
            if (fontSize <= 16) {
                offset = 0.6;
            } else if (fontSize > 16 && fontSize <= 28) {
                offset = 1;
            } else {
                offset = 2;
            }
            tag = tag +  "text-shadow: " + offset + "px " + offset + "px " + Colors.getUiColor(textShadow) + ";"; 
        }
        tag = tag + "' class='no_font_size' >" + text + "</span></div>";


        this.orderTextView.innerHTML = tag;

        let edgeInsets = this.getTextUIEdgeInsets()
        this.orderTextView.style.color = color
        this.orderTextView.style.paddingLeft = 0 + 'px'
        this.orderTextView.style.paddingTop = 0 + 'px'//edgeInsets.top + 'px'
        this.orderTextView.style.paddingRight = 0 + 'px'
        this.orderTextView.style.paddingBottom = edgeInsets.bottom + 'px'
        this.orderTextView.style.fontFamily = Config.textFontFamily // "SimSun" //"Noto Sans SC"
        this.orderTextView.style.whiteSpace = "pre-wrap"
        this.orderTextView.style.wordBreak = "break-word"
        // this.orderTextView.style.textAlign = "end"

        this.orderTextView.style.WebkitUserSelect = 'none'
        this.orderTextView.style.userSelect = 'none'
        if (bold) { //字体是否加粗
            this.orderTextView.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.orderTextView.style.fontWeight = Config.textNormalFontWeight
        }

        if (this.orderTextView.style.textItalics) {    //设置为斜体
            this.orderTextView.fontStyle = 'italic'
        } else {
            this.orderTextView.fontStyle = 'normal'
        }
        let textFontFamily = this.mindElementData.textContent.fontFamily;

        if (textFontFamily == null || textFontFamily == "") {
            textFontFamily = Config.textFontFamily
        }
        this.orderTextView.style.fontFamily = textFontFamily
        // this.setOrderTextViewLayout();
    }

    setIcons() {
        if (this.icons == null) {
            this.icons = new HashMap();
        }
        let iconElementContents = this.mindElementData.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            if (this.icons.isEmpty()) {
                return;
            }
            let valueArr = this.icons.values()
            for (let i = 0; i < valueArr.length; i++) {
                valueArr[i].remove();
            }
            this.icons.clear();
            return;
        }

        if (!this.icons.isEmpty()) {
            let removeIconKeys = new Array();
            let keysArr = this.icons.keys();
            for (let index = 0; index < keysArr.length; index++) {
                let isRemove = true;
                let iconsArr = iconElementContents;
                for (let j = 0; j < iconsArr.length; j++) {
                    if ((new Strings).equals(iconsArr[j].key, keysArr[index])) {
                        isRemove = false;
                        break;
                    }
                }
                if (isRemove && this.icons.containsKey(keysArr[index])) {
                    removeIconKeys.push(keysArr[index]);
                    this.icons.get(keysArr[index]).remove()
                }
            }

            for (let j = 0; j < removeIconKeys.length; j++) {
                this.icons.remove(removeIconKeys[j]);
            }
        }
        if (iconElementContents.length == 0) {
            return;
        }
        
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconElementContent = iconElementContents[index].copy();
            let key = iconElementContent.key;
            if (key == null) {
                continue;
            }
            let iconView = null;
            if (this.icons.keys().includes(key)) {
                iconView = this.icons[key];
            } else {
                if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
                    if (this.mindElementData.imageContent == null) {
                        iconView = new MindElementIconOutlineCell(this.iconContent, iconElementContent, MindElementIconCellType.ICON, this.outlineVue);
                    } else {
                        iconView = new MindElementIconOutlineCell(this.iconsImageContent, iconElementContent, MindElementIconCellType.ICON, this.outlineVue);
                    }
                } else {
                    iconView = new MindElementIconCell(this.context, iconElementContent, MindElementIconCellType.ICON);
                }
                iconView.deletage = this;
                this.icons.put(key, iconView);
            }
        }
    }

    setVideoStatus( data) {
        if (this.mindElementData == null || this.mindElementData.id != data.nodeId) {
            return;
        }
    }

    removeVideoPlayButton() {
        if (this.videoPlayButton == null) {
            return;
        }
        this.videoPlayButton.parentNode.removeChild(this.videoPlayButton);
        this.videoPlayButton = null;
    }

    setImage() {
        let imageContent = this.mindElementData.imageContent;
        if (imageContent == null || imageContent.key == "" || imageContent.key == null) {

            if (this.imageView != null) {
                this.imageView.parentNode.removeChild(this.imageView)
            }
            if (this.imageViewTap != null && this.imageViewTap.photoShade != null && this.imageViewTap.photoShade.parentNode != null) {
                this.imageViewTap.photoShade.parentNode.removeChild(this.imageViewTap.photoShade);
                this.imageViewTap.photoShade = null
            }
            if (this.imageViewTap != null && this.imageViewTap.photoForeignObject != null && this.imageViewTap.photoForeignObject.parentNode != null) {
                this.imageViewTap.photoForeignObject.parentNode.removeChild(this.imageViewTap.photoForeignObject);
                this.imageViewTap.photoForeignObject = null
            }
            this.imageViewTap = null;
            this.imageView = null;
            this.removeVideoPlayButton();
            return;
        }
        if (this.imageView == null) {
            let img = new ShapeImageView(this, this.context, imageContent, ImageShapeType.Diamond);
            this.imageView = img.photo
            this.imageViewTap = img
        }


        this.imageView.style.display = 'block'

        if (imageContent.key != null) {
            let srcHttp = HttpServerConfig.getMediaSrc(imageContent.key)
            new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
                this.imageView.style.background = "center center url(" + imageBase64 + ")";
                this.imageView.style.backgroundRepeat = "no-repeat";
                this.imageView.style.backgroundSize = "contain";
            })

            if(imageContent.isVideo()){
                this.removeVideoPlayButton();
                if (this.videoPlayButton == null) {
                    this.videoPlayButton = document.createElement("div")
                    this.imageViewTap.photoShade.appendChild(this.videoPlayButton);
                }
                if(imageContent.videoUrl.indexOf("http") > -1 || imageContent.videoUrl.indexOf("r/") == 0 || LoadLocalXJMind.isLoaclMind()){
                    this.imageViewTap.photoShade.appendChild(this.videoPlayButton);
                    this.videoPlayButton.classList.add('video-progress-bar')
                    this.videoPlayButton.style.width = imageContent.width+"px"
                    this.videoPlayButton.style.height = imageContent.height+"px"
                    this.videoPlayButton.style.lineHeight = imageContent.height+"px"
                    this.videoPlayButton.style.textAlign = "center";
                    this.playIconImg = document.createElement("img");
                    this.videoPlayButton.appendChild(this.playIconImg);
                    

                    // let videoPlayButtonSize = Math.min(imageContent.width/4, imageContent.height/4);
                    // if (videoPlayButtonSize > this.UiUtil.dip2px(150)) {
                    //     videoPlayButtonSize = this.UiUtil.dip2px(150);
                    // } else if (videoPlayButtonSize < this.UiUtil.dip2px(30)) {
                    //     videoPlayButtonSize = this.UiUtil.dip2px(30);
                    // }
                    if (Math.min(imageContent.width, imageContent.height) > 100) {
                        this.playIconImg.style.width = "50px"
                    } else if (Math.min(imageContent.width, imageContent.height) < 40) {
                        this.playIconImg.style.width = "30px"
                    } else {
                        this.playIconImg.style.width = "40px"
                    }
                    
                    let imgsrc = require("../../../assets/img/mindNewEditMenuIocn/playVideo.png");
                    new imageToStyle().localhostToBase64(imgsrc).then(base64MindLogo => {
                        this.playIconImg.setAttribute("src",base64MindLogo);
                    });
                    this.playIconImg.style.cursor = "pointer"
                    let that = this
                    this.playIconImg.addEventListener("click",()=>{
                        let obj = {};
                        obj.name = that.mindTextSizeChangedDelegate.strings.Mind_Edit_Node_Menu_View_Video;
                        that.mindTextSizeChangedDelegate.checkMenuElement(obj);
                    })
                } else {
                    if(this.mindElementData.id == indexDataStorage.state.nodeId){
                        this.videoPlayButton.classList.add('video-progress-bar')
        
                        let contentViewSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                        contentViewSvg.setAttribute("width", imageContent.width);
                        contentViewSvg.setAttribute("height", imageContent.height);
                        contentViewSvg.setAttribute("viewBox", "0 0 100 100");
                        this.videoPlayButton.appendChild(contentViewSvg);
        
                        let contentViewPath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                        contentViewPath1.setAttribute("d", "M 50 50 m -8 0 a 8 8 0 1 0 16 0  a 8 8 0 1 0 -16 0");
                        contentViewPath1.setAttribute("fill", "none");
                        contentViewPath1.setAttribute("stroke", "#e5e9f2");
                        contentViewPath1.setAttribute("stroke-width","3");
                        contentViewSvg.appendChild(contentViewPath1);
        
                        let contentViewPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                        contentViewPath2.setAttribute("d", "M 50 50 m -8 0 a 8 8 0 1 0 16 0  a 8 8 0 1 0 -16 0");
                        contentViewPath2.setAttribute("fill", "none");
                        contentViewPath2.setAttribute("stroke", "#fd9482");
                        contentViewPath2.setAttribute("stroke-width","3");
                        contentViewPath2.setAttribute("transform","rotate(90,50,50)");
                        contentViewPath2.classList.add('my-svg-path')
                        contentViewSvg.appendChild(contentViewPath2);
                    }else{

                        this.videoPlayButton.classList.add('video-progress-bar');
                        this.videoPlayButton.style.width = imageContent.width+"px";
                        this.videoPlayButton.style.height = imageContent.height+"px";
                        this.videoPlayButton.style.lineHeight = imageContent.height+"px";
                        this.videoPlayButton.style.textAlign = "center";
                        let playTitleSpan = document.createElement("span");
                        this.videoPlayButton.appendChild(playTitleSpan);
                        playTitleSpan.innerText = "VideoNotFound"
                        playTitleSpan.style.color = "#ffffff"
                    }
                }
            } else if (!imageContent.isVideo()) {
                this.removeVideoPlayButton();
            }
            
            
        }
        
    }
    setVideoImage(){

        this.imageVideoViewTap =  document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.imageVideoViewTap.style.width = 300+'px';
        this.imageVideoViewTap.style.height = 150+'px';
        this.context.appendChild(this.imageVideoViewTap);
        


        let videoText = document.createElement("video");
        
        this.imageVideoViewTap.appendChild(videoText);

        let videoSource = document.createElement("source"); 
        videoText.appendChild(videoSource);
        videoText.setAttribute('controls','controls');
        // videoText.setAttribute('width',300);
        // videoText.ready(function() {
        //     var myPlayer = this;
        // let url = require("../../../assets/img/Usehelp-English/4-DE-link-web.mp4")
        // videoSource.setAttribute('src',url); /*动态设置video.js播放的地址。*/
        //     myPlayer.autoplay();
        // });

        var canvas = document.createElement("canvas");

        videoText.currentTime = 2;

        setTimeout(() => {
            
            canvas.width = videoText.videoWidth * 0.8;
            canvas.height = videoText.videoHeight * 0.8;
                
            canvas.getContext('2d').drawImage(videoText, 0, 0, canvas.width, canvas.height);
            

            var img = document.createElement("img");
            img.src = canvas.toDataURL("image/png");
            this.imageVideoView.appendChild(img);
            this.imageVideoViewTap.appendChild(this.imageVideoView);
            
        }, 10000);
    }

    setLinkIcon() {
        if (this.mindElementData == null) {
            return;
        }
        if (!this.mindElementData.isContainLinkContent() || this.getIsShowMore()) {
            if (this.linkIcon != null) {
                this.linkIcon.remove();
                this.removeClickListener()
                this.linkIcon.setData(null);
                this.linkIcon = null;
            }
            return;
        }
        let linkElementContent = this.mindElementData.linkElementContent;
        if (this.linkIcon == null) {
            let iconElementContent = new IconElementContent("mind_link_icon", linkElementContent.width, linkElementContent.height, Colors.clear, this.getTextColor());
            if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
                if (this.mindElementData.imageContent == null) {
                    this.linkIcon = new MindElementIconOutlineCell(this.iconContent, iconElementContent, MindElementIconCellType.LINK);
                } else {
                    this.linkIcon = new MindElementIconOutlineCell(this.iconAndImageContent, iconElementContent, MindElementIconCellType.LINK);
                }
            } else {
                this.linkIcon = new MindElementIconCell(this.context, iconElementContent, MindElementIconCellType.LINK);
            }
            this.linkIcon.deletage = this;
        } else {
            this.linkIcon.data.tintColor = this.getTextColor();
            this.linkIcon.setIconColor();
        }
    }

    setRemarksIcon() {
        if (this.mindElementData == null) {
            return;
        }
        if (!this.mindElementData.isContainRemarksContent() || this.getIsShowMore()) {
            if (this.remarksIcon != null) {
                this.remarksIcon.remove()
                this.remarksIcon.setData(null);
                this.remarksIcon = null;
            }
            return;
        }
        let remarksElementContent = this.mindElementData.remarksElementContent;
        if (this.remarksIcon == null) {
            let iconElementContent = new IconElementContent("remarks_icon",
                remarksElementContent.width, remarksElementContent.height,
                Colors.clear, this.getTextColor());
            if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
                if (this.mindElementData.imageContent == null) {
                    this.remarksIcon = new MindElementIconOutlineCell(this.iconContent, iconElementContent, MindElementIconCellType.REMARKS);
                } else {
                    this.remarksIcon = new MindElementIconOutlineCell(this.iconAndImageContent, iconElementContent, MindElementIconCellType.REMARKS);
                }
            } else {
                this.remarksIcon = new MindElementIconCell(this.context, iconElementContent, MindElementIconCellType.REMARKS);
            }
            this.remarksIcon.deletage = this;
        } else {
            this.remarksIcon.data.tintColor = this.getTextColor();
            this.remarksIcon.setIconColor();
        }
    }

    setResourceIcon() {
        if (this.mindElementData == null) {
            return;
        }
        if (!this.mindElementData.isContainResourceContent() || this.getIsShowMore()) {
            if (this.resourceIcon != null && this.resourceIcon.data != null) {
                this.resourceIcon.remove()
                this.resourceIcon.setData(null);
                this.resourceIcon = null;
            }
            return;
        }
        let resourceContent = this.mindElementData.resourceContent;
        if (this.resourceIcon == null) {
            let iconElementContent = new IconElementContent(this.getResourceIconName(resourceContent.type),
            resourceContent.width, resourceContent.height,
                Colors.clear, this.getTextColor());
            if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
                if (this.mindElementData.imageContent == null) {
                    this.resourceIcon = new MindElementIconOutlineCell(this.iconContent, iconElementContent, MindElementIconCellType.RESOURCE);
                } else {
                    this.resourceIcon = new MindElementIconOutlineCell(this.iconAndImageContent, iconElementContent, MindElementIconCellType.RESOURCE);
                }
            } else {
                this.resourceIcon = new MindElementIconCell(this.context, iconElementContent, MindElementIconCellType.RESOURCE);
            }
            this.resourceIcon.deletage = this;
        } else {
            this.resourceIcon.data.tintColor = this.getTextColor();
            this.resourceIcon.setIconColor();
        }
    }

    getResourceIconName( type) {
        switch (type) {
            case ResourceType.EXCEL:
                return "mind_element_xcl";
            case ResourceType.PDF:
                return "mind_element_pdf";
            case ResourceType.WORD:
                return "mind_element_word";
            case ResourceType.TXT:
                return "mind_element_txt";
            case ResourceType.IMAGE:
                return "mind_element_image";
            case ResourceType.VIDEO:
                return "mind_element_voide";
            case ResourceType.AUDIO:
                return "mind_element_audio";
            case ResourceType.ATTCHMENT:
                return "mind_element_attchment";
            default:
                return "mind_element_attchment";
        }
    }

    setMoreIcon() {
        if (this.mindElementData == null) {
            return;
        }
        if (!this.getIsShowMore()) {
            if (this.moreIcon != null && this.moreIcon.data != null) {
                this.moreIcon.remove()
                this.moreIcon.setData(null);
                this.moreIcon = null;
            }
            return;
        }
        let moreContent = this.mindElementData.moreContent;
        if (this.moreIcon == null) {
            let iconElementContent = new IconElementContent("mind_element_more",
            moreContent.width, moreContent.height,
                Colors.clear, this.getTextColor());
            // CONNECT_MAP应该指的是关联导图图标。
            this.moreIcon = new MindElementIconCell(this.context, iconElementContent, MindElementIconCellType.MORE);
            this.moreIcon.deletage = this;
        } else {
            this.moreIcon.data.tintColor = this.getTextColor();
            this.moreIcon.setIconColor();
        }
    }


    setConnectMapIcon() {

        if (this.mindElementData == null) {
            return;
        }

        if (!this.mindElementData.isContainConnectMapContent() || this.getIsShowMore()) {
            if (this.connectMapIcon != null) {
                this.connectMapIcon.remove()
                this.connectMapIcon.setData(null);
                this.connectMapIcon = null;
            }

            return;
        }
        let connectMapElementContent = this.mindElementData.connectMapElementContent;
        if (this.connectMapIcon == null) {
            let iconElementContent = new IconElementContent("lianxian_map_icon",
                connectMapElementContent.width, connectMapElementContent.height,
                Colors.clear, this.getTextColor());
            // CONNECT_MAP应该指的是关联导图图标。
            this.connectMapIcon = new MindElementIconCell(this.context, iconElementContent, MindElementIconCellType.CONNECT_MAP);
            this.connectMapIcon.deletage = this;
        } else {
            this.connectMapIcon.data.tintColor = this.getTextColor();
            this.connectMapIcon.setIconColor();
        }
    }

    setMindNodeUpNumberView() {

        if (this.mindElementData.hiddenNumber == 0 || this.mindElementData.textContent == null) {
            if (this.mindNodeUpNumberView != null) {
                this.mindNodeUpNumberView.hideen()//设置隐藏
            }
            return;
        }
        if (this.mindNodeUpNumberView == null) {
            this.mindNodeUpNumberView = new MindNodeUpNumberView(this.context, { width: Config.showHideCircleDia, height: Config.showHideCircleDia },
                this, this.mindElementData);
            this.mindNodeUpNumberView.hideen()
        }
        //设置可见
        this.mindNodeUpNumberView.showDom();
        this.mindNodeUpNumberView.setNumber(this.mindElementData.hiddenNumber);

        if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor != null) {
            let lineColor = this.mindElementData.borderColor;
            if (Colors.isClear(lineColor) || this.mindElementData.borderWidth == 0) {
                lineColor = this.mindElementData.textContent.textColor;
            }
            let bgColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor;
            this.mindNodeUpNumberView.setColor(bgColor, lineColor);
        }

    }

    setMindNodeUpNumberViewLayout() {
        if (this.mindElementData.hiddenNumber == 0 || this.mindNodeUpNumberView == null) {
            return;
        }
        let margin = 4;

        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT || 
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            let x = - this.mindNodeUpNumberView.size.width;
            let y = this.mindElementData.height / 2 - this.mindNodeUpNumberView.size.height / 2;
            if (this.mindElementData.mindElementShape == MindElementShapeType.Underline ||
                this.mindElementData.mindElementShape == MindElementShapeType.Two_Underline) {
                y = this.mindElementData.height - this.mindNodeUpNumberView.size.height / 2;
                x += margin
            }
            this.mindNodeUpNumberView.setPoint(x - margin, y);

        } else if (
            this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let x = this.mindElementData.width;
            let y = this.mindElementData.height / 2 - this.mindNodeUpNumberView.size.height / 2;
            if (this.mindElementData.mindElementShape == MindElementShapeType.Underline ||
                this.mindElementData.mindElementShape == MindElementShapeType.Two_Underline) {
                y = this.mindElementData.height - this.mindNodeUpNumberView.size.height / 2;
                x -= margin
            }
            this.mindNodeUpNumberView.setPoint(x + margin, y);
        } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
            let x = this.mindElementData.width / 2 - this.mindNodeUpNumberView.size.width / 2;
            let y = this.mindElementData.height;
            this.mindNodeUpNumberView.setPoint(x, y + margin);
        } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            let x = this.mindElementData.width / 2 - this.mindNodeUpNumberView.size.width / 2;
            let y = - this.mindNodeUpNumberView.size.height;
            this.mindNodeUpNumberView.setPoint(x, y - margin);
        }
    }

    setData(data) {
        super.setData(data);
    }

    onTextSizeChanged(text, width, height) {
        if (this.mindElementData == null) {
            return;
        }
        if (this.mindTextSizeChangedDelegate != null) {
            this.mindTextSizeChangedDelegate.onTextSizeChanged(text, width, height, this.mindElementData.id);
        }
        this.refresh();
    }

    setInputTextViewLayout() {
        if (this.mindElementData == null) {
            return;
        }
        let textMindElementContent = this.getTextContent();
        let fontSize = textMindElementContent.textFontSize;

        let addWidthForTextCalculation = Math.min(15, fontSize * 2);
        let textAlignment = this.getInputTextAlignment()
        let edgeInsets = this.getTextUIEdgeInsets()
        let textWidth = textMindElementContent.width;
        let textHeight = textMindElementContent.height;
        let mindContentWidth = textWidth + addWidthForTextCalculation
        if (textMindElementContent.x + mindContentWidth - edgeInsets.right > this.mindElementData.width) {
            mindContentWidth = this.mindElementData.width - textMindElementContent.x + edgeInsets.right
            addWidthForTextCalculation = mindContentWidth - textWidth
        }

        this.inputTextView.style.paddingLeft = 0 + 'px'
        this.inputTextView.style.paddingTop = edgeInsets.top + 'px'
        this.inputTextView.style.paddingRight = 0 + 'px'
        this.inputTextView.style.paddingBottom = edgeInsets.bottom + 'px'

        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let textX = textMindElementContent.x;
            let textY = textMindElementContent.y;
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            //如果没有图标且没有图片时
            if ((this.mindElementData.iconElementContents == null || 
                this.mindElementData.iconElementContents.length == 0) &&
                this.mindElementData.imageContent == null) {
                textY = (this.mindElementData.height - taskHeight - textHeight) / 2;
            }
            //如果有图标时
            if (this.mindElementData.iconElementContents != null && this.mindElementData.iconElementContents.length > 0) {
                //如果图标为左布局或右布局
                if (this.mindElementData.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_LEFT ||
                    this.mindElementData.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_RIGHT) {
                    textY = (this.mindElementData.height - taskHeight - textHeight) / 2;
                }
            }
            //字体为18像素的时候计算有问题。所以宽度加1像素。
            this.inputTextView.style.width = (mindContentWidth - edgeInsets.left - edgeInsets.right) + "px";
            //单纯的div和svg中的div好像相同的letterSpacing，单字体间距不同。这里微调一下。
            this.inputTextView.style.height = textHeight + "px";

            if (textAlignment == MindElementAlignmentType.MIDDLE) {
                textX -= (addWidthForTextCalculation/2)
            } else if (textAlignment == MindElementAlignmentType.RIGHT) {
                textX -= addWidthForTextCalculation
            }
            this.inputTextView.style.marginLeft = edgeInsets.left + "px"
            this.inputTextView.style.marginRight = edgeInsets.right + "px"

            this.mindContent.setAttribute('x', textX);
            this.mindContent.setAttribute('y', textY);
            this.mindContent.setAttribute('width', mindContentWidth);
            this.mindContent.setAttribute('height', textHeight);
        } else {
            let textX = textMindElementContent.x;
            let textY = textMindElementContent.y;
            this.inputTextView.style.width = (mindContentWidth - edgeInsets.left - edgeInsets.right) + "px";
            this.inputTextView.style.height = textHeight + "px";

            if (textAlignment == MindElementAlignmentType.MIDDLE) {
                textX -= (addWidthForTextCalculation/2)
                this.inputTextView.style.marginLeft = (edgeInsets.left) + "px"
                this.inputTextView.style.marginRight = (edgeInsets.right - addWidthForTextCalculation) + "px"
            } else if (textAlignment == MindElementAlignmentType.RIGHT) {
                textX -= addWidthForTextCalculation
                this.inputTextView.style.marginLeft = (edgeInsets.left) + "px"
                this.inputTextView.style.marginRight = (edgeInsets.right - addWidthForTextCalculation) + "px"
            } else {
                this.inputTextView.style.marginLeft = edgeInsets.left + "px"
                this.inputTextView.style.marginRight = (edgeInsets.right - addWidthForTextCalculation) + "px"
            }
            
            

            this.mindContent.setAttribute('x', textX);
            this.mindContent.setAttribute('y', textY);

            if (this.mindElementData.iconElementContents != null &&
                this.mindElementData.iconElementContents.length > 0 &&
                textMindElementContent.text == '') {
                this.mindContent.setAttribute('width', 0);
                this.mindContent.setAttribute('height', 0);
            } else {
                //为了比避免某些文字计算不准确，加了5像素宽度
                this.mindContent.setAttribute('width', mindContentWidth);
                this.mindContent.setAttribute('height', textHeight);
            }
        }
    }

    setTitleInputTextViewLayout() {
        if (this.mindElementData == null || 
            !this.mindElementData.isCardContent() ||
            this.titleInputTextView == null) {
            return;
        }
        let textMindElementContent = this.mindElementData.titleContent
        let fontSize = textMindElementContent.textFontSize;

        let addWidthForTextCalculation = Math.min(15, fontSize * 2);
        let textAlignment = this.getInputTextAlignment()
        let edgeInsets = this.getTextUIEdgeInsets()
        let textWidth = textMindElementContent.width;
        let textHeight = textMindElementContent.height;
        let mindContentWidth = textWidth + addWidthForTextCalculation
        if (textMindElementContent.x + mindContentWidth - edgeInsets.right > this.mindElementData.width) {
            mindContentWidth = this.mindElementData.width - textMindElementContent.x + edgeInsets.right
            addWidthForTextCalculation = mindContentWidth - textWidth
        }

        this.titleInputTextView.style.paddingLeft = 0 + 'px'
        this.titleInputTextView.style.paddingTop = edgeInsets.top + 'px'
        this.titleInputTextView.style.paddingRight = 0 + 'px'
        this.titleInputTextView.style.paddingBottom = edgeInsets.bottom + 'px'

        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let textX = textMindElementContent.x;
            let textY = textMindElementContent.y;
            let taskHeight = this.mindElementData.taskContent == null ? 0 : this.mindElementData.taskContent.height
            //如果没有图标且没有图片时
            if ((this.mindElementData.iconElementContents == null || 
                this.mindElementData.iconElementContents.length == 0) &&
                this.mindElementData.imageContent == null) {
                textY = (this.mindElementData.height - taskHeight - textHeight) / 2;
            }
            //如果有图标时
            if (this.mindElementData.iconElementContents != null && this.mindElementData.iconElementContents.length > 0) {
                //如果图标为左布局或右布局
                if (this.mindElementData.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_LEFT ||
                    this.mindElementData.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_RIGHT) {
                    textY = (this.mindElementData.height - taskHeight - textHeight) / 2;
                }
            }
            //字体为18像素的时候计算有问题。所以宽度加1像素。
            this.titleInputTextView.style.width = (mindContentWidth - edgeInsets.left - edgeInsets.right) + "px";
            //单纯的div和svg中的div好像相同的letterSpacing，单字体间距不同。这里微调一下。
            this.titleInputTextView.style.height = textHeight + "px";

            if (textAlignment == MindElementAlignmentType.MIDDLE) {
                textX -= (addWidthForTextCalculation/2)
            } else if (textAlignment == MindElementAlignmentType.RIGHT) {
                textX -= addWidthForTextCalculation
            }
            this.titleInputTextView.style.marginLeft = edgeInsets.left + "px"
            this.titleInputTextView.style.marginRight = edgeInsets.right + "px"

            this.mindContent.setAttribute('x', textX);
            this.mindContent.setAttribute('y', textY);
            this.mindContent.setAttribute('width', mindContentWidth);
            this.mindContent.setAttribute('height', textHeight);
        } else {
            let textX = textMindElementContent.x;
            let textY = textMindElementContent.y;
            this.titleInputTextView.style.width = (mindContentWidth - edgeInsets.left - edgeInsets.right) + "px";
            this.titleInputTextView.style.height = textHeight + "px";

            if (textAlignment == MindElementAlignmentType.MIDDLE) {
                textX -= (addWidthForTextCalculation/2)
                this.titleInputTextView.style.marginLeft = (edgeInsets.left) + "px"
                this.titleInputTextView.style.marginRight = (edgeInsets.right - addWidthForTextCalculation) + "px"
            } else if (textAlignment == MindElementAlignmentType.RIGHT) {
                textX -= addWidthForTextCalculation
                this.titleInputTextView.style.marginLeft = (edgeInsets.left) + "px"
                this.titleInputTextView.style.marginRight = (edgeInsets.right - addWidthForTextCalculation) + "px"
            } else {
                this.titleInputTextView.style.marginLeft = edgeInsets.left + "px"
                this.titleInputTextView.style.marginRight = (edgeInsets.right - addWidthForTextCalculation) + "px"
            }
            
            

            this.titleMindContent.setAttribute('x', textX);
            this.titleMindContent.setAttribute('y', textY);
            if (this.mindElementData.iconElementContents != null &&
                this.mindElementData.iconElementContents.length > 0 &&
                textMindElementContent.text == '') {
                this.titleMindContent.setAttribute('width', 0);
                this.titleMindContent.setAttribute('height', 0);
            } else {
                //为了比避免某些文字计算不准确，加了5像素宽度
                this.titleMindContent.setAttribute('width', mindContentWidth);
                this.titleMindContent.setAttribute('height', textHeight);
            }
        }
    }

    setOrderTextViewLayout() {
        if (this.mindElementData == null || this.mindElementData.orderContent == null || this.orderTextView == null) {
            return;
        }
        let textMindElementContent = this.getTextContent();
        let fontSize = textMindElementContent.textFontSize;

        let addWidthForTextCalculation = 0;//fontSize;
        let edgeInsets = this.getTextUIEdgeInsets()
        let textWidth = this.mindElementData.orderContent.width;
        let textHeight = this.mindElementData.orderContent.height;
        let mindContentWidth = textWidth + addWidthForTextCalculation
        
        this.orderTextView.style.paddingLeft = 0 + 'px'
        this.orderTextView.style.paddingTop = 0 + 'px'//edgeInsets.top + 'px'
        this.orderTextView.style.paddingRight = 0 + 'px'
        this.orderTextView.style.paddingBottom = edgeInsets.bottom + 'px'
        let textX = this.mindElementData.orderContent.x;
        let textY = this.mindElementData.orderContent.y;
        this.orderTextView.style.width = (mindContentWidth) + "px";
        this.orderTextView.style.height = textHeight + "px";

        textX -= addWidthForTextCalculation
        this.orderTextView.style.marginLeft = 0 + 'px'//(edgeInsets.left) + "px"
        this.orderTextView.style.marginRight = 0 + 'px'//(edgeInsets.right - addWidthForTextCalculation) + "px"
        
        

        this.mindOrderContent.setAttribute('x', textX);
        this.mindOrderContent.setAttribute('y', textY);            
        this.mindOrderContent.setAttribute('width', mindContentWidth);
        this.mindOrderContent.setAttribute('height', textHeight);
    }

    getTextContent() {
        if (this.mindElementData.textContent != null) {
            if (this.mindElementData.textContent.text == '') {
            }
            return this.mindElementData.textContent;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent;
        } else {
            return new TextContent("");
        }
    }

    getTextUIEdgeInsets() {
        return (new TextCalculation(this.mindElementData)).getTextEdgeInsets();
    }
    //单击图标
    onSelectedIcon(iconData, icon, event, clickType = null) {
        this.mindTextSizeChangedDelegate.onSelectedIcon(this.mindElementData.id, iconData, icon, event, clickType);
    }
    //单击视频播放按钮图标
    onSelectedPlayIcon() {
    }
    //单击图片
    onSelectedImg(data, event, clickType) {
        if (clickType == 2) {
            this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1), { type: 'rightClick' }, event);
        }
        this.mindTextSizeChangedDelegate.onSelectedImg({ data, point: new Point(-1, -1), mindThis: this, event, clickType });
    }

    setData(data) {
        super.setData(data)
        this.setClickListener();
    }

    setClickListener() {
        if (this.inputGesture == null) {

            this.inputGesture = (ev) => {
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                this.mindTextSizeChangedDelegate.onDoubleClickSelected(this.mindElementData, new Point(-1, -1), { type: "dblclick" });
                this.hideAddNodeIcon()
                this.setSelected(true)

            };
            this.subScript = (ev) => {
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                this.mindTextSizeChangedDelegate.onDoubleClickSelected(this.mindElementData, new Point(-1, -1), { type: "dblclick", button: "subScript" });
                this.setSelected(true)

            };
            this.titleDoubleClick = (ev) => {
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                this.mindTextSizeChangedDelegate.onDoubleClickSelected(this.mindElementData, new Point(-1, -1), { type: "dblclick", button: "titleDoubleClick" });
                this.setSelected(true)

            };
            this.onClickListener = (ev) => {
                // window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                //写单击事件要干的事
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                if (ev.shiftKey == 1) {
                    EditMindmapVM.setSelectedNodesById(this.mindElementData.id);
                } else {
                    this.mindTextSizeChangedDelegate.onElementViewClickDown(this.mindElementData, new Point(ev.clientX, ev.clientY), { type: "mousedown" });
                    this.setSelected(true)
                }

            };
            this.imgClick = (ev) => {//up
                if (!this.addNodeViewMouesDown || (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus())) {
                    this.addNodeViewMouesDown = false
                    return
                }
                this.addNodeClickTime = Util.getCurrentTime()
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                let addData = { type: 'smallIcon' }
                this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1), addData);
                this.setSelected(true)
                this.addNodeViewMouesDown = false
            }
            this.addNodeDown = (ev) => {//down
                this.addNodeViewMouesDown = true
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                this.addNodeClickTime = Util.getCurrentTime()
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();                
                this.setSelected(true)
                // setTimeout(() => {
                //     this.hideAddNodeIcon(true, true)
                // }, 1000);
                this.addNodeViewMouesDown = true
            }
            this.imgDblclick = (ev) => {
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            }
            //右击事件
            this.rightClick = (ev) => {
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                if (ev.button == 2 || (ev.button == 0 && ev.ctrlKey == true)) { //右键
                    this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1), { type: 'rightClick' }, ev);
                    this.setSelected(true)
                } else if (ev.button == 0) { //左键松开鼠标
                    this.mindTextSizeChangedDelegate.onElementViewClickUp(this.mindElementData, new Point(ev.clientX, ev.clientY));
                }
            }

            //右击事件
            this.completionClick = (ev) => {
                if (this.mindTextSizeChangedDelegate != null && this.mindTextSizeChangedDelegate.isPlayStatus()) {
                    return
                }
                // if (ev.button == 2 || ev.button == 0) {
                    this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1), { type: 'completionClick' }, ev);
                    this.setSelected(true)
                // }

            }

            if (EditMindmapVM.mindDisplayType != MindDisplayType.Outline) {
                this.context.addEventListener('mousedown', this.onClickListener);
                this.context.addEventListener('dblclick', this.inputGesture);
                if (this.addChildIconImg != null) {
                    this.addChildIconImg.addEventListener('mouseup', this.imgClick);
                    // this.addChildIconImg.addEventListener('mouseup', this.imgClick);
                    this.addChildIconImg.addEventListener('dblclick', this.imgDblclick);    //主要是为了防止触发双击编辑框的内容。
                }
                this.context.addEventListener('mouseup', this.rightClick);
                if (this.explainLabelView != null) {
                    this.explainLabelView.foreignObjectBox.addEventListener('dblclick', this.subScript);
                }
                if (this.titleMindContent != null) {
                    this.titleMindContent.addEventListener('dblclick', this.titleDoubleClick);
                }
            }
        }

    }
    removePx(string) {
        return string.substring(0, string.length - 2)
    }

    setOnLongClick(listener) {
        this.onLongClickListener = listener;
        if (listener == null) {
            this.setOnLongClickListener(null);
            this.inputTextView.setOnLongClickListener(null);
            this.backgroundView.setOnLongClickListener(null);
        } else {
            this.setOnLongClickListener(this);
            this.backgroundView.setOnLongClickListener(this);
            this.inputTextView.setOnLongClickListener(this);
        }
    }

    onLongClick(view) {
        if (this.onLongClickListener != null) {
            this.onLongClickListener.onLongClick(view);
            return true;
        }
        return false;
    }

    removeClickListener() {
        if (this.inputGesture != null) {
            this.context.removeEventListener('dblclick', this.inputGesture, false);
            this.inputGesture = null;
        }
    }

    setInputEdit(bool) {
        this.inputTextView.setAttribute('contenteditable', bool);
    }

    setStyle() {
        super.setStyle();

        this.setTextStyleData();
        if (this.mindElementData == null) {
            return;
        }
        let textAlignment = this.getInputTextAlignment()
        if (textAlignment == MindElementAlignmentType.MIDDLE) {
            this.inputTextView.style.textAlign = 'center'
        } else if (textAlignment == MindElementAlignmentType.RIGHT) {
            this.inputTextView.style.textAlign = 'right'
        } else {
            this.inputTextView.style.textAlign = 'left'
        }
        if (this.titleInputTextView != null) {
            if (textAlignment == MindElementAlignmentType.MIDDLE) {
                this.titleInputTextView.style.textAlign = 'center'
            } else if (textAlignment == MindElementAlignmentType.RIGHT) {
                this.titleInputTextView.style.textAlign = 'right'
            } else {
                this.titleInputTextView.style.textAlign = 'left'
            }
        }
    }

    getInputTextAlignment() {
        let text = this.getTextContent();
        let edgeInsets = this.getTextUIEdgeInsets();
        if (this.mindElementData.mindElementShape == MindElementShapeType.Circular ||
            this.mindElementData.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring2 ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring2_UP ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ellipse ||
            this.mindElementData.mindElementShape == MindElementShapeType.Ring3 ||
            this.mindElementData.mindElementShape == MindElementShapeType.Diamond ||
            this.mindElementData.mindElementShape == MindElementShapeType.Triangle ||
            this.mindElementData.mindElementShape == MindElementShapeType.InvertedTriangle ||
            this.mindElementData.mindElementShape == MindElementShapeType.LeftTriangle ||
            this.mindElementData.mindElementShape == MindElementShapeType.RightTriangle ||
            this.mindElementData.mindElementShape == MindElementShapeType.Gemstone ||
            this.mindElementData.mindElementShape == MindElementShapeType.Heart||
            this.mindElementData.mindElementShape == MindElementShapeType.Octagonal ||
            this.mindElementData.mindElementShape == MindElementShapeType.Regular_hexagon||
            this.mindElementData.mindElementShape == MindElementShapeType.Shield ||
            this.mindElementData.mindElementShape == MindElementShapeType.Book ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE ||
            this.mindElementData.alignmentType == MindElementAlignmentType.MIDDLE ||            
            (text != null && text.width <= this.UiUtil.dip2px(50) + edgeInsets.left + edgeInsets.top)) {
            return MindElementAlignmentType.MIDDLE;
        } else {
            return this.mindElementData.alignmentType == MindElementAlignmentType.UNKNOWN ? MindElementAlignmentType.LEFT : this.mindElementData.alignmentType;
        }
    }

    setTextStyleData() {
        this.inputTextView.style.fontSize = 1 + 'px'
        this.inputTextView.style.lineHeight = Config.textLineHeight

        this.inputTextView.style.color = Colors.getUiColor(this.getTextColor())
        this.inputTextView.setAttribute('abcdef', this.getTextColor())
        this.inputTextView.setAttribute('node-unique-id', 'NodeUniqueID'+ this.mindElementData.id)
        
        if (this.getTextBold()) { //字体是否加粗
            this.inputTextView.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.inputTextView.style.fontWeight = Config.textNormalFontWeight
        }

        if (this.getTextItalics()) {    //设置为斜体
            this.inputTextView.style.fontStyle = 'italic'
        } else {
            this.inputTextView.style.fontStyle = 'normal'
        }

        if (this.getTextStrikethrough()) {    //删除线
            this.inputTextView.style.textDecoration = 'line-through'
        } else {
            this.inputTextView.style.textDecoration = 'none'
        }
    }


    getTextColor() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.textColor;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.textColor;
        }
        return 0xff3300;
    }

    getTextBold() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.textBold;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.textBold;
        }
        return false;
    }

    getTextItalics() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.textItalics;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.textItalics;
        }
        return false;
    }

    getTextStrikethrough() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.textStrikethrough;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.textStrikethrough;
        }
        return false;
    }

    getTextFontSize() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.textFontSize;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.textFontSize;
        }
        return 18;
    }

    getTextBgColor() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.bgColor;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.bgColor;
        }
        return 18;
    }

    getTextShadow() {
        if (this.mindElementData.textContent != null) {
            return this.mindElementData.textContent.textShadow;
        } else if (this.mindElementData.generalizationContent != null) {
            return this.mindElementData.generalizationContent.textShadow;
        }
        return 18;
    }

    getContentText(textContent = null) {
        let text = "";
        let textStyles = [];
        
        let textShadow = this.getTextShadow();
        let bgColor = this.getTextBgColor();

        let fontSize = this.getTextFontSize();
        let color = Colors.getUiColor(this.getTextColor());
        let bold = this.getTextBold();
        let italics = this.getTextItalics();
        let strikethrough = this.getTextStrikethrough();

        if (this.mindElementData.textContent != null) {
            text = this.mindElementData.textContent.getContentText();
            textStyles = this.mindElementData.textContent.styleCells;
        } else if (this.mindElementData.generalizationContent != null) {
            text = this.mindElementData.generalizationContent.getContentText();
            textStyles = this.mindElementData.generalizationContent.styleCells;
        }
        if (textContent != null) {
            textShadow = textContent.textShadow
            bgColor = textContent.bgColor
            fontSize = textContent.textFontSize
            bold = textContent.textBold
            italics = textContent.textItalics
            strikethrough = textContent.textStrikethrough
            text = textContent.getContentText();
            textStyles = textContent.styleCells;
            color = Colors.getUiColor(textContent.textColor);
        }

        if (textStyles == null || textStyles.length == 0 ||
            text == null || text.length == 0) {
                
            let tag = "<div><span id='0' style='font-size:" + fontSize + "px;letter-spacing: 1px;line-height: " + Config.textLineHeight + "; " + Config.TextWritingModeVertical_LR;
            if (bgColor != -10000 && !Colors.isClear(bgColor)) {
                tag = tag +  "background-color:" + Colors.getUiColor(bgColor) + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;";
            }
            if (textShadow != -10000 && !Colors.isClear(textShadow)) {
                let offset = 1
                if (fontSize <= 16) {
                    offset = 0.6;
                } else if (fontSize > 16 && fontSize <= 28) {
                    offset = 1;
                } else {
                    offset = 2;
                }
                tag = tag +  "text-shadow: " + offset + "px " + offset + "px " + Colors.getUiColor(textShadow) + ";"; 
            }
            tag = tag + "' class='no_font_size' >" + text + "</span></div>";
            return tag
        }
        let textStyleMap = new Array();
        let textCount = text.length
        let styleCount = textStyles.length
        let separator = "|"
        let textSeparator = separator
        let colorSeparator = "c——" + separator
        let bgcolorSeparator = "g——" + separator
        let fontSizeSeparator = "f——" + separator
        let boldSeparator = "b" + separator
        let italicsSeparator = "i" + separator
        let strikethroughSeparator = "s——" + separator
        let shadowColorSeparator = "d——" + separator
        var lastTextCellStyle = ""

        let indexSubscript = 1;
        for (let index = 0; index < textCount; index++) {
            let cellText = text.substring(index, index + 1)
            var value = cellText + textSeparator
            var cellStyle = ""
            for (let styleindex = 0; styleindex < styleCount; styleindex++) {
                let cell = textStyles[styleindex]
                if (cell.startIndex <= index && cell.endIndex > index) {
                    if (cell.isSetColor()) {
                        cellStyle += cell.textColor + colorSeparator;
                    }
                    if (cell.isBackgorunColor()) {
                        cellStyle += cell.bgColor + bgcolorSeparator;
                    }
                    if (cell.isTextShadowColor()) {
                        cellStyle += cell.textShadow + shadowColorSeparator;
                    }
                    if (cell.isSetFontSize()) {
                        cellStyle += cell.textFontSize + fontSizeSeparator;
                    }
                    if (cell.textBold.isSet()) {
                        cellStyle += cell.textBold.isValue() + boldSeparator;
                    }
                    if (cell.textItalics.isSet()) {
                        cellStyle += cell.textItalics.isValue() + italicsSeparator;
                    }
                    if (cell.textStrikethrough.isSet()) {
                        cellStyle += cell.textStrikethrough.isValue() + strikethroughSeparator;
                    }
                }
            }
            value += cellStyle
            if (cellStyle == lastTextCellStyle && index > 0) {
                let preCell = textStyleMap[index - indexSubscript]
                let arr = [];
                if (preCell != null && preCell != "") {
                    arr = preCell.split(textSeparator);
                }
                if (arr.length >= 2) {
                    textStyleMap.splice(index - indexSubscript, 1)
                    indexSubscript++;
                    textStyleMap.push(arr[0] + cellText + textSeparator + cellStyle)
                } else {
                    textStyleMap.push(value)
                }
            } else {
                textStyleMap.push(value)
            }
            lastTextCellStyle = cellStyle
        }
        let textStyleMapCount = textStyleMap.length
        if (textStyleMapCount == 1 &&
            textStyleMap[0].indexOf(colorSeparator) == -1 &&
            textStyleMap[0].indexOf(bgcolorSeparator) == -1 &&
            textStyleMap[0].indexOf(shadowColorSeparator) == -1 &&
            textStyleMap[0].indexOf(fontSizeSeparator) == -1 &&
            textStyleMap[0].indexOf(boldSeparator) == -1 &&
            textStyleMap[0].indexOf(italicsSeparator) == -1 &&
            textStyleMap[0].indexOf(strikethroughSeparator) == -1) {
            return "<div><span id='0' style='font-size:" + fontSize + "px;letter-spacing: 1px;line-height: " + Config.textLineHeight + ";" + Config.TextWritingModeVertical_LR + "' class='no_font_size' >" + text + "</span></div>"
        }
        var pTagElement = "<div>";
        for (let index = 0; index < textStyleMapCount; index++) {
            let value = textStyleMap[index]
            let values = value.split(separator)

            if (values.length == 2 && values[1] == "") {
                pTagElement = pTagElement + "<span id='" + index + "' style='font-size:" + fontSize + 
                "px;letter-spacing: 1px;line-height: " + Config.textLineHeight + ";" + Config.TextWritingModeVertical_LR
                if (bgColor != -10000 && !Colors.isClear(bgColor)) {
                    pTagElement = pTagElement +  "background-color:" + Colors.getUiColor(bgColor) + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;";
                }
                if (textShadow != -10000 && !Colors.isClear(textShadow)) {
                    let offset = 1;
                    if (fontSize <= 16) {
                        offset = 0.6;
                    } else if (fontSize > 16 && fontSize <= 28) {
                        offset = 1;
                    } else {
                        offset = 2;
                    }
                    pTagElement = pTagElement +  "text-shadow: " + offset + "px " + offset + "px " + Colors.getUiColor(textShadow) + ";"; 
                }
                pTagElement = pTagElement + "' class='no_font_size' >" + values[0] + "</span>"

            } else {
                let styleText = this.getSplicingSpan(value, values, fontSize);
                pTagElement += styleText;

            }
        }
        pTagElement += "</div>"
        return pTagElement;
    }

    getSplicingSpan(val, vals, fontSize) {
        let separator = "|"
        let colorSeparator = "c——" + separator
        let bgcolorSeparator = "g——" + separator
        let fontSizeSeparator = "f——" + separator
        let boldSeparator = "b" + separator
        let italicsSeparator = "i" + separator
        let strikethroughSeparator = "s——" + separator
        let shadowColorSeparator = "d——" + separator

        let valsCount = vals.length;

        let pTagElement = "";
        pTagElement += "<span style='letter-spacing: 1px;line-height: " + Config.textLineHeight + ";" + Config.TextWritingModeVertical_LR

        if (val.indexOf(colorSeparator) != -1) {

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valColor = vals[index]
                if (valColor.indexOf("c——") != -1) {

                    valStyle = valColor;

                }
            }
            let valStyles = valStyle.split("c——");
            let colorSpan = Colors.getUiColor(parseInt(valStyles[0]));
            if (parseInt(valStyles[0]) != -10000) {
                pTagElement += "color:" + colorSpan + ";"
            }
        }
        if (val.indexOf(bgcolorSeparator) != -1) {

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valColor = vals[index]
                if (valColor.indexOf("g——") != -1) {

                    valStyle = valColor;

                }
            }
            let valStyles = valStyle.split("g——");
            let colorSpan = Colors.getUiColor(parseInt(valStyles[0]));
            if (parseInt(valStyles[0]) != -10000) {
                pTagElement += "background-color:" + colorSpan + ";" + "border-radius:4px; padding:0px 4px; margin-left:4px;margin-right:4px;"
            }
        }
        if (val.indexOf(shadowColorSeparator) != -1) {

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valColor = vals[index]
                if (valColor.indexOf("d——") != -1) {

                    valStyle = valColor;

                }
            }
            let valStyles = valStyle.split("d——");
            let colorSpan = Colors.getUiColor(parseInt(valStyles[0]));
            if (parseInt(valStyles[0]) != -10000) {
                let offset = 1;
                if (val.indexOf(fontSizeSeparator) != -1) {
                    let valStyleFont = [];
                    let valStyle = "";
                    for (let index = 0; index < valsCount; index++) {
                        let valFontSize = vals[index]
                        if (valFontSize.indexOf("f——") != -1) {
                            valStyle = valFontSize;
                        }
                    }
                    valStyleFont = valStyle.split('f——');
                    if (valStyleFont[0] <= 16) {
                        offset = 0.6;
                    } else if (valStyleFont[0] > 16 && valStyleFont[0] <= 28) {
                        offset = 1;
                    } else {
                        offset = 2;
                    }
                }
                pTagElement += "text-shadow: " + offset + "px " + offset + "px " + colorSpan + ";";
            }
        }
        if (val.indexOf(fontSizeSeparator) != -1) {

            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("f——") != -1) {

                    valStyle = valFontSize;

                }
            }

            valStyleFont = valStyle.split('f——');
            pTagElement += "font-size:" + valStyleFont[0] + "px;"
        }
        if (val.indexOf(boldSeparator) != -1) {

            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("b") != -1) {

                    valStyle = valFontSize;

                }
            }
            valStyleFont = valStyle.split('b');

            if (valStyleFont[0] == "true") {
                pTagElement += "font-weight:bold;"
            }
        }
        if (val.indexOf(italicsSeparator) != -1) {


            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("i") != -1) {

                    valStyle = valFontSize;

                }
            }
            valStyleFont = valStyle.split('i');

            if (valStyleFont[0] == "true") {
                pTagElement += "font-style: italic;"
            }
        }
        if (val.indexOf(strikethroughSeparator) != -1) {
            let valStyleFont = [];

            let valStyle = "";

            for (let index = 0; index < valsCount; index++) {
                let valFontSize = vals[index]
                if (valFontSize.indexOf("s——") != -1) {

                    valStyle = valFontSize;

                }
            }
            valStyleFont = valStyle.split('s——');

            if (valStyleFont[0] == "true") {
                pTagElement += "text-decoration: line-through;"
            }
        }
        if (val.indexOf(fontSizeSeparator) == -1) {
            pTagElement += "font-size:" + fontSize + "px;' class='no_font_size"
        }
        let valueText = vals[0]
        pTagElement += "'>" + valueText + "</span>";

        return pTagElement
    }

    getTextSize(text) {
        if (this.mindElementData == null) {
            return new Size(10, 20);
        }
        this.data = this.mindElementData.copy();
        this.textMindElementContent = null;
        if (this.data.textContent != null) {
            this.textMindElementContent = this.data.textContent;
            this.data.textContent.text = text;
        } else if (this.data.generalizationContent != null) {
            this.textMindElementContent = this.data.generalizationContent;
            this.data.generalizationContent.text = text;
        } else {
            return new Size(10, 20);
        }

        MindElementCalculation.set(this.data).caluleTextForData();
        return new Size(this.textMindElementContent.width, this.textMindElementContent.height);
    }

    textViewShouldBeginEditing(textView) {

    }
    textViewShouldEndEditing(textView) {

    }

    deleteBackward() {

    }

    onSelected(data, icon) {
        this.deletage.onSelected(this.mindElementData.id, data, icon);
    }

    getSelectedSpace() {
        if (this.mindElementData == null) {
            return 4;
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return 1;
        }
        if ((this.mindElementData.borderWidth <= 0 || this.mindElementData.borderColor == Colors.clear)) {
            return 3;
        }
        return this.mindElementData.borderWidth + 3;
    }

    setSelected(value, isAddNodeIcon = true) {
        if (this.isSelected == value) {
            return;
        }
        this.isSelected = value;
        this.setSelectedLayer(this.mindElementData.width, this.mindElementData.height);
        if (!value) {
            if (Util.getCurrentTime() - this.addNodeClickTime > 1000) {
                this.hideAddNodeIcon()
            }       
            this.setClickListener();
            this.setSelectedHoverLayer(false)
        } else {
            if (isAddNodeIcon) {
                this.initAddIcon()
                this.setAddIcon()
            }
        }
    }

    setViewRect(width, height, x, y) {
        if (EditMindmapVM.mindDisplayType != MindDisplayType.Outline) {
            super.setViewRect(width, height, x, y)
            let textMindElementContent = this.getTextContent();
            //如果有图标且没文字就不设置文字的默认宽高了。
            if (this.mindElementData.iconElementContents != null &&
                this.mindElementData.iconElementContents.length > 0 &&
                textMindElementContent.text == '') {
                this.mindContent.setAttribute('width', 0);
                this.mindContent.setAttribute('height', 0);
            } else {
                this.mindContent.setAttribute('width', width);
                this.mindContent.setAttribute('height', height);
            }
        }
    }

    getIsShowMore() {
        return this.mindElementData != null && this.mindElementData.moreContent != null;
    }

    setPoint(x, y) {
        this.checkAddChildIconPoint(this.contextPointX, this.contextPointY, x, y);
        super.setPoint(x, y);
    }
}

export default BaseInputView
