
import HashMap from "../../../viewmodel/core/base/HashMap";
import DeviceType from "../../../viewmodel/datatype/DeviceType";
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import UiUtil from "../../../utils/UiUtil";
import MindElementDataDeserialize from "./MindElementDataDeserialize";
import MindElementNodeDeserialize from "./MindElementNodeDeserialize";
import SettingData from "../../../viewmodel/core/minddata/SettingData";
import BoldData from "../../../viewmodel/mindelementdata/mindcontent/BoldData";
import MindEditSectionId from "../../../viewmodel/core/minddata/MindEditSectionId";
import MindSectionChangeData from "../../../viewmodel/core/minddata/MindSectionChangeData";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/25
 * Copyright(c) 2020 mindyushu.com
 */

class MindBaseFormat {

    constructor() {
        this.deviceType = DeviceType.UNKNOWN;
        this.deviceDensity = (new UiUtil).dip2px(1);
        this.nodeConnectLineControlPointMap = new HashMap();
        this.mindGlobalAudio = "";
        this.frameworkIndex = -1;
        this.version = 1;
        this.styleIndex = 1;
        this.uuid = "";
        this.mindkey = "";
        this.mindOriginDataRelativeRootInfo = new HashMap();
    }

    deserializationFreeNodes(value) {
        let freeNodes = new Array();
        if (value["freeNodes"] != null && value["freeNodes"].length > 0) {
            let freeNodesSize = value["freeNodes"].length;
            for (let index = 0; index < freeNodesSize; index++) {
                freeNodes.push(this.deserializationMindDateFormat(value["freeNodes"][index]));
            }
        }
        return freeNodes
    }

    deserializationLineMindElementData(dataValue) {
        return MindElementDataDeserialize.deserialize(dataValue)
    }

    deserializationLineMindTypeNode(nodeValue) {
        return MindElementNodeDeserialize.deserialize(nodeValue);
    }

    deserializationMindDateFormat(mind) {
        let data = this.getMindDateFormat();
        for (var key in mind) {
            let keyValue = mind[key]
            data[key] = keyValue
            if (key == "rootNode") {
                let rootNode = this.deserializationLineMindTypeNode(keyValue)
                data[key] = rootNode
            } else if (key == "rootLine") {
                let rootLines = new Array();
                let rootLinesSize = keyValue.length
                for (let index = 0; index < rootLinesSize; index++) {
                    rootLines.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = rootLines
            } else if (key == "generalizationLine") {
                let generalizationLine = new Array();
                let generalizationLineSize = keyValue.length
                for (let index = 0; index < generalizationLineSize; index++) {
                    generalizationLine.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = generalizationLine
            } else if (key == "generalizationNode") {
                let generalizationNode = new Array();
                let generalizationNodeSize = keyValue.length
                for (let index = 0; index < generalizationNodeSize; index++) {
                    generalizationNode.push(this.deserializationLineMindTypeNode(keyValue[index]))
                }
                data[key] = generalizationNode
            } else if (key == "nodeConnectLinet") {
                let nodeConnectLinet = new Array();
                let nodeConnectLinetSize = keyValue.length
                for (let index = 0; index < nodeConnectLinetSize; index++) {
                    nodeConnectLinet.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = nodeConnectLinet
            } else if (key == "encircle") {
                let encircle = new Array();
                let encircleSize = keyValue.length
                for (let index = 0; index < encircleSize; index++) {
                    encircle.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = encircle
            } else if (key == "nodeConnectLineControlPointMap") {
                data[key] = new HashMap()
            } else if ((key == "globalLineLayout" ||
                key == "globalLayout" ||
                key == "mindType" ||
                key == "mindBGStripesType" ||
                key == "mindDisplayType") && keyValue != null && typeof keyValue == "string") {
                data[key] = parseInt(keyValue)
            } else if (key == "settingData" && keyValue != null) {
                let settingData = new SettingData()
                let settingDataValue = keyValue
                for (var keyDataValueKey in settingDataValue) {
                    if (keyDataValueKey == "freeNodeMoveAdsorbent" || 
                        keyDataValueKey == "branchNodeFreelayout") {
                        let  boldData = new BoldData()
                        let boldDataValue = settingDataValue[keyDataValueKey]
                        for (var boldDataKey in boldDataValue) {
                            boldData[boldDataKey] = boldDataValue[boldDataKey]
                        }
                        settingData[keyDataValueKey] = boldData
                    } else if (keyDataValueKey == "sectionIds") {
                        let sectionIds = new Array();
                        let sectionIdsValue = settingDataValue[keyDataValueKey]
                        let sectionIdsValueSize = sectionIdsValue.length
                        for (let index = 0; index < sectionIdsValueSize; index++) {
                            let sectionIdsValueCell = sectionIdsValue[index]
                            let mindEditSectionIdCell = new MindEditSectionId()
                            for (var sectionIdsValueCellKey in sectionIdsValueCell) {
                                let sectionIdsValueCellKeyValue = sectionIdsValueCell[sectionIdsValueCellKey]
                                if (sectionIdsValueCellKey == "removeList") {
                                    let removeList = [];
                                    let removeListData = sectionIdsValueCellKeyValue;
                                    if (removeListData != null && removeListData.length > 0) {
                                        for (let j = 0; j < removeListData.length; j++) {
                                            let mindSectionChangeData = new MindSectionChangeData()
                                            let removeListDataCell = removeListData[j]
                                            for (var mindSectionChangeDataKey in removeListDataCell) {
                                                mindSectionChangeData[mindSectionChangeDataKey] = removeListDataCell[mindSectionChangeDataKey]
                                            }
                                            removeList.push(mindSectionChangeData)
                                        }
                                    }
                                    mindEditSectionIdCell.removeList = removeList;
                                } else if (sectionIdsValueCellKey == "changeList") {
                                    let changeList = [];
                                    let changeListData = sectionIdsValueCellKeyValue;
                                    if (changeListData != null && changeListData.length > 0) {
                                        for (let j = 0; j < changeListData.length; j++) {
                                            let mindSectionChangeData = new MindSectionChangeData()
                                            let changeListDataCell = changeListData[j]
                                            for (var mindSectionChangeDataKey in changeListDataCell) {
                                                mindSectionChangeData[mindSectionChangeDataKey] = changeListDataCell[mindSectionChangeDataKey]
                                            }
                                            changeList.push(mindSectionChangeData)
                                        }
                                    }
                                    mindEditSectionIdCell.changeList = changeList;
                                } else {
                                    mindEditSectionIdCell[sectionIdsValueCellKey] = sectionIdsValueCellKeyValue
                                }
                                
                            }
                            sectionIds.push(mindEditSectionIdCell)
                        }
                        settingData[keyDataValueKey] = sectionIds
                    } else {
                        settingData[keyDataValueKey] = settingDataValue[keyDataValueKey]
                    }
                }
                data[key] = settingData
            } else if (key == "explainData") {
                let explainData = new Array();
                let explainDataSize = keyValue.length
                for (let index = 0; index < explainDataSize; index++) {
                    explainData.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = explainData
            }
        }
        return data
        // return MindElementDataFormat.deserialize(mind);
    }
    
    getMindDateFormat() {
        return new Object();
    }

    isRemovMindElementKeyForToJson(key, value) {
        let data = new MindElementData()

        if (key == "isEdit" && value == false) {
            return true
        } else if (key == "borderDot" && value == false) {
            return true
        } else if (key == "borderThicken" && value == false) {
            return true
        } else if (key == "isSelected" && value == false) {
            return true
        } else if (key == "isHidden" && value == false) {
            return true
        } else if (key == "isLayoutHidden" && value == false) {
            return true
        } else if (key == "isShadow" && value == false) {
            return true
        } else if (key == "isFreeLayoutNode" && value == false) {
            return true
        } else if (key == "lineThicken" && value == false) {
            return true
        } else if (key == "dottedLine" && value == false) {
            return true
        } else if (key == "nodeConnectLineDottedLine" && value == true) {
            return true
        } else if (key == "textItalics" && value == false) {
            return true
        } else if (key == "textStrikethrough" && value == false) {
            return true
        } else if (key == "textBold" && value == false) {
            return true
        } else if (key == "isFreeTreeNode" && value == false) {
            return true
        } else if (key == "bgColor" && value == -10000) {
            return true
        } else if (key == "textShadow" && value == -10000) {
            return true
        } else if (key == "alignmentType" && value == 0) {
            return true
        } else if (key == "moveAutoAdsorptionTime" && value == -1) {
            return true
        } else if (key == "hiddenNumber" && value == 0) {
            return true
        } else if (key == "backgroundFullType" && value == 0) {
            return true
        } else if (key == "mindElementShape" && value == 0) {
            return true
        } else if (key == "mindElementOriginalShape" && value == 0) {
            return true
        } else if (key == "encircleTitleType" && value == 1) {
            return true
        } else if (key == "encircleShapeType" && value == 1) {
            return true
        } else if (key == "startShape" && value == 1) {
            return true
        } else if (key == "endShape" && value == 2) {
            return true
        } else if (key == "outlineX" && value == -1) {
            return true
        } else if (key == "outlineY" && value == -1) {
            return true
        } else if (key == "backgroundColorAlpha" && value == 1) {
            return true
        } else if (key == "borderColorAlpha" && value == 1) {
            return true
        } else if (key == "textColorAlpha" && value == 1) {
            return true
        } else if (key == "padding" && value == 0) {
            return true
        } else if (key == "customWidth" && value == 0) {
            return true
        } else if (key == "customHeight" && value == 0) {
            return true
        } else if (key == "contentInsetTop" && value == 1) {
            return true
        } else if (key == "contentInsetLeft" && value == 1) {
            return true
        } else if (key == "contentInsetRight" && value == 1) {
            return true
        } else if (key == "contentInsetBottom" && value == 1) {
            return true
        } else if (key == "bubbleRadius" && value == -1) {
            return true
        } else if (key == "cornerRadius" && value == 4) {
            return true
        } else if (key == "startControlPointX" && value == -1) {
            return true
        } else if (key == "startControlPointY" && value == -1) {
            return true
        } else if (key == "endControlPointX" && value == -1) {
            return true
        } else if (key == "endControlPointY" && value == -1) {
            return true
        } else if (key == "backgroundUrl" && value == "") {
            return true
        } else if (key == "explain" && value == "") {
            return true
        } else if (key == "fontFamily" && value == "") {
            return true
        } else if (key == "text" && value == "") {
            //return true
        } else if (key == "latexContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "textContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "titleContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "lineContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "imageContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "generalizationContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "linkElementContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "remarksElementContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "connectMapElementContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "timeLineContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "statisticsContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "taskContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "resourceContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "statisticsContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "iconElementContents" && (value == null || value == undefined)) {
            return true
        } else if (key == "moreContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "orderContent" && (value == null || value == undefined)) {
            return true
        } else if (key == "mindElementOriginalShape" && (value == null || value == undefined)) {
            return true
        } else if (key == "styleCells") {
            if (value == null || value == undefined) {
                return true
            } else if ((typeof value === "object") && value.length == 0) {
                return true
            }
        } else if (key == "styleColor" && value == 0xA6A6A6) {
            return true
        } else if (key == "styleFillColor" && value == 0xEEE3B6) {
            return true
        } else if (key == "borderWidth" && value == 0) {
            return true
        } else if (key == "borderColor" && value == 0xA6A6A6) {
            return true
        } else if (key == "styleFillColor" && value == 0xEEE3B6) {
            return true
        } else if (key == "encircleNodesPoint") {
            if (value == null || value == undefined) {
                return true
            } else if ((typeof value === "object") && value.length == 0) {
                return true
            }
        } else if (key == "formPoint") {
            if (value == null || value == undefined) {
                return true
            } else if ((typeof value === "object") && value.length == 0) {
                return true
            }
        } else if (key == "rightAnglePoints") {
            if (value == null || value == undefined) {
                return true
            } else if ((typeof value === "object") && value.length == 0) {
                return true
            }
        }        
        return false
    }

    formatNumber(value) {
        if (typeof value === "number") {
            let newValue = Math.round(value*100)/100;
            if (newValue == value) {
                return value
            } else {
                return newValue
            }
        } else {
            return value
        }
    }
}
export default MindBaseFormat
