// package mind.yushu.com.mindmap.main.uidata;

// import mind.yushu.com.mindmap.viewmodel.datatype.LineLayout;
import LineLayout from "../../viewmodel/datatype/LineLayout"
// import mind.yushu.com.mindmap.viewmodel.datatype.MindDisplayType;
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType"
// import mind.yushu.com.mindmap.viewmodel.datatype.MindType;
import MindType from "../../viewmodel/datatype/MindType"
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
import NodeLayoutType from "../../viewmodel/datatype/NodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/6
 * Copyright(c) 2020 mindyushu.com
 */

class CreatMindItmeData {
    constructor(
        icon,
        name = "",
        layoutType = NodeLayoutType.LAYOUT_LEFT_RIGHT, 
        mindType = MindType.LINE_MAP,
        w_h_scale = 0.4, 
        styleIndex = 0,
        lineLayout = LineLayout.CURVE_LINE_2,
        mindDisplayType = MindDisplayType.MindMap, 
        isEmpty = false,
        explain = ""
    ) {
        this.icon = icon;
        this.name = name;
        this.layoutType = layoutType;
        this.mindType = mindType;
        this.w_h_scale = w_h_scale;
        this.styleIndex = styleIndex;
        this.lineLayout = lineLayout;
        this.mindDisplayType = mindDisplayType;

        this.width = 0;
        this.height = 0;
        this.left = 0;
        this.top = 0;
        this.isEmpty = isEmpty;
        this.explain = explain;
    }
    
}

export default  CreatMindItmeData
