<template>
  <div v-if="helpShow" @click="hideBox" class="big-help-box">
    <div @click.stop="" class="big-help-box-center">
      <div class="big-help-box-center-header">
        <div class="big-help-box-center-heade-title">
          {{ getString(strings.Mind_Edit_Left_Menu_Help) }}
        </div>
        <div @click="hideBox" class="big-help-box-center-heade-back">
          <a-icon type="close" />
        </div>
      </div>
      <div class="big-help-box-center-content">
        <div class="big-help-box-center-content-leftmenu">
          <div
            v-for="(item, index) in data"
            :key="index"
            @click="checkItem(item)"
            class="big-help-box-center-content-leftmenu-list"
            :class="
              item.src == selected.src
                ? 'big-help-box-center-content-leftmenu-list-check'
                : ''
            "
          >
            {{ item.name }}
            <div v-if="index != data.length - 1" class="bottom_line"></div>
          </div>
        </div>
        <div class="big-help-box-center-content-rightbox">
          <div class="big-help-box-center-content-rightbox-title">
            {{ selected.name }}
          </div>
          <div class="big-help-box-center-content-rightbox-main">
            <img
              v-if="selected.src != ''"
              class="big-help-box-center-content-rightbox-main-img"
              :src="selected.src"
              alt=""
            />
            <div
              class="code-table"
              v-else-if="selected.name == getString(strings.Mind_Usinghelp_13)"
            >
              <table>
                <thead>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_1) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_2) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_3) }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_4) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_5) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_6) }}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_7) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_7) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_8) }}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_9) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_10) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_11) }}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_12) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_13) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_11) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_14) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_15) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_16) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_17) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_18) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_19) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_4) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_5) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_6) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_20) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_21) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Edit_Left_Menu_Save_Picture) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_22) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_23) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Edit_Revoke) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_24) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_25) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Mindmap_Deleted_Recovery) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_26) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_27) }}
                    </td>
                    <td>
                      {{ getString(strings.Mind_Usinghelp_Btn_28) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div @click="hideBox" class="big-help-box-center-footer">
        <div class="big-help-box-center-footer-button">
          {{ getString(strings.Mind_Usinghelp_Understood) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
  data() {
    return {
      data: [
        {
          name: getString(strings.Mind_Usinghelp_1),
          src: require("../../../assets/img/gifFileImg/如何新建导图.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_2),
          src: require("../../../assets/img/gifFileImg/如何新增节点.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_3),
          src: require("../../../assets/img/gifFileImg/如何新建自由节点.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_4),
          src: require("../../../assets/img/gifFileImg/如何建立关联.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_5),
          src: require("../../../assets/img/gifFileImg/如何美化画布.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_6),
          src: require("../../../assets/img/gifFileImg/如何美化文本.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_7),
          src: require("../../../assets/img/gifFileImg/插入备注、链接.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_8),
          src: require("../../../assets/img/gifFileImg/如何插入图标.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_9),
          src: require("../../../assets/img/gifFileImg/如何插入图片.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_10),
          src: require("../../../assets/img/gifFileImg/导图误删如何恢复.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_11),
          src: require("../../../assets/img/gifFileImg/如何保存图、PDF.gif"),
        },
        {
          name: getString(strings.Mind_Usinghelp_13),
          src: "",
        },
        {
          name: getString(strings.Mind_Usinghelp_12),
          src: require("../../../assets/img/gifFileImg/如何解锁会员权限.gif"),
        },
      ],
      selected: {
        name: getString(strings.Mind_Usinghelp_13),
        src: require("../../../assets/img/gifFileImg/如何新建导图.gif"),
      },
      helpShow: false,
      strings: strings,
    };
  },
  created() {},
  methods: {
    ...mapMutations(["helpModal"]),
    getString(i) {
      return getString(i);
    },
    checkItem(item) {
      this.selected = item;
    },
    hideBox() {
      this.helpShow = false;
      this.helpModal(false);
    },
  },
  watch: {
    //  "$store.state.showHelpModal"(newval, olval) {
    //     this.helpShow = true;
    // },
    "$store.state.checkHelp"(newval, olval) {
      if (newval == true) {
        this.helpShow = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.big-help-box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.1);
  user-select: none;
  .big-help-box-center {
    position: absolute;
    width: 822px;
    // height: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    padding-left: 0;
    box-sizing: border-box;
    .big-help-box-center-header {
      height: 35px;
      line-height: 35px;
      font-size: 18px;
      position: relative;
      .big-help-box-center-heade-title {
        width: 100px;
        text-align: center;
        margin: 0 auto;
        color: #333;
      }
      .big-help-box-center-heade-back {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
      }
    }
    .big-help-box-center-content {
      display: flex;
      margin-bottom: 44px;
      color: #666;
      .big-help-box-center-content-leftmenu {
        .big-help-box-center-content-leftmenu-list {
          width: 148px;
          height: 40px;
          line-height: 40px;
          padding-left: 30px;
          padding-right: 12px;
          cursor: pointer;
          margin-bottom: 1px;
        }
        .big-help-box-center-content-leftmenu-list-check {
          background-color: #f2f2f2;
        }

        .bottom_line {
          border-bottom: 1px dashed #dcdcdc;
        }
      }
      .big-help-box-center-content-rightbox {
        margin-left: 20px;
        .big-help-box-center-content-rightbox-title {
          margin-bottom: 30px;
        }
        .big-help-box-center-content-rightbox-main {
          .big-help-box-center-content-rightbox-main-img {
            width: 624px;
            height: 370px;
          }
          // .text-each-list{
          //     margin-left: 2em;
          // }
          .code-table {
            table {
              border-collapse: collapse;
            }

            table,
            th,
            td {
              border: 1px solid black;
              padding: 4px;
            }
            table thead tr td {
              background-color: #f8f8f8;
              text-align: center;
            }
          }
        }
      }
    }
    .big-help-box-center-footer {
      position: absolute;
      right: 20px;
      bottom: 20px;
      padding: 11px 26px;
      background-color: #fd492b;
      color: #fff;
      font-size: 14px;
      border-radius: 21px;
      cursor: pointer;

      &:active {
        background-color: #c93c29;
      }
    }
  }
}
</style>