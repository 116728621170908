import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import Colors from "../../../utils/Colors"
import IdGenerator from "../base/IdGenerator"
import LineLayout from "../../datatype/LineLayout"
import LineOrientation from "../../datatype/LineOrientation"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import MindElementData from "../../mindelementdata/MindElementData"
import CGPoint from "../base/basedata/CGPoint"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 7/7/21
 * Copyright(c) 2020 mindyushu.com
 */

class FishLeftLayout extends BaseLayout {
    constructor() {
        super();
        
        this.branchAngle = 60;
        this.startSpace = this.UiUtil.dip2px(0)
    }

    initConfig() {
        super.initConfig();
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee);
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());

        this.EncircleNodesPointsCalculation = new EncircleNodesPointsCalculation();
    }

    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y <= -1 || this.rootTreeNode.value.x <= -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (this.UiUtil.getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        this.initConfig();
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setRootLine();
    }

    setNodeElementsPoint(node, isChange) {
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (node.value.parentNodeId == this.rootTreeNode.value.id) {
            this.setDataPoint(node.value, isChange);
        } else if (parentNode.value.parentNodeId == this.rootTreeNode.value.id) {
            this.setRootGrandsonDataPoint(node);
            this.resetNodeLayout(node);
            return;
        } else if (!this.isRootNode(node)) {
            return;
        }

        if (node.children.isEmpty()) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }

    setRootGrandsonDataPoint(node) {
        if (node.isEmpty() || node.value.isHidden || this.isLayout(node.value)) {
            return;
        }
        let data = node.value;
        this.pushCurrentLayoutNode(node);
        let parentNode = this.getNodeById(node.value.parentNodeId);
        let parentNodeData = parentNode.value;
        if (parentNode.isEmpty()) {
            return;
        }

        let parentNodeSiblingsNodeDatas = this.getSiblingsMindElementDataById(parentNodeData.id);
        let parentNodePosition = this.getNodeInNodesPosition(parentNodeSiblingsNodeDatas, parentNodeData);

        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
        let baseX;
        let baseY;
        let isTopLayout = parentNodePosition % 2 == 0;
        if (isTopLayout) {
            baseX = parentNodeData.x + parentNodeData.width / 2;
            baseY = parentNodeData.y + parentNodeData.height;
        } else {
            baseX = parentNodeData.x + parentNodeData.width / 2;
            baseY = parentNodeData.y;
        }

        if (allSiblingsNodesPosition == 0) {
            if (node.value.x <= 0 || node.value.y <= 0) {
                node.value.x = 10000;
                node.value.y = 10000;
            }
            let originalX = node.value.x;
            let originalY = node.value.y;
            let layout = this.resetNodeLayout(node);
            let nodeRect = new NodesRectCalculation().calcule(node);
            if (isTopLayout) {
                let height = node.value.y - nodeRect.y + this.NodeVerticalSpacee;
                data.y = baseY + height;
                if (this.isUnderline(data)) {
                    let spaceX = ((height + node.value.height) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                } else {
                    let spaceX = ((height + node.value.height / 2) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                }
            } else {
                let height = nodeRect.y + nodeRect.height() - node.value.y + this.NodeVerticalSpacee;
                data.y = baseY - height;
                if (this.isUnderline(data)) {
                    let spaceX = ((height - node.value.height) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                } else {
                    let spaceX = ((height - node.value.height / 2) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                }
            }
            this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
        } else {
            let preNodeData = siblingsNodeDatas[allSiblingsNodesPosition - 1];
            let preNodeRect = new NodesRectCalculation().calcule(this.getNodeById(preNodeData.id));

            if (node.value.x <= 0 || node.value.y <= 0) {
                node.value.x = 10000;
                node.value.y = 10000;
            }
            let originalX = node.value.x;
            let originalY = node.value.y;
            let layout = this.resetNodeLayout(node);
            let nodeRect = new NodesRectCalculation().calcule(node);
            if (isTopLayout) {
                let height = node.value.y - nodeRect.y + this.NodeVerticalSpacee + (preNodeRect.y + preNodeRect.height() - baseY);
                data.y = baseY + height;

                if (this.isUnderline(data)) {
                    let spaceX = ((height + node.value.height) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                } else {
                    let spaceX = ((height + node.value.height / 2) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                }
            } else {
                let height = nodeRect.y + nodeRect.height() - node.value.y + this.NodeVerticalSpacee + (+ (baseY - preNodeRect.y));
                data.y = baseY - height;

                if (this.isUnderline(data)) {
                    let spaceX = ((height - node.value.height) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                } else {
                    let spaceX = ((height - node.value.height / 2) / this.Util.tan(this.branchAngle));
                    data.x = baseX + spaceX - this.SonNodeHorizontalSpacee - data.width;
                }
            }
            this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
        }
        this.setRootGrandsonLine(node);
    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden || this.isLayout(data)) {
            return;
        }
        this.pushCurrentLayoutNode(node);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (parentNodeData.id != this.rootTreeNode.value.id) {
            return;
        }
        if (this.isRootNode(node)) {
            return;
        } else {
            data.layout = NodeLayoutType.LAYOUT_LEFT
            let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            let nodeHeight = this.getTreeHeight(node);
            let nodeAndRootLineHeight = nodeHeight - data.height + this.NodeVerticalSpacee;
            let isTopLayout = allSiblingsNodesPosition % 2 == 0;
            if (isTopLayout) {//上方
                if (this.isUnderline(parentNode.value)) {
                    data.y = parentNode.value.y + parentNode.value.height - nodeAndRootLineHeight - data.height;
                } else {
                    data.y = parentNode.value.y + parentNode.value.height / 2 - nodeAndRootLineHeight - data.height;
                }
                let baseX = 0;
                if (allSiblingsNodesPosition == 0) {
                    baseX = parentNodeData.x - this.NodeHorizontalSpacee - this.startSpace;
                } else {
                    let preNode = this.getNodeById(siblingsNodeDatas[allSiblingsNodesPosition - 2].id);
                    let preNodeRect = new NodesRectCalculation().calcule(preNode);
                    baseX = preNodeRect.x - this.NodeHorizontalSpacee;
                }

                let nodeCentreX = (nodeAndRootLineHeight / this.Util.tan(this.branchAngle));
                if (nodeCentreX >= data.width / 2) {
                    data.x = baseX - nodeCentreX - data.width / 2;
                } else {
                    data.x = baseX - data.width;
                }
            } else { //下方
                if (this.isUnderline(parentNode.value)) {
                    data.y = parentNode.value.y + parentNode.value.height + nodeAndRootLineHeight;
                } else {
                    data.y = parentNode.value.y + parentNode.value.height / 2 + nodeAndRootLineHeight;
                }

                let baseX = 0;
                if (allSiblingsNodesPosition == 1) {
                    baseX = parentNodeData.x - this.NodeHorizontalSpacee * 3 - this.startSpace;
                } else {
                    let preNode = this.getNodeById(siblingsNodeDatas[allSiblingsNodesPosition - 2].id);
                    let preNodeRect = new NodesRectCalculation().calcule(preNode);
                    baseX = preNodeRect.x - this.NodeHorizontalSpacee;
                }
                let nodeCentreX = (nodeAndRootLineHeight / this.Util.tan(this.branchAngle));
                if (nodeCentreX >= data.width / 2) {
                    data.x = baseX - nodeCentreX - data.width / 2;
                } else {
                    data.x = baseX - data.width;
                }
            }
            this.setRootChildLine(node, isTopLayout);
            this.setBorderThicken(node);
        }
    }

    setRootChildLine(node, isTopLayout) {
        let data = node.value;
        if (data.isHidden) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.isFreeTreeNode = false
        lineData.layout = NodeLayoutType.LAYOUT_LEFT;
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            let height;
            let startPoint = new Point(0, 0);
            let endPoint = new Point(0, 0);

            if (isTopLayout) {
                lineData.y = data.y + data.height;
                if (this.isUnderline(parentNode.value)) {
                    height = parentNode.value.y + parentNode.value.height - lineData.y;
                } else {
                    height = parentNode.value.y + parentNode.value.height / 2 - lineData.y;
                }
                let width = (height / this.Util.tan(this.branchAngle));
                lineData.x = data.x + data.width / 2;

                lineData.width = width;
                lineData.height = height;
                startPoint = new Point(0, 0);
                endPoint = new Point(lineData.width, lineData.height);

            } else {
                if (this.isUnderline(parentNode.value)) {
                    lineData.y = parentNode.value.y + parentNode.value.height;
                } else {
                    lineData.y = parentNode.value.y + parentNode.value.height / 2;
                }
                height = data.y - lineData.y;
                let width = (height / this.Util.tan(this.branchAngle));
                lineData.x = data.x + data.width / 2;

                lineData.width = width;
                lineData.height = height;
                startPoint = new Point(0, lineData.height);
                endPoint = new Point(lineData.width, 0);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = LineLayout.STRAIGHT_LINE;
        } else {
            lineData.type = MindElementType.SON_LINE;

            let height;
            let curveStartPoint;
            let curveEndPoint;
            let straightEndPoint;

            if (isTopLayout) {
                lineData.y = data.y + data.height;
                if (this.isUnderline(parentNode.value)) {
                    height = parentNode.value.y + parentNode.value.height - lineData.y;
                } else {
                    height = parentNode.value.y + parentNode.value.height / 2 - lineData.y;
                }
                let width = (height / this.Util.tan(this.branchAngle));
                lineData.x = data.x + data.width / 2;

                lineData.width = width;
                lineData.height = height;

                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);

            } else {
                if (this.isUnderline(parentNode.value)) {
                    lineData.y = parentNode.value.y + parentNode.value.height;
                } else {
                    lineData.y = parentNode.value.y + parentNode.value.height / 2;
                }
                height = data.y - lineData.y;
                let width = (height / this.Util.tan(this.branchAngle));
                lineData.x = data.x + data.width / 2;

                lineData.width = width;
                lineData.height = height;

                curveStartPoint = new Point(0, lineData.height);
                curveEndPoint = new Point(lineData.width, 0);
                straightEndPoint = new Point(lineData.width, 0);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }

            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = LineLayout.STRAIGHT_LINE;
        }


        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
            lineNodeContent.lineThicken = false;
        } else {
            let childSize = parentNode.children.length;
            if (lineData.lineContent.lineWidth == 0 && childSize > 2 &&
                parentNode.children[0].value.id != data.id &&
                parentNode.children[childSize - 1].value.id != data.id) {
                let firstLine = this.getLineData(parentNode.children[0]);
                let lastLine = this.getLineData(parentNode.children[childSize - 1]);

                if (!firstLine.isEmpty() && !lastLine.isEmpty() &&
                    firstLine.lineContent.lineWidth > 0 &&
                    lastLine.lineContent.lineWidth > 0) {
                    lineData.lineContent.lineWidth = firstLine.lineContent.lineWidth;
                }
            }
            lineData.lineContent.lineThicken = false;
        }
    }

    getFishLineData(node) {
        let arr = this.lineMindElementDataDict.keys();
        let len = arr.length
        for (let index = 0; index < len; index++) {
            let key = arr[index];
            let data = this.lineMindElementDataDict.get(key);
            if (data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE &&
                data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    setRootLine() {
        let data = this.rootTreeNode.value;
        if (data.isHidden || this.rootTreeNode.children.isEmpty()) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getFishLineData(this.rootTreeNode);

        lineData.parentNodeId = this.rootTreeNode.value.parentNodeId;
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(this.rootTreeNode.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_LEFT;
        lineData.type = MindElementType.LAYOUT_FISH_RIGHT_LINE;
        lineData.isFreeTreeNode = false
        
        if (this.isUnderline(this.rootTreeNode.value)) {
            lineData.y = this.rootTreeNode.value.y + this.rootTreeNode.value.height;
        } else {
            lineData.y = this.rootTreeNode.value.y + this.rootTreeNode.value.height / 2;
        }
        let textEdge = this.getTextEdgeInsets(this.rootTreeNode.value)        
        let rect = new NodesRectCalculation().calcule(this.rootTreeNode);        
        lineData.width = rect.width() - this.rootTreeNode.value.width;

        lineData.x = this.rootTreeNode.value.x - lineData.width + textEdge.left / 2;
        lineData.height = 1;
        let startPoint = new Point(0, 0);
        let endPoint = new Point(lineData.width, 0);

        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        lineData.lineContent.lineLayout = LineLayout.STRAIGHT_LINE;
        let headWidth = Math.max(this.rootTreeNode.value.width, this.UiUtil.dip2px(100));
        headWidth = Math.min(headWidth, this.UiUtil.dip2px(400))

        let curveStartPoint = new CGPoint(lineData.width + headWidth, startPoint.y)
        lineData.lineContent.setCurveStartPoint(curveStartPoint);

        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let frishLine = this.getNodeLine(this.rootTreeNode.children[0].value.id);
            if (!frishLine.isEmpty() && frishLine.lineContent != null) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = frishLine.lineContent.color;
                }
                lineNodeContent.lineWidth = frishLine.lineContent.lineWidth;
            } else {
                lineNodeContent.color = this.rootTreeNode.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        }
        if (Colors.isDarkColor(this.rootTreeNode.value.textContent.textColor) &&
            Colors.isDarkColor(lineData.lineContent.color) ) {
                this.rootTreeNode.value.textContent.textColor = Colors.white
        } else if (!Colors.isDarkColor(this.rootTreeNode.value.textContent.textColor) &&
            !Colors.isDarkColor(lineData.lineContent.color) ) {
                this.rootTreeNode.value.textContent.textColor = Colors.black95
        }
        this.setLineThicken(lineData);
    }

    setRootGrandsonLine(node) {
        let data = node.value;
        if (data.isHidden) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isFreeTreeNode = false
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_LEFT;
        lineData.type = MindElementType.SON_LINE;

        lineData.x = data.x + data.width;
        if (this.isUnderline(data)) {
            lineData.y = data.y + data.height;
        } else {
            lineData.y = data.y + data.height / 2;
        }

        let curveStartPoint = new Point(0, 0);
        let curveEndPoint = new Point(0, 0);
        let straightEndPoint = new Point(0, 0);
        lineData.height = 1;
        lineData.width = this.SonNodeHorizontalSpacee;

        curveStartPoint = new Point(0, 0);
        curveEndPoint = new Point(lineData.width, 0);
        straightEndPoint = new Point(lineData.width, 0);

        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent();
            lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
        } else {
            lineData.lineContent.setCurveStartPoint(curveStartPoint);
            lineData.lineContent.setCurveEndPoint(curveEndPoint);
            lineData.lineContent.setStraightEndPoint(straightEndPoint);
        }

        lineData.lineContent.orientation = LineOrientation.RIGHT;
        lineData.lineContent.lineLayout = LineLayout.STRAIGHT_LINE;

        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        } else {
            let childSize = parentNode.children.length;
            if (lineData.lineContent.lineWidth == 0 && childSize > 2 &&
                parentNode.children[0].value.id != data.id &&
                parentNode.children[childSize - 1].value.id != data.id) {
                let firstLine = this.getLineData(parentNode.children[0]);
                let lastLine = this.getLineData(parentNode.children[childSize - 1]);

                if (!firstLine.isEmpty() && !lastLine.isEmpty() &&
                    firstLine.lineContent.lineWidth > 0 &&
                    lastLine.lineContent.lineWidth > 0) {
                    lineData.lineContent.lineWidth = firstLine.lineContent.lineWidth;
                }
            }
        }
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden ||
                !this.isIncludeGeneralizationInCurrentLayout(node) ||
                this.isLayout(node.value)) {
                continue
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let left = 1000000;
            let bottom = 0;
            let right = 0;
            let encircleSpace = 0;
            let targetNodes = []
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);

                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                targetNodes.push(targetNode)
                for (let j = 0; j < index; j++) {
                    let checkNode = list[j];
                    if (checkNode.value.isHidden) {
                        continue
                    }
                    if (checkNode.value.generalizationContent.targetIds.length == 0) {
                        continue
                    }
                    let checkTargetNode = this.getNodeById(checkNode.value.generalizationContent.targetIds[0]);
                    if (!checkTargetNode.isEmpty() && targetNode.isChildNode(checkTargetNode)) {
                        targetNodes.push(checkNode)
                    }
                }
            }
            for (let targetIndex = 0; targetIndex < targetNodes.length; targetIndex++) {
                let targetNode = targetNodes[targetIndex];
                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue;
                }
                let targetLeft = targetNode.getNodeLeft();
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();

                if (left > targetLeft) {
                    left = targetLeft;
                }
                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
                let space = this.getEncircleSpaceAndPadding(targetNode.value)
                if (space > 0) {
                    encircleSpace = Math.max(space, encircleSpace)
                }
            }
            if (top == 1000000 && right == 0) {
                continue;
            }
            node.value.layout = NodeLayoutType.LAYOUT_LEFT
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            mindElementData.x = left - mindElementData.width - Config.GeneralizationLineWidth - Config.GeneralizationSpacing * 2 - encircleSpace;

            this.setGeneralizationElementLine(node, isChange);
            this.resetNodeLayout(node);
        }
    }

    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }

        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x + mindElementData.width + Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);

        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, (lineData.height));

        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.LEFT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeTopHeight(node) {
        if (!this.isRootNode(node)) {
            return this.getTreeHeight(node);
        }
        let childCount = node.children.length;
        let topHieght = 0;
        if (this.isUnderline(node.value)) {
            topHieght = node.value.height + this.getEncircleTopHeight(node.value);
        } else {
            topHieght = node.value.height / 2 + this.getEncircleTopHeight(node.value);
        }
        if (childCount > 0) {
            for (let index = 0; index < childCount; index++) {
                let childHeight = this.getRootChildHeight(node.children[index]);
                if (index % 2 == 0) {
                    if (topHieght < childHeight + this.NodeVerticalSpacee) {
                        topHieght = childHeight + this.NodeVerticalSpacee;
                    }
                }
            }
        }
        let floatExplainData = this.getNodeExplain(node);
        if (!floatExplainData.isEmpty()) {
            topHieght += floatExplainData.height + Config.NodeFloatExplainSpace;
            if (!Colors.isClear(floatExplainData.borderColor)) {
                topHieght += floatExplainData.borderWidth
            }
        }
        return topHieght;
    }

    getTreeBottomHeight(node) {
        if (!this.isRootNode(node)) {
            return this.getTreeHeight(node);
        }

        let childCount = node.children.length;
        let bottomHieght = 0;
        if (this.isUnderline(node.value)) {
            bottomHieght = this.getNodeHeight(node.value) - node.value.height + this.getEncircleBottomHeight(node.value);
        } else {
            bottomHieght = this.getNodeHeight(node.value) - node.value.height / 2 + this.getEncircleBottomHeight(node.value);
        }
        if (childCount > 0) {
            for (let index = 0; index < childCount; index++) {
                let childHeight = this.getRootChildHeight(node.children[index]);
                if (index % 2 == 1) {
                    if (bottomHieght < childHeight + this.NodeVerticalSpacee) {
                        bottomHieght = childHeight + this.NodeVerticalSpacee;
                    }
                }
            }
        }
        return bottomHieght;
    }

    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node)) {
            let topHieght = this.getTreeTopHeight(node);
            let bottomHieght = this.getTreeBottomHeight(node);
            this.dataHeightMap.put(node.value.id, topHieght + bottomHieght);
            return topHieght + bottomHieght;
        } else if (node.value.parentNodeId == this.rootTreeNode.value.id) {
            let height = this.getRootChildHeight(node);
            this.dataHeightMap.put(node.value.id, height);
            return height;
        } else {
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.value.parentNodeId == this.rootTreeNode.value.id) {
                let height = this.getRootGrandsonHeight(node);
                this.dataHeightMap.put(node.value.id, height);
                return height;
            } else {
                let layout = this.getNodeLayout(node);
                let height = layout.getTreeHeight(node);
                this.dataHeightMap.put(node.value.id, height);
                return height;
            }
        }
    }

    getRootChildHeight(node) {
        if (node.isEmpty() || node.value.parentNodeId != this.rootTreeNode.value.id) {
            return 0;
        }
        let height = this.getNodeHeight(node.value) + this.getEncircleTopHeight(node.value) + this.getEncircleBottomHeight(node.value);
        let len = node.children.length;
        for (let index = 0; index < len; index++) {
            let item = node.children[index];
            if (item.value.isHidden) {
                continue;
            }
            let itemHieght = this.getRootGrandsonHeight(item);
            height += itemHieght + this.NodeVerticalSpacee;
        }
        return height;
    }

    getRootGrandsonHeight(node) {
        if (node.value.isHidden) {
            return 0;
        }
        let layout = this.getNodeLayout(node);
        return layout.getTreeHeight(node);
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.isRootNode(node)) {
            let childCount = node.children.length;
            let topWidth = node.value.width;
            let bottomWidth = node.value.width;

            if (childCount > 0 && !node.children[0].value.isHidden) {
                for (let index = 0; index < childCount; index++) {
                    let childWidth = this.getRootChildWidth(node.children[index]);

                    if (index % 2 == 0) {
                        topWidth += (this.NodeHorizontalSpacee + childWidth);
                    } else {
                        if (index == 1) {
                            bottomWidth += (this.NodeHorizontalSpacee * 2 + childWidth);
                        } else {
                            bottomWidth += (this.NodeHorizontalSpacee + childWidth);
                        }
                    }
                }
            }
            return Math.max(topWidth, bottomWidth);
        } else if (node.value.parentNodeId == this.rootTreeNode.value.id) {
            let width = this.getRootChildWidth(node);
            return width;
        } else {
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.value.parentNodeId == this.rootTreeNode.value.id) {
                let width = this.getRootGrandsonWidtht(node);
                return width;
            } else {
                let layout = this.getNodeLayout(node);
                let width = layout.getTreeWidth(node);
                return width;
            }
        }
    }

    getRootChildWidth(node) {
        if (node.isEmpty() || node.value.parentNodeId != this.rootTreeNode.value.id) {
            return 0;
        }
        let width = node.value.width;

        let siblingsNodeDatas = this.getSiblingsMindElementDataById(node.value.id);
        let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, node.value);

        let nodeHeight = this.getTreeHeight(node) + this.NodeVerticalSpacee - node.value.height;
        let isTopNode = allSiblingsNodesPosition % 2 == 0;
        if (isTopNode) {
            nodeHeight = nodeHeight - this.getEncircleTopHeight(node.value);
        } else {
            nodeHeight = nodeHeight - this.getEncircleBottomHeight(node.value);
        }
        let frontWidth = (nodeHeight / this.Util.tan(this.branchAngle));
        if (frontWidth < node.value.width / 2) {
            frontWidth = node.value.width / 2;
        }
        width = frontWidth + node.value.width / 2;
        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            return width;
        }
        let childCount = node.children.length;
        let topDistance = this.NodeHorizontalSpacee;

        for (let index = 0; index < childCount; index++) {
            let item = node.children[index];
            let copyNode = item.copy();
            this.resetNodeLayout(copyNode);
            let preNodeRect = new NodesRectCalculation().calcule(copyNode);

            let triangleHeight = topDistance + (copyNode.value.y - preNodeRect.y);
            if (this.isUnderline(copyNode.value)) {
                triangleHeight += copyNode.value.height;
            } else {
                triangleHeight += copyNode.value.height / 2;
            }
            let spaceX = (triangleHeight / this.Util.tan(this.branchAngle));
            let itemWidth = this.getRootGrandsonWidtht(item);
            if (frontWidth - spaceX + itemWidth > width) {
                width = frontWidth - spaceX + itemWidth;
            }
            topDistance += this.getTreeHeight(item) + this.NodeHorizontalSpacee;
        }
        return width;
    }

    getRootGrandsonWidtht(node) {
        if (node.value.isHidden) {
            return 0;
        }
        let layout = this.getNodeLayout(node);
        return layout.getTreeWidth(node);
    }

    getSiblingsNodesHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        let height = this.getTreeHeight(this.getNodeById(nodes[0].id));

        if (nodes.length > 1) {
            for (let index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue;
                }
                height += this.getTreeHeight(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
            }
        }
        return height;
    }

    getEncircleTopHeight(data) {
        let arr = this.encircleMindElementDataDict.keys();
        let len = arr.length;

        for (let index = 0; index < len; index++) {
            let key = arr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return this.EncircleNodesPointsCalculation.space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return this.EncircleNodesPointsCalculation.space + mind.lineContent.padding;
                        }
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let arr = this.encircleMindElementDataDict.keys();
        let len = arr.length;
        for (let index = 0; index < len; index++) {
            let key = arr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return this.EncircleNodesPointsCalculation.space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    changeLayoutFromFish(node) {
        if (!node.isEmpty()) {
            if (node.value.mindElementShape != node.value.mindElementOriginalShape) {
                node.value.mindElementShape = node.value.mindElementOriginalShape;
            }
            node.value.checkSetTextColor(this.mindBGColor)
            MindElementCalculation.set(node.value).caluleTextForData().calcule();
        }
    }

    changeLayoutToFish(node) {
        if (!node.isEmpty()) {
            node.value.mindElementOriginalShape = node.value.mindElementShape;
            node.value.mindElementShape = MindElementShapeType.Corner;
            if (node.value.mindElementOriginalShape != MindElementShapeType.Corner) {
                MindElementCalculation.set(node.value).calcule();
            }
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_FISH_LEFT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }
}

export default FishLeftLayout