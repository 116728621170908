import Strings from "../../../utils/Strings";

class People {
    constructor(name, id = "") {
        this.name = name;
        this.id = id;
    }
    
    copy() {
        return new People(this.name, this.id);
    }

   equal( data) {
        let result = false;
        
        result = result || !new Strings().equals(data.id, this.id);
        result = result || !new Strings().equals(data.name, this.name);
        return !result;
    }
}

export default People