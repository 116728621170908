import HttpServerConfig from "../../../common/HttpServerConfig";
import MindOperateUIControllerView from "../../../components/complieComponents/tools/MindOperateUIControllerView";
import Config from "../../../core/core/calcule/Config";
import OutlineMindElementCalculation from "../../../core/core/calcule/OutlineMindElementCalculation";
import Colors from "../../../utils/Colors";
import imageToStyle from "../../../utils/ImageToolkit";
import Strings from "../../../utils/Strings";
import UiUtil from "../../../utils/UiUtil";
import Point from "../../../viewmodel/core/base/Point";
import IconLayoutType from "../../../viewmodel/datatype/IconLayoutType";
import ImageShapeType from "../../../viewmodel/datatype/ImageShapeType";
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import BaseInputView from "./BaseInputView";
import ShapeImageOutlineView from "./ShapeImageOutlineView";
import store from "../../../core/store/index"
import StatisticsElementView from "../mindwidget/StatisticsElementView";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import InnerHTMLParse from "../../../components/complieComponents/tools/InnerHTMLParse";
import strings from "../../../common/lang/strings";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class OutlineInputView extends BaseInputView {

    constructor(context, data, index, nextView, outlineVue) {
        super(context, data);
        this.addViewIndex = index
        this.nextView = nextView
        this.outlineVue = outlineVue
        this.iconWidth = new UiUtil().dip2px(14);
        this.iconHeight = new UiUtil().dip2px(14);
        this.rowMarkSize = Config.MindOutlineRowMarkSize;
        this.rowMark;
        this.imageStartLeft = 0;
        this.selectedProtocol;
        this.imageView;
        this.inputTextViewNextSibling;
        this.textAndIconContent;
        this.initOutlineView();
        this.setOutlineStyle();
    }

    changeViewIndex(index, nextView) {
        this.nextView = nextView
        if (index == this.addViewIndex) {
            return
        }
        this.addViewIndex = index
        this.insertAfter(this.context, this.nextView == null ? null : this.nextView.context)
    }

    initView() {

    }

    setStyle() {

    }

    setOutlineStyle() {
        super.setStyle()
        if (this.mindElementData.type != MindElementType.MAIN_SUBJECT) {
            this.inputTextView.style.textAlign = 'left'
        } else {
            this.inputTextView.style.textAlign = 'center'
        }
    }

    initOutlineView() {
        //父容器
        let outlineBox = document.getElementById('outlineBox')
        outlineBox.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        outlineBox.setAttribute("version", "1.1")

        this.context = document.createElement('div')
        this.context.style.display = 'flex'
        this.context.style.minHeight = '26px'
        this.context.style.margin = '4px'

        if (this.mindElementData.type == MindElementType.CONTENT_GENERALIZATION) {
            this.context.style.margin = '-18px 8px -18px 8px'
        }
        if (this.nextView == null) {
            this.root.appendChild(this.context)
        } else {
            this.insertAfter(this.context, this.nextView == null ? null : this.nextView.context)
        }

        //编辑点
        this.editSmallDot = document.createElement('div')

        //主节点加小圆点
        if (this.mindElementData.type == MindElementType.SUBJECT) {
            this.editSmallDot.style.backgroundColor = '#222222'
            this.editSmallDot.style.width = '7px'
            this.editSmallDot.style.height = '7px'
            this.editSmallDot.style.borderRadius = '50%'
            this.context.appendChild(this.editSmallDot)
            this.editSmallDot.style.marginRight = '10px'
        }

        //子节点加小方点
        if (this.mindElementData.type == MindElementType.SON_SUBJECT) {
            this.editSmallDot.style.backgroundColor = '#222222'
            this.editSmallDot.style.width = '5px'
            this.editSmallDot.style.height = '5px'
            this.editSmallDot.style.borderRadius = '0px'
            this.context.appendChild(this.editSmallDot)
            this.editSmallDot.style.marginLeft = this.mindElementData.textContent.x + 'px'
            this.editSmallDot.style.marginRight = '10px'
            this.editSmallDot.style.marginTop = '2px'
        }

        //文本框图标父级div
        this.textAndIconContent = document.createElement("div")
        this.textAndIconContent.style.display = 'flex'
        this.textAndIconContent.style.alignItems = 'center'
        this.textAndIconContent.setAttribute('class', 'textContentBox')

        //图标图片同时存在时div
        this.iconAndImageContent = document.createElement('div')
        this.context.style.flexWrap = 'wrap'
        this.iconAndImageContent.style.width = '100%'
        this.iconAndImageContent.style.alignItems = 'center'
        //单独给图标设置的div
        this.iconsImageContent = document.createElement('div')
        this.iconsImageContent.style.display = 'flex'
        this.iconAndImageContent.appendChild(this.iconsImageContent)
        this.context.appendChild(this.iconAndImageContent)
        this.iconAndImageContent.addEventListener('click', () => {
            this.outlineVue.isShowBigImage(this.mindElementData)
        })
        //只有图标时div 
        this.iconContent = document.createElement('div')
        this.iconContent.style.display = 'flex'
        this.iconContent.style.alignItems = 'center'
        this.iconContent.setAttribute('class', 'iconsContent')
        this.textAndIconContent.insertBefore(this.iconContent, this.inputTextView)

        //文本框
        this.inputTextView = document.createElement("div")
        this.inputTextView.setAttribute('class', 'textContent')
        this.textAndIconContent.appendChild(this.inputTextView)
        this.context.appendChild(this.textAndIconContent)

        this.iconWidth = new UiUtil().dip2px(12);
        this.iconHeight = new UiUtil().dip2px(12);
        this.rowMarkSize = Config.MindOutlineRowMarkSize;

        this.initInput();
        super.setIcons()
        super.setLinkIcon()
        super.setRemarksIcon()
        this.setImage();
        this.observerInput()
    }

    createBackgroundView() {
    }

    //appendChildAf
    insertAfter(newElement, targetElement) {
        if (targetElement == null) {
            this.root.appendChild(newElement)
        } else {
            this.root.insertBefore(newElement, targetElement)
        }
    }

    getInnerHTMLValue(innerHTML, innerText) {
        return new InnerHTMLParse(innerHTML, innerText).getInnerText(false);
    }

    initInput() {
        var textContent = this.getTextContent();
        if (textContent == null) {
            return;
        }

        if (this.mindElementData.textContent != null) {
            this.inputTextView.style.fontSize = Config.normalOutlineFontSize + 'px';
        } else if (this.mindElementData.generalizationContent != null) {
            this.inputTextView.style.fontSize = this.mindElementData.generalizationContent.textFontSize + 'px';
        }

        this.inputTextView.innerHTML = this.getContentText()
        let edgeInsets = this.getTextUIEdgeInsets()
        this.inputTextView.style.color = Colors.getUiColor(Colors.black50)
        this.inputTextView.style.paddingLeft = edgeInsets.left + 'px'
        this.inputTextView.style.paddingTop = edgeInsets.top + 'px'
        this.inputTextView.style.paddingRight = edgeInsets.right + 'px'
        this.inputTextView.style.paddingBottom = edgeInsets.bottom + 'px'
        this.inputTextView.style.fontFamily = Config.textFontFamily
        this.inputTextView.style.whiteSpace = "pre-wrap"
        this.inputTextView.style.wordBreak = "break-word"
        this.inputTextView.style.userSelect = 'text'
        this.inputTextView.style.display = 'inline-block'
        this.inputTextView.style.tabindex = '0'
        this.inputTextView.style.outline = '0'
        this.inputTextView.style.width = '100%'
        this.inputTextView.style.minHeight = '26px'
        this.inputTextView.style.alignItems = 'center'
        this.inputTextView.style.lineHeight = '36px'
        this.inputTextView.setAttribute('contenteditable', true)

        if (this.mindElementData.type == MindElementType.MAIN_SUBJECT) {
            this.inputTextView.style.fontSize = '24px'
            this.textAndIconContent.style.margin = '0 auto'

        }

        if (this.mindElementData.type != MindElementType.MAIN_SUBJECT) {
            this.inputTextView.style.display = 'flex'
            this.textAndIconContent.style.width = '100%'
        }

        if (this.mindElementData.type == MindElementType.SON_SUBJECT) {
        }

        if (textContent.textBold) { //字体是否加粗
            this.inputTextView.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.inputTextView.style.fontWeight = Config.textNormalFontWeight
        }

        if (this.inputTextView.style.textItalics) {    //设置为斜体
            this.inputTextView.fontStyle = 'italic'
        } else {
            this.inputTextView.fontStyle = 'normal'
        }

        this.textClickEdit()
    }


    //键盘事件
    textClickEdit() {
        this.inputTextView.onkeydown = (e) => {
            let MindOperateUIControllerViews = new MindOperateUIControllerView(
                store.state.editMindmap,
                store.state.editMindmap
            );
            //对象兼容
            let keyDown = e || event || window.event || arguments.callee.caller.arguments[0];
            //回车
            if (keyDown.keyCode == 13) {
                e.preventDefault()
                if (this.mindElementData.type == MindElementType.MAIN_SUBJECT) {
                    return
                }
                let position = this.getCursorPosition(this.inputTextView)
                let text = this.inputTextView.innerText
                let lineFeedText = new Strings().lineFeed(text)
                let newLineText = lineFeedText.substring(position)
                let changeText = lineFeedText.substring(0, position)
                this.inputTextView.innerText = changeText
                this.onTextSizeChanged(changeText, 0, 0)
                EditMindmapVM.outlineNewLine(this.mindElementData.id, newLineText)
            }
            if (keyDown.keyCode == 8) {
                if (this.inputTextView.innerText.length == 0) {
                    if (keyDown.keyCode == 8) {
                        EditMindmapVM.removeNodeById(this.mindElementData.id)
                    }
                }
            }
            if (keyDown.keyCode == 9) {
                e.preventDefault()
                MindOperateUIControllerViews.onSelected(MindElementType.NODE_MOVE_BACKWARD);
            }
            if (keyDown.keyCode == 9 && keyDown.shiftKey) {
                e.preventDefault()
                MindOperateUIControllerViews.onSelected(MindElementType.NODE_MOVE_FORWARD);
            }
            //上箭头
            if (keyDown.keyCode == 38) {
                if (this.inputTextView.parentNode.parentNode.previousSibling == null) {
                    return
                }
                let text = this.inputTextView.innerText //文本内容
                let focusOffset = this.getCursorPositionToChildNode().focusOffset //光标偏移数值
                let num = Math.floor(Number(this.inputTextView.style.lineHeight.replace("px", "")))
                let lineNumber = Math.floor(this.inputTextView.clientHeight / num) //计算总行数
                let previousNode = this.inputTextView.parentNode.parentNode.previousSibling.getElementsByClassName("textContentBox")[0].getElementsByClassName("textContent")[0]   //上一个兄弟节点
                let lineTextLength = Math.floor(this.inputTextView.clientWidth / this.clearNumberPx(this.inputTextView.style.fontSize)) //计算一行的文字总长度
                let beforeTextLength //切换到上一行的光标位置
                previousNode.style.width = "auto"
                previousNode.style.height = "auto"
                //移动到了第一行
                if (focusOffset <= lineTextLength && lineNumber > 1) {
                    if (keyDown.keyCode == 38 && focusOffset < lineTextLength || text == "") {
                        beforeTextLength = text.substring(0, focusOffset)
                        let previousNum = Math.floor(Number(previousNode.style.lineHeight.replace("px", "")))
                        let previousText = previousNode.innerText
                        setTimeout(() => {
                            //上个兄弟节点长度大于当前节点长度
                            let previouslineNumber = previousNode.clientHeight / previousNum //计算上一个节点的总行数
                            let paddingCount = this.clearNumberPx(previousNode.style.paddingRight) + this.clearNumberPx(previousNode.style.paddingLeft) //外边距
                            let previouslineTextLength = Math.floor((previousNode.clientWidth - paddingCount) / this.clearNumberPx(previousNode.style.fontSize)) //计算上一行的文字总长度
                            if (previousText.length > text.length) {
                                let offsetNumber = (previouslineNumber - 1) * (previouslineTextLength) //精确向上移动的坐标位置
                                beforeTextLength = previousText.substring(0, offsetNumber + focusOffset)
                            }
                            this.selectFocusLastPrevious(previousNode, beforeTextLength)
                        }, 10);
                    }
                } else if (lineNumber <= 1) { //总行数只有一行的直接向上移动
                    if (keyDown.keyCode == 38 && focusOffset >= lineTextLength * (lineNumber - 1) || text == "") {
                        beforeTextLength = text.substring(lineTextLength * (lineNumber - 1), focusOffset)
                        previousNode.style.width = "auto"
                        previousNode.style.height = "auto"
                        let previousText = previousNode.innerText
                        let previousNum = Math.floor(Number(previousNode.style.lineHeight.replace("px", "")))
                        setTimeout(() => {
                            //上个兄弟节点长度大于当前节点长度
                            let previouslineNumber = previousNode.clientHeight / previousNum //计算上一个节点的总行数
                            let paddingCount = this.clearNumberPx(previousNode.style.paddingRight) + this.clearNumberPx(previousNode.style.paddingLeft) //外边距
                            let previouslineTextLength = Math.floor((previousNode.clientWidth - paddingCount) / this.clearNumberPx(previousNode.style.fontSize)) //计算上一行的文字总长度
                            if (previousText.length > text.length) {
                                let offsetNumber = (previouslineNumber - 1) * (previouslineTextLength) //精确向上移动的坐标位置
                                beforeTextLength = previousText.substring(0, offsetNumber + focusOffset)
                            }
                            this.selectFocusLastPrevious(previousNode, beforeTextLength)
                        }, 10);
                    }
                }
            }
            //下箭头
            if (keyDown.keyCode == 40) {
                if (this.inputTextView.parentNode.parentNode.nextSibling == null) {
                    return
                }
                let text = this.inputTextView.innerText //文本内容
                let focusOffset = this.getCursorPositionToChildNode().focusOffset //光标偏移数值
                //去除px拿到行高
                let num = Math.floor(Number(this.inputTextView.style.lineHeight.replace("px", "")));
                let lineNumber = this.inputTextView.clientHeight / num //计算总行数
                let paddingCount = this.clearNumberPx(this.inputTextView.style.paddingRight) + this.clearNumberPx(this.inputTextView.style.paddingLeft) //外边距
                let lineTextLength = Math.floor((this.inputTextView.clientWidth - paddingCount) / this.clearNumberPx(this.inputTextView.style.fontSize)) //计算一行的总长度
                let beforeTextLength //光标位置
                let nextNode = this.inputTextView.parentNode.parentNode.nextSibling.getElementsByClassName("textContentBox")[0].getElementsByClassName("textContent")[0]    //下一个兄弟节点
                //光标位置大于单行长度*总长度-1
                if (focusOffset > lineTextLength * (lineNumber - 2)) {
                    if (keyDown.keyCode == 40 && focusOffset >= lineTextLength * (lineNumber - 1) || text == "") {
                        beforeTextLength = text.substring(lineTextLength * (lineNumber - 1), focusOffset)
                        setTimeout(() => {
                            this.selectFocusLastNext(nextNode, beforeTextLength)
                        }, 1);
                    }
                } else if (lineNumber <= 1) {
                    if (keyDown.keyCode == 40 && focusOffset >= lineTextLength * (lineNumber - 1) || text == "") {
                        beforeTextLength = text.substring(lineTextLength * (lineNumber - 1), focusOffset)
                        setTimeout(() => {
                            this.selectFocusLastNext(nextNode, beforeTextLength)
                        }, 1);
                    }
                }
            }
        };
        this.textEdit()
    }

    //去除px
    clearNumberPx(num) {
        return Number(num.replace("px", ""));
    }

    //克隆文字对象
    cloneTextContent() {
    }

    //点击编辑
    textEdit() {
        this.context.onclick = () => {
            if (this.mindTextSizeChangedDelegate.isTemplatePage || this.mindTextSizeChangedDelegate.isSharePage) {  //禁止在模板或者分享页面编辑
                this.inputTextView.style.userSelect = 'text'
                this.inputTextView.setAttribute('contenteditable', false)
            } else {
                this.inputTextView.setAttribute('contenteditable', true)
            }
            this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1));

            document.addEventListener("click", (e) => {
                if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
                    this.mindTextSizeChangedDelegate.onTextSizeChanged(this.inputTextView.innerText, 0, 0, this.mindElementData.id);
                }
            })
        }
    }

    //监听文字变换
    observerInput() {
        if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
            let that = this
            // 选择将观察突变的节点
            var targetNode = this.inputTextView

            // 观察者的选项(要观察哪些突变)
            var config = { attributes: true, characterData: true, childList: true, subtree: true };

            // 当观察到突变时执行的回调函数
            var callback = function (mutationsList) {
                mutationsList.forEach(function (item, index) {
                    if (item.type == 'characterData') {
                        let text = that.inputTextView.innerText
                        let dataText = that.getContentText()
                        if (text.length == dataText.length) {
                            if (that.mindTextSizeChangedDelegate != null && dataText != text) {
                                that.mindTextSizeChangedDelegate.onTextSizeChanged(text, 0, 0, that.mindElementData.id);
                            }
                        }
                    }
                });
            };

            // 创建一个链接到回调函数的观察者实例
            var observer = new MutationObserver(callback);

            // 开始观察已配置突变的目标节点
            observer.observe(targetNode, config);
            // 停止观察
        }
    }

    //光标位置
    getCursorPositionToChildNode() {
        var doc = this.inputTextView.ownerDocument || this.inputTextView.document;
        var win = doc.defaultView || doc.parentWindow;
        var caretOffset
        var sel
        sel = win.getSelection();
        return sel
    }

    //通过光标位置截取字符
    getCursorPosition() {
        var doc = this.inputTextView.ownerDocument || this.inputTextView.document;
        var win = doc.defaultView || doc.parentWindow;
        var caretOffset
        var sel
        sel = win.getSelection();
        if (sel.rangeCount > 0) {//选中的区域
            var range = win.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();//克隆一个选中区域
            preCaretRange.selectNodeContents(this.inputTextView);//设置选中区域的节点内容为当前节点
            preCaretRange.setEnd(range.endContainer, range.endOffset);  //重置选中区域的结束位置
            caretOffset = preCaretRange.toString().length;
        }
        return caretOffset
    }

    //将光标移入div内容的最后
    selectFocusLast() {
        this.inputTextView.setAttribute("contenteditable", true)
        let range = document.createRange();
        range.selectNodeContents(this.inputTextView)
        range.collapse(false);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    //将光标移入上个div
    selectFocusLastPrevious(node, position) {
        node.setAttribute("contenteditable", true)
        let range = document.createRange();//创建一个选中区域
        range.selectNodeContents(node);//选中节点的内容
        if (node.innerHTML.length > 0) {
            let targetNode = node
            for (let index = 0; index < 6; index++) {
                if (targetNode.childNodes != null && targetNode.childNodes.length > 0 && 
                    targetNode.childNodes[0].nodeName != null && 
                    (targetNode.childNodes[0].nodeName.toLowerCase() == "div" || targetNode.childNodes[0].nodeName.toLowerCase() == "span")) {
                        targetNode = targetNode.childNodes[0]
                }
            }
            let startNode = targetNode.childNodes != null && targetNode.childNodes.length > 0 ? targetNode.childNodes[0] : targetNode
            if (position.length > targetNode.innerHTML.length) {
                range.setStart(startNode, targetNode.innerHTML.length) //大于下个节点的长度则移到最后
            } else {                
                range.setStart(startNode, position.length); //设置光标起始为指定位置
            }
        }
        range.collapse(true);       //设置选中区域为一个点
        let selection = window.getSelection();//获取当前选中区域
        selection.removeAllRanges();//移出所有的选中范围
        selection.addRange(range);//添加新建的范围
    }

    //将光标移入下个div
    selectFocusLastNext(node, position) {
        node.setAttribute("contenteditable", true)
        let range = document.createRange();//创建一个选中区域
        range.selectNodeContents(node);//选中节点的内容
        if (node.innerHTML.length > 0) {
            let targetNode = node
            for (let index = 0; index < 6; index++) {
                if (targetNode.childNodes != null && targetNode.childNodes.length > 0 && 
                    targetNode.childNodes[0].nodeName != null && 
                    (targetNode.childNodes[0].nodeName.toLowerCase() == "div" || targetNode.childNodes[0].nodeName.toLowerCase() == "span")) {
                        targetNode = targetNode.childNodes[0]
                }
            }
            let startNode = targetNode.childNodes != null && targetNode.childNodes.length > 0 ? targetNode.childNodes[0] : targetNode

            if (position.length > targetNode.innerHTML.length) {
                range.setStart(startNode, targetNode.innerHTML.length) //大于下个节点的长度则移到最后
            } else {
                range.setStart(startNode, position.length); //设置光标起始为指定位置
            }
        }
        range.collapse(true);       //设置选中区域为一个点
        let selection = window.getSelection();//获取当前选中区域
        selection.removeAllRanges();//移出所有的选中范围
        selection.addRange(range);//添加新建的范围
    }

    textViewShouldBeginEditing(textView) {

    }

    textViewShouldEndEditing(textView) {

    }

    setIconLeft() {
        this.imageStartLeft = this.getStartLeft();
    }

    getStartLeft() {
        return (this.mindElementData.level - 1) * Config.MindOutlineImageSize + Config.MindInputText_lineFragmentPadding;
    }


    refreshLayout() {
        this.setIconLeft();
        this.setRowMarkLayout();
        super.refreshLayout();

    }

    getTextUIEdgeInsets() {
        return Config.OutlineInputUIEdgeInsets;
    }


    setSelected(value) {
    }

    setInputEdit(bool) {
        super.setInputEdit(bool);
    }


    setImage() {
        let imageContent = this.mindElementData.imageContent;
        let icons = this.mindElementData.iconElementContents
        let iconWidth = 0
        if (icons != null) {
            for (let i = 0; i < icons.length; i++) {
                iconWidth = iconWidth + Config.normalOutlineFontSize + 10
            }
        }

        if (this.mindElementData.imageContent == null) {
            this.context.style.display = 'flex'
            this.context.style.flexWrap = 'nowrap'
            this.context.style.alignItems = 'start'
            this.iconAndImageContent.style.width = '0'
            this.iconAndImageContent.style.marginLeft = '-6px'
            this.editSmallDot.style.position = 'relative'
            this.editSmallDot.style.top = '15px'
            this.inputTextView.style.marginLeft = 0 + 'px'
        } else {
            //主标题添加
            if (this.mindElementData.type == MindElementType.MAIN_SUBJECT) {
                this.context.style.display = 'inline'
            }
            this.context.style.flexWrap = 'wrap'
            this.context.style.alignItems = 'center'
            this.iconAndImageContent.style.width = 'auto'
            this.iconAndImageContent.style.marginLeft = '6px'
            this.iconAndImageContent.style.display = 'flex'
            this.editSmallDot.style.position = 'none'
            this.editSmallDot.style.top = '0'
            this.inputTextView.style.marginLeft = 9 + this.clearNumberPx(this.editSmallDot.style.width) + iconWidth + 'px'
        }
        if (imageContent == null || imageContent.key == "" || imageContent.key == null) {
            if (this.imageView != null) {
                this.imageView.parentNode.removeChild(this.imageView)
            }
            if (this.imageViewTap != null && this.imageViewTap.photoShade != null && this.imageViewTap.photoShade.parentNode != null) {
                this.imageViewTap.photoShade.parentNode.removeChild(this.imageViewTap.photoShade);
                this.imageViewTap.photoShade = null
            }
            if (this.imageViewTap != null && this.imageViewTap.photoForeignObject != null && this.imageViewTap.photoForeignObject.parentNode != null) {
                this.imageViewTap.photoForeignObject.parentNode.removeChild(this.imageViewTap.photoForeignObject);
                this.imageViewTap.photoForeignObject = null
            }
            this.imageViewTap = null;
            this.imageView = null;
            return;
        }
        if (this.imageView == null) {
            let img = new ShapeImageOutlineView(this, this.iconAndImageContent, imageContent, imageContent.shapeType, this.mindElementData);
            this.imageView = img.photo

        }
        this.imageView.style.display = 'block'

        if (!imageContent.key != null) {
            let srcHttp = HttpServerConfig.getMediaSrc(imageContent.key)
            new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
                this.imageViewTap = imageBase64
            })
        }
    }
    setInputEdit(bool, isShowSoftInput) {
    }

    setSelectedLayer() {
        if (this.selectedView != null && this.selectedView.parentNode != null) {
            this.removeView(this.selectedView);
        }
    }


    setBackgroundView() {
        if (this.backgroundView != null) {
            this.backgroundView.setVisibility(GONE);
        }
    }

    getContentText() {
        return new Strings().lineFeed(super.getContentText())
    }

    setText() {
        let textContent = this.getContentText()
        this.inputTextView.innerHTML = textContent;

    }

    setTextStyleData(isChange) {
        this.inputTextView.style.fontSize = Config.normalOutlineFontSize + 'px'
        this.inputTextView.style.color = Colors.getUiColor(this.getTextColor())
        this.inputTextView.setAttribute('abcdef', this.getTextColor())
        this.inputTextView.setAttribute('node-unique-id', 'NodeUniqueID'+ this.mindElementData.id)

        if (this.mindElementData.type == MindElementType.MAIN_SUBJECT) {
            this.inputTextView.style.fontSize = '20px'
        }

        if (this.getTextBold()) { //字体是否加粗
            this.inputTextView.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.inputTextView.style.fontWeight = Config.textNormalFontWeight
        }

        if (this.getTextItalics()) {    //设置为斜体
            this.inputTextView.style.fontStyle = 'italic'
        } else {
            this.inputTextView.style.fontStyle = 'normal'
        }

        if (this.getTextStrikethrough()) {    //删除线
            this.inputTextView.style.textDecoration = 'line-through'
        } else {
            this.inputTextView.style.textDecoration = 'none'
        }
    }


    getTextColor() {
        let color = Colors.black55;
        if (this.mindElementData.textContent != null) {
            color = this.mindElementData.textContent.textColor;
        } else if (this.mindElementData.generalizationContent != null) {
            color = this.mindElementData.generalizationContent.textColor;
        }
        if (Colors.getUiColor(color) == -1 || Colors.getUiColor(color) == Colors.getUiColor(Colors.white)) {
            color = Colors.black60;
        }

        return color;
    }

    setRowMark() {

    }

    setRowMarkLayout() {

    }

    getRowMarkTop() {
        if (this.mindElementData == null) {
            return 15;
        }

        return this.getTextUIEdgeInsets().top + new UiUtil().dip2px(8) - 0;
    }


    setInputTextViewLayout() {
        if (this.inputTextView.parentNode == null || this.mindElementData == null) {
            return;
        }

        let textMindElementContent = null;
        if (this.mindElementData.textContent != null) {
            textMindElementContent = this.mindElementData.textContent;
        } else if (this.mindElementData.generalizationContent != null) {
            textMindElementContent = this.mindElementData.generalizationContent;
        }
        if (textMindElementContent == null) {
            return;
        }
    }


    setImageViewLayout() {

    }


    setIconsViewLayout() {

        if (this.icons == null || this.icons.length == 0 || this.mindElementData.iconElementContents == null) {
            return;
        }

        let iconElementContents = this.mindElementData.iconElementContents;
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconElementContent = iconElementContents[index];
            let key = iconElementContent.key;
            if (!this.icons.keys().includes(key)) {
                continue;
            }
            let iconView = this.icons.get(key);
            let iconY = iconElementContent.y;
            if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                (iconElementContent.layoutType == IconLayoutType.LAYOUT_LEFT || iconElementContent.layoutType == IconLayoutType.LAYOUT_RIGHT)) {
                iconY = (this.mindElementData.height - iconElementContent.height) / 2;
            }
            iconView.setSize(iconElementContent.width, iconElementContent.height);
            iconView.setPoint(iconElementContent.x, iconY);
            if (iconView.data != null) {
                iconView.data.width = iconElementContent.width;
                iconView.data.height = iconElementContent.height;
                iconView.refreshSize();
                //由于刷新的时候也调用了setPoint，但因为没有this.mindElementData.height，所以表格模式下计算不准。所以要重新设置一下setPoint
                iconView.setPoint(iconElementContent.x, iconY);
            }
        }
    }


    onTextSizeChanged(text, width, height) {
        text = new Strings().lineFeed(text)
        if (this.mindElementData == null) {
            return;
        }
        if (this.mindTextSizeChangedDelegate != null) {
            if ((text.indexOf("\n") == -1 || text.indexOf("\r") == -1)) {
                let newlines = text.split("\n");
                if (newlines.length == 0) {
                    newlines = text.split("\r");
                }
                if (newlines.length > 0) {
                    if (true) {
                        text = text.replace("\r", "").replace("\n", "");
                        this.inputTextView.innerText = text;
                        super.onTextSizeChanged(text, width, height);
                    }
                }
            } else {
                super.onTextSizeChanged(text, width, height);
            }
        }
        this.refresh();
    }


    deleteBackward() {
        if (this.selectedProtocol != null) {
            this.selectedProtocol.inputDeleteBackward(this);
        }
    }

    getTextSize(text) {
        if (this.mindElementData == null) {
            return new Size(10, 20);
        }
        let data = this.mindElementData.copy();
        let textMindElementContent = null;
        if (data.textContent != null) {
            textMindElementContent = data.textContent;
            data.textContent.text = text;
        } else if (data.generalizationContent != null) {
            textMindElementContent = data.generalizationContent;
            data.generalizationContent.text = text;
        } else {
            return new Size(10, 20);
        }

        new OutlineMindElementCalculation(data).caluleText();
        return new Size(textMindElementContent.width, textMindElementContent.height);
    }


    setLinkIconsViewLayout() {
    }


    setRemarksIconsViewLayout() {
        if (this.remarksIcon == null || this.remarksIcon.iconImage.parentNode == null || !this.mindElementData.isContainRemarksContent()) {
            return;
        }
    }


    setConnectMapIconsViewLayout() {

    }

    getViewIndex() {
        return this.addViewIndex;
    }

    setViewIndex(index) {
        this.addViewIndex = index;
    }
}
export default OutlineInputView
