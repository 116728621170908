import ImageShapeType from '../../../viewmodel/datatype/ImageShapeType'  //图片类型枚举 
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil"
import HttpServerConfig from "../../../common/HttpServerConfig"
import imageToStyle from '../../../utils/ImageToolkit';
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import MindElementType from '../../../viewmodel/datatype/MindElementType';

class ShapeImageView {
    constructor(mindTextSizeChangedDelegate, root, data, type = ImageShapeType.Circular) {
        this.deletage = mindTextSizeChangedDelegate;
        this.root = root;
        this.photo; //img标签
        this.photoForeignObject;  //用于包裹img标签
        this.photoShade; //用于遮挡图片。因为拖动图片会改变节点位置。
        this.data = data;
        this.type = type;
        this.imgClick;
        this.UiUtil = new UiUtil();
        this.initView();
    }
    initView() {
        this.photoForeignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.photoForeignObject.setAttribute('width', this.data.width);
        this.photoForeignObject.setAttribute('height', this.data.height);
        this.photoForeignObject.setAttribute('x', this.data.x);
        this.photoForeignObject.setAttribute('y', this.data.y);
        this.photo = document.createElement('div')
        this.photo.className = 'photo-div-box';
        this.photo.style.width = this.data.width + 'px';
        this.photo.style.height = this.data.height + 'px';
        this.photo.style.display = 'block';
        this.photo.style.userSelect = 'none';
        this.photo.style.borderRadius = 0;
        if (this.type == ImageShapeType.Circular) {  //圆
            this.photo.style.borderRadius = '50%'
        } else if (this.type == ImageShapeType.Diamond) { //菱
            this.photo.style.clipPath = "polygon(50% 0,100% 50%,50% 100%,0 50%)"
        } else {
            this.photo.style.borderRadius = this.UiUtil.dip2px(6) + 'px';
            if (this.mindTextSizeChangedDelegate.mindElementData.type == MindElementType.SUBJECT || this.mindTextSizeChangedDelegate.mindElementData.type == MindElementType.CONTENT_GENERALIZATION) {
                this.photo.style.borderRadius = this.UiUtil.dip2px(6) + 'px';
            } else if (this.mindTextSizeChangedDelegate.mindElementData.type == MindElementType.SON_SUBJECT) {
                this.photo.style.borderRadius = this.UiUtil.dip2px(5) + 'px';
            }

        }
        let srcHttp = httpImageToPrefix(this.data.key)

        let srcHttpAccout = srcHttp.substring(0, 4) == "http"
        if (srcHttpAccout || srcHttp.startsWith('data:image')) {
            if (srcHttpAccout) {
                new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
                    this.checkImgUrl(imageBase64);
                })
            } else {
                new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
                    this.checkImgUrl(imageBase64);
                })
            }
        } else {
            new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
                this.checkImgUrl(imageBase64);
            })
        }

        //右击事件
        if (this.imgClick == null) {
            this.imgClick = (ev) => {
                if (ev.button == 2) {
                    window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                    this.onClick(ev, ev.button)
                }
                if (ev.button === 0) {
                    this.onClick(ev, ev.button)
                }
            }
        }

        this.photoForeignObject.appendChild(this.photo);
        this.root.appendChild(this.photoForeignObject);
        this.photoShade = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        this.photoShade.setAttribute('width', this.data.width);
        this.photoShade.setAttribute('height', this.data.height);
        this.photoShade.setAttribute('x', this.data.x);
        this.photoShade.setAttribute('y', this.data.y);

        this.photoShade.style.display = 'block';
        this.photoShade.style.userSelect = 'none';
        this.photoShade.style.zIndex = 2;
        this.root.appendChild(this.photoShade);

        this.photo.addEventListener('mouseup', this.imgClick);
        this.photoShade.addEventListener('mouseup', this.imgClick);
    }

    checkImgUrl(url) {
        this.photo.style.background = "center center url(" + url + ")";
        this.photo.style.backgroundRepeat = "no-repeat";
        this.photo.style.backgroundSize = "contain";

    }

    setData(data) {
        this.data = data;
        if (this.data != null) {
            this.refresh();
        }
    }

    onClick(event, clickType = null) {
        this.deletage.onSelectedImg(this.data, event, clickType)
    }



    refresh() {
        this.photo.style.display = 'block';
        this.photoForeignObject.setAttribute('width', this.data.width);
        this.photoForeignObject.setAttribute('height', this.data.height);
        this.photoForeignObject.setAttribute('x', this.data.x);
        this.photoForeignObject.setAttribute('y', this.data.y);
        this.photo.style.width = this.data.width + 'px';
        this.photo.style.height = this.data.height + 'px';
        this.photo.style.clipPath = ''
        this.photo.style.borderRadius = 0
        if (this.type == ImageShapeType.Circular) {  //圆
            this.photo.style.borderRadius = '50%'
        } else if (this.type == ImageShapeType.Diamond) { //菱
            this.photo.style.clipPath = "polygon(50% 0,100% 50%,50% 100%,0 50%)"
        }
        this.checkImgUrl(imageBase64);
    }

    refreshSize() {
        this.photoForeignObject.setAttribute('width', this.data.width);
        this.photoForeignObject.setAttribute('height', this.data.height);
        this.photoForeignObject.setAttribute('x', this.data.x);
        this.photoForeignObject.setAttribute('y', this.data.y);
        this.photo.style.width = this.data.width + 'px';
        this.photo.style.height = this.data.height + 'px';
    }

    setSize(width, height) {
        this.photoForeignObject.setAttribute('width', width);
        this.photoForeignObject.setAttribute('height', height);
        this.photo.style.width = width + 'px';
        this.photo.style.height = height + 'px';
    }

    setPoint(x, y) {
        this.photoForeignObject.setAttribute('x', x);
        this.photoForeignObject.setAttribute('y', y);
    }
}

export default ShapeImageView