<template >
  <div class="sheet-top-box-menu" v-if="moveSheetMenu"   :style="{marginLeft:reightMenuX+'px',}">
    <!-- <div class="sheet-top-box-menu-son" 
    v-for="(item , index) in itemList" 
    :key="index" 
    @click.stop="sheetOnCilck(item,index)">
      {{ getString(item.name) }}
    </div> -->
    <div class="sheet-top-box-menu-son" @click.stop="sheetEditInsertCheck()">
      {{ getString(strings.Mind_Edit_Insert) }}
    </div>
    <div class="sheet-top-box-menu-son" @click.stop="sheetCopyCheck()">
      {{ getString(strings.Mind_Edit_Node_Menu_Copy) }}
    </div>
    <div v-if="showComplieOrArvhiveMap" class="sheet-top-box-menu-son" @click.stop="sheetExportCheck()">
      {{ getString(strings.Mind_Group_Export) }}
    </div>
    <div class="sheet-top-box-menu-son" @click.stop="sheetFolderRenameCheck()">
      {{ getString(strings.User_Folder_Rename) }}
    </div>
    <div class="sheet-top-box-menu-son" @click.stop="sheetRemove()">
      {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
  name: "CompileMySheetContent",
  props: ["moveSheetMenu","item", "index","reightMenuX"],
  data() {
    return {
      fullWidth: document.documentElement.clientWidth,
      strings: strings,
      showComplieOrArvhiveMap:false,
      itemList:[
        {
          name:strings.Mind_Edit_Insert,
          id:1
        },
        {
          name:strings.Mind_Edit_Node_Menu_Copy,
          id:2
        },
        {
          name:strings.Mind_Group_Export,
          id:3
        },
        {
          name:strings.User_Folder_Rename,
          id:4
        },
        {
          name:strings.Mind_Mindmap_Deleted_Forever,
          id:5
        },
      ]
    };
  },
  created() {
    if(this.$route.name == "Complie" || this.$route.name == "arvhiveMap"){
      this.showComplieOrArvhiveMap = true;
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(["sharePotatoChipsModel"]),
    getString(i) {
      return getString(i);
    },
    hidden(obj){
      this.$emit("hiddenSheetTopReightMenu", obj);
    },
    //插入
    sheetEditInsertCheck(){
      let obj={item:this.item,index:this.index,menuName:"sheetEditInsertCheck" }
      this.hidden(obj);
    },
    //右键菜单--复制
    sheetCopyCheck(){
      let obj={item:this.item,index:this.index,menuName:"sheetCopyCheck" }
      this.hidden(obj);
    },
    //右键菜单插入--重命名
    sheetFolderRenameCheck(){
      let obj={item:this.item,index:this.index,menuName:"sheetFolderRenameCheck" }
      this.hidden(obj);
    },
    //右键菜单插入--删除
    sheetRemove(){
      let obj={item:this.item,index:this.index,menuName:"sheetRemove" }
      this.hidden(obj);
    },
    //右键菜单--导出
    sheetExportCheck(){
      let obj={item:this.item,index:this.index,menuName:"sheetExportCheck" }
      this.hidden(obj);
    },
  },
  destroyed() {},
};
</script>

<style  lang="less">
  .sheet-top-box-menu{
  position: absolute;
  margin-top: -255px;
  // margin-left: 20px;
  width: 95px;
  height: 212px;
  background: #FFFFFF;
  // box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.1);
  box-shadow: 0 0 5px #999;
  opacity: 1;
  border-radius: 4px;
}
.sheet-top-box-menu-son{
  margin-top: 2px;
  width: 95px;
  height: 40px;
  background: #ffffff;
  text-align: center;
  line-height: 40px;
  color: #7B8288;
  cursor:pointer;
}
.sheet-top-box-menu-son:hover{
  background: #F8F8F8;
}
</style>
