// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import java.util.ArrayList;

import MindElementContent from './MindElementContent.js';

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors'
// import mind.yushu.com.mindmap.viewmodel.datatype.OrientationType;
import OrientationType from '../../datatype/OrientationType'
// import mind.yushu.com.mindmap.viewmodel.datatype.TimeLineNodeType;
import TimeLineNodeType from '../../datatype/TimeLineNodeType'
import TimeNodeLayoutType from '../../datatype/TimeNodeLayoutType.js';

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineContent extends MindElementContent {
    constructor(){
        super();
        this.color = Colors.black60;
        this.dotColor = Colors.black30;
        this.lineWidth = 3;
        this.dots = new Array();
        this.nodeType = TimeLineNodeType.TIME_LINE_CIRCULAR;
        this.orientationType = OrientationType.VERTICAL;
        this.lineContentHeight = 12;
        this.lineContentWidth = 12;
        this.dottedLine = false;
        this.timeNodeLayoutType = TimeNodeLayoutType.NORMAL;
        this.lineThicken = false;
        this.isShowArrow = false;
    }




    copy() {
        let data = new TimeLineContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.color = this.color;
        data.dotColor = this.dotColor;
        data.lineWidth = this.lineWidth;
        data.nodeType = this.nodeType;
        data.orientationType = this.orientationType;
        data.lineContentWidth = this.lineContentWidth;
        data.lineContentHeight = this.lineContentHeight;
        data.timeNodeLayoutType = this.timeNodeLayoutType;
        data.lineThicken = this.lineThicken;
        data.isShowArrow = this.isShowArrow;
        data.dottedLine = this.dottedLine;
        
        let dotsCount = this.dots.length;
        if (dotsCount > 0) {
            for (let index = 0; index < dotsCount; index++) {
                data.dots.push(this.dots[index].copy());
            }
        }
        return data;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.color = data.color;
        this.dotColor = data.dotColor;
        this.lineWidth = data.lineWidth;
        this.nodeType = data.nodeType;
        this.orientationType = data.orientationType;
        this.lineContentWidth = data.lineContentWidth;
        this.lineContentHeight = data.lineContentHeight;
        this.dottedLine = data.dottedLine;
        this.lineThicken = data.lineThicken;
        this.isShowArrow = data.isShowArrow;
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
        this.color = data.color;
        this.dotColor = data.dotColor;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.color != this.color;
        result = result || data.dotColor != this.dotColor;
        result = result || data.nodeType != this.nodeType;
        result = result || data.lineWidth != this.lineWidth;
        result = result || data.dots.length != this.dots.length;
        result = result || data.orientationType != this.orientationType;
        result = result || data.dottedLine != this.dottedLine;
        result = result || data.timeNodeLayoutType != this.timeNodeLayoutType;
        result = result || data.lineThicken != this.lineThicken;
        result = result || data.isShowArrow != this.isShowArrow;
        if (!result) {
            let dotsCount = this.dots.length;
            if (dotsCount > 0) {
                for (let index = 0; index < dotsCount; index++) {
                    result = result || !(this.dots[index].equal(data.dots[index]));
                }
            }
        }
        return !result;
    }
}

export default TimeLineContent

