
import Config from "../../../core/core/calcule/Config"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import LineOrientation from "../../datatype/LineOrientation"
import MindElementType from "../../datatype/MindElementType"
import DoubleBubbleDataLayoutDelegate from "../../../viewmodel/delegate/DoubleBubbleDataLayoutDelegate"
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode"
import MindElementData from "../../mindelementdata/MindElementData"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import HashMap from "../../core/base/HashMap";
import Point from "../base/Point"
import NodeLayoutType from "../../datatype/NodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class DoubleBubbleLayout extends BaseLayout {
    constructor() {
        super()
        this.rootTreeBrotherNode; //主体兄弟节点数据结构
        this.commonGroundMindElementDatas = new Array();//两个主节点的共同子节点
        this.angle = 180.0;
        this.startAngle = 0.0;
        this.subjectRadius = new UiUtil().dip2px(80);
        this.sonSubjectRadius = new UiUtil().dip2px(50);
        this.nodeAngle = new HashMap();
        this.sonSubjectAngle = 45.0;
        this.commonNodeSpaceVertical = new UiUtil().dip2px(30);
        this.commonWidth = new UiUtil().dip2px(50);
    }

    initConfig() {
        super.initConfig();
        this.subjectRadius = this.addSubjectSpcaeHorizontal(new UiUtil().dip2px(80));
        this.sonSubjectRadius = this.addSonSubjectSpcaeHorizontal(new UiUtil().dip2px(50));
    }


    setCommonData(rootTreeBrotherNode, commonElementDatas) {
        this.rootTreeBrotherNode = rootTreeBrotherNode;
        this.commonGroundMindElementDatas = commonElementDatas;
    }

    onLayout(isChange) {
        this.initializationCalcule();
        this.setElementsPoint(isChange);
    }

    callbackResetDatas() {
        super.callbackResetDatas();
        if (this.delegate != null && this.delegate instanceof DoubleBubbleDataLayoutDelegate && rootTreeBrotherNode != null) {
            this.delegate.callbackDoubleBubbleResetDatas(this.rootTreeBrotherNode, this.commonGroundMindElementDatas);
        }
    }

    initializationCalcule() {
        if (this.rootTreeNode == null || this.rootTreeNode.children.length == 0) {
            return;
        }
        let count = this.rootTreeNode.children.length;
        this.angle = 360 / count;
        this.commonWidth = this.getCommonNodeMaxWidth();
        switch (this.commonGroundMindElementDatas.length) {
            case 2:
                this.commonNodeSpaceVertical = new UiUtil().dip2px(150);
                break;
            case 3:
                this.commonNodeSpaceVertical = new UiUtil().dip2px(85);
                break;
            default:
                this.commonNodeSpaceVertical = new UiUtil().dip2px(30);
        }
    }

    setElementsPoint(isChange) {
        if (!isChange && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - new UiUtil().getScreenWidth()) / 2;
            let top = (Config.Mind_Height - new UiUtil().getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (new UiUtil().getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        this.initConfig();

        let removeRootCommonData = []
        this.rootTreeNode.children.forEach(node => {
            if (this.isCommonData(node.value.id)) {
                removeRootCommonData.push(node);
            }
        });
        if (this.rootTreeBrotherNode != null) {
            this.rootTreeBrotherNode.children.forEach(node => {
                if (this.isCommonData(node.value.id)) {
                    removeRootCommonData.push(node);
                }
            });
        }
        removeRootCommonData.forEach(node => {
            this.rootTreeNode.removeChildrenNode(node);
            if (this.mainMindElementDataDict.containsKey(node.value.id)) {
                this.mainMindElementDataDict.remove(node.value.id);
            }
            if (this.rootTreeBrotherNode != null) {
                this.rootTreeBrotherNode.removeChildrenNode(node);
            }
        });

        this.setElementLine(this.rootTreeNode, isChange);
        if (this.rootTreeNode != null) {
            this.setNodeElementsPoint(this.rootTreeNode, isChange);
        }
        if (this.rootTreeBrotherNode != null) {
            this.rootTreeBrotherNode.value.y = this.rootTreeNode.value.y;
            if (this.commonGroundMindElementDatas.length > 0) {
                this.rootTreeBrotherNode.value.x = this.rootTreeNode.value.x + this.rootTreeNode.value.width +
                    (this.subjectRadius) + this.commonWidth + (this.subjectRadius);
            } else {
                this.rootTreeBrotherNode.value.x = this.rootTreeNode.getNodeRight() + new UiUtil().dip2px(70);
            }
        }
        if (this.commonGroundMindElementDatas.length > 0) {
            this.commonGroundMindElementDatas.forEach(data => {
                this.setCommonElementDataPoint(data, isChange);
            });
        }

        if (this.rootTreeBrotherNode != null) {
            this.setNodeElementsPoint(this.rootTreeBrotherNode, isChange);
        }

        if (this.commonGroundMindElementDatas.length > 0) {
            this.commonGroundMindElementDatas.forEach(data => {
                let node = this.getNodeById(data.id);
                if (this.rootTreeNode != null) {
                    this.setElementLines(node, isChange, this.rootTreeNode);
                }
                if (this.rootTreeBrotherNode != null) {
                    this.setElementLines(node, isChange, this.rootTreeBrotherNode);
                }
            });
        }

        
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }

    setDataPoint(data, isChange) {
        if (this.isCommonData(data.id)) {
            return;
        }
        let node = this.getNodeById(data.id);
        let parentNode = this.getNodeById(data.parentNodeId);
        this.pushCurrentLayoutNode(node);
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        MindElementCalculation.set(data).calcule();
        if (data.id == this.rootTreeNode.value.id || data.id == this.rootTreeBrotherNode.value.id) {
            return;
        } else {
            if (data.isFreeLayoutNode && this.settingData.branchNodeFreelayout.isValue() && data.type == MindElementType.SUBJECT) {
                if (data.x > 0 && data.y > 0) {
                    let startPoint = new Point(this.rootTreeNode.value.x + this.rootTreeNode.value.width/2, this.rootTreeNode.value.y + this.rootTreeNode.value.height/2)
                    let endPoint = new Point(data.x + data.width/2, data.y + data.height/2)
                    let angle = this.Util.getDegreesByTwoPoints(startPoint, endPoint);
                    this.nodeAngle.put(data.id, angle);
                    this.setElementLine(node, isChange);
                    return
                }
            }

            let parentCenterPoint = new CGPoint((parentNode.value.x) + (parentNode.value.width / 2),
                (parentNode.value.y) + (parentNode.value.height / 2));

            let radius = (parentNode.value.width / 2) + (data.width / 2);

            if (data.type == MindElementType.SUBJECT) {
                radius = radius + this.subjectRadius;
            } else if (data.type == MindElementType.SON_SUBJECT) {
                radius = radius + this.sonSubjectRadius;
            }
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            let count = siblingsNodeDatas.length;

            let dataSiblingsNodesAngle = 360 / count;
            if (parentNode.value.id == this.rootTreeNode.value.id ||
                parentNode.value.id == this.rootTreeBrotherNode.value.id) {
                if (count == 2) {
                    dataSiblingsNodesAngle = 180 / count;
                } else {
                    dataSiblingsNodesAngle = 230 / count;
                }
            }

            let dataAngle = dataSiblingsNodesAngle * allSiblingsNodesPosition;
            if (this.nodeAngle.containsKey(parentNode.value.id)) {
                if (parentNode.children.length <= 4) {
                    dataAngle = this.sonSubjectAngle * allSiblingsNodesPosition;
                    dataAngle = dataAngle + this.nodeAngle.get(parentNode.value.id) - ((count - 1) * this.sonSubjectAngle) / 2;
                } else {
                    dataAngle = dataAngle + this.nodeAngle.get(parentNode.value.id);
                }
            } else {
                if (this.rootTreeNode != null && parentNode.value.id == this.rootTreeNode.value.id) {
                    dataAngle = dataSiblingsNodesAngle * (allSiblingsNodesPosition) - 180 - ((count - 1) * dataSiblingsNodesAngle) / 2;
                } else if (this.rootTreeBrotherNode != null && parentNode.value.id == this.rootTreeBrotherNode.value.id) {
                    dataAngle = dataSiblingsNodesAngle * (allSiblingsNodesPosition) - ((count - 1) * dataSiblingsNodesAngle) / 2;
                }
            }
            this.nodeAngle.put(data.id, dataAngle);
            let x = parentCenterPoint.x + (radius * (Util.cosd(dataAngle)));
            let y = parentCenterPoint.y + (radius * (Util.sind(dataAngle)));

            data.y = y - (data.height) / 2;
            data.x = x - (data.width) / 2;
        }
        this.setElementLine(node, isChange);
    }

    getSiblingsMindElementDataById(id) {
        let data = this.getNodeById(id).value;
        let parentNode = this.getNodeById(data.parentNodeId);
        if (data.isEmpty()) {
            return new Array();
        }
        let elementDatas = new Array();
        parentNode.children.forEach(item => {
            if (!this.isCommonData(item.value.id)) {
                elementDatas.push(item.value);
            }
        });
        return elementDatas;
    }

    setCommonElementDataPoint(data, isChange) {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return;
        }

        let node = this.getNodeById(data.id);
        let parentNode = this.rootTreeNode;
        let allCommonSiblingsNodesPosition = this.getCommonPoint(data);
        MindElementCalculation.set(data).calcule();
        if (data.isFreeLayoutNode && this.settingData.branchNodeFreelayout.isValue()) {
            if (data.x > 0 && data.y > 0) {
                if (this.rootTreeNode != null) {
                    this.setElementLines(node, isChange, this.rootTreeNode);
                }
                if (this.rootTreeBrotherNode != null) {
                    this.setElementLines(node, isChange, this.rootTreeBrotherNode);
                }
                return
            }
        }
        let x = 0;
        let y = 0;
        let parentCenterPoint = new CGPoint(parentNode.value.x + (parentNode.value.width / 2),
            (parentNode.value.y) + (parentNode.value.height / 2));
        let radius = (parentNode.value.width / 2) + (this.commonWidth / 2) + this.subjectRadius;
        x = parentCenterPoint.x + radius - data.width / 2;
        if (allCommonSiblingsNodesPosition == 0) {
            let commonNodesHeight = this.getCommonNodeHeight();
            y = parentCenterPoint.y - commonNodesHeight / 2;
        } else {
            let topData = this.commonGroundMindElementDatas[allCommonSiblingsNodesPosition - 1];
            y = topData.y + topData.height + (this.commonNodeSpaceVertical);
        }

        data.y = y;
        data.x = x;
    }

    getTargetLine(nodeId) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }
        if (this.isCommonData(nodeId)) {
            let data = this.getLineDataByParentNode(node, this.rootTreeNode);
            if (!data.isEmpty()) {
                return data;
            }
            let length = this.commonGroundMindElementDatas.length;
            for (let index = 0; index < length; index++) {
                let cell = this.commonGroundMindElementDatas[index];
                data = this.getLineDataByParentNode(this.getNodeById(cell.id), this.rootTreeNode);
                if (!data.isEmpty()) {
                    return data;
                }
            }
        } else {
            let data = this.getLineData(node);
            if (!data.isEmpty()) {
                return data;
            }
            let datas = this.getSiblingsMindElementDataById(nodeId);
            let length = datas.length;
            for (let index = 0; index < length; index++) {
                let cell = datas[index];
                data = this.getLineData(this.getNodeById(cell.id));
                if (!data.isEmpty()) {
                    return data;
                }
            }
            return data;
        }
        return new MindElementData().emptyMindNode();
    }

    getCommonNodeHeight() {
        let height = 0;
        if (this.commonGroundMindElementDatas.length == 0) {
            return height;
        }
        for (let index = 0; index < this.commonGroundMindElementDatas.length; index++) {
            height += this.commonGroundMindElementDatas[0].height;
            if (index < this.commonGroundMindElementDatas.length - 1) {
                height += (this.commonNodeSpaceVertical);
            }
        }
        return height;
    }

    getCommonPoint(data) {
        if (this.commonGroundMindElementDatas.isEmpty()) {
            return -1;
        }

        for (let index = 0; index < this.commonGroundMindElementDatas.length; index++) {
            if (this.commonGroundMindElementDatas[index].id == data.id) {
                return index;
            }
        }
        return -1;
    }

    isCommonData(id) {
        if (this.commonGroundMindElementDatas.isEmpty()) {
            return false;
        }

        let length = this.commonGroundMindElementDatas.length
        for (let index = 0; index < length; index++) {
            if (this.commonGroundMindElementDatas[index].id == id) {
                return true;
            }
        }
        return false;
    }

    getIntersectionPoint(p1, p2) {
        //已知直线上的两点P1(X1,Y1) P2(X2,Y2)，直线的一般式方程AX+BY+C=0中， A = Y2 - Y1, B = X1 - X2, C = X2*Y1 - X1*Y2
        //直线l1：p1ax+p1by+p1c=0 直线l2：p2ax+p2by+p2c=0 交点坐标为((p1b*p2c-p2b*p1c)/(p1a*p2b-p2a*p1b)，(p2a*p1c-p1a*p2c)/(p1a*p2b-p2a*p1b))
        let p1a = p1[1].y - p1[0].y;
        let p1b = p1[0].x - p1[1].x;
        let p1c = p1[1].x * p1[0].y - p1[0].x * p1[1].y;

        let p2a = p2[1].y - p2[0].y;
        let p2b = p2[0].x - p2[1].x;
        let p2c = p2[1].x * p2[0].y - p2[0].x * p2[1].y;

        return new CGPoint((p1b * p2c - p2b * p1c) / (p1a * p2b - p2a * p1b), (p2a * p1c - p1a * p2c) / (p1a * p2b - p2a * p1b));
    }

    setElementLine(node, isChange) {
        this.setElementLines(node, isChange, new LineMindTypeNode());
    }

    setElementLines(node, isChange, presetParentNode) {
        let data = node.value;
        let parentNode = presetParentNode.isEmpty() ? this.getNodeById(data.parentNodeId) : presetParentNode;
        let parentNodeData = parentNode.value;
        if (parentNodeData.isEmpty()) {
            return;
        }
        let isCreateLineData = false;
        let lineData = this.getLineDataByParentNode(node, parentNode);

        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            lineData.parentNodeId = parentNodeData.id;
            isCreateLineData = true;
        }

        lineData.type = MindElementType.LINE;

        let parentCenterPoint = new CGPoint((parentNode.value.x) + (parentNode.value.width / 2),
            (parentNode.value.y) + (parentNode.value.height / 2));
        let dataCenterPoint = new CGPoint((data.x) + (data.width / 2),
            (data.y) + (data.height / 2));

        var startPoint = new CGPoint(0, (lineData.height));
        var endPoint = new CGPoint((lineData.width), 0);
        let parentIntersectionPoint = parentNode.value.getRightAngleShapeHitPoint(dataCenterPoint, 0);
        let dataIntersectionPoint = data.getRightAngleShapeHitPoint(parentCenterPoint, 0);
        lineData.width = Math.abs(parentIntersectionPoint.x - dataIntersectionPoint.x);
        lineData.height = Math.abs(parentIntersectionPoint.y - dataIntersectionPoint.y);
        let startPointX = 0;
        let startPointY = 0;
        let endPointX = 0;
        let endPointY = 0;

        if (parentIntersectionPoint.x < dataIntersectionPoint.x) {
            lineData.x = parentIntersectionPoint.x;
            startPointX = 0;
            endPointX = lineData.width;
        } else {
            lineData.x = dataIntersectionPoint.x;
            startPointX = lineData.width;
            endPointX = 0;
        }
        if (parentIntersectionPoint.y < dataIntersectionPoint.y) {
            lineData.y = parentIntersectionPoint.y;
            startPointY = 0;
            endPointY = lineData.height;
        } else {
            lineData.y = dataIntersectionPoint.y;
            startPointY = lineData.height;
            endPointY = 0;
        }
        endPoint = new CGPoint(endPointX, endPointY);
        startPoint = new CGPoint(startPointX, startPointY);


        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        lineData.lineContent.lineLayout = this.globalLayout;
        if (data.type == MindElementType.SON_SUBJECT) {
            lineData.lineContent.lineThicken = false;
        }
        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;
            let referenceNodeData = this.getTargetLine(node.value.id);
            if (!referenceNodeData.isEmpty()) {
                lineData.copyStyle(referenceNodeData);
            } else {
                if (this.textElementLineMindElementDataDict.containsKey(parentNode.value.id)) {
                    let parentNodeLine = this.textElementLineMindElementDataDict.get(parentNode.value.id);
                    lineNodeContent.color = parentNodeLine.lineContent.color;
                    lineNodeContent.lineWidth = parentNodeLine.lineContent.lineWidth;
                    lineNodeContent.dottedLine = parentNodeLine.lineContent.dottedLine;
                } else {
                    lineNodeContent.color = node.value.styleColor;
                    if (this.isUnderline(data)) {
                        lineNodeContent.lineWidth = data.borderWidth;
                    } else {
                        lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                    }
                }
                if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                    // let parentNodeChildrenSize = parentNode.children.length;
                    // let lineColorLength = this.settingData.lineColor.length;
                    // for (let index = 0; index < parentNodeChildrenSize; index++) {
                    //     if (parentNode.children[index].value.id == node.value.id) {
                    //         let colorPoint = index % lineColorLength;
                    //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                    //         break;
                    //     }
                    // }
                    let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                    lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
                }
                lineData.backgroundColor = Colors.clear;
                lineData.borderColor = Colors.clear;
                lineData.borderWidth = 0.0;
            }
            this.setLineThicken(lineData);
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
        }
    }

    getNodeById(id) {
        let node = super.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (this.rootTreeBrotherNode != null && id == this.rootTreeBrotherNode.value.id) {
            return this.rootTreeBrotherNode;
        }
        if (this.isCommonData(id)) {
            return this.getCommonNode(id);
        }
        return new LineMindTypeNode();
    }

    getLineDataByParentNode(node, parentNodeData) {
        if (node.isEmpty() || parentNodeData.isEmpty()) {
            return new MindElementData();
        }
        let keys = this.lineMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let data = this.lineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.LINE &&
                data.lineContent.targetId == node.value.id &&
                data.parentNodeId == parentNodeData.value.id) {
                return data;
            } else if (data.type == MindElementType.SON_LINE &&
                data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getCommonNode(id) {
        let length = this.commonGroundMindElementDatas.length;
        for (let index = 0; index < length; index++) {
            let data = this.commonGroundMindElementDatas[index];
            if (data.id == id) {
                return new LineMindTypeNode(data);
            }
        }
        return new LineMindTypeNode();
    }

    getCommonNodeMaxWidth() {
        let width = 0;
        this.commonGroundMindElementDatas.forEach(data => {
            MindElementCalculation.set(data).calcule();
            width = data.width > width ? data.width : width;
        });
        return width;
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_DOUBLE_BUBBLE;
    }
}
export default DoubleBubbleLayout
