/**
 * ProjectName: MindMap
 * Created by tony on 9/11/21
 * Copyright(c) 2020 mindyushu.com
 */

import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import LinePath from "../../../viewmodel/core/base/LinePath";
import Point from "../../../viewmodel/core/base/Point";
import Size from "../../../viewmodel/core/base/Size";

class TaskProgressView  {

    constructor(context, MindElementData,mindTextSizeChangedDelegate) {
        this.context = context;
        this.data = MindElementData;
        this.mindTextSizeChangedDelegate = mindTextSizeChangedDelegate
        this.label;
        this.textPaint;
        this.paint;
        this.paintCircle;
        this.paintPath;
        this.baselineWidth = new UiUtil().dip2px(3);
        this.progresslineWidth = new UiUtil().dip2px(3);
        this.sonProgresslineWidth = new UiUtil().dip2px(3);

        this.completionClick

        this.initView();
    }

    setData(data) {
        this.data = data;
    }

    initView() {
        this.paint = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this.paintCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        this.paintPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.textPaint = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        this.context.appendChild(this.paint);
        this.paint.appendChild(this.paintCircle);
        this.paint.appendChild(this.paintPath);
        this.onDraw();
        this.paint.addEventListener('mouseup', (ev)=>{
            if (ev.button == 2) {
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                this.mindTextSizeChangedDelegate.onSelected(this.data, new Point(-1, -1), { type: 'completionClick' }, ev);
             }
        });
    }

    refreshView() {
        // this.data = data;
        this.paint.innerHTML = "";
        this.paintCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        this.paintPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.textPaint = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        this.context.appendChild(this.paint);
        this.paint.appendChild(this.paintCircle);
        this.paint.appendChild(this.paintPath);
        this.onDraw();
    }

    onDraw() {
        let space = new UiUtil().dip2px(4);
        let radius = this.data.taskContent.height/2 - space - this.baselineWidth/2;
        let centerPoint = new Point(this.data.taskContent.height/2, this.data.taskContent.height/2);
        let size = new Size(this.data.taskContent.height, this.data.taskContent.height);
        let bgColor = 0;
        if (!Colors.isClear(this.data.backgroundColor)) {
            bgColor = this.data.backgroundColor;
        } else {
            bgColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor;
        }
        let isDarkColor = Colors.isDarkColor(bgColor);
        if (this.data.taskContent.taskProgress < 100) {
            
            this.paint.appendChild(this.textPaint);
            let text = getString(strings.Mind_level);
            
            if (Colors.isDarkColor(bgColor)) {
                this.textPaint.setAttribute("stroke",Colors.getUiColorByAlpha (Colors.white, 0.69));
                this.textPaint.setAttribute("fill", Colors.getUiColorByAlpha (Colors.white, 0.69));
            } else {
                this.textPaint.setAttribute("stroke", Colors.getUiColorByAlpha (Colors.black60, 0.5));
                this.textPaint.setAttribute("fill", Colors.getUiColorByAlpha (Colors.black60, 0.5));
            }
            this.textPaint.setAttribute("text-anchor", "middle");
            this.textPaint.setAttribute("dominant-baseline", "middle");
            this.textPaint.setAttribute("x", size.getWidth()/2);
            this.textPaint.setAttribute("y", size.getHeight()/2);
            this.textPaint.setAttribute("font-size", 8);
            this.textPaint.setAttribute("font-weight", 600);
            
            this.textPaint.setAttribute("stroke-width", 0.0);
            this.textPaint.innerHTML = text;
        }
        this.progresslineWidth = isDarkColor ? 4 : 3
        this.sonProgresslineWidth = 3
        this.paint.setAttribute("width", this.data.taskContent.height);
        this.paint.setAttribute("height", this.data.taskContent.height);        
        this.paint.setAttribute("viewBox", "0 0 " + this.data.taskContent.height + " " + this.data.taskContent.height);
        this.paint.style.float ="left";
        this.paintCircle.setAttribute("cx",centerPoint.x)
        this.paintCircle.setAttribute("cy",centerPoint.y)
        this.paintCircle.setAttribute("stroke-width",this.progresslineWidth)
        this.paintCircle.setAttribute("fill","none")
        if (isDarkColor) {
            this.paintCircle.setAttribute("stroke",Colors.getUiColorByAlpha (Colors.white, 0.69))
        } else {
            this.paintCircle.setAttribute("stroke",Colors.getUiColorByAlpha(Colors.black50, 0.4))
        }
        this.paintCircle.setAttribute("r",radius)
        
        if (this.data.taskContent.taskProgress > 0) {
            let taskProgressColor;
            if (this.data.taskContent.taskProgress >= 100 ||
                (this.data.taskContent.endTime <= 0 || this.data.taskContent.endTime > Util.getCurrentTimeInsecond())) {
                taskProgressColor = isDarkColor ? Colors.getUiColorByAlpha(Colors.progressDarkColorColor, 0.6) : Colors.getUiColorByAlpha(Colors.progressColor, 0.85);
            } else {
                taskProgressColor = isDarkColor ? Colors.getUiColorByAlpha(Colors.progressVigilanceDarkColorColor, 0.49) : Colors.getUiColorByAlpha(Colors.progressVigilanceColor, 0.89);
            }
            let progress;
            if(this.data.taskContent.taskProgress>=100){
                progress = 100;
            }else {
                progress = this.data.taskContent.taskProgress;
            }
            progress = Math.max(0, Math.min(progress, 100))
            let progressPath = new LinePath();
            
            const progressAngle = 360 * Math.min(99.99, progress)/100
            let intersectionPointX = centerPoint.x + ((radius) * Util.cosd(progressAngle));
            let intersectionPointY = centerPoint.y + ((radius) * Util.sind(progressAngle));

            progressPath.moveTo(centerPoint.x + radius, centerPoint.y)
            progressPath.circle(radius, radius, 0, progressAngle > 180 ? 1 : 0, 1, intersectionPointX, intersectionPointY);            

            this.paintPath.setAttribute("stroke-width",this.sonProgresslineWidth)
            this.paintPath.setAttribute("fill","none")
            this.paintPath.setAttribute("stroke",Colors.getUiColor(taskProgressColor))
            this.paintPath.setAttribute("stroke-linecap", "round")
            this.paintPath.setAttribute("d", progressPath.getLine())

            let taskProgressRadius = radius;

            if (this.data.taskContent.taskProgress >= 100) {
                let pathPoint = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                let pathPoint2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                let completeWidth = taskProgressRadius*2 - (Colors.isDarkColor(bgColor) ? new UiUtil().dip2px(2) : 0);
                let pacse = (size.getWidth() - completeWidth) / 2;
                let point1 = new Point(pacse + completeWidth * 0.3, pacse + completeWidth * 0.503);
                let point2 = new Point(pacse + completeWidth * 0.456, pacse + completeWidth * 0.654);
                let point3 = new Point(pacse + completeWidth * 0.782, pacse + completeWidth * 0.328);
                if (Colors.isDarkColor(bgColor)) {
                    
                    pathPoint.setAttribute("stroke-width", this.progresslineWidth + new UiUtil().dip2px(1));
                    pathPoint.setAttribute("stroke",Colors.getUiColorByAlpha (Colors.white, 0.69));
                    pathPoint.setAttribute("fill", "none")
                    pathPoint.setAttribute("stroke-linecap", "round")

                    let path1 = new LinePath();
                    path1.moveToByPoint(point1);
                    path1.lineToByPoint(point2);
                    path1.lineToByPoint(point3);
                    pathPoint.setAttribute("d",path1.getLine())
                    this.paint.appendChild(pathPoint);
                }

                pathPoint2.setAttribute("stroke-width",this.progresslineWidth);
                pathPoint2.setAttribute("stroke",Colors.getUiColor(taskProgressColor));
                pathPoint2.setAttribute("fill", "none")
                pathPoint2.setAttribute("stroke-linecap", "round")

                let path = new LinePath();
                path.moveToByPoint(point1);
                path.lineToByPoint(point2);
                path.lineToByPoint(point3);
                pathPoint2.setAttribute("d",path.getLine())
                this.paint.appendChild(pathPoint2);
            }
        }
    }
}

export default TaskProgressView