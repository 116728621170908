// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.DoubleBubbleMindNodeUnit;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNodeUnit;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.TimeMindTypeNodeUnit;

import UiUtil from "../../../utils/UiUtil"
import MindType from "../../../viewmodel/datatype/MindType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import HashMap from "../../../viewmodel/core/base/HashMap"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/11
 * Copyright(c) 2020 mindyushu.com
 */

class AutoAdsorptionFind {
    constructor() {
        this.mindtype = MindType.LINE_MAP;
        this.MaxDeviationValue = new UiUtil().dip2px(10);
        this.MaxDistance = new UiUtil().dip2px(600);
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
    }

    setData(mindtype, mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {
        this.mindtype = mindtype;
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
    }

    findAndChange(data) {

        var left = new MindElementData().emptyMindNode();
        var top = new MindElementData().emptyMindNode();
        var right = new MindElementData().emptyMindNode();
        var bottom = new MindElementData().emptyMindNode();
        var centerX = new MindElementData().emptyMindNode();
        var centerY = new MindElementData().emptyMindNode();
        let mainMindElementDataDictKeys = this.mainMindNodeUnit.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictKeys.length
        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            var node = this.mainMindNodeUnit.mainMindElementDataDict.get(mainMindElementDataDictKeys[index]);
            if (!this.checkDataDistance(data, node.value)) {
                continue
            }
            if (this.checkDataLeft(data, node.value, left)) {
                left = node.value;
            }

            if (this.checkDataTop(data, node.value, top)) {
                top = node.value;
            }

            if (this.checkDataRight(data, node.value, right)) {
                right = node.value;
            }
            if (this.checkDataBottom(data, node.value, bottom)) {
                bottom = node.value;
            }
            if (this.checkDataCenterX(data, node.value, centerX)) {
                centerX = node.value;
            }
            if (this.checkDataCenterY(data, node.value, centerY)) {
                centerY = node.value;
            }
        }
        let doubleBubbleMindNodeUnitArr = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()
        let doubleBubbleMindNodeUnit = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys().length
        for (let index = 0; index < doubleBubbleMindNodeUnit; index++) {
            var node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(doubleBubbleMindNodeUnitArr[index]);
            if (!this.checkDataDistance(data, node.value)) {
                continue
            }
            if (this.checkDataLeft(data, node.value, left)) {
                left = node.value;
            }

            if (this.checkDataTop(data, node.value, top)) {
                top = node.value;
            }

            if (this.checkDataRight(data, node.value, right)) {
                right = node.value;
            }
            if (this.checkDataBottom(data, node.value, bottom)) {
                bottom = node.value;
            }
            if (this.checkDataCenterX(data, node.value, centerX)) {
                centerX = node.value;
            }
            if (this.checkDataCenterY(data, node.value, centerY)) {
                centerY = node.value;
            }
        }
        let generalizationMindElementDataDictArr = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()
        let generalizationMindElementDataDict = this.mainMindNodeUnit.generalizationMindElementDataDict.keys().length
        for (let index = 0; index < generalizationMindElementDataDict; index++) {
            var node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(generalizationMindElementDataDictArr[index]);
            if (!this.checkDataDistance(data, node.value)) {
                continue
            }
            if (this.checkDataLeft(data, node.value, left)) {
                left = node.value;
            }

            if (this.checkDataTop(data, node.value, top)) {
                top = node.value;
            }

            if (this.checkDataRight(data, node.value, right)) {
                right = node.value;
            }
            if (this.checkDataBottom(data, node.value, bottom)) {
                bottom = node.value;
            }
            if (this.checkDataCenterX(data, node.value, centerX)) {
                centerX = node.value;
            }
            if (this.checkDataCenterY(data, node.value, centerY)) {
                centerY = node.value;
            }
        }
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index]
            let mainMindElementDataDictKeys = unit.mainMindElementDataDict.keys()
            let mainMindElementDataDictLength = mainMindElementDataDictKeys.length
            for (let index = 0; index < mainMindElementDataDictLength; index++) {
                let node = unit.mainMindElementDataDict.get(mainMindElementDataDictKeys[index])
                if (!this.checkDataDistance(data, node.value)) {
                    continue
                }
                if (this.checkDataLeft(data, node.value, left)) {
                    left = node.value;
                }

                if (this.checkDataTop(data, node.value, top)) {
                    top = node.value;
                }

                if (this.checkDataRight(data, node.value, right)) {
                    right = node.value;
                }
                if (this.checkDataBottom(data, node.value, bottom)) {
                    bottom = node.value;
                }
                if (this.checkDataCenterX(data, node.value, centerX)) {
                    centerX = node.value;
                }
                if (this.checkDataCenterY(data, node.value, centerY)) {
                    centerY = node.value;
                }
            }
            let generalizationMindElementDataDictKeys = unit.generalizationMindElementDataDict.keys();
            let generalizationMindElementDataDictLength = generalizationMindElementDataDictKeys.length
            for (let index = 0; index < generalizationMindElementDataDictLength; index++) {
                let node = unit.generalizationMindElementDataDict.get(generalizationMindElementDataDictKeys[index])
                if (!this.checkDataDistance(data, node.value)) {
                    continue
                }
                if (this.checkDataLeft(data, node.value, left)) {
                    left = node.value;
                }

                if (this.checkDataTop(data, node.value, top)) {
                    top = node.value;
                }

                if (this.checkDataRight(data, node.value, right)) {
                    right = node.value;
                }
                if (this.checkDataBottom(data, node.value, bottom)) {
                    bottom = node.value;
                }
                if (this.checkDataCenterX(data, node.value, centerX)) {
                    centerX = node.value;
                }
                if (this.checkDataCenterY(data, node.value, centerY)) {
                    centerY = node.value;
                }
            }
        }
        if (left.isEmpty() && top.isEmpty() && right.isEmpty() &&
            bottom.isEmpty() && centerX.isEmpty() && centerY.isEmpty()) {
            return false;
        }

        var leftDistance = left.isEmpty() ? 100000 : Math.abs(left.x - data.x);
        var topDistance = top.isEmpty() ? 100000 : Math.abs(top.y - data.y);
        var rightDistance = right.isEmpty() ? 100000 : Math.abs(right.x + right.width - (data.x + data.width));
        var bottomDistance = bottom.isEmpty() ? 100000 : Math.abs(bottom.y + bottom.height - (data.x + data.height));
        var centerXDistance = centerX.isEmpty() ? 100000 : Math.abs(centerX.x + centerX.width / 2 - (data.x + data.width / 2));
        var centerYDistance = centerY.isEmpty() ? 100000 : Math.abs(centerY.y + centerY.height / 2 - (data.y + data.height / 2));

        var listXDistance = { leftDistance, rightDistance, centerXDistance };
        var listYDistance = { topDistance, bottomDistance, centerYDistance };

        var changeX = true;
        var changeY = true;
        if (this.mindtype == MindType.FLOW_CHART) {
            if (this.checkMin(centerXDistance, listXDistance)) {
                data.x = centerX.x + centerX.width / 2 - data.width / 2;
            } else if (this.checkMin(leftDistance, listXDistance)) {
                data.x = left.x;
            } else if (this.checkMin(rightDistance, listXDistance)) {
                data.x = right.x + right.width - data.width;
            } else {
                changeX = false;
            }
    
            if (this.checkMin(centerYDistance, listYDistance)) {
                data.y = centerY.y + centerY.height / 2 - data.height / 2;
            } else if (this.checkMin(topDistance, listYDistance)) {
                data.y = top.y;
            } else if (this.checkMin(bottomDistance, listYDistance)) {
                data.y = bottom.y + bottom.height - data.height;
            } else  {
                changeY = false;
            }
        } else {
            if (this.checkMin(leftDistance, listXDistance)) {
                data.x = left.x;
            } else if (this.checkMin(rightDistance, listXDistance)) {
                data.x = right.x + right.width - data.width;
            } else if (this.checkMin(centerXDistance, listXDistance)) {
                data.x = centerX.x + centerX.width / 2 - data.width / 2;
            } else {
                changeX = false;
            }
    
            if (this.checkMin(topDistance, listYDistance)) {
                data.y = top.y;
            } else if (this.checkMin(bottomDistance, listYDistance)) {
                data.y = bottom.y + bottom.height - data.height;
            } else if (this.checkMin(centerYDistance, listYDistance)) {
                data.y = centerY.y + centerY.height / 2 - data.height / 2;
            } else {
                changeY = false;
            }
        }        
        return changeX || changeY;
    }

    checkMin(target, list) {
        if (target > 100) {
            return false;
        }
        let listLength = list.length
        for (let index = 0; index < listLength; index++) {
            let item = list[index];
            if (target > item) {
                return false;
            }
        }
        return true;
    }

    checkDataDistance(data, target) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
            target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs(data.x - target.x) < this.MaxDistance && Math.abs(data.y - target.y) < this.MaxDistance;
    }

    checkDataLeft(data, target, reference) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
            target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs(data.x - target.x) < this.MaxDeviationValue && (reference.isEmpty() || Math.abs(data.x - target.x) < Math.abs(data.x - reference.x));
    }

    checkDataTop(data, target, reference) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
            target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs(data.y - target.y) < this.MaxDeviationValue && (reference.isEmpty() || Math.abs(data.y - target.y) < Math.abs(data.y - reference.y));
    }

    checkDataRight(data, target, reference) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
            target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs((data.x + data.width) - (target.x + target.width)) < this.MaxDeviationValue &&
            (reference.isEmpty() || Math.abs((data.x + data.width) - (target.x + target.width)) < Math.abs((data.x + data.width) - (reference.x + reference.width)));
    }

    checkDataBottom(data, target, reference) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
             target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs((data.y + data.height) - (target.y + target.height)) < this.MaxDeviationValue &&
            (reference.isEmpty() || Math.abs((data.y + data.height) - (target.y + target.height)) < Math.abs((data.y + data.height) - (reference.y + reference.height)));
    }

    checkDataCenterX(data, target, reference) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
            target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs(data.x + data.width / 2 - (target.x + target.width / 2)) < this.MaxDeviationValue &&
            (reference.isEmpty() || Math.abs((data.x + data.width / 2) - (target.x + target.width / 2)) < Math.abs((data.x + data.width / 2) - (reference.x + reference.width / 2)));
    }

    checkDataCenterY(data, target, reference) {
        if (data.isEmpty() || target.isEmpty() || data.isHidden || 
            target.isHidden || data.id == target.id) {
            return false;
        }
        return Math.abs((data.y + data.height / 2) - (target.y + target.height / 2)) < this.MaxDeviationValue &&
            (reference.isEmpty() || Math.abs((data.y + data.height / 2) - (target.y + target.height / 2)) < Math.abs((data.y + data.height / 2) - (reference.y + reference.height / 2)));
    }
}
export default AutoAdsorptionFind