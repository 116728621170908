import { apiRequest,getMachineId } from './base';


function clearCache(prefix) {
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i)
        if (key.startsWith(prefix)) {
            localStorage.removeItem(key)
        }
    }
}

let cacheDir = localStorage.getItem('cacheDir');

if (window.require) {
    const fs = window.require('fs');
    const path = window.require('path');
    const http =window.require('http')
    const { ipcRenderer } = window.require('electron');
    ipcRenderer.on('appDir', function (ev, appDir) {
        cacheDir = path.join(appDir, 'Mind+', 'localcache')
        if (!fs.existsSync(cacheDir)) {
            fs.mkdirSync(cacheDir,{recursive:true})
        }
        localStorage.setItem('cacheDir', cacheDir)

        //将machineId缓存到本地文件，方便CrashReporter上传崩溃日志的时候，附带上machineId
        const machineId = getMachineId()
        const machineIdPath = path.join(cacheDir, 'machineId')
        if (!fs.existsSync(machineIdPath)) {
            fs.writeFileSync(machineIdPath, machineId, { flag: 'wx' })
        }
    })
}

function setStorageObject(key, value) {
    if (!cacheDir) {
        return
    }
    try {
        const fs = window.require('fs');
        const path = window.require('path');
        const file = path.join(cacheDir, key)
        if (fs.existsSync(file)) {
            fs.rmSync(file)
        }
        fs.writeFileSync(file, JSON.stringify(value), { flag: 'wx' })
    } catch (e) {
    }
}
function getStorageObject(key) {
    if (!cacheDir) {
        return null
    }
    try {
        const fs = window.require('fs');
        const path = window.require('path');
        const file = path.join(cacheDir, key)
        const s = fs.readFileSync(file, { encoding: 'utf-8' }).toString()
        return JSON.parse(s)
    } catch (e) { }
    return null
}


/**
 * base基础服务
 */

//全局配置-启动时请求一次
let incrementPostBaseGlobalConfig = 0
export function postBaseGlobalConfig(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postBaseGlobalConfig-'
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    if (incrementPostBaseGlobalConfig > 0) {
        return
    }
    incrementPostBaseGlobalConfig++
    apiRequest('POST', '/base/global-config', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        onOk(res)
    }, onFail, eventually, headers, onProgress)
}

//存储空间使用情况-启动时请求一下
let incrementPostUserStorageInfo = 0
export function postUserStorageInfo(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postUserStorageInfo-'
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    if (incrementPostUserStorageInfo > 0) {
        return
    }
    incrementPostUserStorageInfo++
    apiRequest('POST', '/user/storage-info', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        onOk(res)
    }, onFail, eventually, headers, onProgress)
}

/**
 * mindmap导图服务 ======================================================================
 */

//拉取首页文件列表-id制
let incrementPostMindmapListDir = 0
export function postMindmapListDir(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindMapListDir' + req.parentId;
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    let myId = ++incrementPostMindmapListDir
    apiRequest('POST', '/mindmap/list-dir', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        if (incrementPostMindmapListDir === myId) onOk(res)
    }, onFail, eventually, headers, onProgress)
}

//列出最近编辑的导图列表-id制
let incrementPostMindmapListEntities = 0
export function postMindmapListEntities(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindmapListEntities-' + req.parentId + '-' + req.page
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    let myId = ++incrementPostMindmapListEntities
    apiRequest('POST', '/mindmap/list-entities', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        if (incrementPostMindmapListEntities === myId) onOk(res)
        else console.log(incrementPostMindmapListEntities, ' != ', myId);
    }, onFail, eventually, headers, onProgress)
}

// 获取导图内容-id制
let incrementPostMindmapGetMindmap = 0
export function postMindmapGetMindmap(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindmapGetMindmap-'
    // clearCache(key)
    if (req.id) key = key + req.id
    if (req.sheetId) key = key + '-' + req.sheetId
    // let obj = getStorageObject(key)
    // if (obj) {
    //     onOk(obj)
    // }
    let myId = ++incrementPostMindmapGetMindmap
    apiRequest('POST', '/mindmap/get-mindmap', req, res => {
        // if (obj && res.modifyTime + 60 < obj.modifyTime) {
        //     return
        // }
        // setStorageObject(key, res)
        if (incrementPostMindmapGetMindmap === myId) {
            onOk(res)
        }
    }, onFail, eventually, headers, onProgress)
}

//上传导图
export function postMindmapUploadMindmap(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindmapGetMindmap-' + req.mindMapId
    if (req.sheetId) key = key + '-' + req.sheetId
    let obj = getStorageObject(key)
    if (obj) {
        obj.content = req.content
        obj.modifyTime = Math.round(new Date().getTime() / 1000)
        setStorageObject(key, obj)
    }
    apiRequest('POST', '/mindmap/upload-mindmap', req, onOk, onFail, eventually, headers, onProgress)
}

//拉取分页列表-id制
let incrementPostMindmapListSheets = 0
export function postMindmapListSheets(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindmapListSheets-'
    clearCache(key)
    if (req.rootId) key = key + req.rootId
    if (req.lastIndex) key = key + '-' + req.lastIndex.toString()
    let cache = getStorageObject(key)
    if (cache) {
        onOk(cache)
    }
    let myId = ++incrementPostMindmapListSheets
    apiRequest('POST', '/mindmap/list-sheets', req, res => {
        setStorageObject(key, res)
        if (incrementPostMindmapListSheets === myId)
            onOk(res)
    }, onFail, eventually, headers, onProgress)
}

//按类型查询风格-id制
let incrementPostMaterialQueryStyleByType = 0
export function postMaterialQueryStyleByType(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMaterialQueryStyleByType-' + req.typeId + '-' + req.page
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    let myId = ++incrementPostMaterialQueryStyleByType
    apiRequest('POST', '/material/query-style-by-type', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        if (incrementPostMaterialQueryStyleByType === myId) onOk(res)
    }, onFail, eventually, headers, onProgress)
}

//我的LaTex模版-id制
let incrementPostMindmapMyLatex = 0
export function postMindmapMyLatex(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindmapMyLatex-'
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    let myId = ++incrementPostMindmapMyLatex
    apiRequest('POST', '/mindmap/my-latex', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        if (incrementPostMindmapMyLatex === myId) onOk(res)
    }, onFail, eventually, headers, onProgress)
}

//归档首页数据- ID制
let incrementPostMindmapQueryArchives = 0
export function postMindmapQueryArchives(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postMindmapQueryArchives-' + req.parentId + '-' + req.lastIndex
    if (req.dirOnly) {
        key = key + '-true'
    }
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    let myId = ++incrementPostMindmapQueryArchives
    apiRequest('POST', '/mindmap/query-archives', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        if (incrementPostMindmapQueryArchives === myId) onOk(res)
    }, onFail, eventually, headers, onProgress)
}

/**
 * group共享组服务
 */

// 共享组首页数据
let incrementPostGroupMyGroups = 0
export function postGroupMyGroups(req, onOk, onFail, eventually, headers, onProgress) {
    let key = 'postGroupMyGroups-' + req.page
    let cache = localStorage.getItem(key)
    if (cache) {
        try {
            onOk(JSON.parse(cache))
        } catch (e) { }
    }
    let myId = ++incrementPostGroupMyGroups
    apiRequest('POST', '/group/my-groups', req, res => {
        try {
            localStorage.setItem(key, JSON.stringify(res))
        } catch (e) { console.log(e); }
        if (incrementPostGroupMyGroups === myId) onOk(res)
    }, onFail, eventually, headers, onProgress)
}

