
import Colors from "../../../utils/Colors"
import LinePath from "../../../viewmodel/core/base/LinePath"
import CGPoint from '../../../viewmodel/core/base/basedata/CGPoint'
import LineOrientation from '../../../viewmodel/datatype/LineOrientation'
import BaseElementView from "./BaseElementView";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class LeftBraceLineView extends BaseElementView {
    constructor(viewCanvs, data) {
        super(viewCanvs, data);
        this.pathHeader;  //用来画线条的元素
        this.pathFooter;
        this.radius = 4;
        this.isAnimation = false;
        this.duration = 1;
        this.onDraw();
    }

    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.duration = duration;
        this.onDraw();
        this.isAnimation = false;
    }

    refresh() {
        if (!this.isRefresh) {
            return;
        }
        super.refresh();
        this.onDraw();
    }

    onDraw() {
        this.radius = 4;
        this.drawLine();
    }

    drawLine() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        let leftBraceLineMindElementContent = this.mindElementData.lineContent;
        if (leftBraceLineMindElementContent.orientation == LineOrientation.RIGHT ||
            leftBraceLineMindElementContent.orientation == LineOrientation.LEFT) {
            this.radius = this.mindElementData.width / 2;
        } else if (leftBraceLineMindElementContent.orientation == LineOrientation.BOTTOM ||
            leftBraceLineMindElementContent.orientation == LineOrientation.TOP) {
            this.radius = this.mindElementData.height / 2;
        }
        if (this.pathHeader == null) {
            this.pathHeader = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.pathHeader)
        }
        if (this.pathFooter == null) {
            this.pathFooter = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.pathFooter)
        }

        if (leftBraceLineMindElementContent.orientation == LineOrientation.RIGHT) {
            let conterY = leftBraceLineMindElementContent.startPointY / 2 + leftBraceLineMindElementContent.endPointY / 2;
            let conterPoint = new CGPoint((leftBraceLineMindElementContent.startPointX), conterY);
            this.drawHeader(leftBraceLineMindElementContent.startPoint(), conterPoint);
            this.drawHFooter(conterPoint, leftBraceLineMindElementContent.endPoint());
        } else if (leftBraceLineMindElementContent.orientation == LineOrientation.LEFT) {
            let conterY = leftBraceLineMindElementContent.startPointY / 2 + leftBraceLineMindElementContent.endPointY / 2;
            let conterPoint = new CGPoint((leftBraceLineMindElementContent.startPointX), conterY);
            this.drawHeader(leftBraceLineMindElementContent.startPoint(), conterPoint);
            this.drawHFooter(conterPoint, leftBraceLineMindElementContent.endPoint());
        } else if (leftBraceLineMindElementContent.orientation == LineOrientation.BOTTOM) {
            let conterX = leftBraceLineMindElementContent.startPointX / 2 + leftBraceLineMindElementContent.endPointX / 2;
            let conterPoint = new CGPoint((conterX), leftBraceLineMindElementContent.startPointY);
            this.drawHeader(leftBraceLineMindElementContent.startPoint(), conterPoint);
            this.drawHFooter(conterPoint, leftBraceLineMindElementContent.endPoint());
        } else if (leftBraceLineMindElementContent.orientation == LineOrientation.TOP) {
            let conterX = leftBraceLineMindElementContent.startPointX / 2 + leftBraceLineMindElementContent.endPointX / 2;
            let conterPoint = new CGPoint((conterX), leftBraceLineMindElementContent.endPointY);
            this.drawHeader(leftBraceLineMindElementContent.startPoint(), conterPoint);
            this.drawHFooter(conterPoint, leftBraceLineMindElementContent.endPoint());
        }
    }

    drawHeader(startPoint, endPoint) { //括号分成两半，这是画括号的上半部分。
        let lineMindElementContent = this.mindElementData.lineContent;

        var vSpace = (endPoint.y - startPoint.y) * 0.2;
        if (vSpace > this.radius * 1.0) {
            vSpace = this.radius * 1.0
        }
        
        let hSpace = this.radius * 0.3 + lineMindElementContent.lineWidth

        let linePath = new LinePath();
        linePath.setEffectRadius(this.radius)

        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            
            linePath.move(startPoint)
            linePath.cubicTo(endPoint.x + this.radius * 2  + hSpace, startPoint.y + vSpace,
                            startPoint.x, endPoint.y - vSpace,
                            endPoint.x + this.radius * 2, endPoint.y);
            
            linePath.cubicTo(startPoint.x - hSpace, endPoint.y - vSpace,
                            endPoint.x + this.radius * 2, startPoint.y + vSpace,
                            startPoint.x, startPoint.y);

            // linePath.moveTo(startPoint.x, startPoint.y);
            // linePath.lineTo(startPoint.x + this.radius, startPoint.y);
            // linePath.lineTo(startPoint.x + this.radius, endPoint.y);
            // linePath.lineTo(endPoint.x + this.radius * 2, endPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            linePath.moveTo(startPoint.x + this.radius * 2, startPoint.y)
            linePath.cubicToByIos(endPoint.x, endPoint.y,
                                endPoint.x - hSpace, startPoint.y + vSpace,
                                startPoint.x + this.radius * 2, endPoint.y - vSpace)
            linePath.cubicToByIos(startPoint.x + this.radius * 2, startPoint.y,
                                startPoint.x + this.radius * 2 + hSpace, endPoint.y - vSpace,
                                endPoint.x, startPoint.y + vSpace)
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
            linePath.moveTo(startPoint.x, startPoint.y)
            linePath.cubicToByIos(endPoint.x, endPoint.y + this.radius * 2,
                                startPoint.x + vSpace, startPoint.y + this.radius * 2 + hSpace,
                                endPoint.x - vSpace, endPoint.y)
            linePath.cubicToByIos(startPoint.x, startPoint.y,
                                endPoint.x - vSpace, endPoint.y - hSpace,
                                startPoint.x + vSpace, startPoint.y + this.radius * 2)
        } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
            linePath.moveTo(startPoint.x, startPoint.y)
            
            linePath.cubicToByIos(endPoint.x, endPoint.y - this.radius * 2,
                                startPoint.x + vSpace, startPoint.y - this.radius * 2 - hSpace,
                                endPoint.x - vSpace, endPoint.y)
            linePath.cubicToByIos(startPoint.x, startPoint.y,
                                endPoint.x - vSpace, endPoint.y + hSpace,
                                startPoint.x + vSpace, startPoint.y - this.radius * 2)
        }
        this.renderCanvas(linePath);
    }

    renderCanvas(path) {
        let lineMindElementContent = this.mindElementData.lineContent
        this.pathHeader.setAttribute("d", path.getLine())
        this.pathHeader.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.color))
        this.pathHeader.setAttribute("stroke-width", 0.4)//lineMindElementContent.lineWidth)
        this.pathFooter.setAttribute("stroke-linecap", "round")
        if (this.isAnimation) {
            this.pathHeader.style.strokeDashoffset = this.pathHeader.getTotalLength()
            this.pathHeader.style.strokeDasharray = this.pathHeader.getTotalLength()
            this.pathHeader.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
        } else {
            this.pathHeader.style.strokeDashoffset = 0
            this.pathHeader.style.strokeDasharray = 0
            this.pathHeader.style.animation = ''
        }
        //判断是否填充颜色
        if (lineMindElementContent.lineThicken) {
            this.pathHeader.setAttribute("fill", Colors.getUiColor(lineMindElementContent.color))
        } else {
            this.pathHeader.setAttribute("fill", Colors.getUiColor(lineMindElementContent.color))
        }

    }

    renderFooterCanvas(path) {
        let lineMindElementContent = this.mindElementData.lineContent
        this.pathFooter.setAttribute("d", path.getLine())
        this.pathFooter.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.color))
        this.pathFooter.setAttribute("stroke-width", 0.4)//lineMindElementContent.lineWidth)
        this.pathFooter.setAttribute("stroke-linecap", "round")
        if (this.isAnimation) {
            this.pathFooter.style.strokeDashoffset = this.pathFooter.getTotalLength()
            this.pathFooter.style.strokeDasharray = this.pathFooter.getTotalLength()
            this.pathFooter.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
        } else {
            this.pathFooter.style.strokeDashoffset = 0
            this.pathFooter.style.strokeDasharray = 0
            this.pathFooter.style.animation = ''
        }
        //判断是否填充颜色
        if (lineMindElementContent.lineThicken) {
            this.pathFooter.setAttribute("fill", Colors.getUiColor(lineMindElementContent.color))
        } else {
            this.pathFooter.setAttribute("fill", Colors.getUiColor(lineMindElementContent.color))
        }
    }
    drawHFooter(startPoint, endPoint) { //括号分成两半，这是画括号的下半部分。
        let lineMindElementContent = this.mindElementData.lineContent;
        var vSpace = (endPoint.y - startPoint.y) * 0.2;
        if (vSpace > this.radius * 1.0) {
            vSpace = this.radius * 1.0
        }
        
        let hSpace = this.radius * 0.3 + lineMindElementContent.lineWidth

        let linePath = new LinePath();
        linePath.setEffectRadius(this.radius)
        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            linePath.moveTo(startPoint.x + 2 * this.radius, startPoint.y)

            linePath.cubicTo(startPoint.x - hSpace, startPoint.y + vSpace,
                            endPoint.x + this.radius * 2, endPoint.y - vSpace,
                            endPoint.x, endPoint.y);
            
            linePath.cubicTo(endPoint.x + this.radius * 2  + hSpace, endPoint.y - vSpace,
                            startPoint.x, startPoint.y + vSpace,
                            startPoint.x + 2 * this.radius, startPoint.y);

            // linePath.moveTo(startPoint.x + this.radius * 2, startPoint.y);
            // linePath.lineTo(startPoint.x + this.radius, startPoint.y);
            // linePath.lineTo(startPoint.x + this.radius, endPoint.y);
            // linePath.lineTo(endPoint.x, endPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            linePath.move(startPoint)
            
            linePath.cubicToByIos( endPoint.x + this.radius * 2, endPoint.y,
                              startPoint.x + this.radius * 2 + hSpace, startPoint.y + vSpace,
                              endPoint.x, endPoint.y - vSpace)
            
            linePath.cubicToByIos( startPoint.x, startPoint.y,
                              endPoint.x - hSpace, endPoint.y - vSpace,
                              startPoint.x + this.radius * 2, startPoint.y + vSpace)

            // linePath.moveTo(startPoint.x, startPoint.y);
            // linePath.lineTo(startPoint.x + this.radius, startPoint.y);
            // linePath.lineTo(startPoint.x + this.radius, endPoint.y);
            // linePath.lineTo(endPoint.x + this.radius * 2, endPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {

            linePath.moveTo(startPoint.x, startPoint.y + this.radius * 2)
            
            linePath.cubicToByIos(endPoint.x, endPoint.y,
                                startPoint.x + vSpace, startPoint.y - hSpace,
                                endPoint.x - vSpace, endPoint.y + this.radius * 2)
            linePath.cubicToByIos(startPoint.x, startPoint.y + this.radius * 2,
                                endPoint.x - vSpace, endPoint.y + this.radius * 2 + hSpace,
                                startPoint.x + vSpace, startPoint.y)

            // linePath.moveTo(startPoint.x, startPoint.y + this.radius * 2);
            // linePath.lineTo(startPoint.x, startPoint.y + this.radius);
            // linePath.lineTo(endPoint.x, startPoint.y + this.radius);
            // linePath.lineTo(endPoint.x, endPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
            linePath.moveTo(startPoint.x, startPoint.y - this.radius * 2)
            
            linePath.cubicToByIos(endPoint.x, endPoint.y,
                              startPoint.x + vSpace, startPoint.y + hSpace,
                               endPoint.x - vSpace, endPoint.y - this.radius * 2)
            linePath.cubicToByIos(startPoint.x, startPoint.y - this.radius * 2,
                              endPoint.x - vSpace, endPoint.y - this.radius * 2 - hSpace,
                               startPoint.x + vSpace, startPoint.y)
        }
        this.renderFooterCanvas(linePath);
    }

    createBackgroundView() {
    }
}
export default LeftBraceLineView
