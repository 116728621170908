
import Config from "../../../core/core/calcule/Config"
import HashMap from "../base/HashMap";
import LineLayout from "../../datatype/LineLayout";
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import IdGenerator from "../base/IdGenerator"
import MindElementData from "../../mindelementdata/MindElementData"
import MindElementType from "../../datatype/MindElementType"
import MindDataLayoutDelegate from "../../defer/MindDataLayoutDelegate"
import MindMapStyleColor from "../base/MindMapStyleColor";
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import BoldData from "../../mindelementdata/mindcontent/BoldData";
import SettingData from "../minddata/SettingData";
import NodeLayoutType from "../../datatype/NodeLayoutType"
import BaseLayout from "./BaseLayout";
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation";
import Point from "../base/Point";
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent";
import EncircleShapeType from "../../datatype/EncircleShapeType";
import RectsMergeCalculation from "./RectsMergeCalculation";
import Size from "../base/Size";
import Colors from "../../../utils/Colors";
import CGPoint from "../base/basedata/CGPoint";
import LineOrientation from "../../datatype/LineOrientation";
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType";
/**
 * MindDataLayoutDelegate
 * Created by tony on 2019/12/26
 */
 class CircleLayout extends BaseLayout {
    constructor() {
        super();
        this.space = new UiUtil().dip2px(10);
        this.textMaxWidth = new UiUtil().dip2px(80);
        this.rootTextMaxWidth = new UiUtil().dip2px(180);
        this.textMaxHLength = 15;
        this.rootTextMaxHLength = 30;
        this.subjectRadius = new UiUtil().dip2px(40);
        this.sonSubjectRadius = new UiUtil().dip2px(20);
        this.nodeSizeMap = new HashMap();
        this.nodeAngle = new HashMap();
    }

    initConfig() {
        super.initConfig();
        this.subjectRadius = this.addSubjectSpcaeHorizontal(this.UiUtil.dip2px(40));
        this.sonSubjectRadius = this.addSonSubjectSpcaeHorizontal(this.UiUtil.dip2px(20));
    }

    onLayout( isChange) {
        this.nodeSizeMap.clear();
        this.nodeAngle.clear();
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }

    setEncirclePoint() {
        let encircleMindElementDataDictKeys = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictKeysContent = encircleMindElementDataDictKeys.length;
        for(var index = 0; index < encircleMindElementDataDictKeysContent; index++){
            let key = encircleMindElementDataDictKeys[index];
            let mind = this.encircleMindElementDataDict.get(key);
            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;

            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                mind.lineContent.textContent.y = 0;
            }
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;
                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                let points = new EncircleNodesPointsCalculation().calcule(node, type, padding);
                let encircleNodesPoint = new Array();
                let pointsCount = points.length 
                for(var pIndex = 0; pIndex < pointsCount; pIndex++){
                    let point = points[pIndex];
                    encircleNodesPoint.push(new Point((point.x) - mind.x, (point.y) - mind.y));
                }
                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                        EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                let targetIdsCount = targetIds.length
                for(var idIndex = 0; idIndex < targetIdsCount; idIndex++){
                // for (int id : targetIds) {
                    let id = targetIds[idIndex];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    setElementsPoint( isChange) {
        if ((!isChange || this.rootTreeNode.value.y <= -1 || this.rootTreeNode.value.x <= -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (this.UiUtil.getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = this.UiUtil.dip2px(10) + left;
        }
        this.rootTreeNode.value.layout = NodeLayoutType.LAYOUT_CIRCLE;
        MindElementCalculation.set(this.rootTreeNode.value).caluleTextForLimitLength(this.rootTextMaxWidth, this.rootTextMaxHLength).calcule();
        this.initConfig();
        this.setNodeTextSize(this.rootTreeNode);
        let rootSize = this.getNodeSize(this.rootTreeNode);
        let nodeCenterPoint = new Point(0, 0);
        if (this.rootTreeNode.children.length == 1) {
            let childSize = this.getNodeSize(this.rootTreeNode.children[0]);
            nodeCenterPoint = new Point(this.rootTreeNode.value.x + this.rootTreeNode.value.width / 2 + (this.space >> 1) + childSize.getWidth()/2, this.rootTreeNode.value.y + this.rootTreeNode.value.height/2);
        } else {
            nodeCenterPoint = new Point(this.rootTreeNode.value.x + this.rootTreeNode.value.width/2, this.rootTreeNode.value.y + this.rootTreeNode.value.height/2);
        }
        this.setElementLine(this.rootTreeNode, nodeCenterPoint, isChange);
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
    }

    setGeneralizationElementsPoint( isChange) {
    }

    setGeneralizationElementLine( node, isChange) {
    }

    setNodeTextSize( node) {
        let maxWidth = this.textMaxWidth;
        let textLimitLength = this.textMaxHLength
        if (this.isRootNode(node)) {
            maxWidth = this.rootTextMaxWidth;
            textLimitLength = this.rootTextMaxHLength
        }
        maxWidth = node.value.customWidth > 10 && node.value.customWidth < maxWidth ? node.value.customWidth : maxWidth;
        MindElementCalculation.set(node.value).caluleTextForLimitLength(maxWidth, textLimitLength).calcule();
        
        if (node.children.length == 0) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.setNodeTextSize(node.children[index]);
        }
    }

    getNodeSize( node) {
        if (node.isEmpty() || node.value.isHidden || node.value.isLayoutHidden) {
            return new Size(0, 0);
        }
        if (this.nodeSizeMap.containsKey(node.value.id)) {
            return this.nodeSizeMap.get(node.value.id);
        }
        let width = Math.max(node.value.width, node.value.height);
        let height = width;
        if (node.children.isEmpty()) {
            return new Size(width, height);
        }
        let childMaxWidth = 0;
        let childSizeList = new Array();
        for (var index = 0; index < node.children.length; index++) {
            let cellSize = this.getNodeSize(node.children[index]);
            if (cellSize.getHeight() > 0) {
                childMaxWidth = Math.max(childMaxWidth, cellSize.getWidth());
                childSizeList.push(cellSize);
            }
        }
        if (childSizeList.isEmpty()) {
            return new Size(width, height);
        }
        let count = childSizeList.length;
        let cellAngle = 360.0/count;
        if (cellAngle < 90 && cellAngle > 2) {
            let hypotenuse = (childMaxWidth)/2.0/this.Util.sind(cellAngle);
            if (hypotenuse - (width + this.space)/2 > childMaxWidth/2) {
                width = (hypotenuse + childMaxWidth/2 + this.space * (count == 1 ? 3 : 2)) * (count > 1 ? 2 : 1);
                height = width;
            } else {
                width += (childMaxWidth + this.space * (count == 1 ? 3 : 2)) * (count > 1 ? 2 : 1);
                height = width;
            }
        } else {
            width += (childMaxWidth + this.space * (count == 1 ? 3 : 2)) * (count > 1 ? 2 : 1);
            height = width;
        }
        let size = new Size(width, height);
        this.nodeSizeMap.put(node.value.id, size);
        return size;
    }

    setNodeElementsPoint( node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }

    setDataPoint( data, isChange) {
        if (data.isHidden || data.id == this.rootTreeNode.value.id) {
            return;
        }
        if (Colors.isClear(data.backgroundColor)) {
            data.backgroundColor = this.mindBGColor;
        }
        let node = this.getNodeById(data.id);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeSize = this.getNodeSize(parentNode);
        let nodeSize = this.getNodeSize(node);
        let parentDataSize = new Size(parentNode.value.width, parentNode.value.height);
        let dataSize = new Size(data.width, data.height);
        this.pushCurrentLayoutNode(node);
        data.layout = NodeLayoutType.LAYOUT_CIRCLE;
        let parentCenterPoint = new CGPoint((parentNode.value.x) + (parentNode.value.width / 2),
                (parentNode.value.y) + (parentNode.value.height / 2));

        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);

        let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
        let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
        let parentNodePosition = 0;
        for (var index = 0; index < grandpaNode.children.length; index++) {
            if (grandpaNode.children[index].value.id == parentNode.value.id) {
                parentNodePosition = index;
                break;
            }
        }
        let startAngle = 0;
        if (this.nodeAngle.containsKey(parentNode.value.id)) {
            startAngle = this.nodeAngle.get(parentNode.value.id);
        } else if (parentNodePosition > 0) {
            startAngle = 360.0/grandpaNode.children.length * parentNodePosition;
        }
        let dataCenterPoint;
        let nodeCenterPoint;
        let angle = 0;
        if (node.children.length == 1 && !node.children[0].value.isHidden && !node.children[0].value.isLayoutHidden) {
            let dataAngle = 360.0/ parentNode.children.length;
            let dataRadius = (parentDataSize.getWidth() >> 1) + (dataSize.getWidth() >> 1) + this.space;
            let nodeRadius = (parentDataSize.getWidth() >> 1) + (nodeSize.getWidth() >> 1) + this.space/2;

            if (parentNode.children.length < 3) {
                if (grandpaNode.isEmpty()) {
                    angle = dataAngle * allSiblingsNodesPosition + startAngle;
                    dataCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, dataRadius);
                    nodeCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, nodeRadius);
                } else {
                    angle = dataAngle * allSiblingsNodesPosition + startAngle;
                    dataCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, dataRadius);
                    nodeCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, nodeRadius);
                }
            } else {
                dataAngle = 360.0/ parentNode.children.length;
                angle = dataAngle * allSiblingsNodesPosition + startAngle;

                dataRadius = (parentDataSize.getWidth() >> 1) + (((parentNodeSize.getWidth() >> 1) - (parentDataSize.getWidth() >> 1) - this.space) >> 1) + this.space;
                dataRadius = dataRadius - (nodeSize.getWidth() >> 1) + (dataSize.getWidth() >> 1) + this.space;
                nodeRadius = (parentDataSize.getWidth() >> 1) + (((parentNodeSize.getWidth() >> 1) - (parentDataSize.getWidth() >> 1) - this.space) >> 1) + this.space;
                if (parentNode.children.length == 1) {
//                    dataRadius = (parentDataSize.getWidth() >> 1) + (((parentNodeSize.getWidth() >> 1) - (parentDataSize.getWidth() >> 1) - space) >> 1) + space * 2;
                }

                dataCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, dataRadius);
                nodeCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, nodeRadius);
            }
        } else if (parentNode.children.length == 1 &&
                node.children.length > 1 && !node.children[0].value.isHidden && !node.children[0].value.isLayoutHidden) {
                    let dataAngle = 360.0/ parentNode.children.length;
                    let dataRadius = (parentDataSize.getWidth() >> 1) + (nodeSize.getWidth() >> 1) + this.space;
                    let nodeRadius = (parentDataSize.getWidth() >> 1) + (nodeSize.getWidth() >> 1) + this.space;
            if (grandpaNode.isEmpty()) {
                angle = dataAngle * allSiblingsNodesPosition + startAngle;
                dataCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, dataRadius);
                nodeCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, nodeRadius);
            } else {
                angle = dataAngle * allSiblingsNodesPosition + startAngle;
                dataCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, dataRadius);
                nodeCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, nodeRadius);
            }
        } else {
            let dataAngle = 360.0/ parentNode.children.length;
            angle = dataAngle * allSiblingsNodesPosition + startAngle;
            let dataRadius = (parentDataSize.getWidth() >> 1) + (((parentNodeSize.getWidth() >> 1) - (parentDataSize.getWidth() >> 1) - this.space) >> 1) + this.space;
            let nodeRadius = (parentDataSize.getWidth() >> 1) + (((parentNodeSize.getWidth() >> 1) - (parentDataSize.getWidth() >> 1) - this.space) >> 1) + this.space;
            if (parentNode.children.length == 1) {
                dataRadius = (parentDataSize.getWidth() >> 1) + (((parentNodeSize.getWidth() >> 1) - (parentDataSize.getWidth() >> 1) - this.space) >> 1) + this.space * 2;
            }
            dataCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, dataRadius);
            nodeCenterPoint = this.Util.getCirclePoint(parentCenterPoint, angle, nodeRadius);
        }
        this.nodeAngle.put(data.id, angle);
        data.x = dataCenterPoint.x - dataSize.getWidth()/2;
        data.y = dataCenterPoint.y - dataSize.getHeight()/2;
        this.setElementLine(node, nodeCenterPoint, isChange);
    }

    setElementLine( node, nodeCenterPoint, isChange) {
        if (node.value.isHidden) {
            return;
        }
        let data = node.value;
        let dataSize = new Size(data.width, data.height);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (this.isGeneralizatioData(data)) {
            return;
        }
        let nodeSize = this.getNodeSize(node);

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        let cornerRadius = nodeSize.getWidth()/2;
//        cornerRadius = dataSize.getWidth() /2 + (nodeSize.getWidth()/2 - dataSize.getWidth()/2) / 2;
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_CIRCLE;
        if (data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            lineData.y = nodeCenterPoint.y - cornerRadius;
            lineData.x = nodeCenterPoint.x - cornerRadius;
            lineData.height = cornerRadius * 2;
            lineData.width = cornerRadius * 2;
            let startPoint = new Point((lineData.width), (lineData.height));
            let endPoint = new Point(0, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0xff333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }

            lineData.lineContent.orientation = LineOrientation.LEFT;
            lineData.lineContent.lineLayout = lineLayout;
            lineData.lineContent.cornerRadius = cornerRadius;
        } else if (data.type == MindElementType.SON_SUBJECT) {
            lineData.type = MindElementType.SON_LINE;

            lineData.y = nodeCenterPoint.y - cornerRadius;
            lineData.x = nodeCenterPoint.x - cornerRadius;
            lineData.height = cornerRadius * 2;
            lineData.width = cornerRadius * 2;

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (lineLayout == LineLayout.STRAIGHT_LINE ||
                    lineLayout == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(lineData.width, 0);
                curveEndPoint = new Point(lineData.width/4,  lineData.height);
                straightEndPoint = new Point(0, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE ||
                    lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                curveStartPoint = new Point(lineData.width, 0);
                curveEndPoint = new Point(lineData.width/6,  lineData.height);
                straightEndPoint = new Point(0, lineData.height);

            } else if (lineLayout == LineLayout.CURVE_LINE_2 ||
                    lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                curveStartPoint = new Point(lineData.width, 0);
                curveEndPoint = new Point(0,  lineData.height);
                straightEndPoint = new Point(0, lineData.height);

            } else {
                curveStartPoint = new Point(lineData.width, 0);
                curveEndPoint = new Point(lineData.width/2,  lineData.height);
                straightEndPoint = new Point(0, lineData.height);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
            lineData.lineContent.orientation = LineOrientation.LEFT;
            lineData.lineContent.lineLayout = lineLayout;
            lineData.lineContent.cornerRadius = cornerRadius;
        }
        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineNodeContent.color = referenceLine.lineContent.color;
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (referenceLine.lineContent.lineWidth == 0 &&
                        !grandpaNode.isEmpty() &&
                        grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                } else {
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                }
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                    parentNode = this.originalRootTreeNode;
                }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
                // for (var index = 0; index < parentNode.children.length; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % this.settingData.lineColor[index];
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
            }
            lineData.backgroundColor = Colors.clear;
            lineData.borderColor = Colors.clear;
            lineData.borderWidth = 0;
        }
        this.setLineThicken(lineData);
    }

    changeLayoutFromCircle( node) {
        if (!node.isEmpty()) {
            if (node.value.mindElementShape != node.value.mindElementOriginalShape) {
                node.value.mindElementShape = node.value.mindElementOriginalShape;
            }
            MindElementCalculation.set(node.value).caluleTextForData().calcule();
        }
        let nodeCount = node.children.length;
        for(var index = 0; index < nodeCount; index++){
            let item = node.children[index];
            this.changeLayoutFromCircle(item);
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_CIRCLE;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default CircleLayout