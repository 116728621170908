<template>
  <div class="com-lf-menu-share">
    <div class="com-lf-menu-content">
      <div class="com-lf-right-hide" @click="hideModels">
        <img
          src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/click_hide.png"
          alt=""
        />
      </div>
      <div class="com-mind-title">
      </div>
      <div class="com-development">
        <img src="../../../assets/img/mapIcon/res/mipmap-icon/development.svg" alt="">
        <div style="margin-top:40px;font-size:16px;text-align: center;">功能升级中，敬请期待 . . .</div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      logoSrc: require("../../../assets/img/code/logo.png"),
      url: require("../../../assets/img/code/qrcode_for_gh_a6c2f551a025_860.jpg"),
    };
  },
  components: {
    vueQr,
  },
  methods:{
    ...mapMutations(["showDaGangModel"]),
    hideModels(){
      this.showDaGangModel(false)
    }
  }
};
</script>

<style lang="less" scoped>
.com-lf-menu-share {
  width: 524px;
  min-height: 476px;
  position: absolute;
  left: 50%;
  height: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 9999;
  border-radius: 10px;
  .com-lf-menu-content {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .com-development{
      margin-top: 40px;
    }
    .com-lf-right-hide {
      position: absolute;
      right: 0px;
      top: -6px;
      cursor: pointer;
    }
    .com-mind-title {
      .com-mind-text {
        display: flex;
        justify-content: center;
        font-size: 18px;
        height: 25px;
        font-weight: 600;
        line-height: 14px;
        color: #333333;
        opacity: 1;
      }
    }
  }
}
</style>