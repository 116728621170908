<template>
  <div id="outline-box">
    <!-- 大纲 -->
    <svg id="outlineBox">
      <foreignObject y="18">
        <!-- icon弹框 -->
        <MenuPerSon
          v-if="showIconMenu"
          :showIconType="showIconType"
          :showIconX="showIconX"
          :showIconY="showIconY"
          :tintColor="tintColor"
          @continuousShow="continuousShow"
          @hideIconMenu="hideIconMenu"
          @checkMenuIconElement="removeIconMenu"
        ></MenuPerSon>

        <div id="outline-content"></div>
      </foreignObject>
    </svg>
    <svg id="outlineChop"></svg>
  </div>
</template>

<script>
import MindElementType from "../../viewmodel/datatype/MindElementType";
import MainOutlineInputView from "../../views/editmap/elementviews/MainOutlineInputView";
import OutlineInputView from "../../views/editmap/elementviews/OutlineInputView";
import OutlineGeneralizationInputView from "../../views/editmap/elementviews/OutlineGeneralizationInputView";
import HashMap from "../../viewmodel/core/base/HashMap";
import { mapMutations } from "vuex";
import outlineStatisticsElementView from "../../views/editmap/mindwidget/outlineStatisticsElementView";
import MenuPerSon from "./iconMenu/MenuPerSon";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import VueDataEvent from "../../core/core/basemode/VueDataEvent";

export default {
  data() {
    return {
      viewElementList: new HashMap(),
      mindTextSizeChangedDelegate: null,
      router: this.$router.history.current.name,
      showIconMenu: false, //显示icon弹框
      showIconType: 0, //icon弹框类型
      iconHideTime: null,
      showIconX: 0,
      showIconY: 0,
      iconNode: {}, //icon对象
      tintColor: "", //icon颜色
    };
  },
  components: {
    MenuPerSon,
  },
  props: {
     mindMapingType: {
      type: VueDataEvent,
      defualt() {
        return new VueDataEvent(false);
      },
    },
  },
  created() {
    this.bus.$on("setMindMapDelegae", (mindMap) => {
      this.mindTextSizeChangedDelegate = mindMap;
    });

    this.bus.$on("addMindContentView", (data, index) => {
      this.addMindContentView(data, index);
    });

    this.bus.$on("changeMindContentView", (data, index) => {
      this.changeMindContentView(data, index);
    });

    this.bus.$on("onInputBecomeFirstResponder", (id) => {
      this.onInputBecomeFirstResponder(id);
    });
    this.bus.$on("onRemoveGeneralization", (data) => {
      this.removeElementView(data);
    });
  },
  mounted() {
    if (this.router == "template" || this.router == "c" || this.router == "s" ) {
      let templateBox = document.getElementById("outline-box");
      templateBox.style.minHeight = "calc(100vh - 9px)";
      // templateBox.style.overflow = "auto";

      let outlineBox = document.getElementById("outlineBox");
      let foreignObject = outlineBox.getElementsByTagName("foreignObject")[0];
      let foreignObjectChildDiv = foreignObject.getElementsByTagName("div")[0];
      let templateContent = document.getElementById("outline-content");

      templateContent.style.width = "100vw";
      templateContent.style.minWidth = "1124px";
      templateContent.style.overflow = "auto";
      templateContent.style.position = "relative";
      foreignObject.setAttribute("y", "100");
      if (this.router == "template") {
        outlineBox.style.height = "100vh";
      }
      //web端
      if (this.router == "c") {
        foreignObject.setAttribute("y", "108");
        outlineBox.style.height = "calc(100vh + 80px)";
        foreignObject.style.height = "calc(100vh + 80px)";
        foreignObject.style.marginRight = "-1px";
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        ) {
          templateBox.style.minHeight = "calc(100vh - -77px)";
          foreignObjectChildDiv.style.height = "calc(100vh - 56px)";
          templateContent.style.maxWidth = "1124px";
        } else {
          foreignObjectChildDiv.style.height = "calc(100vh - 118px)";
        }
      }
      if (this.router == "s") {
        foreignObject.setAttribute("y", "146");
        outlineBox.style.height = "calc(100vh + 80px)";
        foreignObject.style.height = "calc(100vh + 80px)";
        foreignObject.style.marginRight = "-1px";
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        ) {
          templateBox.style.minHeight = "calc(100vh - 0px)";
          foreignObjectChildDiv.style.height = "calc(100vh - 56px)";
          templateContent.style.maxWidth = "1124px";
        } else {
          foreignObjectChildDiv.style.height = "calc(100vh - 118px)";
          foreignObject.setAttribute("y", "146");
          templateContent.style.maxWidth = "1124px";
          foreignObjectChildDiv.style.height = "calc(100vh - 104px)";
        }
      }
    }
  },
  methods: {
    ...mapMutations(["removeElementViews"]),
    //初始化大纲数据
    elementMindViewSeparator(data, index, nextView) {
      let editmap = document.getElementById("outline-content");

      if (data.isStatisticsContent()) {
        // return new StatisticsElementView(editmap, data);
        return new outlineStatisticsElementView(editmap, data, this, 0xfffff);
      }

      switch (MindElementType.from(data.type)) {
        case MindElementType.MAIN_SUBJECT:
          return new MainOutlineInputView(editmap, data, index, nextView, this);
        case MindElementType.SUBJECT:
        case MindElementType.SON_SUBJECT:
        case MindElementType.FREE_NODE:
          return new OutlineInputView(editmap, data, index, nextView, this);
        case MindElementType.CONTENT_GENERALIZATION:
          return new OutlineGeneralizationInputView(
            editmap,
            data,
            index,
            nextView
          );
        default:
          return;
      }
    },
    //次级节点点击收起子节点
    isShowBigImage(data) {
      let obj = { src: data.imageContent.key };
      this.bus.$emit("examineImg", obj);
    },
    //悬浮图标展示弹框
    isShowIconMenu(type, data, iconNode) {
      this.showIconType = type;
      this.showIconX = data.x;
      this.showIconY = data.y;
      this.showIconMenu = true;
      this.iconNode = iconNode;
      this.tintColor = iconNode.iconData.tintColor;
      this.bus.$emit("isShowIconDetialsMenu", false); //隐藏图标菜单
    },
    continuousShow() {
      clearTimeout(this.iconHideTime);
    },
    hideIconMenu() {
      this.showIconMenu = false;
    },
    //移除图标
    removeIconMenu: function (obj) {
      this.hideIconMenu();
      EditMindmapVM.removeIcon(this.iconNode.nodeId, this.iconNode.iconData);
    },
    onInputBecomeFirstResponder(id) {
      if (!this.viewElementList.containsKey(id)) {
        return;
      }
      let view = this.viewElementList.get(id);
      if (view.selectFocusLast != null) {
        view.selectFocusLast();
      }
    },

    addMindContentView(data, index) {
      if (
        data.type == MindElementType.LINE ||
        data.type == MindElementType.SON_LINE ||
        data.type == MindElementType.LEFTBRACELINE ||
        data.type == MindElementType.NODE_CONNECT_LINE ||
        data.type == MindElementType.BAOWEI_VIEW ||
        data.type == MindElementType.FORM_LINE ||
        data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
        data.type == MindElementType.LAYOUT_CUSTOM_LINE ||
        data.type == MindElementType.TIME_LINE_BASE_ELEMENT
      ) {
        return;
      }
      if (this.viewElementList.containsKey(data.id)) {
        this.changeMindContentView(data, index);
      } else {
        let nextView = this.findMindElementViewByIndex(index + 1);
        let viewCell = this.elementMindViewSeparator(data, index, nextView);
        if (viewCell == null) {
          return;
        }
        viewCell.isTemplatePage = this.isTemplatePage;
        viewCell.isSharePage = this.isSharePage;

        if (
          data.type == MindElementType.MAIN_SUBJECT ||
          data.type == MindElementType.SUBJECT ||
          data.type == MindElementType.SON_SUBJECT ||
          data.type == MindElementType.CONTENT_GENERALIZATION
        ) {
          viewCell.mindTextSizeChangedDelegate = this.mindTextSizeChangedDelegate;
          // viewCell.setClickListener();
          // viewCell.refresh();
        }
        this.viewElementList.put(data.id, viewCell);
      }
    },

    changeMindContentView(data, index) {
      if (data == null || data.isEmpty()) {
        return;
      }
      if (!this.viewElementList.containsKey(data.id)) {
        this.addMindContentView(data, index);
        return;
      }
      let view = this.viewElementList.get(data.id);
      view.setData(data);
      let nextView = this.findMindElementViewByIndex(index + 1);
      if (view.changeViewIndex != null) {
        view.changeViewIndex(index, nextView);
      }
      if (!view.isRefresh) {
        return;
      }
      // view.setViewRect(data.width, data.height, data.x, data.y);
      view.refresh();
    },

    findMindElementViewByIndex(viewIndex) {
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (
          view != null &&
          view.getViewIndex != null &&
          view.getViewIndex() == viewIndex
        ) {
          return view;
        }
      }
      return null;
    },

    removeElementView(data) {
      if (!this.viewElementList.containsKey(data.id)) {
        return;
      }
      let view = this.viewElementList.get(data.id);
      view.removeView();
      this.viewElementList.remove(data.id);
      this.removeElementViews(null);
      if (view.getViewIndex != null) {
        let viewIndex = view.getViewIndex();

        let keys = this.viewElementList.keys();
        for (let index = 0; index < keys.length; index++) {
          let view = this.viewElementList.get(keys[index]);
          if (
            view != null &&
            view.getViewIndex != null &&
            view.getViewIndex() > viewIndex
          ) {
            view.setViewIndex(view.getViewIndex() - 1);
          }
        }
      }
    },
  },
  watch: {
    "$store.state.outlineViews"(outlineViews) {},
    "$store.state.removeElementView"(accout) {
      if (accout == null) {
        return;
      }
      this.removeElementView(accout.id);
    },
  },
};
</script>

<style lang="less" scoped>
#outline-box {
  position: relative;
  #outlineBox {
    z-index: 2;
    // width: 1124px;
  }
  #outlineChop {
    z-index: 1;
  }
  svg {
    height: calc(100vh - 70px);
    max-width: 1124px;
    min-width: 1124px;
    position: absolute;
    right: 50%;
    transform: translate(50%, 0%);
    foreignObject {
      height: 100vh;
      max-width: 1124px;
      min-width: 1124px;
      width: 100%;
      // background-color: #ffffff;
    }
  }
  #outline-content {
    height: calc(100vh - 107px);
    max-width: 1124px;
    min-width: 1124px;
    position: relative;
    padding: 20px 60px 60px;
    overflow: hidden;
    overflow-y: scroll;
    margin: 0 auto;
    background-color: #ffffff;

  }
  //滚动条样式
  #outline-content::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  #outline-content::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  #outline-content::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  #outline-content::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}
@media screen and (min-width: 200px) and (max-width: 1000px) {
  #outline-box {
    position: relative;
    // top: 0px !important;
    #outlineBox {
      z-index: 2;
    }
    #outlineChop {
      z-index: 1;
    }
    svg {
      height: 100vh;
      max-width: 100%;
      min-width: 100%;
      position: absolute;
      right: 50%;
      transform: translate(50%, 0%);
      foreignObject {
        height: 100vh;
        max-width: 100vw;
        min-width: 100vw;
        width: 100%;
        div {
          min-width: 100% !important;
        }
      }
    }
    #outline-content {
      height: 100vh;
      // max-width: 100%;
      min-width: 100%;
      background-color: #ffffff;
      position: relative;
      // top: 80px !important;
      padding: 15px 15px 60px;
      overflow: hidden;
      overflow-y: scroll;
      margin: 0 auto;
    }
    //滚动条样式
    #outline-content::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      /**/
    }
    #outline-content::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    #outline-content::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
    #outline-content::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }
  }
}
</style>
