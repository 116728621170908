
import TimeLineMindDateFormat from "../../../core/core/dataformat/TimeLineMindDateFormat"
import MindHistoryData from "../../../viewmodel/core/minddata/MindHistoryData"
import HashMap from "../../core/base/HashMap"
import StorageManager from "../../../core/core/storage/StorageManager"
import AutoAdsorptionFind from "../../../core/core/calcule/AutoAdsorptionFind"
import DeviceType from "../../../viewmodel/datatype/DeviceType";

import CalculSaveImageRect from "../../../core/core/calcule/CalculSaveImageRect"
import Config from "../../../core/core/calcule/Config"
import MindElementHitCalculation from "../../../core/core/calcule/MindElementHitCalculation"
import MindNodeConutCalculation from "../../../core/core/calcule/MindNodeConutCalculation"
import NodeConnectLineCalcul from "../../../core/core/calcule/NodeConnectLineCalcul"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import DoubleBubbleMindDateFormat from "../../../core/core/dataformat/DoubleBubbleMindDateFormat"
import MindDateFormat from "../../../core/core/dataformat/MindDateFormat"
import Colors from "../../../utils/Colors"
import Strings from "../../../utils/Strings"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../../core/base/IdGenerator"
import MindMapStyleColor from "../../core/base/MindMapStyleColor"
import CGRect from "../../core/base/basedata/Rect"
import MindIconsManager from "../../core/tools/MindIconsManager"
import MindSettingManager from "../../core/tools/setting/MindSettingManager"
import MindSettingData from "../../core/tools/setting/MindSettingData";
import ConnectLineType from "../../datatype/ConnectLineType"
import IconLayoutType from "../../datatype/IconLayoutType"
import ImageLayoutType from "../../datatype/ImageLayoutType"
import ImageShapeType from "../../datatype/ImageShapeType"
import LineLayout from "../../datatype/LineLayout"
import MindBGStripesType from "../../datatype/MindBGStripesType"
import MindDisplayType from "../../datatype/MindDisplayType"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import MindType from "../../datatype/MindType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import ViewStatus from "../../datatype/ViewStatus"
import LineMindTypeNode from "../LineMindTypeNode"
import LineMindTypeNodeUnit from "../LineMindTypeNodeUnit"
import MindElementData from "../MindElementData"
import ConnectMapElementContent from "../mindcontent/ConnectMapElementContent"
import IconElementContent from "../mindcontent/IconElementContent"
import ImageContent from "../mindcontent/ImageContent"
import LineElementContent from "../mindcontent/LineElementContent"
import LinkElementContent from "../mindcontent/LinkElementContent"
import RemarksElementContent from "../mindcontent/RemarksElementContent"
import DoubleBubbleMindNodeUnit from "../DoubleBubbleMindNodeUnit"
import TimeMindTypeNodeUnit from "../TimeMindTypeNodeUnit"
import publicDataContent from "../../../core/store/index"
import StringsLanguage from '../../../common/lang/strings';
import Point from "../../core/base/Point"
import Size from "../../core/base/Size"
import Util from "../../../utils/Util"
import store from "../../../core/store/index"
import { postMindmapDeleteImage } from "../../../common/netWork/mind_map_api"
import { postGroupUploadGmap, postGroupViewGmap } from "../../../common/netWork/group_api"
import { postMindmapViewConnectmap } from "../../../common/netWork/mind_map_api"
import { postBaseGlobalConfig } from "../../../common/netWork/networkcache";

import MindMapStyleHandle from "../MindMapStyleHandle"
import StatisticsContent from "../mindcontent/StatisticsContent"
import StatisticsCellData from "../mindcontent/StatisticsCellData"
import StatisticsChapeType from "../../datatype/StatisticsChapeType"
import MoveMindElementDataByPoint from "../../../core/core/calcule/MoveMindElementDataByPoint"
import CGPoint from "../../core/base/basedata/CGPoint"
import MoveMindElementDataInfo from "../../../core/core/basemode/MoveMindElementDataInfo"
import TextContent from "../mindcontent/TextContent"
import OutlineMindElementCalculation from "../../../core/core/calcule/OutlineMindElementCalculation"
import CalculSelectedNodesByRect from "../../../core/core/calcule/CalculSelectedNodesByRect"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import RectsMergeCalculation from "../../core/layout/RectsMergeCalculation"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import MindCopyManager from "../../../core/core/common/MindCopyManager"
import MindMapPlayVM from "./MindMapPlayVM"
import MindMapPlayStatus from "../../datatype/MindMapPlayStatus"
import GlobalInputTextType from "../../datatype/GlobalInputTextType"
import { ssrCompile } from "vue-template-compiler"
import SettingData from "../../core/minddata/SettingData"
import { minor } from "semver"
import strings from "../../../common/lang/strings"
import LinePointShape from "../../datatype/LinePointShape"
import BoldData from "../mindcontent/BoldData"
import QuickStyleType from "../../datatype/QuickStyleType"
import TaskContent from "../mindcontent/TaskContent"
import Definition from "../../../core/core/common/Definition"
import LatexContent from "../mindcontent/LatexContent"
import ResourceContent from "../mindcontent/ResourceContent"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"
import { isClickSaveMapButton, isOffline } from "../../../assets/js/GlobalVariable.js";
import MindMapFramework from "../../../viewmodel/mindelementdata/MindMapFramework";
import { getMachineId, md5 } from "../../../common/netWork/base"
import PointAndTime from "../../../core/core/basemode/PointAndTime"
import MarkdownFileImport from "../../../core/core/dataformat/fileImport/MarkdownFileImport"
import getString from "../../../common/lang/language"
import MindElementFullType from "../../datatype/MindElementFullType"
import SaveMindMapToLocal from "../../core/tools/mapfile/SaveMindMapToLocal"
import MindContentData from "../../core/tools/mapfile/MindContentData"
import MapSaveData from "../../core/tools/mapfile/MapSaveData"
import MindNodesNearbyData from "./MindNodesNearbyData"
import MindmapDataRouter from "../../facade/MindmapDataRouter"
import LoadLocalXJMind from "../../core/tools/mapfile/LoadLocalXJMind"
import MindMe from "../../facade/MindMe"
import MindElementAlignmentType from "../../datatype/MindElementAlignmentType"
import FontLoad from "../../../core/core/calcule/FontLoad"
import OrderContent from "../mindcontent/OrderContent"
import OrderType from "../../datatype/OrderType"
import GroupMindMapData from "./GroupMindMapData"
import groupMindSyncAndMerge from "./GroupMindSyncAndMerge"
import MindEditSectionId from "../../core/minddata/MindEditSectionId"
import LineOrientation from "../../datatype/LineOrientation"
import MindElementFreeNodeCheckPoint from "../../../core/core/calcule/MindElementFreeNodeCheckPoint"
import TimeLineFramework from "../TimeLineFramework"
import FlowChartDirection from "../../datatype/FlowChartDirection.js"
import CheckTextIsSelected from "../../../utils/CheckTextIsSelected.js"

if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}

/**
 * Created by tony on 2019/12/24
 */

class EditMindmapVM {
    constructor() {
        this.isInitMindMapCenterDisplay = false;
        this.delegate;
        this.headerMenu;
        this.mindDetailsID = publicDataContent.state.themeStyleIndex;
        this.nodeContentValue = "";
        this.mindPreviewID = -1;
        this.mindMapParentId = -1;
        this.editMindmapWidth = Config.Mind_Width;
        this.editMindmapHeight = Config.Mind_Height;

        this.mainMindNodeUnit = new LineMindTypeNodeUnit();
        this.freeNodes = new Array();
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.historyList = new Array();
        this.currentHistoryIndex = 0;
        this.addHostryDelaySaveMindMapTask = null;
        this.backwardRetreating = false;
        this.forwarding = false;
        this.selectedNodeId = IdGenerator.INVALID_ID; //当前选中的节点ID

        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;

        this.globalLineLayout = LineLayout.CURVE_LINE_2;
        this.mindBGStripesType = MindBGStripesType.NonExistent;
        this.isAddFreeNode = false;
        this.addStatisticsNode = new MindElementData().emptyMindNode();
        this.isAddNodeConnectLine = false;
        this.addNodeConnectLineType = ConnectLineType.STRAIGHT_ARROW_LINE;
        this.mindPreviewImageKey = "";
        this.mindType = MindType.LINE_MAP;
        this.mindBGColor = Colors.white;
        this.mindMapStyleColor = new MindMapStyleColor(2);
        this.mindDisplayType = MindDisplayType.MindMap;
        this.viewStatus = ViewStatus.Unknown;
        this.savePreviewImageFromViewTime = 0;
        this.isEmptyNode = false;
        this.selectedList = new Array();
        this.saveTimeout = -1;
        this.coverMindTimeout;
        this.mindNodeJson;
        this.mindGlobalAudio = "";
        this.mindMapPlayVM;
        this.settingData = new SettingData();
        this.Util = Util;
        this.currentSelectedRect = new CGRect(0, 0, 0, 0);
        this.selfUuid = getMachineId()
        this.lastClickPoint = new PointAndTime()
        this.checkNodeConnectLineData = new MindElementData()
        this.mindkey = "";
        this.originalDataMap = new HashMap();
        this.loadDataModel = null;
        this.mindOwnerId = "";
        this.requestorType = 0; ////组 请求者类型0-普通用户 1-管理员 2-组创建者
        this.mindSheetId = "";
        this.mindRootId = "";
        this.mindPath = "";
        this.lastSaveImageCover = "";
        this.lastSaveImageCoverTime = 0;
        this.lastLoadDataTime = 0
        this.isElectron = false;
        this.lastChangeTime = -1;
        this.lastServerSaveTime = -1;
        this.lastLocalSaveTime = -1;
        this.updataLocalMindMapInViewModeTime = -1;
    };

    //导图id，导图文件列表id，导图类型，导图布局，导图线条布局，‘’,
    init(mindDetailsID, mindPreviewID, nodeContentValue, mindType,
        globalLayout, globalLineLayout, styleIndex,
        mindMapParentId, mindDisplayType, isEmptyNode) {
        this.clearAllDataAndView();
        this.mindDetailsID = mindDetailsID;
        this.mindPreviewID = mindPreviewID;
        this.nodeContentValue = nodeContentValue;
        this.mindType = mindType;
        this.settingData.branchNodeFreelayout = new BoldData().create(false)
        this.settingData.freeNodeMoveAdsorbent = new BoldData().create(true)
        this.globalLayout = globalLayout;
        this.globalLineLayout = globalLineLayout;
        this.mindMapStyleColor = new MindMapStyleColor(styleIndex);
        this.mindMapParentId = mindMapParentId;
        this.viewStatus = ViewStatus.DidLoad;
        if (this.mindType == MindType.FLOW_CHART) {
            this.mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, MindType.LINE_MAP, this.mindMapStyleColor.currentSelectedIndex, this.isEmptyNode);
        } else {
            this.mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mindMapStyleColor.currentSelectedIndex, this.isEmptyNode);
        }        
        this.mindDisplayType = mindDisplayType == undefined || mindDisplayType == null ? MindDisplayType.MindMap : mindDisplayType;
        this.isEmptyNode = isEmptyNode;
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(this.mindType, this.mindMapStyleColor.currentSelectedIndex);
        this.doubleBubbleMindNodeUnit.mindMapStyleColor = this.mindMapStyleColor;
        this.timeMindTypeNodeUnit.mindMapStyleColor = this.mindMapStyleColor;
        this.mindSheetId = "";
        this.mindRootId = "";
        this.mindPath = "";
        if (this.delegate != null) {    //记录创建了几次vm
            this.delegate.createdEditMindmapVM();
        }
    };

    EditMindmapVM(mindDetailsID, mindPreviewID, mindType, globalLayout) {
        this(mindDetailsID, mindPreviewID, mindType, globalLayout, LineLayout.CURVE_LINE_2, 3, -1, MindDisplayType.MindMap, false);
    }

    setPreviewImageKey() {
    }

    createData() {
        IdGenerator.shared.resetId();
        IdGenerator.shared.setSettingData(this.settingData);
        this.setPreviewImageKey();
        this.initData();
        if (this.mindDisplayType == MindDisplayType.Outline &&
            (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP)) {
            this.setMindMindDisplay(false);
        }
        this.delegate.changMindBackgroundColor(this.mindMapStyleColor.getMindMapFillColor());
        this.refresh(false, false);
        if (!this.isFlowChart()) {
            if (this.globalLayout == NodeLayoutType.LAYOUT_RIGHT || this.globalLayout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                this.onChangeRainbowColor(2, false, false);
            } else {
                this.onChangeRainbowColor(10, false, false);
            }
            this.refresh(true, false);
        }
        if (this.mindType == MindType.LINE_MAP) {
            if (this.globalLayout != NodeLayoutType.LAYOUT_RADIATE) {
                this.setFramework(this.mindMapStyleColor.currentSelectedIndex);
                if (this.globalLayout == NodeLayoutType.LAYOUT_RIGHT || this.globalLayout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    this.onChangeRainbowColor(2, false, false);
                } else {
                    this.onChangeRainbowColor(10, false, false);
                }
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            if (this.globalLayout != NodeLayoutType.LAYOUT_BRIDGE) {
                this.setFramework(this.mindMapStyleColor.currentSelectedIndex);
                if (this.globalLayout == NodeLayoutType.LAYOUT_RIGHT || this.globalLayout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    this.onChangeRainbowColor(2, false, false);
                } else {
                    this.onChangeRainbowColor(10, false, false);
                }
            }
        }
        this.refresh(true);
        let contentRect = this.getMindContentRect();
        this.delegate.mindContentDisplay(contentRect);        
        this.willSave(false);
        this.addHostry();
        let that = this;
        setTimeout(() => {
            this.delegate.mindContentDisplay(contentRect);
            that.coverMindData()
        }, 200);
    }

    onChangeMindDisplayType() {
        if (this.mindDisplayType == MindDisplayType.MindMap) {
            this.mindDisplayType = MindDisplayType.Outline;
        } else {
            this.mindDisplayType = MindDisplayType.MindMap;
        }
        this.setMindMindDisplay(true);
        setTimeout(() => {
            this.setMindContentCenterDisplay(true);
        }, 50);
    }

    isFlowChart() {
        return this.mindType == MindType.FLOW_CHART;
    }

    isHasReplaceStickNode() {
        let target = this.getSelectedNodeById();
        if (target.isEmpty() ||
            (target.value.type != MindElementType.MAIN_SUBJECT &&
                target.value.type != MindElementType.SUBJECT &&
                target.value.type != MindElementType.SON_SUBJECT &&
                target.value.type != MindElementType.CONTENT_GENERALIZATION)) {
            return false;
        }
        return true;
    }

    replaceNode(targetId) {

        let node = MindCopyManager.getCopyNode();
        if (targetId == undefined || targetId == null || targetId == "") {
            targetId = this.selectedNodeId;
        }
        let target = this.getNodeById(targetId);
        if (node.isEmpty()) {
            return;
        }
        if (target.isEmpty() ||
            (target.value.type != MindElementType.MAIN_SUBJECT &&
                target.value.type != MindElementType.SUBJECT &&
                target.value.type != MindElementType.SON_SUBJECT &&
                target.value.type != MindElementType.CONTENT_GENERALIZATION)) {
            this.selectAddTargetTips();
            return;
        }
        let isKeepStyle = target.value.parentNodeId == IdGenerator.INVALID_ID;
        targetId = target.value.id;
        let x = target.value.x;
        let y = target.value.y;
        let parentNodeId = target.value.parentNodeId;
        let mindType = target.value.type;
        let level = target.value.level;
        let mindElementShape = target.value.mindElementShape;
        let borderColor = target.value.borderColor;
        let backgroundColor = target.value.backgroundColor;

        target.value.stick(node.value);

        target.value.id = targetId;
        target.value.type = mindType;
        target.value.x = x;
        target.value.y = y;
        target.value.level = level;
        target.value.parentNodeId = parentNodeId;

        if (!isKeepStyle && (mindElementShape == MindElementShapeType.Underline || mindElementShape == MindElementShapeType.Two_Underline)) {
            target.value.mindElementShape = mindElementShape;
            if (target.value.mindElementShape == MindElementShapeType.Card) {
                this.setNodeToCard(target.value.id, false)
            }
            target.value.borderColor = borderColor;
            if (target.value.isCardContent()) {
                target.value.checkSetTextColor(this.mindBGColor)
            }
        }
        let childCount = node.children.length;
        for (var index = 0; index < childCount; index++) {
            let child = node.children[index];
            this.stickNodeByNode(targetId, child, new CGPoint(), isKeepStyle);
        }
        MindElementCalculation.set(target.value).caluleTextForData();
        if (this.isRootNode(target.value.id)) {
            let unit = this.findFreeUnit(target.value.id);
            if (unit != null) {
                unit.setElementLayout(node.value.layout);
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    checkNodeViewToScreen(data, delay = true, isSelected = true) {
        if (data == null || data.isEmpty() || data.x == null) {
            return;
        }
        if (delay) {
            let that = this;
            setTimeout(() => {
                that.delegate.checkNodeViewToScreen(data);
                if (isSelected) {
                    that.delegate.onSelected(data, new CGPoint(data.x, data.y));
                }
            }, 500);
        } else {
            this.delegate.checkNodeViewToScreen(data);
            if (isSelected) {
                this.delegate.onSelected(data, new CGPoint(data.x, data.y));
            }
        }
    }

    stickNodeByNode(targetId, node, point, isKeepStyle) {
        var target = this.getNodeById(targetId);
        if (node.isEmpty()) {
            return false;
        }
        if (target.isEmpty()) {
            target = this.createFreeNode(0);
            let id = target.value.id;
            target.value = node.value.copy();
            target.value.parentNodeId = IdGenerator.INVALID_ID;
            target.value.id = id;
            target.value.type = MindElementType.SUBJECT;
            target.value.level = 0;
            MindElementCalculation.set(target.value).caluleTextForData();
            let unit = this.findFreeUnit(id);
            if (unit == null) {
                return false;
            }
            unit.setElementLayout(node.value.layout);
            let childCount = node.children.length;
            for (var index = 0; index < childCount; index++) {
                let child = node.children[index];
                let newNode = target.stickNode(child);
                MindElementCalculation.set(newNode.value).caluleTextForData();
            }
            unit.setNodeToDictStructure(target);

            let idMaps = this.getNodeIdMap(target, node);
            let list = new NodesRectCalculation().getNodes(node);
            let listCount = list.length;
            for (var j = 0; j < listCount; j++) {
                let cell = list[j];
                if (!idMaps.containsKey(cell.id)) {
                    continue;
                }
                let newData = idMaps.get(cell.id);
                if (newData.isEmpty()) {
                    continue;
                }
                let parentNode = unit.getNodeById(newData.parentNodeId);
                let encircleData = MindCopyManager.getEncircleData(cell);
                if (!encircleData.isEmpty() && !newData.isEmpty() && encircleData.lineContent != null) {
                    let targetIds = new Array();
                    let lineTargetIdsCount = encircleData.lineContent.targetIds.length
                    for (var i = 0; i < lineTargetIdsCount; i++) {
                        let cellId = encircleData.lineContent.targetIds[i];
                        // for (Integer cellId : encircleData.lineContent.targetIds) {
                        if (idMaps.containsKey(cellId)) {
                            targetIds.push(idMaps.get(cellId).id);
                        }
                    }
                    unit.stickEncircleNode(encircleData, newData.id, targetIds);
                }

                let explainData = MindCopyManager.getExplainData(cell);
                if (!explainData.isEmpty() && explainData.lineContent != null) {
                    unit.stickExplainMindNode(explainData, newData.id);
                }

                let generalizationNode = MindCopyManager.getNodeGeneralization(cell);
                if (!generalizationNode.isEmpty() && generalizationNode.value.generalizationContent != null) {
                    let targetIds = new Array();
                    let generalTargetIdsCount = generalizationNode.value.generalizationContent.targetIds.length
                    for (var i = 0; i < generalTargetIdsCount; i++) {
                        let cellId = generalizationNode.value.generalizationContent.targetIds[i];
                        if (idMaps.containsKey(cellId)) {
                            targetIds.push(idMaps.get(cellId).id);
                        }
                    }
                    unit.stickGeneralizationContentNode(generalizationNode, targetIds,
                        MindCopyManager.getGeneralizationNodeLine(generalizationNode.value.id));
                }
                let line = MindCopyManager.getNodeLine(cell.id);
                if (!line.isEmpty() && !parentNode.isEmpty()) {
                    unit.stickTextElementLineElement(line, parentNode.value.id, newData.id);
                }
            }
            target.value.x = 2000;
            target.value.y = 2000;
            unit.refreshLayout(true, this.settingData);
            let targetRect = unit.getRect();
            let showPoint = point;
            if (showPoint == null || showPoint.x <= 0 || showPoint.y <= 0) {
                showPoint = this.delegate.getMindMapCentralPoint();
                let freeNodesWithoutTarget = [];
                for (let index = 0; index < this.freeNodes.length; index++) {
                    const element = this.freeNodes[index];
                    if (element.rootTreeNode != null && element.rootTreeNode.value.id != target.value.id) {
                        freeNodesWithoutTarget.push(element);
                    }
                }
                let mindElementHitCalculation = new MindElementHitCalculation();
                mindElementHitCalculation.setData(this.mainMindNodeUnit,
                    freeNodesWithoutTarget,
                    [],
                    this.timeMindTypeNodeUnit, this.doubleBubbleMindNodeUnit);
                let rect = mindElementHitCalculation.getMapBlankSpace(
                    new CGRect((showPoint.x - targetRect.width() / 2),
                        (showPoint.y - targetRect.height() / 2),
                        targetRect.width(),
                        targetRect.height())
                );
                
                target.value.x = rect.x + (target.value.x - targetRect.x);
                target.value.y = rect.y + (target.value.y - targetRect.y);
            } else {
                target.value.x = (showPoint.x - target.value.width / 2);
                target.value.y = (showPoint.y - target.value.height / 2);
            }
            this.checkNodeViewToScreen(target.value);
            return true;
        }

        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let findNode = this.mainMindNodeUnit.getNodeById(target.value.id);
            if (!findNode.isEmpty()) {
                let newNode = target.stickNode(node);
                this.mainMindNodeUnit.setNodeToDictStructure(target);
                MindElementCalculation.set(newNode.value).caluleTextForData();
                let lineColor = this.mainMindNodeUnit.getNodeLineColor(newNode);
                if (target.children.length > 0) {
                    let idMaps = this.getNodeIdMap(target.children[target.children.length - 1], node);
                    let list = new NodesRectCalculation().getNodes(node);
                    let listCount = list.length;
                    for (var j = 0; j < listCount; j++) {
                        let cell = list[j];
                        if (!idMaps.containsKey(cell.id)) {
                            continue;
                        }
                        let newData = idMaps.get(cell.id);
                        if (newData.isEmpty()) {
                            continue;
                        }
                        if (!isKeepStyle && (newData.mindElementShape == MindElementShapeType.Underline || newData.mindElementShape == MindElementShapeType.Two_Underline)) {
                            newData.borderColor = lineColor;
                            if (newData.isCardContent()) {
                                newData.checkSetTextColor(this.mindBGColor)
                            }
                        }
                        let parentNode = this.mainMindNodeUnit.getNodeById(newData.parentNodeId);
                        let encircleData = MindCopyManager.getEncircleData(cell);
                        if (!encircleData.isEmpty() && !newData.isEmpty() && encircleData.lineContent != null) {
                            let targetIds = new Array();
                            let lineTargetIdsCount = encircleData.lineContent.targetIds.length
                            for (var i = 0; i < lineTargetIdsCount; i++) {
                                let cellId = encircleData.lineContent.targetIds[i];
                                if (idMaps.containsKey(cellId)) {
                                    targetIds.push(idMaps.get(cellId).id);
                                }
                            }
                            this.mainMindNodeUnit.stickEncircleNode(encircleData, newData.id, targetIds);
                        }
                        let explainData = MindCopyManager.getExplainData(cell);
                        if (!explainData.isEmpty() && explainData.lineContent != null) {
                            this.mainMindNodeUnit.stickExplainMindNode(explainData, newData.id);
                        }

                        let generalizationNode = MindCopyManager.getNodeGeneralization(cell);
                        if (!generalizationNode.isEmpty() && generalizationNode.value.generalizationContent != null) {
                            let targetIds = new Array();
                            let generalTargetIdsCount = generalizationNode.value.generalizationContent.targetIds.length
                            for (var i = 0; i < generalTargetIdsCount; i++) {
                                let cellId = generalizationNode.value.generalizationContent.targetIds[i];
                                if (idMaps.containsKey(cellId)) {
                                    targetIds.push(idMaps.get(cellId).id);
                                }
                            }
                            this.mainMindNodeUnit.stickGeneralizationContentNode(generalizationNode,
                                targetIds,
                                MindCopyManager.getGeneralizationNodeLine(generalizationNode.value.id));
                        }
                        let line = MindCopyManager.getNodeLine(cell.id);
                        if (isKeepStyle && !line.isEmpty() && !parentNode.isEmpty()) {
                            this.mainMindNodeUnit.stickTextElementLineElement(line, parentNode.value.id, newData.id);
                        }
                    }
                    this.checkNodeViewToScreen(newNode.value);
                }
            } else {
                this.freeNodes.forEach(unit => {
                    let freeNode = unit.getNodeById(target.value.id);
                    if (!freeNode.isEmpty()) {
                        let newNode = target.stickNode(node);
                        unit.setNodeToDictStructure(target);
                        let lineColor = unit.getNodeLineColor(newNode);
                        if (target.children.length > 0) {
                            let idMaps = this.getNodeIdMap(target.children[target.children.length - 1], node);
                            let list = new NodesRectCalculation().getNodes(node);
                            let listCount = list.length;
                            // for(MindElementData cell:list ){
                            for (var j = 0; j < listCount; j++) {
                                let cell = list[j];
                                if (!idMaps.containsKey(cell.id)) {
                                    continue;
                                }
                                let newData = idMaps.get(cell.id);
                                if (newData.isEmpty()) {
                                    continue;
                                }
                                if (!isKeepStyle && (newData.mindElementShape == MindElementShapeType.Underline || newData.mindElementShape == MindElementShapeType.Two_Underline)) {
                                    newData.borderColor = lineColor;
                                    if (newData.isCardContent()) {
                                        newData.checkSetTextColor(this.mindBGColor)
                                    }
                                }
                                let parentNode = unit.getNodeById(newData.parentNodeId);
                                let encircleData = MindCopyManager.getEncircleData(cell);
                                if (!encircleData.isEmpty() && !newData.isEmpty() && encircleData.lineContent != null) {
                                    let targetIds = new Array();
                                    let lineTargetIdsCount = encircleData.lineContent.targetIds.length
                                    for (var i = 0; i < lineTargetIdsCount; i++) {
                                        let cellId = encircleData.lineContent.targetIds[i];
                                        if (idMaps.containsKey(cellId)) {
                                            targetIds.push(idMaps.get(cellId).id);
                                        }
                                    }
                                    unit.stickEncircleNode(encircleData, newData.id, targetIds);
                                }
                                let explainData = MindCopyManager.getExplainData(cell);
                                if (!explainData.isEmpty() && explainData.lineContent != null) {
                                    unit.stickExplainMindNode(explainData, newData.id);
                                }
                                let generalizationNode = MindCopyManager.getNodeGeneralization(cell);
                                if (!generalizationNode.isEmpty() && generalizationNode.value.generalizationContent != null) {
                                    let targetIds = new Array();
                                    let generalTargetIdsCount = generalizationNode.value.generalizationContent.targetIds.length
                                    for (var i = 0; i < generalTargetIdsCount; i++) {
                                        let cellId = generalizationNode.value.generalizationContent.targetIds[i];
                                        if (idMaps.containsKey(cellId)) {
                                            targetIds.push(idMaps.get(cellId).id);
                                        }
                                    }
                                    unit.stickGeneralizationContentNode(generalizationNode, targetIds,
                                        MindCopyManager.getGeneralizationNodeLine(generalizationNode.value.id));
                                }
                                let line = MindCopyManager.getNodeLine(cell.id);
                                if (isKeepStyle && !line.isEmpty() && !parentNode.isEmpty()) {
                                    unit.stickTextElementLineElement(line, parentNode.value.id, newData.id);
                                }
                            }
                            this.checkNodeViewToScreen(newNode.value);
                        }
                    }
                })
            }
        } else if (this.mindType == MindType.BUBBLE_MAP) {
            let findNode = this.doubleBubbleMindNodeUnit.getNodeById(target.value.id);
            if (!findNode.isEmpty()) {
                target.stickNode(node);
                this.doubleBubbleMindNodeUnit.setNodeToDictStructure(target);
            } else {
                this.freeNodes.forEach(unit => {
                    let freeNode = unit.getNodeById(target.value.id);
                    if (!freeNode.isEmpty()) {
                        target.stickNode(node);
                        unit.setNodeToDictStructure(target);
                    }
                })
            }
        }
        return true;
    }

    setMindMindDisplay(isSave) {
        if (this.mindDisplayType == MindDisplayType.Outline) {
            this.delegate.openOutlineDisplayView();
        } else {
            this.delegate.mindmapDisplayView();
        }
        this.mainMindNodeUnit.setMindDisplayType(this.mindDisplayType);
        this.freeNodes.forEach(unit => {
            unit.setMindDisplayType(this.mindDisplayType);
        });
        this.refresh(true, true, false, false);
        this.setMindContentCenterDisplay(true);
        if (isSave) {
            this.willSave(true);
        }
    }

    setMindContentCenterDisplay(isMindContentDisplayCenter, isScale = true) {
        if (this.loadDataModel != null) {
            return
        }
        if (this.mindDisplayType == MindDisplayType.MindMap && !this.isInitMindMapCenterDisplay) {

            let contentRect = this.getMindContentRectByMargin(-100000, -100000);
            let checkValue = this.checkEnlargeCanvas(contentRect);
            if (checkValue.width() != this.editMindmapWidth || checkValue.height() != this.editMindmapHeight) {
                this.editMindmapWidth = checkValue.width();
                this.editMindmapHeight = checkValue.height();
                Config.Mind_Width = this.editMindmapWidth;
                Config.Mind_Height = this.editMindmapHeight;
                this.delegate.resetDrawingBoardSize(new Size(checkValue.width(), checkValue.height()));
            }
            if (checkValue.x != 0 || checkValue.y != 0) {
                this.moveRootNodePoint(checkValue.x, checkValue.y);
                this.refresh(true, true, false, false);
            }

            if (isMindContentDisplayCenter) {
                let contentRect = this.getMindContentRect();
                this.delegate.mindContentDisplay(contentRect, isScale);
            }
        }
    }

    moveRootNodeCenterDisplay(initView = false) {
        let rootPoint = null
        let contentRect = this.getMindContentRect();
        if (contentRect.width() * contentRect.height() < 4000 * 4000) {
            this.delegate.mindContentDisplay(contentRect, false);
            return
        }
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            if (this.mainMindNodeUnit.rootTreeNode == null) {
                return
            }
            rootPoint = this.mainMindNodeUnit.rootTreeNode.value.getCenterPoint()
        } else if (this.mindType == MindType.TIME_MAP) {
            if (this.timeMindTypeNodeUnit.title == null) {
                return
            }
            rootPoint = this.timeMindTypeNodeUnit.title.getCenterPoint()
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            if (this.doubleBubbleMindNodeUnit.rootTreeNode == null) {
                return
            }
            rootPoint = this.doubleBubbleMindNodeUnit.rootTreeNode.getCenterPoint()
        }
        if (rootPoint != null && rootPoint.x > 0 && rootPoint.y > 0) {
            this.delegate.moveXYDrawingBoardToCenter(rootPoint.x, rootPoint.y)
        }
    }

    moveRootNodePoint(x, y) {
        if (this.loadDataModel != null) {
            return
        }
        let encircleMindMap = new HashMap()
        
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            this.mainMindNodeUnit.rootTreeNode.value.x += x;
            this.mainMindNodeUnit.rootTreeNode.value.y += y;
            for (let index = 0; index < this.mainMindNodeUnit.rootTreeNode.children.length; index++) {
                let cell = this.mainMindNodeUnit.rootTreeNode.children[index]
                if (!cell.isEmpty() && cell.value.isFreeLayoutNode) {
                    cell.value.x += x;
                    cell.value.y += y;
                }
            }
            if (this.isFlowChart()) {
                let encircleMindElementDataDictArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
                for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                    let key = encircleMindElementDataDictArr[index];
                    encircleMindMap.put(key, this.mainMindNodeUnit.encircleMindElementDataDict.get(key));
                }
            }            
        } else if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.title.x += x;
            this.timeMindTypeNodeUnit.title.y += y;
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let changeNodeMap = new HashMap();
            this.doubleBubbleMindNodeUnit.rootTreeNode.value.x += x;
            this.doubleBubbleMindNodeUnit.rootTreeNode.value.y += y;

            changeNodeMap.put(this.doubleBubbleMindNodeUnit.rootTreeNode.value.id, this.doubleBubbleMindNodeUnit.rootTreeNode.value);
            this.doubleBubbleMindNodeUnit.rootTreeNode.children.forEach(child => {
                if (child.value.isFreeLayoutNode) {
                    child.value.x += x;
                    child.value.y += y;
                    changeNodeMap.put(child.value.id, child.value);
                }
            });
            if (this.doubleBubbleMindNodeUnit.rootTreeBrotherNode != null) {
                this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.children.forEach(child => {
                    if (child.value.isFreeLayoutNode && !changeNodeMap.containsKey(child.value.id)) {
                        child.value.x += x;
                        child.value.y += y;
                        changeNodeMap.put(child.value.id, child.value);
                    }
                });
            }
            this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.forEach(data => {
                if (data.isFreeLayoutNode && !changeNodeMap.keySet().contains(data.id)) {
                    data.x += x;
                    data.y += y;
                    changeNodeMap.put(data.id, data);
                }
            })
        }

        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            //标记
            if (unit.rootTreeNode == null) {
                unit.rootTreeNode = LineMindTypeNode.emptyMindNode();
            }
            unit.rootTreeNode.value.x += x;
            unit.rootTreeNode.value.y += y;
            let childLength = unit.rootTreeNode.children.length;
            for (let i = 0; i < childLength; i++) {
                let child = unit.rootTreeNode.children[i];
                if (child.value.isFreeLayoutNode) {
                    child.value.x += x;
                    child.value.y += y;
                }
            }
            if (this.isFlowChart()) {
                let encircleMindElementDataDictArr = unit.encircleMindElementDataDict.keys();
                for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                    let key = encircleMindElementDataDictArr[index];
                    encircleMindMap.put(key, unit.encircleMindElementDataDict.get(key));
                }
            }            
        }

        let encircleMindMapKeys = encircleMindMap.keys();
        for (let index = 0; index < encircleMindMapKeys.length; index++) {
            let key = encircleMindMapKeys[index];
            let mind = encircleMindMap.get(key);
            mind.x += x;
            mind.y += y;
        }
        let nodeConnectLineDataDictKeys = this.nodeConnectLineDataDict.keys()
        let nodeConnectLineDataDictLength = nodeConnectLineDataDictKeys.length
        for (let index = 0; index < nodeConnectLineDataDictLength; index++) {
            let key = nodeConnectLineDataDictKeys[index];
            let line = this.nodeConnectLineDataDict.get(key);
            if (line == null || line.isEmpty() || line.lineContent == null) {
                continue
            }
            let start = line.lineContent.getStartControlPoint();
            let end = line.lineContent.getEndControlPoint();
            if (start.x <= 1 || start.y <= 1 ||
                end.x <= 1 || end.y <= 1) {
                continue;
            }
            line.x += x;
            line.y += y;

            line.lineContent.startControlPointX += x;
            line.lineContent.startControlPointY += y;

            line.lineContent.endControlPointX += x;
            line.lineContent.endControlPointY += y;
        }
    }

    //Return parameter 1:resize大小，2：moveX 3:moveY
    checkEnlargeCanvas(contentRect) {
        var width = this.editMindmapWidth;
        var height = this.editMindmapHeight;
        var moveX = 0;
        var moveY = 0;
        var contentWindowSize = this.delegate.getDisplayContentWindowSize();
        let reservedSpace = Math.min(contentWindowSize.getWidth(), contentWindowSize.getHeight()) * 2;

        if (contentRect.width() > this.editMindmapWidth) {
            width = contentRect.width() + reservedSpace;
        }
        if (contentRect.height() > this.editMindmapHeight) {
            height = contentRect.height() + reservedSpace;
        }

        if (width > height) {
            height = width;
        } else {
            width = height;
        }

        if (contentRect.x < 0) {
            moveX = reservedSpace / 2 - contentRect.x;
        }
        if (contentRect.x + contentRect.width() > width) {
            moveX = -(contentRect.x + contentRect.width() - width + reservedSpace / 2);
        }
        if (contentRect.y < 0) {
            moveY = reservedSpace / 2 - contentRect.y;
        }
        if (contentRect.y + contentRect.height() > height) {
            moveY = -(contentRect.y + contentRect.height() - height + reservedSpace / 2);
        }

        return new CGRect(moveX, moveY, width, height);
    }

    getPreviewImagetRect() {
        let rect = this.getMindContentRect();
        let limitSize = 600;

        if (rect.width() > rect.height()) {
            rect.y = rect.y - (rect.width() - rect.height()) / 2;
            rect.height(rect.width());
        } else if (rect.height() > rect.width()) {
            rect.x = rect.x - (rect.height() - rect.width()) / 2;
            rect.width(rect.height());
        }

        if (rect.width() > limitSize) {
            rect.x = rect.x + (rect.width() - limitSize) / 2
            rect.y = rect.y + (rect.height() - limitSize) / 2
            rect.width(limitSize);
            rect.height(limitSize);
        }

        return rect;
    }

    getMindContentRect() {
        return this.getMindContentRectByMargin(0, 0);
    }

    getMindContentRectForSaveImage(verticalScreen, width, height) {
        let rect = this.getMindContentRectByMargin(0, 0);
        if (width <= 0 || height <= 0) {
            return rect
        }
        let scale = verticalScreen ? width / height : height / width
        if (scale > 5.0 || scale < 0.2) {
            return rect
        }
        let rectScale = rect.width() / rect.height()
        if (rectScale == scale) {
            return rect
        } else if (rectScale < scale) {
            let x = rect.x
            let y = rect.y
            let width = rect.width()
            let height = rect.height()
            let changeSize = height * scale - width
            rect.x = x - changeSize / 2
            rect.width(width + changeSize);
            return rect;
        } else {
            let x = rect.x
            let y = rect.y
            let width = rect.width()
            let height = rect.height()
            let changeSize = width / scale - height
            rect.y = y - changeSize / 2
            rect.height(height + changeSize);
            return rect;
        }
    }

    getSelectedMindContentRect() {
        return new CGRect(this.currentSelectedRect.x, this.currentSelectedRect.y,
            this.currentSelectedRect.x + this.currentSelectedRect.width() - this.currentSelectedRect.x,
            this.currentSelectedRect.y + this.currentSelectedRect.height() - this.currentSelectedRect.y);
    }

    isSettingFontFamilyInSelectedNode() {
        if (this.selectedList.isEmpty()) {
            let node = this.getSelectedNode();
            if (node.isEmpty()) {
                return false;
            }
            let list = this.getNodeAllData(node)
            for (let index = 0; index < list.length; index++) {
                let cell = list[index]
                if (cell != null && cell.textContent != null && cell.textContent.fontFamily != null && cell.textContent.fontFamily.length > 0) {
                    return true;
                } else if (cell != null && cell.generalizationContent != null && cell.generalizationContent.fontFamily != null && cell.generalizationContent.fontFamily.length > 0) {
                    return true;
                }
            }
        } else {
            let selectedListLength = this.selectedList.length;
            for (let index = 0; index < selectedListLength; index++) {
                let data = this.selectedList[index];
                if (data != null && data.textContent != null && data.textContent.fontFamily != null && data.textContent.fontFamily.length > 0) {
                    return true;
                } else if (data != null && data.generalizationContent != null && data.generalizationContent.fontFamily != null && data.generalizationContent.fontFamily.length > 0) {
                    return true;
                }
            }
        }
        return false
    }

    isSettingFontFamily() {
        if (this.mainMindNodeUnit != null && this.mainMindNodeUnit.rootTreeNode != null && !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            let list = this.getNodeAllData(this.mainMindNodeUnit.rootTreeNode)
            for (let index = 0; index < list.length; index++) {
                let cell = list[index]
                if (cell != null && cell.textContent != null && cell.textContent.fontFamily != null && cell.textContent.fontFamily.length > 0) {
                    return true;
                } else if (cell != null && cell.generalizationContent != null && cell.generalizationContent.fontFamily != null && cell.generalizationContent.fontFamily.length > 0) {
                    return true;
                }
            }
        }
        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            let list = this.getNodeAllData(unit.rootTreeNode)
            for (let index = 0; index < list.length; index++) {
                let cell = list[index]
                if (cell != null && cell.textContent != null && cell.textContent.fontFamily != null && cell.textContent.fontFamily.length > 0) {
                    return true;
                } else if (cell != null && cell.generalizationContent != null && cell.generalizationContent.fontFamily != null && cell.generalizationContent.fontFamily.length > 0) {
                    return true;
                }
            }
        }
        if (this.timeMindTypeNodeUnit != null && this.timeMindTypeNodeUnit.title != null &&
            !this.timeMindTypeNodeUnit.title.isEmpty()) {
            if (this.timeMindTypeNodeUnit.title.textContent != null && 
                this.timeMindTypeNodeUnit.title.textContent.fontFamily != null && 
                this.timeMindTypeNodeUnit.title.textContent.fontFamily.length > 0) {
                return true;
            }
            let values = this.timeMindTypeNodeUnit.mainMindElementDataDict.values();
            let valuesLength = values.length
            for (let index = 0; index < valuesLength; index++) {
                let cell = values[index];
                if (cell != null && cell.title.textContent != null && cell.title.textContent.fontFamily != null && cell.title.textContent.fontFamily.length > 0) {
                    return true;
                }
                if (cell != null && cell.head.textContent != null && cell.head.textContent.fontFamily != null && cell.head.textContent.fontFamily.length > 0) {
                    return true;
                }
                if (cell != null && cell.desc.textContent != null && cell.desc.textContent.fontFamily != null && cell.desc.textContent.fontFamily.length > 0) {
                    return true;
                }
            }
        }

        if (this.doubleBubbleMindNodeUnit != null && this.doubleBubbleMindNodeUnit.rootTreeNode != null && 
            !this.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty()) {
            let list = this.getNodeAllData(this.doubleBubbleMindNodeUnit.rootTreeNode)
            for (let index = 0; index < list.length; index++) {
                let cell = list[index]
                if (cell != null && cell.textContent != null && cell.textContent.fontFamily != null && cell.textContent.fontFamily.length > 0) {
                    return true;
                } else if (cell != null && cell.generalizationContent != null && cell.generalizationContent.fontFamily != null && cell.generalizationContent.fontFamily.length > 0) {
                    return true;
                }
            }
            list = this.getNodeAllData(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode)
            for (let index = 0; index < list.length; index++) {
                let cell = list[index]
                if (cell != null && cell.textContent != null && cell.textContent.fontFamily != null && cell.textContent.fontFamily.length > 0) {
                    return true;
                } else if (cell != null && cell.generalizationContent != null && cell.generalizationContent.fontFamily != null && cell.generalizationContent.fontFamily.length > 0) {
                    return true;
                }
            }
            for (let index = 0; index < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; index++) {
                let cell = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
                if (cell != null && cell.textContent != null && cell.textContent.fontFamily != null && cell.textContent.fontFamily.length > 0) {
                    return true;
                }
            }
        }
        return false
    }

    getNodeRectForSaveImage() {
        let imageSpace = (MindSettingManager.getMindExportImageSpace() || new MindSettingData().mindExportImageSpace) / 2
        if (this.selectedList.isEmpty()) {
            let node = this.getSelectedNode();
            if (node.isEmpty()) {
                return new CGRect(0, 0, 0, 0);
            }
            let space = imageSpace > 10 ? new UiUtil().dip2px(10) : imageSpace;
            let rect = this.getNodeRect(node.value.id);
            return new CGRect(rect.x - space, rect.y - space,
                rect.width() + space * 2,
                rect.height() + space * 2);
        } else {
            let left = 1000000;
            let top = 1000000;
            let right = 0;
            let bottom = 0;
            let space = imageSpace > new UiUtil().dip2px(10) ? new UiUtil().dip2px(10) : imageSpace;
            let selectedListLength = this.selectedList.length;
            for (let index = 0; index < selectedListLength; index++) {
                let data = this.selectedList[index];

                let rect = this.getNodeRect(data.id);
                if (left > rect.x) {
                    left = rect.x;
                }
                if (top > rect.y) {
                    top = rect.y;
                }
                if (right < rect.x + rect.width()) {
                    right = rect.x + rect.width();
                }
                if (bottom < rect.y + rect.height()) {
                    bottom = rect.y + rect.height();
                }
            }
            return new CGRect(left - space, top - space,
                right - left + space * 2,
                bottom - top + space * 2);
        }
    }

    getNodeRect(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return new CGRect(0, 0, 0, 0);
        }

        let rect = new NodesRectCalculation().calcule(node);
        let list = this.getNodeAllData(node);
        let listLength = list.length;
        var left = 1000000;
        var top = 1000000;
        var right = -1000000;
        var bottom = -1000000;

        for (let index = 0; index < listLength; index++) {
            let cell = list[index];

            left = Math.min(left, cell.x);
            top = Math.min(top, cell.y);

            right = Math.max(right, cell.x + cell.width);
            bottom = Math.max(bottom, cell.y + cell.height);

            let lines = this.getEncircleData(cell);
            for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
                let line = lines[lineIndex];
                if (line.isEmpty()) {
                    continue;
                }
                left = Math.min(left, line.x);
                top = Math.min(top, line.y);
    
                right = Math.max(right, line.x + line.width);
                bottom = Math.max(bottom, line.y + line.height);
            }
            
        }
        return new CGRect(left, top,
            right - left,
            bottom - top);
    }

    getNodeAllData(node) {
        if (node == null || node.isEmpty()) {
            return new Array();
        }
        let list = new NodesRectCalculation().getNodes(node);
        let tempList = new Array();
        let listLength = list.length;
        for (let index = 0; index < listLength; index++) {
            let data = list[index];
            let generalizationNode = this.getNodeGeneralization(data);
            if (generalizationNode.isEmpty()) {
                continue;
            }
            let generalizationNodeList = this.getNodeAllData(generalizationNode);
            tempList = tempList.concat(generalizationNodeList);
        }
        list = list.concat(tempList);
        return list;
    }

    getEncircleData(data) { //获取data对应的包裹线列表
        let list = []
        let lines = this.mainMindNodeUnit.getEncircleData(data);
        list = list.concat(lines)
        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            lines = unit.getEncircleData(data);
            if (lines.length > 0) {
                list = list.concat(lines)
            }
        }
        return list;
    }

    removeNodeLineWithOut() {
        let selectedMap = new HashMap();
        if (this.selectedList.isEmpty()) {
            let node = this.getSelectedNode();
            if (node.isEmpty()) {
                return;
            }
            let list = this.getNodeAllData(node);
            let listLength = list.length;
            for (let index = 0; index < listLength; index++) {
                let data = list[index];
                selectedMap.put(data.id, data);
            }
        } else {
            let selectedListLength = this.selectedList.length;
            for (let index = 0; index < selectedListLength; index++) {
                let cell = this.selectedList[index];
                let node = this.getNodeById(cell.id);
                if (node.isEmpty()) {
                    continue;
                }
                let list = this.getNodeAllData(node);
                let listLength = list.length;
                for (let index = 0; index < listLength; index++) {
                    let data = list[index];
                    selectedMap.put(data.id, data);
                }
            }
        }
        let keys = this.mainMindNodeUnit.lineMindElementDataDict.keys();
        let keysLength = keys.length;
        for (let index = 0; index < keysLength; index++) {
            let key = keys[index];
            let line = this.mainMindNodeUnit.lineMindElementDataDict.get(key);
            if (line.isEmpty() || line.lineContent == null) {
                continue;
            }
            let parent = this.getNodeById(line.parentNodeId);
            let target = this.getNodeById(line.lineContent.targetId);
            if (parent.isEmpty() || target.isEmpty()) {
                continue;
            }
            if (!selectedMap.containsKey(parent.value.id) || !selectedMap.containsKey(target.value.id)) {
                this.removeViewByMindData(line);
            }
        }

        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            let keys = unit.lineMindElementDataDict.keys();
            let keysLength = keys.length;
            for (let index = 0; index < keysLength; index++) {
                let key = keys[index];
                let line = unit.lineMindElementDataDict.get(key);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                let parent = this.getNodeById(line.parentNodeId);
                let target = this.getNodeById(line.lineContent.targetId);
                if (parent.isEmpty() || target.isEmpty()) {
                    continue;
                }
                if (!selectedMap.containsKey(parent.value.id) || !selectedMap.containsKey(target.value.id)) {
                    this.removeViewByMindData(line);
                }
            }
        }
    }

    removeSelectedMindLineWithOut() {
        let selectedMap = new HashMap();
        let selectedListLength = this.selectedList.length;
        for (let index = 0; index < selectedListLength; index++) {
            let data = this.selectedList[index];
            selectedMap.put(data.id, data);
        }

        let keys = this.mainMindNodeUnit.lineMindElementDataDict.keys();
        let keysLength = keys.length;
        for (let index = 0; index < keysLength; index++) {
            let key = keys[index];
            let line = this.mainMindNodeUnit.lineMindElementDataDict.get(key);
            if (line.isEmpty() || line.lineContent == null) {
                continue;
            }
            let parent = this.getNodeById(line.parentNodeId);
            let target = this.getNodeById(line.lineContent.targetId);
            if (parent.isEmpty() || target.isEmpty()) {
                continue;
            }
            if (!selectedMap.containsKey(parent.value.id) || !selectedMap.containsKey(target.value.id)) {
                this.removeViewByMindData(line);
            }
        }

        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            let keys = unit.lineMindElementDataDict.keys();
            let keysLength = keys.length;
            for (let index = 0; index < keysLength; index++) {
                let key = keys[index];
                let line = unit.lineMindElementDataDict.get(key);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                let parent = this.getNodeById(line.parentNodeId);
                let target = this.getNodeById(line.lineContent.targetId);
                if (parent.isEmpty() || target.isEmpty()) {
                    continue;
                }
                if (!selectedMap.containsKey(parent.value.id) || !selectedMap.containsKey(target.value.id)) {
                    this.removeViewByMindData(line);
                }
            }
        }
    }

    getMindContentRectByMargin(minLeft, minTop) {
        let mainRect = new CGRect();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            mainRect = this.mainMindNodeUnit.getRectByMargin(minLeft, minTop);
        } else if (this.mindType == MindType.TIME_MAP) {
            mainRect = this.timeMindTypeNodeUnit.getRect();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            mainRect = this.doubleBubbleMindNodeUnit.getRect();
        }
        let left = mainRect.x;
        let top = mainRect.y;
        let right = mainRect.x + mainRect.size.getWidth();
        let bottom = mainRect.y + mainRect.size.getHeight();
        let freeNodesUnit = this.freeNodes
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = freeNodesUnit[index];
            let unitRect = unit.getRectByMargin(minLeft, minTop);
            if (unitRect.x < left) {
                left = unitRect.x;
            }
            if (unitRect.y < top) {
                top = unitRect.y;
            }
            if (unitRect.x + unitRect.width() > right) {
                right = unitRect.x + unitRect.width();
            }
            if (unitRect.y + unitRect.height() > bottom) {
                bottom = unitRect.y + unitRect.height();
            }
        }
        let space = new UiUtil().dip2px(60);

        let nodeConnectLineDataDictKeys = this.nodeConnectLineDataDict.keys()
        let nodeConnectLineDataDictLength = nodeConnectLineDataDictKeys.length
        for (let index = 0; index < nodeConnectLineDataDictLength; index++) {
            let key = nodeConnectLineDataDictKeys[index];
            let line = this.nodeConnectLineDataDict.get(key);
            if (line == null || line.isEmpty() || line.lineContent == null) {
                continue
            }
            let startData = this.getNodeById(line.parentNodeId);
            let endData = this.getNodeById(line.lineContent.targetId);
            if (startData.isEmpty() || endData.isEmpty()) {
                continue
            }
            if (line.lineContent.connectLineType == ConnectLineType.STRAIGHT_CIRCULAR_LINE ||
                line.lineContent.connectLineType == ConnectLineType.STRAIGHT_ARROW_LINE) {
                if (line.x < left) {
                    left = line.x;
                }
                if (line.y < top) {
                    top = line.y;
                }
                if (line.x + line.width > right) {
                    right = line.x + line.width;
                }
                if (line.y + line.height > bottom) {
                    bottom = line.y + line.height;
                }
            } else if (line.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE &&
                line.lineContent.rightAnglePoints != null && !line.lineContent.rightAnglePoints.isEmpty()) {

                for (let rigIndex = 0; rigIndex < line.lineContent.rightAnglePoints.length; rigIndex++) {
                    let point = line.lineContent.rightAnglePoints[rigIndex];
                    if (point.x + line.x - space < left) {
                        left = point.x + line.x - space;
                    }
                    if (point.y + line.y - space < top) {
                        top = point.y + line.y - space;
                    }
                    if (point.x + line.x + space > right) {
                        right = point.x + line.x + space;
                    }
                    if (point.y + line.y + space > bottom) {
                        bottom = point.y + line.y + space;
                    }
                }
            } else if (line.lineContent.connectLineType == ConnectLineType.CURVE_LINE) {
                if (line.lineContent.startControlPointX < left) {
                    left = line.lineContent.startControlPointX;
                }
                if (line.lineContent.endControlPointX < left) {
                    left = line.lineContent.endControlPointX;
                }
                if (line.lineContent.startControlPointY < top) {
                    top = line.lineContent.startControlPointY;
                }
                if (line.lineContent.endControlPointY < top) {
                    top = line.lineContent.endControlPointY;
                }

                if (line.lineContent.startControlPointX > right) {
                    right = line.lineContent.startControlPointX + space;
                }
                if (line.lineContent.endControlPointX > right) {
                    right = line.lineContent.endControlPointX;
                }

                if (line.lineContent.startControlPointY > bottom) {
                    bottom = line.lineContent.startControlPointY;
                }
                if (line.lineContent.endControlPointY > bottom) {
                    bottom = line.lineContent.endControlPointY;
                }
            }
            if (line.lineContent.textContent != null) {
                if (line.x + line.lineContent.textContent.x - space < left) {
                    left = line.x + line.lineContent.textContent.x - space;
                }
                if (line.y + line.lineContent.textContent.y - space < top) {
                    top = line.y + line.lineContent.textContent.y - space;
                }
                if (line.x + line.lineContent.textContent.x + line.lineContent.textContent.width + space > right) {
                    right = line.x + line.lineContent.textContent.x + line.lineContent.textContent.width + space;
                }
                if (line.y + line.lineContent.textContent.y + line.lineContent.textContent.height + space > bottom) {
                    bottom = line.y + line.lineContent.textContent.y + line.lineContent.textContent.height + space;
                }
            }
        }
        return new CGRect(left, top, right - left, bottom - top);
    }

    viewDidLoad() {
        this.mindSheetId = "";
        this.mindRootId = "";
        this.mindPath = "";
        this.viewStatus = ViewStatus.DidLoad;
        IdGenerator.shared.resetId();
        if (this.saveTimeout != -1) {
            clearTimeout(this.saveTimeout);
        }
        
        this.registerDependent();
        if ((new Strings).isEmpty(this.nodeContentValue)) {
            this.createData();
        } else {
            this.loadData(this.nodeContentValue);
        }
        this.savaEnterMindmapTimes();
        this.updataLocalMindMapInViewMode()
        this.nodeContentValue = "";
    }

    clearAllDataAndView() {
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            for (let i = 0; i < this.mainMindNodeUnit.lineMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.lineMindElementDataDict.keys()[i];
                let line = this.mainMindNodeUnit.lineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }
            for (let i = 0; i < this.mainMindNodeUnit.mainMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.mainMindElementDataDict.keys()[i];
                let node = this.mainMindNodeUnit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }
            for (let i = 0; i < this.mainMindNodeUnit.generalizationLineMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.generalizationLineMindElementDataDict.keys()[i];
                let line = this.mainMindNodeUnit.generalizationLineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }
            for (let i = 0; i < this.mainMindNodeUnit.generalizationMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()[i];
                let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }
            for (let i = 0; i < this.mainMindNodeUnit.encircleMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.encircleMindElementDataDict.keys()[i];
                let mind = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
                this.removeViewByMindData(mind);
            }
            for (let i = 0; i < this.mainMindNodeUnit.explainMindElementDataDict.values().length; i++) {
                let mind = this.mainMindNodeUnit.explainMindElementDataDict.values()[i];
                this.removeViewByMindData(mind);
            }
            this.mainMindNodeUnit.clearAllData()
        } else if (this.mindType == MindType.TIME_MAP) {
            if (this.timeMindTypeNodeUnit.title == null || this.timeMindTypeNodeUnit.baseLine == null) {
                return
            }
            this.removeViewByMindData(this.timeMindTypeNodeUnit.title);
            this.removeViewByMindData(this.timeMindTypeNodeUnit.baseLine);
            for (let i = 0; i < this.timeMindTypeNodeUnit.mainMindElementDataDict.keys().length; i++) {
                const key = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys()[i];
                let data = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(data.head);
                this.removeViewByMindData(data.title);
                this.removeViewByMindData(data.desc);
            }
            this.timeMindTypeNodeUnit.clearAllData()
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            if (this.doubleBubbleMindNodeUnit.rootTreeNode == null || this.doubleBubbleMindNodeUnit.rootTreeBrotherNode == null) {
                return
            }

            for (let i = 0; i < this.doubleBubbleMindNodeUnit.lineMindElementDataDict.keys().length; i++) {
                const key = this.doubleBubbleMindNodeUnit.lineMindElementDataDict.keys()[i];
                let line = this.doubleBubbleMindNodeUnit.lineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }

            for (let i = 0; i < this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys().length; i++) {
                const key = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()[i];
                let node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }

            for (let i = 0; i < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; i++) {
                const data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[i];
                this.removeViewByMindData(data);
            }
            this.doubleBubbleMindNodeUnit.clearAllData()
        }

        for (let i = 0; i < this.nodeConnectLineDataDict.keys().length; i++) {
            const key = this.nodeConnectLineDataDict.keys()[i];
            let line = this.nodeConnectLineDataDict.get(key);
            this.removeViewByMindData(line);
        }
        this.nodeConnectLineDataDict.clear()
        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            for (let i = 0; i < unit.lineMindElementDataDict.keys().length; i++) {
                const key = unit.lineMindElementDataDict.keys()[i];
                let line = unit.lineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }
            for (let i = 0; i < unit.mainMindElementDataDict.keys().length; i++) {
                const key = unit.mainMindElementDataDict.keys()[i];
                let node = unit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }
            for (let i = 0; i < unit.generalizationLineMindElementDataDict.keys().length; i++) {
                const key = unit.generalizationLineMindElementDataDict.keys()[i];
                let line = unit.generalizationLineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }

            for (let i = 0; i < unit.generalizationMindElementDataDict.keys().length; i++) {
                const key = unit.generalizationMindElementDataDict.keys()[i];
                let node = unit.generalizationMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }

            for (let i = 0; i < unit.encircleMindElementDataDict.keys().length; i++) {
                const key = unit.encircleMindElementDataDict.keys()[i];
                let mind = unit.encircleMindElementDataDict.get(key);
                this.removeViewByMindData(mind);
            }

            for (let i = 0; i < unit.explainMindElementDataDict.values().length; i++) {
                let mind = unit.explainMindElementDataDict.values()[i];
                this.removeViewByMindData(mind);
            }
        }
        this.freeNodes = new Array()
        this.historyList = new Array();
        this.currentHistoryIndex = 0;
    }

    checkLatexRefresh(data) {
        // this.refresh()
        let that = this;
        window.onload = function () {
            that.refresh()
        }
    }

    //遍历latex公式为图片
    // ergodicLatexNodes(){
    //     let latexNodesId = store.state.latexNodesId;
    //     for(var index = 0; index < latexNodesId.length; index++){
    //         let nodeId = latexNodesId[index];
    //         let node = this.getNodeById(nodeId);
    //         if(node.latexContent == null|| node.latexContent==undefined){
    //         continue;
    //         }
    //         this.delegate.latexImg(node.id,node.latexContent.text,node.latexContent.width);
    //     }
    // }

    loadData(data) {
        if (this.delegate != null) {
            this.isElectron = this.delegate.$tools.isElectron()
        }
        if (data != null && data != "" && data.length > 10) {
            this.lastLoadDataTime = Util.getCurrentTime()
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
                let originalDataMap = new HashMap();
                let mind = new MindDateFormat().format(data, originalDataMap);
                this.clearAllDataAndView();
                this.setMindDataFormCache(mind, true, originalDataMap);
                this.setMindMindDisplay(false);
                this.addHostry();
            } else if (this.mindType == MindType.TIME_MAP) {
                let originalDataMap = new HashMap();
                let mind = new TimeLineMindDateFormat().format(data, originalDataMap);
                this.clearAllDataAndView();
                this.setTimeLineMindDataFormCache(mind, true, originalDataMap);
                this.addHostry();
            } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                let originalDataMap = new HashMap();
                let mind = new DoubleBubbleMindDateFormat().format(data, originalDataMap);
                this.clearAllDataAndView();
                this.setDoubleBubbleMindDateFormCache(mind, true, originalDataMap);
                this.addHostry();
            }
            this.checkLatexRefresh(data)
            this.moveRootNodeCenterDisplay(true);

            if (this.delegate.$router.history.current.name == 'gmap') {
                let mindMapId = this.delegate.$route.query.id
                let sheetId = this.delegate.$route.query.sheetId
                let lastLocalMapData = new GroupMindMapData(this.mindType, mindMapId, sheetId);
                lastLocalMapData.setContent(data);
                groupMindSyncAndMerge.get().setLastData(lastLocalMapData);
                this.checkSettingDataSectionId();
            }
        } else {
            this.initData();
            this.refresh(false);
            this.willSave(true);
        };
        
        this.setPreviewImageKey();
    }

    verifyLoadDataMindKey(data, originalDataMap) {
        let name = this.delegate.$route.name
        if (name == "template" || name == "c" || name == "s") {
            return true;
        }
        this.mindkey = data.mindkey;
        if (new Strings().isEmpty(this.mindkey)) {
            return true;
        }
        this.loadDataModel = data;
        this.originalDataMap = originalDataMap;
        this.delegate.showMindKeyInputView();
        return false;
    }

    setMindDataFormCache(data, isVerifyKey = true, originalDataMap) {
        if (isVerifyKey && !this.verifyLoadDataMindKey(data, originalDataMap)) {
            return;
        }
        this.loadDataModel = null;
        this.originalDataMap = new HashMap()
        IdGenerator.shared.resetId();
        this.settingData = data.settingData.copy()
        if (data.rootNode != null) {            
            this.mainMindNodeUnit.globalLayout = data.globalLayout;
            IdGenerator.shared.setSettingData(this.settingData);
            this.mainMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            Config.setTightModeEdgeInsets(this.settingData.tightMode);
            this.mainMindNodeUnit.setLineColors(data.settingData.lineColor);
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.mainMindNodeUnit.setMindMapStyleColor(data.styleIndex)
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
            this.freeNodes.forEach(item => {
                item.setMindBGColor(this.mindBGColor);
                item.setMindDisplayType(data.mindDisplayType);
            });
            this.delegate.changMindBackgroundColor(data.mindBGColor);
        }
        this.mindDisplayType = data.mindDisplayType;
        this.mainMindNodeUnit.mindDisplayType = data.mindDisplayType;
        this.delegate.setMindDisplayType(this.mindDisplayType);
        this.globalLineLayout = data.globalLineLayout;
        this.mainMindNodeUnit.globalLineLayout = data.globalLineLayout;
        data.generalizationLine.forEach(line => {
            this.mainMindNodeUnit.generalizationLineMindElementDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        data.explainData.forEach(cell => {
            this.mainMindNodeUnit.explainMindElementDataDict.put(cell.id, cell);
            IdGenerator.shared.setId(cell.id);
        });
        data.generalizationNode.forEach(node => {
            this.mainMindNodeUnit.setGeneralizationNode(node);
        });
        let rootLineCount = data.rootLine.length;
        for (let nber = 0; nber < rootLineCount; nber++) {
            let line = data.rootLine[nber];
            if (line.lineContent != null) {
                let node = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId);
                let targetLine = this.mainMindNodeUnit.getNodeLine(line.lineContent.targetId);
                IdGenerator.shared.setId(line.id);
                if (!node.isEmpty() &&
                        (targetLine.isEmpty() ||
                        line.type == MindElementType.LAYOUT_CUSTOM_LINE && targetLine.type != MindElementType.LAYOUT_CUSTOM_LINE)) {
                    let isAdd = false;
                    if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        if (node.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                            continue;
                        }
                    } else {                   
                        let values = this.mainMindNodeUnit.lineMindElementDataDict.values();
                        let valuesCount = values.length;
                        for (let index = 0; index < valuesCount; index++) {
                            let lineData = values[index];
                            if ((lineData.type == MindElementType.LINE ||
                                lineData.type == MindElementType.SON_LINE) &&
                                lineData.lineContent != null &&
                                lineData.lineContent.targetId == line.lineContent.targetId &&
                                lineData.parentNodeId == line.parentNodeId) {
                                isAdd = true;
                                break;
                            }
                        }
                    }
                    if (isAdd) {
                        continue;
                    }
                    this.mainMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    this.mainMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        }
        
        data.nodeConnectLinet.forEach(line => {
            if (line.x > 0 && line.y > 0) {
                this.nodeConnectLineDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            }
        });
        data.encircle.forEach(line => {
            this.mainMindNodeUnit.encircleMindElementDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            if (unit.rootNode != null) {
                let freeNode = this.createFreeNodeUnit();
                // console.log(unit);
                freeNode.setRootNode(unit.rootNode);
                freeNode.mindDisplayType = this.mindDisplayType;
                unit.rootLine.forEach(line => {
                    freeNode.lineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                    freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                });
                unit.generalizationLine.forEach(line => {
                    freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                });
                unit.generalizationNode.forEach(node => {
                    freeNode.setGeneralizationNode(node);
                });
                unit.encircle.forEach(line => {
                    freeNode.encircleMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                });
                unit.explainData.forEach(cell => {
                    freeNode.explainMindElementDataDict.put(cell.id, cell);
                    IdGenerator.shared.setId(cell.id);
                });
                if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                    freeNode.setElementLayout(data.globalLayout, true);
                } else {
                    freeNode.setElementLayout(unit.globalLayout, true);
                }
                if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                    freeNode.globalLineLayout = data.globalLineLayout;
                } else {
                    freeNode.globalLineLayout = unit.globalLineLayout;
                }
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
        this.refresh(true, false, false, false);
        this.setFreeDataPointForCache(data.mindOriginDataRelativeRootInfo, data.deviceType, data.deviceDensity, data.uuid);
        let ismove = this.checkMoveCentralPoint(originalDataMap);
        let isChangConnectControl = this.setMindConnectDataFormCache(data.nodeConnectLineControlPointMap, originalDataMap, data.deviceDensity);
        if (isChangConnectControl || ismove) {
            this.refresh(true, true, false, false);
        } else {
            this.drawElements(true, false);
        }
        this.setMindContentCenterDisplay(true);
        this.initDataFont(data.uuid)

        // setTimeout(() => {
        //     let node = this.getNodeById(36964)
        //     console.log("node------node*****", node.value.id, node.value);
        //     this.delegate.checkNodeViewOffScreen(node.value, false)
        // }, 10000);
    }

    checkMoveCentralPoint(originalDataMap) {
        let contentRect = this.getMindContentRect().getRect();

        let leftSpace = (this.editMindmapWidth - contentRect.width()) >> 1;
        let topSpace = (this.editMindmapHeight - contentRect.height()) >> 1;
        if (leftSpace > 0 && topSpace > 0) {
            let moveX = 0;
            let moveY = 0;
            
            if (contentRect.x < leftSpace) {
                moveX = (leftSpace) - contentRect.x;
            }
            if (contentRect.y < topSpace) {
                moveY = (topSpace) - contentRect.y;
            }
            if (this.editMindmapWidth - (contentRect.width() + contentRect.x) < leftSpace) {
                moveX = this.editMindmapWidth - (contentRect.width() + contentRect.x) - (leftSpace);
            }
            if (this.editMindmapHeight - (contentRect.y + contentRect.height()) < topSpace) {
                moveY = this.editMindmapHeight - (contentRect.y + contentRect.height()) - (topSpace);
            }
            if (moveX != 0 || moveY != 0) {
                let encircleMindMap = new HashMap()
                if (this.isFlowChart()) {
                    let encircleMindElementDataDictArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
                    for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                        let key = encircleMindElementDataDictArr[index];
                        encircleMindMap.put(key, this.mainMindNodeUnit.encircleMindElementDataDict.get(key));
                    }
                }
                this.mainMindNodeUnit.moveNodePoint(moveX, moveY);
                this.timeMindTypeNodeUnit.moveNodePoint(moveX, moveY);
                this.doubleBubbleMindNodeUnit.moveNodePoint(moveX, moveY);

                this.freeNodes.forEach(item => {
                    if (item.rootTreeNode != null) {
                        item.rootTreeNode.value.x += moveX;
                        item.rootTreeNode.value.y += moveY;
                    }
                    if (this.isFlowChart()) {
                        let encircleMindElementDataDictArr = item.encircleMindElementDataDict.keys();
                        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                            let key = encircleMindElementDataDictArr[index];
                            encircleMindMap.put(key, item.encircleMindElementDataDict.get(key));
                        }
                    }
                });
        
                let encircleMindMapKeys = encircleMindMap.keys();
                for (let index = 0; index < encircleMindMapKeys.length; index++) {
                    let key = encircleMindMapKeys[index];
                    let mind = encircleMindMap.get(key);
                    mind.x += moveX;
                    mind.y += moveY;
                }
            }
            let originalDataMapValues = originalDataMap.values()
            for (let index = 0; index < originalDataMapValues.length; index++) {
                let cell = originalDataMapValues[index]
                cell.x += moveX;
                cell.y += moveY;        
            }
            this.refresh(true, false, false, false);
            let nodeConnectLineDataDictKeys = this.nodeConnectLineDataDict.keys()
            let nodeConnectLineDataDictLength = nodeConnectLineDataDictKeys.length
            for (let index = 0; index < nodeConnectLineDataDictLength; index++) {
                let key = nodeConnectLineDataDictKeys[index]
                let line = this.nodeConnectLineDataDict.get(key);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                let startData = this.getNodeById(line.parentNodeId);
                let endData = this.getNodeById(line.lineContent.targetId);
                if (startData.isEmpty() || endData.isEmpty()) {
                    continue;
                }
                line.x += moveX
                line.y += moveY
                
                line.lineContent.startControlPointX += moveX;
                line.lineContent.startControlPointY += moveY;

                line.lineContent.endControlPointX += moveX;
                line.lineContent.endControlPointY += moveY;
            }
            return true;
        } else {
            return false;
        }
    }

setMindConnectDataFormCache(nodeConnectLineControlPointMap, originalDataMap, deviceDensity) {
        let isChangConnectControl = false;
        let nodeConnectLineDataDictKey = this.nodeConnectLineDataDict.keys()
        let nodeConnectLineDataDictLength = nodeConnectLineDataDictKey.length
        if (deviceDensity == null) {
            deviceDensity = 1;
        }
        let nodeConnectLineCalcul = new NodeConnectLineCalcul();
        nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        let targetNodeConnectLines = new Array();
        let nodeConnectLines = new Array();

        for (let index = 0; index < nodeConnectLineDataDictLength; index++) {
            let line = this.nodeConnectLineDataDict.get(nodeConnectLineDataDictKey[index]);
            if (line.isEmpty()) {
                continue;
            }
            let startData = this.getNodeById(line.parentNodeId);
            let endData = this.getNodeById(line.lineContent.targetId);
            if (startData.value.type == MindElementType.NODE_CONNECT_LINE ||
                endData.value.type == MindElementType.NODE_CONNECT_LINE) {
                targetNodeConnectLines.push(line);
            } else {
                nodeConnectLines.push(line);
            }
        }
        for (let index = 0; index < targetNodeConnectLines.length; index++) {
            nodeConnectLines.push(targetNodeConnectLines[index]);
        }
        for (let index = 0; index < nodeConnectLines.length; index++) {
            let line = nodeConnectLines[index];
            if (line.isEmpty()) {
                continue;
            }
            let startData = this.getNodeById(line.parentNodeId);
            let endData = this.getNodeById(line.lineContent.targetId);
            let startCopyData = originalDataMap.get(line.parentNodeId);
            let endCopyData = originalDataMap.get(line.lineContent.targetId);
            if (startData.isEmpty() || endData.isEmpty()) {
                continue;
            }
            if (nodeConnectLineControlPointMap.containsKey(line.id)) {
                let controlPoint = nodeConnectLineControlPointMap.get(line.id);
                if (controlPoint.startPoint == null ||
                        controlPoint.endPoint == null) {
                    continue;
                }
                if (controlPoint.originalStartPoint.x > controlPoint.startData.x &&
                        controlPoint.originalStartPoint.x < controlPoint.startData.x + controlPoint.startData.width) {
                    line.lineContent.startControlPointX = startData.value.x +
                            ((controlPoint.originalStartPoint.x - controlPoint.startData.x) / controlPoint.startData.width * startData.value.width);
                } else {
                    line.lineContent.startControlPointX = startData.value.x - controlPoint.startPoint.x;
                }
                if (controlPoint.originalStartPoint.y > controlPoint.startData.y &&
                        controlPoint.originalStartPoint.y < controlPoint.startData.y + controlPoint.startData.height) {
                    line.lineContent.startControlPointY = startData.value.y +
                            ((controlPoint.originalStartPoint.y - controlPoint.startData.y) / controlPoint.startData.height * startData.value.height);
                } else {
                    line.lineContent.startControlPointY = startData.value.y - controlPoint.startPoint.y;
                }

                if (controlPoint.originalEndPoint.x > controlPoint.endData.x &&
                        controlPoint.originalEndPoint.x < controlPoint.endData.x + controlPoint.endData.width) {
                    line.lineContent.endControlPointX = endData.value.x +
                            ((controlPoint.originalEndPoint.x - controlPoint.endData.x) / controlPoint.endData.width * endData.value.width);
                } else {
                    line.lineContent.endControlPointX = endData.value.x - controlPoint.endPoint.x;
                }
                if (controlPoint.originalEndPoint.y > controlPoint.endData.y &&
                        controlPoint.originalEndPoint.y < controlPoint.endData.y + controlPoint.endData.height) {
                    line.lineContent.endControlPointY = endData.value.y +
                            ((controlPoint.originalEndPoint.y - controlPoint.endData.y) / controlPoint.endData.height * endData.value.height);
                } else {
                    line.lineContent.endControlPointY = endData.value.y - controlPoint.endPoint.y;
                }

                let start = line.lineContent.startPoint();
                let end = line.lineContent.endPoint();

                if (startData.value.x != startCopyData.x || startData.value.width != startCopyData.width) {//
                    if (startData.value.width == startCopyData.width) {
                        line.lineContent.startPointX += (startData.value.x - startCopyData.x);
                    } else {
                        let globalX = line.lineContent.startPointX + line.x;
                        let leftDiff = globalX - startCopyData.x;
                        let centerDiff = globalX - (startCopyData.x + startCopyData.width / 2);
                        let rightDiff = globalX - (startCopyData.x + startCopyData.width);
                        let isScale = true;

                        if (Math.abs(leftDiff) == Math.min(Math.min(Math.abs(leftDiff), Math.abs(centerDiff)), Math.abs(rightDiff))) {
                            if (leftDiff < 0) {
                                line.lineContent.startPointX -= (startCopyData.x - startData.value.x);
                                isScale = false;
                            }
                        } else if (Math.abs(rightDiff) == Math.min(Math.min(Math.abs(leftDiff), Math.abs(centerDiff)), Math.abs(rightDiff))) {
                            if (rightDiff > 0) {
                                line.lineContent.startPointX -= (startCopyData.x + startCopyData.width - (startData.value.x + startData.value.width));
                                isScale = false;
                            }
                        } else {
                            if (Math.abs(centerDiff) < new UiUtil().dip2px(3)) {
                                line.lineContent.startPointX -= (startCopyData.x + startCopyData.width/2 - (startData.value.x + startData.value.width / 2));
                                isScale = false;
                            }
                        }
                        if (isScale) {
                            let xScale =  (globalX - startCopyData.x) / startCopyData.width;
                            let isChange = true;
                            if (xScale > -0.05 && xScale < 0.05) {
                                xScale = 0;
                            } else if (xScale > 49.95 && xScale < 50.05) {
                                xScale = 50;
                                isScale = false;
                            } else if (xScale > 99.05 && xScale < 100.05) {
                                xScale = 100;
                            }
                            if (isScale) {
                                let changeX = startData.value.x + (xScale * startData.value.width);
                                line.lineContent.startPointX = changeX - line.x;
                            }
                        }
                    }
                }
                if (startData.value.y != startCopyData.y || startData.value.height != startCopyData.height) {
                    if (startData.value.height == startCopyData.height) {
                        line.lineContent.startPointY += (startData.value.y - startCopyData.y);
                    } else {
                        let globalY = line.lineContent.startPointY + line.y;
                        let topDiff = globalY - startCopyData.y;
                        let centerDiff = globalY - (startCopyData.y + startCopyData.height / 2);
                        let bottomDiff = globalY - (startCopyData.y + startCopyData.height);
                        let isScale = true;

                        if (Math.abs(topDiff) == Math.min(Math.min(Math.abs(topDiff), Math.abs(centerDiff)), Math.abs(bottomDiff))) {
                            if (topDiff < 0) {
                                line.lineContent.startPointY -= (startCopyData.y - startData.value.y);
                                isScale = false;
                            }
                        } else if (Math.abs(bottomDiff) == Math.min(Math.min(Math.abs(topDiff), Math.abs(centerDiff)), Math.abs(bottomDiff))) {
                            if (bottomDiff > 0) {
                                line.lineContent.startPointY -= (startCopyData.y + startCopyData.height - (startData.value.y + startData.value.height));
                                isScale = false;
                            }
                        } else {
                            if (Math.abs(centerDiff) < new UiUtil().dip2px(3)) {
                                line.lineContent.startPointY -= (startCopyData.y + startCopyData.height/2 - (startData.value.y + startData.value.height / 2));
                                isScale = false;
                            }
                        }
                        if (isScale) {
                            let yScale =  (globalY - startCopyData.y) / startCopyData.height;
                            if (yScale > -0.05 && yScale < 0.05) {
                                yScale = 0;
                            } else if (yScale > 49.95 && yScale < 50.05) {
                                yScale = 50;
                                isScale = false;
                            } else if (yScale > 99.05 && yScale < 100.05) {
                                yScale = 100;
                            }
                            if (isScale) {
                                let changeY = startData.value.y + (yScale * startData.value.height);
                                line.lineContent.startPointY = changeY - line.y;
                            }
                        }
                    }
                }
                if (endData.value.x != endCopyData.x || endData.value.width != endCopyData.width) {
                    if (endData.value.width == endCopyData.width) {
                        line.lineContent.endPointX += (endData.value.x - endCopyData.x);
                    } else {
                        let globalX = line.lineContent.endPointX + line.x;
                        let leftDiff = globalX - endCopyData.x;
                        let centerDiff = globalX - (endCopyData.x + endCopyData.width / 2);
                        let rightDiff = globalX - (endCopyData.x + endCopyData.width);
                        let endSpace = line.lineContent.getLineEndMargin()
                        let isScale = true;
                        if (Math.abs(leftDiff) == Math.min(Math.min(Math.abs(leftDiff), Math.abs(centerDiff)), Math.abs(rightDiff))) {
                            if (leftDiff < 0) {
                                line.lineContent.endPointX -= (endCopyData.x - endData.value.x);
                                if (deviceDensity > 0) {
                                    line.lineContent.endPointX -= ((leftDiff) / deviceDensity * (deviceDensity - 1))
                                    line.lineContent.endPointX += (endSpace / deviceDensity * (deviceDensity - 1))
                                }
                                isScale = false;
                            }
                        } else if (Math.abs(rightDiff) == Math.min(Math.min(Math.abs(leftDiff), Math.abs(centerDiff)), Math.abs(rightDiff))) {
                            if (rightDiff > 0) {
                                line.lineContent.endPointX -= (endCopyData.x + endCopyData.width - (endData.value.x + endData.value.width));
                                if (deviceDensity > 0) {
                                    line.lineContent.endPointX -= ((rightDiff) / deviceDensity * (deviceDensity - 1))
                                    line.lineContent.endPointX -= (endSpace / deviceDensity * (deviceDensity - 1))
                                }
                                isScale = false;
                            }
                        } else {
                            if (Math.abs(centerDiff) < 4) {
                                line.lineContent.endPointX -= (endCopyData.x + endCopyData.width/2 - (endData.value.x + endData.value.width / 2));
                                isScale = false;
                            }
                        }

                        if (isScale) {
                            let xScale =  (globalX - endCopyData.x) /  endCopyData.width;

                            if (xScale > -0.05 && xScale < 0.05) {
                                xScale = 0;
                            } else if (xScale > 49.95 && xScale < 50.05) {
                                xScale = 50;
                                isScale = false;
                            } else if (xScale > 99.85 && xScale < 100.05) {
                                xScale = 100;
                            }
                            if (isScale) {
                                let changeX = endData.value.x + (xScale * endData.value.width);
                                line.lineContent.endPointX = changeX - line.x;
                            }
                        }
                    }
                }
                
                if (endData.value.y != endCopyData.y || endData.value.height != endCopyData.height) {
                    if (endData.value.height == endCopyData.height) {
                        line.lineContent.endPointY += (endData.value.y - endCopyData.y);
                    } else {
                        let globalY = line.lineContent.endPointY + line.y;
                        let topDiff = globalY - endCopyData.y;
                        let centerDiff = globalY - (endCopyData.y + endCopyData.height / 2);
                        let bottomDiff = globalY - (endCopyData.y + endCopyData.height);
                        let isScale = true;
                        let endSpace = line.lineContent.getLineEndMargin()
                        if (Math.abs(topDiff) == Math.min(Math.min(Math.abs(topDiff), Math.abs(centerDiff)), Math.abs(bottomDiff))) {
                            if (topDiff < 0) {
                                line.lineContent.endPointY -= (endCopyData.y - endData.value.y);
                                if (deviceDensity > 0) {
                                    line.lineContent.endPointY -= ((topDiff) / deviceDensity * (deviceDensity - 1))
                                    line.lineContent.endPointY += (endSpace / deviceDensity * (deviceDensity - 1))
                                }
                                isScale = false;
                            }
                        } else if (Math.abs(bottomDiff) == Math.min(Math.min(Math.abs(topDiff), Math.abs(centerDiff)), Math.abs(bottomDiff))) {
                            if (bottomDiff > 0) {
                                line.lineContent.endPointY -= (endCopyData.y + endCopyData.height - (endData.value.y + endData.value.height));
                                if (deviceDensity > 0) {
                                    line.lineContent.endPointY -= ((bottomDiff) / deviceDensity * (deviceDensity - 1))
                                    line.lineContent.endPointY -= (endSpace / deviceDensity * (deviceDensity - 1))
                                }
                                isScale = false;
                            }
                        } else {
                            if (Math.abs(centerDiff) < 4) {
                                line.lineContent.endPointY -= (endCopyData.y + endCopyData.height/2 - (endData.value.y + endData.value.height / 2));
                                isScale = false;
                            }
                        }
                        if (isScale) {
                            let yScale =  (globalY - endCopyData.y) / endCopyData.height;
                            if (yScale > -0.05 && yScale < 0.05) {
                                yScale = 0;
                            } else if (yScale > 49.95 && yScale < 50.05) {
                                yScale = 50;
                                isScale = false;
                            } else if (yScale > 99.05 && yScale < 100.05) {
                                yScale = 100;
                            }
                            if (isScale) {
                                let changeY = endData.value.y + (yScale * endData.value.height);
                                line.lineContent.endPointY = changeY - line.y;
                            }
                        }
                    }
                }
                if (line.lineContent.rightAnglePoints != null &&
                        line.lineContent.rightAnglePoints.length > 0) {
                    for (let j = 0; j < line.lineContent.rightAnglePoints.length; j++) {
                        let point = line.lineContent.rightAnglePoints[j];
                        let changeX = false;
                        let changeY = false;
                        if (point.x == start.x) {
                            point.x = line.lineContent.startPointX;
                            changeX = true;
                        }
                        if (point.y == start.y) {
                            point.y = line.lineContent.startPointY;
                            changeY = true;
                        }
                        if (point.x == end.x) {
                            point.x = line.lineContent.endPointX;
                            changeX = true;
                        }
                        if (point.y == end.y) {
                            point.y = line.lineContent.endPointY;
                            changeY = true;
                        }
                        if (!changeX) {
                            if (end.x - start.x != 0 && (line.lineContent.endPointX - line.lineContent.startPointX) != 0) {
                                let sx = (point.x - start.x) / (end.x - start.x);
                                point.x = (sx *  (line.lineContent.endPointX - line.lineContent.startPointX)) + line.lineContent.startPointX;
                            } else {
                                let sy = 1.0;
                                if (Math.abs(line.lineContent.endPointY - line.lineContent.startPointY) > 0 && Math.abs(end.y - start.y) > 0) {
                                    sy = Math.abs(end.y - start.y) / Math.abs(line.lineContent.endPointY - line.lineContent.startPointY);
                                }
                                point.x = line.lineContent.startPointX + ((point.x - start.x) / sy);
                            }
                        }
                        if (!changeY) {
                            if (end.y - start.y != 0 && (line.lineContent.endPointY - line.lineContent.startPointY) != 0) {
                                let sy = (point.y - start.y) / (end.y - start.y);
                                point.y = (sy *  (line.lineContent.endPointY - line.lineContent.startPointY)) + line.lineContent.startPointY;
                            } else {
                                let sy = 1.0;
                                if (Math.abs(line.lineContent.endPointX - line.lineContent.startPointX) > 0 && Math.abs(end.x - start.x) > 0) {
                                    sy = Math.abs(end.x - start.x) / Math.abs(line.lineContent.endPointX - line.lineContent.startPointX);
                                }
                                point.y = line.lineContent.startPointY + ((point.y - start.y) / sy);
                            }
                        }
                    }
                }
                let ignore = new UiUtil().dip2px(6);
                let globalStart = new Point(line.lineContent.startPointX + line.x, line.lineContent.startPointY + line.y);
                let startMargin = line.lineContent.getLineStartMargin();
                let startRect = new CGRect(startData.value.x - startMargin, startData.value.y - startMargin, startData.value.width + startMargin * 2, startData.value.height + startMargin * 2);
                if (Util.containsInRectForPoint(startRect, globalStart)) {
                    if (globalStart.x - ignore < startData.value.x) {
                        globalStart.x = startData.value.x - startMargin;
                    } else if (globalStart.x + ignore > startData.value.x + startData.value.width) {
                        globalStart.x = startData.value.x + startData.value.width + startMargin;
                    }
                    if (globalStart.y - ignore < startData.value.y) {
                        globalStart.y = startData.value.y - startMargin;
                    } else if (globalStart.y + ignore > startData.value.y + startData.value.height) {
                        globalStart.y = startData.value.y + startData.value.height + startMargin;
                    }
                }
                let hitStart = startData.value.hitPoint(globalStart, startMargin);
                let moveX = 0;
                let moveY = 0;
                if (hitStart.x > -1 && hitStart.y > -1 && (globalStart.x != hitStart.x || hitStart.y != hitStart.x)) {
                    let spaceStart = Util.getPointSpacingByCGPoint(globalStart, hitStart);
                    if (spaceStart <= ignore) {
                        let changeStart = line.lineContent.startPoint();
                        line.lineContent.startPointX = hitStart.x - line.x;
                        line.lineContent.startPointY = hitStart.y - line.y;
                        moveX = line.lineContent.startPointX - changeStart.x;
                        moveY = line.lineContent.startPointY - changeStart.y;
                        for (let j = 0; j < line.lineContent.rightAnglePoints.length; j++) {
                            let point = line.lineContent.rightAnglePoints[j];
                            point.x += moveX;
                            point.y += moveY;
                        }
                        if (line.lineContent.endPointX == changeStart.x) {
                            line.lineContent.endPointX = line.lineContent.startPointX;
                        }
                        if (line.lineContent.endPointY == changeStart.y) {
                            line.lineContent.endPointY = line.lineContent.startPointY;
                        }
                    }
                }
                let globalEnd = new Point(line.lineContent.endPointX + line.x, line.lineContent.endPointY + line.y);
                let endMargin = line.lineContent.getLineEndMargin();
                let endRect = new CGRect(endData.value.x - endMargin, endData.value.y - endMargin, endData.value.width + endMargin * 2, endData.value.height + endMargin * 2);
                if (Util.containsInRectForPoint(endRect, globalEnd)) {
                    if (globalEnd.x - ignore < endData.value.x) {
                        globalEnd.x = endData.value.x - endMargin;
                    } else if (globalEnd.x + ignore > endData.value.x + endData.value.width) {
                        globalEnd.x = endData.value.x + endData.value.width + endMargin;
                    }
                    if (globalEnd.y - ignore < endData.value.y) {
                        globalEnd.y = endData.value.y - endMargin;
                    } else if (globalEnd.y + ignore > endData.value.y + endData.value.height) {
                        globalEnd.y = endData.value.y + endData.value.height + endMargin;
                    }
                }
                let hitEnd = endData.value.hitPoint(globalEnd, endMargin);
                if (hitEnd.x > -1 && hitEnd.y > -1 && (globalEnd.x != hitEnd.x || hitEnd.y != hitEnd.x)) {
                    let spaceEnd = Util.getPointSpacingByCGPoint(globalEnd, hitEnd);
                    if (spaceEnd <= ignore) {
                        let changeEnd = line.lineContent.endPoint();
                        line.lineContent.endPointX = hitEnd.x - line.x;
                        line.lineContent.endPointY = hitEnd.y - line.y;

                        moveX = (line.lineContent.endPointX - changeEnd.x) - moveX;
                        moveY = (line.lineContent.endPointY - changeEnd.y) - moveY;
                        for (let j = 0; j < line.lineContent.rightAnglePoints.length; j++) {
                            let point = line.lineContent.rightAnglePoints[j];
                            if (point.x == changeEnd.x) {
                                point.x = line.lineContent.endPointX;
                            } else {
                                point.x += moveX;
                            }
                            if (point.y == changeEnd.y) {
                                point.y = line.lineContent.endPointY;
                            } else {
                                point.y += moveY;
                            }
                        }
                        if (line.lineContent.startPointX == changeEnd.x) {
                            line.lineContent.startPointX = line.lineContent.endPointX;
                        }
                        if (line.lineContent.startPointY == changeEnd.y) {
                            line.lineContent.startPointY = line.lineContent.endPointY;
                        }
                    }
                }
                nodeConnectLineCalcul.checkLinePoint(line);
                isChangConnectControl = true;
            }
        }
        return isChangConnectControl;
    }

    setTimeLineMindDataFormCache(data, isVerifyKey = true, originalDataMap) {
        if (isVerifyKey && !this.verifyLoadDataMindKey(data, originalDataMap)) {
            return;
        }
        this.loadDataModel = null;
        this.originalDataMap = new HashMap()
        if (data == null || data.title == null || data.title == undefined) {
            return
        }
        IdGenerator.shared.resetId();
        this.settingData = data.settingData.copy()
        IdGenerator.shared.setSettingData(this.settingData);
        this.timeMindTypeNodeUnit.title = data.title;
        this.mindDisplayType = data.mindDisplayType == undefined || data.mindDisplayType == null ? MindDisplayType.MindMap : data.mindDisplayType;
        IdGenerator.shared.setId(data.title.id);
        this.timeMindTypeNodeUnit.baseLine = data.baseLine;
        IdGenerator.shared.setId(data.baseLine.id);
        this.mindBGColor = data.mindBGColor;
        Config.setTightModeEdgeInsets(this.settingData.tightMode);
        this.timeMindTypeNodeUnit.setMindBGColor(this.mindBGColor);
        this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
        this.timeMindTypeNodeUnit.setMindMapStyleColor(data.styleIndex);
        this.mindGlobalAudio = data.mindGlobalAudio;
        this.mindBGStripesType = data.mindBGStripesType;
        this.delegate.changMindBackgroundColor(data.mindBGColor);
        data.mainMindElement.forEach(node => {
            this.timeMindTypeNodeUnit.mainMindElementDataDict.put(node.id, node);
            IdGenerator.shared.setId(node.id);
            IdGenerator.shared.setId(node.head.id);
            IdGenerator.shared.setId(node.title.id);
            IdGenerator.shared.setId(node.desc.id);
        });
        this.timeMindTypeNodeUnit.setElementLayout(data.globalLayout);
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            freeNode.setFrameworkModle(unit.frameworkIndex);

            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
                IdGenerator.shared.setId(cell.id);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
        });
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });

        this.refresh(true, false, false);
        this.setFreeDataPointForCache(data.mindOriginDataRelativeRootInfo, data.deviceType, data.deviceDensity, data.uuid);
        let ismove = this.checkMoveCentralPoint(originalDataMap);        
        let isChangConnectControl = this.setMindConnectDataFormCache(data.nodeConnectLineControlPointMap, originalDataMap, data.deviceDensity);
        
        
        if (isChangConnectControl || ismove) {
            this.refresh(true, true, false);
        } else {
            this.drawElements(true);
        }
        let that = this
        setTimeout(function () {
            that.setMindContentCenterDisplay(true);
        }, 100)
        this.initDataFont(data.uuid)
    }

    setFreeDataPointForCache(mindOriginDataRelativeRootInfo, deviceType, deviceDensity, uuid) {
        if (mindOriginDataRelativeRootInfo.isEmpty() ||
            ((deviceType == DeviceType.WEB ||
                deviceType == DeviceType.WINDOWS ||
                deviceType == DeviceType.MAC ||
                deviceType == DeviceType.LINUX) &&
                (uuid != null && uuid != "" && uuid == this.selfUuid) &&
                deviceDensity == new UiUtil().dip2px(1))) {
            return;
        }
        let rootRect = new CGRect();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let rect = new NodesRectCalculation().calcule(this.mainMindNodeUnit.rootTreeNode);
            rootRect = new CGRect(rect.x, rect.y, rect.width(), rect.height());
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            rootRect = this.doubleBubbleMindNodeUnit.getRect();
        } else if (this.mindType == MindType.TIME_MAP) {
            rootRect = new CGRect(this.timeMindTypeNodeUnit.title.x, this.timeMindTypeNodeUnit.title.y,
                this.timeMindTypeNodeUnit.title.width, this.timeMindTypeNodeUnit.title.height)
        }
        if (rootRect.width() <= 1 || rootRect.x <= 1) {
            return;
        }
        if (this.mainMindNodeUnit.rootTreeNode != null &&
            !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            for (let index = 0; index < this.mainMindNodeUnit.rootTreeNode.children.length; index++) {
                let cell = this.mainMindNodeUnit.rootTreeNode.children[index]
                if (cell.isEmpty() ||
                    !cell.value.isFreeLayoutNode ||
                    !mindOriginDataRelativeRootInfo.containsKey(cell.value.id)) {
                    continue;
                }
                let info = mindOriginDataRelativeRootInfo.get(cell.value.id);
                if (info.data == null ||
                    info.data.isEmpty() ||
                    info.referenceData == null ||
                    info.referenceData.isEmpty()) {
                    continue;
                }
                let referenceNode = this.getNodeById(info.referenceData.id);
                if (referenceNode.isEmpty()) {
                    continue;
                }
                let originDataCentre = new CGPoint(info.data.x + info.data.width / 2,
                    info.data.y + info.data.height / 2);
                let originRootCentre = new CGPoint(info.referenceData.x + info.referenceData.width / 2,
                    info.referenceData.y + info.referenceData.height / 2);

                let dataCentre = new CGPoint(cell.value.x + cell.value.width / 2,
                    info.data.y + info.data.height / 2);
                let referenceCentre = new CGPoint(referenceNode.value.x + referenceNode.value.width / 2,
                    referenceNode.value.y + referenceNode.value.height / 2);

                let originReferenceSpace = info.referenceData.width;
                let referenceSpace = referenceNode.value.width;

                let originScaleX = (originDataCentre.x - originRootCentre.x) / originReferenceSpace;
                let originScaleY = (originDataCentre.y - originRootCentre.y) / originReferenceSpace;

                cell.value.x = (originScaleX * referenceSpace + referenceCentre.x) - cell.value.width / 2;
                cell.value.y = (originScaleY * referenceSpace + referenceCentre.y) - cell.value.height / 2;
            }
        }

        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            if (unit.rootTreeNode == null || unit.rootTreeNode.isEmpty()) {
                continue;
            }

            let cell = unit.rootTreeNode
            if (cell.isEmpty() ||
                !mindOriginDataRelativeRootInfo.containsKey(cell.value.id)) {
                continue;
            }
            let info = mindOriginDataRelativeRootInfo.get(cell.value.id);
            if (info.data == null ||
                info.data.isEmpty() ||
                info.referenceData == null ||
                info.referenceData.isEmpty()) {
                continue;
            }
            let referenceNode = this.getNodeById(info.referenceData.id);
            if (referenceNode.isEmpty()) {
                continue;
            }

            let originDataCentre = new CGPoint(info.data.x + info.data.width / 2,
                info.data.y + info.data.height / 2);
            let originRootCentre = new CGPoint(info.referenceData.x + info.referenceData.width / 2,
                info.referenceData.y + info.referenceData.height / 2);

            let dataCentre = new CGPoint(cell.value.x + cell.value.width / 2,
                info.data.y + info.data.height / 2);
            let referenceCentre = new CGPoint(referenceNode.value.x + referenceNode.value.width / 2,
                referenceNode.value.y + referenceNode.value.height / 2);

            let originReferenceSpace = info.referenceData.width;
            let referenceSpace = referenceNode.value.width;

            let originScaleX = (originDataCentre.x - originRootCentre.x) / originReferenceSpace;
            let originScaleY = (originDataCentre.y - originRootCentre.y) / originReferenceSpace;

            cell.value.x = (originScaleX * referenceSpace + referenceCentre.x) - cell.value.width / 2;
            cell.value.y = (originScaleY * referenceSpace + referenceCentre.y) - cell.value.height / 2;
            unit.refreshLayout(true, this.settingData);
        }
        let nodeConnectLineDatas = this.nodeConnectLineDataDict.values();
        for (let index = 0; index < nodeConnectLineDatas.length; index++) {
            let line = nodeConnectLineDatas[index]
            if (line == null || line.isEmpty()) {
                continue;
            }
            if (!mindOriginDataRelativeRootInfo.containsKey(line.id)) {
                continue;
            }
            let info = mindOriginDataRelativeRootInfo.get(line.id);
            if (info.data == null ||
                info.data.isEmpty() ||
                info.referenceData == null ||
                info.referenceData.isEmpty()) {
                continue;
            }
            let referenceNode = this.getNodeById(info.referenceData.id);
            if (referenceNode.isEmpty()) {
                continue;
            }

            // let originDataCentre = new CGPoint(info.data.x + info.data.width / 2,
            //     info.data.y + info.data.height / 2);
            // let originRootCentre = new CGPoint(info.referenceData.x + info.referenceData.width / 2,
            //     info.referenceData.y + info.referenceData.height / 2);

            // let dataCentre = new CGPoint(line.x + line.width / 2,
            //     info.data.y + info.data.height / 2);
            // let referenceCentre = new CGPoint(referenceNode.value.x + referenceNode.value.width / 2,
            //     referenceNode.value.y + referenceNode.value.height / 2);

            // let originReferenceSpace = info.referenceData.width;
            // let referenceSpace = referenceNode.value.width;

            // let originScaleX = (originDataCentre.x - originRootCentre.x) / originReferenceSpace;
            // let originScaleY = (originDataCentre.y - originRootCentre.y) / originReferenceSpace;

            // line.x = (originScaleX * referenceSpace + referenceCentre.x) - line.width / 2;
            // line.y = (originScaleY * referenceSpace + referenceCentre.y) - line.height / 2;
        }
    }

    setFramework(id) {
        if (this.mindType != MindType.LINE_MAP && this.mindType != MindType.TIME_MAP) {
            return;
        }
        let unit = this.findFreeUnit(this.selectedNodeId);
        if (unit != null && unit.rootTreeNode != null && !unit.rootTreeNode.isEmpty()) {
            let rect = unit.getRectWithoutEncircle();
            unit.setFramework(id, this.settingData);
            this.refresh(true);
            let frameworkRect = unit.getRectWithoutEncircle();
            let moveX = rect.x + rect.width()/2 - (frameworkRect.x + frameworkRect.width()/2);
            let moveY = rect.y + rect.height()/2 - (frameworkRect.y + frameworkRect.height()/2);
            this.delegate.moveDrawingBoard(-moveX, -moveY);            
            this.willSave(true);
            this.addHostry();
            return;
        }

        if (this.mindType == MindType.LINE_MAP) {
            if (unit == null) {
                unit = this.mainMindNodeUnit;
            }
            if (unit == null || unit.rootTreeNode == null || unit.rootTreeNode.isEmpty()) {
                return;
            }
            if (unit.rootTreeNode.value.id == this.mainMindNodeUnit.rootTreeNode.value.id) {
                let rootLayout = new MindMapFramework(id).getMindMapRootLayout();
                this.setGlobalLayout(rootLayout);
            }
            let rect = unit.getRectWithoutEncircle();
            unit.setFramework(id, this.settingData);
            this.refresh(true);
            let frameworkRect = unit.getRectWithoutEncircle();
            let moveX = rect.x + rect.width()/2 - (frameworkRect.x + frameworkRect.width()/2);
            let moveY = rect.y + rect.height()/2 - (frameworkRect.y + frameworkRect.height()/2);
            this.delegate.moveDrawingBoard(-moveX, -moveY);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.TIME_MAP) {
            if (this.timeMindTypeNodeUnit.title == null || this.timeMindTypeNodeUnit.title.isEmpty()) {
                return;
            }
            let rect = this.timeMindTypeNodeUnit.getRect(0);

            let rootLayout = new TimeLineFramework(id).getMindMapRootLayout();
            this.setGlobalLayout(rootLayout);
            this.timeMindTypeNodeUnit.setFramework(id, this.settingData);
            this.refresh(true);
            let frameworkRect = this.timeMindTypeNodeUnit.getRect(0);
            let moveX = rect.x + rect.width()/2 - (frameworkRect.x + frameworkRect.width()/2);
            let moveY = rect.y + rect.height()/2 - (frameworkRect.y + frameworkRect.height()/2);
            this.delegate.moveDrawingBoard(-moveX, -moveY);
            this.willSave(true);
            this.addHostry();
        }
    }

    isShowFrameworkSetting() {
        return this.mindType == MindType.LINE_MAP || this.mindType == MindType.TIME_MAP;
    }

    setDoubleBubbleMindDateFormCache(data, isVerifyKey = true, originalDataMap) {
        if (isVerifyKey && !this.verifyLoadDataMindKey(data, originalDataMap)) {
            return;
        }
        this.loadDataModel = null;
        this.originalDataMap = new HashMap()
        IdGenerator.shared.resetId();
        if (data.rootNode != null) {
            this.settingData = data.settingData.copy()
            IdGenerator.shared.setSettingData(this.settingData);
            this.doubleBubbleMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor; 
            this.doubleBubbleMindNodeUnit.setMindBGColor(this.mindBGColor)           
            Config.setTightModeEdgeInsets(this.settingData.tightMode);
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.doubleBubbleMindNodeUnit.setMindMapStyleColor(data.styleIndex);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.delegate.changMindBackgroundColor(data.mindBGColor);
        }
        this.mindDisplayType = data.mindDisplayType == undefined || data.mindDisplayType == null ? MindDisplayType.MindMap : data.mindDisplayType;
        this.globalLineLayout = data.globalLineLayout;
        this.doubleBubbleMindNodeUnit.globalLineLayout = data.globalLineLayout;
        if (data.rootTreeBrotherNode != null) {
            this.doubleBubbleMindNodeUnit.setRootTreeBrotherNode(data.rootTreeBrotherNode);
        }
        this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas = data.commonGroundMindElementDatas;

        data.rootLine.forEach(line => {
            if (line.lineContent != null) {
                let node = this.doubleBubbleMindNodeUnit.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty()) {
                    this.doubleBubbleMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                    this.doubleBubbleMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        });

        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
                IdGenerator.shared.setId(cell.id);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
            if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                freeNode.globalLineLayout = data.globalLineLayout;
            } else {
                freeNode.globalLineLayout = unit.globalLineLayout;
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
        this.refresh(true, false, false);
        this.setFreeDataPointForCache(data.mindOriginDataRelativeRootInfo, data.deviceType, data.deviceDensity, data.uuid);
        let ismove = this.checkMoveCentralPoint(originalDataMap);
        let isChangConnectControl = this.setMindConnectDataFormCache(data.nodeConnectLineControlPointMap, originalDataMap, data.deviceDensity);        
        if (isChangConnectControl || ismove) {
            this.refresh(true, true, false);
        } else {
            this.drawElements(true);
        }
        let contentRect = this.getMindContentRect();
        this.delegate.mindContentDisplay(contentRect);
        this.initDataFont(data.uuid)
    }

    initDataFont(uuid) {
        let fontMap = new HashMap()
        let caluleTextDatas = []
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            var list = [];
            this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list);
            let freeNodesLength = this.freeNodes.length;
            for (let i = 0; i < freeNodesLength; i++) {
                const unit = this.freeNodes[i];
                this.serializeNodeElementsToList(unit.rootTreeNode, list);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (node.value.textContent != null && node.value.textContent.fontFamily != null && node.value.textContent.fontFamily != "") {
                    fontMap.put(node.value.textContent.fontFamily, node.value.textContent.fontFamily)
                    caluleTextDatas.push(node.value)
                } else if (node.value.generalizationContent != null && node.value.generalizationContent.fontFamily != null && 
                    node.value.generalizationContent.fontFamily != "") {
                    fontMap.put(node.value.generalizationContent.fontFamily, node.value.generalizationContent.fontFamily)
                    caluleTextDatas.push(node.value)
                }
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            var list = [];
            this.serializeNodeElementsToListForTimeLine(list);
            for (let index = 0; index < list.length; index++) {
                let data = list[index];
                if (data.textContent != null && data.textContent.fontFamily != null && data.textContent.fontFamily != "") {
                    fontMap.put(data.textContent.fontFamily, data.textContent.fontFamily)
                    caluleTextDatas.push(data)
                } else if (data.generalizationContent != null && data.generalizationContent.fontFamily != null && 
                    data.generalizationContent.fontFamily != "") {
                    fontMap.put(data.generalizationContent.fontFamily, data.generalizationContent.fontFamily)
                    caluleTextDatas.push(data)
                }
            }
            list = [];
            let freeNodesLength = this.freeNodes.length;
            for (let i = 0; i < freeNodesLength; i++) {
                const unit = this.freeNodes[i];
                this.serializeNodeElementsToList(unit.rootTreeNode, list);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (node.value.textContent != null && node.value.textContent.fontFamily != null && node.value.textContent.fontFamily != "") {
                    fontMap.put(node.value.textContent.fontFamily, node.value.textContent.fontFamily)
                    caluleTextDatas.push(node.value)
                } else if (node.value.generalizationContent != null && node.value.generalizationContent.fontFamily != null && 
                    node.value.generalizationContent.fontFamily != "") {
                    fontMap.put(node.value.generalizationContent.fontFamily, node.value.generalizationContent.fontFamily)
                    caluleTextDatas.push(node.value)
                }
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            var list = [];
            this.serializeNodeElementsToListForDoubleBubble(this.doubleBubbleMindNodeUnit.rootTreeNode, list);
            this.serializeNodeElementsToListForDoubleBubble(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode, list);
            let length = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length
            for (let index = 0; index < length; index++) {
                let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
                list.push(data);
            }

            for (let index = 0; index < list.length; index++) {
                let data = list[index];
                if (data.textContent != null && data.textContent.fontFamily != null && data.textContent.fontFamily != "") {
                    fontMap.put(data.textContent.fontFamily, data.textContent.fontFamily)
                    caluleTextDatas.push(data)
                } else if (data.generalizationContent != null && data.generalizationContent.fontFamily != null && 
                    data.generalizationContent.fontFamily != "") {
                    fontMap.put(data.generalizationContent.fontFamily, data.generalizationContent.fontFamily)
                    caluleTextDatas.push(data)
                }
            }
            list = [];
            let freeNodesLength = this.freeNodes.length;
            for (let i = 0; i < freeNodesLength; i++) {
                const unit = this.freeNodes[i];
                this.serializeNodeElementsToList(unit.rootTreeNode, list);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (node.value.textContent != null && node.value.textContent.fontFamily != null && node.value.textContent.fontFamily != "") {
                    fontMap.put(node.value.textContent.fontFamily, node.value.textContent.fontFamily)
                    caluleTextDatas.push(node.value)
                } else if (node.value.generalizationContent != null && node.value.generalizationContent.fontFamily != null && 
                    node.value.generalizationContent.fontFamily != "") {
                    fontMap.put(node.value.generalizationContent.fontFamily, node.value.generalizationContent.fontFamily)
                    caluleTextDatas.push(node.value)
                }
            }
        }

        let loadFonts = fontMap.values()
        if (loadFonts.length > 0) {
            let that = this
            FontLoad.load(loadFonts).then((success) => {
                if (success) {
                    Config.TextSizeCaluleMap.clear()
                    for (let index = 0; index < caluleTextDatas.length; index++) {
                        MindElementCalculation.set(caluleTextDatas[index]).caluleTextForData()
                    }
                    that.refresh(true);
                }
              })
        }
    }

    initData() {
        if (this.delegate != null) {
            this.isElectron = this.delegate.$tools.isElectron()
        }
        let lineColors = this.mindMapStyleColor.getLineColors()
        if (lineColors != null && lineColors.length > 0) {
            this.mainMindNodeUnit.setLineColors(lineColors)
            this.settingData.lineColor = lineColors
        }
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART) {
            this.mainMindNodeUnit.createRootNode();
            if (this.isFlowChart()) {
                this.mainMindNodeUnit.rootTreeNode.value.mindElementShape = MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner;
                this.mainMindNodeUnit.rootTreeNode.value.textContent.text = new UiUtil().getString(StringsLanguage.Mind_Shape_Start_Text)
                MindElementCalculation.set(this.mainMindNodeUnit.rootTreeNode.value).caluleTextForData().calcule(false);
                this.mainMindNodeUnit.refreshLayout(true, this.settingData);
            }
            this.settingData.lineThicken = this.mindMapStyleColor.getSubjectNodeLineFull();
            this.setSelectedId(this.mainMindNodeUnit.rootTreeNode.value.id);
            if (this.mindDisplayType == MindDisplayType.Outline) {
                this.addChildMindElement(false, new UiUtil().getString(StringsLanguage.Mind_Edit_Main_Idea));
            } else if (!this.isEmptyNode) {                
                if (this.isFlowChart()) {
                    this.addFlowChartNode(this.selectedNodeId, null, FlowChartDirection.RIGHT, MindElementShapeType.Corner, false);
                    if (this.freeNodes.length > 0 && this.freeNodes[0].rootTreeNode != null) {
                        this.selectedNodeId = this.freeNodes[0].rootTreeNode.value.id;
                    }
                    this.addFlowChartNode(this.selectedNodeId, null, FlowChartDirection.RIGHT, MindElementShapeType.Corner, false);
                } else {
                    this.addChildMindElement(false);
                    this.addChildMindElement(false);
                    this.addChildMindElement(false);
                    if (this.globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                        this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                        this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                        this.globalLayout == NodeLayoutType.LAYOUT_FREE_TREE) {
                        this.addChildMindElement(false);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                        this.addChildMindElement(false);
                        let mainMindNodeUnitCount = this.mainMindNodeUnit.rootTreeNode.children.length;
                        for (let index = 0; index < mainMindNodeUnitCount; index++) {
                            if (index < 2) {
                                this.setSelectedId(this.mainMindNodeUnit.rootTreeNode.children[index].value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                            } else {
                                this.setSelectedId(this.mainMindNodeUnit.rootTreeNode.children[index].value.id);
                                this.addChildMindElement(false);
                            }
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                        this.globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                        this.globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        this.globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        this.globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                        let len = this.mainMindNodeUnit.rootTreeNode.children.length;
                        for (let index = 0; index < len; index++) {
                            if (index < 2) {
                                this.setSelectedId(this.mainMindNodeUnit.rootTreeNode.children[index].value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                            } else {
                                this.setSelectedId(this.mainMindNodeUnit.rootTreeNode.children[index].value.id);
                                this.addChildMindElement(false);
                            }
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                        let len = this.mainMindNodeUnit.rootTreeNode.children.length;
                        for (let index = 0; index < len; index++) {
                            let child = this.mainMindNodeUnit.rootTreeNode.children[index];
                            child.value.layout = NodeLayoutType.LAYOUT_RIGHT;
                            this.setSelectedId(child.value.id);
                            let sonNode1 = this.addChildMindElement(false);
                            let sonNode2 = this.addChildMindElement(false);
                            sonNode1.value.layout = NodeLayoutType.LAYOUT_RIGHT;
                            sonNode2.value.layout = NodeLayoutType.LAYOUT_RIGHT;
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
                        let len = this.mainMindNodeUnit.rootTreeNode.children.length;
                        for (let index = 0; index < len; index++) {
                            let child = this.mainMindNodeUnit.rootTreeNode.children[index];
    
                            if (index % 2 == 0) {
                                child.value.layout = NodeLayoutType.LAYOUT_TREE_RIGHT;
                            } else {
                                child.value.layout = NodeLayoutType.LAYOUT_TOP_TREE_RIGHT;
                            }
                            this.setSelectedId(child.value.id);
                            let sonNode1 = this.addChildMindElement(false);
                            let sonNode2 = this.addChildMindElement(false);
                            sonNode1.value.layout = NodeLayoutType.LAYOUT_RIGHT;
                            sonNode2.value.layout = NodeLayoutType.LAYOUT_RIGHT;
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        this.mainMindNodeUnit.rootTreeNode.value.alignmentType = MindElementAlignmentType.MIDDLE
                        let len = this.mainMindNodeUnit.rootTreeNode.children.length;
                        for (let index = 0; index < len; index++) {
                            let child = this.mainMindNodeUnit.rootTreeNode.children[index];
                            this.setSelectedId(child.value.id);
                            let sonNode1 = this.addChildMindElement(false);
                            let sonNode2 = this.addChildMindElement(false);
                            if (index == 1) {
                                this.setSelectedId(sonNode1.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                            }
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_RADIATE) {
                        this.mainMindNodeUnit.rootTreeNode.value.alignmentType = MindElementAlignmentType.MIDDLE
                        let len = this.mainMindNodeUnit.rootTreeNode.children.length;
                        this.mainMindNodeUnit.rootTreeNode.value.backgroundColor = Colors.white
                        for (let index = 0; index < len; index++) {
                            let child = this.mainMindNodeUnit.rootTreeNode.children[index];
                            if (index == 0) {
                                this.setSelectedId(child.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                                child.value.backgroundColor = 0x64E3FF
                            } else if (index == 1) {
                                this.setSelectedId(child.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                                child.value.backgroundColor = Colors.white
                            } else {
                                this.setSelectedId(child.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                                child.value.backgroundColor = 0x64E3FF
                            }
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                        this.changeLineColor(Colors.black)
                        this.setTightMode(true)
                        this.settingData.lineThicken = true
                    } else if (this.mindMapStyleColor.currentSelectedIndex == 39) {
                        let len = this.mainMindNodeUnit.rootTreeNode.children.length;
                        for (let index = 0; index < len; index++) {
                            let child = this.mainMindNodeUnit.rootTreeNode.children[index];
                            if (index == 0) {
                                this.setSelectedId(child.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                            } else if (index == 1) {
                                this.setSelectedId(child.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                            } else {
                                this.setSelectedId(child.value.id);
                                this.addChildMindElement(false);
                                this.addChildMindElement(false);
                            }
                        }
                        this.setSelectedId(IdGenerator.INVALID_ID);
                        this.settingData.lineThicken = true
                    }
                }
                
                if (this.mindMapStyleColor.getRootNodeChildDepth() > 1) {
                    for (let index = 0; index < this.mainMindNodeUnit.rootTreeNode.children.length; index++) {
                        let cell = this.mainMindNodeUnit.rootTreeNode.children[index]
                        if (cell.children.length > 0) {
                            continue
                        }
                        this.setSelectedId(cell.value.id)
                        this.addChildMindElement(false);
                        this.addChildMindElement(false);
                    }
                }
            }
            this.setSelectedId(IdGenerator.INVALID_ID);
            this.saveSubtitle();
        } else if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.creatTitle();
            this.saveSubtitle();
        } else if (this.mindType == MindType.BUBBLE_MAP) {
            this.mainMindNodeUnit.createRootNode();
            this.mainMindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_BUBBLE, true);
            this.setSelectedId(this.mainMindNodeUnit.rootTreeNode.value.id);
            if (!this.isEmptyNode) {
                this.addChildMindElement(false);
                this.addChildMindElement(false);
                this.addChildMindElement(false);
                this.addChildMindElement(false);
                this.addChildMindElement(false);
                this.addChildMindElement(false);
            }
            this.setSelectedId(IdGenerator.INVALID_ID);
            this.saveSubtitle();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.doubleBubbleMindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_DOUBLE_BUBBLE);
            this.globalLayout = NodeLayoutType.LAYOUT_DOUBLE_BUBBLE;
            this.doubleBubbleMindNodeUnit.createRootNode();
            this.setSelectedId(this.doubleBubbleMindNodeUnit.rootTreeNode.value.id);
            this.addChildMindElement(false);
            this.addChildMindElement(false);
            this.setSelectedId(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.value.id);
            this.addChildMindElement(false);
            this.addChildMindElement(false);
            this.setSelectedId(IdGenerator.INVALID_ID);
            this.saveSubtitle();
        }
    }
    saveSubtitle() {
        new StorageManager().get().saveMyMindSubtitle(this.mindPreviewID, this.getSubtitle());
    }

    getSubtitle() {
        let subtitle = "";
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART) {
            subtitle = this.mainMindNodeUnit.getSubtitle();

        } else if (this.mindType == MindType.TIME_MAP) {
            subtitle = this.timeMindTypeNodeUnit.getSubtitle();
        } else if (this.mindType == MindType.BUBBLE_MAP) {
            subtitle = this.mainMindNodeUnit.getSubtitle();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            subtitle = this.doubleBubbleMindNodeUnit.getSubtitle();
        }
        return subtitle;
    }

    getTitle() {
        let title = "";
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART) {
            title = this.mainMindNodeUnit.getTitle();

        } else if (this.mindType == MindType.TIME_MAP) {
            title = this.timeMindTypeNodeUnit.getTitle();
        } else if (this.mindType == MindType.BUBBLE_MAP) {
            title = this.mainMindNodeUnit.getTitle();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            title = this.doubleBubbleMindNodeUnit.getTitle();
        }
        if (title.length == 0) {
            title = new UiUtil().getString(StringsLanguage.About_App_Name);
        }
        title = title.replace(/\n|\r/g,"");
        return title;
    }

    savaEnterMindmapTimes() {

    }

    registerDependent() {
        this.mainMindNodeUnit.delegate = this;
        this.timeMindTypeNodeUnit.delegate = this;
        this.doubleBubbleMindNodeUnit.delegate = this;
        this.mainMindNodeUnit.setElementLayout(this.getGlobalLayout(), true);
        this.mainMindNodeUnit.globalLineLayout = this.globalLineLayout;
        this.timeMindTypeNodeUnit.setElementLayout(this.getGlobalLayout());
        this.doubleBubbleMindNodeUnit.globalLineLayout = this.globalLineLayout;
        this.freeNodes.forEach(unit => {
            unit.delegate = this;
            unit.setElementLayout(this.getGlobalLayout(), true);
            unit.globalLineLayout = this.globalLineLayout;
        });
    }

    onTextSizeChanged(text, width, height, id, type) {

        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        this.settingData.addChangeData(node.value.id);
        if (type == GlobalInputTextType.NodeExplain) {
            node.value.explain = text;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        } else if (node.value.type == MindElementType.BAOWEI_VIEW) {
            var textContent = node.value.lineContent.textContent;
            if (textContent == null) {
                textContent = new TextContent(text);
                if (Colors.isClear(node.value.lineContent.strokeColor)) {
                    textContent.textColor = 0x333333;
                } else if (Colors.isDarkColor(node.value.lineContent.strokeColor)) {
                    textContent.textColor = 0xffffff;
                } else {
                    textContent.textColor = 0x333333;
                }
                textContent.textFontSize = 14;
            }
            textContent.text = text;
            let size = MindElementCalculation.set().caluleText(text, textContent.textFontSize, textContent.textBold);
            textContent.width = size.getWidth() + new UiUtil().dip2px(10);
            textContent.height = size.getHeight() + new UiUtil().dip2px(8);
            node.value.lineContent.textContent = textContent;


            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        } else if (node.value.type == MindElementType.NODE_CONNECT_LINE && node.value.lineContent.textContent != null) {
            node.value.lineContent.textContent.text = text;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        } else if (type == GlobalInputTextType.NodeTitleInput && node.value.titleContent != null) {
            node.value.titleContent.text = text;
            MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        }


        if (node.value.textContent != null && text == node.value.textContent.text) {
            return;
        }


        let previewNode = node.copy();
        node = new LineMindTypeNode();
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            node = unit.onTextSizeChanged(text, width, height, id);
            if (!node.isEmpty()) {
                break
            }
        }
        if (node.isEmpty()) {
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
                node = this.mainMindNodeUnit.onTextSizeChanged(text, width, height, id);
                if (node.value.type == MindElementType.SUBJECT) {
                    this.saveSubtitle();
                }
            } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                node = this.doubleBubbleMindNodeUnit.onTextSizeChanged(text, width, height, id);
                if (node.value.type == MindElementType.SUBJECT) {
                    (new StorageManager).get().saveMyMindSubtitle(this.mindPreviewID, this.doubleBubbleMindNodeUnit.getSubtitle());
                }
            } else if (this.mindType == MindType.TIME_MAP) {
                let data = this.timeMindTypeNodeUnit.onTextSizeChanged(text, width, height, id);
                if (!data.isEmpty()) {
                    node = new LineMindTypeNode(data);
                }
            }
        }
        if (this.isRootNode(node.value.id)) {
            node.value.x = previewNode.value.x - (node.value.width - previewNode.value.width) / 2
            node.value.y = previewNode.value.y - (node.value.height - previewNode.value.height) / 2
        }
        this.checkNodeConnectLinePointByChangeSize(previewNode.value, node.value);
        this.dataConnectLinePoint(node.value, previewNode.value);        
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }
    //当entity为导图时有效
    getSubtitle() {
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            return this.mainMindNodeUnit.getSubtitle();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return this.doubleBubbleMindNodeUnit.getSubtitle();
        } else if (this.mindType == MindType.TIME_MAP) {
            return this.timeMindTypeNodeUnit.getSubtitle()
        } else {
            return "";
        }
    }

    addTextInFreeNode(text, point, id) {
        let node = this.getNodeById(id);
        if (!node.isEmpty()) {
            if (node.value.textContent != null && (node.value.type == MindElementType.MAIN_SUBJECT ||
                node.value.type == MindElementType.SUBJECT ||
                node.value.type == MindElementType.SON_SUBJECT)) {
                if (this.Util.isEditInputSelected(node.value.textContent.text)) {
                    node.value.textContent.text = text;
                } else {
                    node.value.textContent.text = node.value.textContent.text + " " + text;
                }
                MindElementCalculation.set(node.value).caluleTextForData();
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            } else if (node.value.generalizationContent != null && node.value.type == MindElementType.CONTENT_GENERALIZATION) {
                if (this.Util.isEditInputSelected(node.value.generalizationContent.text)) {
                    node.value.generalizationContent.text = text;
                } else {
                    node.value.generalizationContent.text = node.value.generalizationContent.text + " " + text;
                }
                MindElementCalculation.set(node.value).caluleTextForData();
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }
        } else {
            node = this.createFreeNode(0);
            if (node.value.textContent != null) {
                node.value.textContent.text = text;
            }
            MindElementCalculation.set(node.value).caluleTextForData();
            node.value.x = point.x - node.value.width / 2;
            node.value.y = point.y - node.value.height / 2;

            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    addImage(imageUrl, id, width, height, originUrl, point) {
        var node = this.getNodeById(id);
        var isAddFreeNode = false;
        if (node.isEmpty()) {
            node = this.createFreeNode(0);
            if (node.value.textContent != null) {
                node.value.textContent.text = "";
            }
            isAddFreeNode = true;
        }
        var imageWidth = width;
        var imageHeight = height;
        let limitSize = 300.0;
        if (this.isFlowChart()) {
            if (node.value.width >= width) {
                limitSize = width
            } else {
                limitSize = 150.0
            }
        }
        if (width > limitSize || height > limitSize) {
            let aspectWidth = limitSize / width;
            let aspectHeight = limitSize / height;
            let aspectRatio = Math.min(aspectWidth, aspectHeight);
            imageWidth = width * aspectRatio;
            imageHeight = height * aspectRatio;
        }
        let preData = node.value.copy();
        let imageContent = new ImageContent(imageUrl, imageWidth, imageHeight);
        if (this.mindType == MindType.TIME_MAP) {
            imageContent.height = imageWidth;
            imageContent.width = imageHeight;
            imageContent.layoutType = ImageLayoutType.LAYOUT_LEFT;
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || 
            this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            imageContent.layoutType = ImageLayoutType.LAYOUT_TOP;
        }
        if (Math.abs(imageWidth - imageHeight) < 20) {
            imageContent.shapeType = ImageShapeType.Square
        } else {
            imageContent.shapeType = ImageShapeType.Square
            if (isAddFreeNode) {
                node.value.mindElementShape = MindElementShapeType.Corner;
            }
        }
        if ((node.value.mindElementShape == MindElementShapeType.Semicircle) &&
            (imageContent.width > 120 || imageContent.height > 120)) {
            node.value.mindElementShape = MindElementShapeType.Corner
        }
        node.value.imageContent = imageContent;
        MindElementCalculation.set(node.value).calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);

        if (isAddFreeNode) {
            let unit = this.findFreeUnit(node.value.id);
            let targetRect = new CGRect(0, 0, 100, 50);
            if (node != null && !node.isEmpty()) {
                node.value.x = 4000;
                node.value.y = 4000;
            }
            if (unit != null) {
                unit.refreshLayout(true, this.settingData);
                targetRect = unit.getRect();
            } else {
                targetRect = new NodesRectCalculation().calcule(node);
            }
            if (point == null || point.x <= 0 || point.y <= 0) {
                point = this.delegate.getMindMapCentralPoint();
            }
            let showPoint = point
            if (showPoint != null) {
                let freeNodesWithoutTarget = [];
                for (let index = 0; index < this.freeNodes.length; index++) {
                    const element = this.freeNodes[index];
                    if (element.rootTreeNode != null && element.rootTreeNode.value.id != node.value.id) {
                        freeNodesWithoutTarget.push(element);
                    }
                }
                let mindElementHitCalculation = new MindElementHitCalculation();
                mindElementHitCalculation.setData(this.mainMindNodeUnit,
                    freeNodesWithoutTarget,
                    [],
                    this.timeMindTypeNodeUnit, this.doubleBubbleMindNodeUnit);
                let rect = mindElementHitCalculation.getMapBlankSpace(
                    new CGRect((showPoint.x - targetRect.width() / 2),
                                (showPoint.y - targetRect.height() / 2),
                                targetRect.width(),
                                targetRect.height())
                );
                
                node.value.x = rect.x + (node.value.x - targetRect.x);
                node.value.y = rect.y + (node.value.y - targetRect.y);
            }
        } else {
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setNodeToCard(id = -1, isRefresh = true) {
        if (id == -1) {
            id = this.selectedNodeId
        }        
        var node = this.getNodeById(id);
        if (node.isEmpty()) {
            return
        }
        node.value.mindElementShape = MindElementShapeType.Card;
        node.value.checkShapeCard(this.mindBGColor)
        if (isRefresh) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    //添加上传的视频
    addVideo(videoUrl, imageUrl, id, width, height, origin, point) {
        var node = this.getNodeById(id);
        var isAddFreeNode = false;
        if (!this.isNodeAddImage(node)) {
            node = this.createFreeNode(0);
            if (node.value.textContent != null) {
                node.value.textContent.text = "";
            }
            isAddFreeNode = true;
        }
        var imageWidth = width;
        var imageHeight = height;
        let limitSize = 300.0;
        if (this.isFlowChart()) {
            if (node.value.width >= width) {
                limitSize = width
            } else {
                limitSize = 150.0
            }
        }
        if (width > limitSize || height > limitSize) {
            let aspectWidth = limitSize / width;
            let aspectHeight = limitSize / height;
            let aspectRatio = Math.min(aspectWidth, aspectHeight);
            imageWidth = width * aspectRatio;
            imageHeight = height * aspectRatio;
        }
        let preData = node.value.copy();
        let imageContent = new ImageContent(imageUrl, imageWidth, imageHeight);
        if (videoUrl != null) {
            imageContent.videoUrl = videoUrl;
        }
        if (this.mindType == MindType.TIME_MAP) {
            imageContent.height = imageWidth;
            imageContent.width = imageHeight;
            imageContent.layoutType = ImageLayoutType.LAYOUT_LEFT;
        } else if (this.mindType == MindType.LINE_MAP ||
                this.mindType == MindType.FLOW_CHART || 
                this.mindType == MindType.BUBBLE_MAP || 
                this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            imageContent.layoutType = ImageLayoutType.LAYOUT_TOP;
        }
        if (Math.abs(imageWidth - imageHeight) < 20) {
        } else {
            imageContent.shapeType = ImageShapeType.Square
            if (isAddFreeNode) {
                node.value.mindElementShape = MindElementShapeType.Corner;
            }
        }
        if (node.value.mindElementShape == MindElementShapeType.Semicircle) {
            node.value.mindElementShape = MindElementShapeType.Corner
        }
        node.value.imageContent = imageContent;
        MindElementCalculation.set(node.value).calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value);
        }
        this.dataConnectLinePoint(node.value, preData);
        if (isAddFreeNode) {
            if (point == null || point.x <= 0 || point.y <= 0) {
                point = this.delegate.getMindMapCentralPoint();

                let mindElementHitCalculation = new MindElementHitCalculation();
                mindElementHitCalculation.setData(this.mainMindNodeUnit,
                    this.freeNodes,
                    this.nodeConnectLineDataDict,
                    this.timeMindTypeNodeUnit,
                    this.doubleBubbleMindNodeUnit);
                let rect = mindElementHitCalculation.getMapBlankSpace(new CGRect(point.x - node.value.width / 2,
                    point.y - node.value.height / 2,
                    node.value.width, node.value.height));
                point.x = rect.x + rect.width() / 2;
                point.y = rect.y + rect.height() / 2;
            }
            node.value.x = point.x - node.value.width / 2;
            node.value.y = point.y - node.value.height / 2;
        } else {
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }
    //添加素材库图片
    addMaterialImage(serverUrl, id, width, height, point) {
        let node = this.getNodeById(id);
        var isAddFreeNode = false;
        if (node.isEmpty()) {
            node = this.createFreeNode(0);
            if (node.value.textContent != null) {
                node.value.textContent.text = "";
            }
            isAddFreeNode = true;
        }
        var imageWidth = width;
        var imageHeight = height;
        let limitSize = 300.0;
        if (this.isFlowChart()) {
            if (node.value.width >= width) {
                limitSize = width
            } else {
                limitSize = 150.0
            }
        }
        if (width > limitSize || height > limitSize) {
            let aspectWidth = limitSize / width;
            let aspectHeight = limitSize / height;
            let aspectRatio = Math.min(aspectWidth, aspectHeight);
            imageWidth = width * aspectRatio;
            imageHeight = height * aspectRatio;
        }
        let preData = node.value.copy();
        let imageContent = new ImageContent(serverUrl, imageWidth, imageHeight);
        if (this.mindType == MindType.TIME_MAP) {
            imageContent.height = imageWidth;
            imageContent.width = imageHeight;
            imageContent.layoutType = ImageLayoutType.LAYOUT_LEFT;
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || 
            this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            imageContent.layoutType = ImageLayoutType.LAYOUT_TOP;
        }
        imageContent.shapeType = ImageShapeType.Square;
        if (isAddFreeNode) {
            node.value.mindElementShape = MindElementShapeType.Corner;
        }
        node.value.imageContent = imageContent;
        MindElementCalculation.set(node.value).calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);
        if (isAddFreeNode) {
            if (point == null || point.x <= 0 || point.y <= 0) {
                point = this.delegate.getMindMapCentralPoint();

                let mindElementHitCalculation = new MindElementHitCalculation();
                mindElementHitCalculation.setData(this.mainMindNodeUnit,
                    this.freeNodes,
                    this.nodeConnectLineDataDict,
                    this.timeMindTypeNodeUnit,
                    this.doubleBubbleMindNodeUnit);
                let rect = mindElementHitCalculation.getMapBlankSpace(new CGRect(point.x - node.value.width / 2,
                    point.y - node.value.height / 2,
                    node.value.width, node.value.height));
                point.x = rect.x + rect.width() / 2;
                point.y = rect.y + rect.height() / 2;
            }
            node.value.x = point.x - node.value.width / 2;
            node.value.y = point.y - node.value.height / 2;
        } else {
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }


    checkMoveMap(previewNode, node) {
        if (node.isEmpty()) {
            return;
        }
        if (previewNode.isEmpty()) {
            return;
        }

        this.delegate.moveDrawingBoard((node.value.width + node.value.x - previewNode.value.x - previewNode.value.width),
            (node.value.height + node.value.y - previewNode.value.y - previewNode.value.height), node.value);
    }


    setNodeViewToScreen(data) {
        if (data == null || data.isEmpty()) {
            return;
        }
        this.delegate.checkNodeViewToScreen(data);
    }

    checkMouseMove(point) {
        if (point == null ||
            point.x <= 0 ||
            point.y <= 0) {
            return;
        }
        let mindElementHitCalculation = new MindElementHitCalculation();
        mindElementHitCalculation.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);

        let data = mindElementHitCalculation.hitLine(point);
        this.delegate.lineViewHoverView(data)
        if (this.isAddNodeConnectLine) {
            let line = this.getCheckNodeConnectLineData(this.getNodeById(this.selectedNodeId), point);
            if (line != null && !line.isEmpty()) {
                this.mindNodeViewAddAndChange(line, true, this.globalLayout);
                return true
            } else {
                this.removeViewByMindData(this.checkNodeConnectLineData);
            }
        } else {
            this.removeViewByMindData(this.checkNodeConnectLineData);
        }
        return false
    }

    isCurrentAddNodeConnectLine() {
        return this.isAddNodeConnectLine
    }

    onSelected(data) {
        if (data == null || data.isEmpty()) {
            return
        }
        this.selectedList = []
        this.clearAddFreeNodeStatus();
        if (this.isAddNodeConnectLine) {
            this.removeViewByMindData(this.checkNodeConnectLineData);
            this.isAddNodeConnectLine = false;
            let line = this.createNodeConnectLine(this.selectedNodeId, data.id);
            if (!line.isEmpty()) {
                this.nodeConnectLineDataDict.put(line.id, line);
                this.refresh(true);
                this.willSave(true);
                this.addHostry();

                setTimeout(() => {
                    this.delegate.onSelected(line, new Point(-1, -1));
                    this.delegate.removeConnectLineShapeView();
                }, 100);
            }
        }
        this.setSelectedId(data.id);
    }

    onClose() {
        IdGenerator.shared.resetId();
        this.delegate.close();
    }

    removeNode() {
        if (this.selectedList != null &&
            !this.selectedList.isEmpty() &&
            this.mindDisplayType == MindDisplayType.MindMap) {
            let selectedCount = this.selectedList.length
            this.clearAddFreeNodeStatus();
            for (let index = 0; index < selectedCount; index++) {
                let selectedData = this.selectedList[index]
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.EXPLAIN ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION ||
                    selectedData.type == MindElementType.NODE_CONNECT_LINE ||
                    selectedData.type == MindElementType.BAOWEI_VIEW) {
                    this.addRemoveDataIdToSettingData(this.getNodeById(selectedData.id));
                }
            }
            for (let index = 0; index < selectedCount; index++) {
                let selectedData = this.selectedList[index]
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.EXPLAIN ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION ||
                    selectedData.type == MindElementType.NODE_CONNECT_LINE ||
                    selectedData.type == MindElementType.BAOWEI_VIEW) {
                    this.removeData(selectedData.id, false)
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else {
            this.addRemoveDataIdToSettingData(this.getNodeById(this.selectedNodeId));
            this.removeNodeById(this.selectedNodeId)
        }
    }

    removeNodeById(id) {
        this.clearAddFreeNodeStatus();
        if (id == IdGenerator.INVALID_ID || id == IdGenerator.ROOT_ID) {
            return;
        }
        if (this.mindDisplayType == MindDisplayType.Outline) {
            let nextSelectedNode = this.mainMindNodeUnit.getUpperLevelNode(id);

            if (nextSelectedNode.isEmpty()) {

                if (this.freeNodes.length > 0) {

                    for (let index = 0; index < this.freeNodes.length; index++) {
                        nextSelectedNode = this.freeNodes[index].getUpperLevelNode(id);
                        if (!nextSelectedNode.isEmpty()) {

                            break
                        }
                    }
                }
            }
            if (!nextSelectedNode.isEmpty()) {
                this.outlineBecomeFirstResponder(nextSelectedNode.value.id)
            }
        }
        let nodeIndex = -1
        let node = this.getNodeById(id)  
        let parentNode = this.getNodeById(node.value.parentNodeId)   
        if (!node.isEmpty() && !parentNode.isEmpty()) {            
            for (let index = 0; index < parentNode.children.length; index++) {
                const child = parentNode.children[index];
                if (child.value.id == id) {
                    nodeIndex = index;
                    break
                }
            }
        }
          
        this.removeData(id);
        
        if (!node.isEmpty()) {
            
            if (node.value.type == MindElementType.EXPLAIN ||
                node.value.type == MindElementType.MAIN_SUBJECT ||
                node.value.type == MindElementType.SUBJECT ||
                node.value.type == MindElementType.SON_SUBJECT ||
                node.value.type == MindElementType.NODE_CONNECT_LINE || 
                node.value.type == MindElementType.BAOWEI_VIEW) {
                if (nodeIndex > 0 && !parentNode.isEmpty()) {
                    let targetNode = parentNode.children[nodeIndex - 1]
                    if (!targetNode.isEmpty()) {
                        this.delegate.onSelected(targetNode.value, new Point(-1, -1));
                        this.delegate.checkNodeViewToScreen(targetNode.value)
                    }
                } else {
                    let targetNode = this.getNodeById(node.value.parentNodeId)
                    if (!targetNode.isEmpty()) {
                        this.delegate.onSelected(targetNode.value, new Point(-1, -1));
                        this.delegate.checkNodeViewToScreen(targetNode.value)
                    }
                }                
            } else if (node.value.type == MindElementType.CONTENT_GENERALIZATION && 
                    node.value.generalizationContent != null) {
                for (let index = 0; index < node.value.generalizationContent.targetIds.length; index++) {
                    const element = node.value.generalizationContent.targetIds[index];
                    let targetNode = this.getNodeById(element)
                    if (!targetNode.isEmpty()) {
                        this.delegate.onSelected(targetNode.value, new Point(-1, -1));
                        this.delegate.checkNodeViewToScreen(targetNode.value)
                        break
                    }
                } 
                
            }
        }
    }

    outlineBecomeFirstResponder(id) {
        if (this.mindDisplayType != MindDisplayType.Outline) {
            return;
        }
        this.setSelectedId(id)
        this.delegate.onInputBecomeFirstResponder(id)
    }

    removeData(id, isRefreshAndSave = true) {
        try {
            let node = this.getNodeById(id);
            if (node.isEmpty() || node.value.type == MindElementType.MAIN_SUBJECT) {
                if (isRefreshAndSave) {
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                }
                return;
            }

            this.mainMindNodeUnit.removeElementNode(node.value.id);
            this.timeMindTypeNodeUnit.removeElementNode(node.value.id);
            this.doubleBubbleMindNodeUnit.removeElementNode(node.value.id);
            if (this.freeNodes.length > 0) {
                for (let index = 0; index < this.freeNodes.length; index++) {
                    let freeNode = this.freeNodes[index].getNodeById(node.value.id);
                    if (!freeNode.isEmpty()) {
                        this.freeNodes[index].removeElementNode(node.value.id);
                        if (freeNode.value.type != MindElementType.CONTENT_GENERALIZATION &&
                            freeNode.value.parentNodeId == IdGenerator.INVALID_ID) {
                            this.checkRemoveEncircleLine(this.freeNodes[index]);
                            this.freeNodes[index].removeUselessElement();
                            this.freeNodes.remove(index);
                            break;
                        }
                    }
                }
            }
            if (this.nodeConnectLineDataDict.containsKey(node.value.id)) {
                this.removeViewByMindData(this.nodeConnectLineDataDict.get(node.value.id));
                this.nodeConnectLineDataDict.remove(node.value.id);
            }
            if (node.value.type == MindElementType.SUBJECT) {
                this.saveSubtitle();
            }
            if (isRefreshAndSave) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }
        } catch (e) {
            console.log(e);
        }
    }

    removeImage() {
        this.clearAddFreeNodeStatus();
        let node = this.getNodeById(this.selectedNodeId)
        if (node.isEmpty() ||
            node.value.imageContent == null ||
            node.value.imageContent.key == null ||
            node.value.imageContent.key.length == 0) {
            return;
        }
        let preData = node.value.copy();
        //TODO 发送移出图片协议node.value.imageContent.key
        let obj = {};
        obj.imageUrl = node.value.imageContent.key
        this.delegate.deletMindImageRequest(obj);
        this.mainMindNodeUnit.removeImage(this.selectedNodeId);
        this.timeMindTypeNodeUnit.removeImage(this.selectedNodeId);
        this.doubleBubbleMindNodeUnit.removeImage(this.selectedNodeId);
        this.freeNodes.forEach(item => {
            item.removeImage(this.selectedNodeId);
        });
        MindElementCalculation.set(node.value).calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);
        
        let isIncludeLink = node.value.isContainLinkContent()
        let isIncludeRemarks = node.value.isContainRemarksContent()
        let isIncludeConnectMap = node.value.isContainConnectMapContent()
        let isIncludeIcon = node.value.iconElementContents != null && node.value.iconElementContents.length > 0
        let isTaskContent = node.value.taskContent != null && !new Strings().isEmpty(node.value.taskContent.getContentText())
        let isLatextContent = node.value.isContainLatextContent()
        var isIncludeText = true
        if (node.value.textContent != null) {
            isIncludeText = !(new Strings()).isEmpty(node.value.textContent.text)
        } else if (node.value.generalizationContent != null) {
            isIncludeText = !(new Strings()).isEmpty(node.value.generalizationContent.text)
        }

        if (this.isRootNode(node.value.id) &&
            !isIncludeLink &&
            !isIncludeRemarks &&
            !isIncludeConnectMap &&
            !isIncludeIcon &&
            !isTaskContent &&
            !isLatextContent &&
            !isIncludeText &&
            node.children.length == 0 &&
            this.timeMindTypeNodeUnit.getNodeById(node.value.id).isEmpty()) {
            this.addRemoveDataIdToSettingData(node);
            this.removeData(node.value.id, false)
        }

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    removeVideo() {
        this.clearAddFreeNodeStatus();
        let node = this.getNodeById(this.selectedNodeId)
        if (node.isEmpty() ||
            node.value.imageContent == null ||
            node.value.imageContent.key == null ||
            node.value.imageContent.key.length == 0) {
            return;
        }
        let preData = node.value.copy();
        //TODO 发送移出图片协议node.value.imageContent.key
        // let obj = [];
        // obj[0] = node.value.imageContent.videoUrl;

        let objImg = {};
        objImg.imageUrl = node.value.imageContent.key
        // this.delegate.deletMindVideoRequest(obj);
        this.delegate.deletMindImageRequest(objImg);
        this.mainMindNodeUnit.removeImage(this.selectedNodeId);
        this.timeMindTypeNodeUnit.removeImage(this.selectedNodeId);
        this.doubleBubbleMindNodeUnit.removeImage(this.selectedNodeId);
        this.freeNodes.forEach(item => {
            item.removeImage(this.selectedNodeId);
        });
        this.checkNodeConnectLinePointByChangeSize(preData, node.value);
        this.dataConnectLinePoint(node.value, preData);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    clearSelected(isFresh = true) {
        this.selectedList = [];
        this.setSelectedId(IdGenerator.INVALID_ID);
        this.timeMindTypeNodeUnit.clearSelected();
        this.mainMindNodeUnit.clearSelected();
        this.freeNodes.forEach(node => {
            node.clearSelected();
        });
        if (isFresh) {
            this.refresh(true);
        }
    }

    setSelectedId(id) {
        this.selectedNodeId = id;
    }

    addFlowChartNode(id, point, direction = FlowChartDirection.RIGHT, shape = MindElementShapeType.Corner, isRefresh = true, text = "") {
        if (!this.isFlowChart()) {
            if (id != null && id > -1) {
                this.addChildMindElement(true, "", id)
            }
            return new LineMindTypeNode();
        }
        let node = this.createFreeNode(0);
        node.value.mindElementShape = shape;
        node.value.alignmentType = MindElementAlignmentType.MIDDLE
        if (text != null && text.length > 0) {
            node.value.textContent.text = text            
            MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
            if (node.value.width > 160) {
                node.value.customWidth = 160
                MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
            }
        } else {
            MindElementCalculation.set(node.value).calcule(false);
        }
        
        let targertId = id;
        let showPoint = point;
        let targetNode = this.getNodeById(targertId);
        let toDirection = FlowChartDirection.UNKNOWN
        if ((point == null || point.x <= 0 || point.y <= 0) && !targetNode.isEmpty()) {
            let horizontalSpace = new UiUtil().dip2px(100);
            let verticalSpace = new UiUtil().dip2px(50);
            showPoint = new Point()
            switch(direction) {
                case FlowChartDirection.LEFT:
                    showPoint.x = targetNode.value.x - node.value.width - horizontalSpace;
                    showPoint.y = targetNode.value.y + (targetNode.value.height - node.value.height) / 2;
                    toDirection = FlowChartDirection.RIGHT
                    break
                case FlowChartDirection.TOP:
                    showPoint.x = targetNode.value.x + (targetNode.value.width - node.value.width) / 2
                    showPoint.y = targetNode.value.y - node.value.height - verticalSpace;
                    toDirection = FlowChartDirection.BOTTOM
                    break
                case FlowChartDirection.RIGHT:
                    showPoint.x = targetNode.value.x + targetNode.value.width + horizontalSpace;
                    showPoint.y = targetNode.value.y + (targetNode.value.height - node.value.height) / 2;
                    toDirection = FlowChartDirection.LEFT
                    break
                default:
                    showPoint.x = targetNode.value.x + (targetNode.value.width - node.value.width) / 2
                    showPoint.y = targetNode.value.y + targetNode.value.height + verticalSpace;
                    toDirection = FlowChartDirection.TOP
                    break    
            }
        } else if (point == null || point.x <= 0 || point.y <= 0) {
            showPoint = this.delegate.getMindMapCentralPoint();
            showPoint.x = showPoint.x - node.value.width / 2;
            showPoint.y = showPoint.y - node.value.height / 2;
        }
        
        node.value.x = showPoint.x;
        node.value.y = showPoint.y;
        let rect = new CGRect(node.value.x - 2, node.value.y - 2, node.value.width + 4, node.value.height + 4);
        let allRootNodeTreeRectMaps = new HashMap();
        let allRootNodeTreeRectShapeTypeMaps = new HashMap();
        let freeRootNode = null;
        this.freeNodes.forEach(unit => {                                       
            if (unit.rootTreeNode != null && unit.rootTreeNode.value.id != node.value.id) {
                unit.refreshLayout(true, this.settingData);
                freeRootNode = unit.rootTreeNode;

                allRootNodeTreeRectMaps.put(unit.rootTreeNode.value.id, unit.getRectByMargin(0, 0, 0, true));
                allRootNodeTreeRectShapeTypeMaps.put(unit.rootTreeNode.value.id, unit.rootTreeNode.value.mindElementShape)
            }
        });

        let mindElementFreeNodeCheckPoint = new MindElementFreeNodeCheckPoint();
        mindElementFreeNodeCheckPoint.setData(this.mainMindNodeUnit,
                this.timeMindTypeNodeUnit,
                this.doubleBubbleMindNodeUnit,
                allRootNodeTreeRectMaps,
                allRootNodeTreeRectShapeTypeMaps);

        mindElementFreeNodeCheckPoint.setRect(rect, node.value.mindElementShape);
        let hitPoint = null
        if (direction == FlowChartDirection.LEFT || direction == FlowChartDirection.RIGHT) {
            hitPoint = mindElementFreeNodeCheckPoint.getVerticalPoint(rect);   
        } else {
            hitPoint = mindElementFreeNodeCheckPoint.getHorizontalPoint(rect);   
        }             
        if (hitPoint.x != -1 || hitPoint.y != -1) {           
            node.value.x = node.value.x + hitPoint.x;
            node.value.y = node.value.y + hitPoint.y;
        }
        if (this.settingData.lineColor.length > 1) {
            let unit = this.freeNodes[this.freeNodes.length - 1];
            let color = this.settingData.lineColor[(this.freeNodes.length - 1) % this.settingData.lineColor.length];
            unit.setDataColorForRainbowrLine(unit.rootTreeNode.value, color);
        }
        if (!targetNode.isEmpty()) {
            this.isAddNodeConnectLine = false;
            let line = this.createNodeConnectLine(targetNode.value.id, node.value.id);
            if (!line.isEmpty()) {
                line.lineContent.connectLineType = ConnectLineType.RIGHT_ANGLE_LINE;
                line.lineContent.nodeConnectLineDottedLine = false;
                let nodeConnectLineCalcul = new NodeConnectLineCalcul();
                nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
                    this.freeNodes,
                    this.nodeConnectLineDataDict,
                    this.timeMindTypeNodeUnit,
                    this.doubleBubbleMindNodeUnit,
                    this.mindBGColor,
                    this.lastClickPoint);
                nodeConnectLineCalcul.setConnectLine(line, true, direction, toDirection);
                
                this.nodeConnectLineDataDict.put(line.id, line);
            }
        }
        if (isRefresh) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            this.checkNodeViewToScreen(node.value);
    
            if (!node.isEmpty()) {
                this.editNode(node.value.id);
                setTimeout(() => {
                    this.delegate.showChangeNodeShape(node.value, direction);
                }, 100);
            }
        }
        return node;
    }

    addChildMindElement(isRefresh, newText, targetId) {
        if (this.isFlowChart()) {
            if (targetId != null && targetId >= 0) {
                this.addFlowChartNode(targetId, null)
            } else if (this.selectedNodeId != null && this.selectedNodeId >= 0) {
                this.addFlowChartNode(this.selectedNodeId, null)
            }
            return;
        }
        let selectedNode = this.getSelectedNode();
        if (selectedNode.value.type == MindElementType.EXPLAIN) {
            this.delegate.$message.error(new UiUtil().getString(StringsLanguage.Mind_Adding_Child_Nodes_Not_Allowed));//"注释不允许添加子节点"
            return new LineMindTypeNode();
        }
        if (arguments.length == 0) {
            let previewNode = this.getNodeById(this.selectedNodeId).copy();
            let node = this.addChildMindElement(true);
            if (!node.isEmpty()) {
                this.setNodeViewToScreen(node.value);
                this.setMindContentCenterDisplay(false);
            }
        } else if (arguments.length == 1) {
            return this.addChildMindElement(isRefresh, "");
        } else if (arguments.length == 2) {
            return this.addChildMindElement(isRefresh, newText, this.selectedNodeId);
        } else if (arguments.length == 3) {
            if (targetId == null || targetId < 0) {
                targetId = this.selectedNodeId;
            }
            let node = new LineMindTypeNode();
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {

                if (targetId == IdGenerator.INVALID_ID) {
                    this.selectAddTargetTips();
                    // let checkMember = this.checkLoginAndMember();
                    return node;
                }
                groupMindSyncAndMerge.get().stopCurrentMerge()
                this.showNodeChilds(targetId, false, 0);
                let targetNode = this.getNodeById(targetId)
                if (!targetNode.isEmpty() && targetNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                    let parentNode = this.getNodeById(targetNode.value.parentNodeId)
                    if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        let grandpa = this.getNodeById(parentNode.value.parentNodeId) 
                        if (this.isRootNode(grandpa.value.id) && !grandpa.isEmpty() && grandpa.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                            this.addYoungerBrotherMindElement()
                            return new LineMindTypeNode();
                        }
                    }
                }
                node = this.mainMindNodeUnit.addChildMindElement(targetId);
                if (node.isEmpty()) {
                    for (let index = 0; index < this.freeNodes.length; index++) {
                        const element = this.freeNodes[index];
                        node = element.addChildMindElement(targetId);
                        if (!node.isEmpty()) {
                            break;
                        }
                    }
                }

                if (!node.isEmpty() && node.value.textContent != null && this.mindDisplayType == MindDisplayType.Outline) {
                    node.value.textContent.text = newText;
                    this.outlineBecomeFirstResponder(node.value.id)
                }

                if (node.value.type == MindElementType.SUBJECT) {
                    this.saveSubtitle();
                }
            } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                if (targetId == IdGenerator.INVALID_ID) {
                    this.selectAddTargetTips();
                    // let checkMember = this.checkLoginAndMember();
                    return node;
                }
                groupMindSyncAndMerge.get().stopCurrentMerge()
                node = this.doubleBubbleMindNodeUnit.addChildMindElement(targetId);
                if (node.isEmpty()) {
                    for (let index = 0; index < this.freeNodes.length; index++) {
                        const element = this.freeNodes[index];
                        node = element.addChildMindElement(targetId);
                        if (!node.isEmpty()) {
                            break;
                        }
                    }
                }
                if (node.value.type == MindElementType.SUBJECT) {
                    this.saveSubtitle();
                }
            } else if (this.mindType == MindType.TIME_MAP) {
                groupMindSyncAndMerge.get().stopCurrentMerge()
                let length = this.freeNodes.length
                for (let index = 0; index < length; index++) {
                    let unit = this.freeNodes[index]
                    node = unit.addChildMindElement(targetId);
                    if (!node.isEmpty()) {
                        break;
                    }
                }
                if (node.isEmpty()) {
                    this.timeMindTypeNodeUnit.creatNodeToBottom();
                }
            }

            if (isRefresh) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                if (!node.isEmpty()) {
                    this.editNode(node.value.id);
                }
            }
            // let checkMember = this.checkLoginAndMember();
            return node;
        }
    }

    checkIsSelectedNodeAndShowTip() {
        let selectedNode = this.getNodeById(this.selectedNodeId);
        if (selectedNode.isEmpty() || 
            selectedNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE ||
            selectedNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.selectAddTargetTips();
            return false;
        }
        return true;
    }

    addGeneralizationContent() {
        let selectedNode = this.getNodeById(this.selectedNodeId);
        if (selectedNode.isEmpty() || 
            selectedNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE || 
            selectedNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.selectAddTargetTips();
            return (new LineMindTypeNode).emptyMindNode();
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        let node = this.mainMindNodeUnit.addGeneralizationContent(this.selectedNodeId);
        if (node.isEmpty()) {
            let length = this.freeNodes.length
            for (let index = 0; index < length; index++) {
                let unit = this.freeNodes[index];
                node = unit.addGeneralizationContent(this.selectedNodeId);
                if (!node.isEmpty()) {
                    break;
                }
            }
        }

        if (!node.isEmpty()) {
            if (this.mindDisplayType == MindDisplayType.Outline) {
                node.value.generalizationContent.textColor = Colors.black50;
                node.value.generalizationContent.text = "";
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            if (this.mindDisplayType == MindDisplayType.Outline) {
                this.outlineBecomeFirstResponder(node.value.id)
            }

        }
        return node;
    }

    getMyCurrentMindOnlyId() {
        if (this.delegate.$router.history.current.name == 'c' ||
            this.delegate.$router.history.current.name == 's' ||
            this.delegate.$router.history.current.name == 'template') {
            return ""
        }
        let mindMapId = this.delegate.$route.query.id
        let sheetId = this.delegate.$route.query.sheetId
        let name = this.getTitle()
        if (mindMapId != null && mindMapId.length > 0 && sheetId != null && sheetId.length > 0) {
            return sheetId
        } else if (mindMapId != null && mindMapId.length > 0) {
            return mindMapId
        } else if (name.length > 0) {
            return Util.md5(name)
        }
        return "";
    }

    setMindMapCanvasScale(scale) {
        try {
            let id = this.getMyCurrentMindOnlyId()
            localStorage.setItem('mindmapcanvasscale', id + "|" + scale)
        } catch (error) {
            console.log("mindmapcanvasscale error->", error);
        }
    }

    addExplainContent() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.selectAddTargetTips();
            return;
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        let data = this.mainMindNodeUnit.addExplainContent(this.selectedNodeId);
        if (data.isEmpty()) {
            let freeNodesCount = this.freeNodes.length;
            for (var index = 0; index < freeNodesCount; index++) {
                let unit = this.freeNodes[index];
                data = unit.addExplainContent(this.selectedNodeId);
                if (!data.isEmpty()) {
                    break;
                }
            }
        }
        if (!data.isEmpty()) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    addBrotherMindElement() {
        if (this.isFlowChart()) {
            let selectedNode = this.getSelectedNode();
            if (selectedNode.isEmpty()) {
                return;
            }
            this.addFlowChartNode(selectedNode.value.id, null);
            return;
        }
        let selectedNode = this.getSelectedNode();
        if (selectedNode.value.type == MindElementType.EXPLAIN) {
            this.delegate.$message.error(new UiUtil().getString(StringsLanguage.Mind_Adding_Child_Nodes_Not_Allowed));//"注释不允许添加子节点"
            return;
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        let node = (new LineMindTypeNode).emptyMindNode();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            if (this.selectedNodeId == IdGenerator.INVALID_ID) {
                this.selectAddTargetTips();
                return;
            }
            node = this.mainMindNodeUnit.addBrotherMindElement(this.selectedNodeId);
            if (node.isEmpty()) {
                for (let index = 0; index < this.freeNodes.length; index++) {
                    let unit = this.freeNodes[index]
                    node = unit.addBrotherMindElement(this.selectedNodeId);
                    if (!node.isEmpty()) {
                        break;
                    }
                };
            }
            if (node.value.type == MindElementType.SUBJECT) {
                this.saveSubtitle();
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            if (this.selectedNodeId == IdGenerator.INVALID_ID) {
                this.selectAddTargetTips();
                return;
            }
            node = this.doubleBubbleMindNodeUnit.addBrotherMindElement(this.selectedNodeId);
            if (node.isEmpty()) {
                for (let index = 0; index < this.freeNodes.length; index++) {
                    let unit = this.freeNodes[index]
                    node = unit.addBrotherMindElement(this.selectedNodeId);
                    if (!node.isEmpty()) {
                        break;
                    }
                };
            }
            if (node.value.type == MindElementType.SUBJECT) {
                this.saveSubtitle();
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                node = unit.addBrotherMindElement(this.selectedNodeId);
                if (!node.isEmpty()) {
                    return
                }
            }
            if (node.isEmpty()) {
                this.timeMindTypeNodeUnit.addBrotherMindElement(this.selectedNodeId);
            }
        }
        let previewNode = this.getNodeById(this.selectedNodeId).copy();
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
        if (!node.isEmpty()) {
            this.editNode(node.value.id);
            this.setNodeViewToScreen(node.value);
            this.setMindContentCenterDisplay(false);
        }
    }

    addYoungerBrotherMindElement(newText, targetId, isRefresh = true) {
        if (this.isFlowChart()) {
            let selectedNode = this.getSelectedNode();
            if (selectedNode.isEmpty()) {
                return;
            }
            this.addFlowChartNode(selectedNode.value.id, null);
            return;
        }
        let selectedNode = this.getSelectedNode();
        if (selectedNode.value.type == MindElementType.EXPLAIN) {
            this.delegate.$message.error(new UiUtil().getString(StringsLanguage.Mind_Adding_Child_Nodes_Not_Allowed));//"注释不允许添加子节点"
            return;
        }
        if (arguments.length == 0) {
            newText = "";
            targetId = this.selectedNodeId
        } else if (arguments.length == 1) {
            targetId = this.selectedNodeId;
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        var node = new LineMindTypeNode();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            if (targetId == IdGenerator.INVALID_ID) {
                this.selectAddTargetTips();
                return node;
            }
            node = this.mainMindNodeUnit.addYoungerBrotherMindElement(targetId);
            if (node.isEmpty()) {
                for (let index = 0; index < this.freeNodes.length; index++) {
                    node = this.freeNodes[index].addYoungerBrotherMindElement(targetId);
                    if (!node.isEmpty()) {
                        break;
                    }
                }
            }
            if (!node.isEmpty() && node.value.textContent != null && this.mindDisplayType == MindDisplayType.Outline) {
                node.value.textContent.text = newText;
                this.outlineBecomeFirstResponder(node.value.id)
            }

            if (node.value.type == MindElementType.SUBJECT) {
                this.saveSubtitle();
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            if (targetId == IdGenerator.INVALID_ID) {
                this.selectAddTargetTips();
                return node;
            }
            node = this.doubleBubbleMindNodeUnit.addYoungerBrotherMindElement(targetId);
            if (node.isEmpty()) {
                for (let index = 0; index < this.freeNodes.length; index++) {
                    node = this.freeNodes[index].addYoungerBrotherMindElement(targetId);
                    if (!node.isEmpty()) {
                        break;
                    }
                }
            }
            if (node.value.type == MindElementType.SUBJECT) {
                this.saveSubtitle();
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            for (let index = 0; index < this.freeNodes.length; index++) {
                node = this.freeNodes[index].addYoungerBrotherMindElement(targetId);
                if (!node.isEmpty()) {
                    break;
                }
            }
            if (node.isEmpty()) {
                this.timeMindTypeNodeUnit.addYoungerBrotherMindElement(targetId);
            }
        }
        if (isRefresh) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            if (!node.isEmpty()) {
                let previewNode = this.getNodeById(targetId).copy();
                this.editNode(node.value.id);
                this.setNodeViewToScreen(node.value);
                this.setMindContentCenterDisplay(false);
            }
        }
        return node;
    }

    editSelectedNode() {
        this.editNode(this.selectedNodeId)
    }

    editNode(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || this.mindDisplayType == MindDisplayType.Outline) {
            return;
        }

        setTimeout(() => {
            this.delegate.clearViewSelected();
            this.delegate.onSelected(node.value);
            this.delegate.setNodeToEdit(id);
        }, 100);
    }

    getNodeLine(nodeId) {
        let list = new Array();
        let line = this.mainMindNodeUnit.getNodeLine(nodeId);
        if (!line.isEmpty()) {
            list.push(line);
            return list;
        }
        let lines = this.doubleBubbleMindNodeUnit.getNodeLines(nodeId);
        if (lines.length > 0 && (lines.length > 1 || !lines[0])) {
            return lines;
        }
        let lineData = this.freeNodes
        let lineLength = lineData.length
        for (let index = 0; index < lineLength; index++) {
            const unit = lineData[index];
            line = unit.getNodeLine(nodeId);
            if (!line.isEmpty()) {
                list.push(line);
                return list;
            }
        }
        line = this.timeMindTypeNodeUnit.getNodeLine(nodeId);
        if (!line.isEmpty()) {
            list.push(line);
            return list;
        }
        if (this.mindType == MindType.TIME_MAP) {
            list.push(this.timeMindTypeNodeUnit.baseLine);
            return list;
        }
        return list;
    }
    //统计图
    onStatisticChange(data) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty()) {
            return
        }
        if (!data.isStatisticsContent()) {
            return;
        }
        if (node.textContent != null) {
            node.value.textContent.text = data.statisticsContent.cellsData.get[0].explainTitle + " " +
                node.value.statisticsContent.cellsData.get[0].explain;
        }
        node.value.statisticsContent = data.statisticsContent;
        if (node.isEmpty()) {
            this.addStatisticsNode.value = data;
        } else {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeLineLayout(layout) {
        this.clearAddFreeNodeStatus();
        if (this.selectedList != null && !this.selectedList.isEmpty()) {
            let isChange = false;
            let selectedCount = this.selectedList.length;
            for (let index = 0; index < selectedCount; index++) {
                let selectedData = this.selectedList[index]
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                    let node = this.getNodeById(selectedData.id);
                    if (node.isEmpty()) {
                        continue;
                    }
                    let children = node.children
                    if (layout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        layout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                            children = node.getAllChild()
                    }
                    let childCount = children.length;
                    for (let index = 0; index < childCount; index++) {
                        let child = children[index];
                        let lineDatas = this.getNodeLine(child.value.id);
                        let lineDatasCount = lineDatas.length;
                        for (let index = 0; index < lineDatasCount; index++) {
                            let lineData = lineDatas[index]
                            if (!lineData.isEmpty() && lineData.lineContent != null) {
                                lineData.lineContent.lineLayout = layout;
                                isChange = true;
                            }
                        }
                    }
                }
            }
            if (isChange) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            }
        }

        if (this.selectedNodeId != IdGenerator.INVALID_ID) {
            let freeNodesCount = this.freeNodes.length
            for (let index = 0; index < freeNodesCount; index++) {
                let unit = this.freeNodes[index]
                let node = unit.getNodeById(this.selectedNodeId);
                if (!node.isEmpty()) {
                    let children = node.children
                    if (layout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        layout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                            children = node.getAllChild()
                    }
                    let childCount = children.length;
                    for (let index = 0; index < childCount; index++) {
                        let child = children[index];
                        let lineData = unit.getNodeLine(child.value.id);
                        if (!lineData.isEmpty() && lineData.lineContent != null) {
                            lineData.lineContent.lineLayout = layout;
                        }
                    }
                    if (unit.isRootNode(node.value.id)) {
                        unit.globalLineLayout = layout;
                    }

                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                    return;
                }
            }
        }
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            let node = this.mainMindNodeUnit.getNodeById(this.selectedNodeId);
            if (node.isEmpty() && this.mainMindNodeUnit.rootTreeNode != null) {
                node = this.mainMindNodeUnit.rootTreeNode;
            }

            let children = node.children
            if (layout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                layout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE || 
                (this.mainMindNodeUnit.globalLineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                    this.mainMindNodeUnit.globalLineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE)) {
                    children = node.getAllChild()
            }
            let childCount = children.length;

            for (let index = 0; index < childCount; index++) {
                let child = children[index];
                let lineData = this.mainMindNodeUnit.getNodeLine(child.value.id);
                if (!lineData.isEmpty() && lineData.lineContent != null) {
                    lineData.lineContent.lineLayout = layout;
                }
            }
            if (this.mainMindNodeUnit.isRootNode(node.value.id)) {
                this.mainMindNodeUnit.globalLineLayout = layout;
                this.globalLineLayout = layout;
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();

            return;
        } else {

            this.globalLineLayout = layout;
            this.mainMindNodeUnit.globalLineLayout = layout;
            this.doubleBubbleMindNodeUnit.globalLineLayout = layout;

            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    clearAddFreeNodeStatus() {
        this.isAddFreeNode = false;
        this.delegate.hideTips();
    }

    changeConnectLineShape(type) {
        let line = this.getNodeById(this.selectedNodeId);
        if (line.isEmpty() ||
            line.value.type != MindElementType.NODE_CONNECT_LINE ||
            line.value.lineContent == null ||
            line.value.lineContent.connectLineType == type) {
            return;
        }
        line.value.lineContent.connectLineType = type;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeConnectLineStartShape(type) {
        let line = this.getNodeById(this.selectedNodeId);
        if (line.isEmpty() ||
            line.value.type != MindElementType.NODE_CONNECT_LINE ||
            line.value.lineContent == null ||
            line.value.lineContent.startShape == type) {
            return;
        }
        line.value.lineContent.startShape = type;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeConnectLineEndShape(type) {
        let line = this.getNodeById(this.selectedNodeId);
        if (line.isEmpty() ||
            line.value.type != MindElementType.NODE_CONNECT_LINE ||
            line.value.lineContent == null ||
            line.value.lineContent.endShape == type) {
            return;
        }
        line.value.lineContent.endShape = type;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeNodeLayout(type) {
        this.clearAddFreeNodeStatus();

        if (this.selectedList != null && !this.selectedList.isEmpty()) {
            let isChange = false;
            this.selectedList.forEach(selectedData => {
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                    this.changeNodeLayoutById(selectedData.id, type);
                    isChange = true;
                }
            });
            if (isChange) {
                this.resetRootNodeChildBranchNodeFreelayout()
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            }
        }

        if (this.selectedNodeId != IdGenerator.INVALID_ID) {
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index]
                let node = unit.getNodeById(this.selectedNodeId);
                if (!node.isEmpty()) {
                    if (node.value.id == unit.rootTreeNode.value.id) {
                        unit.setElementLayout(type);
                    } else {
                        unit.setNodeLayout(this.selectedNodeId, type);
                    }
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                    return;
                }
            }
        }

        if (this.mainMindNodeUnit != null &&
            !this.mainMindNodeUnit.getNodeById(this.selectedNodeId).isEmpty() &&
            this.selectedNodeId != this.mainMindNodeUnit.rootTreeNode.value.id) {
            this.mainMindNodeUnit.setNodeLayout(this.selectedNodeId, type);
            this.resetRootNodeChildBranchNodeFreelayout()
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        }

        if (type == this.globalLayout) {
            return;
        }
        this.setGlobalLayout(type);
        this.resetRootNodeChildBranchNodeFreelayout()
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeNodeFullShape(type) {
        this.clearAddFreeNodeStatus();

        if (this.selectedList != null && !this.selectedList.isEmpty()) {
            let isChange = false;
            this.selectedList.forEach(selectedData => {
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION ||
                    selectedData.type == MindElementType.EXPLAIN) {
                    selectedData.backgroundFullType = type;
                    isChange = true;
                }
            });
            if (isChange) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            }
        }

        let node = this.getSelectedNode()
        if (node.isEmpty()) {
            return;
        }
        if (node.value.type == MindElementType.MAIN_SUBJECT ||
            node.value.type == MindElementType.SUBJECT ||
            node.value.type == MindElementType.SON_SUBJECT ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION ||
            node.value.type == MindElementType.EXPLAIN) {
            node.value.backgroundFullType = type;
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setNodeTask(startTime, endTime, peoples, priority, taskProgress, id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() ||
            (node.value.textContent == null && node.value.generalizationContent == null)) {
            this.selectAddTargetTips();
        }
        if (startTime <= 0 && endTime <= 0 && peoples.isEmpty()) {
            node.value.taskContent = null;
        } else {
            if (node.value.taskContent == null) {
                node.value.taskContent = new TaskContent();
            }
            node.value.taskContent.startTime = startTime;
            node.value.taskContent.endTime = endTime;
            node.value.taskContent.peoples = peoples;
            node.value.taskContent.priority = priority;
            node.value.taskContent.taskProgress = taskProgress;
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeNodeLayoutById(id, type) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (id != IdGenerator.INVALID_ID) {
            this.freeNodes.forEach(unit => {
                node = unit.getNodeById(id);
                if (!node.isEmpty()) {
                    if (node.value.id == unit.rootTreeNode.value.id) {
                        unit.setElementLayout(type);
                    } else {
                        unit.setNodeLayout(id, type);
                    }
                }
            })
        }
        if (this.mainMindNodeUnit != null &&
            !this.mainMindNodeUnit.getNodeById(id).isEmpty() &&
            id != this.mainMindNodeUnit.rootTreeNode.value.id) {
            this.mainMindNodeUnit.setNodeLayout(id, type);
            this.resetRootNodeChildBranchNodeFreelayout()
        }
    }

    setGlobalLayout(type, isInit = false) {
        this.clearAddFreeNodeStatus();
        this.globalLayout = type;
        this.globalLayout = this.getGlobalLayout();
        this.mainMindNodeUnit.setElementLayout(this.globalLayout, isInit);
        this.timeMindTypeNodeUnit.setElementLayout(this.globalLayout);
        this.doubleBubbleMindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_DOUBLE_BUBBLE);
    }
    changeNodeAllLineWidth(width) {
        if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.setLineWidth(width);
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.mainMindNodeUnit.changeNodeAllLineWidth(this.selectedNodeId, width);
            this.doubleBubbleMindNodeUnit.changeNodeAllLineWidth(this.selectedNodeId, width);
        } else if (this.mindType == MindType.FLOW_CHART) {
            let keys = this.nodeConnectLineDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let line = this.nodeConnectLineDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                line.lineContent.lineWidth = width;
            }
        }
        this.freeNodes.forEach(unit => {
            unit.changeNodeAllLineWidth(this.selectedNodeId, width);
        });
    }

    //改变时间线箭头
    changeTimeLineArrow(isArrow) {
        if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.baseLine != null &&
            !this.timeMindTypeNodeUnit.baseLine.isEmpty()) {
            this.timeMindTypeNodeUnit.baseLine.timeLineContent.isShowArrow = isArrow;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTimeNodeLayoutType(type) {
        if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.baseLine != null &&
            !this.timeMindTypeNodeUnit.baseLine.isEmpty()) {
            this.timeMindTypeNodeUnit.baseLine.timeLineContent.timeNodeLayoutType = type;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTiemLineDotted(isDotted) {
        if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.baseLine != null &&
            !this.timeMindTypeNodeUnit.baseLine.isEmpty()) {
            this.timeMindTypeNodeUnit.baseLine.timeLineContent.dottedLine = isDotted;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }


    changeNodeLineLength(length) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        node.value.bubbleRadius = length;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeNodeLineWidth(id, width) {
        if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.setLineWidth(width);
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.mainMindNodeUnit.changeNodeLineWidth(id, width);
            this.doubleBubbleMindNodeUnit.changeNodeLineWidth(this.selectedNodeId, width);
        }
        this.freeNodes.forEach(unit => {
            unit.changeNodeLineWidth(id, width);
        });
    }
    changeNodeAllLineColor(color) {
        if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.setLineColor(color);
            this.freeNodes.forEach(unit => {
                unit.changeNodeAllLineColor(this.selectedNodeId, color);
            });
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.settingData.lineColor = [];
            this.mainMindNodeUnit.changeNodeAllLineColor(this.selectedNodeId, color);
            this.doubleBubbleMindNodeUnit.changeNodeAllLineColor(this.selectedNodeId, color);
        } else if (this.mindType == MindType.FLOW_CHART) {
            let keys = this.nodeConnectLineDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let line = this.nodeConnectLineDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                line.lineContent.color = color;
            }
        }
        this.freeNodes.forEach(unit => {
            unit.changeNodeAllLineColor(this.selectedNodeId, color);
        });
    }
    changeNodeLineColor(id, color) {
        if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.setLineColor(color);
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.mainMindNodeUnit.changeNodeLineColor(id, color);
            this.doubleBubbleMindNodeUnit.changeNodeAllLineColor(this.selectedNodeId, color);
        }
        let node = this.getNodeById(id);
        if (!node.isEmpty() &&
            (node.type == MindElementType.NODE_CONNECT_LINE || node.type == MindElementType.BAOWEI_LINE_FULL) &&
            node.value.lineContent != null) {
            node.value.lineContent.color = color;
            node.value.lineContent.strokeColor = color;
        }
        this.freeNodes.forEach(unit => {
            unit.changeNodeLineColor(id, color);
        });
    }

    getSelectedNodeById() {
        return this.getNodeById(this.selectedNodeId);
    }
    saveImage(watermark, customWatermarkData) {

    }
    savePDF() {

    }
    sharedImage() {

    }
    sharedFile() {
        let imageBase64 = new Array();
        this.delegate.saveFile(this.mindDetailsID, this.mindType, this.mindPreviewID, imageBase64);
    }


    sharedDarkdownFile() {

    }

    setFreeNodeStatus() {
        this.isAddFreeNode = true;
        this.showTipsMessage(new UiUtil().getString(StringsLanguage.Global_Add_Free_Node_Tips));
    }

    setStatisticsNode(chapeType) {
        this.showTipsMessage(new UiUtil().getString(StringsLanguage.Global_Add_Free_Chart_Node_Tips));
        let data = new MindElementData().emptyMindNode();
        data.id = 10000000;
        let statisticsContent = new StatisticsContent();
        statisticsContent.radius = new UiUtil().dip2px(68);
        statisticsContent.title = "";
        let cellsData = new Array();
        let color = [0xff3300, 0xF88800, 0x00DD4F, 0x0093DD, 0x7235DD, 0xDD3583, 0x4B4D12, 0x86DD5F];
        let coloriIndex = Math.floor(Math.random() * 10);
        let count = 3;
        for (let index = 0; index < count; index++) {
            let cell = new StatisticsCellData();
            if (chapeType == StatisticsChapeType.CakeShape_1 ||
                chapeType == StatisticsChapeType.CakeShape_2 ||
                chapeType == StatisticsChapeType.CakeShape_3 ||
                chapeType == StatisticsChapeType.CakeShape_4 ||
                chapeType == StatisticsChapeType.CakeShape_5) {
                cell.value = 1;
            } else {
                cell.value = index + Math.floor(Math.random() * 40);
            }
            cell.color = color[(index + coloriIndex) % color.length];
            cell.explainTitle = new UiUtil().getString(StringsLanguage.Echart_Edit_Title) + " " + index;
            cell.explain = new Date().getFullYear() + "." + (index + 1) + new UiUtil().getString(StringsLanguage.Mind_Month);
            cell.lineColor = cell.color;
            cellsData.push(cell);
        }
        statisticsContent.type = chapeType;
        statisticsContent.cellsData = cellsData;
        data.statisticsContent = statisticsContent;
        this.addStatisticsNode = data;
    }

    checkSelectedNodeAndTip() {
        if (this.isFlowChart()) {
            return true
        }
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            this.selectAddTargetTips();
            return false;
        } else {
            return true
        }
    }

    selectedNodeConnectLineStatus(type, isTips = true) {
        let node = this.getSelectedNodeById()
        if (node.isEmpty() && this.isFlowChart() && this.mainMindNodeUnit.rootTreeNode != null && 
            !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            let line = this.createFreeLine()
            if (!line.isEmpty()) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                this.delegate.onSelected(line, new Point(-1, -1))
                return
            }
        }
        if (node.isEmpty()) {
            this.selectAddTargetTips();
            return;
        }
        this.isAddNodeConnectLine = true;
        this.addNodeConnectLineType = type;
        this.clearAddFreeNodeStatus();
        if (isTips) {
            this.delegate.showTips(new UiUtil().getString(StringsLanguage.Global_Add_Relationship_Tips));
        }
    }

    isHitData(rect) {
        var list = []
        if (this.mainMindNodeUnit.rootTreeNode != null && !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            let childs = this.mainMindNodeUnit.rootTreeNode.getAllChild()
            list.push(this.mainMindNodeUnit.rootTreeNode.value)
            for (let index = 0; index < childs.length; index++) {
                list.push(childs[index].value)
            }
        }
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index]
            if (unit.rootTreeNode != null &&
                !unit.rootTreeNode.isEmpty()) {
                list.push(unit.rootTreeNode.value)
                let childs = unit.rootTreeNode.getAllChild()
                for (let j = 0; j < childs.length; j++) {
                    list.push(childs[j].value)
                }
            }
        }
        for (let index = 0; index < list.length; index++) {
            let cell = list[index]
            if (Util.isIntersectedForRect(cell.getRect(), rect)) {
                return cell
            }
        }
        return new MindElementData()
    }

    createFreeLine() {
        var point = this.delegate.getMindMapCentralPoint()
        let target = this.mainMindNodeUnit.rootTreeNode.value
        let line = this.createNodeConnectLine(target.id, target.id)
        let lineLength = 200
        
        if (!line.isEmpty()) {
            line.parentNodeId = line.id
            line.lineContent.targetId = line.id
            line.lineContent.connectLineType = ConnectLineType.RIGHT_ANGLE_LINE
            line.lineContent.nodeConnectLineDottedLine = false
            this.nodeConnectLineDataDict.put(line.id, line);
            let hitData = this.isHitData(new CGRect(point.x - lineLength / 2, point.y - 1, lineLength, 2))
            if (!hitData.isEmpty()) {
                var bottomPoint = this.delegate.getMindMapCentralPoint()
                for (let index = 0; index < 10; index++) { //向下
                    let data = this.isHitData(new CGRect(bottomPoint.x - lineLength / 2, bottomPoint.y - 1, lineLength, 2))
                    if (!data.isEmpty()) {
                        bottomPoint.y = (data.y + data.height) + 4
                    } else {
                        break
                    }
                }
                var topPoint = this.delegate.getMindMapCentralPoint()
                for (let index = 0; index < 10; index++) {  //向上
                    let data = this.isHitData(new CGRect(topPoint.x - lineLength / 2, topPoint.y - 1, lineLength, 2))
                    if (!data.isEmpty()) {
                        topPoint.y = (data.y) - 4
                    } else {
                        break
                    }
                }
                if (bottomPoint.y != point.y && topPoint.y != point.y &&
                    Math.abs(bottomPoint.y - point.y) < Math.abs(topPoint.y - point.y) && 
                    Math.abs(bottomPoint.y - point.y) < (Util.getScreenHeight() / 2 - 50)) {
                    point.y = bottomPoint.y
                } else if (bottomPoint.y != point.y && topPoint.y != point.y &&
                    Math.abs(bottomPoint.y - point.y) > Math.abs(topPoint.y - point.y) && 
                    Math.abs(topPoint.y - point.y) < (Util.getScreenHeight() / 2 - 70)) {
                   point.y = topPoint.y
                }
            }
            line.x = point.x - lineLength / 2
            line.y = point.y
            line.lineContent.startPointX = 0
            line.lineContent.startPointY = 0
            line.lineContent.endPointX = lineLength
            line.lineContent.endPointY = 0
            line.lineContent.rightAnglePoints.push(new CGPoint(lineLength / 2, 0))
            line.lineContent.startControlPointX = (point.x + lineLength / 2)
            line.lineContent.startControlPointY = (point.y - 50)
            line.lineContent.endControlPointX = (point.x + lineLength / 2)
            line.lineContent.endControlPointY = (point.y + 50)
            return line
        }
        return new MindElementData()
    }

    moveSlectedNodeByVerticalKey(changeValue) {
        let node = this.getSelectedNode()
        if (node.isEmpty() && this.selectedList.length == 0) {
            return;
        }
        if (this.selectedList.length > 0) {
            this.selectedList.forEach(cell => {
                if (cell.type == MindElementType.MAIN_SUBJECT ||
                    cell.type == MindElementType.SUBJECT ||
                    cell.type == MindElementType.SON_SUBJECT ||
                    cell.type == MindElementType.CONTENT_GENERALIZATION) {
                    if (this.isRootNode(cell.id)) {
                        this.nodeMoveCheckConnectLines(cell, 0, changeValue);
                    }
                    let copyData = cell.copy()
                    cell.y = cell.y - changeValue;
                    this.dataConnectLinePoint(cell, copyData);
                }
            });
        } else {
            let copyData = node.value.copy()
            node.value.y = node.value.y - changeValue;
            this.dataConnectLinePoint(node.value, copyData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    moveSlectedNodeByHorizontal(changeValue) {
        let node = this.getSelectedNode()
        if (node.isEmpty() && this.selectedList.length == 0) {
            return;
        }
        if (this.selectedList.length > 0) {
            this.selectedList.forEach(cell => {
                if (cell.type == MindElementType.MAIN_SUBJECT ||
                    cell.type == MindElementType.SUBJECT ||
                    cell.type == MindElementType.SON_SUBJECT ||
                    cell.type == MindElementType.CONTENT_GENERALIZATION) {
                    if (this.isRootNode(cell.id)) {
                        this.nodeMoveCheckConnectLines(cell, changeValue, 0);
                    }
                    let copyData = cell.copy()
                    cell.x = cell.x - changeValue;
                    this.dataConnectLinePoint(cell, copyData);
                }
            });
        } else {
            let copyData = node.value.copy()
            node.value.x = node.value.x - changeValue;
            this.dataConnectLinePoint(node.value, copyData);
        }
        
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    isDataSelected(id) {
        if (this.isSelectedNodeInSelectedList(id)) {
            return true;
        } 
        let node = this.getNodeById(id)
        return !node.isEmpty() && node.value.isSelected
    }

    setEncircleTargetByChangeNode(target){
        let encircleMindElementDataDict = new HashMap();
        if (this.mainMindNodeUnit != null) {
            let encircleMindElementDataDictArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                let key = encircleMindElementDataDictArr[index];
                encircleMindElementDataDict.put(key, this.mainMindNodeUnit.encircleMindElementDataDict.get(key));
            }
        }
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let encircleMindElementDataDictArr = unit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                let key = encircleMindElementDataDictArr[index];
                encircleMindElementDataDict.put(key, unit.encircleMindElementDataDict.get(key));
            }
        }

        if (encircleMindElementDataDict.isEmpty()) {
            return;
        }

        let encircleMindElementDataDictArr = encircleMindElementDataDict.keys();
        let isTargetRootNode = this.isRootNode(target.value.id)
        let taregtRect = new CGRect(target.value.x, target.value.y, target.value.width, target.value.height)
        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = encircleMindElementDataDict.get(key);
            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            
            let rect = new CGRect(mind.x, mind.y, mind.width, mind.height)
            if (this.isRootNode(targetIds[0]) && isTargetRootNode) {  
                if (targetIds.includes(target.value.id)) {
                    if (mind.lineContent.targetIds != null && mind.lineContent.targetIds.length > 1 &&
                        !Util.isIntersectedForRect(rect, taregtRect)) {
                        let list = []
                        for (let index = 0; index < mind.lineContent.targetIds.length; index++) {
                            if (mind.lineContent.targetIds[index] != target.value.id) {
                                list.push(mind.lineContent.targetIds[index])
                            }
                        }
                        if (list.length > 0) {
                            mind.lineContent.targetIds = list
                            if (mind.lineContent.targetId == target.value.id) {
                                mind.lineContent.targetId = list[0]
                            }
                            if (mind.parentNodeId == target.value.id) {
                                mind.parentNodeId = list[0]
                            }
                        }
                    }
                    return;
                }              
                if (mind.lineContent.targetIds != null && mind.lineContent.targetIds.length > 0 &&
                    Util.isIntersectedForRect(rect, taregtRect)) {
                        mind.lineContent.targetIds.push(target.value.id)
                        return
                }
            } else {
                if (targetIds.includes(target.value.id)) {
                    return;
                }
                let node = this.getNodeById(targetIds[0])
                if (node.value.parentNodeId != target.value.parentNodeId) {
                    continue;
                }
                if (mind.lineContent.targetIds != null && mind.lineContent.targetIds.length > 0 &&
                    Util.isIntersectedForRect(rect, taregtRect)) {
                    mind.lineContent.targetIds.push(target.value.id)
                    return
                }
            }
        }
    }    

    changeNodePoint(id, point) {

        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        let moveX = point.x;
        let moveY = point.y;
        if (point.x + node.value.width > this.editMindmapWidth) {
            moveX = this.editMindmapWidth - node.value.width;
        }
        if (point.y + node.value.height > this.editMindmapHeight) {
            moveY = this.editMindmapHeight - node.value.height;
        }
        if ((this.selectedList == null || this.selectedList.length == 0) && node.value.type == MindElementType.NODE_CONNECT_LINE) {
            let copyData = node.value.copy()
            let distanceX = node.value.x - moveX;
            let distanceY = node.value.y - moveY;
            this.nodeMoveCheckConnectLines(node.value, distanceX, distanceY);
            node.value.x = moveX;
            node.value.y = moveY;            
            node.value.lineContent.startControlPointX = node.value.lineContent.startControlPointX - distanceX;
            node.value.lineContent.startControlPointY = node.value.lineContent.startControlPointY - distanceY;
            node.value.lineContent.endControlPointX = node.value.lineContent.endControlPointX - distanceX;
            node.value.lineContent.endControlPointY = node.value.lineContent.endControlPointY - distanceY;
            this.dataConnectLinePoint(node.value, copyData);
            this.setEncircleTargetByChangeNode(node)
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        } else if (this.isFlowChart() && (this.selectedList == null || this.selectedList.length == 0) && node.value.type == MindElementType.BAOWEI_VIEW) {
            let copyData = node.value.copy()
            let distanceX = node.value.x - moveX;
            let distanceY = node.value.y - moveY;
            this.nodeMoveCheckConnectLines(node.value, distanceX, distanceY);
            node.value.x = moveX;
            node.value.y = moveY; 
            node.value.customWidth = node.value.width
            node.value.customHeight = node.value.height           
            node.value.lineContent.startControlPointX = node.value.lineContent.startControlPointX - distanceX;
            node.value.lineContent.startControlPointY = node.value.lineContent.startControlPointY - distanceY;
            node.value.lineContent.endControlPointX = node.value.lineContent.endControlPointX - distanceX;
            node.value.lineContent.endControlPointY = node.value.lineContent.endControlPointY - distanceY;
            node.value.lineContent.targetIds = [node.value.id];
            node.value.lineContent.targetId = node.value.id;
            node.value.parentNodeId = node.value.id;
            this.dataConnectLinePoint(node.value, copyData);
            this.setEncircleTargetByChangeNode(node)
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return;
        }
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let isMainRootNodeChild = this.mainMindNodeUnit.isRootNode(node.value.parentNodeId)
            if (isMainRootNodeChild && (this.settingData.branchNodeFreelayout.isValue() && this.isBranchNodeFreelayoutMode() || this.isFlowChart())) {
                node.value.isFreeLayoutNode = true
                let copyData = node.value.copy()
                node.value.x = moveX;
                node.value.y = moveY;
                this.dataConnectLinePoint(node.value, copyData);
                this.setEncircleTargetByChangeNode(node)
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            }
            let info = this.getHitMindElementDataByPoint(id, point);
            let targetData = info.node.value;

            if (!this.isFlowChart() && !this.mainMindNodeUnit.isRootNode(id) && info.isHit() && targetData.id != id) {
                this.moveNode(targetData.id, node.value.id, false, info.index);
            } else {
                if (this.selectedList != null && this.isSelectedNodeInSelectedList(id)) {
                    let distanceX = node.value.x - moveX;
                    let distanceY = node.value.y - moveY;
                    let moveConnectLines = []
                    this.selectedList.forEach(cell => {
                        if (this.isRootNode(cell.id)) {
                            this.nodeMoveCheckConnectLines(cell, distanceX, distanceY);
                        }
                        if (cell.type == MindElementType.MAIN_SUBJECT ||
                            cell.type == MindElementType.SUBJECT ||
                            cell.type == MindElementType.EXPLAIN ||
                            cell.type == MindElementType.SON_SUBJECT ||
                            cell.type == MindElementType.CONTENT_GENERALIZATION) {
                            let copyData = cell.copy()
                            cell.x = cell.x - distanceX;
                            cell.y = cell.y - distanceY;
                            let connectLines = this.getNodeConnectLine(cell.id);
                            for (let index = 0; index < connectLines.length; index++) {
                                const line = connectLines[index];
                                if (line != null && !line.isEmpty() && line.lineContent != null &&
                                    !moveConnectLines.includes(line) &&
                                    this.isSelectedNodeInSelectedList(line.parentNodeId) && 
                                    this.isSelectedNodeInSelectedList(line.lineContent.targetId)) { //连接线起点终点都在移动里
                                    line.x = line.x - distanceX;
                                    line.y = line.y - distanceY;
                                    moveConnectLines.push(line)
                                }
                            }
                            this.dataConnectLinePoint(cell, copyData, moveConnectLines);
                        }
                    })
                } else {
                    if (this.isRootNode(node.value.id)) {
                        this.nodeMoveCheckConnectLines(node.value, node.value.x - moveX, node.value.y - moveY);
                        let copyData = node.value.copy()
                        node.value.x = moveX;
                        node.value.y = moveY;
                        this.dataConnectLinePoint(node.value, copyData);

                        if (!this.settingData.isOverlap && this.mindDisplayType != MindDisplayType.Outline) {                             
                            let allRootNodeTreeRectMaps = new HashMap();
                            let allRootNodeTreeRectShapeTypeMaps = new HashMap();
                            let freeRect = null;
                            let freeRectShapeType = MindElementShapeType.Unknown;
                            let freeRootNode = null;
                            this.freeNodes.forEach(unit => {                                       
                                if (!unit.getNodeById(node.value.id).isEmpty()) {
                                    unit.refreshLayout(true, this.settingData);
                                    freeRect = unit.getRectByMargin(0, 0, 2, true);
                                    if (node.children.length == 0) {
                                        freeRectShapeType = node.value.mindElementShape
                                    }
                                    freeRootNode = unit.rootTreeNode;
                                } else {
                                    allRootNodeTreeRectMaps.put(unit.rootTreeNode.value.id, unit.getRectByMargin(0, 0, 0, true));
                                    if (unit.rootTreeNode.children.length == 0) {
                                        allRootNodeTreeRectShapeTypeMaps.put(unit.rootTreeNode.value.id, unit.rootTreeNode.value.mindElementShape)
                                    } else {
                                        allRootNodeTreeRectShapeTypeMaps.put(unit.rootTreeNode.value.id, MindElementShapeType.Unknown)
                                    }
                                }
                            });
                            
                            if (freeRect != null && freeRootNode != null) {                            
                                let mindElementFreeNodeCheckPoint = new MindElementFreeNodeCheckPoint();
                                mindElementFreeNodeCheckPoint.setData(this.mainMindNodeUnit,
                                        this.timeMindTypeNodeUnit,
                                        this.doubleBubbleMindNodeUnit,
                                        allRootNodeTreeRectMaps,
                                        allRootNodeTreeRectShapeTypeMaps);
                                mindElementFreeNodeCheckPoint.setRect(freeRect, freeRectShapeType);
                                let targetPoint = mindElementFreeNodeCheckPoint.getPoint(freeRect);
                                if (targetPoint.x != -1 || targetPoint.y != -1) {
                                    let copyData = freeRootNode.value.copy()
                                    freeRootNode.value.x = freeRootNode.value.x + targetPoint.x;
                                    freeRootNode.value.y = freeRootNode.value.y + targetPoint.y;
                                    this.dataConnectLinePoint(freeRootNode.value, copyData);
                                    setTimeout(() => {
                                        this.setNodeViewToScreen(freeRootNode.value);                                    
                                    }, 100);
                                }
                            }
                        }
                    } else {
                        let moveDistance = Util.getPointSpacing(new CGPoint(moveX, moveY), new CGPoint(node.value.x, node.value.y));

                        if (moveDistance > 20 && node.value.type == MindElementType.SUBJECT ||
                            node.value.type == MindElementType.SON_SUBJECT) {
                            this.addRemoveDataIdToSettingData(node);
                            this.setCopyNodeId(node.value.id);
                            this.stickNode(-1, new CGPoint(moveX + (node.value.width) / 2,
                                moveY + (node.value.height) / 2), true, false);
                            this.removeData(node.value.id, false);
                        }
                    }
                }
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            let timeData = this.timeMindTypeNodeUnit.getNodeById(id);
            let info = this.getHitMindElementDataByPoint(id, point);
            let targetData = info.node.value;
            if (timeData.isEmpty() && info.isHit() && targetData.id != id) {
                this.moveNode(targetData.id, node.value.id, false, info.index);
            } else if (this.selectedList != null && this.isSelectedNodeInSelectedList(id)) {
                let distanceX = node.value.x - moveX;
                let distanceY = node.value.y - moveY;
                this.selectedList.forEach(cell => {
                    if (this.isRootNode(cell.id)) {
                        this.nodeMoveCheckConnectLines(cell, distanceX, distanceY);
                    }
                    cell.x = cell.x - distanceX;
                    cell.y = cell.y - distanceY;
                })
            } else {
                if (this.isRootNode(node.value.id)) {
                    this.nodeMoveCheckConnectLines(node.value, node.value.x - moveX, node.value.y - moveY);
                    let freeNodesLnegth = this.freeNodes.length;
                    for (let index = 0; index < freeNodesLnegth; index++) {
                        let unit = this.freeNodes[index];
                        this.freeNodes.splice(index, 1);
                        this.freeNodes.push(unit);
                        break;

                    }
                }
                node.value.x = moveX;
                node.value.y = moveY;
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let isMainRootNodeChild = this.doubleBubbleMindNodeUnit.isRootNode(node.value.parentNodeId)
            let isRootBrotherNode = this.doubleBubbleMindNodeUnit.isRootBrotherNode(node.value.parentNodeId);
            let isCommonNode = this.doubleBubbleMindNodeUnit.isCommonNode(node.value.id);
            if ((isMainRootNodeChild || isRootBrotherNode || isCommonNode) &&
                this.settingData.branchNodeFreelayout.isValue() &&
                this.isBranchNodeFreelayoutMode()) {
                node.value.isFreeLayoutNode = true
                node.value.x = moveX;
                node.value.y = moveY;
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            }

            let bubbleDNode = this.doubleBubbleMindNodeUnit.getNodeById(id);
            let info = this.getHitMindElementDataByPoint(id, point);
            let targetData = info.node.value;
            if (bubbleDNode.isEmpty() && info.isHit() && targetData.id != id) {
                this.moveNode(targetData.id, node.value.id, false, info.index);
            } else if (this.selectedList != null && this.isSelectedNodeInSelectedList(id)) {
                let distanceX = node.value.x - moveX;
                let distanceY = node.value.y - moveY;
                this.selectedList.forEach(cell => {
                    if (this.isRootNode(cell.id)) {
                        this.nodeMoveCheckConnectLines(cell, distanceX, distanceY);
                    }
                    cell.x = cell.x - distanceX;
                    cell.y = cell.y - distanceY;
                })
            } else {
                if (this.isRootNode(node.value.id)) {
                    this.nodeMoveCheckConnectLines(node.value, node.value.x - moveX, node.value.y - moveY);
                    let freeNodesLnegth = this.freeNodes.length;
                    for (let index = 0; index < freeNodesLnegth; index++) {
                        let unit = this.freeNodes[index];
                        if (unit.isRootNode(node.value.id)) { //移动到最底层
                            this.freeNodes.splice(index, 1);
                            this.freeNodes.push(unit);
                            break;
                        }
                    }
                }
                node.value.x = moveX;
                node.value.y = moveY;
            }
        }
        this.setEncircleTargetByChangeNode(node)
        this.checkAutoAdsorption(node);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    dataConnectLinePoint(data, copyData, ignoreLines = []) {
        if (data == null || copyData == null || 
            data.isEmpty() || copyData.isEmpty() || 
            data.id != copyData.id) {
            return;
        }
        let lines = this.getNodeConnectLine(data.id)
        if (lines.length == 0) {
            return
        }
        let nodeConnectLineCalcul = new NodeConnectLineCalcul();
        nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        let copyDataCenterPoint = copyData.getCenterPoint()

        for (let index = 0; index < lines.length; index++) {
            const line = lines[index];
            if (line.lineContent == null) {
                continue;
            }
            if (ignoreLines != null && ignoreLines.includes(line)) {
                continue;
            }
            let start = line.lineContent.startPoint();
            let end = line.lineContent.endPoint();
            if (line.parentNodeId == data.id && line.parentNodeId != line.id) { 
                let globalStartPoint = new Point(line.x + line.lineContent.startPointX, line.y + line.lineContent.startPointY)
                let degrees = Util.getCircleDegreesInPoint(copyDataCenterPoint, globalStartPoint)
                if (data.x != copyData.x || data.width != copyData.width) {
                    if (data.width == copyData.width) {
                        line.lineContent.startPointX += (data.x - copyData.x);
                    } else {
                        let globalX = line.lineContent.startPointX + line.x;
                        let leftDiff = globalX - copyData.x;
                        let centerDiff = globalX - (copyData.x + copyData.width / 2);
                        let rightDiff = globalX - (copyData.x + copyData.width);
                        let isScale = true;
    
                        if (Math.abs(leftDiff) == Math.min(Math.abs(leftDiff), Math.abs(centerDiff), Math.abs(rightDiff))) {
                            if (leftDiff < 0) {
                                line.lineContent.startPointX -= (copyData.x - data.x);
                                isScale = false
                            }                        
                        } else if (Math.abs(rightDiff) == Math.min(Math.abs(leftDiff), Math.abs(centerDiff), Math.abs(rightDiff))) {
                            if (rightDiff > 0) {
                                line.lineContent.startPointX -= (copyData.x + copyData.width - (data.x + data.width));
                                isScale = false
                            } 
                        } else {
                            if (Math.abs(centerDiff) < 4) {
                                line.lineContent.startPointX -= (copyData.x + copyData.width/2 - (data.x + data.width / 2));
                                isScale = false
                            }
                        }
                        if (isScale) {
                            let xScale = (globalX - copyData.x) / copyData.width
                            let isChange = true;
                            if (xScale > -0.05 && xScale < 0.05) {
                                xScale = 0
                            } else if (xScale > 49.95 && xScale < 50.05) {
                                xScale = 50
                                isScale = false;
                            } else if (xScale > 99.05 && xScale < 100.05) {
                                xScale = 100
                            }
                            if (isScale) {
                                let changeX = data.x + xScale * data.width;
                                line.lineContent.startPointX = changeX - line.x
                            }
                        }
                    }
                }
                if (data.y != copyData.y || data.height != copyData.height) {
                    if (data.height == copyData.height) {
                        line.lineContent.startPointY += (data.y - copyData.y);
                    } else {
                        let globalY = line.lineContent.startPointY + line.y;
                        let topDiff = globalY - copyData.y;
                        let centerDiff = globalY - (copyData.y + copyData.height / 2);
                        let bottomDiff = globalY - (copyData.y + copyData.height);
                        let isScale = true;
    
                        if (Math.abs(topDiff) == Math.min(Math.abs(topDiff), Math.abs(centerDiff), Math.abs(bottomDiff))) {
                            if (topDiff < 0) {
                                line.lineContent.startPointY -= (copyData.y - data.y);
                                isScale = false
                            }                        
                        } else if (Math.abs(bottomDiff) == Math.min(Math.abs(topDiff), Math.abs(centerDiff), Math.abs(bottomDiff))) {
                            if (bottomDiff > 0) {
                                line.lineContent.startPointY -= (copyData.y + copyData.height - (data.y + data.height));
                                isScale = false
                            } 
                        } else {
                            if (Math.abs(centerDiff) < 4) {
                                line.lineContent.startPointY -= (copyData.y + copyData.height/2 - (data.y + data.height / 2));
                                isScale = false
                            }
                        }
                        if (isScale) {
                            let yScale = (globalY - copyData.y) / copyData.height
                            if (yScale > -0.05 && yScale < 0.05) {
                                yScale = 0
                            } else if (yScale > 49.95 && yScale < 50.05) {
                                yScale = 50
                                isScale = false
                            } else if (yScale > 99.05 && yScale < 100.05) {
                                yScale = 100
                            }
                            if (isScale) {
                                let changeY = data.y + yScale * data.height;
                                line.lineContent.startPointY = changeY - line.y
                            }
                        }
                    }
                }
            }

            if (line.lineContent.targetId == data.id && line.lineContent.targetId != line.id) {
                let globalEndPoint = new Point(line.x + line.lineContent.endPointX, line.y + line.lineContent.endPointY)
                let degrees = Util.getCircleDegreesInPoint(copyDataCenterPoint, globalEndPoint)
                if (data.x != copyData.x || data.width != copyData.width) {
                    if (data.width == copyData.width) {
                        line.lineContent.endPointX += (data.x - copyData.x);
                    } else {
                        let globalX = line.lineContent.endPointX + line.x;   
                        let leftDiff = globalX - copyData.x;
                        let centerDiff = globalX - (copyData.x + copyData.width / 2);
                        let rightDiff = globalX - (copyData.x + copyData.width);
                        let isScale = true;
    
                        if (Math.abs(leftDiff) == Math.min(Math.abs(leftDiff), Math.abs(centerDiff), Math.abs(rightDiff))) {
                            if (leftDiff < 0) {
                                line.lineContent.endPointX -= (copyData.x - data.x);
                                isScale = false
                            }                        
                        } else if (Math.abs(rightDiff) == Math.min(Math.abs(leftDiff), Math.abs(centerDiff), Math.abs(rightDiff))) {
                            if (rightDiff > 0) {
                                line.lineContent.endPointX -= (copyData.x + copyData.width - (data.x + data.width));
                                isScale = false
                            } 
                        } else {
                            if (Math.abs(centerDiff) < 4) {
                                line.lineContent.endPointX -= (copyData.x + copyData.width/2 - (data.x + data.width / 2));
                                isScale = false
                            }
                        } 
                        if (isScale) {
                            let xScale = (globalX - copyData.x) / copyData.width
                        
                            if (xScale > -0.05 && xScale < 0.05) {
                                xScale = 0
                            } else if (xScale > 49.95 && xScale < 50.05) {
                                xScale = 50
                                isScale = false
                            } else if (xScale > 99.85 && xScale < 100.05) {
                                xScale = 100
                            }
                            if (isScale) {
                                let changeX = data.x + xScale * data.width;
                                line.lineContent.endPointX = changeX - line.x
                            }
                        }
                    }
                }
                if (data.y != copyData.y || data.height != copyData.height) {
                    if (data.height == copyData.height) {
                        line.lineContent.endPointY += (data.y - copyData.y);
                    } else {
                        let globalY = line.lineContent.endPointY + line.y;    
                        let topDiff = globalY - copyData.y;
                        let centerDiff = globalY - (copyData.y + copyData.height / 2);
                        let bottomDiff = globalY - (copyData.y + copyData.height);
                        let isScale = true;
    
                        if (Math.abs(topDiff) == Math.min(Math.abs(topDiff), Math.abs(centerDiff), Math.abs(bottomDiff))) {
                            if (topDiff < 0) {
                                line.lineContent.endPointY -= (copyData.y - data.y);
                                isScale = false
                            }                        
                        } else if (Math.abs(bottomDiff) == Math.min(Math.abs(topDiff), Math.abs(centerDiff), Math.abs(bottomDiff))) {
                            if (bottomDiff > 0) {
                                line.lineContent.endPointY -= (copyData.y + copyData.height - (data.y + data.height));
                                isScale = false
                            } 
                        } else {
                            if (Math.abs(centerDiff) < 4) {
                                line.lineContent.endPointY -= (copyData.y + copyData.height/2 - (data.y + data.height / 2));
                                isScale = false
                            }
                        }
                        if (isScale) {
                            let yScale = (globalY - copyData.y) / copyData.height                    
                            if (yScale > -0.05 && yScale < 0.05) {
                                yScale = 0
                            } else if (yScale > 49.95 && yScale < 50.05) {
                                yScale = 50
                                isScale = false
                            } else if (yScale > 99.05 && yScale < 100.05) {
                                yScale = 100
                            }
                            if (isScale) {
                                let changeY = data.y + yScale * data.height;
                                line.lineContent.endPointY = changeY - line.y
                            }
                        } 
                    }       
                }
            }
            
            if (line.lineContent.rightAnglePoints != null &&
                line.lineContent.rightAnglePoints.length > 0) {
                for (let j = 0; j < line.lineContent.rightAnglePoints.length; j++) {
                    let point = line.lineContent.rightAnglePoints[j];
                    let changeX = false;
                    let changeY = false;
                    if (point.x == start.x) {
                        point.x = line.lineContent.startPointX;
                        changeX = true;
                    }
                    if (point.y == start.y) {
                        point.y = line.lineContent.startPointY;
                        changeY = true;
                    }
                    if (point.x == end.x) {
                        point.x = line.lineContent.endPointX;
                        changeX = true;
                    }
                    if (point.y == end.y) {
                        point.y = line.lineContent.endPointY;
                        changeY = true;
                    }
                    if (!changeX) {
                        if (end.x - start.x != 0 && (line.lineContent.endPointX - line.lineContent.startPointX) != 0) {
                            let sx = (point.x - start.x) / (end.x - start.x);
                            point.x = (sx * (line.lineContent.endPointX - line.lineContent.startPointX)) + line.lineContent.startPointX;
                        } else {
                            let sy = 1.0;
                            if (Math.abs(line.lineContent.endPointY - line.lineContent.startPointY) > 0 && Math.abs(end.y - start.y) > 0) {
                                sy = Math.abs(end.y - start.y) / Math.abs(line.lineContent.endPointY - line.lineContent.startPointY);
                            }
                            point.x = line.lineContent.startPointX + ((point.x - start.x) / sy);
                        }
                    }
                    if (!changeY) {
                        if (end.y - start.y != 0 && (line.lineContent.endPointY - line.lineContent.startPointY) != 0) {
                            let sy = (point.y - start.y) / (end.y - start.y);
                            point.y = (sy * (line.lineContent.endPointY - line.lineContent.startPointY)) + line.lineContent.startPointY;
                        } else {
                            let sy = 1.0;
                            if (Math.abs(line.lineContent.endPointX - line.lineContent.startPointX) > 0 && Math.abs(end.x - start.x) > 0) {
                                sy = Math.abs(end.x - start.x) / Math.abs(line.lineContent.endPointX - line.lineContent.startPointX);
                            }
                            point.y = line.lineContent.startPointY + ((point.y - start.y) / sy);
                        }
                    }
                }
            }

            nodeConnectLineCalcul.checkLinePoint(line);
            
        }
    }

    moveNode(targetId, moveId, isRefreshAndSave, nodeIndex) {
        if (arguments.length == 2) {
            isRefreshAndSave = true
            nodeIndex = -1
        } else if (arguments.length == 3) {
            nodeIndex = -1
        }
        let node = this.getNodeById(moveId);
        let target = this.getNodeById(targetId);
        if (node.isEmpty() || target.isEmpty()) {
            return node;
        }
        this.addRemoveDataIdToSettingData(node);
        let moveNodeUnit = this.findFreeUnit(moveId);
        let isInSameUnit = (moveNodeUnit == null ? true : (!moveNodeUnit.getNodeById(targetId).isEmpty()));

        let newNode = (new LineMindTypeNode).emptyMindNode();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let findNode = this.mainMindNodeUnit.getNodeById(target.value.id);
            if (!findNode.isEmpty()) {
                newNode = target.stickNode(node, nodeIndex);
                this.mainMindNodeUnit.setNodeToDictStructure(target);
            } else if (this.freeNodes.length > 0) {
                this.freeNodes.forEach(unit => {
                    let freeNode = unit.getNodeById(target.value.id);
                    if (!freeNode.isEmpty()) {
                        newNode = target.stickNode(node, nodeIndex);
                        unit.setNodeToDictStructure(target);
                    }
                });
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let findNode = this.doubleBubbleMindNodeUnit.getNodeById(target.value.id);
            if (!findNode.isEmpty()) {
                newNode = target.stickNode(node, nodeIndex);
                this.doubleBubbleMindNodeUnit.setNodeToDictStructure(target);
            } else {
                this.freeNodes.forEach(unit => {
                    let freeNode = unit.getNodeById(target.value.id);
                    if (!freeNode.isEmpty()) {
                        newNode = target.stickNode(node, nodeIndex);
                        unit.setNodeToDictStructure(target);
                    }
                });
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            this.freeNodes.forEach(unit => {
                let freeNode = unit.getNodeById(target.value.id);
                if (!freeNode.isEmpty()) {
                    newNode = target.stickNode(node, nodeIndex);
                    unit.setNodeToDictStructure(target);
                }
            });
        }
        if (!newNode.isEmpty()) {
            if (moveNodeUnit != null && moveNodeUnit.rootTreeNode != null && !moveNodeUnit.rootTreeNode.isEmpty()) {
                this.mainMindNodeUnit.changeNodeId(node, newNode, moveNodeUnit.generalizationMindElementDataDict,
                    moveNodeUnit.explainMindElementDataDict, moveNodeUnit.encircleMindElementDataDict);
                this.freeNodes.forEach(unit => {
                    unit.changeNodeId(node, newNode, moveNodeUnit.generalizationMindElementDataDict,
                        moveNodeUnit.explainMindElementDataDict, moveNodeUnit.encircleMindElementDataDict);
                });
            } else {
                this.mainMindNodeUnit.changeNodeId(node, newNode, new HashMap(), new HashMap(), new HashMap());
                this.freeNodes.forEach(unit => {
                    unit.changeNodeId(node, newNode, new HashMap(), new HashMap(), new HashMap());
                });
            }



            let list = new NodesRectCalculation().getNodes(node);
            let listCount = list.length
            let idMaps = this.getNodeIdMap(newNode, node);

            let isTargetRoot = this.isRootNode(targetId);
            let targetLines = this.getNodeLine(targetId);
            for (let index = 0; index < listCount; index++) {
                let cell = list[index]

                if (!idMaps.containsKey(cell.id)) {
                    continue;
                }
                let newData = idMaps.get(cell.id);
                if (newData.isEmpty()) {
                    continue;
                }

                if (!isInSameUnit && !isTargetRoot && (newData.mindElementShape == MindElementShapeType.Underline || newData.mindElementShape == MindElementShapeType.Two_Underline) &&
                    !targetLines.isEmpty() && targetLines[0].lineContent != null) {
                    newData.borderColor = targetLines[0].lineContent.color;
                    if (newData.isCardContent()) {
                        newData.checkSetTextColor(this.mindBGColor)
                    }
                }
                let nodeConnectLineValues = this.nodeConnectLineDataDict.values()
                let nodeConnectLineCount = nodeConnectLineValues.length;
                for (let lineIndex = 0; lineIndex < nodeConnectLineCount; lineIndex++) {
                    let nodeConnectCell = nodeConnectLineValues[lineIndex]
                    if (nodeConnectCell.lineContent != null && nodeConnectCell.parentNodeId == cell.id) {
                        nodeConnectCell.parentNodeId = newData.id;
                    } else if (nodeConnectCell.lineContent != null && nodeConnectCell.lineContent.targetId == cell.id) {
                        nodeConnectCell.lineContent.targetId = newData.id;
                    }
                }
            }
            if (!isInSameUnit && moveNodeUnit != null && moveNodeUnit.rootTreeNode != null &&
                node.value.id != moveNodeUnit.rootTreeNode.value.id) {
                moveNodeUnit.removeElementNode(node.value.id);
                this.removeData(node.value.id);
            }
        }
        if (this.mindDisplayType == MindDisplayType.Outline) {
            newNode.outlineCalculationText();
        }
        this.removeData(moveId, isRefreshAndSave);
        if (isRefreshAndSave) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
        return newNode;
    }

    getNodeIdMap(node, original) {
        let idMaps = new HashMap();
        if (node == null || original == null || node.isEmpty() || original.isEmpty()) {
            return idMaps
        }
        idMaps.put(original.value.id, node.value);
        if (node.children.length == original.children.length && node.children.length > 0) {
            for (let index = 0; index < node.children.length; index++) {
                let cellMap = this.getNodeIdMap(node.children[index], original.children[index])
                if (cellMap == null || cellMap.isEmpty()) {
                    continue
                }
                let keys = cellMap.keys()
                for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
                    let key = keys[keyIndex]
                    idMaps.put(key, cellMap.get(key));
                }
            }
        }
        return idMaps
    }

    checkAutoAdsorption(node) {
        if (node.isEmpty()) {
            return false;
        }
        if (!this.checkDataFreeNode(node.value)) {
            return false;
        }
        let timeStamp = Date.parse(new Date());
        if (timeStamp - node.value.moveAutoAdsorptionTime < 2000) {
            return false;
        }
        let copyData = node.value.copy()
        let autoAdsorptionFind = new AutoAdsorptionFind();
        autoAdsorptionFind.setData(this.mindType, 
            this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        let isChange = autoAdsorptionFind.findAndChange(node.value);
        if (isChange) {
            this.dataConnectLinePoint(node.value, copyData);
            node.value.moveAutoAdsorptionTime = timeStamp;
            return true;
        }
        return false;
    }

    checkDataFreeNode(data) {
        if (data.isEmpty()) {
            return false;
        }
        let length = this.freeNodes.length;
        for (let index = 0; index < length; index++) {
            const item = this.freeNodes[index];
            if (item.rootTreeNode != null && item.rootTreeNode.value.id == data.id) {
                return true;
            }

        }
        return false;
    }

    encircleNodes() {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty() || node.value.type == MindElementType.NODE_CONNECT_LINE ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            this.selectAddTargetTips();
            return;
        }

        if (this.selectedList != null && !this.selectedList.isEmpty()) {
            let list = this.getSelectedRootNodes();
            let encircleData = new MindElementData().emptyMindNode();
            if (!list.isEmpty()) { 
                let encircleList = this.mainMindNodeUnit.encircleMindElementDataDict.values();
                for (let index = 0; index < this.freeNodes.length; index++) {
                    encircleList = encircleList.concat(this.freeNodes[index].encircleMindElementDataDict.values())
                }
                let rootListCount = list.length;
                let encircleListCount = encircleList.length;

                for (let i = 0; i < encircleListCount; i++) {
                    let mind = encircleList[i];
                    if (mind.lineContent != null && mind.lineContent.targetIds != null && 
                        mind.lineContent.targetIds.length == list.length) {
                        let exist = true;
                        for (let j = 0; j < rootListCount; j++) {
                            const element = list[j];
                            if (!mind.lineContent.targetIds.includes(element.id)) {
                                exist = false;
                                break
                            }
                        }
                        if (exist) {
                            return
                        }
                    }
                }

                let targetList = new Array();
                list.forEach(data => {
                    targetList.push(data.id);
                    if (encircleData.isEmpty()) {
                        encircleData = this.mainMindNodeUnit.setEncircleNodes(data.id);
                        if (encircleData.isEmpty()) {
                            this.freeNodes.forEach(unit => {
                                if (encircleData.isEmpty()) {
                                    encircleData = unit.setEncircleNodes(this.selectedNodeId);
                                }
                            });
                        }
                    }
                });
                if (!encircleData.isEmpty()) {
                    encircleData.lineContent.targetIds = targetList;
                }
            } else {
                let targetList = new Array();
                let parentId = -1;
                this.selectedList.forEach(data => {
                    if (data.type == MindElementType.SON_SUBJECT ||
                        data.type == MindElementType.SUBJECT) {
                        if (parentId == -1) {
                            parentId = data.parentNodeId;
                        }
                        if (parentId == data.parentNodeId) {
                            targetList.push(data.id);
                        }
                    }
                });
                let encircleList = this.mainMindNodeUnit.encircleMindElementDataDict.values();
                for (let index = 0; index < this.freeNodes.length; index++) {
                    encircleList = encircleList.concat(this.freeNodes[index].encircleMindElementDataDict.values())
                }
                let targetListCount = targetList.length;
                let encircleListCount = encircleList.length;
                for (let i = 0; i < encircleListCount; i++) {
                    let mind = encircleList[i];
                    if (mind.lineContent != null && mind.lineContent.targetIds != null && 
                        mind.lineContent.targetIds.length == targetListCount) {
                        let exist = true;
                        
                        for (let j = 0; j < targetListCount; j++) {
                            const element = targetList[j];
                            if (!mind.lineContent.targetIds.includes(element)) {
                                exist = false;
                                break
                            }
                        }
                        if (exist) {
                            return
                        }
                    }
                }
                targetList = new Array();
                parentId = -1;
                this.selectedList.forEach(data => {
                    if (data.type == MindElementType.SON_SUBJECT ||
                        data.type == MindElementType.SUBJECT) {
                        if (encircleData.isEmpty()) {
                            encircleData = this.mainMindNodeUnit.setEncircleNodes(data.id);
                            if (encircleData.isEmpty()) {
                                this.freeNodes.forEach(unit => {
                                    if (encircleData.isEmpty()) {
                                        encircleData = unit.setEncircleNodes(selectedNodeId);
                                    }
                                });
                            }
                            if (!encircleData.isEmpty()) {
                                parentId = data.parentNodeId;
                                targetList.push(data.id);
                            }
                        } else {
                            if (parentId == data.parentNodeId) {
                                targetList.push(data.id);
                            }
                        }
                    }
                });

                if (!encircleData.isEmpty()) {
                    encircleData.lineContent.targetIds = targetList;
                }
            }
        } else {
            let list = this.getEncircleData(node.value)
            for (let index = 0; index < list.length; index++) {
                const line = list[index];
                if (line.lineContent != null && 
                    ((line.lineContent.targetIds.length == 1 && line.lineContent.targetIds[0] == this.selectedNodeId) ||
                    (line.lineContent.targetIds.length == 0 && line.parentNodeId == this.selectedNodeId))) {
                    return;
                }
                
            }
            this.mainMindNodeUnit.setEncircleNodes(this.selectedNodeId);
            this.freeNodes.forEach(unit => {
                unit.setEncircleNodes(this.selectedNodeId);
            });
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    showNodeChilds(id, isRefresh, openDepth = 0) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {// || node.value.hiddenNumber == 0) {
            return;
        }
        node.value.hiddenNumber = 0;
        this.mainMindNodeUnit.showNodeChilds(id, openDepth);
        this.freeNodes.forEach(unit => {
            unit.showNodeChilds(id, openDepth);
        });
        if (isRefresh) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    checkRemoveEncircleLine(unit) {
        if (unit == null) {
            return
        }
        var removeEncircleMindLineKeys = []
        if (unit.encircleMindElementDataDict != null &&
            !unit.encircleMindElementDataDict.isEmpty()) {

            let encircleDatas = unit.encircleMindElementDataDict.values()

            for (let index = 0; index < encircleDatas.length; index++) {
                let mind = encircleDatas[index]
                if (mind.lineContent == null) {
                    removeEncircleMindLineKeys.push(mind)
                    continue;
                }
                if ((this.isFlowChart() && (mind.customWidth > 0 || mind.customHeight > 0))) {
                    continue;
                }
                let node = this.getNodeById(mind.parentNodeId)
                let hit = !node.isEmpty()
                for (let targetIndex = 0; targetIndex < mind.lineContent.targetIds; targetIndex++) {
                    let cell = this.getNodeById(mind.lineContent.targetIds[targetIndex])
                    if (!cell.isEmpty()) {
                        hit = true
                        break
                    }
                }
                if (!hit) {
                    removeEncircleMindLineKeys.push(mind)
                }
            }
        }
        if (removeEncircleMindLineKeys.length > 0) {
            for (let index = 0; index < removeEncircleMindLineKeys.length; index++) {
                unit.removeEncircleMindElementData(removeEncircleMindLineKeys[index]);
            }
        }
        if (unit.lineMindElementDataDict != null) {
            let mainLines = unit.lineMindElementDataDict.values()
            if (mainLines != null && mainLines.length > 0) {
                for (let i = 0; i < mainLines.length; i++) {
                    let line = mainLines[i];
                    if (line != null && !line.isEmpty() && line.lineContent != null) {
                        if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                            this.removeViewByMindData(line);
                        } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                            this.removeViewByMindData(line);
                        } else if (line.type == MindElementType.FORM_LINE) {
                            this.removeViewByMindData(line);
                        } else if (line.layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
                            this.removeViewByMindData(line);
                        }
                    }
                }
            }
        }
    }

    removeUselessElement() {
        this.mainMindNodeUnit.removeUselessElement()
        this.doubleBubbleMindNodeUnit.removeUselessElement()
        this.freeNodes.forEach(unit => {
            unit.removeUselessElement()
            this.checkRemoveEncircleLine(unit)
        })
        this.checkRemoveEncircleLine(this.mainMindNodeUnit)

        var removeNodeConnectLineKeys = []
        let nodeConnectLineDatas = this.nodeConnectLineDataDict.values();
        for (let index = 0; index < nodeConnectLineDatas.length; index++) {
            let line = nodeConnectLineDatas[index]
            let target = this.getNodeById(line.parentNodeId).value;
            let to = this.getNodeById(line.lineContent.targetId).value;
            if (target.isEmpty() || to.isEmpty()) {
                removeNodeConnectLineKeys.push(line.id);
            }
        }
        removeNodeConnectLineKeys.forEach(id => {
            this.removeViewByMindData(this.nodeConnectLineDataDict.get(id));
            this.nodeConnectLineDataDict.remove(id);
        });
    }

    refresh(isChange = true, isRefreshUI = true, isCheckConnectLine = true, checkNodeViewOffScreen = true) {
        if (this.loadDataModel != null) {
            return
        }

        MindElementCalculation.layoutRefreshCalculeDataList = []
        this.removeUselessElement()
        var refreshForwardRelativePoint = new HashMap();
        var refreshDataForwardRelativePoint = new HashMap();
        if (isCheckConnectLine) {
            let keys = this.nodeConnectLineDataDict.keys();
            let length = keys.length;
            for (let index = 0; index < length; index++) {
                let key = keys[index];
                let line = this.nodeConnectLineDataDict.get(key);
                if (line.lineContent == null) {
                    continue;
                }
                let target = this.getNodeById(line.parentNodeId).value;
                let to = this.getNodeById(line.lineContent.targetId).value;
                if (target.isEmpty() || to.isEmpty()) {
                    continue;
                }
                let lineContent = line.lineContent
                if (lineContent == null || lineContent.startControlPointX == -1 || lineContent.startControlPointY == -1 ||
                    lineContent.endControlPointX == -1 || lineContent.endControlPointY == -1) {
                    continue;
                }
                refreshDataForwardRelativePoint.put(target.id, target.copy());
                refreshDataForwardRelativePoint.put(to.id, to.copy());
                let list = new Array();
                let targetCenterPoint = target.getCenterPoint();
                let toCenterPoint = to.getCenterPoint();
                list.push((targetCenterPoint.x - lineContent.startControlPointX) / target.width);
                list.push((targetCenterPoint.y - lineContent.startControlPointY) / target.height);

                list.push((toCenterPoint.x - lineContent.endControlPointX) / to.width);
                list.push((toCenterPoint.y - lineContent.endControlPointY) / to.height);

                refreshForwardRelativePoint.put(key, list);
            }
        }
        let rootOriginalDatas = [];
        if (this.mainMindNodeUnit.rootTreeNode != null &&
            !this.mainMindNodeUnit.rootTreeNode.isEmpty() &&
            this.mainMindNodeUnit.rootTreeNode.value.x > 1 &&
            this.mainMindNodeUnit.rootTreeNode.value.y > 1 &&
            this.mainMindNodeUnit.rootTreeNode.children.length == 0) {
            rootOriginalDatas.push(this.mainMindNodeUnit.rootTreeNode.value.copy());
        }
        if (this.doubleBubbleMindNodeUnit.rootTreeNode != null &&
            !this.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty() &&
            this.doubleBubbleMindNodeUnit.rootTreeNode.value.x > 1 &&
            this.doubleBubbleMindNodeUnit.rootTreeNode.value.y > 1 &&
            this.doubleBubbleMindNodeUnit.rootTreeNode.children.length == 0) {
            rootOriginalDatas.push(this.doubleBubbleMindNodeUnit.rootTreeNode.value.copy());
        }
        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            if (unit.rootTreeNode != null &&
                !unit.rootTreeNode.isEmpty() &&
                unit.rootTreeNode.children.length == 0) {
                rootOriginalDatas.push(unit.rootTreeNode.value.copy())
            }
        }

        this.mainMindNodeUnit.refreshLayout(isChange, this.settingData);
        if (!isChange && this.mindType == MindType.LINE_MAP &&
            this.globalLayout != NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT &&
            (this.globalLineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                this.globalLineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                this.globalLineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                this.globalLineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                this.globalLineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                this.globalLineLayout == LineLayout.STRAIGHT_LINE_2)) {
            this.mainMindNodeUnit.setlineThicken(true);
        }
        this.doubleBubbleMindNodeUnit.refreshLayout(isChange, this.settingData);
        let node = null;
        if (this.mindDisplayType == MindDisplayType.Outline) {
            node = this.mainMindNodeUnit.getOutlinePreviousNode();
        }
        let allRootNodeTreeRectMaps = new HashMap();
        let allRootNodeTreeRectShapeTypeMaps = new HashMap();
        this.freeNodes.forEach(unit => {
            if (this.mindDisplayType == MindDisplayType.Outline) {
                unit.setOutlinePreviousNode(node)
            }
            unit.refreshLayout(isChange, this.settingData);
            if (checkNodeViewOffScreen && !this.settingData.isOverlap && this.mindDisplayType != MindDisplayType.Outline) {
                let rect = unit.getRectByMargin(0, 0, 2, true);
                let rectShapeType = MindElementShapeType.Unknown;
                if (unit.rootTreeNode.children.length == 0) {
                    rectShapeType = unit.rootTreeNode.value.mindElementShape
                }

                let mindElementFreeNodeCheckPoint = new MindElementFreeNodeCheckPoint();
                mindElementFreeNodeCheckPoint.setData(this.mainMindNodeUnit,
                        this.timeMindTypeNodeUnit,
                        this.doubleBubbleMindNodeUnit,
                        allRootNodeTreeRectMaps,
                        allRootNodeTreeRectShapeTypeMaps);
                mindElementFreeNodeCheckPoint.setRect(rect, rectShapeType);
                let point = mindElementFreeNodeCheckPoint.getPoint(rect);                
                if (point.x != -1 || point.y != -1) {
                    let preData = unit.rootTreeNode.value.copy();                 
                    unit.rootTreeNode.value.x = unit.rootTreeNode.value.x + point.x;
                    unit.rootTreeNode.value.y = unit.rootTreeNode.value.y + point.y;

                    this.checkNodeConnectLinePointByChangeSize(preData, unit.rootTreeNode.value)
                    this.dataConnectLinePoint(unit.rootTreeNode.value, preData);

                    unit.refreshLayout(isChange, this.settingData);
                    allRootNodeTreeRectMaps.put(unit.rootTreeNode.value.id, unit.getRectByMargin(0, 0, 0, true));
                    allRootNodeTreeRectShapeTypeMaps.put(unit.rootTreeNode.value.id, rectShapeType)
                } else {
                    allRootNodeTreeRectMaps.put(unit.rootTreeNode.value.id, unit.getRectByMargin(0, 0, 0, true));
                    allRootNodeTreeRectShapeTypeMaps.put(unit.rootTreeNode.value.id, rectShapeType)
                }
            }
            node = unit.getOutlinePreviousNode()
        });
        this.timeMindTypeNodeUnit.refreshLayout(isChange, this.settingData);
        if (!isCheckConnectLine) {
            let rootOriginalDatasLength = rootOriginalDatas.length;
            for (let index = 0; index < rootOriginalDatasLength; index++) {
                let rootData = rootOriginalDatas[index]
                let cell = this.getNodeById(rootData.id);
                if (cell.isEmpty()) {
                    continue;
                }
                this.checkNodeConnectLinePointByChangeSize(rootData, cell.value);
                
                this.dataConnectLinePoint(cell.value, rootData);
            }
        }

        let keys = refreshForwardRelativePoint.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let key = keys[index];
            if (!this.nodeConnectLineDataDict.containsKey(key)) {
                continue;
            }
            let list = refreshForwardRelativePoint.get(key);

            let line = this.nodeConnectLineDataDict.get(key);
            let target = this.getNodeById(line.parentNodeId).value;
            let to = this.getNodeById(line.lineContent.targetId).value;
            if (target.isEmpty() || to.isEmpty() || list.length != 4) {
                continue;
            }
            let lineContent = line.lineContent;
            if (lineContent == null || lineContent.startControlPointX == -1 || lineContent.startControlPointY == -1 ||
                lineContent.endControlPointX == -1 || lineContent.endControlPointY == -1) {
                continue;
            }
            let targetCenterPoint = target.getCenterPoint();
            let toCenterPoint = to.getCenterPoint();

            let startControlPointX = targetCenterPoint.x - list[0] * target.width;
            if (Math.abs(startControlPointX - lineContent.startControlPointX) > 5) {
                lineContent.startControlPointX = startControlPointX;
            }
            let startControlPointY = targetCenterPoint.y - list[1] * (target.height);
            if (Math.abs(startControlPointY - lineContent.startControlPointY) > 5) {
                lineContent.startControlPointY = startControlPointY;
            }

            let endControlPointX = toCenterPoint.x - list[2] * to.width;
            if (Math.abs(endControlPointX - lineContent.endControlPointX) > 5) {
                lineContent.endControlPointX = endControlPointX
            }
            let endControlPointY = toCenterPoint.y - list[3] * (to.height);
            if (Math.abs(endControlPointY - lineContent.endControlPointY) > 5) {
                lineContent.endControlPointY = endControlPointY;
            }
            let copyTarget = refreshDataForwardRelativePoint.get(target.id)
            let toTarget = refreshDataForwardRelativePoint.get(to.id)
            this.dataConnectLinePoint(target, copyTarget);
            this.dataConnectLinePoint(to, toTarget);
        }
        this.refreshEncircle();
        let nodeConnectLineCalcul = new NodeConnectLineCalcul();
        nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit,
            this.mindBGColor,
            this.lastClickPoint);
        nodeConnectLineCalcul.refreshNodeConnectLineLayout();
        if (isRefreshUI) {
            this.drawElements(isChange, checkNodeViewOffScreen);
        }
        MindElementCalculation.layoutRefreshCalculeDataList = []
    }

    setNodeFastStyle(fastStyleType) {

        let node = this.getNodeById(this.selectedNodeId)
        if (node.isEmpty() ||
            node.value.type == MindElementType.LINE ||
            node.value.type == MindElementType.SON_LINE ||
            node.value.type == MindElementType.BAOWEI_VIEW ||
            node.value.lineContent != null ||
            (node.value.textContent == null && node.value.generalizationContent == null)) {
            return
        }
        let lineDataList = this.getNodeLine(node.value.id)
        let lineData = lineDataList.length > 0 ? lineDataList[0] : null
        this.addChangeDataStyleId(node.value);
        switch (fastStyleType) {
            case QuickStyleType.EXTREMELY_IMPORTANT: //及其重要
                node.value.backgroundColor = Colors.red
                node.value.backgroundColorAlpha = 1.0
                if (!Colors.isClear(node.value.borderColor) &&
                    node.value.borderWidth > 0) {
                    node.value.borderColor = Colors.black
                    if (node.value.borderWidth <= 0) {
                        node.value.borderWidth = 2
                    }
                }

                if (node.value.textContent != null) {
                    node.value.textContent.textBold = true
                }
                if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textBold = true
                }
                node.value.checkSetTextColor(this.mindBGColor)
                break;
            case QuickStyleType.IMPORTANT://重要
                node.value.backgroundColor = 0xFD833C
                node.value.backgroundColorAlpha = 1.0
                if (!Colors.isClear(node.value.borderColor) &&
                    node.value.borderWidth > 0) {
                    node.value.borderColor = Colors.black
                    if (node.value.borderWidth <= 0) {
                        node.value.borderWidth = 1
                    }
                }
                if (node.value.textContent != null) {
                    node.value.textContent.textBold = true
                }
                if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textBold = true
                }
                node.value.checkSetTextColor(this.mindBGColor)
                break;
            case QuickStyleType.COMMONLY://一般
                node.value.backgroundColor = 0xFEFF00
                node.value.backgroundColorAlpha = 1.0
                if (!Colors.isClear(node.value.borderColor) &&
                    node.value.borderWidth > 0) {
                    node.value.borderColor = Colors.black
                    if (node.value.borderWidth <= 0) {
                        node.value.borderWidth = 1
                    }
                }

                if (node.value.textContent != null) {
                    node.value.textContent.textBold = false
                }
                if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textBold = false
                }
                node.value.checkSetTextColor(this.mindBGColor)
                break;
            case QuickStyleType.DELETE: //删除
                if (Colors.isDarkColor(this.mindBGColor)) {
                    if (node.value.textContent != null) {
                        node.value.textContent.textBold = false
                        node.value.textContent.textStrikethrough = true
                    }
                    if (node.value.generalizationContent != null) {
                        node.value.generalizationContent.textBold = false
                        node.value.generalizationContent.textStrikethrough = true
                    }
                } else {
                    if (node.value.textContent != null) {
                        node.value.textContent.textBold = false
                        node.value.textContent.textStrikethrough = true
                    }
                    if (node.value.generalizationContent != null) {
                        node.value.generalizationContent.textBold = false
                        node.value.generalizationContent.textStrikethrough = true
                    }
                }
                break;
            case QuickStyleType.ORDINARY: //正常
                if (Colors.isDarkColor(this.mindBGColor)) {
                    node.value.backgroundColor = Colors.black20
                    node.value.backgroundColorAlpha = 1.0
                    if (node.value.textContent != null) {
                        node.value.textContent.textColor = Colors.black
                        node.value.textContent.textBold = false
                        node.value.textContent.textStrikethrough = false
                    }
                    if (node.value.generalizationContent != null) {
                        node.value.generalizationContent.textColor = Colors.black
                        node.value.generalizationContent.textBold = false
                        node.value.generalizationContent.textStrikethrough = false
                    }
                } else {
                    node.value.backgroundColor = Colors.white
                    node.value.backgroundColorAlpha = 1.0
                    if (!Colors.isClear(node.value.borderColor) &&
                        node.value.borderWidth > 0) {
                        node.value.borderColor = lineData == null ||
                            lineData.lineContent == null ? Colors.black60 : lineData.lineContent.color
                        if (node.value.borderWidth <= 0) {
                            node.value.borderWidth = 1
                        }
                    }

                    if (node.value.textContent != null) {
                        node.value.textContent.textBold = false
                        node.value.textContent.textStrikethrough = false
                    }
                    if (node.value.generalizationContent != null) {
                        node.value.generalizationContent.textBold = false
                        node.value.generalizationContent.textStrikethrough = false
                    }
                    node.value.checkSetTextColor(this.mindBGColor)
                }
                break;
            case QuickStyleType.YELLOW_BG_FULL_DrawOblique: //纹理
                node.value.backgroundColor = 0xFD910B
                node.value.backgroundColorAlpha = 1.0
                node.value.backgroundFullType = MindElementFullType.DrawOblique
                
                if (node.value.textContent != null) {
                    node.value.textContent.textColor = Colors.white
                    node.value.textContent.textShadow = 0x583c01;
                    node.value.textContent.textBold = true
                }
                if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textColor = Colors.white
                    node.value.generalizationContent.textShadow = 0x583c01;
                    node.value.generalizationContent.textBold = true
                }
                break;
            case QuickStyleType.BLUE_BG_FULL_Gradient: //渐变
                node.value.backgroundColor = 0x0087F9
                node.value.backgroundColorAlpha = 1.0
                node.value.backgroundFullType = MindElementFullType.Gradient
                
                if (node.value.textContent != null) {
                    node.value.textContent.textColor = Colors.white
                    node.value.textContent.textShadow = 0x110486;
                    node.value.textContent.textBold = true
                }
                if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textColor = Colors.white
                    node.value.generalizationContent.textShadow = 0x110486;
                    node.value.generalizationContent.textBold = true
                }
                break;
            case QuickStyleType.SHADOW_GREEN: //阴影
                node.value.backgroundColor = 0x45D977
                node.value.backgroundColorAlpha = 1.0
                node.value.backgroundFullType = MindElementFullType.FULL
                
                if (node.value.textContent != null) {
                    node.value.textContent.textColor = Colors.white
                    node.value.textContent.textShadow = 0x178903;
                    node.value.textContent.textBold = true
                }
                if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textColor = Colors.white
                    node.value.generalizationContent.textShadow = 0x178903;
                    node.value.generalizationContent.textBold = true
                }
                break;
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    refreshEncircle() {
        let encircleMindElementDataDict = new HashMap();
        let explainMindElementDataDict  = new HashMap();
        if (this.mainMindNodeUnit != null) {
            let encircleMindElementDataDictArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                let key = encircleMindElementDataDictArr[index];
                encircleMindElementDataDict.put(key, this.mainMindNodeUnit.encircleMindElementDataDict.get(key));
            }
            let explainMindElementDataDictArr = this.mainMindNodeUnit.explainMindElementDataDict.keys();
            for (let index = 0; index < explainMindElementDataDictArr.length; index++) {
                let key = explainMindElementDataDictArr[index];
                explainMindElementDataDict.put(key, this.mainMindNodeUnit.explainMindElementDataDict.get(key));
            }
        }
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let encircleMindElementDataDictArr = unit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                let key = encircleMindElementDataDictArr[index];
                encircleMindElementDataDict.put(key, unit.encircleMindElementDataDict.get(key));
            }
            let explainMindElementDataDictArr = unit.explainMindElementDataDict.keys();
            for (let index = 0; index < explainMindElementDataDictArr.length; index++) {
                let key = explainMindElementDataDictArr[index];
                explainMindElementDataDict.put(key, unit.explainMindElementDataDict.get(key));
            }
        }
        this.refreshEncircleLayout(encircleMindElementDataDict, explainMindElementDataDict);

        let explainMindElementDataDictKeys = explainMindElementDataDict.keys();
        let explainMindElementDataDictKeysCount = explainMindElementDataDictKeys.length;
        for (let index = 0; index < explainMindElementDataDictKeysCount; index++) {
            let key = explainMindElementDataDictKeys[index];
            let mindElementData = explainMindElementDataDict.get(key)

            let parentNode = this.getNodeById(mindElementData.parentNodeId);
            if (!mindElementData.isEmpty() &&
                    !parentNode.isEmpty()) {
                mindElementData.isHidden = parentNode.value.isHidden
            }

            if (mindElementData.isEmpty() ||
                    parentNode.isEmpty() ||
                    parentNode.value.isHidden) {
                continue;
            }
            mindElementData.isHidden = false
            let borderWidth = new UiUtil().dip2px(1);
            if (parentNode.value.borderWidth > 0 && !Colors.isClear(parentNode.value.borderColor)) {
                borderWidth += parentNode.value.borderWidth;
            }
            if (mindElementData.borderWidth > 0 && !Colors.isClear(mindElementData.borderColor)) {
                borderWidth += mindElementData.borderWidth;
            }
            mindElementData.layout = parentNode.value.layout;
            mindElementData.x = parentNode.value.x + (parentNode.value.width - mindElementData.width)/2;
            mindElementData.y = parentNode.value.y - mindElementData.height - Config.NodeFloatExplainSpace - borderWidth;
            mindElementData.lineContent.height = Config.NodeFloatExplainSpace;
            mindElementData.lineContent.width = Config.NodeFloatExplainWidth;
            mindElementData.lineContent.orientation = LineOrientation.BOTTOM;
        }
    }

    refreshEncircleLayout(encircleMindElementDataDict, explainMindElementDataDict) {
        if (encircleMindElementDataDict == null || encircleMindElementDataDict.isEmpty()) {
            return;
        }
        let encircleMindRectDict = new HashMap();
        let encircleFreeIds = [];

        let encircleMindElementDataDictArr = encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = encircleMindElementDataDict.get(key);
            let targetIds = new Array();
            if (mind.lineContent == null) {                
                continue;
            }
            if ( (this.isFlowChart() && (mind.customWidth > 0 || mind.customHeight > 0))) {
                if (mind.lineContent != null && mind.lineContent.isContainText()) {
                    let space = new EncircleNodesPointsCalculation().space
                    if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                        mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - space;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                        mind.lineContent.textContent.width = mind.width;
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else {
                        mind.lineContent.textContent.x = space;
                        mind.lineContent.textContent.y = 0;
                    }
                }
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            if (this.isRootNode(targetIds[0])) {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;
            }            

            let rectList = new Array();
            for (let count = 0; count < targetIds.length; count++) {
                let id = targetIds[count];
                let node = this.getNodeById(id);
                if (node.isEmpty()) {
                    continue;
                }

                let rect = new NodesRectCalculation(explainMindElementDataDict).calcule(node);
                rectList.push(rect);
            }
            if (rectList.isEmpty()) {
                continue;
            }

            let rect = new RectsMergeCalculation().calcule(rectList);
            encircleMindRectDict.put(mind.id, rect);
            encircleFreeIds.push(mind.id);
        }

        if (encircleMindRectDict.isEmpty()) {
            return;
        }
        encircleFreeIds.sort(function (data1, data2) {
            let id1Rect = encircleMindRectDict.get(data1);
            let id2Rect = encircleMindRectDict.get(data2);
            if (id1Rect.width() * id1Rect.height() == id2Rect.width() * id2Rect.height()) {
                return 0;
            } else if (id1Rect.width() * id1Rect.height() < id2Rect.width() * id2Rect.height()) {
                return -1;
            } else {
                return 1;
            }
        })
        let space = new EncircleNodesPointsCalculation().space
        
        for (let index = 0; index < encircleFreeIds.length; index++) {
            let id = encircleFreeIds[index]
            let mind = encircleMindElementDataDict.get(id);
            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let rect = encircleMindRectDict.get(id);
            if (!this.isRootNode(targetIds[0])) {
                let left = 0;
                let top = 0;
                let right = 0;
                let bottom = 0;
                space = new EncircleNodesPointsCalculation().space
                if (index > 0) {
                    let leftList = new HashMap();
                    let topList = new HashMap();
                    let rightList = new HashMap();
                    let bottomList = new HashMap();
                    
                    let encircleMindRectDictKeys = encircleMindRectDict.keys()                    
                    for (let encircleMindRectDictKeysIndex = 0; encircleMindRectDictKeysIndex < encircleMindRectDictKeys.length; encircleMindRectDictKeysIndex++) {
                        let key = encircleMindRectDictKeys[encircleMindRectDictKeysIndex]
                        if (key == id) {
                            continue;
                        }

                        let mindRect = encircleMindRectDict.get(key);
                        if (mindRect.width() <= 0) {
                            continue;
                        }
                        if (Util.isIntersectedForRect(mindRect, rect) &&
                            (rect.width() * rect.height() > mindRect.width() * mindRect.height())) {
                            if (Math.abs(rect.y - mindRect.y) < space) {
                                let mindEncircle = encircleMindElementDataDict.get(key)
                                if (topList.isEmpty()) {
                                    top += space;
                                    if (mindEncircle.lineContent.isContainText()) {
                                        top += mindEncircle.lineContent.textContent.height;
                                    }
                                } else {
                                    let isInclude = false;
                                    let topListValues = topList.values()
                                    for (let topListValuesIndex = 0; topListValuesIndex < topListValues.length; topListValuesIndex++) {
                                        let cellRect = topListValues[topListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        top += space;
                                        if (mindEncircle.lineContent.isContainText()) {
                                            top += mindEncircle.lineContent.textContent.height;
                                        }
                                    }
                                }
                                topList.put(key, mindRect);
                            }
                            if (Math.abs(rect.x - mindRect.x) < space) {
                                if (leftList.isEmpty()) {
                                    left += space;
                                } else {
                                    let isInclude = false;
                                    let leftListValues = leftList.values()
                                    for (let leftListValuesIndex = 0; leftListValuesIndex < leftListValues.length; leftListValuesIndex++) {
                                        let cellRect = leftListValues[leftListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        left += space;
                                    }
                                }
                                leftList.put(key, mindRect);
                            }
                            if (Math.abs(rect.x + rect.width() - mindRect.x - mindRect.width()) < space) {
                                if (rightList.isEmpty()) {
                                    right += space;
                                } else {
                                    let isInclude = false;
                                    let rightListValues = rightList.values()
                                    for (let rightListValuesIndex = 0; rightListValuesIndex < rightListValues.length; rightListValuesIndex++) {
                                        let cellRect = rightListValues[rightListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        right += space;
                                    }
                                }
                                rightList.put(key, mindRect);
                            }
                            if (Math.abs(rect.y + rect.height() - mindRect.y - mindRect.height()) < space) {
                                if (bottomList.isEmpty()) {
                                    bottom += space;
                                } else {
                                    let isInclude = false;
                                    let bottomListValues = bottomList.values()
                                    for (let bottomListValuesIndex = 0; bottomListValuesIndex < bottomListValues.length; bottomListValuesIndex++) {
                                        let cellRect = bottomListValues[bottomListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        bottom += space;
                                    }
                                }
                                bottomList.put(key, mindRect);
                            }
                        }
                    }
                }

                let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;

                if (targetIds.length == 1) {
                    let node = this.getNodeById(targetIds[0]);
                    if (node.isEmpty()) {
                        continue;
                    }
                    let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                    let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;
                    let rect = new NodesRectCalculation(explainMindElementDataDict).calcule(node);
                    mind.x = rect.x - space - padding;
                    mind.y = rect.y - space - padding - textHeight;
                    mind.width = rect.width() + space * 2 + padding * 2;
                    mind.height = rect.height() + space * 2 + padding * 2 + textHeight;

                    if (left > 0) {
                        mind.x = mind.x - left;
                        mind.width = mind.width + left;
                    }
                    if (right > 0) {
                        mind.width = mind.width + right;
                    }
                    if (top > 0) {
                        mind.y = mind.y - top;
                        mind.height = mind.height + top;
                    }
                    if (bottom > 0) {
                        mind.height = mind.height + bottom;
                    }

                    let points = new EncircleNodesPointsCalculation().calcule(node, type, padding);
                    let encircleNodesPoint = new Array();

                    for (let index = 0; index < points.length; index++) {
                        let point = points[index]
                        encircleNodesPoint.push(new Point((point.x) - mind.x, (point.y) - mind.y));
                    }
                    if (mind.lineContent == null) {
                        let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                        lineContent.encircleNodesPoint = encircleNodesPoint;
                        lineContent.strokeColor = node.value.styleColor;
                        mind.lineContent = lineContent;
                    } else {
                        mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                    }
                } else {
                    mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                        EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                    let rectList = new Array();
                    for (let index = 0; index < targetIds.length; index++) {
                        let id = targetIds[index];
                        let node = this.getNodeById(id);
                        if (node.isEmpty()) {
                            continue;
                        }

                        let rect = new NodesRectCalculation(explainMindElementDataDict).calcule(node);
                        rectList.push(rect);
                    }
                    if (rectList.isEmpty()) {
                        continue;
                    }
                    let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                    let rect = new RectsMergeCalculation().calcule(rectList);
                    mind.x = rect.x - space - padding;
                    mind.y = rect.y - space - padding - textHeight;
                    mind.width = rect.width() + space * 2 + padding * 2;
                    mind.height = rect.height() + space * 2 + padding * 2 + textHeight;

                    if (left > 0) {
                        mind.x = mind.x - left;
                        mind.width = mind.width + left;
                    }
                    if (right > 0) {
                        mind.width = mind.width + right;
                    }
                    if (top > 0) {
                        mind.y = mind.y - top;
                        mind.height = mind.height + top;
                    }
                    if (bottom > 0) {
                        mind.height = mind.height + bottom;
                    }

                    mind.lineContent.encircleNodesPoint = new Array();
                }
                if (mind.lineContent != null && mind.lineContent.isContainText()) {
                    if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                        mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - space;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                        mind.lineContent.textContent.width = mind.width;
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else {
                        mind.lineContent.textContent.x = space;
                        mind.lineContent.textContent.y = 0;
                    }
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let left = 0;
                let top = 0;
                let right = 0;
                let bottom = 0;
                space = new EncircleNodesPointsCalculation().space * 1.8;

                if (index > 0) {
                    let leftList = new HashMap();
                    let topList = new HashMap();
                    let rightList = new HashMap();
                    let bottomList = new HashMap();
                    
                    let encircleMindRectDictKeys = encircleMindRectDict.keys()                    
                    for (let encircleMindRectDictKeysIndex = 0; encircleMindRectDictKeysIndex < encircleMindRectDictKeys.length; encircleMindRectDictKeysIndex++) {
                        let key = encircleMindRectDictKeys[encircleMindRectDictKeysIndex]
                        if (key == id) {
                            continue;
                        }

                        let mindRect = encircleMindRectDict.get(key);
                        if (mindRect.width() <= 0) {
                            continue;
                        }
                        if (Util.isIntersectedForRect(mindRect, rect) &&
                            (rect.width() * rect.height() > mindRect.width() * mindRect.height())) {
                            if (Math.abs(rect.y - mindRect.y) < space) {
                                let mindEncircle = encircleMindElementDataDict.get(key)
                                if (topList.isEmpty()) {
                                    top += space;
                                    if (mindEncircle.lineContent.isContainText()) {
                                        top += mindEncircle.lineContent.textContent.height;
                                    }
                                } else {
                                    let isInclude = false;
                                    let topListValues = topList.values()
                                    for (let topListValuesIndex = 0; topListValuesIndex < topListValues.length; topListValuesIndex++) {
                                        let cellRect = topListValues[topListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        top += space;
                                        if (mindEncircle.lineContent.isContainText()) {
                                            top += mindEncircle.lineContent.textContent.height;
                                        }
                                    }
                                }
                                topList.put(key, mindRect);
                            }
                            if (Math.abs(rect.x - mindRect.x) < space) {
                                if (leftList.isEmpty()) {
                                    left += space;
                                } else {
                                    let isInclude = false;
                                    let leftListValues = leftList.values()
                                    for (let leftListValuesIndex = 0; leftListValuesIndex < leftListValues.length; leftListValuesIndex++) {
                                        let cellRect = leftListValues[leftListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        left += space;
                                    }
                                }
                                leftList.put(key, mindRect);
                            }
                            if (Math.abs(rect.x + rect.width() - mindRect.x - mindRect.width()) < space) {
                                if (rightList.isEmpty()) {
                                    right += space;
                                } else {
                                    let isInclude = false;
                                    let rightListValues = rightList.values()
                                    for (let rightListValuesIndex = 0; rightListValuesIndex < rightListValues.length; rightListValuesIndex++) {
                                        let cellRect = rightListValues[rightListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        right += space;
                                    }
                                }
                                rightList.put(key, mindRect);
                            }
                            if (Math.abs(rect.y + rect.height() - mindRect.y - mindRect.height()) < space) {
                                if (bottomList.isEmpty()) {
                                    bottom += space;
                                } else {
                                    let isInclude = false;
                                    let bottomListValues = bottomList.values()
                                    for (let bottomListValuesIndex = 0; bottomListValuesIndex < bottomListValues.length; bottomListValuesIndex++) {
                                        let cellRect = bottomListValues[bottomListValuesIndex]
                                        if (Util.isIntersectedForRect(mindRect, cellRect)) {
                                            isInclude = true;
                                            break;
                                        }
                                    }
                                    if (isInclude) {
                                        bottom += space;
                                    }
                                }
                                bottomList.put(key, mindRect);
                            }
                        }
                    }
                }

                let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
                if (mind.lineContent != null && mind.lineContent.isContainText()) {
                    mind.lineContent.textContent.x = space;
                    mind.lineContent.textContent.y = 0;
                }
                mind.x = rect.x - space - padding;
                mind.y = rect.y - space - padding - textHeight;
                mind.width = rect.width() + space * 2 + padding * 2;
                mind.height = rect.height() + space * 2 + padding * 2 + textHeight;
                if (left > 0) {
                    mind.x = mind.x - left;
                    mind.width = mind.width + left;
                }
                if (right > 0) {
                    mind.width = mind.width + right;
                }
                if (top > 0) {
                    mind.y = mind.y - top;
                    mind.height = mind.height + top;
                }
                if (bottom > 0) {
                    mind.height = mind.height + bottom;
                }

                mind.lineContent.encircleNodesPoint = new Array();

                if (mind.lineContent != null && mind.lineContent.isContainText()) {
                    if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                        mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - space;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                        mind.lineContent.textContent.width = mind.width;
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else {
                        mind.lineContent.textContent.x = space;
                        mind.lineContent.textContent.y = 0;
                    }
                }
            }
        }
    }

    serializeNodeElementsToListForTimeLine(list) {
        if (this.timeMindTypeNodeUnit == null || this.timeMindTypeNodeUnit.title == null || this.timeMindTypeNodeUnit.title.isEmpty()) {
            return;
        }
        list.push(this.timeMindTypeNodeUnit.title);
        let values = this.timeMindTypeNodeUnit.mainMindElementDataDict.values()
        for (let index = 0; index < values.length; index++) {
            let data = values[index];
            list.push(data.head);
            list.push(data.title);
            list.push(data.desc);
        }
    }

    serializeNodeElementsToListForDoubleBubble(node, list) {
        if (node == null || node.isEmpty()) {
            return;
        }
        var contain = false
        for (let index = 0; index < list.length; index++) {
            const item = list[index];
            if (item.id == node.value.id) {
                contain = true
                break
            }
        }
        if (!contain) {
            list.push(node.value);
        }
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.serializeNodeElementsToListForDoubleBubble(node.children[index], list);
        }
    }

    serializeNodeElementsToList(node, list, nodeGeneralizationChildren = false) {
        if (node == null || node.isEmpty()) {
            return
        }
        var contain = false
        for (let index = 0; index < list.length; index++) {
            const item = list[index];
            if (item.value.id == node.value.id) {
                contain = true
                break
            }
        }
        if (!contain) {
            list.push(node);
        }
        
        let nodeGeneralization = this.getNodeGeneralization(node);
        if (!nodeGeneralization.isEmpty()) {
            contain = false
            for (let index = 0; index < list.length; index++) {
                const item = list[index];
                if (item.value.id == nodeGeneralization.value.id) {
                    contain = true
                    break
                }
            }
            if (!contain) {
                list.push(nodeGeneralization);
                if (nodeGeneralizationChildren) {
                    for (let index = 0; index < nodeGeneralization.children.length; index++) {
                        this.serializeNodeElementsToList(nodeGeneralization.children[index], list, nodeGeneralizationChildren);
                    }
                }
            }
        }
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.serializeNodeElementsToList(node.children[index], list, nodeGeneralizationChildren);
        }
    }

    getNodeGeneralization(targetNode) {
        let node = this.mainMindNodeUnit.getNodeGeneralizationByNode(targetNode);
        if (!node.isEmpty()) {
            return node;
        }

        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            node = unit.getNodeGeneralizationByNode(targetNode);
            if (!node.isEmpty()) {
                return node;
            }
        }
        return node;
    }

    getNodeGeneralizationLine(generalization) {
        let line = this.mainMindNodeUnit.getGeneralizationNodeLine(generalization.value.id);
        if (!line.isEmpty()) {
            return line;
        }
        let freeNodesContent = this.freeNodes.length;
        for (var index = 0; index < freeNodesContent; index++) {
            // for (LineMindTypeNodeUnit unit : freeNodes) {
            let unit = this.freeNodes[index];
            line = unit.getGeneralizationNodeLine(generalization.value.id);
            if (!line.isEmpty()) {
                return line;
            }
        }
        return line;
    }

    outTextContent() {
        let list = new Array();
        let lineList = new Array();

        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list);
            let freeNodesLength = this.freeNodes.length;
            for (let i = 0; i < freeNodesLength; i++) {
                const unit = this.freeNodes[i];
                this.serializeNodeElementsToList(unit.rootTreeNode, list);
            }
            let lineLength = list.length;
            for (let index = 0; index < lineLength; index++) {
                let node = list[index];
                var lineTab = ""
                if (node.value.level > 0) {
                    let maxIndex = Math.min(node.value.level, 4)
                    for (let levelIndex = 1; levelIndex < maxIndex; levelIndex++) {
                        lineTab = lineTab + "  ";
                    }
                }

                if (node.value.textContent != null) {
                    lineList.push(lineTab + node.value.textContent.text)
                }
                if (!new Strings().isEmpty(node.value.explain)) {
                    lineList.push(lineTab + node.value.explain)
                }
                if (node.value.generalizationContent != null) {
                    lineList.push(lineTab + node.value.generalizationContent.text)
                }
            }
        }
        return lineList
    }

    drawElements(isChange, checkNodeViewOffScreen = true) {
        if (this.loadDataModel != null) {
            return
        }
        if (this.mindDisplayType == MindDisplayType.Outline) {
            let list = new Array();
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
                this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list);
                let freeNodesLength = this.freeNodes.length;
                for (let i = 0; i < freeNodesLength; i++) {
                    const unit = this.freeNodes[i];
                    this.serializeNodeElementsToList(unit.rootTreeNode, list);
                }
                for (let index = 0; index < list.length; index++) {
                    let node = list[index];
                    this.mindNodeViewAddAndChange(node.value, isChange, index, this.globalLayout, checkNodeViewOffScreen);
                }
            }
        } else {
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
                let addFishLines = [];
                let radiateLine = [];
                let customLine = [];
                this.mainMindNodeUnit.lineMindElementDataDict.values().forEach(line => {
                    if (line.isEmpty() || line.lineContent == null) {
                        return;
                    }
                    if (line.layout == NodeLayoutType.LAYOUT_CIRCLE) {
                        let node = this.getNodeById(line.lineContent.targetId);
                        if (this.mainMindNodeUnit.rootTreeNode.value.layout != NodeLayoutType.LAYOUT_CIRCLE ||
                            node.children.isEmpty() || node.children[0].value.isHidden || node.children[0].value.isLayoutHidden) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, this.globalLayout, checkNodeViewOffScreen);
                        }
                    } else if (line.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        if (this.mainMindNodeUnit.rootTreeNode.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                            this.removeViewByMindData(line);
                        } else {
                            radiateLine.push(line)
                        }
                    } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                        if (line.lineContent == null) {
                            this.removeViewByMindData(line);
                        } else {                            
                            let fishNode = this.getNodeById(line.lineContent.targetId);
                            
                            if (fishNode.isEmpty() ||
                                fishNode.children.isEmpty() || fishNode.children[0].value.isHidden ||
                                (fishNode.value.layout != NodeLayoutType.LAYOUT_FISH_RIGHT && fishNode.value.layout != NodeLayoutType.LAYOUT_FISH_LEFT)) {
                                this.removeViewByMindData(line);
                            } else {
                                addFishLines.push(line);
                            }
                        }
                    } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        if (line.lineContent == null || line.isHidden ) {
                            this.removeViewByMindData(line);
                        } else {
                            let targetNode = this.getNodeById(line.lineContent.targetId);
                            if (targetNode.isEmpty() ||
                                targetNode.value.isHidden ||
                                (this.mainMindNodeUnit.rootTreeNode.value.layout != NodeLayoutType.LAYOUT_RADIATE &&
                                    targetNode.value.id == this.mainMindNodeUnit.rootTreeNode.value.id)) {
                                this.removeViewByMindData(line);
                            } else {
                                customLine.push(line)
                            }
                        }
                    } else if (((this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FORM || 
                        this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                        !this.mainMindNodeUnit.isGeneralizationNodeById(line.parentNodeId) &&
                        (line.isHidden ||
                                line.type == MindElementType.LEFTBRACELINE ||
                                line.type == MindElementType.BAOWEI_VIEW))) {
                        this.removeViewByMindData(line);
                    } else if (this.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
                        let targetData = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId);
                        if (!targetData.isEmpty() && targetData.value.type == MindElementType.SUBJECT) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                        }
                    } else if (line.type == MindElementType.FORM_LINE) {
                        let node = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId)
                        if (node.isEmpty() || node.value.isHidden || (node.value.layout != NodeLayoutType.LAYOUT_FORM && node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL)) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                        }
                    } else {
                        let node = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId)
                        if (node.isEmpty() || node.value.isHidden) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                        }
                    }
                });
                let addFishLinesSize = addFishLines.length
                for (let index = 0; index < addFishLinesSize; index++) {
                    this.mindNodeViewAddAndChange(addFishLines[index], isChange, -1, addFishLines[index].layout, checkNodeViewOffScreen);
                }
                if (radiateLine.length > 0) {
                    radiateLine = this.getDesOrderRadiat(radiateLine, "width")
                    for (let index = 0; index < radiateLine.length; index++) {
                        this.mindNodeViewAddAndChange(radiateLine[index], isChange, -1, radiateLine[index].layout, checkNodeViewOffScreen);
                    }
                }
                for (let index = 0; index < customLine.length; index++) {
                    this.mindNodeViewAddAndChange(customLine[index], isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                }
                this.mainMindNodeUnit.mainMindElementDataDict.values().forEach(node => {
                    this.mindNodeViewAddAndChange(node.value, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                });

                this.mainMindNodeUnit.generalizationLineMindElementDataDict.values().forEach(line => {
                    if (((this.globalLayout == NodeLayoutType.LAYOUT_FORM || this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                        line.type == MindElementType.LEFTBRACELINE) ||
                        this.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                        this.mainMindNodeUnit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE ||
                        this.mainMindNodeUnit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        this.removeViewByMindData(line);
                    } else {
                        this.mindNodeViewAddAndChange(line, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                    }
                });

                this.mainMindNodeUnit.generalizationMindElementDataDict.values().forEach(node => {
                    if (this.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                        this.mainMindNodeUnit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE ||
                        this.mainMindNodeUnit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        this.removeViewByMindNodeInUi(node);
                    } else {
                        this.mindNodeViewAddAndChange(node.value, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                    }
                });
                this.mainMindNodeUnit.explainMindElementDataDict.values().forEach(data => {
                    if (this.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                        this.globalLayout == NodeLayoutType.LAYOUT_FORM || this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        this.removeViewByMindData(data);
                    } else {
                        this.mindNodeViewAddAndChange(data, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                    }
                });
                let encircleDatas = this.mainMindNodeUnit.encircleMindElementDataDict.values();
                let encircleDatasSize = encircleDatas.length;
                for (let index = 0; index < encircleDatasSize; index++) {
                    const mind = encircleDatas[index];
                    if (mind.lineContent == null) {
                        continue;
                    }
                    let isAddView = false
                    if (mind.lineContent.targetIds.length > 0) {
                        for (let targetIndex = 0; targetIndex < mind.lineContent.targetIds.length; targetIndex++) {
                            const id = mind.lineContent.targetIds[targetIndex];
                            if (this.isRootNode(id)) {
                                this.mindNodeViewAddAndChange(mind, isChange, -1, this.mainMindNodeUnit.globalLayout, checkNodeViewOffScreen);
                                isAddView = true;
                                break;
                            }
                        }
                    }
                    if (isAddView) {
                        continue;
                    }
                    if ((this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FORM || 
                        this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                        mind.type == MindElementType.BAOWEI_VIEW) {
                        this.removeViewByMindData(mind);
                    } else {
                        this.mindNodeViewAddAndChange(mind, isChange, -1, this.mainMindNodeUnit.globalLayout, checkNodeViewOffScreen);
                    }
                }
            } else if (this.mindType == MindType.TIME_MAP) {
                this.mindNodeViewAddAndChange(this.timeMindTypeNodeUnit.title, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                this.mindNodeViewAddAndChange(this.timeMindTypeNodeUnit.baseLine, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                this.timeMindTypeNodeUnit.mainMindElementDataDict.values().forEach(data => {
                    this.mindNodeViewAddAndChange(data.head, -1, this.globalLayout, checkNodeViewOffScreen);
                    this.mindNodeViewAddAndChange(data.title, -1, this.globalLayout, checkNodeViewOffScreen);
                    this.mindNodeViewAddAndChange(data.desc, -1, this.globalLayout, checkNodeViewOffScreen);
                });
            } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                this.doubleBubbleMindNodeUnit.lineMindElementDataDict.values().forEach(line => {
                    this.mindNodeViewAddAndChange(line, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                });

                this.doubleBubbleMindNodeUnit.mainMindElementDataDict.values().forEach(node => {
                    this.mindNodeViewAddAndChange(node.value, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                });

                this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.forEach(data => {
                    this.mindNodeViewAddAndChange(data, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                });
            }

            this.freeNodes.forEach(unit => {
                let addFishLines = [];
                let radiateLine = [];
                let customLine = [];
                unit.lineMindElementDataDict.values().forEach(line => {
                    if (line.layout == NodeLayoutType.LAYOUT_CIRCLE) {
                        let node = this.getNodeById(line.lineContent.targetId);
                        if (unit.rootTreeNode.value.layout != NodeLayoutType.LAYOUT_CIRCLE ||
                            node.children.isEmpty() || node.children[0].value.isHidden || node.children[0].value.isLayoutHidden) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, 1, unit.globalLayout, checkNodeViewOffScreen);
                        }
                    } else if (line.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        let node = this.getNodeById(line.lineContent.targetId);
                        if (unit.rootTreeNode.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                            this.removeViewByMindData(line);
                        } else {
                            radiateLine.push(line);
                        }
                    } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                        if (line.lineContent == null) {
                            this.removeViewByMindData(line);
                        } else {
                            let fishNode = this.getNodeById(line.lineContent.targetId);
                            if (fishNode.isEmpty() ||
                                fishNode.children.isEmpty() || fishNode.children[0].value.isHidden ||
                                (fishNode.value.layout != NodeLayoutType.LAYOUT_FISH_RIGHT && fishNode.value.layout != NodeLayoutType.LAYOUT_FISH_LEFT)) {
                                this.removeViewByMindData(line);
                            } else {
                                addFishLines.push(line);
                            }
                        }
                    } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        if (line.lineContent == null || line.isHidden) {
                            this.removeViewByMindData(line);
                        } else {
                            let targetNode = this.getNodeById(line.lineContent.targetId);
                            if (targetNode.isEmpty() ||
                                targetNode.value.isHidden ||
                                (unit.rootTreeNode.value.layout != NodeLayoutType.LAYOUT_RADIATE &&
                                    targetNode.value.id == unit.rootTreeNode.value.id)) {
                                this.removeViewByMindData(line);
                            } else {
                                customLine.push(line)
                            }
                        }
                    } else if ((unit.globalLayout == NodeLayoutType.LAYOUT_FORM || unit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                        !unit.isGeneralizationNodeById(line.parentNodeId) &&
                        (line.isHidden ||
                                line.type == MindElementType.LEFTBRACELINE ||
                                line.type == MindElementType.BAOWEI_VIEW)) {
                        this.removeViewByMindData(line);
                    } else if (unit.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
                        let targetData = unit.getNodeById(line.parentNodeId);
                        if (!targetData.isEmpty() && unit.rootTreeNode.value.id == targetData.value.id) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                        }
                    } else if (line.type == MindElementType.FORM_LINE) {
                        let node = unit.getNodeById(line.lineContent.targetId)
                        if (node.isEmpty() || node.value.isHidden || (node.value.layout != NodeLayoutType.LAYOUT_FORM && node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL)) {
                            this.removeViewByMindData(line);
                        } else {
                            this.mindNodeViewAddAndChange(line, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                        }
                    } else {
                        this.mindNodeViewAddAndChange(line, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                    }
                });
                let addFishLinesSize = addFishLines.length
                for (let index = 0; index < addFishLinesSize; index++) {
                    this.mindNodeViewAddAndChange(addFishLines[index], isChange, -1, addFishLines[index].layout, checkNodeViewOffScreen);
                }
                if (radiateLine.length > 0) {
                    radiateLine = this.getDesOrderRadiat(radiateLine, "width")
                    for (let index = 0; index < radiateLine.length; index++) {
                        this.mindNodeViewAddAndChange(radiateLine[index], isChange, -1, radiateLine[index].layout, checkNodeViewOffScreen);
                    }
                }
                for (let index = 0; index < customLine.length; index++) {
                    this.mindNodeViewAddAndChange(customLine[index], isChange, -1, customLine[index].layout, checkNodeViewOffScreen);
                }
                unit.mainMindElementDataDict.values().forEach(node => {
                    this.mindNodeViewAddAndChange(node.value, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                });
                unit.generalizationLineMindElementDataDict.values().forEach(line => {
                    if (unit.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                        unit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE ||
                        unit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        this.removeViewByMindData(line);
                    } else {
                        this.mindNodeViewAddAndChange(line, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                    }
                });
                let explainMindElementDatas = unit.explainMindElementDataDict.values()
                explainMindElementDatas.forEach(data => {
                    if (unit.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                        unit.globalLayout == NodeLayoutType.LAYOUT_FORM ||
                        unit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        this.removeViewByMindData(data);
                    } else {
                        this.mindNodeViewAddAndChange(data, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                    }
                });
                unit.generalizationMindElementDataDict.values().forEach(node => {
                    if (unit.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                        unit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE ||
                        unit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                        this.removeViewByMindNodeInUi(node.value);
                    } else {
                        this.mindNodeViewAddAndChange(node.value, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                    }
                });
                let encircleDatas = unit.encircleMindElementDataDict.values();
                let encircleDatasSize = encircleDatas.length;
                for (let index = 0; index < encircleDatasSize; index++) {
                    const mind = encircleDatas[index];
                    if (mind.lineContent == null) {
                        continue;
                    }
                    let isAddView = false
                    if (mind.lineContent.targetIds.length > 0) {
                        for (let targetIndex = 0; targetIndex < mind.lineContent.targetIds.length; targetIndex++) {
                            const id = mind.lineContent.targetIds[targetIndex];
                            if (this.isRootNode(id)) {
                                this.mindNodeViewAddAndChange(mind, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                                isAddView = true;
                                break;
                            }
                        }
                    }
                    if (isAddView) {
                        continue;
                    }
                    if ((unit.globalLayout == NodeLayoutType.LAYOUT_FORM || unit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                        mind.type == MindElementType.BAOWEI_VIEW) {
                        this.removeViewByMindData(mind);
                    } else {
                        this.mindNodeViewAddAndChange(mind, isChange, -1, unit.globalLayout, checkNodeViewOffScreen);
                    }
                }
            });

            this.nodeConnectLineDataDict.values().forEach(line => {
                let startData = this.getNodeById(line.parentNodeId);
                let endData = this.getNodeById(line.lineContent.targetId);
                if (startData.isEmpty() || endData.isEmpty() ||
                    startData.value.isHidden || endData.value.isHidden) {
                    this.removeViewByMindData(line);
                } else {
                    this.mindNodeViewAddAndChange(line, isChange, -1, this.globalLayout, checkNodeViewOffScreen);
                }
            });
        }
        //添加和删除audio
        if (this.isGlobalAudio()) {
            this.delegate.addGlobalAudio();
        } else {
            this.delegate.removeGlobalAudio();
        }
        this.delegate.refreshMindLayout();
    }

    getDesOrderRadiat(lineList, property) {
        let list = new Array();
        lineList.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1[property] < data2[property]) {
                return 1;
            } else if (data1.width == data2.width) {
                return 0;
            } else {
                return -1;
            }
        })
        return list;
    }

    getAscOrderRadiat(lineList, property) {
        let list = new Array();
        lineList.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1[property] < data2[property]) {
                return -1;
            } else if (data1.width == data2.width) {
                return 0;
            } else {
                return 1;
            }
        })
        return list;
    }

    removeViewByMindNodeInUi(node) {
        let lines = this.getNodeLine(node.value.id);
        let linesCount = lines.length
        for (var i = 0; i < linesCount; i++) {
            let cell = lines[i];
            this.removeViewByMindData(cell);
        }
        this.removeViewByMindData(node.value);
        let nodeChildrenCount = node.children.length
        for (var j = 0; j < nodeChildrenCount; j++) {
            let child = node.children[j]
            this.removeViewByMindNodeInUi(child);
        }
    }

    mindNodeViewAddAndChange(data, isChange, index, layout, checkNodeViewOffScreen = true) {        
        if (this.mindDisplayType == MindDisplayType.MindMap && data.isHidden ||
            ((data.x <= 0 || data.y <= 0) && this.mindDisplayType == MindDisplayType.MindMap)) {
            this.removeViewByMindData(data);
        } else {
            if (isChange) {
                this.delegate.changeMindContentView(data, index, checkNodeViewOffScreen);
            } else {
                this.delegate.addMindContentView(data, index, checkNodeViewOffScreen);
            }
        }
    }

    lineControlPointMove(x, y, isStart) {
        let data = this.getSelectedNode();
        if (data.isEmpty() || data.value.type != MindElementType.NODE_CONNECT_LINE || data.value.lineContent == null) {
            return;
        }
        let change = false;
        if (isStart) {
            change = data.value.lineContent.startControlPointX != x || data.value.lineContent.startControlPointY != y;
            data.value.lineContent.startControlPointX = x;
            data.value.lineContent.startControlPointY = y;
        } else {
            change = data.value.lineContent.endControlPointX != x || data.value.lineContent.endControlPointY != y;
            data.value.lineContent.endControlPointX = x;
            data.value.lineContent.endControlPointY = y;
        }
        if (change) {
            let nodeConnectLineCalcul = new NodeConnectLineCalcul();
            nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
                this.freeNodes,
                this.nodeConnectLineDataDict,
                this.timeMindTypeNodeUnit,
                this.doubleBubbleMindNodeUnit);
            nodeConnectLineCalcul.setConnectLine(data.value, false);

            this.mindNodeViewAddAndChange(data.value, true, -1, this.globalLayout);
            this.willSave(true);
        }
    }

    linePointMove(x, y, isStart) {
        let data = this.getSelectedNode();
        if (data.isEmpty() || data.value.type != MindElementType.NODE_CONNECT_LINE || data.value.lineContent == null) {
            return;
        }
        
        let nodeConnectLineCalcul = new NodeConnectLineCalcul();
        nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        nodeConnectLineCalcul.moveConnectLinePoint(data.value, new Point(x, y), isStart);
        this.mindNodeViewAddAndChange(data.value, true, -1, this.globalLayout);
        this.willSave(true);
    }

    changeDataAngle(value, id) {
        let data = this.getNodeById(id);
        if (data.isEmpty()) {
            return;
        }
        data.value.angle = value
        this.mindNodeViewAddAndChange(data.value, true, -1, this.globalLayout);
        this.willSave(true);
    }

    rightAngleLinePointMove(x, y, index) {
        let data = this.getSelectedNode();
        if (data.isEmpty() || data.value.type != MindElementType.NODE_CONNECT_LINE || data.value.lineContent == null) {
            return;
        }
        
        let nodeConnectLineCalcul = new NodeConnectLineCalcul();
        nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        nodeConnectLineCalcul.moveConnectLineRightAnglePoint(data.value, new Point(x, y), index);
        this.mindNodeViewAddAndChange(data.value, true, -1, this.globalLayout);
        this.willSave(true);
    }

    getNodeExplain(id) {
        let data = this.mainMindNodeUnit.getNodeExplainById(id);
        if (!data.isEmpty()) {
            return data;
        }
        let freeNodesCount = this.freeNodes.length
        for (let index = 0; index < freeNodesCount; index++) {
            let unit = this.freeNodes[index];
            data = unit.getNodeExplainById(id);
            if (!data.isEmpty()) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getLineDataById(id) {
        let line = new MindElementData()
        if (this.mainMindNodeUnit != null) {
            line = this.mainMindNodeUnit.getLineDataById(id);
            if (!line.isEmpty()) {
                return line;
            }
        }
        
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            line = unit.getLineDataById(id);
            if (!line.isEmpty()) {
                return line;
            }
        }
        if (this.doubleBubbleMindNodeUnit != null) {
            line = this.doubleBubbleMindNodeUnit.getLineDataById(id);
            if (!line.isEmpty()) {
                return line;
            }
        }
        return line;
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    getSelectedNode() {
        return this.getNodeById(this.selectedNodeId);
    }

    isSelectedNodeEmpty() {
        return this.getSelectedNode();
    }

    removeViewByMindData(data) {
        if (data == null) {
            return
        }
        this.delegate.removeElementView(data);
    }

    getGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            //布局
            return NodeLayoutType.LAYOUT_TREE_LEFT;
        } else {
            return this.globalLayout;
        }
    }

    getFreeGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return NodeLayoutType.LAYOUT_BUBBLE;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            return NodeLayoutType.LAYOUT_TOP_TREE_RIGHT;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
            return NodeLayoutType.LAYOUT_TREE_RIGHT;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_VERTICAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            this.globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT ||
            this.globalLayout == NodeLayoutType.LAYOUT_FORM || 
            this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE ||
            this.globalLayout == NodeLayoutType.LAYOUT_RADIATE) {
            return NodeLayoutType.LAYOUT_RIGHT;
        } else {
            return this.globalLayout;
        }
    }

    getMindType() {
        return this.mindType;
    }

    isHasCopyStyle() {
        return MindCopyManager.isHasCopyStyleData();;
    }

    setCopyStyleId(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        MindCopyManager.setCopyStyleData(node.value);
    }

    isHasCopyNode() {
        return MindCopyManager.isHasCopyNode();
    }

    setCopyNodeId(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        let list = new NodesRectCalculation().getNodes(node);
        let generalizationLines = new Array(); //概要线
        let generalizationNodes = new Array(); //概要节点
        let encircles = new Array(); //包裹节点

        let explainMindElementDataDict = new HashMap(); //浮窗节点

        let lineMindElementDataDict = new HashMap(); //主体线字典， Int为线ID data为line
        let textElementLineMindElementDataDict = new HashMap(); //主体节点对应的线，Int为节点ID，data为line

        let listContent = list.length;
        for (var index = 0; index < listContent; index++) {
            let cell = list[index];
            let encircleLines = this.getEncircleData(cell);

            if (encircleLines.length > 0) {
                encircles = encircles.concat(encircleLines);
            }

            let generalizationNode = this.getNodeGeneralization(cell);
            if (!generalizationNode.isEmpty()) {
                generalizationNodes.push(generalizationNode);
                let line = this.getNodeGeneralizationLine(generalizationNode);
                if (!line.isEmpty()) {
                    generalizationLines.push(line);
                }
            }

            let lines = this.getNodeLine(cell.id);
            if (!lines.isEmpty()) {
                lineMindElementDataDict.put(lines[0].id, lines[0]);
                textElementLineMindElementDataDict.put(cell.id, lines[0]);
            }

            let explainMindElementData = this.getNodeExplain(cell.id);
            if (!explainMindElementData.isEmpty()) {
                explainMindElementDataDict.put(explainMindElementData.id, explainMindElementData);
            }
        }
        MindCopyManager.setCopyNode(node, encircles, generalizationNodes, generalizationLines, lineMindElementDataDict, textElementLineMindElementDataDict, explainMindElementDataDict);
    }

    stickStyle(targetId) {
        if (!MindCopyManager.isHasCopyStyleData()) {
            return;
        }
        let data = MindCopyManager.getStyleData();
        let target = this.getNodeById(targetId);
        if (data == null || data.isEmpty() || target.isEmpty()) {
            return;
        }
        MindCopyManager.setCopyStyleData(data)
        target.stickStyleByNode(new LineMindTypeNode(data));
        MindElementCalculation.set(target.value).caluleTextForData();
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    getHitMindElementDataByPoint(id, point) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return new MoveMindElementDataInfo();
        }
        if (this.isRootNode(id) && !this.settingData.freeNodeMoveAdsorbent.isValue()) {
            return new MoveMindElementDataInfo();
        }

        let isMainRootNodeChild = this.mainMindNodeUnit.isRootNode(node.value.parentNodeId)
        if (isMainRootNodeChild && this.settingData.branchNodeFreelayout.isValue() && this.isBranchNodeFreelayoutMode()) {
            return new MoveMindElementDataInfo();
        }

        let mindElementHitCalculation = new MoveMindElementDataByPoint();
        mindElementHitCalculation.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        let targetNode = node.copy();
        targetNode.value.x = point.x;
        targetNode.value.y = point.y;
        let info = mindElementHitCalculation.hit(new CGPoint(point.x, point.y), targetNode);
        return info;
    }

    hiddenNodeChilds(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        this.mainMindNodeUnit.hiddenNodeChilds(id);
        this.freeNodes.forEach((unit) => {
            unit.hiddenNodeChilds(id);
        })

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    showNodeChildsTrue(id, openDepth = 0) { //将原来的showNodeChilds 改为 showNodeChildsTrue
        this.showNodeChilds(id, true, openDepth);
    }

    isSelected() {
        return false;
    }

    onMoveBackward() {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty() ||
            node.value.parentNodeId == IdGenerator.INVALID_ID ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return;
        }

        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty() ||
            parentNode.children.length < 2 ||
            parentNode.children[0].value.id == node.value.id) {
            return;
        }

        let index = parentNode.getChildIndex(node);
        if (index == -1 || index == 0) {
            return;
        }
        let targetNode = parentNode.children[index - 1];

        let newNode = this.moveNode(targetNode.value.id, node.value.id);

        if (!newNode.isEmpty()) {
            this.setSelectedId(newNode.value.id);
            this.outlineBecomeFirstResponder(newNode.value.id);
        }
    }


    onMoveForward() {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty() ||
            node.value.parentNodeId == IdGenerator.INVALID_ID ||
            node.value.parentNodeId == IdGenerator.ROOT_ID ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return;
        }

        let parentNode = this.getNodeById(node.value.parentNodeId);

        if (parentNode.isEmpty() ||
            parentNode.value.parentNodeId == IdGenerator.INVALID_ID) {
            return;
        }
        let parentSuperNode = this.getNodeById(parentNode.value.parentNodeId);
        if (parentSuperNode.isEmpty()) {
            return;
        }
        let newNode = this.moveNode(parentSuperNode.value.id, node.value.id);
        if (!newNode.isEmpty()) {
            this.setSelectedId(newNode.value.id);
            this.outlineBecomeFirstResponder(newNode.value.id);
        }
    }


    outlineNewLine(targetId, newlineText) {
        if (targetId != this.selectedNodeId) {
            return;
        }
        let node = this.getNodeById(targetId);
        if (node.isEmpty()) {
            return;
        }
        if (node.children.length > 0) {
            this.addChildMindElement(true, newlineText);
        } else {
            this.addYoungerBrotherMindElement(newlineText);
        }
    }
    outlineNewLineDOM(dom) {
        this.delegate.outlineNewLineDOM(dom)
    }

    setNodeBold(id) {

    }


    setNodeItalics(id) {

    }


    setNodeStrikethrough(id) {

    }


    addGeneralizationContentForOutline() {

    }

    stickNode(targetId, point, isKeepStyle, isRefresh = true) {
        let node = MindCopyManager.getCopyNode();
        if (node.isEmpty()) {
            return;
        }
        if (this.stickNodeByNode(targetId, node, point, isKeepStyle) && isRefresh) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    stickString(targetId, point, value) {
        if (value == null || value.length == null || value.length == 0) {
            return;
        }
        
        let showPoint = point;
        if (showPoint == null || showPoint.x <= 0 || showPoint.y <= 0) {
            showPoint = this.delegate.getMindMapCentralPoint();
        }
        let list = new MarkdownFileImport(null, value, false).dataSeparator();
        if (list.isEmpty() || list[0].length == 0) {
            return;
        }
        let target = this.getNodeById(targetId)
        if (target.isEmpty()) {
            target = this.createFreeNode(0);
            if (target != null && !target.isEmpty()) {
                target.value.x = 4000;
                target.value.y = 4000;
            }
            
            this.replaceNodeString(target.value.id, value, false);
            let unit = this.findFreeUnit(target.value.id);
            let targetRect = new CGRect(0, 0, 100, 50);
            if (unit != null) {
                unit.refreshLayout(true, this.settingData);
                targetRect = unit.getRect();
            } else {
                targetRect = new NodesRectCalculation().calcule(target);
            }
            if ((point == null || point.x <= 0 || point.y <= 0) && showPoint != null) {
                let freeNodesWithoutTarget = [];
                for (let index = 0; index < this.freeNodes.length; index++) {
                    const element = this.freeNodes[index];
                    if (element.rootTreeNode != null && element.rootTreeNode.value.id != target.value.id) {
                        freeNodesWithoutTarget.push(element);
                    }
                }
                let mindElementHitCalculation = new MindElementHitCalculation();
                mindElementHitCalculation.setData(this.mainMindNodeUnit,
                    freeNodesWithoutTarget,
                    [],
                    this.timeMindTypeNodeUnit, this.doubleBubbleMindNodeUnit);
                let rect = mindElementHitCalculation.getMapBlankSpace(
                    new CGRect((showPoint.x - targetRect.width() / 2),
                                (showPoint.y - targetRect.height() / 2),
                                targetRect.width(),
                                targetRect.height())
                );
                
                target.value.x = rect.x + (target.value.x - targetRect.x);
                target.value.y = rect.y + (target.value.y - targetRect.y);
            } else {
                target.value.x = (showPoint.x - target.value.width / 2);
                target.value.y = (showPoint.y - target.value.height / 2);
            }
            if (value.length > 10) {
                target.value.mindElementShape = MindElementShapeType.Corner;
            }

            MindCopyManager.clearPasteboard();
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            this.checkNodeViewToScreen(target.value);
            return;
        } else {
            this.replaceNodeString(targetId, value, true, false)
            MindCopyManager.clearPasteboard();
        }
    }

    replaceNodeString(targetId, value, isRefresh = true, isAi = false) {
        if (value == null || value.length == null || value.length == 0) {
            return;
        }
        let markdownFileImport = new MarkdownFileImport(null, value, false)
        let list = markdownFileImport.dataSeparator();
        if (list.length == 0 || list[0].length == 0) {
            return;
        }
        let target = this.getNodeById(targetId);
        if (target.isEmpty() ||
                (target.value.type != MindElementType.MAIN_SUBJECT &&
                        target.value.type != MindElementType.SUBJECT &&
                        target.value.type != MindElementType.SON_SUBJECT &&
                        target.value.type != MindElementType.CONTENT_GENERALIZATION)) {
            this.selectAddTargetTips();
            return;
        }
        let cellList = markdownFileImport.splitStrByEndChar(list[0]);
        if (target.value.textContent != null) {
            let preData = target.value.copy()
            if (Util.isEditInputSelected(target.value.textContent.text) || target.value.textContent.text.length == 0) {
                target.value.textContent.text = cellList[0].trim();
            } else {
                target.value.textContent.text = target.value.textContent.text + " " + (cellList[0].trim());
            }
            MindElementCalculation.set(target.value).caluleTextForData().calcule(false);
            this.dataConnectLinePoint(target.value, preData);
        } else if (target.value.generalizationContent != null) {
            let preData = target.value.copy()
            if (Util.isEditInputSelected(target.value.generalizationContent.text) || 
                target.value.generalizationContent.text.length == 0) {
                target.value.generalizationContent.text = cellList[0].trim();
            } else {
                target.value.generalizationContent.text = target.value.generalizationContent.text + " " + (cellList[0].trim());
            }
            MindElementCalculation.set(target.value).caluleTextForData().calcule(false);
            this.dataConnectLinePoint(target.value, preData);
        } else {
            return;
        }
        let targetChildCount = target.children.length;
        if (cellList.length > 1) {
            let lastId = targetId;
            for (let index = 1; index < cellList.length; index++) {
                let node = null
                if (this.isFlowChart()) {
                    node = this.addFlowChartNode(lastId, null, FlowChartDirection.BOTTOM, MindElementShapeType.Corner, false, cellList[index]);
                    lastId = node.value.id
                } else {
                    node = this.addChildMindElement(false, cellList[index], targetId);
                }
                if (!node.isEmpty() && node.value.textContent != null) {
                    let preData = node.value.copy()
                    node.value.textContent.text = cellList[index].trim();
                    MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
                    this.dataConnectLinePoint(node.value, preData);
                }
            }
        }
        if (list.length > 1) {
            let textNodeMap = new HashMap()
            let textRowMap = markdownFileImport.textRowMap
            let lastLeve = textRowMap.containsKey(list[0]) ? textRowMap.get(list[0]).level : 0;
            let lastNodeId = targetId
            for (let index = 1; index < list.length; index++) {
                let node = null
                let cellText = list[index]
                let cellLevel = textRowMap.containsKey(cellText) ? textRowMap.get(cellText).level : 0
                if (cellLevel > lastLeve) {
                    if (this.isFlowChart()) {
                        node = this.addFlowChartNode(lastNodeId, null, FlowChartDirection.RIGHT, MindElementShapeType.Corner, false, cellText);
                    } else {
                        node = this.addChildMindElement(false, cellText, lastNodeId);
                    }
                } else if (cellLevel == lastLeve) {
                    let lastNode = this.getNodeById(lastNodeId)
                    let parentNode = this.getNodeById(lastNode.value.parentNodeId)
                    if (parentNode.isEmpty()) {
                        if (this.isFlowChart()) {
                            node = this.addFlowChartNode(lastNodeId, null, FlowChartDirection.RIGHT, MindElementShapeType.Corner, false, cellText);
                        } else {
                            node = this.addYoungerBrotherMindElement(cellText, lastNodeId, false);
                        }                        
                    } else {
                        if (this.isFlowChart()) {
                            node = this.addFlowChartNode(lastNodeId, null, FlowChartDirection.RIGHT, MindElementShapeType.Corner, false, cellText);
                        } else {
                            node = this.addChildMindElement(false, cellText, parentNode.value.id);
                        }
                    }
                } else {
                    let taregtLevel = cellLevel
                    for (let i = 0; i < 20; i++) {
                        if (node != null && !node.isEmpty()) {
                            break
                        }
                        for (let j = 1; j < index; j++) {
                            let level = textRowMap.containsKey(list[j]) ? textRowMap.get(list[j]).level : 0
                            if (taregtLevel == level) {
                                let levelNode = textNodeMap.get(list[j])
                                if (levelNode != null && !levelNode.isEmpty()) {
                                    if (this.isFlowChart()) {
                                        node = this.addFlowChartNode(lastNodeId, null, FlowChartDirection.BOTTOM, MindElementShapeType.Corner, false, cellText);
                                    } else {
                                        let lastNode = this.getNodeById(levelNode.value.id)
                                        let parentNode = this.getNodeById(lastNode.value.parentNodeId)
                                        if (parentNode.isEmpty()) {
                                            node = this.addYoungerBrotherMindElement(cellText, levelNode.value.id, false);
                                        } else {
                                            node = this.addChildMindElement(false, cellText, parentNode.value.id);
                                        }
                                    }
                                    break
                                }
                            }
                        }
                        taregtLevel = taregtLevel - 1
                    }
                }
                if (node == null || node.isEmpty()) {
                    if (this.isFlowChart()) {
                        node = this.addFlowChartNode(targetId, null, FlowChartDirection.RIGHT, MindElementShapeType.Corner, false, cellText);
                    } else {
                        node = this.addYoungerBrotherMindElement(cellText, targetId, false);
                    }
                }
                if (!node.isEmpty() && node.value.textContent != null) {
                    let preData = node.value.copy()
                    let cellChildList = markdownFileImport.splitStrByEndChar(cellText);
                    node.value.textContent.text = cellChildList[0].trim();
                    MindElementCalculation.set(node.value).caluleTextForData().calcule(false); 
                    if (this.isRootNode(node.value.id) && this.isFlowChart()) {
                        node.value.y = preData.y + (preData.height - node.value.height) / 2
                    }
                    this.dataConnectLinePoint(node.value, preData);  

                    if (cellChildList.length > 1) {  
                        let lastNode = node                      
                        for (let childndex = 1; childndex < cellChildList.length; childndex++) {
                            let childNode = null;
                            if (childndex == 1 || !markdownFileImport.isSameLevelEndChar(cellText, cellChildList[childndex])) {
                                if (this.isFlowChart()) {
                                    childNode = this.addFlowChartNode(lastNode.value.id, null, FlowChartDirection.BOTTOM, MindElementShapeType.Corner, false, cellChildList[childndex]);
                                } else {
                                    childNode = this.addChildMindElement(false, cellChildList[childndex], lastNode.value.id);
                                }
                            } else {
                                if (this.isFlowChart()) {
                                    childNode = this.addFlowChartNode(lastNode.value.id, null, FlowChartDirection.BOTTOM, MindElementShapeType.Corner, false, cellChildList[childndex]);
                                } else {
                                    let parentNode = this.getNodeById(lastNode.value.parentNodeId)
                                    if (parentNode.isEmpty()) {
                                        childNode = this.addYoungerBrotherMindElement(cellChildList[childndex], lastNode.value.id, false);
                                    } else {
                                        childNode = this.addChildMindElement(false, cellChildList[childndex], parentNode.value.id);
                                    }
                                }
                            }
                            if (!childNode.isEmpty() && childNode.value.textContent != null) {
                                let preData = childNode.value.copy()
                                childNode.value.textContent.text = cellChildList[childndex].trim();
                                MindElementCalculation.set(childNode.value).caluleTextForData().calcule(false);
                                this.dataConnectLinePoint(childNode.value, preData);
                            }
                            lastNode = childNode
                        }
                    }
                }
                lastLeve = cellLevel
                lastNodeId = node.value.id
                textNodeMap.put(cellText, node)
            }
        }
        MindCopyManager.clearPasteboard();
        let isSetFramework = this.mindType == MindType.LINE_MAP && 
                            targetChildCount == 0 && this.mainMindNodeUnit.rootTreeNode != null &&
                            this.mainMindNodeUnit.rootTreeNode.value.id == targetId &&
                            this.mainMindNodeUnit.mindMapFramework != null;
        this.refresh(true, !isSetFramework);
        if (isSetFramework) {
            if (this.mainMindNodeUnit.lineColors != null && this.mainMindNodeUnit.lineColors.length > 0) {
                this.mainMindNodeUnit.onSelectedRainbowr(this.mainMindNodeUnit.lineColors, false, 0);
            }
            this.setFramework(this.mainMindNodeUnit.mindMapFramework.currentSelectedIndex);
        }
        if (isRefresh) {
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    removeIcon(nodeId, iconData) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty() || node.value.iconElementContents == null || node.value.iconElementContents.length == 0) {
            return;
        }
        let preData = node.value.copy();
        let iconElementContents = node.value.iconElementContents;
        for (let index = 0; index < iconElementContents.length; index++) {
            if (iconElementContents[index].key == (iconData.key)) {
                iconElementContents.remove(index);
                node.value.iconElementContents = iconElementContents;
                break;
            }
        }
        MindElementCalculation.set(node.value).calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    backwardRetreat() {
        if (this.currentHistoryIndex == 0 || this.historyList.length < this.currentHistoryIndex + 1) {
            this.delegate.setBackwardRetreatEnable(false);
            return;
        }
        this.currentHistoryIndex = this.currentHistoryIndex - 1;
        this.backwardRetreating = true;
        let data = this.historyList[this.currentHistoryIndex];
        data.parse();
        this.globalLayout = data.globalLayout;
        this.globalLineLayout = data.globalLineLayout;
        this.mindBGColor = data.mindBGColor;
        this.settingData = data.settingData.copy()
        Config.setTightModeEdgeInsets(this.settingData.tightMode);
        let removeList = this.findRemoveDatas(data);

        if (removeList != null && removeList.length > 0) {
            let removeListLength = removeList.length
            for (let index = 0; index < removeListLength; index++) {
                this.removeData(removeList[index]);
            }
        }
        let mainLines = this.mainMindNodeUnit.lineMindElementDataDict.values()
        mainLines.forEach(line => {
            if (line != null && !line.isEmpty() && line.lineContent != null) {
                if (line.lineContent.targetId == this.mainMindNodeUnit.rootTreeNode.value.id) {
                    this.removeViewByMindData(line);
                } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                    this.removeViewByMindData(line);
                } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                    this.removeViewByMindData(line);
                } else if (line.type == MindElementType.FORM_LINE) {
                    this.removeViewByMindData(line);
                } else if (line.layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
                    this.removeViewByMindData(line);
                }
            }
        });
        this.freeNodes.forEach(unit => {
            let mainLines = unit.lineMindElementDataDict.values()
            mainLines.forEach(line => {
                if (line != null && !line.isEmpty() && line.lineContent != null) {
                    if (unit.rootTreeNode == null || line.lineContent.targetId == unit.rootTreeNode.value.id) {
                        this.removeViewByMindData(line);
                    } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                        this.removeViewByMindData(line);
                    } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        this.removeViewByMindData(line);
                    } else if (line.type == MindElementType.FORM_LINE) {
                        this.removeViewByMindData(line);
                    } else if (line.layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
                        this.removeViewByMindData(line);
                    }
                }
            });
        });

        if (data.mainMindNodeUnit != null) {
            this.mainMindNodeUnit = data.mainMindNodeUnit.copy();
            this.mainMindNodeUnit.delegate = this
        }
        let newFreeNodes = new Array();
        data.freeNodes.forEach(cell => {
            cell.delegate = this
            newFreeNodes.push(cell.copy());
        });
        this.freeNodes = newFreeNodes;
        this.nodeConnectLineDataDict.clear();
        if (data.nodeConnectLineDataDict != null) {
            data.nodeConnectLineDataDict.keys().forEach(key => {
                this.nodeConnectLineDataDict.put(key, data.nodeConnectLineDataDict.get(key).copy());
            });
        }        
        if (data.doubleBubbleMindNodeUnit != null) {
            this.doubleBubbleMindNodeUnit = data.doubleBubbleMindNodeUnit.copy();
            this.doubleBubbleMindNodeUnit.delegate = this
        }
        if (data.timeMindTypeNodeUnit != null) {
            this.timeMindTypeNodeUnit = data.timeMindTypeNodeUnit.copy();
            this.timeMindTypeNodeUnit.delegate = this
        }
        data.clearDatas();
        this.delegate.changMindBackgroundColor(this.mindBGColor);
        this.refresh(true);
        this.willSave(true);

        this.delegate.setBackwardRetreatEnable(this.currentHistoryIndex != 0);
        this.delegate.setForwardEnable(true);
        this.backwardRetreating = false;
    }

    backwardRetreatForSetStyle() {
        this.backwardRetreat();
        if (this.historyList.length > this.currentHistoryIndex + 1) {
            this.historyList.splice(this.currentHistoryIndex + 1, 1);
        }
        this.delegate.setForwardEnable(this.currentHistoryIndex != this.historyList.length - 1);
    }

    forward() {
        if (this.currentHistoryIndex == this.historyList.length - 1 || this.historyList.length < this.currentHistoryIndex + 2) {
            this.delegate.setForwardEnable(false);
            return;
        }
        this.currentHistoryIndex = this.currentHistoryIndex + 1;
        this.forwarding = true;
        let data = this.historyList[this.currentHistoryIndex];
        data.parse();
        this.globalLayout = data.globalLayout;
        this.globalLineLayout = data.globalLineLayout;
        this.mindBGColor = data.mindBGColor;
        this.settingData = data.settingData.copy()
        Config.setTightModeEdgeInsets(this.settingData.tightMode);

        let removeList = this.findRemoveDatas(data);
        removeList.forEach(id => {
            this.removeData(id);
        });
        let mainLines = this.mainMindNodeUnit.lineMindElementDataDict.values()
        mainLines.forEach(line => {
            if (line != null && !line.isEmpty() && line.lineContent != null) {
                if (line.lineContent.targetId == this.mainMindNodeUnit.rootTreeNode.value.id) {
                    this.removeViewByMindData(line);
                } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                    this.removeViewByMindData(line);
                } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                    this.removeViewByMindData(line);
                } else if (line.type == MindElementType.FORM_LINE) {
                    this.removeViewByMindData(line);
                } else if (line.layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
                    this.removeViewByMindData(line);
                }
            }
        });
        this.freeNodes.forEach(unit => {
            let mainLines = unit.lineMindElementDataDict.values()
            mainLines.forEach(line => {
                if (line != null && !line.isEmpty() && line.lineContent != null) {
                    if (line.lineContent.targetId == unit.rootTreeNode.value.id) {
                        this.removeViewByMindData(line);
                    } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                        this.removeViewByMindData(line);
                    } else if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        this.removeViewByMindData(line);
                    } else if (line.type == MindElementType.FORM_LINE) {
                        this.removeViewByMindData(line);
                    } else if (line.layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
                        this.removeViewByMindData(line);
                    }
                }
            });
        });
        if (data.mainMindNodeUnit != null) {
            this.mainMindNodeUnit = data.mainMindNodeUnit.copy();
            this.mainMindNodeUnit.delegate = this
        }
        let newFreeNodes = new Array();
        data.freeNodes.forEach(cell => {
            cell.delegate = this
            newFreeNodes.push(cell.copy());
        });
        this.freeNodes = newFreeNodes;

        this.nodeConnectLineDataDict.clear();
        data.nodeConnectLineDataDict.keys().forEach(key => {
            this.nodeConnectLineDataDict.put(key, data.nodeConnectLineDataDict.get(key).copy());
        });
        if (data.doubleBubbleMindNodeUnit != null) {
            this.doubleBubbleMindNodeUnit = data.doubleBubbleMindNodeUnit.copy();
            this.doubleBubbleMindNodeUnit.delegate = this
        }
        if (data.timeMindTypeNodeUnit != null) {
            this.timeMindTypeNodeUnit = data.timeMindTypeNodeUnit.copy();
            this.timeMindTypeNodeUnit.delegate = this
        }
        data.clearDatas();
        this.delegate.changMindBackgroundColor(this.mindBGColor);
        this.refresh(true);
        this.willSave(true);

        this.delegate.setBackwardRetreatEnable(true);
        this.delegate.setForwardEnable(this.currentHistoryIndex != this.historyList.length - 1);
        this.forwarding = false;
    }

    findRemoveDatas(data) {
        let list = new Array();
        let mainData = this.findLineMindRemoveNode(data, this.mainMindNodeUnit);
        list = list.concat(mainData);
        for (let index = 0; index < this.freeNodes.length; index++) {
            list = list.concat(this.findLineMindRemoveNode(data, this.freeNodes[index]));
        }
        list = list.concat(this.findNodeConnectLineRemoveData(data, this.nodeConnectLineDataDict));
        list = list.concat(this.findDoubleBubbleMindRemoveNode(data, this.doubleBubbleMindNodeUnit));
        list = list.concat(this.findTimeLineMindRemoveNode(data, this.timeMindTypeNodeUnit));
        return list;
    }

    findTimeLineMindRemoveNode(data, timeMindTypeNode) {
        let list = new Array();
        if (timeMindTypeNode == null) {
            return list;
        }
        for (let index = 0; index < timeMindTypeNode.mainMindElementDataDict.keys().length; index++) {
            let node = timeMindTypeNode.mainMindElementDataDict.get(timeMindTypeNode.mainMindElementDataDict.keys()[index]);
            if (node == null) {
                return
            }
            let head = node.head;
            let diffNode = data.getNodeById(head.id);
            if (diffNode.isEmpty()) {
                list.push(head.id);
            }
        }
        return list;
    }

    findDoubleBubbleMindRemoveNode(data, doubleBubbleMind) {
        let list = new Array();
        if (doubleBubbleMind == null) {
            return list;
        }
        for (let index = 0; index < doubleBubbleMind.mainMindElementDataDict.keys().length; index++) {
            let node = doubleBubbleMind.mainMindElementDataDict.get(doubleBubbleMind.mainMindElementDataDict.keys()[index]);
            if (node == null || node.isEmpty()) {
                return
            }
            let diffNode = data.getNodeById(node.value.id);
            if (diffNode.isEmpty()) {
                list.push(node.value.id);
            }
        }
        for (let index = 0; index < doubleBubbleMind.commonGroundMindElementDatas.length; index++) {
            if (doubleBubbleMind.commonGroundMindElementDatas[index].isEmpty()) {
                return
            }
            let diffNode = data.getNodeById(doubleBubbleMind.commonGroundMindElementDatas[index].id);
            if (diffNode.isEmpty()) {
                list.push(doubleBubbleMind.commonGroundMindElementDatas[index].id);
            }
        }
        return list;
    }

    findNodeConnectLineRemoveData(data, nodeConnectLine) {//nodeConnectLine type Map
        let list = new Array();
        if (nodeConnectLine.length == 0) {
            return list;
        }
        let values = nodeConnectLine.values()
        let valuesCount = values.length
        for (let index = 0; index < valuesCount; index++) {
            let line = values[index];
            if (line == null || line.isEmpty()) {
                return
            }
            let diffNode = data.getNodeById(line.id);
            if (diffNode.isEmpty()) {
                list.push(line.id);
            }
        }
        return list;
    }

    findLineMindRemoveNode(data, lineMind) {
        let list = new Array();
        if (lineMind == null) {
            return list;
        }
        let dataKeys = lineMind.mainMindElementDataDict.keys();
        let dataLength = dataKeys.length;
        for (let index = 0; index < dataLength; index++) {
            let node = lineMind.mainMindElementDataDict.get(dataKeys[index]);
            if (node == null || node.isEmpty()) {
                break
            }
            let diffNode = data.getNodeById(node.value.id);
            if (diffNode.isEmpty()) {
                list.push(node.value.id);
            }
        }
        let generalizationMindElementDataDictKey = lineMind.generalizationMindElementDataDict.keys()
        for (let index = 0; index < generalizationMindElementDataDictKey.length; index++) {
            let node = lineMind.generalizationMindElementDataDict.get(generalizationMindElementDataDictKey[index]);
            if (node == null || node.isEmpty()) {
                break
            }
            let diffNode = data.getNodeById(node.value.id);
            if (diffNode.isEmpty()) {
                list.push(node.value.id);
            }
        }
        let encircleMindElementDataDictKey = lineMind.encircleMindElementDataDict.keys()
        for (let index = 0; index < encircleMindElementDataDictKey.length; index++) {
            let node = lineMind.encircleMindElementDataDict.get(encircleMindElementDataDictKey[index]);
            if (node == null || node.isEmpty()) {
                break
            }
            let diffNode = data.getNodeById(node.id);
            if (diffNode.isEmpty()) {
                list.push(node.id);
            }
        }

        let explainMindElementDataDictValue = lineMind.explainMindElementDataDict.values()
        for (let index = 0; index < explainMindElementDataDictValue.length; index++) {
            let node = explainMindElementDataDictValue[index];
            if (node == null || node.isEmpty()) {
                break
            }
            let diffNode = data.getNodeById(node.id);
            if (diffNode.isEmpty()) {
                list.push(node.id);
            }
        }
        return list;
    }

    onSelectedNode(data) {
        if (data == null || data.isEmpty()) {
            return;
        }
        let node = this.getNodeById(data.id)
        if (node.isEmpty()) {
            return;
        }
        this.selectedNodeId = node.value.id;
        this.delegate.onSelected(node.value, new Point(-1, -1))
        this.checkNodeViewToScreen(node.value)
    }

    onReplaceNodeText(find, replace, id) {
        if (find == null || find == "") {
            return
        }
        
        let node = this.getNodeById(id)
        if (node.isEmpty()) {
            return
        }
        if (node.value.textContent != null && node.value.textContent.text.toUpperCase().indexOf(find.toUpperCase()) > -1) {
            node.value.textContent.text = node.value.textContent.text.replace(new RegExp(find, "gi"), replace);
            MindElementCalculation.set(node.value).caluleTextForData()
        }
        if (node.value.generalizationContent != null && node.value.generalizationContent.text.toUpperCase().indexOf(find.toUpperCase()) > -1) {
            node.value.generalizationContent.text = node.value.generalizationContent.text.replace(new RegExp(find, "gi"), replace);
            MindElementCalculation.set(node.value).caluleTextForData()
        }
        
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    onAllReplaceNodeText(find, replace) {
        if (find == null || find == "") {
            return;
        }
        let list = this.findText(find)
        for (let index = 0; index < list.length; index++) {
            let cell = list[index]
            let node = this.getNodeById(cell.id)
            if (node.isEmpty()) {
                continue
            }
            if (node.value.textContent != null && node.value.textContent.text.toUpperCase().indexOf(find.toUpperCase()) > -1) {
                node.value.textContent.text = node.value.textContent.text.replace(new RegExp(find, "gi"), replace);
                MindElementCalculation.set(node.value).caluleTextForData()
            }
            if (node.value.generalizationContent != null && node.value.generalizationContent.text.toUpperCase().indexOf(find.toUpperCase()) > -1) {
                node.value.generalizationContent.text = node.value.generalizationContent.text.replace(new RegExp(find, "gi"), replace);
                MindElementCalculation.set(node.value).caluleTextForData()
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    findText(text) {
        let result = [];
        if (text == "") {
            return result;
        }
        let list = [];
        
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list, true)
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue
                }
                this.serializeNodeElementsToList(unit.rootTreeNode, list, true);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (node.isEmpty()) {
                    continue
                }
                if (node.value.textContent != null &&
                    node.value.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(node.value)
                } else if (node.value.generalizationContent != null &&
                    node.value.generalizationContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(node.value)
                }
                let explain = this.getNodeExplain(node.value.id)
                if (!explain.isEmpty() &&
                    explain.textContent != null &&
                    explain.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(explain)
                }
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.serializeNodeElementsToList(this.doubleBubbleMindNodeUnit.rootTreeNode, list, true);
            if (this.doubleBubbleMindNodeUnit.rootTreeBrotherNode != null) {
                this.serializeNodeElementsToList(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode, list, true);
            }
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue
                }
                this.serializeNodeElementsToList(unit.rootTreeNode, list, true)
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (node.isEmpty()) {
                    continue
                }
                if (node.value.textContent != null &&
                    node.value.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(node.value)
                } else if (node.value.generalizationContent != null &&
                    node.value.generalizationContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(node.value)
                }
                let explain = this.getNodeExplain(node.value.id)
                if (!explain.isEmpty() &&
                    explain.textContent != null &&
                    explain.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(explain)
                }
            }
            for (let index = 0; index < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; index++) {
                let value =this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index];
                if (value.textContent != null &&
                    value.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(value)
                }
                let explain = this.getNodeExplain(value.id)
                if (!explain.isEmpty() &&
                    explain.textContent != null &&
                    explain.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(explain)
                }
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            let dataList = []
            if (!this.timeMindTypeNodeUnit.title.isHidden) {
                dataList.push(this.timeMindTypeNodeUnit.title)
            }
            let mainMindElementDataDictArray = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys();
            let mainMindElementDataDictLength = mainMindElementDataDictArray.length

            for (let index = 0; index < mainMindElementDataDictLength; index++) {
                let key = mainMindElementDataDictArray[index];
                let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
                if (!node.head.isHidden) {
                    dataList.push(node.head)
                }
                if (!node.title.isHidden) {
                    dataList.push(node.title)
                }
                if (!node.desc.isHidden) {
                    dataList.push(node.desc)
                }
            }
            
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue
                }
                this.serializeNodeElementsToList(unit.rootTreeNode, list, true)
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (!node.isEmpty() && !node.value.isHidden) {
                    dataList.push(node.value)
                }
            }
            for (let index = 0; index < dataList.length; index++) {
                let value = dataList[index];
                if (value.isEmpty()) {
                    continue
                }
                if (value.textContent != null &&
                    value.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(value)
                } else if (value.generalizationContent != null &&
                    value.generalizationContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(value)
                }
                let explain = this.getNodeExplain(value.id)
                if (!explain.isEmpty() &&
                    explain.textContent != null &&
                    explain.textContent.text.toUpperCase().indexOf(text.toUpperCase()) > -1) {
                    result.push(explain)
                }
            }
        }
        return result
    }

    openLinkPanel() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.selectAddTargetTips();
            return;
        }
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return;
        }
        this.delegate.showAddLinkPanel(node);
    }

    openAiPanel() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.selectAddTargetTips();
            return;
        }
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return;
        }
        this.delegate.showAddAiPanel(node);
    }

    addLink(link, id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }

        if (node.value.linkElementContent == null) {
            let list = new Array();
            list.push(link);
            node.value.linkElementContent = new LinkElementContent(list, 20, 20);
        }
        else {
            node.value.linkElementContent.links.push(link);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    removeAllLink(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || !node.value.isContainLinkContent()) {
            return;
        }
        node.value.linkElementContent = null;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    removeLink(link, id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || !node.value.isContainLinkContent()) {
            return;
        }
        let list = new Array();
        for (let index = 0; index < node.value.linkElementContent.links.length; index++) {
            if (link != node.value.linkElementContent.links[index]) {
                list.push(node.value.linkElementContent.links[index]);
            }
        }
        node.value.linkElementContent.links = list;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    openLink(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || !node.value.isContainLinkContent()) {
            return;
        }
        new UiUtil().openLinkByPhone(node.value.linkElementContent.links[0]);
    }

    editLink(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || !node.value.isContainLinkContent()) {
            return;
        }
        this.delegate.showAddLinkPanel(node);
    }

    openRemarksPanel() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.selectAddTargetTips();
            return;
        }
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        this.delegate.showRemarksPanel(node);
    }

    changeRemarks(text, id) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        let isNew = false;
        if (node.value.remarksElementContent == null) {
            node.value.remarksElementContent = new RemarksElementContent(text, 20, 20);
            isNew = true;
        }
        let remakes = node.value.remarksElementContent.text;
        node.value.remarksElementContent.text = text;
        if (isNew || !(text == remakes)) {
            if (isNew || text.length == 0 || remakes.length == 0) {
                this.refresh(true);
            }
            this.willSave(true);
            this.addHostry();
        }
    }

    //删除附件
    removeResource(nodeId) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty() ||
            node.value.resourceContent == null) {
            return;
        }
        node.value.resourceContent = null;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setResourceById(url, type, size, id, name) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.resourceContent == null && !new Strings().isEmpty(url)) {
            node.value.resourceContent = new ResourceContent(url, 20, 20);
        } else if (new Strings().isEmpty(url)) {
            node.value.resourceContent = null;
        }
        if (node.value.resourceContent != null) {
            node.value.resourceContent.url = url;
            node.value.resourceContent.size = size == "" ? 1 : size;
            node.value.resourceContent.type = type;
            node.value.resourceContent.name = name;
        }

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setResource(url, type, size) {
        this.setResourceById(url, type, size, this.selectedNodeId);
    }

    resizeBaoweiNode(rect) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (node.value.type != MindElementType.BAOWEI_VIEW) {
            return;
        }
        if (this.isFlowChart()) {
            node.value.customWidth = rect.width()
            node.value.customHeight = rect.height()
            node.value.width = rect.width()
            node.value.height = rect.height()
            node.value.x = rect.x
            node.value.y = rect.y
            node.value.lineContent.targetIds = [node.value.id];
            node.value.lineContent.targetId = node.value.id;
            node.value.parentNodeId = node.value.id;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return
        }
        let targetId = node.value.lineContent.targetIds.isEmpty() ? node.value.lineContent.targetId : node.value.lineContent.targetIds[0];
        let targetNode = this.getNodeById(targetId);
        if (targetNode.isEmpty()) {
            return;
        }
        if (this.isRootNode(targetId)) {
            let list = new Array();
            if (this.mainMindNodeUnit.rootTreeNode != null && 
                !this.mainMindNodeUnit.rootTreeNode.isEmpty() &&
                Util.isIntersectedForRect(rect, this.mainMindNodeUnit.rootTreeNode.value.getRect())) {
                let rootRect = new CGRect(rect.x, this.mainMindNodeUnit.rootTreeNode.value.y, 
                    rect.width(), this.mainMindNodeUnit.rootTreeNode.value.height)

                let crossArea = Util.getCrossArea(rect, rootRect);
                if (crossArea > rootRect.width() * rootRect.height() / 2) {
                    list.push(this.mainMindNodeUnit.rootTreeNode);
                }
            }
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index]
                if (unit.rootTreeNode != null && !unit.rootTreeNode.isEmpty() &&
                    Util.isIntersectedForRect(rect, unit.rootTreeNode.value.getRect())) {
                    let rootRect = new CGRect(rect.x, unit.rootTreeNode.value.y, 
                        rect.width(), unit.rootTreeNode.value.height)
                    let crossArea = Util.getCrossArea(rect, rootRect);
                    if (crossArea > rootRect.width() * rootRect.height() / 2) {
                        list.push(unit.rootTreeNode);
                    }
                }
            }
            if (list.isEmpty()) {
                this.removeData(node.value.id);
            } else {
                let targetIds = new Array();
                for (let index = 0; index < list.length; index++) {
                    let cell = list[index];
                    targetIds.push(cell.value.id);
                }
                node.value.lineContent.targetIds = targetIds;
                node.value.lineContent.targetId = list[0].value.id;
                node.value.parentNodeId = node.value.lineContent.targetId;
            }
        } else {
            this.mainMindNodeUnit.resizeNode(rect, this.selectedNodeId);
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index];
                unit.resizeNode(rect, this.selectedNodeId);
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    resizeContentGeneralizationNode(rect) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (node.value.type != MindElementType.CONTENT_GENERALIZATION ||
            node.value.generalizationContent == null ||
            node.value.generalizationContent.targetIds.isEmpty()) {
            return;
        }
        let targetId = node.value.generalizationContent.targetIds[0];
        let targetNode = this.getNodeById(targetId);
        if (targetNode.isEmpty()) {
            return;
        }
        if (!this.isRootNode(targetId)) {
            this.mainMindNodeUnit.resizeNode(rect, this.selectedNodeId);
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index];
                unit.resizeNode(rect, this.selectedNodeId);
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    resizeNode(rect) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (this.isFlowChart() && 
            (node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.SUBJECT || 
                node.value.type == MindElementType.CONTENT_GENERALIZATION)) {
            let copyData = node.value.copy()
            node.value.customWidth = rect.width()
            node.value.customHeight = rect.height()
            node.value.x = rect.x
            node.value.y = rect.y
            if (node.value.imageContent != null) {
                let sc = node.value.imageContent.width / node.value.imageContent.height
                node.value.imageContent.height = rect.height() - (node.value.height - node.value.imageContent.height);
                node.value.imageContent.width = node.value.imageContent.height * sc;
            }
            MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
            this.dataConnectLinePoint(node.value, copyData);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (node.value.type == MindElementType.BAOWEI_VIEW) {
            this.resizeBaoweiNode(rect);
        } else if (node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            this.resizeContentGeneralizationNode(rect);
        }
    }

    clickPoints(point, event) {
        let data = this.getMindElementDataByPoint(point);
        if (data.isEmpty()) {
        } else {
            this.setClickPoint(point, data.id);
            if (data.type == MindElementType.NODE_CONNECT_LINE || data.type == MindElementType.BAOWEI_VIEW) {
                this.delegate.onSelected(data, point, null, event);
            } else if (data.type == MindElementType.TIME_LINE_BASE_ELEMENT && data.selected()) {
                this.delegate.onSelected(data, point);
            } else if (data.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                this.delegate.onSelected(data, point);
            }
            if (this.isAddNodeConnectLine) {
                this.onSelected(data)
            }
        }
        if (this.isAddFreeNode || this.isAddNodeConnectLine) {
            let node = this.addFreeNodeByPoint(point);
            if (node != null && !node.isEmpty()) {
                this.onSelected(node.value)
                if (this.isFlowChart()) {
                    setTimeout(() => {
                        this.delegate.clearViewSelected();
                        this.delegate.onSelected(node.value);
                        this.delegate.showChangeNodeShape(node.value);
                    }, 100);
                }
            }
        }
        return data
    }

    getMindElementDataByPoint(point) {
        let mindElementHitCalculation = new MindElementHitCalculation();
        mindElementHitCalculation.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);

        let data = mindElementHitCalculation.hit(point);
        return data;
    }

    getMindElementDataByRect(point) {
        let mindElementHitCalculation = new MindElementHitCalculation();
        mindElementHitCalculation.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);

        let data = mindElementHitCalculation.hitByRect(point);
        return data;
    }


    addFreeNodeByPoint(point, isDoubleClick = false) {
        if (this.globalLayout == NodeLayoutType.LAYOUT_FORM || this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let node = this.mainMindNodeUnit.checkAddNodeForForm(point);
            if (!node.isEmpty()) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                this.editNode(node.value.id);
                return node;
            }
        }
        this.clearAddFreeNodeStatus();
        if (this.mindType == MindType.LINE_MAP ||
            this.mindType == MindType.FLOW_CHART ||
                this.mindType == MindType.BUBBLE_MAP ||
                this.mindType == MindType.DOUBLE_BUBBLE_MAP ||
                this.mindType == MindType.TIME_MAP) {
            if (isDoubleClick && !MindSettingManager.get().getDoubleClickCreateFreeNode() && this.addStatisticsNode.isEmpty()) {
                return new LineMindTypeNode()
            }
            let node = this.createFreeNode(0);
            if (node == null || node.isEmpty()) {
                return node
            }
            node.value.x = point.x - node.value.width / 2;
            node.value.y = point.y - node.value.height / 2;
            
            this.refresh(true);
            this.willSave(true);
            this.addHostry();

            setTimeout(() => {
                this.setSelectedId(node.value.id);
                this.delegate.onSelected(node.value, new Point(-1, -1));
            }, 100)
            return node
        } else {
            return new LineMindTypeNode()
        }
    }

    createFreeNode(level) {
        let mindNodeUnit = this.createFreeNodeUnit();
        let node = new LineMindTypeNode();
        if (!this.addStatisticsNode.isEmpty()) {
            node = mindNodeUnit.createFreeNode(-1, 1, this.addStatisticsNode);
            this.addStatisticsNode = new MindElementData().emptyMindNode();
        } else {
            node = mindNodeUnit.createFreeNode(-1, 1);
        }
        if (this.isFlowChart()) {
            node.value.alignmentType = MindElementAlignmentType.MIDDLE
            if (node.value.mindElementShape != MindElementShapeType.Corner) {
                node.value.mindElementShape = MindElementShapeType.Corner
                node.value.textContent.text = new UiUtil().getString(StringsLanguage.Mind_Shape_Corner_Text)
            }            
            MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
        }

        mindNodeUnit.setRootNode(node);
        return node;
    }

    createFreeNodeUnit() {
        let mindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mindMapStyleColor.currentSelectedIndex, true);
        mindNodeUnit.delegate = this;
        if (this.mindType == MindType.TIME_MAP) {
            mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            mindNodeUnit.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        } else {
            if (this.getGlobalLayout() == NodeLayoutType.LAYOUT_FORM || this.getGlobalLayout() == NodeLayoutType.LAYOUT_FORM_HORIZONTAL || this.getGlobalLayout() == NodeLayoutType.LAYOUT_TRIANGLE) {
                mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            } else {
                mindNodeUnit.setElementLayout(this.getFreeGlobalLayout(), true);
            }
            mindNodeUnit.globalLineLayout = this.globalLineLayout;
        }

        mindNodeUnit.setMindBGColor(this.mindBGColor);
        this.freeNodes.push(mindNodeUnit);

        return mindNodeUnit;
    }

    getCheckNodeConnectLineData(target, point) {
        if (target == null || target.isEmpty() || point == null) {
            return null
        }
        if (this.checkNodeConnectLineData == null) {
            this.checkNodeConnectLineData = new MindElementData()
        }
        if (this.checkNodeConnectLineData.isEmpty()) {
            this.checkNodeConnectLineData.id = 99999999
            this.checkNodeConnectLineData.type = MindElementType.NODE_CONNECT_LINE;
            this.checkNodeConnectLineData.backgroundColor = Colors.clear;
            this.checkNodeConnectLineData.borderColor = Colors.clear;
            this.checkNodeConnectLineData.borderWidth = 0;
            this.checkNodeConnectLineData.lineContent = new LineElementContent();
        }
        let line = this.checkNodeConnectLineData
        this.checkNodeConnectLineData.parentNodeId = target.value.id;
        let lineContent = this.checkNodeConnectLineData.lineContent
        lineContent.connectLineType = this.addNodeConnectLineType;
        lineContent.targetId = -1;
        lineContent.dottedLine = true
        lineContent.nodeConnectLineDottedLine = this.isFlowChart() ? false : true;
        
        if (target.value.type == MindElementType.NODE_CONNECT_LINE) {
            lineContent.startShape = LinePointShape.CIRCULAR;
        } else {
            switch (lineContent.connectLineType) {
                case ConnectLineType.CURVE_LINE:
                case ConnectLineType.RIGHT_ANGLE_LINE:
                case ConnectLineType.STRAIGHT_ARROW_LINE:
                    lineContent.startShape = LinePointShape.NORMAL;
                    break;
                case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                    lineContent.startShape = LinePointShape.CIRCULAR_RADIATION;
                    break;
                default:
                    lineContent.startShape = LinePointShape.NORMAL;
            }
        }
        lineContent.endShape = LinePointShape.ARROW;
        lineContent.nodeConnectLineDottedLine = true;

        if (target.value.type == MindElementType.NODE_CONNECT_LINE && target.value.lineContent != null) {
            lineContent.color = target.value.lineContent.color;
        } else if (!Colors.isClear(target.value.borderColor) && target.value.borderWidth > 0) {
            lineContent.color = target.value.borderColor;
        } else if (!Colors.isClear(target.value.backgroundColor)) {
            if (target.value.backgroundColor != this.mindBGColor) {
                lineContent.color = target.value.backgroundColor;
            } else if (target.value.textContent != null &&
                !Colors.isClear(target.value.textContent.textColor) &&
                target.value.textContent.textColor != this.mindBGColor) {
                lineContent.color = target.value.textContent.textColor;
            } else {
                lineContent.color = target.value.styleColor;
            }
        } else if (target.value.textContent != null &&
            !Colors.isClear(target.value.textContent.textColor) &&
            target.value.textContent.textColor != this.mindBGColor) {
            lineContent.color = target.value.textContent.textColor;
        } else {
            lineContent.color = target.value.styleColor;
        }
        lineContent.lineWidth = 3.0;


        let targetCenter = target.value.getCenterPoint();
        let targetCenterSpace = Util.getPointSpacing(targetCenter, point)
        let pointAndTargetCenterAngle = Util.getCircleDegreesInPoint(targetCenter, point)
        point = Util.getCirclePoint(targetCenter, pointAndTargetCenterAngle, targetCenterSpace - 15)
        let targetI = null;
        if (target.value.type == MindElementType.NODE_CONNECT_LINE) {
            let points = []
            points.push(new CGPoint(target.value.x + target.value.lineContent.startPointX,
                target.value.y + target.value.lineContent.startPointY));            
            if (target.value.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
                for (let index = 0; index < target.value.lineContent.rightAnglePoints.length; index++) {
                    const cellPoint = target.value.lineContent.rightAnglePoints[index];
                    points.push(new CGPoint(target.value.x + cellPoint.x, target.value.y + cellPoint.y));
                }
            }
            points.push(new CGPoint(target.value.x + target.value.lineContent.endPointX,
                target.value.y + target.value.lineContent.endPointY));
            targetI = points[0]; 
            let minSpace = 9999999;
            for (let index = 0; index < points.length; index++) {
                const cellPoint = points[index];
                if (index > 0 && target.value.lineContent.connectLineType != ConnectLineType.CURVE_LINE) {
                    let foot = Util.getStaticgetLineIntersectionVerticalPoint(points[index], points[index - 1], point);
                    let d = Util.getPointSpacing(points[index], points[index - 1]);
                    let d1 = Util.getPointSpacing(points[index], foot);
                    let d2 = Util.getPointSpacing(points[index - 1], foot);
                    if (d1 < d && d2 < d) {
                        let space = Util.getPointSpacing(foot, point)
                        if (space < minSpace) {
                            targetI = foot;
                            minSpace = space;
                        }
                    } else {
                        let space = Util.getPointSpacing(cellPoint, point)
                        if (space < minSpace) {
                            targetI = cellPoint;
                            minSpace = space;
                        }
                    }
                } else {
                    let space = Util.getPointSpacing(cellPoint, point)
                    if (space < minSpace) {
                        targetI = cellPoint;
                        minSpace = space;
                    }
                }
                
            }
        } else {
            targetI = target.value.getRightAngleShapeHitPoint(point, 0); 
        }
        let pointTatgetSpace = Util.getPointSpacing(targetI, point)
        let pointAngle = Util.getCircleDegreesInPoint(targetI, point)
        let startControlPointAngle = pointAngle + 30;
        let targetCenterAngle = Util.getCircleDegreesInPoint(point, targetI)
        let endControlPointAngle = targetCenterAngle + 30;

        let startControlPoint = Util.getCirclePoint(targetI, startControlPointAngle, pointTatgetSpace/4)
        let endControlPoint = Util.getCirclePoint(point, endControlPointAngle, pointTatgetSpace/4)

        lineContent.startControlPointX = startControlPoint.x;
        lineContent.startControlPointY = startControlPoint.y;

        lineContent.endControlPointX = endControlPoint.x;
        lineContent.endControlPointY = endControlPoint.y;

        let arrowH = lineContent.getLineEndMargin();

        let toI = point

        line.x = Math.min(targetI.x, toI.x);
        line.y = Math.min(targetI.y, toI.y);

        let startControlPointInLine = new CGPoint(startControlPoint.x - (line.x),
                    startControlPoint.y - (line.y));
        let endControlPointInLine = new CGPoint(endControlPoint.x - (line.x),
                endControlPoint.y - (line.y));
        let startPoint = new CGPoint(Math.abs((targetI.x) - line.x), Math.abs((targetI.y) - line.y));
        let endPoint = new CGPoint(Math.abs((toI.x) - line.x), Math.abs((toI.y) - line.y));

        lineContent.startPointX = Math.abs((targetI.x) - line.x);
        lineContent.startPointY = Math.abs((targetI.y) - line.y);
        lineContent.endPointX = Math.abs((toI.x) - line.x);
        lineContent.endPointY = Math.abs((toI.y) - line.y);
        if (target.value.type == MindElementType.NODE_CONNECT_LINE &&
            (lineContent.startShape == LinePointShape.ARROW ||
            lineContent.startShape == LinePointShape.BAMBOO_ARROW ||
            lineContent.startShape == LinePointShape.SIMPLE_ARROW ||
            lineContent.startShape == LinePointShape.T_SHAPED)) {
            arrowH = 0.0
            if (lineContent.startShape == LinePointShape.ARROW || lineContent.startShape == LinePointShape.BAMBOO_ARROW) {
                arrowH = new UiUtil().getArrowWidth(new Point( 0, 0), new Point( 0, 200), 
                        new UiUtil().dip2px(lineContent.lineWidth)) + new UiUtil().dip2px(4)
            }else if (lineContent.startShape == LinePointShape.SIMPLE_ARROW) {
                arrowH = new UiUtil().dip2px(4);
            } else {
                arrowH = (new UiUtil().dip2px(lineContent.lineWidth)/2 + 
                        new UiUtil().dip2px(target.value.lineContent.lineWidth)/2) + new UiUtil().dip2px(4);
            }
            var angle = 0;
            var distance = 0;
            var point
            switch (lineContent.connectLineType) {
                case ConnectLineType.STRAIGHT_ARROW_LINE:
                case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                    angle = Util.getCircleDegreesInPoint(endPoint, startPoint);
                    distance = Util.getPointSpacing(endPoint, startPoint);
                    distance = distance - arrowH;
                    point = Util.getCirclePoint(endPoint, angle, distance);
                    lineContent.startPointX = (point.x);
                    lineContent.startPointY = (point.y);
                    break;
                case ConnectLineType.CURVE_LINE:
                    angle = Util.getCircleDegreesInPoint(startControlPointInLine, startPoint);
                    distance = Util.getPointSpacing(startControlPointInLine, startPoint);
                    distance = distance - arrowH;
                    point = Util.getCirclePoint(startControlPointInLine, angle, distance);
                    lineContent.startPointX = (point.x);
                    lineContent.startPointY = (point.y);
                    break;
                case ConnectLineType.RIGHT_ANGLE_LINE:
                    if (lineContent.rightAnglePoints.length > 0) {
                        let point0 = new CGPoint(lineContent.rightAnglePoints[0].x, lineContent.rightAnglePoints[0].y);
                        let angle = Util.getCircleDegreesInPoint(point0, startPoint);
                        distance = Util.getPointSpacing(point0, startPoint);
                        distance = distance - arrowH;
                        point = Util.getCirclePoint(point0, angle, distance);
                        lineContent.startPointX = (point.x);
                        lineContent.startPointY = (point.y);
                    }
                break;
                default:
            }
        }

        line.width = Math.abs(lineContent.startPointX - lineContent.endPointX);
        line.height = Math.abs(lineContent.startPointY - lineContent.endPointY);

        return this.checkNodeConnectLineData;
    }
    
    createNodeConnectLine(targteId, toId) {
        let targetNode = this.getNodeById(targteId);
        let toNode = this.getNodeById(toId);
        if (targetNode.isEmpty() || toNode.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }
        let line = new MindElementData();
        line.id = IdGenerator.shared.getId();
        line.parentNodeId = targteId;
        line.type = MindElementType.NODE_CONNECT_LINE;
        line.backgroundColor = Colors.clear;
        line.borderColor = Colors.clear;
        line.borderWidth = 0;
        let lineContent = new LineElementContent();
        lineContent.targetId = toId;
        lineContent.connectLineType = this.addNodeConnectLineType;

        if (targetNode.value.type == MindElementType.NODE_CONNECT_LINE) {
            lineContent.startShape = LinePointShape.CIRCULAR;
        } else {
            switch (lineContent.connectLineType) {
                case ConnectLineType.CURVE_LINE:
                case ConnectLineType.RIGHT_ANGLE_LINE:
                case ConnectLineType.STRAIGHT_ARROW_LINE:
                    lineContent.startShape = LinePointShape.NORMAL;
                    break;
                case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                    lineContent.startShape = LinePointShape.CIRCULAR_RADIATION;
                    break;
                default:
                    lineContent.startShape = LinePointShape.NORMAL;
            }

        }
        if (toNode.value.type == MindElementType.NODE_CONNECT_LINE) {
            lineContent.endShape = LinePointShape.CIRCULAR;
        } else {
            switch (lineContent.connectLineType) {
                case ConnectLineType.CURVE_LINE:
                case ConnectLineType.RIGHT_ANGLE_LINE:
                case ConnectLineType.STRAIGHT_ARROW_LINE:
                    lineContent.endShape = LinePointShape.ARROW;
                    break;
                case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                    lineContent.endShape = LinePointShape.CIRCULAR;
                    break;
                default:
                    lineContent.endShape = LinePointShape.ARROW;
            }
        }

        if (this.addNodeConnectLineType == ConnectLineType.STRAIGHT_CIRCULAR_LINE ||
            this.addNodeConnectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
            lineContent.nodeConnectLineDottedLine = false;
        }
        if (targetNode.value.type == MindElementType.NODE_CONNECT_LINE && targetNode.value.lineContent != null) {
            lineContent.color = targetNode.value.lineContent.color;
        } else if (toNode.value.type == MindElementType.NODE_CONNECT_LINE && toNode.value.lineContent != null) {
            lineContent.color = toNode.value.lineContent.color;
        } else if (!Colors.isClear(toNode.value.borderColor) && toNode.value.borderWidth > 0) {
            lineContent.color = toNode.value.borderColor;
        } else if (!Colors.isClear(toNode.value.backgroundColor)) {
            if (toNode.value.backgroundColor != this.mindBGColor) {
                lineContent.color = toNode.value.backgroundColor;
            } else if (toNode.value.textContent != null &&
                !Colors.isClear(toNode.value.textContent.textColor) &&
                toNode.value.textContent.textColor != this.mindBGColor) {
                lineContent.color = toNode.value.textContent.textColor;
            } else {
                lineContent.color = toNode.value.styleColor;
            }
        } else if (toNode.value.textContent != null &&
            !Colors.isClear(toNode.value.textContent.textColor) &&
            toNode.value.textContent.textColor != this.mindBGColor) {
            lineContent.color = toNode.value.textContent.textColor;
        } else {
            lineContent.color = toNode.value.styleColor;
        }
        lineContent.lineWidth = 2.0;
        line.lineContent = lineContent;
        return line;
    }

    changMindBackgroundColor(color, isSave = true) {
        if (this.mindBGColor != color) {
            this.mindBGColor = color;
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
            this.timeMindTypeNodeUnit.setMindBGColor(this.mindBGColor);
            this.doubleBubbleMindNodeUnit.setMindBGColor(this.mindBGColor)
            this.freeNodes.forEach(item => {
                item.setMindBGColor(this.mindBGColor);
            });
            if (isSave) {
                this.willSave(true);
                this.addHostry();
            }
        }
    }
    //关键
    changeMindBackgroundColorFromUI(color) {
        delegate.changMindBackgroundColor(color);
    }

    onSelectedRainbowr(lineColors, fullColors) {

    }

    setlineThicken(start, end) {
        this.settingData.lineThicken = start;
        this.settingData.lineEndThicken = end;
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.mainMindNodeUnit.setlineThicken(start);
            this.doubleBubbleMindNodeUnit.setlineThicken(start);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.baseLine.timeLineContent != null) {
            this.timeMindTypeNodeUnit.baseLine.timeLineContent.lineThicken = start;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    setTightMode(value) {
        if (this.settingData.tightMode == value) {
            return;
        }
        this.settingData.tightMode = value;
        Config.setTightModeEdgeInsets(value);

        if (this.mindType == MindType.LINE_MAP || 
            this.mindType == MindType.FLOW_CHART || 
            this.mindType == MindType.BUBBLE_MAP) {
            var list = [];
            this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list);
            let freeNodesLength = this.freeNodes.length;
            for (let i = 0; i < freeNodesLength; i++) {
                const unit = this.freeNodes[i];
                this.serializeNodeElementsToList(unit.rootTreeNode, list);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                let previewData = node.value.copy();
                MindElementCalculation.set(node.value).caluleTextForData().calcule(false)
                if (this.isRootNode(previewData.id)) {
                    node.value.x = previewData.x - (node.value.width - previewData.width) / 2;
                    node.value.y = previewData.y - (node.value.height - previewData.height) / 2;
                    this.checkNodeConnectLinePointByChangeSize(previewData, node.value);
                }
                this.dataConnectLinePoint(node.value, previewData);
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            var list = [];
            this.serializeNodeElementsToListForTimeLine(list);
            for (let index = 0; index < list.length; index++) {
                let data = list[index];
                MindElementCalculation.set(data).caluleTextForData()
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            var list = [];
            this.serializeNodeElementsToListForDoubleBubble(this.doubleBubbleMindNodeUnit.rootTreeNode, list);
            this.serializeNodeElementsToListForDoubleBubble(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode, list);
            let length = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length
            for (let index = 0; index < length; index++) {
                let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
                list.add(data);
            }

            for (let index = 0; index < list.length; index++) {
                let data = list[index];
                MindElementCalculation.set(data).caluleTextForData()
            }
        }

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setSameLevelAlignment(value) {
        if (this.settingData.sameLevelAlignment == value) {
            return;
        }
        this.settingData.sameLevelAlignment = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setNodeOverlap(value) {
        if (this.settingData.isOverlap == value) {
            return;
        }
        this.settingData.isOverlap = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setUseTimeLineColor(value) {
        if (this.settingData.useTimeLineColor == value) {
            return;
        }
        this.settingData.useTimeLineColor = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    styleToPeer(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || this.isRootNode(node.value.id)) {
            return;
        }
        let freeNodesLength = this.freeNodes.length;
        for (let i = 0; i < freeNodesLength; i++) {
            const unit = this.freeNodes[i];
            unit.styleToPeer(node);;
        }
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            this.mainMindNodeUnit.styleToPeer(node);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();

        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.doubleBubbleMindNodeUnit.styleToPeer(node);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.styleToPeer(node);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    styleToAllSon(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || this.isRootNode(node.value.id)) {
            return;
        }
        let freeNodesLength = this.freeNodes.length;
        for (let i = 0; i < freeNodesLength; i++) {
            const unit = this.freeNodes[i];
            unit.styleToAllSon(node);;
        }
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            this.mainMindNodeUnit.styleToAllSon(node);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.doubleBubbleMindNodeUnit.styleToAllSon(node);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.TIME_MAP) {
            this.timeMindTypeNodeUnit.styleToPeer(node);
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    setlineDot(b) {
        let node = this.getSelectedNode();
        if (!this.selectedList.isEmpty()) {
            let selectedListLength = this.selectedList.length;
            for (let index = 0; index < selectedListLength; index++) {
                let selectedData = this.selectedList[index];
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                    this.mainMindNodeUnit.setlineDot(b, selectedData.id);
                    this.doubleBubbleMindNodeUnit.setlineDot(b, selectedData.id);
                    let freeNodesLength = this.freeNodes.length;
                    for (let index = 0; index < freeNodesLength; index++) {
                        let item = this.freeNodes[index];
                        item.setlineDot(b, selectedData.id);
                    }
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            let freeNodesLength = this.freeNodes.length;
            for (let index = 0; index < freeNodesLength; index++) {
                let item = this.freeNodes[index];
                if (!item.getNodeById(this.selectedNodeId).isEmpty()) {
                    item.setlineDot(b, this.selectedNodeId);
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                    return;
                }
            }
            if (node.isEmpty()) {
                this.mainMindNodeUnit.setlineDot(b, this.mainMindNodeUnit.rootTreeNode.value.id);
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            } else {
                let mainNode = this.mainMindNodeUnit.getNodeById(this.selectedNodeId);
                if (!mainNode.isEmpty()) {
                    this.mainMindNodeUnit.setlineDot(b, this.selectedNodeId);
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                    return;
                }
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            if (node.isEmpty()) {
                this.doubleBubbleMindNodeUnit.setlineDot(b, this.doubleBubbleMindNodeUnit.rootTreeNode.value.id);
                let freeNodesLength = this.freeNodes.length;
                for (let index = 0; index < freeNodesLength; index++) {
                    let item = this.freeNodes[index];
                    item.setlineDot(b, this.selectedNodeId);
                }
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            } else if (!node.isEmpty()) {
                let mainNode = this.doubleBubbleMindNodeUnit.getNodeById(this.selectedNodeId);
                let freeNodesLength = this.freeNodes.length;
                for (let index = 0; index < freeNodesLength; index++) {
                    let item = this.freeNodes[index];
                    item.setlineDot(b, this.selectedNodeId);
                }
                if (!mainNode.isEmpty()) {
                    this.doubleBubbleMindNodeUnit.setlineDot(b, this.selectedNodeId);
                }
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.baseLine.timeLineContent != null) {
            this.timeMindTypeNodeUnit.baseLine.timeLineContent.dottedLine = b;
            let freeNodesLength = this.freeNodes.length;
            for (let index = 0; index < freeNodesLength; index++) {
                let item = this.freeNodes[index];
                item.setlineDot(b, this.selectedNodeId);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.isFlowChart()) {
            let keys = this.nodeConnectLineDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let line = this.nodeConnectLineDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                line.lineContent.nodeConnectLineDottedLine = b
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    collectionStyleLineColor(data) {
        if ((this.mindType != MindType.LINE_MAP && this.mindType != MindType.BUBBLE_MAP) ||
            data == null ||
            data.subjectLineData == null ||
            data.subjectLineData.length == 0) {
            return
        }
        var colors = [];
        for (let index = 0; index < data.subjectLineData.length; index++) {
            let line = data.subjectLineData[index];
            if (line.lineContent != null) {
                colors.push(line.lineContent.color);
            }
        }
        if (colors.length > 1) {
            this.settingData.lineColor = colors
            this.mainMindNodeUnit.setLineColors(colors);
        }
    }

    setStyleToMap(data, isCoverCustomStyle = true) {
        let handle = new MindMapStyleHandle(this,
            this.mindType,
            this.globalLayout,
            this.globalLineLayout,
            this.mindDisplayType,
            this.mindBGColor).
            setData(this.mainMindNodeUnit,
                this.freeNodes,
                this.timeMindTypeNodeUnit,
                this.doubleBubbleMindNodeUnit,
                this.nodeConnectLineDataDict);
        handle.setStyleToMap(data, isCoverCustomStyle);
    }

    creatCustomStyle() {
        let handle = new MindMapStyleHandle(this,
            this.mindType,
            this.globalLayout,
            this.globalLineLayout,
            this.mindDisplayType,
            this.mindBGColor).
            setData(this.mainMindNodeUnit,
                this.freeNodes,
                this.timeMindTypeNodeUnit,
                this.doubleBubbleMindNodeUnit,
                this.nodeConnectLineDataDict);
        return handle.creatCustomStyle();
    }


    setTimeLineType(type) {
        this.timeMindTypeNodeUnit.setTimeLineType(type);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setShapeType(type) {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return;
        }
        let previewData = node.value.copy()
        if (node.value.type == MindElementType.EXPLAIN) {
            if (type == MindElementShapeType.Corner ||
                type == MindElementShapeType.Semicircle ||
                type == MindElementShapeType.Underline ||
                type == MindElementShapeType.Ellipse) {
                node.value.mindElementShape = type;
                if (node.value.mindElementShape == MindElementShapeType.Card) {
                    this.setNodeToCard(node.value.id, false)
                }
                MindElementCalculation.set(node.value).calcule(false)
                if (this.isRootNode(previewData.id)) {
                    node.value.x = previewData.x - (node.value.width - previewData.width) / 2;
                    node.value.y = previewData.y - (node.value.height - previewData.height) / 2;
                    this.checkNodeConnectLinePointByChangeSize(previewData, node.value);
                }
                this.dataConnectLinePoint(node.value, previewData);
            } else {
                return;
            }
        } else {
            if ((type == MindElementShapeType.Underline || type == MindElementShapeType.Two_Underline) &&
                node.value.mindElementShape != MindElementShapeType.Underline && node.value.mindElementShape != MindElementShapeType.Two_Underline) {
                let lines = this.getNodeLine(node.value.id)
                if (lines.length > 0 && lines[0].lineContent != null) {
                    if (Colors.isClear(node.value.borderColor)) {
                        node.value.borderColor = lines[0].lineContent.color
                        if (node.value.isCardContent()) {
                            node.value.checkSetTextColor(this.mindBGColor)
                        }
                    }
                    if (node.value.borderWidth < lines[0].lineContent.lineWidth) {
                        node.value.borderWidth = lines[0].lineContent.lineWidth
                    }
                }
            }
            
            node.value.mindElementShape = type;
            if (node.value.mindElementShape == MindElementShapeType.Card) {
                this.setNodeToCard(node.value.id, false)
            }
            MindElementCalculation.set(node.value).calcule(false)
            if (this.isRootNode(previewData.id)) {
                node.value.x = previewData.x - (node.value.width - previewData.width) / 2;
                node.value.y = previewData.y - (node.value.height - previewData.height) / 2;
                this.checkNodeConnectLinePointByChangeSize(previewData, node.value);
            }
            this.dataConnectLinePoint(node.value, previewData);

            this.addChangeDataStyleId(node.value);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    singleSetShapeDot(value) {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return;
        }
        this.addChangeDataStyleId(node.value);
        node.value.borderDot = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setShapeDot(value) {
        if (this.selectedList.isEmpty()) {
            this.singleSetShapeDot(value);
            return;
        }
        this.selectedList.forEach(item => {
            item.borderDot = value;
            this.addChangeDataStyleId(item);
        })
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    singleSetShapeDot(value) {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return;
        }
        this.addChangeDataStyleId(node.value);
        node.value.borderDot = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setShadow(value) {
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return
        }

        if (this.selectedList.isEmpty()) {
            let data = node.value
            if (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.EXPLAIN ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.CONTENT_GENERALIZATION) {
                    data.isShadow = value;
                this.addChangeDataStyleId(data);
            } else {
                return
            }    
        } else {
            this.selectedList.forEach(item => {
                if (item.type == MindElementType.MAIN_SUBJECT ||
                    item.type == MindElementType.SUBJECT ||
                    item.type == MindElementType.EXPLAIN ||
                    item.type == MindElementType.SON_SUBJECT ||
                    item.type == MindElementType.CONTENT_GENERALIZATION) {
                    item.isShadow = value;
                    this.addChangeDataStyleId(item);
                }                
            })
        }
        
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setlineShadow(b) {
        let node = this.getSelectedNode();
        if (node.value.type == MindElementType.NODE_CONNECT_LINE) {
            node.value.isShadow = b
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return
        } else if (node.value.type == MindElementType.BAOWEI_VIEW) {
            node.value.isShadow = b
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
            return
        }
        if (!this.selectedList.isEmpty()) {
            let selectedListLength = this.selectedList.length;
            for (let index = 0; index < selectedListLength; index++) {
                let selectedData = this.selectedList[index];
                if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                    selectedData.type == MindElementType.SUBJECT ||
                    selectedData.type == MindElementType.SON_SUBJECT ||
                    selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                    this.mainMindNodeUnit.setlineShadow(b, selectedData.id);
                    this.doubleBubbleMindNodeUnit.setlineShadow(b, selectedData.id);
                    let freeNodesLength = this.freeNodes.length;
                    for (let index = 0; index < freeNodesLength; index++) {
                        let item = this.freeNodes[index];
                        item.setlineShadow(b, selectedData.id);
                    }
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            let freeNodesLength = this.freeNodes.length;
            for (let index = 0; index < freeNodesLength; index++) {
                let item = this.freeNodes[index];
                if (!item.getNodeById(this.selectedNodeId).isEmpty()) {
                    item.setlineShadow(b, this.selectedNodeId);
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                    return;
                }
            }
            if (node.isEmpty()) {
                this.mainMindNodeUnit.setlineShadow(b, this.mainMindNodeUnit.rootTreeNode.value.id);
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            } else {
                let mainNode = this.mainMindNodeUnit.getNodeById(this.selectedNodeId);
                if (!mainNode.isEmpty()) {
                    this.mainMindNodeUnit.setlineShadow(b, this.selectedNodeId);
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                    return;
                }
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            if (node.isEmpty()) {
                this.doubleBubbleMindNodeUnit.setlineShadow(b, this.doubleBubbleMindNodeUnit.rootTreeNode.value.id);
                let freeNodesLength = this.freeNodes.length;
                for (let index = 0; index < freeNodesLength; index++) {
                    let item = this.freeNodes[index];
                    item.setlineShadow(b, this.selectedNodeId);
                }
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
                return;
            } else if (!node.isEmpty()) {
                let mainNode = this.doubleBubbleMindNodeUnit.getNodeById(this.selectedNodeId);
                let freeNodesLength = this.freeNodes.length;
                for (let index = 0; index < freeNodesLength; index++) {
                    let item = this.freeNodes[index];
                    item.setlineShadow(b, this.selectedNodeId);
                }
                if (!mainNode.isEmpty()) {
                    this.doubleBubbleMindNodeUnit.setlineShadow(b, this.selectedNodeId);
                }
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.baseLine.timeLineContent != null) {
            this.timeMindTypeNodeUnit.baseLine.isShadow = b;
            let freeNodesLength = this.freeNodes.length;
            for (let index = 0; index < freeNodesLength; index++) {
                let item = this.freeNodes[index];
                item.setlineShadow(b, this.selectedNodeId);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.isFlowChart()) {
            let keys = this.nodeConnectLineDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let line = this.nodeConnectLineDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                line.isShadow = b
            }
            
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    addIconToNode(key, styleColor, tintColor) {
        this.delegate.editmapScrollView.hideInput();
        
        if (styleColor == null) {
            let node = this.getNodeById(this.selectedNodeId)
            if (node.isEmpty()) {
                return
            }
            let color = Colors.red
            if (node.value.iconElementContents != null && 
                node.value.iconElementContents.length > 0) {

                let mindIcons = MindIconsManager.get().mindIcons;
                let mindIconType = null;
                for (let index = 0; index < mindIcons.length; index++) {
                    let mindTyple = mindIcons[index]
                    for (let j = 0; j < mindTyple.cells.length; j++) {
                        let cell = mindTyple.cells[j]
                        if (cell.key == key) {
                            mindIconType = mindTyple;
                            break
                        }
                    }
                }
                if (mindIconType != null && mindIconType.cells.length > 0) {
                    for (let index = 0; index < mindIconType.cells.length; index++) {
                        let item = mindIconType.cells[index]
                        for (let j = 0; j < node.value.iconElementContents.length; j++) {
                            let cell = node.value.iconElementContents[j]
                            if (cell.key == item.key) {
                                color = cell.styleColor
                                break
                            }
                        }
                    }
                }
            }
            let iconElementContent = new IconElementContent(key, 20, 20, color);
            this.selectIcons(this.selectedNodeId, iconElementContent); 
        } else {
            let iconElementContent = new IconElementContent(key, 20, 20, styleColor, tintColor);
            this.selectIcons(this.selectedNodeId, iconElementContent);
        }
        
    }

    selectIcons(id, data) {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            this.selectAddTargetTips();
            return;
        }
        let preData = node.value.copy();
        let isBigIcon = MindIconsManager.get().isBigIcon(data.key);
        let iconElementContents = node.value.iconElementContents;
        if (iconElementContents == null) {
            iconElementContents = new Array();
        }
        let mindIcons = MindIconsManager.get().mindIcons;
        let mindIconType = null;
        for (let index = 0; index < mindIcons.length; index++) {
            let mindTyple = mindIcons[index]
            for (let j = 0; j < mindTyple.cells.length; j++) {
                let cell = mindTyple.cells[j]
                if (cell.key == data.key) {
                    mindIconType = mindTyple;
                    break
                }
            }
        }
        let MindIconsManagers = MindIconsManager.get().tabDatas
        let MindIconsManagerLength = MindIconsManagers.length
        for (let index = 0; index < MindIconsManagerLength; index++) {
            let mindTyple = MindIconsManagers[index]
            for (let j = 0; j < mindTyple.cells.length; j++) {
                let cell = mindTyple.cells[j]
                if (cell.key == data.key) {
                    mindIconType = mindTyple;
                    break
                }
            }
        }
        if (mindIconType == null) {
            return;
        }

        if (iconElementContents.length > 0) {
            let newIconElementContents = new Array();
            for (let index = 0; index < iconElementContents.length; index++) {
                let isExist = false;
                mindIconType.cells.forEach(cell => {
                    if (cell.key == iconElementContents[index].key) {
                        isExist = true;
                    }
                });
                if (!isExist) {
                    newIconElementContents.push(iconElementContents[index]);
                }
            }
            iconElementContents = newIconElementContents;
        }
        
        let iconSize = isBigIcon ? (new UiUtil).dip2px(60) : (new UiUtil).dip2px(26);
        if (node.value.textContent != null) {
            let size = MindElementCalculation.caluleText("A", node.value.textContent.textFontSize, node.value.textContent.textBold)
            iconSize = node.value.isCardContent() || isBigIcon ? size.getHeight() * 2 : size.getHeight() * 0.75
        } else if (node.value.generalizationContent != null) {
            let size = MindElementCalculation.caluleText("A", 
                        node.value.generalizationContent.textFontSize, 
                        node.value.generalizationContent.textBold)
            iconSize = node.value.isCardContent() || isBigIcon ? size.getHeight() * 2 : size.getHeight() * 0.75
        }
        let icon = new IconElementContent(data.key, iconSize, iconSize, data.styleColor, data.tintColor);
        if (iconElementContents.length > 0) {
            icon.layoutType = iconElementContents[0].layoutType;
            icon.width = iconElementContents[0].width;
            icon.height = iconElementContents[0].height;
            if (node.value.isCardContent() || isBigIcon) {
                icon.layoutType = IconLayoutType.LAYOUT_TOP;
            }
            if (isBigIcon) {
                iconElementContents = []
            }
        } else if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
            node.value.layout == NodeLayoutType.LAYOUT_TOP ||
            node.value.layout == NodeLayoutType.LAYOUT_CIRCLE ||
            node.value.layout == NodeLayoutType.LAYOUT_RADIATE ||
            node.value.layout == NodeLayoutType.LAYOUT_BUBBLE ||
            node.value.layout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE ||
            node.value.isCardContent() || isBigIcon) {
            icon.layoutType = IconLayoutType.LAYOUT_TOP;
        } else {
            if (this.isTimeNode(node.value.id)) {
                icon.layoutType = IconLayoutType.LAYOUT_TOP;
            } else {
                icon.layoutType = IconLayoutType.LAYOUT_LEFT;
            }
            
        }
        iconElementContents.push(icon);
        node.value.iconElementContents = iconElementContents;

        MindElementCalculation.set(node.value).calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    getGolbalLineColor() {
        if (this.mindType == MindType.TIME_MAP) {
            return this.timeMindTypeNodeUnit.getGolbalLineColor();
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            return this.mainMindNodeUnit.getGolbalLineColor();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return this.doubleBubbleMindNodeUnit.getGolbalLineColor();
        }
        return Colors.red;
    }

    getGolbalLineWidth() {
        if (this.mindType == MindType.TIME_MAP) {
            return this.timeMindTypeNodeUnit.getGolbalLineWidth();
        } else if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            return this.mainMindNodeUnit.getGolbalLineWidth();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return this.doubleBubbleMindNodeUnit.getGolbalLineWidth();
        }
        return 2.0;
    }

    changeLineColor(value) {
        let node = this.getSelectedNode();
        
        if (node.isEmpty() || this.isRootNode(node.value.id)) {
            if (this.isRootNode(node.value.id) && node.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                this.changNodeCuatomLineColor(node.value.id, value)
            } else {
                this.changeNodeAllLineColor(value);
            }
        } else {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        this.changeNodeLineColor(textContent.id, value);
                        MindElementCalculation.set(selectedData).caluleTextForData().calcule();
                    }
                }
            } else {
                this.changeNodeLineColor(node.value.id, value);
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changNodeCuatomLineColor(id, color) {
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.mainMindNodeUnit.changNodeCuatomLineColor(id, color);
        }
        this.freeNodes.forEach(unit => {
            unit.changNodeCuatomLineColor(id, color);
        });
    }

    changeLineWidth(value) {
        let node = this.getSelectedNode();
        if (value != -1) {
            if (node.isEmpty() || this.isRootNode(node.value.id)) {
                this.changeNodeAllLineWidth(value);
            } else if (node.value.lineContent != null) {
                node.value.lineContent.lineWidth = value;
            } else {
                if (!this.selectedList.isEmpty()) {
                    for (let index = 0; index < this.selectedList.length; index++) {
                        let selectedData = this.selectedList[index]
                        if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                            selectedData.type == MindElementType.SUBJECT ||
                            selectedData.type == MindElementType.SON_SUBJECT ||
                            selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                            let textContent = selectedData
                            this.changeNodeLineWidth(textContent.id, value);
                            MindElementCalculation.set(selectedData).caluleTextForData().calcule();
                        }
                    }
                } else {
                    this.changeNodeLineWidth(node.value.id, value);
                }

            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    // 自由主题拖动吸附
    changeFreeNodeMoveAdsorbent(val) {
        if (this.settingData.freeNodeMoveAdsorbent.isValue() == val) {
            return
        }
        this.settingData.freeNodeMoveAdsorbent.setValue(val);
    }

    isFreeNodeMoveAdsorbent() {
        return this.settingData.freeNodeMoveAdsorbent.isValue()
    }

    // 主分支子节点是否允许自由布局
    changeBranchNodeFreelayout(val) {
        if (this.settingData.branchNodeFreelayout.isValue() == val) {
            return
        }
        this.settingData.branchNodeFreelayout.setValue(val);
        this.resetRootNodeChildBranchNodeFreelayout()
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    resetRootNodeChildBranchNodeFreelayout() {
        if (!this.settingData.branchNodeFreelayout.isValue() || !this.isBranchNodeFreelayoutMode()) {
            if (this.mainMindNodeUnit.rootTreeNode != null && !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
                let childCount = this.mainMindNodeUnit.rootTreeNode.children.length
                for (let index = 0; index < childCount; index++) {
                    let node = this.mainMindNodeUnit.rootTreeNode.children[index]
                    if (node == null || node.isEmpty()) {
                        continue
                    }
                    node.value.isFreeLayoutNode = false
                }
            }
        }
    }

    isBranchNodeFreelayout() {
        return this.settingData.branchNodeFreelayout.isValue()
    }

    isBranchNodeFreelayoutMode() {
        if (this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return true
        }
        if (this.mindType != MindType.LINE_MAP || this.mainMindNodeUnit.rootTreeNode == null) {
            return false
        }

        return this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FREE_TREE;
    }

    onChangeFastStyleColorsByRainbow() {
        var colors = []
        var generalizationLineColor = []
        if (this.settingData.lineColor.length <= 2) {
            colors = Colors.FistStyleRainbowColores1[0];
            generalizationLineColor = Colors.FistStyleFreeGeneralizationColores1[0];
        } else {
            for (let index = 0; index < Colors.FistStyleRainbowColores1.length; index++) {
                let item = Colors.FistStyleRainbowColores1[index]
                if (item.length != this.settingData.lineColor.length + 2) {
                    continue;
                }
                var hit = true
                for (let j = 0; j < this.settingData.lineColor.length; j++) {
                    if (this.settingData.lineColor[j] != item[j + 2]) {
                        hit = false
                        break
                    }
                }
                if (hit) {
                    if (index == Colors.FistStyleRainbowColores1.length - 1) {
                        colors = Colors.FistStyleRainbowColores1[0]
                        generalizationLineColor = Colors.FistStyleFreeGeneralizationColores1[0];
                    } else {                        
                        colors = Colors.FistStyleRainbowColores1[index + 1]
                        generalizationLineColor = Colors.FistStyleFreeGeneralizationColores1[index + 1];
                    }
                    break
                }
            }
        }
        if (colors.length == 0) {
            colors = Colors.FistStyleRainbowColores1[0]
            generalizationLineColor = Colors.FistStyleFreeGeneralizationColores1[0];
        }
        var lineColor = []
        for (let index = 1; index < colors.length; index++) {
            lineColor.push(colors[index])
        }
        if (generalizationLineColor.length == 0) {
            generalizationLineColor = [colors[1], colors[2], colors[2]]
        } else if (generalizationLineColor.length == 1) {
            generalizationLineColor.push(colors[2])
            generalizationLineColor.push(colors[2])
        } else if (generalizationLineColor.length == 2) {
            generalizationLineColor.push(colors[2])
        }
        this.onChangeFastStyleColors(colors[0], lineColor,
                                generalizationLineColor[0], lineColor[2],
                                generalizationLineColor[1])
    }

    onChangeFastStyleColorsBySolid() {
        var colors = []
        var generalizationLineColor = []
        if (this.settingData.lineColor.length <= 2) {
            colors = Colors.FistStyleRainbowColores2[0];
            generalizationLineColor = Colors.FistStyleFreeGeneralizationColores2[0];
        } else {
            for (let index = 0; index < Colors.FistStyleRainbowColores2.length; index++) {
                let item = Colors.FistStyleRainbowColores2[index]
                if (item.length != this.settingData.lineColor.length + 2) {
                    continue;
                }
                var hit = true
                for (let j = 0; j < this.settingData.lineColor.length; j++) {
                    if (this.settingData.lineColor[j] != item[j + 2]) {
                        hit = false
                        break
                    }
                }
                if (hit) {
                    if (index == Colors.FistStyleRainbowColores2.length - 1) {
                        colors = Colors.FistStyleRainbowColores2[0]
                        generalizationLineColor = Colors.FistStyleFreeGeneralizationColores2[0];
                    } else {
                        if (index == 0 && this.mainMindNodeUnit.rootTreeNode != null &&
                            (this.mainMindNodeUnit.rootTreeNode.children.length <= 1 || this.mainMindNodeUnit.isUnderline(this.mainMindNodeUnit.rootTreeNode.children[0].value))) {
                            colors = Colors.FistStyleRainbowColores2[index + 2]
                            generalizationLineColor = Colors.FistStyleFreeGeneralizationColores2[index + 2];
                        } else {
                            colors = Colors.FistStyleRainbowColores2[index + 1]
                            generalizationLineColor = Colors.FistStyleFreeGeneralizationColores2[index + 1];
                        }
                    }
                    break
                }
            }
        }
        if (colors.length == 0) {
            colors = Colors.FistStyleRainbowColores2[0]
            generalizationLineColor = Colors.FistStyleFreeGeneralizationColores2[0];
        }
        var lineColor = []
        for (let index = 1; index < colors.length; index++) {
            lineColor.push(colors[index])
        }
        if (generalizationLineColor.length == 0) {
            generalizationLineColor = [colors[1], colors[2], colors[2]]
        } else if (generalizationLineColor.length == 1) {
            generalizationLineColor.push(colors[2])
            generalizationLineColor.push(colors[2])
        } else if (generalizationLineColor.length == 2) {
            generalizationLineColor.push(colors[2])
        }
        this.onChangeFastStyleColors(colors[0], lineColor,
                                generalizationLineColor[0], lineColor[2],
                                generalizationLineColor[1])
    }

    onChangeFastStyleColors(bgColor, lineColors,
                            generalizationColor, generalizationLineColor,
                            generalizationSonColor) {
        if (lineColors.length < 3) {
           return
        }
        this.changMindBackgroundColor(bgColor, false);
        this.delegate.changMindBackgroundColor(bgColor)

        this.settingData.lineColor = []
        let lineColorsLength = lineColors.length
        for (let index = 1; index < lineColorsLength; index++) {
            this.settingData.lineColor.push(lineColors[index]);
        }

        if (this.mindType == MindType.LINE_MAP || 
            this.mindType == MindType.BUBBLE_MAP || 
            this.mindType == MindType.DOUBLE_BUBBLE_MAP ||
            this.mindType == MindType.FLOW_CHART) {
            this.mainMindNodeUnit.onSelectedRainbowr(lineColors, false, 1,
                                                        generalizationColor, generalizationLineColor, generalizationSonColor)
            this.doubleBubbleMindNodeUnit.onSelectedRainbowr(lineColors, lineColors, false)
            
            let freeNodesLength = this.freeNodes.length;
            for (let index = 0; index < freeNodesLength; index++) {
                let unit = this.freeNodes[index];
                if (unit.rootTreeNode != null && !unit.rootTreeNode.isEmpty()) {
                    let freeLine = [generalizationColor, generalizationLineColor, generalizationLineColor, generalizationLineColor]
                    if (this.isFlowChart()) {
                        let colorsIndex = 1 + (index % (lineColors.length - 1))
                        
                        freeLine = [lineColors[colorsIndex], generalizationLineColor, generalizationLineColor, generalizationLineColor]
                    }
                    unit.onSelectedRainbowr(freeLine, false, 1,
                                            generalizationColor, generalizationLineColor,
                                            generalizationSonColor, generalizationSonColor)
                }
            }
            let keys = this.nodeConnectLineDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let line = this.nodeConnectLineDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                if (this.isFlowChart()) {
                    let parentNode = this.getNodeById(line.parentNodeId)
                    if (parentNode.isEmpty() || parentNode.value.type == MindElementType.NODE_CONNECT_LINE ||
                        parentNode.value.type == MindElementType.BAOWEI_VIEW) {
                        line.lineContent.color = lineColors[0]
                        line.lineContent.checkColorSetTextColor(bgColor)
                    } else {
                        if (parentNode.value.borderColor == this.mindBGColor || 
                            Colors.isClear(parentNode.value.borderColor) ||
                            parentNode.value.borderWidth == 0) {
                            if (Colors.isClear(parentNode.value.backgroundColor) ||
                                parentNode.value.backgroundColor == this.mindBGColor) {
                                line.lineContent.color = lineColors[0]
                            } else {
                                line.lineContent.color = parentNode.value.backgroundColor
                            }
                        } else {
                            line.lineContent.color = parentNode.value.borderColor
                        }
                        
                        line.lineContent.checkColorSetTextColor(bgColor)
                    }
                } else {
                    let parentNode = this.getNodeById(line.parentNodeId)
                    if (!parentNode.isEmpty()) {
                        let list = this.getNodeLine(line.parentNodeId)
                        if (list.length > 0 && list[0].lineContent != null) {
                            line.lineContent.color = list[0].lineContent.color
                            line.lineContent.checkColorSetTextColor(bgColor)
                        } else {
                            let targetNode = this.getNodeById(line.lineContent.targetId)
                            if (!targetNode.isEmpty()) {
                                let list = this.getNodeLine(targetNode.value.id)
                                if (list.length > 0 && list[0].lineContent != null) {
                                    line.lineContent.color = list[0].lineContent.color
                                    line.lineContent.checkColorSetTextColor(bgColor)
                                }
                            }
                        }
                    } else {
                        let targetNode = this.getNodeById(line.lineContent.targetId)
                        if (!targetNode.isEmpty()) {
                            let list = this.getNodeLine(targetNode.value.id)
                            if (list.length > 0 && list[0].lineContent != null) {
                                line.lineContent.color = list[0].lineContent.color
                                line.lineContent.checkColorSetTextColor(bgColor)
                            }
                        }
                    }
                }
            }
            
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else if (this.mindType == MindType.TIME_MAP) {
            let freeNodesLength = this.freeNodes.length;
            for (let index = 0; index < freeNodesLength; index++) {
                let unit = this.freeNodes[index];
                if (unit.rootTreeNode != null && !unit.rootTreeNode.isEmpty()) {
                    let freeLine = [generalizationColor, generalizationLineColor, generalizationLineColor, generalizationLineColor]
                    unit.onSelectedRainbowr(freeLine, false, 1,
                                            generalizationColor, generalizationLineColor,
                                            generalizationSonColor, generalizationSonColor)
                }
            }
            this.timeMindTypeNodeUnit.onSelectedRainbowr(lineColors)
            let keys = this.nodeConnectLineDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let line = this.nodeConnectLineDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    continue;
                }
                let parentNode = this.getNodeById(line.parentNodeId)
                if (!parentNode.isEmpty()) {
                    let list = this.getNodeLine(line.parentNodeId)
                    if (list.length > 0 && list[0].lineContent != null) {
                        line.lineContent.color = list[0].lineContent.color
                        line.lineContent.checkColorSetTextColor(bgColor)
                    } else {
                        let targetNode = this.getNodeById(line.lineContent.targetId)
                        if (!targetNode.isEmpty()) {
                            let list = this.getNodeLine(targetNode.value.id)
                            if (list.length > 0 && list[0].lineContent != null) {
                                line.lineContent.color = list[0].lineContent.color
                                line.lineContent.checkColorSetTextColor(bgColor)
                            }
                        }
                    }
                } else {
                    let targetNode = this.getNodeById(line.lineContent.targetId)
                    if (!targetNode.isEmpty()) {
                        let list = this.getNodeLine(targetNode.value.id)
                        if (list.length > 0 && list[0].lineContent != null) {
                            line.lineContent.color = list[0].lineContent.color
                            line.lineContent.checkColorSetTextColor(bgColor)
                        }
                    }
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    onChangeRainbowColor(value, nodeBackgorundColor = false, refreshUi = true) {
        if (Colors.RainbowColores.length > value) {
            let lineColors = Colors.RainbowColores[value];
            if (lineColors.length > 0) {
                this.settingData.lineColor = [];
                let lineColorsLength = lineColors.length;
                for (let index = 1; index < lineColorsLength; index++) {
                    this.settingData.lineColor.push(lineColors[index]);
                }
            }
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                this.mainMindNodeUnit.onSelectedRainbowr(lineColors, nodeBackgorundColor);
                this.doubleBubbleMindNodeUnit.onSelectedRainbowr(lineColors, nodeBackgorundColor);

                let freeNodesLength = this.freeNodes.length;
                for (let index = 0; index < freeNodesLength; index++) {
                    let unit = this.freeNodes[index];
                    let node = unit.getNodeById(this.selectedNodeId);
                    if (!node.isEmpty()) {
                        unit.onSelectedRainbowr(lineColors, nodeBackgorundColor);
                    }
                }
                if (refreshUi) {
                    this.refresh(true);
                    this.willSave(true);
                    this.addHostry();
                }
            } else if (this.mindType == MindType.FLOW_CHART) {
                this.mainMindNodeUnit.onSelectedRainbowr(lineColors, nodeBackgorundColor);
                let lines = this.getNodeConnectLine(this.mainMindNodeUnit.rootTreeNode.value.id)
                for(let index = 0; index < lines.length; index++) {
                    let line = lines[index];
                    if (line.isEmpty() || line.lineContent == null) {
                        continue;
                    }
                    line.lineContent.color = lineColors[0]
                }
                let freeNodesLength = this.freeNodes.length;
                for (let index = 0; index < freeNodesLength; index++) {
                    let unit = this.freeNodes[index];
                    let color = lineColors[(index + 1) % lineColors.length];
                    unit.setDataColorForRainbowrLine(unit.rootTreeNode.value, color)
                    let lines = this.getNodeConnectLine(unit.rootTreeNode.value.id)
                    for(let j = 0; j < lines.length; j++) {
                        let line = lines[j];
                        if (line.isEmpty() || line.lineContent == null) {
                            continue;
                        }
                        line.lineContent.color = color
                    }
                }
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }
        }
    }

    setSubjectSpcaeHorizontal(value) {
        if (this.settingData.subjectSpcaeHorizontal == value) {
            return;
        }
        this.settingData.subjectSpcaeHorizontal = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setSubjectSpcaeVertical(value) {
        if (this.settingData.subjectSpcaeVertical == value) {
            return;
        }
        this.settingData.subjectSpcaeVertical = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setSonSubjectSpcaeHorizontal(value) {
        if (this.settingData.sonSubjectSpcaeHorizontal == value) {
            return;
        }
        this.settingData.sonSubjectSpcaeHorizontal = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setSonSubjectSpcaeVertical(value) {
        if (this.settingData.sonSubjectSpcaeVertical == value) {
            return;
        }
        this.settingData.sonSubjectSpcaeVertical = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    deleteTask() {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.taskContent == null) {
            return;
        }
        node.value.taskContent = null;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    delLatext1(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || (node.value.textContent == null && node.value.generalizationContent == null)) {
            return;
        }
        if (node == null || node.value == null || node.value.isEmpty() || node.value.latexContent == null || new Strings().isEmpty(node.value.latexContent.text)) {
            return;
        }
        node.value.latexContent = null;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    delLatext() {
        this.delLatext1(this.selectedNodeId);
    }

    setTextFontSizeUp(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
            node.value.lineContent.textContent.setTextFontSizeUp(-1, -1);
        }
        if (node.value.textContent != null) {
            node.value.textContent.setTextFontSizeUp(this.delegate.getSelectionStart(node.value.id),
                this.delegate.getSelectionEnd(node.value.id));
        }
        if (node.value.generalizationContent != null) {
            node.value.generalizationContent.setTextFontSizeUp(this.delegate.getSelectionStart(node.value.id),
                this.delegate.getSelectionEnd(node.value.id));
        }
        this.addChangeDataStyleId(node.value);
        MindElementCalculation.set(node.value).caluleTextForData();
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setTextFontSizeDown(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
            node.value.lineContent.textContent.setTextFontSizeDown(-1, -1);

        }
        if (node.value.textContent != null) {
            node.value.textContent.setTextFontSizeDown(this.delegate.getSelectionStart(node.value.id),
                this.delegate.getSelectionEnd(node.value.id));
        }
        if (node.value.generalizationContent != null) {
            node.value.generalizationContent.setTextFontSizeDown(this.delegate.getSelectionStart(node.value.id),
                this.delegate.getSelectionEnd(node.value.id));
        }
        this.addChangeDataStyleId(node.value);
        MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeLineToNodeColor(value) {
        //关联线虚线
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.lineContent.dottedLine == null) {
            return;
        }
        node.value.lineContent.dottedLine = value;
        node.value.lineContent.nodeConnectLineDottedLine = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeBaoweiFullAndConnectLineColor(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.lineContent == null) {
            let line = this.getLineDataById(this.selectedNodeId)
            if (!line.isEmpty()) {
                node = new LineMindTypeNode(line)
            }
        }
        if (node.isEmpty() || node.value.lineContent == null || node.value.lineContent.color == value) {
            return;
        }

        node.value.lineContent.color = value;        
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeBaoweiLineColor(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.lineContent == null || node.value.lineContent.strokeColor == value) {
            return;
        }
        
        node.value.lineContent.strokeColor = value;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeTextStyle(value, isCardTitle = false) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        let startIndex = this.delegate.getSelectionStart(node.value.id);
        let endIndex = this.delegate.getSelectionEnd(node.value.id);
        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                var nodeValue = false;
                for (let index = 0; index < this.selectedList.length; index++) {
                    let textContent = this.selectedList[index].textContent
                    if (textContent != null) {
                        if (textContent != null) {
                            if (value == 0) {
                                nodeValue = textContent.textBold;
                            }
                            if (value == 1) {
                                nodeValue = textContent.textItalics
                            }
                            if (value == 2) {
                                nodeValue = textContent.textStrikethrough
                            }
                            break
                        }
                    } else if (this.selectedList[index].generalizationContent != null) {
                        if (this.selectedList[index].generalizationContent != null) {
                            if (value == 0) {
                                nodeValue = this.selectedList[index].generalizationContent.textBold;
                            }
                            if (value == 1) {
                                nodeValue = this.selectedList[index].generalizationContent.textItalics
                            }
                            if (value == 2) {
                                nodeValue = this.selectedList[index].generalizationContent.textStrikethrough
                            }
                            break
                        }
                    }
                }
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData.textContent

                        if (textContent == null && selectedData.generalizationContent != null) {
                            textContent = selectedData.generalizationContent
                        }

                        if (textContent == null) {
                            continue;
                        }
                        if (value == 0) {
                            textContent.textBold = !nodeValue;
                        }
                        if (value == 1) {
                            textContent.textItalics = !nodeValue;
                        }
                        if (value == 2) {
                            textContent.textStrikethrough = !nodeValue;
                        }
                        MindElementCalculation.set(selectedData).caluleTextForData().calcule();
                        this.addChangeDataStyleId(selectedData);
                    }
                }
            } else {

                let textContent = node.value.textContent
                if (isCardTitle && node.value.isCardContent()) {
                    textContent = node.value.titleContent;
                }
                if (textContent == null && node.value.generalizationContent != null) {
                    textContent = node.value.generalizationContent
                }

                if (textContent == null && node.value.lineContent != null && node.value.lineContent.textContent != null) {
                    textContent = node.value.lineContent.textContent;
                }

                if (textContent != null) {
                    if (value == 0) {
                        textContent.setTextBoldByPoint(startIndex, endIndex);
                    }
                    if (value == 1) {
                        textContent.setTextItalicsByPoint(startIndex, endIndex);
                    }
                    if (value == 2) {
                        textContent.setTextStrikethroughByPoint(startIndex, endIndex);
                    }
                    MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
                    this.addChangeDataStyleId(node.value);
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTextColor(value, isCardTitle = false) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        let startIndex = this.delegate.getSelectionStart(node.value.id);
        let endIndex = this.delegate.getSelectionEnd(node.value.id);

        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0, selectedListLen = this.selectedList.length; index < selectedListLen; index++) {
                    let selectedData = this.selectedList[index];
                    let selectedDataType = selectedData.type;
                    if (selectedDataType == MindElementType.MAIN_SUBJECT ||
                        selectedDataType == MindElementType.SUBJECT ||
                        selectedDataType == MindElementType.SON_SUBJECT ||
                        selectedDataType == MindElementType.CONTENT_GENERALIZATION) {
                        let textContent = selectedData.textContent
                        if (textContent != null) {
                            if (textContent.textColor == value) {
                                continue
                            }
                            textContent.textColor = value;
                            this.addChangeDataStyleId(selectedData);
                        } else if (selectedData.generalizationContent != null) {
                            if (selectedData.generalizationContent.textColor == value) {
                                continue
                            }
                            selectedData.generalizationContent.textColor = value;
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (isCardTitle && node.value.isCardContent()) {
                    node.value.titleContent.setTextColor(startIndex, endIndex, value);
                } else if (node.value.textContent != null) {
                    if (node.value.textContent.textColor == value) {
                        return
                    }
                    node.value.textContent.setTextColor(startIndex, endIndex, value);
                } else if (node.value.generalizationContent != null) {
                    if (node.value.generalizationContent.textColor == value) {
                        return
                    }
                    node.value.generalizationContent.setTextColor(startIndex, endIndex, value);
                } else if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
                    if (node.value.lineContent.textContent.textColor == value) {
                        return
                    }
                    node.value.lineContent.textContent.textColor = value;
                }
                this.addChangeDataStyleId(node.value);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTextShadowColor(value, isCardTitle = false) {
        let node = this.getSelectedNode();

        if (node.isEmpty()) {
            return;
        }
        let startIndex = this.delegate.getSelectionStart(node.value.id);
        let endIndex = this.delegate.getSelectionEnd(node.value.id);
        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0, selectedListLen = this.selectedList.length; index < selectedListLen; index++) {
                    let selectedData = this.selectedList[index];
                    let selectedDataType = selectedData.type;
                    if (selectedDataType == MindElementType.MAIN_SUBJECT ||
                        selectedDataType == MindElementType.SUBJECT ||
                        selectedDataType == MindElementType.SON_SUBJECT ||
                        selectedDataType == MindElementType.CONTENT_GENERALIZATION) {
                        let textContent = selectedData.textContent
                        if (textContent != null) {
                            if (textContent.textShadow == value) {
                                continue
                            }
                            textContent.textShadow = value;
                            this.addChangeDataStyleId(selectedData);
                        } else if (selectedData.generalizationContent != null) {
                            if (selectedData.generalizationContent.textShadow == value) {
                                continue
                            }
                            selectedData.generalizationContent.textShadow = value;
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (isCardTitle && node.value.isCardContent()) {
                    node.value.titleContent.setTextShadow(startIndex, endIndex, value);
                } else if (node.value.textContent != null) {
                    if (node.value.textContent.textShadow == value) {
                        return
                    }
                    node.value.textContent.setTextShadow(startIndex, endIndex, value);
                } else if (node.value.generalizationContent != null) {
                    if (node.value.generalizationContent.textShadow == value) {
                        return
                    }
                    node.value.generalizationContent.setTextShadow(startIndex, endIndex, value);
                } else if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
                    if (node.value.lineContent.textContent.textShadow == value) {
                        return
                    }
                    node.value.lineContent.textContent.textShadow = value;
                }
                this.addChangeDataStyleId(node.value);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTextBgColor(value, isCardTitle = false) {
        let node = this.getSelectedNode();

        if (node.isEmpty()) {
            return;
        }

        let startIndex = this.delegate.getSelectionStart(node.value.id);
        let endIndex = this.delegate.getSelectionEnd(node.value.id);

        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0, selectedListLen = this.selectedList.length; index < selectedListLen; index++) {
                    let selectedData = this.selectedList[index];
                    let selectedDataType = selectedData.type;
                    if (selectedDataType == MindElementType.MAIN_SUBJECT ||
                        selectedDataType == MindElementType.SUBJECT ||
                        selectedDataType == MindElementType.SON_SUBJECT ||
                        selectedDataType == MindElementType.CONTENT_GENERALIZATION) {
                        let textContent = selectedData.textContent
                        if (textContent != null) {
                            if (textContent.bgColor == value) {
                                continue
                            }
                            textContent.bgColor = value;
                            this.addChangeDataStyleId(selectedData);
                        } else if (selectedData.generalizationContent != null) {
                            if (selectedData.generalizationContent.bgColor == value) {
                                continue
                            }
                            selectedData.generalizationContent.bgColor = value;
                            this.addChangeDataStyleId(selectedData);
                        }
                        MindElementCalculation.set(selectedData).caluleTextForData().calcule();
                    }
                }
            } else {
                if (isCardTitle && node.value.isCardContent()) {
                    node.value.titleContent.setTextBgColor(startIndex, endIndex, value);
                } else if (node.value.textContent != null) {
                    if (node.value.textContent.bgColor == value) {
                        return
                    }
                    node.value.textContent.setTextBgColor(startIndex, endIndex, value);
                } else if (node.value.generalizationContent != null) {
                    if (node.value.generalizationContent.bgColor == value) {
                        return
                    }
                    node.value.generalizationContent.setTextBgColor(startIndex, endIndex, value);
                } else if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
                    if (node.value.lineContent.textContent.bgColor == value) {
                        return
                    }
                    node.value.lineContent.textContent.bgColor = value;
                }
                MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
                this.addChangeDataStyleId(node.value);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTextSize(value, isCardTitle = false) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        let startIndex = this.delegate.getSelectionStart(node.value.id);
        let endIndex = this.delegate.getSelectionEnd(node.value.id);
        let copyData = node.value.copy()
        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                        let previewData = selectedData.copy()
                        var textContent = selectedData.textContent
                        if (selectedData.generalizationContent != null) {
                            textContent = selectedData.generalizationContent
                        }
                        if (textContent != null) {
                            if (textContent.textFontSize == value) {
                                continue
                            }
                            textContent.textFontSize = value;
                        }
                        this.addChangeDataStyleId(selectedData);
                        MindElementCalculation.set(selectedData).caluleTextForData().calcule(false);
                        if (this.isRootNode(previewData.id)) {
                            selectedData.x = previewData.x - (selectedData.width - previewData.width) / 2;
                            selectedData.y = previewData.y - (selectedData.height - previewData.height) / 2;
                            this.checkNodeConnectLinePointByChangeSize(previewData, selectedData);
                        }
                        this.dataConnectLinePoint(selectedData, previewData);
                    }
                }
            } else {
                let previewData = node.value.copy()
                if (isCardTitle && node.value.isCardContent()) {
                    node.value.titleContent.setTextFontSize(startIndex, endIndex, value);
                } else if (node.value.textContent != null) {
                    if (node.value.textContent.textFontSize == value) {
                        return
                    }
                    node.value.textContent.setTextFontSize(startIndex, endIndex, value);

                } else if (node.value.generalizationContent != null) {
                    if (node.value.generalizationContent.textFontSize == value) {
                        return
                    }
                    node.value.generalizationContent.setTextFontSize(startIndex, endIndex, value);

                }

                if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
                    if (node.value.lineContent.textContent.textFontSize == value) {
                        return
                    }
                    node.value.lineContent.textContent.textFontSize = value
                }
                this.addChangeDataStyleId(node.value);
                MindElementCalculation.set(node.value).caluleTextForData().calcule(false);

                if (this.isRootNode(previewData.id)) {
                    node.value.x = previewData.x - (node.value.width - previewData.width) / 2;
                    node.value.y = previewData.y - (node.value.height - previewData.height) / 2;
                    this.checkNodeConnectLinePointByChangeSize(previewData, node.value);
                }
                this.dataConnectLinePoint(node.value, previewData);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeTextFontFamily(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }

        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                        let previewData = selectedData.copy()
                        var textContent = selectedData.textContent
                        if (selectedData.generalizationContent != null) {
                            textContent = selectedData.generalizationContent
                        }
                        if (textContent != null) {
                            textContent.fontFamily = value;
                        }
                        this.addChangeDataStyleId(selectedData);
                        MindElementCalculation.set(selectedData).caluleTextForData().calcule(false);
                        if (this.isRootNode(previewData.id)) {
                            selectedData.x = previewData.x - (selectedData.width - previewData.width) / 2;
                            selectedData.y = previewData.y - (selectedData.height - previewData.height) / 2;
                            this.checkNodeConnectLinePointByChangeSize(previewData, selectedData);
                        }
                        this.dataConnectLinePoint(selectedData, previewData);
                    }
                }
            } else {
                let previewData = node.value.copy()
                if (node.value.textContent != null) {
                    if (node.value.textContent.fontFamily == value) {
                        return
                    }
                    node.value.textContent.fontFamily = value;

                } else if (node.value.generalizationContent != null) {
                    if (node.value.generalizationContent.fontFamily == value) {
                        return
                    }
                    node.value.generalizationContent.fontFamily = value;

                }

                if (node.value.lineContent != null && node.value.lineContent.textContent != null) {
                    if (node.value.lineContent.textContent.fontFamily == value) {
                        return
                    }
                    node.value.lineContent.textContent.fontFamily = value
                }
                this.addChangeDataStyleId(node.value);
                MindElementCalculation.set(node.value).caluleTextForData().calcule(false);

                if (this.isRootNode(previewData.id)) {
                    node.value.x = previewData.x - (node.value.width - previewData.width) / 2;
                    node.value.y = previewData.y - (node.value.height - previewData.height) / 2;
                    this.checkNodeConnectLinePointByChangeSize(previewData, node.value);
                }
                this.dataConnectLinePoint(node.value, previewData);
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeSubjectTextSize(value) {
        this.mainMindNodeUnit.changeSubjectTextSize(value);
        this.doubleBubbleMindNodeUnit.changeSubjectTextSize(value);
        this.timeMindTypeNodeUnit.changeSubjectTextSize(value);
        let len = this.freeNodes.length;
        for (let index = 0; index < len; index++) {
            let unit = this.freeNodes[index]
            unit.changeSubjectTextSize(value);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeSonSubjectTextSize(value) {
        this.mainMindNodeUnit.changeSonSubjectTextSize(value);
        this.doubleBubbleMindNodeUnit.changeSonSubjectTextSize(value);
        this.timeMindTypeNodeUnit.changeSonSubjectTextSize(value);
        let len = this.freeNodes.length;
        for (let index = 0; index < len; index++) {
            let unit = this.freeNodes[index]
            unit.changeSonSubjectTextSize(value);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeBorderColor(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (value != null) {

            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        if (textContent.borderColor != null) {
                            textContent.borderColor = value;
                            if (textContent.borderWidth == 0) {
                                let lines = this.getNodeLine(textContent.id)
                                if (lines.length > 0 && lines[0].lineContent != null) {
                                    textContent.borderWidth = lines[0].lineContent.lineWidth
                                } else {
                                    textContent.borderColor = 2
                                }
                            }
                            if (textContent.isCardContent()) {
                                textContent.checkSetTextColor(this.mindBGColor)
                            }
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (node.value.borderColor != null) {
                    node.value.borderColor = value;
                    if (node.value.borderWidth == 0) {
                        let lines = this.getNodeLine(node.value.id)
                        if (lines.length > 0 && lines[0].lineContent != null) {
                            node.value.borderWidth = lines[0].lineContent.lineWidth
                        } else {
                            node.value.borderColor = 2
                        }
                    }
                    if (node.value.isCardContent()) {
                        node.value.checkSetTextColor(this.mindBGColor)
                    }
                    this.addChangeDataStyleId(node.value);
                }
            }

            setTimeout(() => {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }, 100);
        }
    }

    changeFillColorColor(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0, selectedListLen = this.selectedList.length; index < selectedListLen; index++) {
                    let selectedData = this.selectedList[index];
                    let selectedDataType = selectedData.type;
                    if (selectedDataType == MindElementType.MAIN_SUBJECT ||
                        selectedDataType == MindElementType.SUBJECT ||
                        selectedDataType == MindElementType.SON_SUBJECT ||
                        selectedDataType == MindElementType.CONTENT_GENERALIZATION) {
                        let textContent = selectedData;
                        if (textContent.backgroundColor != null) {
                            textContent.backgroundColor = value;
                            textContent.checkSetTextColor(this.mindBGColor)  
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (node.value.backgroundColor != null) {
                    node.value.backgroundColor = value;
                    node.value.checkSetTextColor(this.mindBGColor)
                    this.addChangeDataStyleId(node.value);
                }
            }

            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeFillColorColorAlpha(value) {
        if (value == null || (typeof value) != "number" || value < 0 || value > 1.0) {
            return
        }
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0, selectedListLen = this.selectedList.length; index < selectedListLen; index++) {
                    let selectedData = this.selectedList[index];
                    let selectedDataType = selectedData.type;
                    if (selectedDataType == MindElementType.MAIN_SUBJECT ||
                        selectedDataType == MindElementType.SUBJECT ||
                        selectedDataType == MindElementType.SON_SUBJECT ||
                        selectedDataType == MindElementType.CONTENT_GENERALIZATION ||
                        selectedDataType == MindElementType.BAOWEI_VIEW) {
                        let textContent = selectedData;
                        if (textContent.backgroundColorAlpha != null) {
                            textContent.backgroundColorAlpha = value;
                            textContent.checkSetTextColor(this.mindBGColor)                           
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (node.value.backgroundColorAlpha != null) {
                    node.value.backgroundColorAlpha = value;
                    node.value.checkSetTextColor(this.mindBGColor)
                    this.addChangeDataStyleId(node.value);
                }
            }

            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeBorderlLine(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        if (value != null && node.value.borderWidth != value) {

            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        if (textContent.borderWidth != null) {
                            textContent.borderWidth = value;
                            if (Colors.isClear(textContent.borderColor)) {
                                let lines = this.getNodeLine(textContent.id)
                                if (lines.length > 0 && lines[0].lineContent != null) {
                                    textContent.borderColor = lines[0].lineContent.color
                                } else if (Colors.isDarkColor(this.mindBGColor)) {
                                    textContent.borderColor = Colors.white
                                } else {
                                    textContent.borderColor = Colors.black80
                                }
                            }
                            if (textContent.isCardContent()) {
                                textContent.checkSetTextColor(this.mindBGColor)
                            }
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (node.value.borderWidth != null) {
                    node.value.borderWidth = value;
                    if (Colors.isClear(node.value.borderColor)) {
                        let lines = this.getNodeLine(node.value.id)
                        if (lines.length > 0 && lines[0].lineContent != null) {
                            node.value.borderColor = lines[0].lineContent.color
                        } else if (Colors.isDarkColor(this.mindBGColor)) {
                            node.value.borderColor = Colors.white
                        } else {
                            node.value.borderColor = Colors.black80
                        }
                        if (node.value.isCardContent()) {
                            node.value.checkSetTextColor(this.mindBGColor)
                        }
                    }
                    this.addChangeDataStyleId(node.value);
                }
            }

            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeNodeType(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty()) {
            return;
        }
        let previewData = node.value.copy()
        if (value != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        if (value != undefined && textContent.mindElementShape != value) {
                            let previewSelectedData = selectedData.copy()
                            if ((value == MindElementShapeType.Underline || value == MindElementShapeType.Two_Underline) &&
                                textContent.mindElementShape != MindElementShapeType.Underline && 
                                textContent.mindElementShape != MindElementShapeType.Two_Underline) {
                                let lines = this.getNodeLine(textContent.id)
                                if (lines.length > 0 && lines[0].lineContent != null) {
                                    if (Colors.isClear(textContent.borderColor)) {
                                        textContent.borderColor = lines[0].lineContent.color
                                    }
                                    if (textContent.borderWidth < lines[0].lineContent.lineWidth) {
                                        textContent.borderWidth = lines[0].lineContent.lineWidth
                                    }
                                }
                            } else if (value == MindElementShapeType.CornerShadow && textContent.mindElementShape != MindElementShapeType.CornerShadow) {
                                if (Colors.isClear(textContent.borderColor)) {
                                    if (Colors.isClear(textContent.backgroundColor)) {
                                        if (textContent.textContent != null) {
                                            textContent.borderColor = textContent.textContent.textColor
                                        } else if (textContent.generalizationContent != null) {
                                            textContent.borderColor = textContent.generalizationContent.textColor
                                        }
                                    } else {
                                        textContent.borderColor = textContent.backgroundColor
                                    }
                                }
                                if (textContent.borderWidth <= 0) {
                                    textContent.borderWidth = 2
                                }
                            }
                            textContent.mindElementShape = value;
                            if (value == MindElementShapeType.Card) {
                                this.setNodeToCard(selectedData.id, false)
                            }
                            MindElementCalculation.set(selectedData).calcule(false)
                            if (this.isRootNode(selectedData.id)) {
                                selectedData.x = previewSelectedData.x - (selectedData.width - previewSelectedData.width) / 2;
                                selectedData.y = previewSelectedData.y - (selectedData.height - previewSelectedData.height) / 2;
                                this.checkNodeConnectLinePointByChangeSize(previewSelectedData, selectedData);
                            }
                            this.dataConnectLinePoint(selectedData, previewSelectedData);
                            this.addChangeDataStyleId(selectedData);
                        }
                    }
                }
            } else {
                if (value != undefined && node.value.mindElementShape != value) {
                    if ((value == MindElementShapeType.Underline || value == MindElementShapeType.Two_Underline) &&
                        node.value.mindElementShape != MindElementShapeType.Underline && node.value.mindElementShape != MindElementShapeType.Two_Underline) {
                        let lines = this.getNodeLine(node.value.id)
                        if (lines.length > 0 && lines[0].lineContent != null) {
                            if (Colors.isClear(node.value.borderColor)) {
                                node.value.borderColor = lines[0].lineContent.color
                            }
                            if (node.value.borderWidth < lines[0].lineContent.lineWidth) {
                                node.value.borderWidth = lines[0].lineContent.lineWidth
                            }
                        }
                    } else if (value == MindElementShapeType.CornerShadow && node.value.mindElementShape != MindElementShapeType.CornerShadow) {
                        if (Colors.isClear(node.value.borderColor)) {
                            if (Colors.isClear(node.value.backgroundColor)) {
                                if (node.value.textContent != null) {
                                    node.value.borderColor = node.value.textContent.textColor
                                } else if (node.value.generalizationContent != null) {
                                    node.value.borderColor = node.value.generalizationContent.textColor
                                }
                            } else {
                                node.value.borderColor = node.value.backgroundColor
                            }
                        }
                        if (node.value.borderWidth <= 0) {
                            node.value.borderWidth = 2
                        }
                    }
                    node.value.mindElementShape = value;
                    if (value == MindElementShapeType.Card) {
                        this.setNodeToCard(node.value.id, false)
                    }
                    if (this.isFlowChart() && node.value.textContent != null &&
                         CheckTextIsSelected.isSelected(node.value.textContent.text)) {
                        let text = CheckTextIsSelected.getTextByShape(node.value.mindElementShape)
                        if (text != "") {
                            node.value.textContent.text = text
                        } else {
                            node.value.textContent.text = new UiUtil().getString(StringsLanguage.Mind_Shape_Text)
                        }
                        MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
                    } else {
                        MindElementCalculation.set(node.value).calcule(false)
                    }
                    if (this.isRootNode(previewData.id)) {
                        node.value.x = previewData.x - (node.value.width - previewData.width) / 2;
                        node.value.y = previewData.y - (node.value.height - previewData.height) / 2;
                        this.checkNodeConnectLinePointByChangeSize(previewData, node.value);
                    }
                    this.dataConnectLinePoint(node.value, previewData);
                    this.addChangeDataStyleId(node.value);
                }
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeEncircleLineType(value) {  //改变包裹线类型
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.type != MindElementType.BAOWEI_VIEW) {
            return
        }
        if (value != null && value != '') {
            node.value.lineContent.encircleShapeType = value
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeEncircleLineTitleText(value) {  //改变包裹线文字颜色
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.type != MindElementType.BAOWEI_VIEW) {
            return
        }
        if (node.value.lineContent.textContent == undefined) {
            return
        }
        if (value != null && value != '') {
            node.value.lineContent.textContent.textColor = value;

            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeEncircleLinePadding(value) {  //改变包裹线边距
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.type != MindElementType.BAOWEI_VIEW) {
            return
        }
        if (value != null && value != '') {
            node.value.lineContent.padding = value
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeEncircleLineTextSize(value) { //改变包裹线标题字体大小
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.type != MindElementType.BAOWEI_VIEW) {
            return
        }
        if (node.value.lineContent.textContent == undefined) {
            return
        }
        if (value != null && value != '') {
            node.value.lineContent.textContent.textFontSize = value
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeIconSize(value) {
        let node = this.getSelectedNode();
        if (value != null) {
            let isChange
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                        if (selectedData.iconElementContents == null || selectedData.iconElementContents.length == 0) {
                            continue;
                        }
                        isChange = false;
                        let preData = selectedData.copy()
                        selectedData.iconElementContents.forEach(cell => {
                            if (cell.width != value || cell.height != value) {
                                isChange = true;
                            }
                            cell.width = value;
                            cell.height = value;
                        });
                        MindElementCalculation.set(selectedData).calcule(false);
                        if (this.isRootNode(selectedData.id)) {
                            selectedData.x = preData.x - (selectedData.width - preData.width) / 2
                            selectedData.y = preData.y - (selectedData.height - preData.height) / 2
                            this.checkNodeConnectLinePointByChangeSize(preData, selectedData)
                        }
                        this.dataConnectLinePoint(selectedData, preData);
                    }
                }
            } else {
                let preData = node.value.copy()
                isChange = false;
                if (!node.isEmpty() && node.value.iconElementContents != null && node.value.iconElementContents.length > 0) {
                    node.value.iconElementContents.forEach(cell => {
                        if (cell.width != value || cell.height != value) {
                            isChange = true;
                        }
                        cell.width = value;
                        cell.height = value;
                    });
                }
                MindElementCalculation.set(node.value).calcule(false);
                if (this.isRootNode(node.value.id)) {
                    node.value.x = preData.x - (node.value.width - preData.width) / 2
                    node.value.y = preData.y - (node.value.height - preData.height) / 2
                    this.checkNodeConnectLinePointByChangeSize(preData, node.value)
                }
                this.dataConnectLinePoint(node.value, preData);
            }
            if (isChange) {
                this.refresh(true);
                this.willSave(true);
                this.addHostry();
            }
        }
    }

    changeImageSize(width, height) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.imageContent == null) {
            return;
        }
        if (width != null && height != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        if (textContent.imageContent != null) {
                            let preData = selectedData.copy();
                            let sc = textContent.imageContent.width / textContent.imageContent.height
                            textContent.imageContent.width = width;
                            textContent.imageContent.height = width / sc;

                            MindElementCalculation.set(selectedData).calcule(false);
                            if (this.isRootNode(selectedData.id)) {
                                selectedData.x = preData.x - (selectedData.width - preData.width) / 2
                                selectedData.y = preData.y - (selectedData.height - preData.height) / 2
                                this.checkNodeConnectLinePointByChangeSize(preData, selectedData)
                            }
                            this.dataConnectLinePoint(selectedData, preData);
                        }
                    }
                }
            } else {
                if (node.value.imageContent.width != width || node.value.imageContent.height != height) {
                    let preData = node.value.copy()                    
                    node.value.imageContent.width = width;
                    node.value.imageContent.height = height;
                    MindElementCalculation.set(node.value).calcule(false);
                    if (this.isRootNode(node.value.id)) {
                        node.value.x = preData.x - (node.value.width - preData.width) / 2
                        node.value.y = preData.y - (node.value.height - preData.height) / 2
                        this.checkNodeConnectLinePointByChangeSize(preData, node.value)
                    }
                    this.dataConnectLinePoint(node.value, preData);
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }

    }

    changeImageLayout(type) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.imageContent == null) {
            return;
        }
        if (type != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {
                        let textContent = selectedData
                        if (textContent.imageContent.layoutType != type) {
                            textContent.imageContent.layoutType = type;
                        }
                    }
                }
            } else {
                if (node.value.imageContent.layoutType != type) {
                    node.value.imageContent.layoutType = type;
                }
            }
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeImageShape(type) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.imageContent == null) {
            return;
        }
        if (type != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        if (textContent.imageContent.shapeType != type) {
                            textContent.imageContent.shapeType = type;
                        }
                    }
                }
            } else {
                if (node.value.imageContent.shapeType != type) {
                    node.value.imageContent.shapeType = type;
                }
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeImageUrl(url) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.imageContent == null) {
            return;
        }
        if (node.value.imageContent != url) {
            node.value.imageContent = url;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    changeIconLayout(type) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.iconElementContents == null || !node.value.iconElementContents) {
            return;
        }
        if (type != null) {
            if (!this.selectedList.isEmpty()) {
                for (let index = 0; index < this.selectedList.length; index++) {
                    let selectedData = this.selectedList[index]
                    if (selectedData.type == MindElementType.MAIN_SUBJECT ||
                        selectedData.type == MindElementType.SUBJECT ||
                        selectedData.type == MindElementType.SON_SUBJECT ||
                        selectedData.type == MindElementType.CONTENT_GENERALIZATION) {

                        let textContent = selectedData
                        textContent.iconElementContents.forEach(cell => {
                            cell.layoutType = type;
                        });
                    }
                }
            } else {
                node.value.iconElementContents.forEach(cell => {
                    cell.layoutType = type;
                });
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    isRootNode(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || (node.value.type != MindElementType.MAIN_SUBJECT && node.value.type != MindElementType.SUBJECT)) {
            return false;
        }
        let result = false;
        result = result || this.mainMindNodeUnit.isRootNode(node.value.id);
        this.freeNodes.forEach(unit => {
            result = result || unit.isRootNode(node.value.id);
        })
        result = result || this.timeMindTypeNodeUnit.isRootNode(node.value.id);
        result = result || this.doubleBubbleMindNodeUnit.isRootNode(node.value.id);
        return result;
    }

    isTimeNode(id) {  
        let node = this.timeMindTypeNodeUnit.getNodeById(id)      
        return !node.isEmpty()
    }

    viewWillAppear() {
        this.viewStatus = ViewStatus.WillAppear;

        let changeStyleColorFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_COLOR);
        changeStyleColorFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleColorFilter);

        let changeStyleLineFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_LINE_WIDTH);
        changeStyleLineFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleLineFilter);

        let changeStyleLineLengthFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_LINE_LENGTH);
        changeStyleLineLengthFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleLineLengthFilter);

        let changeStyleTextSizeFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_SIZE);
        changeStyleLineFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleTextSizeFilter);

        let changeStyleTextBorderWidthFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BORDER_WIDTH);
        changeStyleTextBorderWidthFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleTextBorderWidthFilter);

        let changeTextShadowFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_TEXT_SHADOW_THICKEN);
        changeTextShadowFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeTextShadowFilter);

        let changeLineThickenFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_LINE_THICKEN);
        changeLineThickenFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeLineThickenFilter);

        let changeStyleTextBoldFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_BOLD);
        changeStyleLineFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleTextBoldFilter);

        let changeStyleTextItalicsFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ITALICS);
        changeStyleLineFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleTextItalicsFilter);

        let changeStyleTextStrikethroughFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_STRIKETHROUGH);
        changeStyleLineFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleTextStrikethroughFilter);

        let changeStyleShapeFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_SHAPE);
        changeStyleShapeFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleShapeFilter);

        let changeLineDottedStyleFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_LINE_DOTTED);
        changeLineDottedStyleFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeLineDottedStyleFilter);

        let addIconFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_ADD_ICON);
        addIconFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, addIconFilter);

        let sizeIconStyleFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_ICON_STYLE);
        sizeIconStyleFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, sizeIconStyleFilter);

        let sizeImageStyleFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_IMAGE_STYLE);
        sizeImageStyleFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, sizeImageStyleFilter);

        let remarksFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_REMARKS);
        remarksFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, remarksFilter);

        let linkFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_LINK);
        linkFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, linkFilter);

        let rootFontSizeFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_ROOT_FONT_SIZE);
        rootFontSizeFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, rootFontSizeFilter);

        let subjectFontSizeFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_SUBJECT_FONT_SIZE);
        subjectFontSizeFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, subjectFontSizeFilter);

        let sonSubjectFontSizeFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_GLOBAL_SON_SUBJECT_FONT_SIZE);
        sonSubjectFontSizeFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, sonSubjectFontSizeFilter);

        let changeNodeBackgrounFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_TEXT_NODE_BACKGROUN_IMAGE);
        changeNodeBackgrounFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeNodeBackgrounFilter);

        let changeStyleTextAlignmentLeftFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_TEXT_ALIGNMENT);
        changeStyleTextAlignmentLeftFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeStyleTextAlignmentLeftFilter);

        let timeNodeTypeFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_TIME_NODE_TYPE_KEY);
        timeNodeTypeFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, timeNodeTypeFilter);

        let timeArrowFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_LINE_ARROW);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, timeArrowFilter);

        let freenodeAdsorbentFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_FREENODE_ADSORBENT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, freenodeAdsorbentFilter);

        let changeNodeConnectLineShapeFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_SHAPE);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeNodeConnectLineShapeFilter);

        let changeNodeConnectLineStartShapeFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_START_POINT_SHAPE);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeNodeConnectLineStartShapeFilter);

        let changeNodeConnectLineEndShapeFilter = new IntentFilter(Definition.ACTION_RECEIVE_CHANGE_NODE_CONNECT_LINE_END_POINT_SHAPE);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, changeNodeConnectLineEndShapeFilter);

        let encircleTitleLayoutFilter = new IntentFilter(Definition.ACTION_RECEIVE_STYLE_CHANGE_ENCIRCLE_TITLE_LAYOUT);
        // encircleTitleLayoutFilter.addCategory(Intent.CATEGORY_DEFAULT);
        AppBroadcastManager.getInstance().registerReceiver(receiveBroadcast, encircleTitleLayoutFilter);
    }

    viewWillDisappear() {
        this.viewStatus = ViewStatus.WillDisappear;
        AppBroadcastManager.getInstance().unregisterReceiver(receiveBroadcast);
    }

    saveMindMapTask() {
        this.executeSave();
    };

    willSave(isDelay) {
        if (this.delegate != null && !this.delegate.isEditStatus()) {
            return
        }
        if (arguments.length == 0) {
            isDelay = false;
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        this.updateLastChangeTime();
        clearTimeout(this.saveTimeout);
        this.saveTimeout = -1;
        if (isDelay) {
            let delaySaveTime = this.delegate.$router.history.current.name == 'gmap' ? 2000 : (this.isElectron ? 5000 : 3000)
            this.delegate.saveEditAccout('alreadyEdit')
            this.saveTimeout = setTimeout(() => {
                if (!LoadLocalXJMind.isLoaclMind()) {
                    this.temporaryStorage(); 
                }
                this.saveTimeout = -1;
                this.executeSave();
            }, delaySaveTime);
        } else {
            if (!LoadLocalXJMind.isLoaclMind()) {
                this.temporaryStorage(); 
            }
            this.executeSave();
        }
        if (this.lastCheckNodes == null || Util.getCurrentTime() - this.lastCheckNodes > 10000) {
            this.checkStyleTitleShow();
            this.checkLoginAndMemberFloatPopup();
            this.checkLoginAndMember();
            this.lastCheckNodes = Util.getCurrentTime()
        }
    }

    executeSave() {
        if (this.loadDataModel != null) {
            return;
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        this.delegate.spatialCuesFile();
        let delaySaveTime = this.delegate.$router.history.current.name == 'gmap' ? 2000 : 10000
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let content = this.getCurrentMindContent();
            if (content == null || content.length == 0) {
                return
            }
            this.mindNodeJson = content;
            if (this.mindNodeJson != null && this.mindNodeJson.length > 50) {
                this.delegate.saveEditAccout('alreadyEdit')
                if (this.coverMindTimeout) {
                    clearTimeout(this.coverMindTimeout)
                }
                groupMindSyncAndMerge.get().stopCurrentMerge()
                this.coverMindTimeout = setTimeout(() => {
                    this.coverMindTimeout = -1
                    this.coverMindData()
                }, delaySaveTime);
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let content = this.getCurrentMindContent();
            if (content == null || content.length == 0) {
                return
            }
            this.mindNodeJson = content;
            if (this.mindNodeJson != null && this.mindNodeJson.length > 50) {

                this.delegate.saveEditAccout('alreadyEdit')
                if (this.coverMindTimeout) {
                    clearTimeout(this.coverMindTimeout)
                }
                this.coverMindTimeout = setTimeout(() => {
                    this.coverMindTimeout = -1
                    this.coverMindData()
                }, delaySaveTime);
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            let content = this.getCurrentMindContent();
            if (content == null || content.length == 0) {
                return
            }
            this.mindNodeJson = content;
            if (this.mindNodeJson != null && this.mindNodeJson.length > 50) {
                this.delegate.saveEditAccout('alreadyEdit')
                if (this.coverMindTimeout) {
                    clearTimeout(this.coverMindTimeout)
                }
                this.coverMindTimeout = setTimeout(() => {
                    this.coverMindTimeout = -1
                    this.coverMindData()
                }, delaySaveTime);
            }
        }
        this.savePreviewImage(true);
    }

    temporaryStorage() {
        this.mindSheetId = this.delegate.$route.query.sheetId;
        this.mindRootId = this.delegate.$route.query.id;
        
        if ((this.mindSheetId == null || this.mindSheetId.length == 0) &&
            (this.mindRootId == null || this.mindRootId.length == 0)) {
            return
        }
        let id = this.mindSheetId
        if (id == null || id.length == 0) {
            id = this.mindRootId
        }

        let fileName = id;

        SaveMindMapToLocal.reset();
        var name = this.getTitle()
        let currentData = new MindContentData();
        currentData.name = name;
        currentData.id = id;
        currentData.content = this.getCurrentMindContent();
        SaveMindMapToLocal.setTemporaryStorageName(fileName)
        let isGroupMind = this.delegate.$router.history.current.name == 'gmap'
        SaveMindMapToLocal.name = name
        SaveMindMapToLocal.setContents(this.getCurrentMindContent(), this.mindType, id, id, false, isGroupMind);
        let that = this;
        let lastSaveTime = Util.getCurrentTime()
        SaveMindMapToLocal.temporarySave(this.delegate).then((success) => {
            console.log("save local-", success);
            if (success) {
                that.lastLocalSaveTime = lastSaveTime;
            } else {
            }
        })
    }

    saveMindMapToLocal(isSaveAs = false, temporaryStorage = false) {
        if (SaveMindMapToLocal.isSaveingDataToLocal) {// || !isClickSaveMapButton.value
            return;
        }
        let isGroupMind = this.delegate.$router.history.current.name == 'gmap'
        if (isGroupMind) {
            this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Edit_Left_Menu_Export_Group_Not_Supporte_Save_As));
            return
        }
        SaveMindMapToLocal.reset();
        var name = LoadLocalXJMind.getName()
        if (name == null || name.length == 0) {
            name = this.getTitle();
        }
        let currentData = new MindContentData(); 
        this.mindSheetId = this.delegate.$route.query.sheetId;
        this.mindRootId = this.delegate.$route.query.id;
        if (this.mindRootId == null) {
            this.mindRootId = LoadLocalXJMind.getRootId()
            this.mindSheetId = LoadLocalXJMind.getSheetId();
        }
        currentData.name = name;
        currentData.id = this.mindSheetId;
        currentData.content = this.getCurrentMindContent();
        SaveMindMapToLocal.setNameAndPath(name, LoadLocalXJMind.getPath())
        
        SaveMindMapToLocal.setContents(this.getCurrentMindContent(), this.mindType, this.mindRootId, this.mindSheetId, isSaveAs, isGroupMind);
        let that = this;
        let lastSaveTime = Util.getCurrentTime()
        SaveMindMapToLocal.save(this.delegate).then((success) => {
            console.log("save local-", success);
            if (success) {
                that.lastLocalSaveTime = lastSaveTime;
                that.saveSuccess();
            } else {
                that.delegate.saveEditAccout('saveError')                
                if (that.delegate.showSwitchSheet) {
                    that.delegate.saveSheetSwitchAccout('error')
                }
                that.delegate.setFinishSavingMap({ fulfill: true, result: false });
            }
        })
    }

    getCurrentMindContent() {
        if (this.loadDataModel != null) {
            return "";
        }
        var content = "";
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let mindDateFormat = new MindDateFormat();
            mindDateFormat.mindBGColor = this.mindBGColor;
            mindDateFormat.settingData = this.settingData;
            mindDateFormat.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            mindDateFormat.version = Config.MindFormatVersion;
            mindDateFormat.mindGlobalAudio = this.mindGlobalAudio;
            mindDateFormat.mindkey = this.mindkey;
            mindDateFormat.mindBGStripesType = this.mindBGStripesType;
            mindDateFormat.mindDisplayType = this.mindDisplayType;
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();

                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList)
                    .setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            }
            mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            mindDateFormat = mindDateFormat.setDatas(this.mainMindNodeUnit.rootTreeNode,
                this.mainMindNodeUnit.lineMindElementDataDict.values(),
                this.mainMindNodeUnit.generalizationLineMindElementDataDict.values(),
                this.mainMindNodeUnit.generalizationMindElementDataDict.values(),
                freeUnitNodes,
                this.mainMindNodeUnit.encircleMindElementDataDict.values(),
                this.mainMindNodeUnit.explainMindElementDataDict.values()).setLayout(this.globalLayout, this.globalLineLayout);

            content = mindDateFormat.toJSONString();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let mindDateFormat = new DoubleBubbleMindDateFormat();
            mindDateFormat.mindBGColor = this.mindBGColor;
            mindDateFormat.settingData = this.settingData;
            mindDateFormat.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            mindDateFormat.version = Config.MindFormatVersion;
            mindDateFormat.mindkey = this.mindkey;
            mindDateFormat.mindGlobalAudio = this.mindGlobalAudio;
            mindDateFormat.mindBGStripesType = this.mindBGStripesType;
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();
                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList).setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            };
            mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            mindDateFormat = mindDateFormat.setDatas(this.doubleBubbleMindNodeUnit.rootTreeNode,
                this.doubleBubbleMindNodeUnit.lineMindElementDataDict.values(),
                freeUnitNodes,
                this.doubleBubbleMindNodeUnit.rootTreeBrotherNode,
                this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas)
                .setLayout(this.globalLayout, this.globalLineLayout);

            content = mindDateFormat.toJSONString();
        } else if (this.mindType == MindType.TIME_MAP) {
            let format = new TimeLineMindDateFormat();
            format.mindBGColor = this.mindBGColor;
            format.settingData = this.settingData;
            format.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            format.version = Config.MindFormatVersion;
            format.mindkey = this.mindkey;
            format.mindGlobalAudio = this.mindGlobalAudio;
            format.mindBGStripesType = this.mindBGStripesType;
            format.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();
                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList)
                    .setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            };

            format = format.setDatas(this.timeMindTypeNodeUnit.title,
                this.timeMindTypeNodeUnit.baseLine,
                this.timeMindTypeNodeUnit.mainMindElementDataDict,
                freeUnitNodes, this.timeMindTypeNodeUnit.globalLayout);
            content = format.toJSONString();
        }
        return content;
    }

    updateMindmapResources(id, path, url) {
        let node = this.getNodeById(id)
        if (node.isEmpty()) {
            return
        }
        let data = node.value;
        if (data.imageContent != null &&
            data.imageContent.key != null && 
            data.imageContent.key.length > 0 &&
            data.imageContent.key == path) {
            data.imageContent.key = url
        }
        if (data.imageContent != null &&
            data.imageContent.videoUrl != null && 
            data.imageContent.videoUrl.length > 0 &&
            data.imageContent.videoUrl == path) {
            data.imageContent.videoUrl = url
        }
        if (data.resourceContent != null &&
            data.resourceContent.url != null && 
            data.resourceContent.url.length > 0 &&
            data.resourceContent.url == path) {
            data.resourceContent.url = url
        }
    }

    checkLoginAndMember() {
        const meMessage = MindMe.getMeMessage();
        if (meMessage && meMessage.isMember) {
            return true;
        }

        let mindNodeConutCalculation = new MindNodeConutCalculation();
        mindNodeConutCalculation.setData(
            this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit
        );
        let obj = this.delegate.generalConfigurationParameters;
        var nodesLimitNumber;
        if (obj == false || obj == null || obj == "") {
            nodesLimitNumber = Config.USER_NODE_LIMIT_Operation_CONUT;
            return true;
        } else {
            if (obj.maxNodesLimit <= 0) {
                nodesLimitNumber = Config.USER_NODE_LIMIT_Operation_CONUT;
                return true;
            } else {
                nodesLimitNumber = obj.maxNodesLimit;
            }
        }
        let nodeConut = mindNodeConutCalculation.getConut();

        let nowTime = (Util).getCurrentTime();
        if (MindMe.isLogin()) { // 已登录 弹付费窗 2分钟弹出一次
            if (nodeConut > nodesLimitNumber) {
                this.delegate.titleNodeLimit(nodesLimitNumber)
            }
        } else if (nodeConut > Config.GUEST_NODE_LIMIT_CONUT) {// 未登录 弹注册 1分钟弹出一次
            let showLoginTime = localStorage.getItem('showLoginTime');
            if (showLoginTime) {
                let time = nowTime - showLoginTime;
                if (time > Config.STORAGE_LIMIT_POPUP_WINDOW_TIME) {
                    store.state.showLogin = true;
                    localStorage.setItem('showLoginTime', nowTime);
                }
            } else {
                store.state.showLogin = true;
                localStorage.setItem('showLoginTime', nowTime);
            }
        }
        return true;
    }

    checkLoginAndMemberFloatPopup() {
        const meMessage = MindMe.getMeMessage();;
        if (meMessage && meMessage.isMember) {
            return true;
        }

        let mindNodeConutCalculation = new MindNodeConutCalculation();
        mindNodeConutCalculation.setData(
            this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit
        );
        let obj = this.delegate.generalConfigurationParameters;
        var notifyNodeLimit;
        if (obj == false || obj == null || obj == "") {
            return true;
        } else {
            if (obj.notifyNodeLimit <= 0) {
                return true;
            } else {
                notifyNodeLimit = obj.notifyNodeLimit;
            }
        }
        let nodeConut = mindNodeConutCalculation.getConut();

        let nowTime = (Util).getCurrentTime();
        if (MindMe.isLogin()) { // 已登录
            let showFloatTime = localStorage.getItem('showFloatTime');

            if (showFloatTime) {
                let time = nowTime - showFloatTime;
                if (nodeConut > notifyNodeLimit) {
                    if (time >= Config.REGISTER_POPUP_WINDOW_FLOAT_TIME) {
                        this.delegate.visionTitleFloatPopup();
                        localStorage.setItem('showFloatTime', nowTime);
                    }
                } else {
                    return false;
                }
            } else {

                if (nodeConut > notifyNodeLimit) {
                    this.delegate.visionTitleFloatPopup();
                }
                localStorage.setItem('showFloatTime', nowTime);
            }
        } else if (nodeConut > Config.GUEST_NODE_LIMIT_CONUT) {// 未登录 弹注册 1分钟弹出一次
            let showLoginTime = localStorage.getItem('showLoginTime');
            if (showLoginTime) {
                let time = nowTime - showLoginTime;
                if (time > Config.STORAGE_LIMIT_POPUP_WINDOW_TIME) {
                    store.state.showLogin = true;
                    localStorage.setItem('showLoginTime', nowTime);
                }
            } else {
                store.state.showLogin = true;
                localStorage.setItem('showLoginTime', nowTime);
            }
        }
        return true;
    }

    checkStyleTitleShow() {

        let mindNodeConutCalculation = new MindNodeConutCalculation();
        mindNodeConutCalculation.setData(
            this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit
        );
        let nodeConut = mindNodeConutCalculation.getConut();

        let nowTime = (Util).getCurrentTime();
        let showStyleTitleTime = localStorage.getItem('showStyleTitleTime');

        if (showStyleTitleTime == null || showStyleTitleTime == "") {
            if (nodeConut > Config.STYLE_PROMPT_BOX_NODE_LIMT) {
                store.state.showStyleTitleModel = true
                localStorage.setItem('showStyleTitleTime', nowTime);
            }
        }
    }

    cloudStorage() {
        if (!LoadLocalXJMind.isLoaclMind() || LoadLocalXJMind.isCloudStorage()) {
            this.saveDataForClosePage()
        } else {
            SaveMindMapToLocal.reset();
            var name = LoadLocalXJMind.getName()
            if (name == null || name.length == 0) {
                name = this.getTitle();
            }
            let currentData = new MindContentData(); 
            this.mindSheetId = this.delegate.$route.query.sheetId;
            this.mindRootId = this.delegate.$route.query.id;
            if (this.mindRootId == null) {
                this.mindRootId = LoadLocalXJMind.getRootId()
                this.mindSheetId = LoadLocalXJMind.getSheetId();
            }
            currentData.name = name;
            currentData.id = this.mindSheetId;
            currentData.content = this.getCurrentMindContent();

            SaveMindMapToLocal.setNameAndPath(name, LoadLocalXJMind.getPath())
            let isGroupMind = this.delegate.$router.history.current.name == 'gmap'
            SaveMindMapToLocal.setContents(this.getCurrentMindContent(), this.mindType, this.mindRootId, this.mindSheetId, false, isGroupMind);
            let that = this;
            SaveMindMapToLocal.uploadMindmap(this.delegate).then((success) => {
                if (success) {
                    that.delegate.saveEditAccout('saveSuccess')
                } else {
                    that.delegate.saveEditAccout('saveError')
                }
            })
        }
    }

    saveContentByShortcutKeys() {
        if (LoadLocalXJMind.isLoaclNotSaved()) {
            if (LoadLocalXJMind.isCloudStorage()) {
                this.saveDataForClosePage()
            } else {
                this.saveMindMapToLocal(true)
            }
        } else {
            this.saveDataForClosePage()
        }
    }

    saveDataForClosePage() {
        if (!LoadLocalXJMind.isCloudStorage() && LoadLocalXJMind.isLoaclNotSaved()) {
            isClickSaveMapButton.value = true
            this.saveMindMapToLocal();
            return
        }
                        
        this.mindSheetId = this.delegate.$route.query.sheetId;
        this.mindRootId = this.delegate.$route.query.id;

        if (this.saveTimeout != null && this.saveTimeout != -1) {
            clearTimeout(this.saveTimeout);
            this.saveTimeout = -1;
            this.executeSave();
        }
        if (this.coverMindTimeout == null ||
            this.coverMindTimeout == -1 ||
            this.mindNodeJson == null ||
            this.mindNodeJson.length < 50) {
            clearTimeout(this.coverMindTimeout);
            this.coverMindTimeout = -1;
            //通过这个接口检测网络是否可用
            postBaseGlobalConfig({}, (res) => {
                if (this.delegate.showSwitchSheet) {
                    this.delegate.saveSheetSwitchAccout('saveSuccess')
                }
            }, (err) => {
                if (this.delegate.showSwitchSheet) {
                    this.delegate.saveSheetSwitchAccout('error')
                }
            })
            this.delegate.setFinishSavingMap({ fulfill: true, result: true });
            if (isOffline.value) {
                this.coverMindData();
                isOffline.value = false;
                return;
            }
            if (isClickSaveMapButton.value) {
                this.delegate.$message.success(new UiUtil().getString(StringsLanguage.Header_Mind_Tips_Saved));
                isClickSaveMapButton.value = false;
            }
            return;
        } else {
            clearTimeout(this.coverMindTimeout);
            this.coverMindTimeout = -1;
            this.coverMindData();
        }
    }

    hasWithoutSaveData() {
        if (this.lastChangeTime == -1) {
            return false;
        }
        if (LoadLocalXJMind.isLoaclMind()) {
            if (LoadLocalXJMind.isCloudStorage()) {
                return this.lastChangeTime > this.lastServerSaveTime || this.lastChangeTime > this.lastLocalSaveTime;
            } else {
                return this.lastChangeTime > this.lastLocalSaveTime;
            }
        } else {
            return this.lastChangeTime > this.lastServerSaveTime;
        }
        // return !(this.coverMindTimeout == null ||
        //     this.coverMindTimeout == -1 ||
        //     this.mindNodeJson == null ||
        //     this.mindNodeJson.length < 50) || (this.mindNodeJson != null &&
        //         this.mindNodeJson.length > 50)|| this.saveTimeout != -1;
    }

    coverMindData() {
        if (this.mindNodeJson == null || this.mindNodeJson.length < 50) {
            return
        }
        if (
            (this.delegate.$router.history.current.name == 'gmap' &&
                this.delegate.MindPageType != 'groupMapEdit') ||
            (this.delegate.$router.history.current.name == 'arvhiveMap' &&
                this.delegate.MindPageType != 'arvhiveMapEdit') ||
            this.delegate.$router.history.current.name == 'c' ||
            this.delegate.$router.history.current.name == 's' ||
            this.delegate.$router.history.current.name == 'template'
        ) {
            return
        }       
        if (LoadLocalXJMind.isLoaclMind()) {
            this.saveMindMapToLocal();
            if (!LoadLocalXJMind.isCloudStorage()) {
                return;
            }
        }
        groupMindSyncAndMerge.get().stopCurrentMerge()
        clearTimeout(this.coverMindTimeout);
        this.coverMindTimeout = -1;
        let imageUrl = store.state.canvanDatas.cover
        if (this.delegate.showSwitchSheet ||  Math.abs(this.lastSaveImageCoverTime - Util.getCurrentTime()) < 1000 * 120) {
            if (this.lastSaveImageCover != null && this.lastSaveImageCover.length > 0) {
                this.saveContentToServer(this.lastSaveImageCover)
            } else {
                this.saveContentToServer(imageUrl)
            }
        } else {
            let rect = this.getMindContentRect();
            if (rect.width() * rect.height() > 1000000) {
                if (this.lastSaveImageCover != null && this.lastSaveImageCover.length > 0) {
                    this.saveContentToServer(this.lastSaveImageCover)
                } else {
                    this.saveContentToServer(imageUrl)
                }
            } else {
                this.delegate.coverToImage().then(res => {
                    if (res == null || res.length <= 2) {
                        this.delegate.saveEditAccout('saveError')
                    } else {
                        this.lastSaveImageCover = res;
                        this.lastSaveImageCoverTime = Util.getCurrentTime();
                        this.saveContentToServer(res)
                    }
                })
            }
        }
    }

    groupMapSaveCallback(success, error) {
        if (success) {
            this.lastServerSaveTime = Math.round(new Date().getTime() / 1000)
            // console.log("save--success ", Util.getCurrentTimeInsecond());
            this.saveSuccess();
        } else {
            let that = this
            this.delegate.saveEditAccout('saveError')
            if (error.code == 23) {
                that.delegate.showTips(new UiUtil().getString(StringsLanguage.Mind_Information_Has_Expired))//"信息已过期请重新登录"
                that.delegate.showLoginModel(false);
                setTimeout(() => {
                    that.delegate.showLoginModel(true);
                }, 100);
            } else if (error.code != 154) {
                this.delegate.showTips(error.desc)
            }
            if (that.delegate.showSwitchSheet) {
                this.delegate.saveSheetSwitchAccout('error')
            }
            this.delegate.setFinishSavingMap({ fulfill: true, result: false });
        }
    }

    checkSettingDataSectionId() {
        if (this.delegate.$router.history.current.name != 'gmap' && this.delegate.MindPageType != 'groupMapEdit') {
            return
        }
        if (this.settingData.sectionIds == null) {
            this.settingData.sectionIds = [];
        }
        IdGenerator.shared.setSettingData(this.settingData);
        let sectionId = IdGenerator.shared.getSelfSectionId();
        if (sectionId == null) {
            let maxId = IdGenerator.shared.getMaxId();
            sectionId = new MindEditSectionId();
            sectionId.uuid = getMachineId();
            sectionId.idCount = IdGenerator.shared.ID_SECTION_COUNT;
            let startId = Math.ceil((maxId) / (sectionId.idCount)) * sectionId.idCount;
            for (let index = 0; index < this.settingData.sectionIds.length; index++) {
                if (startId < this.settingData.sectionIds[index].startId + this.settingData.sectionIds[index].idCount) {
                    startId = this.settingData.sectionIds[index].startId + this.settingData.sectionIds[index].idCount + 1;
                }
            }
            sectionId.startId = startId;
            this.settingData.sectionIds.push(sectionId);
            this.executeSave();
        } else {
            let sameSectionStartList = [];
            let maxSectionStartId = 0;
            for (let index = 0; index < this.settingData.sectionIds.length; index++) {
                let cell = this.settingData.sectionIds[index];
                maxSectionStartId = Math.max(maxSectionStartId, cell.startId);
                if (cell.startId == sectionId.startId) {
                    sameSectionStartList.push(cell);
                }
                if (cell.idCount < 100) {
                    cell.idCount = IdGenerator.ID_SECTION_COUNT;
                }
            }
            if (sameSectionStartList.length > 1 && sameSectionStartList[0].uuid == sectionId.uuid) {
                sectionId.startId = maxSectionStartId + sectionId.idCount + 1;
                this.executeSave();
            } else {
                let existIds = IdGenerator.shared.getExistIdsSort();
                for (let index = 0; index < existIds.length; index++) {
                    let cell = existIds[index];
                    if (cell < sectionId.startId) {
                        continue;
                    }
                    if (cell >= sectionId.startId + sectionId.idCount) {
                        break;
                    }
                    if (index < existIds.length - 1) {
                        if (cell + 80 > sectionId.startId + sectionId.idCount) {
                            let maxId = IdGenerator.shared.getMaxId();
                            let startId = Math.ceil(maxId / sectionId.idCount) * sectionId.idCount;
                            for (let j = 0; j < this.settingData.sectionIds.length; j++) {
                                if (startId < this.settingData.sectionIds[j].startId + this.settingData.sectionIds[j].idCount) {
                                    startId = this.settingData.sectionIds[j].startId + this.settingData.sectionIds[j].idCount + 1;
                                }
                            }
                            sectionId.startId = startId;
                            this.executeSave();
                            break;
                        }
                    }
                }
            }
        }
    }

    updataLocalMindMapInViewMode() {
        if (this.delegate.$router.history.current.name != 'gmap') {
            return;
        }
        if (this.updataLocalMindMapInViewModeTime > -1) {
            clearInterval(this.updataLocalMindMapInViewModeTime)
        }
        let intervalTime = this.delegate.MindPageType == 'groupMapEdit' ? 10000 : 5000;
        this.updataLocalMindMapInViewModeTime = setInterval(() => {
            clearInterval(this.updataLocalMindMapInViewModeTime)
            // console.log("merge--start ", Util.getCurrentTimeInsecond());
            let mindMapId = this.delegate.$route.query.id
            let sheetId = this.delegate.$route.query.sheetId
            let mindNodeJson = this.getCurrentMindContent();
            if (mindNodeJson == null || mindNodeJson.length < 100) {
                this.updataLocalMindMapInViewMode()
                return;
            }            
            let title = this.getTitle();
            let subtitle = this.getSubtitle();
            if (this.mindMapPlayVM == null || this.mindMapPlayVM.getMindMapPlayStatus() == MindMapPlayStatus.NORMAL) {
                let currentLocalMapData = new GroupMindMapData(this.mindType, mindMapId, sheetId);    
                currentLocalMapData.isEditMap = this.delegate.MindPageType == 'groupMapEdit';
                currentLocalMapData.isAutoUpdataMap = true;
                groupMindSyncAndMerge.get().setBaseInfo(this.mindType, mindMapId, sheetId, title, subtitle).merge(this, currentLocalMapData);
            }
            
            this.updataLocalMindMapInViewMode()
        }, intervalTime);
    }

    updataLocalDataMode(data) {
        let mindMapId = this.delegate.$route.query.id
        let sheetId = this.delegate.$route.query.sheetId
        if (data == null || !data.isAvailable() || data.serverId != mindMapId || data.sheetId != sheetId || groupMindSyncAndMerge.get().stopMerge) {
            return;
        }
        IdGenerator.shared.setSettingData(data.settingData);
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            this.mindkey = data.mindkey;
            if (data.mainMindNodeUnit == null) {
                return;
            }
            this.mainMindNodeUnit.rootTreeNode = data.mainMindNodeUnit.rootTreeNode;
            this.mainMindNodeUnit.mainMindElementDataDict = data.mainMindNodeUnit.mainMindElementDataDict;
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData;
            Config.setTightModeEdgeInsets(this.settingData.tightMode);
            this.mainMindNodeUnit.setLineColors(data.settingData.lineColor);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
            this.delegate.changMindBackgroundColor(data.mindBGColor);

            this.globalLineLayout = data.globalLineLayout;
            this.mainMindNodeUnit.globalLineLayout = data.globalLineLayout;

            this.mainMindNodeUnit.lineMindElementDataDict = data.mainMindNodeUnit.lineMindElementDataDict;
            this.mainMindNodeUnit.textElementLineMindElementDataDict = data.mainMindNodeUnit.textElementLineMindElementDataDict;

            this.mainMindNodeUnit.generalizationLineMindElementDataDict = data.mainMindNodeUnit.generalizationLineMindElementDataDict;
            this.mainMindNodeUnit.generalizationMindElementDataDict = data.mainMindNodeUnit.generalizationMindElementDataDict;
            this.mainMindNodeUnit.explainMindElementDataDict = data.mainMindNodeUnit.explainMindElementDataDict;

            this.nodeConnectLineDataDict = data.nodeConnectLineDataDict;
            this.mainMindNodeUnit.encircleMindElementDataDict = data.mainMindNodeUnit.encircleMindElementDataDict;
            if (data.globalLayout != this.globalLayout) {
                this.setGlobalLayout(data.globalLayout);
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.mindkey = data.mindkey;
            if (data.doubleBubbleMindNodeUnit == null) {
                return;
            }
            this.doubleBubbleMindNodeUnit.rootTreeNode = data.doubleBubbleMindNodeUnit.rootTreeNode;
            this.doubleBubbleMindNodeUnit.mainMindElementDataDict = data.doubleBubbleMindNodeUnit.mainMindElementDataDict;
            this.mindBGColor = data.mindBGColor;
            this.doubleBubbleMindNodeUnit.setMindBGColor(this.mindBGColor)
            this.settingData = data.settingData;
            Config.setTightModeEdgeInsets(this.settingData.tightMode);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.delegate.changMindBackgroundColor(data.mindBGColor);

            this.globalLineLayout = data.globalLineLayout;
            this.mainMindNodeUnit.globalLineLayout = data.globalLineLayout;

            this.doubleBubbleMindNodeUnit.lineMindElementDataDict = data.doubleBubbleMindNodeUnit.lineMindElementDataDict;
            this.doubleBubbleMindNodeUnit.textElementLineMindElementDataDict = data.doubleBubbleMindNodeUnit.textElementLineMindElementDataDict;

            this.nodeConnectLineDataDict = data.nodeConnectLineDataDict;
            if (data.globalLayout != this.globalLayout) {
                this.setGlobalLayout(data.globalLayout);
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            this.mindkey = data.mindkey;
            if (data.timeMindTypeNodeUnit == null) {
                return;
            }
            this.timeMindTypeNodeUnit.title = data.timeMindTypeNodeUnit.title;
            this.timeMindTypeNodeUnit.baseLine = data.timeMindTypeNodeUnit.baseLine;
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData;
            this.timeMindTypeNodeUnit.setMindBGColor(this.mindBGColor);
            Config.setTightModeEdgeInsets(this.settingData.tightMode);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.delegate.changMindBackgroundColor(data.mindBGColor);

            IdGenerator.shared.setId(this.timeMindTypeNodeUnit.title.id);
            IdGenerator.shared.setId(this.timeMindTypeNodeUnit.baseLine.id);
            data.timeMindTypeNodeUnit.mainMindElementDataDict.values().array.forEach(node => {
                timeMindTypeNodeUnit.mainMindElementDataDict.put(node.id, node);
                IdGenerator.shared.setId(node.id);
                IdGenerator.shared.setId(node.head.id);
                IdGenerator.shared.setId(node.title.id);
                IdGenerator.shared.setId(node.desc.id);
            });
            this.timeMindTypeNodeUnit.setElementLayout(data.timeMindTypeNodeUnit.globalLayout);

            this.nodeConnectLineDataDict = data.nodeConnectLineDataDict;
        }

        this.freeNodes = data.freeNodes;
        data.removeDatas.forEach(cell => {
            this.removeViewByMindData(cell);
        })

        let list = [];
        for (let i = 0; i < this.selectedList.length; i++) {
            const cell = this.selectedList[i];
            let node = this.getNodeById(cell.id);
            if (!node.isEmpty()) {
                list.push(node.value);
            }
        }
        selectedList = list;

        this.refresh(true);
        this.checkSettingDataSectionId();
    }

    groupMapMergeCallback(success, result, error, mindMapId, sheetId, mindNodeJson, currentLocalMapData) {
        if (success) {
            let subtitle = this.getSubtitle()
            let name = this.getTitle()
            let mindMapId = this.delegate.$route.query.id
            let sheetId = this.delegate.$route.query.sheetId
            let isSave = false;
            if (result == 2) {
                if (currentLocalMapData != null && currentLocalMapData.serverId == mindMapId && 
                    currentLocalMapData.sheetId == sheetId &&
                    this.delegate.$router.history.current.name == 'gmap') {
                if (currentLocalMapData.isEditMap && this.delegate.MindPageType == 'groupMapEdit') {
                    if (!currentLocalMapData.isAutoUpdataMap) {
                        isSave = true;
                        groupMindSyncAndMerge.get().setBaseInfo(this.mindType, mindMapId, sheetId, name, subtitle).save(mindNodeJson, this)
                        return;
                    }
                }
            }
            }
            if (currentLocalMapData == null || !currentLocalMapData.isAvailable()) {
                return;
            }
            
            if (currentLocalMapData != null && currentLocalMapData.isAvailable() && currentLocalMapData.serverId == mindMapId && 
                currentLocalMapData.sheetId == sheetId &&
                this.delegate.$router.history.current.name == 'gmap') {
                if (currentLocalMapData.isEditMap && this.delegate.MindPageType == 'groupMapEdit') {
                    if (!currentLocalMapData.isAutoUpdataMap) {
                        isSave = true;
                        groupMindSyncAndMerge.get().setBaseInfo(this.mindType, mindMapId, sheetId, name, subtitle).save(mindNodeJson, this)
                    }
                }
            }
            // console.log("merge--end", result, Util.getCurrentTimeInsecond());
            if (success && result == 0) {
                if (!isSave && groupMindSyncAndMerge.get().serverMapData != null && groupMindSyncAndMerge.get().serverMapData.isAvailable()) {
                    // let lastLocalMapData = new GroupMindMapData(this.mindType, currentLocalMapData.serverId, currentLocalMapData.sheetId);
                    // lastLocalMapData.setContent(currentLocalMapData.generalSave());
                    groupMindSyncAndMerge.get().setLastData(groupMindSyncAndMerge.get().serverMapData);
                }
                this.updataLocalDataMode(currentLocalMapData)
            }
        } else {
            this.delegate.saveEditAccout('saveError')
        }
    }

    saveContentToServer(imageCover) {
        this.coverMindTimeout = -1;

        let mindMapId = this.delegate.$route.query.id
        let sheetId = this.delegate.$route.query.sheetId
        let imageUrl = store.state.canvanDatas.cover
        let name = this.getTitle()
        let content = this.mindNodeJson
        let subtitle = this.getSubtitle()
        let modifyTime = Math.round(new Date().getTime() / 1000)
        let that = this;

        if (this.delegate.showSwitchSheet && this.mindSheetId != this.delegate.$route.query.sheetId) {
            return;
        }

        if (content == null || content.length < 10) {
            return;
        }

        if (imageUrl != imageCover && imageUrl.length > 0) {
            postMindmapDeleteImage({ imageUrl })
        }
        let lastSaveTime = Util.getCurrentTime()
        //判断时共享组编辑界面还是普通的导图编辑界面。
        if (this.delegate.$router.history.current.name == 'gmap' && this.delegate.MindPageType == 'groupMapEdit') {
            this.updataLocalMindMapInViewMode()
            groupMindSyncAndMerge.get().stopCurrentMerge()
            let currentLocalMapData = new GroupMindMapData(this.mindType, this.delegate.$route.query.id, this.delegate.$route.query.sheetId);
            // currentLocalMapData.setContent(content);
            // console.log("merge adn save", Util.getCurrentTimeInsecond());
            
            groupMindSyncAndMerge.get().setBaseInfo(this.mindType, mindMapId, sheetId, name, subtitle, imageCover, this.mindGlobalAudio).merge(this, currentLocalMapData);

            // let canvansData = { gmapId: mindMapId, 
            //     sheetId: sheetId, name, content, cover: imageCover, subtitle, audio: this.mindGlobalAudio, modifyTime }
            // postGroupUploadGmap(canvansData, res => {
            //     that.lastServerSaveTime = lastSaveTime
            //     this.saveSuccess();
            // }, error => {
            //     that.delegate.saveEditAccout('saveError')
            //     if (error.code == 23) {
            //         that.delegate.showTips(new UiUtil().getString(StringsLanguage.Mind_Information_Has_Expired))//"信息已过期请重新登录"
            //         that.delegate.showLoginModel(false);
            //         setTimeout(() => {
            //             that.delegate.showLoginModel(true);
            //         }, 100);
            //     } else if (error.code != 154) {
            //         that.delegate.showTips(error.desc)
            //     }
            //     if (that.delegate.showSwitchSheet) {
            //         that.delegate.saveSheetSwitchAccout('error')
            //     }
            //     that.delegate.setFinishSavingMap({ fulfill: true, result: false });
            // })
        } else {
            let canvansData = { mindMapId, sheetId: this.delegate.$route.query.sheetId, name, content, cover: imageCover, 
                                subtitle, audio: this.mindGlobalAudio, modifyTime }
            MindmapDataRouter.postUploadMindmap(canvansData, imageCover => {
                that.lastServerSaveTime = lastSaveTime
                // console.log("save server-success ", imageCover);
                this.saveSuccess();
            }, error => {
                // console.log("save server-error ");
                that.delegate.saveEditAccout('saveError')
                if (error.code == 23) {
                    that.delegate.showTips(new UiUtil().getString(StringsLanguage.Mind_Information_Has_Expired))//"信息已过期请重新登录"
                    that.delegate.showLoginModel(false);
                    setTimeout(() => {
                        that.delegate.showLoginModel(true);
                    }, 100);
                } else if (error.code != 154) {
                    that.delegate.showTips(error.desc)
                }
                if (that.delegate.showSwitchSheet) {
                    that.delegate.saveSheetSwitchAccout('error')
                }
                that.delegate.setFinishSavingMap({ fulfill: true, result: false });
            })
        }
    }

    saveSuccess() {
        if (!this.hasWithoutSaveData()) {
            this.delegate.saveEditAccout('saveSuccess')
            this.mindNodeJson = ""
            if (this.delegate.showSwitchSheet) {
                this.delegate.saveSheetSwitchAccout('saveSuccess')
            }
            this.delegate.setFinishSavingMap({ fulfill: true, result: true });
            if (isClickSaveMapButton.value) {
                this.delegate.$message.success(new UiUtil().getString(StringsLanguage.Message_Tips_Save_Success));
                isClickSaveMapButton.value = false;
            }
        }
    }

    savePreviewImage(isDelay) {
        this.savePreviewImageFromView();
    }

    savePreviewImageFromView() {
        // try {
        //     if (this.loadDataModel != null) {
        //         return;
        //     }
        // } catch (e) {
        //     console.log(e);
        // }
    }

    getPreviewImage() {
        return this.getPreviewImage(true);
    }

    getPreviewImage(isTimeLimit) {
        return this.getPreviewImage(new Size(960, 960), isTimeLimit);
    }

    getPreviewImage(sizeLimit, isTimeLimit) {
        let timeStamp = Util.getCurrentTime();
        if (this.viewStatus == ViewStatus.Unknown || 
            this.viewStatus == ViewStatus.WillDisappear || 
            (isTimeLimit && timeStamp - this.savePreviewImageFromViewTime < 5) ||
            this.loadDataModel != null) {
            return null;
        }

        let rect = new CGRect();

        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let calculSaveImageRect = new CalculSaveImageRect(this.mainMindNodeUnit.mainMindElementDataDict,
                this.mainMindNodeUnit.generalizationMindElementDataDict,
                this.mainMindNodeUnit.encircleMindElementDataDict,
                this.mainMindNodeUnit.rootTreeNode.value,
                this.mainMindNodeUnit.lineMindElementDataDict,
                this.mainMindNodeUnit.explainMindElementDataDict);
            rect = calculSaveImageRect.get(MindSettingManager.getMindExportImageSpace());
            if (rect.width() > rect.height()) {
                rect.y = rect.y - (rect.width() - rect.height()) / 2;
                rect.height(rect.width());
            } else {
                rect.x = rect.x - (rect.height() - rect.width()) / 2;
                rect.width(rect.height());
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let calculSaveImageRect = new CalculSaveImageRect(this.doubleBubbleMindNodeUnit.mainMindElementDataDict,
                new HashMap(), new HashMap(), this.doubleBubbleMindNodeUnit.rootTreeNode.value, new HashMap(), new HashMap());
            rect = calculSaveImageRect.get(MindSettingManager.getMindExportImageSpace());
            if (rect.width() > rect.height()) {
                rect.y = rect.y - (rect.width() - rect.height()) / 2;
                rect.height(rect.width());
            } else {
                rect.x = rect.x - (rect.height() - rect.width()) / 2;
                rect.width(rect.height());
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            rect = this.timeMindTypeNodeUnit.getRect();
        }
        if (this.mindDisplayType == MindDisplayType.MindMap &&
            (rect.width() > sizeLimit.getWidth() ||
                rect.height() > sizeLimit.getHeight())) {
            rect = new CGRect(rect.x + (rect.width() - sizeLimit.getWidth()) / 2,
                rect.y + ((rect.height() - sizeLimit.getHeight()) / 2),
                sizeLimit.getWidth(),
                sizeLimit.getHeight());
        }
        let image = this.delegate.getPreviewImage(rect, false);
        if (image == null) {
            return null;
        }
        this.savePreviewImageFromViewTime = timeStamp;
        return image;
    }

    addHostry() {
        if (this.backwardRetreating || this.forwarding || this.loadDataModel != null) {
            return;
        }
        this.addHostryExecute();
    }

    addHostryExecute() {
        if (this.backwardRetreating || this.forwarding) {
            return;
        }
        let count = this.historyList.length;        if (this.currentHistoryIndex < count - 1) {
            let newList = new Array();
            for (let index = 0; index < count; index++) {
                if (index <= this.currentHistoryIndex) {
                    newList.push(this.historyList[index]);
                } else {
                    break;
                }
            }
            this.historyList = newList;
        }
        count = this.historyList.length;
        if (count > 20) {
            let newList = new Array();
            for (let index = count - 15; index < count; index++) {
                newList.push(this.historyList[index]);
            }
            this.historyList = newList;
        }
        this.currentHistoryIndex = this.historyList.length;
        
        let data = new MindHistoryData().copyBaseData(this.mindType, this.globalLayout, this.globalLineLayout, this.mindBGColor);
        data.setContentJson(this.getCurrentMindContent());
        this.historyList.push(data);
        this.delegate.setBackwardRetreatEnable(this.historyList.length > 1);
        this.delegate.setForwardEnable(false);
    }

    selectAddTargetTips() {
        this.showTipsMessage(new UiUtil().getString(StringsLanguage.Global_Add_Target_Tips));
    }

    isAddPictureForSelectedNode() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            return false;
        }
        return this.isNodeAddImage(this.getSelectedNode());
    }

    isNodeAddImage(node) {
        if (node.isEmpty()) {
            return false;
        }
        return node.value.type == MindElementType.MAIN_SUBJECT ||
            node.value.type == MindElementType.SUBJECT ||
            node.value.type == MindElementType.SON_SUBJECT ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION ||
            node.value.type == MindElementType.BAOWEI_VIEW ||
            node.value.type == MindElementType.EXPLAIN;
    }

    showTipsMessage(message) {
        this.delegate.showTips(message, true);
    }

    openSelecteConnectMapPanel() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.selectAddTargetTips();
            return;
        }
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return;
        }
        this.delegate.openSelecteConnectMapPanel(node);
    }

    setMindIdToNodeForConnectMap(nodeId, mindmapId, mapServerId, sheetId) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return new UiUtil().getString(StringsLanguage.Mind_Fail);//'失败'
        }
        if (node.value.isContainConnectMapContent()) {
            this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_ConnectMap_Exist_Tips));
            return new UiUtil().getString(StringsLanguage.Mind_Fail);//'失败'
        }
        node.value.connectMapElementContent = new ConnectMapElementContent(mindmapId, mapServerId, sheetId, 20, 20);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
        return node.value.connectMapElementContent
    }

    onSplitAndConnectMap() {
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.selectAddTargetTips();
            return;
        }
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty() ||
            node.value.type == MindElementType.LINE ||
            node.value.type == MindElementType.BAOWEI_VIEW) {
            return;
        }

        if ((this.mindType != MindType.LINE_MAP &&
            this.mindType != MindType.BUBBLE_MAP)) {
            return;
        }
        if (node.value.isContainConnectMapContent()) {
            this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_ConnectMap_Exist_Tips));
            return;
        }
        let title = "";
        if (node.value.textContent != null) {
            title = node.value.textContent.text;
        } else if (node.value.generalizationContent != null) {
            title = node.value.generalizationContent.text;
        }
        if (title.length == 0) {
            title = new UiUtil().getString(StringsLanguage.Mind_Edit_Main_Idea);
        }

        let finalTitle = title;
        let mindDetailsID = (new StorageManager).get().createMindDetails(finalTitle);
        let mindPreviewID = (new StorageManager).get().createMindPreview(finalTitle, "", mindDetailsID, this.mindType.getValue());
        if (this.mindMapParentId != -1) {
            let data = (new StorageManager).get().readMindFileDescribeById(this.mindMapParentId);
            if (data.childIds.length > 0) {
                data.childIds = data.childIds + "," + mindPreviewID;
            } else {
                data.childIds = "" + mindPreviewID;
            }
            (new StorageManager).get().saveMindFileChilds(this.mindMapParentId, data.childIds);
        }

        let mindDescribe = (new StorageManager).get().getPreviewMindById(mindPreviewID);
        let mindPreviewImageKey = mindDescribe.imageurl;
        if (mindPreviewImageKey.length == 0) {
            (new StorageManager).get().saveMyMindImage(mindPreviewID, mindPreviewImageKey);
        }
        let mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindType, this.mindMapStyleColor.currentSelectedIndex, false);
        mainMindNodeUnit.setElementLayout(this.globalLayout, true);
        mainMindNodeUnit.globalLineLayout = this.globalLineLayout;
        mainMindNodeUnit.createRootNode();
        mainMindNodeUnit.rootTreeNode.value.stick(node.value);
        mainMindNodeUnit.rootTreeNode.value.x = -1;
        mainMindNodeUnit.rootTreeNode.value.y = -1;
        mainMindNodeUnit.rootTreeNode.value.parentNodeId = IdGenerator.INVALID_ID;
        mainMindNodeUnit.rootTreeNode.value.id = IdGenerator.ROOT_ID;
        mainMindNodeUnit.rootTreeNode.value.type = MindElementType.MAIN_SUBJECT;
        MindElementCalculation.set(mainMindNodeUnit.rootTreeNode.value).caluleTextForData();
        node.children.forEach(item => {
            let newNode = mainMindNodeUnit.rootTreeNode.stickNode(item);
            if (!newNode.isEmpty()) {
                MindElementCalculation.set(newNode.value).caluleTextForData();
            }
            this.removeData(item.value.id, false);
        });
        mainMindNodeUnit.setRootNode(mainMindNodeUnit.rootTreeNode);
        mainMindNodeUnit.refreshLayout(false, this.settingData);
        let mindDateFormat = new MindDateFormat();
        mindDateFormat.mindBGColor = this.mindBGColor;
        mindDateFormat.settingData = this.settingData;

        mindDateFormat.mindBGStripesType = this.mindBGStripesType;
        mindDateFormat.mindDisplayType = this.mindDisplayType;
        let freeUnitNodes = new Array();
        mindDateFormat.nodeConnectLinet = new Array();

        mindDateFormat = mindDateFormat.setDatas(mainMindNodeUnit.rootTreeNode,
            new Array(mainMindNodeUnit.lineMindElementDataDict.values()),
            new Array(mainMindNodeUnit.generalizationLineMindElementDataDict.values()),
            new Array(mainMindNodeUnit.generalizationMindElementDataDict.values()),
            freeUnitNodes,
            new Array(mainMindNodeUnit.encircleMindElementDataDict.values()),
            new Array(mainMindNodeUnit.explainMindElementDataDict.values()))
            .setLayout(this.globalLayout, this.globalLineLayout);
        this.mindNodeJson = mindDateFormat.toJSONString();
        if (mindNodeJson != null) {
            (new StorageManager).get().saveMindNodes(mindDetailsID, mindNodeJson);
        }
        this.setMindIdToNodeForConnectMap(node.value.id, mindDetailsID);
    }

    openConnectMapByNode(nodeId, token) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty() ||
            node.value.connectMapElementContent == null ||
            node.value.connectMapElementContent.uuid == "") {
            return;
        }
        let mindId = node.value.connectMapElementContent.uuid;
        let sheetId = node.value.connectMapElementContent.sheetId;
        let routeName = this.delegate.$route.name;
        let routeId = this.delegate.$route.query.id;
        let isMapContent = false;
        const query = {}
        if (sheetId && sheetId != mindId) {
            query['sheetId'] = sheetId
        }
        if (routeName == 'gmap') {//共享组
            postGroupViewGmap({ gmapId: mindId, sheetId: '' }, (res) => {
                if (res.id == null && res.id != "") {
                    isMapContent = false;
                    this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
                } else {
                    this.mindOwnerId = res.userId;
                    this.requestorType = res.requestorType;
                    query['id'] = mindId;
                    if (mindId == routeId) {//当前分页直接跳转
                        if (this.delegate.mindMapingType != null && this.delegate.mindMapingType.value) {
                            //共享组判断是否是编辑状态
                            query['editState'] = 'groupMapEdit'
                        }
                        this.delegate.$router.replace({
                            query
                        });
                        return
                    }
                    if (this.delegate.$tools.isElectron()) {
                        ipcRenderer.send("openCalendarWindow", "/gmap?id=" + mindId);
                    } else {
                        const routerData = this.delegate.$router.resolve({
                            path: "/gmap",
                            query
                        });
                        this.createATagJump(routerData.href)
                    }
                }
            }, (e) => {
                isMapContent = false;
                this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
            })
        } else if (routeName == 'Complie' || routeName == 'arvhiveMap') {//最近创作或归档
            let executeOnlyOnceFlag = false;//只执行一次
            MindmapDataRouter.postGetMindmap({ id: mindId, sheetId: '' }, (res) => {
                if (res.id == null && res.id != "") {
                    isMapContent = false;
                    this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
                } else {
                    query['id'] = mindId;
                    if (mindId == routeId) {//当前分页直接跳转
                        if (routeName == 'arvhiveMap' && this.delegate.mindMapingType != null && this.delegate.mindMapingType.value) {
                            //归档判断是否是编辑状态
                            query['editState'] = 'arvhiveMapEdit'
                        }
                        this.delegate.$router.replace({
                            query
                        });
                        return
                    }
                    if (!executeOnlyOnceFlag) {
                        if (res.isArchived) {
                            if (this.delegate.$tools.isElectron()) {
                                ipcRenderer.send("openCalendarWindow", "/arvhiveMap?id=" + mindId + '&sheetId=' + sheetId);
                            } else {
                                const routerData = this.delegate.$router.resolve({
                                    path: "/arvhiveMap",
                                    query
                                });
                                this.createATagJump(routerData.href)
                            }
                        } else {
                            if (this.delegate.$tools.isElectron()) {
                                ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + mindId + '&sheetId=' + sheetId);
                            } else {
                                const routerData = this.delegate.$router.resolve({
                                    path: "/mindmap",
                                    query
                                });
                                this.createATagJump(routerData.href)
                            }
                        }
                        executeOnlyOnceFlag = true;
                    }
                }
            }, (e) => {
                isMapContent = false;
                this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
            })
        } else if (routeName == 's') {//分享
            let mindRootId = sessionStorage.getItem("mindRootId");
            if (mindRootId) {
                routeId = mindRootId;
            }
            if (routeId && mindId == routeId) {//当前分页直接跳转
                this.delegate.$router.replace({
                    query
                });
                return
            } else {
                postMindmapViewConnectmap({ token: token, mindMapId: mindId }, (res) => {
                    this.delegate.shareToken = res.token;
                    if (this.delegate.$tools.isElectron()) {
                        ipcRenderer.send("openCalendarWindow", "/" + mindId + "?token=" + token);
                    } else {
                        query['token'] = res.token;
                        const routerData = this.delegate.$router.resolve({
                            path: mindId,
                            query
                        });
                        this.createATagJump(routerData.href)
                    }
                }, (err) => {
                    this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
                })
            }
        } else if (routeName == 'c') {//风暴
            routeId = this.delegate.$route.params.id;
            if (routeId && mindId == routeId) {//当前分页直接跳转
                this.delegate.$router.replace({
                    query
                });
                return
            } else {
                this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
            }
        } else if (routeName == 'template') {//模板
            query['id'] = mindId;
            if (routeId && mindId == routeId) {//当前分页直接跳转
                this.delegate.$router.replace({
                    query
                });
                return
            } else {
                this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
            }
        } else {
            this.showTipsMessage(new UiUtil().getString(StringsLanguage.Mind_Mindmap_Open_ConnectMap_No_Exist_Tips));
        }

    }

    createATagJump(url) {
        const a = document.createElement('a');//创建a标签浏览器不会阻止弹出窗口
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
        }, 5000);
    }

    removeConnectMap(nodeId) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty() ||
            node.value.connectMapElementContent == null) {
            return;
        }
        node.value.connectMapElementContent = null;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setSelectedNodeByRect(rect) {
        this.clearSelected();
        this.currentSelectedRect = rect;
        let calculSelectedNodesByRect = new CalculSelectedNodesByRect();
        calculSelectedNodesByRect.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        let list = calculSelectedNodesByRect.hit(rect);
        let isSelecteView = false;

        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.isEmpty() ||
                data.type == MindElementType.LINE ||
                data.type == MindElementType.SON_LINE ||
                data.type == MindElementType.FORM_LINE ||
                data.type == MindElementType.RADIATION_MAP_LINE ||
                data.type == MindElementType.LEFTBRACELINE ||
                data.type == MindElementType.TIME_LINE_BASE_ELEMENT ||
                data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
                data.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                break;
            }
            if (!isSelecteView) {
                this.setSelectedId(data.id);
                this.delegate.onSelected(data, new Point(-1, -1));
            } else {
                this.delegate.onSelectedForMoreSelect(data);
            }
            isSelecteView = true;
        }
        this.delegate.hiddenMindMenu();
        if (list.length > 1) {
            list.sort(function (data1, data2) {
                if (data1.level == data2.level) {
                    return 0;
                } else if (data1.level < data2.level) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }
        this.selectedList = list;
    }

    getMindDetailsID() {
        return this.mindDetailsID;
    }

    selecteMainNode() {
        let mainNode = this.getNodeById(IdGenerator.ROOT_ID);
        if (mainNode.isEmpty()) {
            return;
        }
        this.delegate.onSelected(mainNode.value)
    }

    uncheckNode() {
        this.delegate.clearNodeSelectedStatus()
    }

    getRootSelectedNode() {
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            return this.mainMindNodeUnit.rootTreeNode.value
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            return this.timeMindTypeNodeUnit.title
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            return this.doubleBubbleMindNodeUnit.rootTreeNode.value
        }
        return new MindElementData().emptyMindNode()
    }

    getSelectedLeftData() {
        let node = this.getSelectedNodeById()
        if (node.isEmpty() || node.value.isHidden) {
            return new MindElementData().emptyMindNode()
        }
        let mindNodesNearbyData = new MindNodesNearbyData();
        mindNodesNearbyData.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        return mindNodesNearbyData.getLeftData(node.value)
    }

    getSelectedRightData() {
        let node = this.getSelectedNodeById()
        if (node.isEmpty() || node.value.isHidden) {
            return new MindElementData().emptyMindNode()
        }
        let mindNodesNearbyData = new MindNodesNearbyData();
        mindNodesNearbyData.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        return mindNodesNearbyData.getRightData(node.value)
    }

    getSelectedTopData() {
        let node = this.getSelectedNodeById()
        if (node.isEmpty() || node.value.isHidden) {
            return new MindElementData().emptyMindNode()
        }
        let mindNodesNearbyData = new MindNodesNearbyData();
        mindNodesNearbyData.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        return mindNodesNearbyData.getTopData(node.value)
    }

    getSelectedBottomData() {
        let node = this.getSelectedNodeById()
        if (node.isEmpty() || node.value.isHidden) {
            return new MindElementData().emptyMindNode()
        }
        let mindNodesNearbyData = new MindNodesNearbyData();
        mindNodesNearbyData.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        return mindNodesNearbyData.getBottomData(node.value)
    }

    getSelectedNodeSonData() {
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode()
        }
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            if (node.children.length == 0) {
                return new MindElementData().emptyMindNode()
            }
            return node.children[0].value
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            if (node.value.id == this.timeMindTypeNodeUnit.title.id) {
                let timeNode = this.timeMindTypeNodeUnit.getTopTimeNode()
                if (timeNode.isEmpty()) {
                    return new MindElementData().emptyMindNode()
                } else {
                    return timeNode.head
                }
            } else {
                let timeNode = this.timeMindTypeNodeUnit.getTimeNodeByDataId(node.value.id)
                if (!timeNode.isEmpty()) {
                    if (node.value.id == timeNode.head.id) {
                        return timeNode.title
                    } else if (node.value.id == timeNode.title.id) {
                        return timeNode.desc
                    } else {
                        let timeNode = this.timeMindTypeNodeUnit.getNextNodeByLevel(timeNode.level)
                        if (!timeNode.isEmpty()) {
                            return timeNode.head
                        }
                    }
                }
            }
            return this.timeMindTypeNodeUnit.title
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            if (node.children.length == 0) {
                return new MindElementData().emptyMindNode()
            }
            return node.children[0].value
        }
        return new MindElementData().emptyMindNode()
    }

    getSelectedNodePerData() {
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode()
        }
        var targetData = new MindElementData().emptyMindNode()
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                targetData = new MindElementData().emptyMindNode()
            } else {
                targetData = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId).value
            }
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            if (node.value.id == this.timeMindTypeNodeUnit.title.id) {
                targetData = new MindElementData().emptyMindNode()
            } else {
                let timeNode = this.timeMindTypeNodeUnit.getTimeNodeByDataId(node.value.id)
                if (!timeNode.isEmpty()) {
                    if (node.value.id == timeNode.desc.id) {
                        targetData = timeNode.title
                    } else if (node.value.id == timeNode.title.id) {
                        targetData = timeNode.head
                    } else {
                        let topTimeNode = this.timeMindTypeNodeUnit.getTopTimeNode()
                        if (timeNode.level == topTimeNode.level) {
                            targetData = this.timeMindTypeNodeUnit.title
                        }
                        let timeNode = this.timeMindTypeNodeUnit.getPerNodeByLevel(timeNode.level)
                        if (!timeNode.isEmpty()) {
                            targetData = timeNode.desc
                        }
                    }
                }
            }
            // return this.timeMindTypeNodeUnit.title
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                targetData = new MindElementData().emptyMindNode()
            } else {
                targetData = this.doubleBubbleMindNodeUnit.getNodeById(node.value.parentNodeId).value
            }
        }
        if (targetData.isEmpty()) {
            let len = this.freeNodes.length;
            for (let index = 0; index < len; index++) {
                let unit = this.freeNodes[index]
                let parentNode = unit.getNodeById(node.value.parentNodeId)
                if (!parentNode.isEmpty()) {
                    targetData = parentNode.value
                    break
                }
            }
        }
        return targetData
    }

    getSelectedNodeDownData() {
        if (this.mindDisplayType == MindDisplayType.Outline) {
            return this.getSelectedOutlineNodeDownData()
        }
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode()
        }
        var targetData = new MindElementData().emptyMindNode()
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                targetData = this.getSelectedNodeSonData()
            }
            let mainNode = this.mainMindNodeUnit.getNodeById(node.value.id)
            if (!mainNode.isEmpty()) {
                let parentNode = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId)
                if (!parentNode.isEmpty()) {
                    if (parentNode.children.length > 0) {
                        let count = parentNode.children.length
                        for (let index = 0; index < count; index++) {
                            let child = parentNode.children[index]
                            if (child.value.id == node.value.id) {
                                if (index < count - 1) {
                                    return parentNode.children[index + 1].value
                                } else {
                                    targetData = this.getSelectedNodeSonData()
                                }
                            }
                        }
                        if (targetData.isEmpty()) {
                            targetData = parentNode.value
                        }
                    }
                } else {
                    targetData = this.getSelectedNodeSonData()
                }
            }
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            targetData = this.getSelectedNodeSonData()
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                targetData = this.getSelectedNodeSonData()
            }
            let parentNode = this.doubleBubbleMindNodeUnit.getNodeById(node.value.parentNodeId)
            if (!parentNode.isEmpty()) {
                if (parentNode.children.length > 0) {
                    let count = parentNode.children.length
                    for (let index = 0; index < count; index++) {
                        let child = parentNode.children[index]
                        if (child.value.id == node.value.id) {
                            if (index < count - 1) {
                                return parentNode.children[index + 1].value
                            } else {
                                targetData = this.getSelectedNodeSonData()
                            }
                        }
                    }
                }
            } else {
                targetData = this.getSelectedNodeSonData()
            }
        }
        if (targetData.isEmpty()) {
            let len = this.freeNodes.length;
            for (let index = 0; index < len; index++) {
                let unit = this.freeNodes[index]
                let parentNode = unit.getNodeById(node.value.parentNodeId)
                if (!parentNode.isEmpty()) {
                    if (parentNode.children.length > 0) {
                        let count = parentNode.children.length
                        for (let index = 0; index < count; index++) {
                            let child = parentNode.children[index]
                            if (child.value.id == node.value.id) {
                                if (index < count - 1) {
                                    return parentNode.children[index + 1].value
                                } else {
                                    targetData = this.getSelectedNodeSonData()
                                }
                            }
                        }
                    }
                }
            }
        }

        return targetData
    }

    getSelectedNodeUpData() {
        if (this.mindDisplayType == MindDisplayType.Outline) {
            return this.getSelectedOutlineNodeUpData()
        }
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode()
        }
        var targetData = new MindElementData().emptyMindNode()
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                return new MindElementData().emptyMindNode()
            }
            let mainNode = this.mainMindNodeUnit.getNodeById(node.value.id)
            if (!mainNode.isEmpty()) {
                let parentNode = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId)
                if (!parentNode.isEmpty() && parentNode.children.length > 0) {
                    let count = parentNode.children.length
                    for (let index = 0; index < count; index++) {
                        let child = parentNode.children[index]
                        if (child.value.id == node.value.id) {
                            if (index == 0) {
                                targetData = this.getSelectedNodePerData()
                            } else {
                                return parentNode.children[index - 1].value
                            }
                        }
                    }
                }
            }

        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            targetData = this.getSelectedNodeSonData()
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                targetData = new MindElementData().emptyMindNode()
            }
            let parentNode = this.doubleBubbleMindNodeUnit.getNodeById(node.value.parentNodeId)
            if (!parentNode.isEmpty() && parentNode.children.length > 0) {
                let count = parentNode.children.length
                for (let index = 0; index < count; index++) {
                    let child = parentNode.children[index]
                    if (child.value.id == node.value.id) {
                        if (index == 0) {
                            targetData = this.getSelectedNodePerData()
                        } else {
                            return parentNode.children[index - 1].value
                        }
                    }
                }
            }
        }
        if (targetData.isEmpty()) {
            let len = this.freeNodes.length;
            for (let index = 0; index < len; index++) {
                let unit = this.freeNodes[index]
                let parentNode = unit.getNodeById(node.value.parentNodeId)
                if (!parentNode.isEmpty() && parentNode.children.length > 0) {
                    let count = parentNode.children.length
                    for (let index = 0; index < count; index++) {
                        let child = parentNode.children[index]
                        if (child.value.id == node.value.id) {
                            if (index == 0) {
                                targetData = parentNode.value
                            } else {
                                return parentNode.children[index - 1].value
                            }
                        }
                    }
                }
            }
        }
        return targetData
    }

    getSelectedOutlineNodeDownData() {
        if (this.mindDisplayType != MindDisplayType.Outline) {
            return this.getSelectedNodeDownData()
        }
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode()
        }
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {

            var downNode = this.mainMindNodeUnit.getOutlineNextNode(this.selectedNodeId)
            if (downNode.isEmpty()) {
                let len = this.freeNodes.length;
                for (let index = 0; index < len; index++) {
                    let unit = this.freeNodes[index]
                    downNode = unit.getOutlineNextNode(this.selectedNodeId)
                    if (!downNode.isEmpty()) {
                        break
                    }
                }
            }
            return downNode.value


        } else {
            return new MindElementData().emptyMindNode()
        }
    }

    getSelectedOutlineNodeUpData() {
        if (this.mindDisplayType != MindDisplayType.Outline) {
            return this.getSelectedNodeUpData()
        }
        let node = this.getSelectedNodeById()
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode()
        }
        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {

            var upNode = this.mainMindNodeUnit.getOutlinePreviousNode(this.selectedNodeId)
            if (upNode.isEmpty()) {
                let len = this.freeNodes.length;
                for (let index = 0; index < len; index++) {
                    let unit = this.freeNodes[index]
                    upNode = unit.getOutlinePreviousNode(this.selectedNodeId)
                    if (!upNode.isEmpty()) {
                        break
                    }
                }
            }
            return upNode.value
        } else {
            return new MindElementData().emptyMindNode()
        }
    }
    //获取audio url
    getGlobalAudioUrl() {
        return this.mindGlobalAudio;
    }
    //添加audio
    setGlobalAudioUrl(url) {
        if (this.mindGlobalAudio == url) {
            return;
        }
        this.mindGlobalAudio = url;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }
    //是否为null
    isGlobalAudio() {
        return !(new Strings().isEmpty(this.getGlobalAudioUrl()));
    }

    isSelectedNodeInSelectedList(id) {
        if (this.selectedList.isEmpty()) {
            return false;
        }
        for (let i = 0; i < this.selectedList.length; i++) {
            const data = this.selectedList[i];
            if (data.id == id) {
                return true;
            }
        }
        return false;
    }
    isShowAlignment() {
        if (this.selectedList.isEmpty()) {
            return false;
        }
        let list = this.getSelectedRootNodes();
        return list.length > 1;
    }

    isShowSameWidth() {
        if (this.selectedList.isEmpty()) {
            return false;
        }
        let list = this.getSelectedTextNodes();
        return list.length > 1;
    }

    setNodeSameWidth() {
        let list = this.getSelectedTextNodes()
        if (list.isEmpty()) {
            return
        }
        var maxWidth = 0;
        this.selectedList.forEach(data => {
            if ((maxWidth < data.width) && 
                (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.EXPLAIN ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.CONTENT_GENERALIZATION)) {
                maxWidth = data.width;
            }
        })
        this.selectedList.forEach(data => {
            if (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.EXPLAIN ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.CONTENT_GENERALIZATION) {
                data.customWidth = maxWidth;
                if (data.alignmentType == MindElementAlignmentType.LEFT) {
                    data.alignmentType = MindElementAlignmentType.MIDDLE
                }
                MindElementCalculation.set(data).caluleTextForData().calcule();
            }
        })
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setTaskPriority(level) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.taskContent == null) {
            return;
        }
        node.value.taskContent.priority = level;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setTaskProgress(progress) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.taskContent == null) {
            return;
        }
        node.value.taskContent.taskProgress = progress;
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    onDataSizeChanged(size, id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        let preData = node.value.copy();
        if (size.getWidth() < new UiUtil().dip2px(5)) {
            node.value.customWidth = 0;
            node.value.customHeight = 0;
        } else {
            node.value.customWidth = Math.max(new UiUtil().dip2px(12), size.getWidth());
            node.value.customHeight = Math.max(new UiUtil().dip2px(12), size.getHeight());
            if (node.value.alignmentType == MindElementAlignmentType.LEFT) {
                node.value.alignmentType = MindElementAlignmentType.MIDDLE
            }
        }
        MindElementCalculation.set(node.value).caluleTextForData().calcule(false);

        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }
    //直接传入一个数据
    changeNodeWidth(width, id) {
        if (id == null || id == undefined || id == "") {
            id = this.selectedNodeId;
        }
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        let preData = node.value.copy();
        if (width < new UiUtil().dip2px(5)) {
            node.value.customWidth = 0;
            node.value.customHeight = 0;
        } else {
            node.value.customWidth = Math.max(new UiUtil().dip2px(12), width);
            node.value.customHeight = 0;
            if (node.value.alignmentType == MindElementAlignmentType.LEFT) {
                node.value.alignmentType = MindElementAlignmentType.MIDDLE
            }
        }
        MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
        if (this.isRootNode(node.value.id)) {
            node.value.x = preData.x - (node.value.width - preData.width) / 2
            node.value.y = preData.y - (node.value.height - preData.height) / 2
            this.checkNodeConnectLinePointByChangeSize(preData, node.value)
        }
        this.dataConnectLinePoint(node.value, preData);
        this.selectedList.forEach(data => {
            if (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.EXPLAIN ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.CONTENT_GENERALIZATION) {
                let preData = data.copy()
                if (width < new UiUtil().dip2px(5)) {
                    data.customWidth = 0;
                    data.customHeight = 0;
                } else {
                    data.customWidth = width;
                    data.customHeight = 0;
                    if (data.alignmentType == MindElementAlignmentType.LEFT) {
                        data.alignmentType = MindElementAlignmentType.MIDDLE
                    }
                }
                
                MindElementCalculation.set(data).caluleTextForData().calcule();

                if (this.isRootNode(data.id)) {
                    data.x = preData.x - (data.width - preData.width) / 2
                    data.y = preData.y - (data.height - preData.height) / 2
                    this.checkNodeConnectLinePointByChangeSize(preData, data)
                }
                this.dataConnectLinePoint(data, preData);
            }
        })
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    changeAlignmentType(alignmentType) {
        if (!this.selectedList.isEmpty()) {
            this.selectedList.forEach(data => {
                if (data.type == MindElementType.MAIN_SUBJECT ||
                    data.type == MindElementType.SUBJECT ||
                    data.type == MindElementType.SON_SUBJECT ||
                    data.type == MindElementType.CONTENT_GENERALIZATION) {
                    data.alignmentType = alignmentType;
                }
            });
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        } else {
            let node = this.getSelectedNode();
            if (node.isEmpty()) {
                this.selectAddTargetTips();
                return;
            }
            node.value.alignmentType = alignmentType;
            this.refresh(true);
            this.willSave(true);
            this.addHostry();
        }
    }

    getSelectedRootNodes() {
        let list = new Array();
        if (this.selectedList.isEmpty()) {
            return list;
        }
        this.selectedList.forEach(data => {
            if (this.isRootNode(data.id) || (data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue())) {
                list.push(data);
            }
        });
        return list;
    }

    getSelectedTextNodes() {
        let list = new Array();
        if (this.selectedList.isEmpty()) {
            return list;
        }
        this.selectedList.forEach(data => {
            if (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.CONTENT_GENERALIZATION) {
                list.push(data);
            }
        });
        return list;
    }

    getSelectedList() {
        return this.selectedList;
    }

    setSelectedNodesById(id) {
        let node = this.getNodeById(id);
        let data = node.value;
        if (node.isEmpty()) {
            return
        }
        if (this.isSelectedNodeInSelectedList(id) || this.selectedNodeId == id) {
            this.delegate.clearSelectedView(id)

            if (this.selectedList != null && this.selectedList.length > 0) {
                var list = []
                for (let index = 0; index < this.selectedList.length; index++) {
                    if (this.selectedList[index].id != id) {
                        list.push(this.selectedList[index])
                    }
                }
                this.selectedList = list;
                this.delegate.setTopToolBarForNode()
            }
            if (this.selectedNodeId == id) {
                if (this.selectedList != null && this.selectedList.length > 0) {
                    this.setSelectedId(this.selectedList[0].id)
                } else {
                    this.setSelectedId(IdGenerator.INVALID_ID)
                }
            }
            return
        }
        if (
            data.type == MindElementType.LINE ||
            data.type == MindElementType.SON_LINE ||
            data.type == MindElementType.FORM_LINE ||
            data.type == MindElementType.RADIATION_MAP_LINE ||
            data.type == MindElementType.LEFTBRACELINE ||
            data.type == MindElementType.TIME_LINE_BASE_ELEMENT ||
            data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
            data.type == MindElementType.LAYOUT_CUSTOM_LINE) {
            return;
        }
        if (this.selectedNodeId == IdGenerator.INVALID_ID) {
            this.setSelectedId(id);
            this.delegate.onSelected(data, new Point(-1, -1));
            this.delegate.hiddenMindMenu();
        } else {
            var list = []
            if (this.selectedList != null) {
                list = this.selectedList
            }

            if (list.isEmpty()) {
                list.push(data);
                let currentSelectedNode = this.getNodeById(this.selectedNodeId);
                if (!currentSelectedNode.isEmpty()) {
                    list.push(currentSelectedNode.value);
                }
            } else {
                list.push(data);
            }
            let count = list.length
            var isSelecteView = false
            for (let index = 0; index < count; index++) {
                let cell = list[index]
                if (cell == null || cell.isEmpty()) {
                    continue
                }
                if (!isSelecteView) {
                    this.setSelectedId(cell.id);
                    this.delegate.onSelected(cell, new Point(-1, -1));
                } else {
                    this.delegate.onSelectedForMoreSelect(cell);
                }
                isSelecteView = true;
            }
            this.delegate.hiddenMindMenu();
            this.selectedList = list
            this.delegate.setTopToolBarForNode()
        }
    }

    leftAlignment() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty()) {
            return;
        }
        let left = 20000000;
        rectMap.keys().forEach(id => {
            left = Math.min(left, rectMap.get(id).x);
        });
        for (let i = 0; i < rectMap.keys().length; i++) {
            const id = rectMap.keys()[i];
            let node = this.getNodeById(id);
            let rect = rectMap.get(id);
            if (node.isEmpty()) {
                continue;
            }
            let preData = node.value.copy();
            let move = (rect.x - left);
            this.nodeMoveCheckConnectLines(node.value, move, 0);
            node.value.x = node.value.x - move;
            this.dataConnectLinePoint(node.value, preData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    topAlignment() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty()) {
            return;
        }
        let top = 20000000;
        rectMap.keys().forEach(id => {
            top = Math.min(top, rectMap.get(id).y);
        });
        for (let i = 0; i < rectMap.keys().length; i++) {
            const id = rectMap.keys()[i];
            let node = this.getNodeById(id);
            let rect = rectMap.get(id);
            if (node.isEmpty()) {
                continue;
            }
            let preData = node.value.copy();
            let move = (rect.y - top);
            this.nodeMoveCheckConnectLines(node.value, 0, move);
            node.value.y = node.value.y - move;
            this.dataConnectLinePoint(node.value, preData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    rightAlignment() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty()) {
            return;
        }
        let right = 0;
        rectMap.keys().forEach(id => {
            right = Math.max(right, rectMap.get(id).x + rectMap.get(id).width());
        });
        for (let i = 0; i < rectMap.keys().length; i++) {
            const id = rectMap.keys()[i];
            let node = this.getNodeById(id);
            let rect = rectMap.get(id);
            if (node.isEmpty()) {
                continue;
            }
            let preData = node.value.copy();
            let move = right - (rect.x + rect.width());
            this.nodeMoveCheckConnectLines(node.value, -move, 0);
            node.value.x = node.value.x + move;
            this.dataConnectLinePoint(node.value, preData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    bottomAlignment() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty()) {
            return;
        }
        let bottom = 0;
        rectMap.keys().forEach(id => {
            bottom = Math.max(bottom, rectMap.get(id).y + rectMap.get(id).height());
        });
        for (let i = 0; i < rectMap.keys().length; i++) {
            const id = rectMap.keys()[i];
            let node = this.getNodeById(id);
            let rect = rectMap.get(id);
            if (node.isEmpty()) {
                continue;
            }
            let preData = node.value.copy();
            let move = bottom - (rect.y + rect.height());
            this.nodeMoveCheckConnectLines(node.value, 0, -move);
            node.value.y = node.value.y + move;
            this.dataConnectLinePoint(node.value, preData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    centreHorizontalAlignment() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty()) {
            return;
        }
        let left = 2000000;
        let centre = 0;
        rectMap.keys().forEach(id => {
            if (left > rectMap.get(id).x) {
                left = rectMap.get(id).x;
                centre = rectMap.get(id).y + rectMap.get(id).height() / 2;
            }
        });
        for (let i = 0; i < rectMap.keys().length; i++) {
            const id = rectMap.keys()[i];
            let node = this.getNodeById(id);
            let rect = rectMap.get(id);
            if (node.isEmpty()) {
                continue;
            }
            let preData = node.value.copy();
            let move = centre - (rect.y + rect.height() / 2);
            this.nodeMoveCheckConnectLines(node.value, 0, -move);
            node.value.y = node.value.y + move;
            this.dataConnectLinePoint(node.value, preData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    centreVerticalAlignment() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty()) {
            return;
        }
        let top = 2000000;
        let centre = 0;
        rectMap.keys().forEach(id => {
            if (top > rectMap.get(id).y) {
                top = rectMap.get(id).y;
                centre = rectMap.get(id).x + rectMap.get(id).width() / 2;
            }
        });
        for (let i = 0; i < rectMap.keys().length; i++) {
            const id = rectMap.keys()[i];
            let node = this.getNodeById(id);
            let rect = rectMap.get(id);
            if (node.isEmpty()) {
                continue;
            }
            let preData = node.value.copy();
            let move = centre - (rect.x + rect.width() / 2);
            this.nodeMoveCheckConnectLines(node.value, -move, 0);
            node.value.x = node.value.x + move;
            this.dataConnectLinePoint(node.value, preData);
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    averageDistributionVertical() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty() || rectMap.length == 1) {
            return;
        }
        let top = 2000000;
        let bottom = 0;
        let totalHeight = 0;
        rectMap.keys().forEach(id => {
            let rect = rectMap.get(id);
            if (top > rect.y) {
                top = rect.y;
            }
            if (bottom < rect.y + rect.height()) {
                bottom = rect.y + rect.height();
            }
            totalHeight += rect.height();
        });

        list.sort((sweet1, sweet2) => {
            let recr1 = rectMap.get(sweet1.id);
            let recr2 = rectMap.get(sweet2.id);
            if (recr1.y == recr2.y) {
                return 0;
            } else if (recr1.y < recr2.y) {
                return -1;
            } else {
                return 1;
            }
        })

        let spcae = ((bottom - top) - totalHeight) / (list.length - 1);
        let preTop = 0;
        for (let index = 0; index < list.length; index++) {
            let data = list[index];
            if (!rectMap.containsKey(data.id)) {
                continue;
            }
            let rect = rectMap.get(data.id);
            if (index > 0) {
                let preData = data.copy();
                let move = preTop - rect.y;
                this.nodeMoveCheckConnectLines(data, 0, -move);
                data.y = data.y + move;
                preTop += rect.height() + spcae;
                this.dataConnectLinePoint(data, preData);
            } else {
                preTop = rect.y + rect.height() + spcae;
            }
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    averageDistributionHorizontal() {
        let list = this.getSelectedRootNodes();
        if (list.isEmpty()) {
            return;
        }
        let rectMap = new HashMap();
        for (let i = 0; i < list.length; i++) {
            const data = list[i];
            if (data.type == MindElementType.BAOWEI_VIEW ||
                data.type == MindElementType.NODE_CONNECT_LINE) {
                continue;
            }
            if (!this.mainMindNodeUnit.isRootNode(data.id) &&
                data.isFreeLayoutNode && this.isBranchNodeFreelayoutMode() && this.settingData.branchNodeFreelayout.isValue()) {
                rectMap.put(data.id, new CGRect(data.x, data.y, data.width, data.head));
                continue;
            }
            let node = this.mainMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.mainMindNodeUnit.getRectWithoutEncircle());
                continue;
            }
            node = this.doubleBubbleMindNodeUnit.getNodeById(data.id);
            if (!node.isEmpty()) {
                rectMap.put(data.id, this.doubleBubbleMindNodeUnit.getRect());
                continue;
            }
            let timeData = this.timeMindTypeNodeUnit.getNodeById(data.id);
            if (!timeData.isEmpty()) {
                rectMap.put(data.id, this.timeMindTypeNodeUnit.getRect());
                continue;
            }

            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                node = unit.getNodeById(data.id);
                if (!node.isEmpty()) {
                    rectMap.put(data.id, unit.getRectWithoutEncircle());
                    break;
                }
            }
        }
        if (rectMap.isEmpty() || rectMap.length == 1) {
            return;
        }
        let left = 2000000;
        let rigth = 0;
        let totalWidth = 0;
        rectMap.keys().forEach(id => {
            let rect = rectMap.get(id);
            if (left > rect.x) {
                left = rect.x;
            }
            if (rigth < rect.x + rect.width()) {
                rigth = rect.x + rect.width();
            }
            totalWidth += rect.width();
        });
        list.sort((sweet1, sweet2) => {
            let recr1 = rectMap.get(sweet1.id);
            let recr2 = rectMap.get(sweet2.id);
            if (recr1.x == recr2.x) {
                return 0;
            } else if (recr1.x < recr2.x) {
                return -1;
            } else {
                return 1;
            }
        })
        let spcae = ((rigth - left) - totalWidth) / (list.length - 1);
        let preLeft = 0;
        for (let i = 0; i < list.length; i++) {
            let data = list[i];
            if (!rectMap.containsKey(data.id)) {
                continue;
            }
            let rect = rectMap.get(list[i].id);
            if (i > 0) {
                let preData = data.copy();
                let move = preLeft - rect.x;
                this.nodeMoveCheckConnectLines(data, -move, 0);
                data.x = data.x + move;
                preLeft += rect.width() + spcae;
                this.dataConnectLinePoint(data, preData);
            } else {
                preLeft = rect.x + rect.width() + spcae;
            }

        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }


    getSelectedGRect(id) {
        let rect = new CGRect(0, 0, 0, 0);
        let node = this.getNodeById(id);
        if (node.isEmpty() || node.value.type != MindElementType.CONTENT_GENERALIZATION || node.value.generalizationContent == null) {
            return rect;
        }
        let targetIds = node.value.generalizationContent.targetIds;
        if (targetIds.isEmpty()) {
            return rect;
        }
        let rectList = new Array();
        for (let index = 0; index < targetIds.length; index++) {
            let targetId = targetIds[index]
            let targetNode = this.getNodeById(targetId);
            if (targetNode.isEmpty()) {
                continue;
            }
            let cellRect = new NodesRectCalculation().calcule(targetNode);
            rectList.push(cellRect);
        }
        if (rectList.isEmpty()) {
            return rect;
        }
        return new RectsMergeCalculation().calcule(rectList);

    }

    addImageForNodeBackgroun(id, serverUrl) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            this.selectAddTargetTips();
            return;
        }
        if (node.value.type != MindElementType.MAIN_SUBJECT &&
            node.value.type != MindElementType.SUBJECT &&
            node.value.type != MindElementType.SON_SUBJECT &&
            node.value.type != MindElementType.CONTENT_GENERALIZATION &&
            node.value.type != MindElementType.BAOWEI_VIEW &&
            node.value.type != MindElementType.EXPLAIN) {
            return;
        }
        if (node.value.type == MindElementType.BAOWEI_VIEW) {
            node.value.lineContent.backgroundUrl = serverUrl;
        } else {
            node.value.backgroundUrl = serverUrl;
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    nodeMoveCheckConnectLines(data, moveDistanceX, moveDistanceY) {
        if (data.isEmpty()) {
            return;
        }
        var connectLines = this.getNodeConnectLine(data.id);
        if (!connectLines.isEmpty()) {
            for (let index = 0; index < connectLines.length; index++) {
                let connectLine = connectLines[index]
                if (connectLine.parentNodeId == data.id) {
                    connectLine.lineContent.startControlPointX = connectLine.lineContent.startControlPointX - moveDistanceX;
                    connectLine.lineContent.startControlPointY = connectLine.lineContent.startControlPointY - moveDistanceY;
                } else if (connectLine.lineContent.targetId == data.id) {
                    connectLine.lineContent.endControlPointX = connectLine.lineContent.endControlPointX - moveDistanceX;
                    connectLine.lineContent.endControlPointY = connectLine.lineContent.endControlPointY - moveDistanceY;
                }
            }
        }
    }

    getNodeConnectLine(id) {
        var lines = [];
        let keys = this.nodeConnectLineDataDict.keys();
        for (let index = 0; index < keys.length; index++) {
            let line = this.nodeConnectLineDataDict.get(keys[index]);
            if (line.isEmpty() || line.lineContent == null) {
                continue;
            }
            if (line.parentNodeId == id || line.lineContent.targetId == id) {
                lines.push(line);
            }
        }
        return lines;
    }

    /**
     * @method playMap 播放导图
     * @param {float} mapScale 比例
     * @param {int} speed 速度
     * @param {int} textSpeed 文字速度
     * @param {array} playOrderList 播放顺序列表
     */

    playMap(data) {

        if (this.mindMapPlayVM == null || this.mindMapPlayVM.getMindMapPlayStatus() == MindMapPlayStatus.NORMAL) {
            this.setMindMapPlayData();
        }
        this.mindMapPlayVM.setConfig(data);
        this.mindMapPlayVM.play();
    }

    resumePlay(data) {

        if (this.mindMapPlayVM == null || this.mindMapPlayVM.getMindMapPlayStatus() == MindMapPlayStatus.NORMAL) {
            this.setMindMapPlayData();
        }
        this.mindMapPlayVM.setConfig(data);
        this.mindMapPlayVM.resumePlay();
    }

    replayMap() {
        this.mindMapPlayVM.play();
    }

    isExistResumeData() {
        if (this.mindMapPlayVM == null) {
            return false;
        }
        return this.mindMapPlayVM.isExistResumeData();
    }

    pausePlayMap() {
        if (this.mindMapPlayVM == null) {
            return;
        }
        this.mindMapPlayVM.pause();
    }

    stopPlayMap() {
        if (this.mindMapPlayVM == null) {
            return;
        }
        this.mindMapPlayVM.stop();
        this.refresh(true);
    }

    getMindMapPlayStatus() {
        if (this.mindMapPlayVM == null) {
            return MindMapPlayStatus.NORMAL;
        }
        return this.mindMapPlayVM.getMindMapPlayStatus();
    }

    mindMapPlayComplete() {
        this.delegate.mindMapPlayComplete();
    }

    setMindMapPlayData() {
        if (this.mindMapPlayVM == null) {
            this.mindMapPlayVM = new MindMapPlayVM(this.mindType, this.delegate, this);
        }
        this.mindMapPlayVM.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit,
            this.mindBGColor);
    }

    getPlayRootNodes() {
        let list = new Array();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            list.push(this.mainMindNodeUnit.rootTreeNode);
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            list.push(this.doubleBubbleMindNodeUnit.rootTreeNode);
        } else if (this.mindType == MindType.TIME_MAP) {
            list.push(new LineMindTypeNode(this.timeMindTypeNodeUnit.title));
        }
        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            if (unit != null && unit.rootTreeNode != null && !unit.rootTreeNode.isEmpty()) {
                list.push(unit.rootTreeNode);
            }
        }
        return list;
    }

    checkNodeConnectLinePointByChangeSize(original, data) {
        if (original.isEmpty() || data.isEmpty() ||
            (original.width == data.width && original.height == data.height)) {
            return;
        }
        let nodeConnectLines = this.getNodeConnectLine(data.id);
        if (!nodeConnectLines.isEmpty()) {
            let length = nodeConnectLines.length;
            for (let index = 0; index < length; index++) {
                let cell = nodeConnectLines[index]
                if (cell.lineContent == null) {
                    continue;
                }
                if (cell.parentNodeId == data.id) {
                    let targetOriginalCenterPoint = original.getCenterPoint();
                    let targetCenterPoint = data.getCenterPoint();
                    let startControlPointScaleX = (targetOriginalCenterPoint.x - cell.lineContent.startControlPointX) / original.width;
                    let startControlPointScaleY = (targetOriginalCenterPoint.y - cell.lineContent.startControlPointY) / original.height;

                    if (original.y < cell.lineContent.startControlPointY &&
                        original.y + original.height > cell.lineContent.startControlPointY &&
                        original.x + original.width < cell.lineContent.startControlPointX) { // 正右侧
                        cell.lineContent.startControlPointX = data.x + data.width + (cell.lineContent.startControlPointX - original.x - original.width);
                        cell.lineContent.startControlPointY = (targetCenterPoint.y - startControlPointScaleY * (data.height));
                    } else if (original.y < cell.lineContent.startControlPointY &&
                        original.y + original.height > cell.lineContent.startControlPointY &&
                        original.x > cell.lineContent.startControlPointX) { // 正左侧
                        cell.lineContent.startControlPointX = data.x - (original.x - cell.lineContent.startControlPointX);
                        cell.lineContent.startControlPointY = (targetCenterPoint.y - startControlPointScaleY * (data.height));
                    } else if (original.y > cell.lineContent.startControlPointY &&
                        original.x + original.width > cell.lineContent.startControlPointX &&
                        original.x < cell.lineContent.startControlPointX) { // 正上方
                        cell.lineContent.startControlPointX = (targetCenterPoint.x - startControlPointScaleX * (data.width));
                        cell.lineContent.startControlPointY = data.y - (original.y - cell.lineContent.startControlPointY);
                    } else if (original.y + original.height < cell.lineContent.startControlPointY &&
                        original.x + original.width > cell.lineContent.startControlPointX &&
                        original.x < cell.lineContent.startControlPointX) { // 正下方
                        cell.lineContent.startControlPointX = (targetCenterPoint.x - startControlPointScaleX * (data.width));
                        cell.lineContent.startControlPointY = data.y + data.height + (cell.lineContent.startControlPointY - original.y - original.height);
                    } else {
                        cell.lineContent.startControlPointX = (targetCenterPoint.x - startControlPointScaleX * (data.width));
                        cell.lineContent.startControlPointY = (targetCenterPoint.y - startControlPointScaleY * (data.height));
                    }
                }
                if (cell.lineContent.targetId == data.id) {
                    let targetOriginalCenterPoint = original.getCenterPoint();
                    let endControlPointScaleX = (targetOriginalCenterPoint.x - cell.lineContent.endControlPointX) / original.width;
                    let endControlPointScaleY = (targetOriginalCenterPoint.y - cell.lineContent.endControlPointY) / original.height;

                    let targetCenterPoint = data.getCenterPoint();

                    if (original.y < cell.lineContent.endControlPointY &&
                        original.y + original.height > cell.lineContent.endControlPointY &&
                        original.x + original.width < cell.lineContent.endControlPointX) { // 正右侧
                        cell.lineContent.endControlPointX = data.x + data.width + (cell.lineContent.endControlPointX - original.x - original.width);
                        cell.lineContent.endControlPointY = (targetCenterPoint.y - endControlPointScaleY * (data.height));
                    } else if (original.y < cell.lineContent.endControlPointY &&
                        original.y + original.height > cell.lineContent.endControlPointY &&
                        original.x > cell.lineContent.endControlPointX) { // 正左侧
                        cell.lineContent.endControlPointX = data.x - (original.x - cell.lineContent.endControlPointX);
                        cell.lineContent.endControlPointY = (targetCenterPoint.y - endControlPointScaleY * (data.height));
                    } else if (original.y > cell.lineContent.endControlPointY &&
                        original.x + original.width > cell.lineContent.endControlPointX &&
                        original.x < cell.lineContent.endControlPointX) { // 正上方
                        cell.lineContent.endControlPointX = (targetCenterPoint.x - endControlPointScaleX * (data.width));
                        cell.lineContent.endControlPointY = data.y - (original.y - cell.lineContent.endControlPointY);
                    } else if (original.y + original.height < cell.lineContent.endControlPointY &&
                        original.x + original.width > cell.lineContent.endControlPointX &&
                        original.x < cell.lineContent.endControlPointX) { // 正下方
                        cell.lineContent.endControlPointX = (targetCenterPoint.x - endControlPointScaleX * (data.width));
                        cell.lineContent.endControlPointY = data.y + data.height + (cell.lineContent.endControlPointY - original.y - original.height);
                    } else {
                        cell.lineContent.endControlPointX = (targetCenterPoint.x - endControlPointScaleX * (data.width));
                        cell.lineContent.endControlPointY = (targetCenterPoint.y - endControlPointScaleY * (data.height));
                    }
                }
            }

        }
    }
    //清除内容里面的样式
    clearTextStyle(id, isCardTitle = false) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (isCardTitle && node.value.isCardContent()) {
            node.value.titleContent.styleCells = new Array();
        } else if (node.value.textContent != null) {
            node.value.textContent.styleCells = new Array();
        } else if (node.value.generalizationContent != null) {
            node.value.generalizationContent.styleCells = new Array();
        }
        MindElementCalculation.set(node.value).caluleTextForData();
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    onDoubleClick(point) { 
        MindSettingManager.loadData();//重新加载数据
        let mindElementHitCalculation = new MindElementHitCalculation();
        mindElementHitCalculation.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        let radiateData = mindElementHitCalculation.hitRadiateData(point);
        if (!radiateData.isEmpty()) {
            let node = this.getNodeById(radiateData.id)
            let parentNode = this.getNodeById(radiateData.parentNodeId)
            if (!parentNode.isEmpty()) {
                if (Util.getCrossArea(new CGRect(parentNode.value.x, parentNode.value.y, parentNode.value.width + 500, parentNode.value.height), 
                                            new CGRect(node.value.x - 200, node.value.y, node.value.width, node.value.height)) > 0) {//radiateData 
                    this.selectedNodeId = radiateData.id
                    this.addChildMindElement()
                    return;
                } else if (parentNode.value.y + parentNode.value.height - 5 < radiateData.y && 
                    parentNode.value.y + parentNode.value.height - 5 < radiateData.y + radiateData.height) {//radiateData 下方
                    this.addYoungerBrotherMindElement("", parentNode.children[parentNode.children.length - 1].value.id)
                    return;
                } else if (parentNode.value.y + parentNode.value.height - 5 > radiateData.y && 
                    parentNode.value.y + parentNode.value.height - 5 > radiateData.y + radiateData.height) {//radiateData 上方
                    this.selectedNodeId = parentNode.children[0].value.id
                    this.addBrotherMindElement()
                    return;
                }
            }
        }
        
        let data = mindElementHitCalculation.hit(point);

        if (data.isEmpty()) {
            this.addFreeNodeByPoint(point, true);
        } else if (data.type == MindElementType.BAOWEI_VIEW) {
            if (this.isRootNode(data.parentNodeId)) {
                this.addFreeNodeByPoint(point, true);
            }
        }
    }

    findFreeUnit(id) {
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            if (!unit.getNodeById(id).isEmpty()) {
                return unit;
            }
        }
        return null;
    }

    isChangeNodeLayout() {  //判断当前节点是否有可以改变的布局状态。
        if (this.isFlowChart()) {
            return false;
        }
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return false;
        }
        if (this.mindType == MindType.LINE_MAP) {
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (!grandpaNode.isEmpty() && grandpaNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return true;
                } else {
                    return false;
                }
            }
            if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (!grandpaNode.isEmpty() && grandpaNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        } else if (this.mindType == MindType.BUBBLE_MAP ||
            this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return false;
        } else if (this.mindType == MindType.TIME_MAP) {
            let mainNode = this.timeMindTypeNodeUnit.getNodeById(node.value.id);
            if (!mainNode.isEmpty()) {
                return false;
            }
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                return false;
            }
            if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                return false;
            }
        }
        return true;
    }

    isChangeNodeLineStyle() {
        if (this.isFlowChart()) {
            return false;
        }
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return false;
        }
        if (this.mindType == MindType.LINE_MAP) {            
            return true;
        } else if (this.mindType == MindType.BUBBLE_MAP ||
            this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return true;
        } else if (this.mindType == MindType.TIME_MAP) {
            let mainNode = this.timeMindTypeNodeUnit.getNodeById(node.value.id);
            if (!mainNode.isEmpty()) {
                return false;
            }
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                return false;
            }
            if (!parentNode.isEmpty() && parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                return false;
            }
        }
        return true;
    }

    isChangeNodeLineLayout() {//判断当前节点是否可以改变线条布局
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty()) {
            return false;
        }
        if (this.mindType == MindType.LINE_MAP) {
            return true;
        } else if (this.mindType == MindType.BUBBLE_MAP ||
            this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return false;
        } else if (this.mindType == TIME_MAP) {
            let mainNode = this.timeMindTypeNodeUnit.getNodeById(node.value.id);
            if (!mainNode.isEmpty()) {
                return false;
            }
            return true;
        }
        return true;
    }

    getChangeNodeLineLayout() {
        let node = this.getNodeById(this.selectedNodeId);

        if (node.isEmpty()) {
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
                return this.mainMindNodeUnit.globalLineLayout;
            } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                return this.doubleBubbleMindNodeUnit.globalLineLayout;
            } else if (this.mindType == MindType.TIME_MAP) {
                return this.timeMindTypeNodeUnit.globalLineLayout;
            } else {
                return LineLayout.CURVE_LINE;
            }
        }
        if (this.mindType == MindType.TIME_MAP) {
            let timeData = this.timeMindTypeNodeUnit.getNodeById(this.selectedNodeId);
            if (!timeData.isEmpty()) {
                return this.timeMindTypeNodeUnit.globalLineLayout;
            }
        }
        if (node.children.isEmpty()) {
            return LineLayout.CURVE_LINE;
        }
        let nodeChildrenContent = node.children.length;
        for (var index = 0; index < nodeChildrenContent; index++) {
            let child = node.children[index];
            let line = this.getNodeLine(child.value.id);
            if (line.isEmpty() || line[0].lineContent == null) {
                continue;
            }
            return line[0].lineContent.lineLayout;
        }
        return LineLayout.CURVE_LINE;
    }

    getAllImageUrlList(id) {
        let urlList = new Array();
        let list = new Array();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list, true);
            let freeNodesContent = this.freeNodes.length;
            for (var index = 0; index < freeNodesContent; index++) {
                let unit = this.freeNodes[index];
                this.serializeNodeElementsToList(unit.rootTreeNode, list, true);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (!node.isEmpty() &&
                    node.value.imageContent != null &&
                    !new Strings().isEmpty(node.value.imageContent.key) &&
                    !node.value.imageContent.isVideo()) {
                    urlList.push(node.value.imageContent.key);
                }
                let explain = this.getNodeExplain(node.value.id)
                if (!explain.isEmpty() &&
                    explain.imageContent != null &&
                    !new Strings().isEmpty(explain.imageContent.key) &&
                    !explain.imageContent.isVideo()) {
                    urlList.push(explain.imageContent.key);
                }
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.serializeNodeElementsToList(this.doubleBubbleMindNodeUnit.rootTreeNode, list, true);
            this.serializeNodeElementsToList(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode, list, true);
            let freeNodesContent = this.freeNodes.length;
            for (var index = 0; index < freeNodesContent; index++) {
                let unit = this.freeNodes[index];
                this.serializeNodeElementsToList(unit.rootTreeNode, list, true);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (!node.isEmpty() &&
                    node.value.imageContent != null &&
                    !new Strings().isEmpty(node.value.imageContent.key) &&
                    !node.value.imageContent.isVideo()) {
                    urlList.push(node.value.imageContent.key);
                }
                let explain = this.getNodeExplain(node.value.id)
                if (!explain.isEmpty() &&
                    explain.imageContent != null &&
                    !new Strings().isEmpty(explain.imageContent.key) &&
                    !explain.imageContent.isVideo()) {
                    urlList.push(explain.imageContent.key);
                }
            }
            for (var index = 0; index < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; index++) {
                let value = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
                if (!value.isEmpty() &&
                    value.imageContent != null &&
                    !new Strings().isEmpty(value.imageContent.key) &&
                    !value.imageContent.isVideo()) {
                    urlList.push(value.imageContent.key);
                }
                let explain = this.getNodeExplain(value.id)
                if (!explain.isEmpty() &&
                    explain.imageContent != null &&
                    !new Strings().isEmpty(explain.imageContent.key) &&
                    !explain.imageContent.isVideo()) {
                    urlList.push(explain.imageContent.key);
                }
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            let dataList = new Array();
            if (!this.timeMindTypeNodeUnit.title.isHidden) {
                dataList.push(this.timeMindTypeNodeUnit.title)
            }
            let mainMindElementDataDictArray = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys();
            let mainMindElementDataDictLength = mainMindElementDataDictArray.length

            for (let index = 0; index < mainMindElementDataDictLength; index++) {
                let key = mainMindElementDataDictArray[index];
                let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
                if (!node.head.isHidden) {
                    dataList.push(node.head)
                }
                if (!node.title.isHidden) {
                    dataList.push(node.title)
                }
                if (!node.desc.isHidden) {
                    dataList.push(node.desc)
                }
            }
            
            let freeNodesContent = this.freeNodes.length;
            for (var index = 0; index < freeNodesContent; index++) {
                let unit = this.freeNodes[index];
                this.serializeNodeElementsToList(unit.rootTreeNode, list, true);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (!node.isEmpty() && !node.value.isHidden) {
                    dataList.push(node.value)
                }
            }
            for (let index = 0; index < dataList.length; index++) {
                let value = dataList[index];
                if (!value.isEmpty() &&
                    value.imageContent != null &&
                    !new Strings().isEmpty(value.imageContent.key) &&
                    !value.imageContent.isVideo()) {
                    urlList.push(value.imageContent.key);
                }
                let explain = this.getNodeExplain(value.id)
                if (!explain.isEmpty() &&
                    explain.imageContent != null &&
                    !new Strings().isEmpty(explain.imageContent.key) &&
                    !explain.imageContent.isVideo()) {
                    urlList.push(explain.imageContent.key);
                }
            }
        }
        return urlList;
    }

    getAllLatexList() {
        let latexList = new Array();
        let list = new Array();
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            this.serializeNodeElementsToList(this.mainMindNodeUnit.rootTreeNode, list);
            let freeNodesContent = this.freeNodes.length;
            for (var index = 0; index < freeNodesContent; index++) {
                // for (LineMindTypeNodeUnit unit : freeNodes) {
                let unit = this.freeNodes[index];
                this.serializeNodeElementsToList(unit.rootTreeNode, list);
            }
            for (let index = 0; index < list.length; index++) {
                let node = list[index];
                if (!node.isEmpty() &&
                    node.value.latexContent != null) {
                    latexList.push(node);
                }
            }
        }
        return latexList;
    }

    getSubjectSpcaeHorizontal() {
        return this.settingData.subjectSpcaeHorizontal;
    }

    getSubjectSpcaeVertical() {
        return this.settingData.subjectSpcaeVertical;
    }

    getSonSubjectSpcaeHorizontal() {
        return this.settingData.sonSubjectSpcaeHorizontal;
    }

    getSonSubjectSpcaeVertical() {
        return this.settingData.sonSubjectSpcaeVertical;
    }

    getUseTimeLineColor() {
        return this.settingData.useTimeLineColor;
    }

    isLineThicken() {
        return this.settingData.lineThicken;
    }

    isTightMode() {
        return this.settingData.tightMode;
    }

    isSameLevelAlignment() {
        return this.settingData.sameLevelAlignment;
    }

    isNodeOverlap() {
        return this.settingData.isOverlap;
    }

    isLineEndThicken() {
        return this.settingData.lineEndThicken;
    }

    //latex公式相关
    addLatextId(id, text) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || (node.value.textContent == null && node.value.generalizationContent == null)) {
            return;
        }
        if (new Strings().isEmpty(text)) {
            node.value.latexContent = null;
        } else {
            let latexContent = new LatexContent();
            latexContent.text = text;
            node.value.latexContent = latexContent;
        }
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setOrderType(orderType) {
        let node = this.getSelectedNode()
        if (node.isEmpty() || (node.value.textContent == null && node.value.generalizationContent == null)) {
            return;
        }
        if (node.value.orderContent == null || node.value.orderContent.parentNodeOrderLevel != node.value.level) {
            node.value.orderContent = new OrderContent(0, 0);
            node.value.orderContent.parentNodeOrderLevel = node.value.level;
            node.value.orderContent.orderDepth = 1
        }
        node.value.orderContent.orderType = orderType
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    setOrderDepth(depth) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || depth <= 0 || (node.value.textContent == null && node.value.generalizationContent == null)) {
            return;
        }
        if (node.value.orderContent == null || node.value.orderContent.parentNodeOrderLevel != node.value.level) {
            node.value.orderContent = new OrderContent(0, 0);
            node.value.orderContent.parentNodeOrderLevel = node.value.level;
            node.value.orderContent.orderType = OrderType.Order_1
        }
        node.value.orderContent.orderDepth = depth
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    clearOrder() {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.orderContent == null) {
            return;
        }
        node.value.orderContent = null

        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    addLatext(text) {
        this.addLatextId(this.selectedNodeId, text);
    }

    isLineDotted() {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.id == IdGenerator.ROOT_ID) {
            if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
                if (this.mainMindNodeUnit.rootTreeNode != null &&
                    !this.mainMindNodeUnit.rootTreeNode.children.isEmpty()) {
                    let line = this.getNodeLine(this.mainMindNodeUnit.rootTreeNode.children[0].value.id);
                    if (!line.isEmpty() && line[0].lineContent != null) {
                        return line[0].lineContent.dottedLine;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                if (this.doubleBubbleMindNodeUnit.rootTreeNode != null &&
                    !this.doubleBubbleMindNodeUnit.rootTreeNode.children.isEmpty()) {
                    let line = this.getNodeLine(this.doubleBubbleMindNodeUnit.rootTreeNode.children[0].value.id);
                    if (!line.isEmpty() && line[0].lineContent != null) {
                        return line[0].lineContent.dottedLine;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if (this.mindType == MindType.TIME_MAP) {
                return this.timeMindTypeNodeUnit.baseLine != null &&
                    this.timeMindTypeNodeUnit.baseLine.timeLineContent != null &&
                    this.timeMindTypeNodeUnit.baseLine.timeLineContent.dottedLine;
            } else if (this.isFlowChart()) {
                let keys = this.nodeConnectLineDataDict.keys();
                for (let index = 0; index < keys.length; index++) {
                    let line = this.nodeConnectLineDataDict.get(keys[index]);
                    if (line.isEmpty() || line.lineContent == null) {
                        continue;
                    }
                    return line.lineContent.nodeConnectLineDottedLine
                }
            }
        } else {
            let line = this.getNodeLine(node.value.id);
            if (!line.isEmpty() && line[0].lineContent != null) {
                return line[0].lineContent.dottedLine;
            } else {
                return false;
            }
        }
        return false;
    }

    setEncircleTitle(value) {
        let node = this.getSelectedNode();
        if (node.isEmpty() || node.value.lineContent == null) {
            return;
        }
        node.value.lineContent.encircleTitleType = EncircleTitleLayoutType.from(value);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    addChangeDataStyleId(data) {
        if (data.isEmpty() || this.settingData.changeDataStyleId.indexOf(data.id) > -1) {
            return;
        }
        this.settingData.changeDataStyleId.push(data.id)
    }

    isCoverCustomStyle(data) {
        if (data.isEmpty()) {
            return true;
        }
        return this.settingData.changeDataStyleId.indexOf(data.id) > -1
    }

    clearChangeDataStyleId() {
        this.settingData.changeDataStyleId = []
    }

    isIncludeCustomStyle() {
        return this.settingData.changeDataStyleId.length > 0
    }

    setClickPoint(point, id) {
        this.lastClickPoint.setPoint(point, id);
    }

    textAppend(result) {
        let node = this.getNodeById(this.selectedNodeId);
        if (node.isEmpty() || new Strings().isEmpty(result)) {
            return;
        }

        if (node.value.textContent != null) {
            if (this.Util.isEditInputSelected(node.value.textContent.text)) {
                node.value.textContent.text = result;
            } else {
                node.value.textContent.text = node.value.textContent.text + result;
            }

        } else if (node.value.generalizationContent != null) {
            if (this.Util.isEditInputSelected(node.value.generalizationContent.text)) {
                node.value.generalizationContent.text = result;
            } else {
                node.value.generalizationContent.text = node.value.generalizationContent.text + result;
            }

        } else if (node.value.lineContent != null && node.value.lineContent.isContainText()) {
            if (this.Util.isEditInputSelected(node.value.lineContent.textContent.text)) {
                node.value.lineContent.textContent.text = result;
            } else {
                node.value.lineContent.textContent.text = node.value.lineContent.textContent.text + result;
            }
        }

        let size = MindElementCalculation.caluleText(node.value.textContent.text);
        node.value.textContent.width = size.getWidth() + new UiUtil().dip2px(10);
        node.value.textContent.height = size.getHeight() + new UiUtil().dip2px(8);
        this.refresh(true);
        this.willSave(true);
        this.addHostry();
    }

    getMindkey() {
        return this.mindkey;
    }

    isUnlockMindMapStatus() {
        return !(new Strings().isEmpty(this.mindkey)) && this.loadDataModel != null;
    }

    setMindKey(key) {
        if (new Strings().isEmpty(key)) {
            this.mindkey = key;
            this.willSave(false);
            return;
        }
        if (key.length > 8) {
            this.showTipsMessage(getString(strings.Please_Enter_Mind_Password_Format_Error));
            return;
        }
        
        this.mindkey = md5(key);
        this.willSave(false);
    }

    verifyKey(key) {
        if (new Strings().isEmpty(key)) {
            return getString(strings.Please_Enter_Mind_Password_Format_Error);
        } else if (new Strings().equalsIgnoreCase(this.mindkey, md5(key))) {
            if (this.loadDataModel != null) {
                if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
                    this.clearAllDataAndView();
                    this.setMindDataFormCache(this.loadDataModel, false, this.originalDataMap);
                    this.setMindMindDisplay(false);
                    this.addHostry();
                } else if (this.mindType == MindType.TIME_MAP) {
                    this.clearAllDataAndView();
                    this.setTimeLineMindDataFormCache(this.loadDataModel, false, this.originalDataMap);
                    this.addHostry();
                }
                else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                    this.clearAllDataAndView();
                    this.setDoubleBubbleMindDateFormCache(this.loadDataModel, false, this.originalDataMap);
                    this.addHostry();
                }
                this.moveRootNodeCenterDisplay(true);
                return "";
            } else {
                return getString(strings.Mind_Load_Data_Error);
            }
        } else {
            return getString(strings.Please_Enter_Mind_Password_Error);
        }
    }

    setMindPassword() {
        let routeName = this.delegate.$route.name;
        if (routeName == 'gmap') {//共享组
            let userMeData = MindMe.getMeMessage();;
            if (this.requestorType == 1 || this.requestorType == 2 || (userMeData != null && userMeData.userId == this.mindOwnerId)) {
                this.delegate.openMindPasswordPanelForSetting()
            } else {
                this.showTipsMessage(getString(strings.Please_Enter_Mind_Password_For_Owner));
            }
        } else {
            this.delegate.openMindPasswordPanelForSetting()
        }
    }

    isResetViewHorizontal(data) {
        if (data == null || data.isEmpty()) {
            return false
        }
        if (data.type == MindElementType.BAOWEI_VIEW) {
            let targetNode = this.getNodeById(data.parentNodeId)

            if (targetNode.isEmpty()) {
                if (data.lineContent != null && mind.lineContent.targetIds.length > 0) {
                    targetNode = this.getNodeById(mind.lineContent.targetIds[0])
                }
            }
            if (targetNode.isEmpty()) {
                return false
            }
            let targetParentNode = this.getNodeById(targetNode.value.parentNodeId)
            if (targetParentNode.isEmpty()) {
                return false
            } else {
                return targetParentNode.value.layout == NodeLayoutType.LAYOUT_BOTTOM || 
                        targetParentNode.value.layout == NodeLayoutType.LAYOUT_TOP || 
                        targetParentNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                        targetParentNode.value.layout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT
            }
        } else if (data.type == MindElementType.CONTENT_GENERALIZATION && data.generalizationContent != null) {

            if (data.generalizationContent.targetIds.length > 0) {
                let targetNode = this.getNodeById(data.generalizationContent.targetIds[0])
                if (targetNode.isEmpty()) {
                    return false
                }
                let targetParentNode = this.getNodeById(targetNode.value.parentNodeId)
                if (targetParentNode.isEmpty()) {
                    return false
                } else {
                    return targetParentNode.value.layout == NodeLayoutType.LAYOUT_BOTTOM || 
                            targetParentNode.value.layout == NodeLayoutType.LAYOUT_TOP
                }
            } else {
                return false;
            }
        }

        return false;
    }

    isResetViewFree(data) {
        if (data == null || data.isEmpty()) {
            return false
        }
        if (data.type == MindElementType.BAOWEI_VIEW) {
            if (this.isFlowChart()) {
                return true
            }
            let targetNode = this.getNodeById(data.parentNodeId)
            
            if (targetNode.isEmpty()) {
                if (data.lineContent != null && mind.lineContent.targetIds.length > 0) {
                    targetNode = this.getNodeById(mind.lineContent.targetIds[0])
                }
            }
            if (targetNode.isEmpty()) {
                return false
            }
            
            return this.isRootNode(targetNode.value.id)
        }

        return false;
    }

    updateLastChangeTime() {
        this.lastChangeTime = Util.getCurrentTime()
    }

    addRemoveDataIdToSettingData(node) {
        if (node == null || node.isEmpty()) {
            return;
        }
        this.settingData.addRemoveData(node.value.id);
        for (let index = 0; index < node.children.length; index++) {
            this.addRemoveDataIdToSettingData(node.children[index]);
        }
   }
}


export default new EditMindmapVM()