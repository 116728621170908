
import FrameworkCell from "./FrameworkCell"
import NodeLayoutType from "../datatype/NodeLayoutType";
import LineLayout from "../datatype/LineLayout";
import MindElementShapeType from "../datatype/MindElementShapeType";
import TimeLineNodeType from "../datatype/TimeLineNodeType";


class TimeLineFramework {
    constructor(selectedIndex = 0) {
        this.currentSelectedIndex = selectedIndex;
        this.MindMapFrameworkList =
            [new FrameworkCell(0, "mind_skeleton_time_0"), new FrameworkCell(1, "mind_skeleton_time_1"), new FrameworkCell(2, "mind_skeleton_time_2"),
            new FrameworkCell(3, "mind_skeleton_time_3"), new FrameworkCell(4, "mind_skeleton_time_4"), new FrameworkCell(5, "mind_skeleton_time_5"),
            new FrameworkCell(6, "mind_skeleton_time_6"), new FrameworkCell(7, "mind_skeleton_time_7"), new FrameworkCell(8, "mind_skeleton_time_8"),

            new FrameworkCell(9, "mind_skeleton_time_9"), new FrameworkCell(10, "mind_skeleton_time_10"), new FrameworkCell(11, "mind_skeleton_time_11")];
        
            this.mindMapRootLayout = [
                                NodeLayoutType.LAYOUT_VERTICAL, NodeLayoutType.LAYOUT_VERTICAL, NodeLayoutType.LAYOUT_VERTICAL,
                             NodeLayoutType.LAYOUT_HORIZONTAL, NodeLayoutType.LAYOUT_HORIZONTAL, NodeLayoutType.LAYOUT_HORIZONTAL,
                             NodeLayoutType.LAYOUT_HORIZONTAL_S, NodeLayoutType.LAYOUT_HORIZONTAL_S, NodeLayoutType.LAYOUT_TIME_ARC,

                             NodeLayoutType.LAYOUT_TIME_ARC, NodeLayoutType.LAYOUT_HORIZONTAL_CRISSCROSS, NodeLayoutType.LAYOUT_LADDER];

        this.mindMapRootLineLayout = [
            TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR,
            TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD,
            TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER,

            TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER, TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER
        ];

        this.rootNodeShapeType = [
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
        
            MindElementShapeType.Corner, MindElementShapeType.CornerShadow, MindElementShapeType.Two_Underline];

        this.headShapeType = [
            MindElementShapeType.Corner, MindElementShapeType.Corner_Right, MindElementShapeType.Corner,
            MindElementShapeType.Corner_Right, MindElementShapeType.Ring2, MindElementShapeType.Corner,
            MindElementShapeType.Two_Underline, MindElementShapeType.Semicircle, MindElementShapeType.Corner,
         
            MindElementShapeType.Two_Underline, MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner, MindElementShapeType.Ellipse];

        this.titleShapeType = [
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Two_Underline, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Semicircle, MindElementShapeType.Corner,
         
            MindElementShapeType.Two_Underline, MindElementShapeType.Corner, MindElementShapeType.Corner];

        this.descNodeShapeType = [
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,

            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner];

        this.rootNodeBorderWidths =  [1.5, 1.5, 2.5,
                1.5, 2.0, 1.5,
                1.5, 1.5, 1.5,
                
                1.0, 1.0, 2.0]

        this.rootNodeTextSize =  [32, 32, 32,
                32, 32, 32,
                32, 32, 32,
                        
                32, 32, 32]

        this.headBorderWidths =   [2.0, 1.0, 2.0,
                        1.0, 2.0, 0.0,
                        1.0, 2.0, 2.0,
                        
                        2.0, 1.0, 2.0]

        this.titleBorderWidths =   [2.0, 2.0, 2.0,
                        1.0, 0.0, 0.0,
                        1.0, 1.0, 2.0,
                        
                        2.0, 1.0, 2.0]

        this.descBorderWidths = [0.0, 0.0, 0.0,
                        0.0, 0.0, 0.0,
                        0.0, 0.0, 0.0,
                        
                        0.0, 1.0, 0.0]

        this.lineWidths = [  2.0, 10.0, 1.0,
            1.0, 2.0, 2.0,
            10.0, 1.0, 3.0,
            
            3.0, 10.0, 2.0]

        this.rootNodeShowFullColor = [
            false, false, false,
            false, false, false,
            false, false, false,
            
            false, false, false];

        this.rootNodeShowBorderColor = [
            false, false, false,
            false, false, false,
            false, false, false,
            
            false, true, true];

        this.headShowFullColor = [
            false, false, false,
            false, true, false,
            false, true, false,
            
            true, false, true];

        this.headShowBorderColor = [
            false, true, false,
            true, true, false,
            true, true, false,
            
            true, true, true];

        this.titleShowFullColor = [
            false, false, false,
            false, false, false,
            false, false, false,
            
            false, false, false];

        this.titleShowBorderColor = [
            false, false, false,
            true, false, false,
            false, true, false,
            
            true, false, false];

        this.descNodeFullColor = [
            false, false, true,
            false, false, false,
            false, false, false,
            
            false, false, false];

        this.descShowBorderColor = [
            false, false, false,
            false, false, false,
            false, false, false,
            
            false, false, false];

    }

    getCurrentSelectedIndex() {
        return Math.min(Math.max(this.currentSelectedIndex, 0), this.mindMapRootLayout.length - 1);
    }

    getMindMapRootLayout() {
        return this.mindMapRootLayout[this.getCurrentSelectedIndex()]
    }

    getMindMapRootLineLayout() {
        return this.mindMapRootLineLayout[this.getCurrentSelectedIndex()]
    }

    getRootNodeShapeType() {
        return this.rootNodeShapeType[this.getCurrentSelectedIndex()]
    }

    getRootBorderWidths() {
        return this.rootNodeBorderWidths[this.getCurrentSelectedIndex()]
    }

    getRootNodeTextSize() {
        return this.rootNodeTextSize[this.getCurrentSelectedIndex()]
    }

    getHeadShapeType() {
        return this.headShapeType[this.getCurrentSelectedIndex()]
    }

    getHeadBorderWidths() {
        return this.headBorderWidths[this.getCurrentSelectedIndex()]
    }

    getTitleShapeType() {
        return this.titleShapeType[this.getCurrentSelectedIndex()]
    }

    getTitleBorderWidths() {
        return this.titleBorderWidths[this.getCurrentSelectedIndex()]
    }

    getDescNodeShapeType() {
        return this.descNodeShapeType[this.getCurrentSelectedIndex()]
    }

    getDescBorderWidths() {
        return this.descBorderWidths[this.getCurrentSelectedIndex()]
    }

    getLineWidth() {
        return this.lineWidths[this.getCurrentSelectedIndex()]
    }

    isDescNodeFullColor() {
        return this.descNodeFullColor[this.getCurrentSelectedIndex()]
    }

    isDescShowBorderColor() {
        return this.descShowBorderColor[this.getCurrentSelectedIndex()]
    }

    isRootNodeShowFullColor() {
        return this.rootNodeShowFullColor[this.getCurrentSelectedIndex()]
    }

    isRootNodeShowBorderColor() {
        return this.rootNodeShowBorderColor[this.getCurrentSelectedIndex()]
    }

    isHeadShowBorderColor() {
        return this.headShowBorderColor[this.getCurrentSelectedIndex()]
    }

    isHeadShowFullColor() {
        return this.headShowFullColor[this.getCurrentSelectedIndex()]
    }

    isTitleShowBorderColor() {
        return this.titleShowBorderColor[this.getCurrentSelectedIndex()]
    }

    isTitleShowFullColor() {
        return this.titleShowFullColor[this.getCurrentSelectedIndex()]
    }
}



export default TimeLineFramework
