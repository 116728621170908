class VueDataEvent {
    constructor(value) { //Obj
        this.value = value
        this.time = -1;
    }

    setChange() {
        this.time = this.time + 1;
    }

    setValue(value) {
        this.value = value;
    }
}

export default VueDataEvent