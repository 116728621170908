
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

import UiUtil from "../../../utils/UiUtil";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";

class CalculMindElementsRect {
    constructor(mindElements) {
        this.mindElements = mindElements;
    }

    getIncludeMargin() {
        let rect = this.getAbsolute();
        rect.x = rect.x - new UiUtil().dip2px(20);
        rect.y = rect.y - new UiUtil().dip2px(20);
        rect.width(rect.width() + new UiUtil().dip2px(40));
        rect.height(rect.height() + new UiUtil().dip2px(40));
        return rect;
    }

    getAbsolute() {
        let rect = new CGRect();
        let left = 500000;
        let top = 500000;
        let right = 0;
        let bottom = 0;
        let length = this.mindElements.length
        for (let index = 0; index < length; index++) {
            let data = this.mindElements[index];
            if (data.isHidden) {
                continue;
            }
            if (left > data.x) {
                left = data.x;
            }
            if (top > data.y) {
                top = data.y;
            }
            if (right < data.x + data.width) {
                right = data.x + data.width;
            }
            if (bottom < data.y + data.height) {
                bottom = data.y + data.height;
            }
        }

        rect.x = left;
        rect.y = top;
        rect.width(right - left);
        rect.height(bottom - top);
        return rect;
    }
}
export default CalculMindElementsRect
