import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import Util from "../../../utils/Util"
import HashMap from "../base/HashMap"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"
import MindElementData from "../../mindelementdata/MindElementData"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"

class TriangleLayout extends BaseLayout {

    constructor() {
        super()
        // this.NodeVerticalSpacee = Config.NodeVerticalSpacee;
    }

    initConfig() {
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee);
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee);
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee);
    }


    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);

    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - (new UiUtil).getScreenWidth()) / 2;
            let top = (Config.Mind_Height - (new UiUtil).getScreenHeight()) / 2;
            this.rootTreeNode.value.y = ((new UiUtil).getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        this.initConfig();
        this.setElementLine(this.rootTreeNode, isChange);
        this.setSubjectNode();
    }

    setEncirclePoint() {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDict.length; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);

            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
            var targetData = new MindElementData();
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }
                targetData = node.value;
                mind.layout = node.value.layout;

                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;
                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                
                let encircleNodesPointsCalculationSpace = new EncircleNodesPointsCalculation().space;
                if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    if (mind.lineContent.isContainText()) {
                        mind.lineContent.encircleTitleType = EncircleTitleLayoutType.TOP_LEFT;
                    }
                    mind.x = (rect.x);
                    mind.y = (rect.y) - textHeight;
                    mind.width = rect.width() + encircleNodesPointsCalculationSpace + padding;
                    mind.height = rect.height() + textHeight;
                } else {
                    mind.x = rect.x - encircleNodesPointsCalculationSpace - padding;
                    mind.y = rect.y - encircleNodesPointsCalculationSpace - padding - textHeight;
                    mind.width = rect.width() + encircleNodesPointsCalculationSpace * 2 + padding * 2;
                    mind.height = rect.height() + encircleNodesPointsCalculationSpace * 2 + padding * 2 + textHeight;
                }

                let points = new EncircleNodesPointsCalculation().calcule(node, type, padding);
                let encircleNodesPoint = new Array();

                for (let index = 0; index < points.length; index++) {
                    let point = points[index]
                    encircleNodesPoint.push(new Point((point.x) - mind.x, (point.y) - mind.y));
                }
                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                for (let index = 0; index < targetIds.length; index++) {
                    let id = targetIds[index];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }
                    targetData = node.value;
                    mind.layout = node.value.layout;
                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let encircleNodesPointsCalculationSpace = new EncircleNodesPointsCalculation().space;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - encircleNodesPointsCalculationSpace - padding;
                mind.y = rect.y - encircleNodesPointsCalculationSpace - padding - textHeight;
                mind.width = rect.width() + encircleNodesPointsCalculationSpace * 2 + padding * 2;
                mind.height = rect.height() + encircleNodesPointsCalculationSpace * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    if (!targetData.isEmpty() && targetData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                        let startX = Util.tan(30) * (mind.height - textHeight);
                        mind.lineContent.textContent.x = startX - mind.lineContent.textContent.width;
                    } else {
                        mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                    }
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        data.layout = NodeLayoutType.LAYOUT_RIGHT;
        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            let allSiblingsNodesHeight = this.getSiblingsNodesHeight(siblingsNodeDatas);
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            let topNodeHeight = 0;
            if (allSiblingsNodesPosition > 0) {
                for (var j = 0; j < allSiblingsNodesPosition; j++) {
                    let treeHeight = this.getTreeHeight(this.getNodeById(siblingsNodeDatas[j].id));
                    topNodeHeight += treeHeight + (siblingsNodeDatas[j].type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                }
            }
            let dataTreeHeight = this.getTreeHeight(this.getNodeById(data.id));
            data.y = parentNodeData.y - (allSiblingsNodesHeight - this.getNodeHeight(parentNodeData)) / 2 + topNodeHeight + (dataTreeHeight - data.height) / 2;
            if (parentNodeData.layout == NodeLayoutType.LAYOUT_TRIANGLE &&
                    this.rootTreeNode.isChildNode(parentNode)) {
                data.x = this.rootTreeNode.children[0].value.x + this.rootTreeNode.children[0].value.width + (data.type == MindElementType.SON_SUBJECT ?  this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee);
            } else {
                data.x = parentNodeData.x + parentNodeData.width + (data.type == MindElementType.SON_SUBJECT ?  this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee);
            }
        }
        this.setBorderThicken(node);
        this.setElementLine(node, isChange);
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (parentNodeData.isEmpty()) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_RIGHT;
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            if (this.isUnderline(data)) {
                lineData.y = data.y + data.height;
            } else {
                lineData.y = data.y + data.height / 2;
            }
            if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                lineData.x = parentNodeData.x + parentNodeData.width / 2 + (this.isFromLeftRightLayout ? (parentNodeData.width * 0.1) : 0);
            } else if (lineLayout == LineLayout.CURVE_LINE) {
                lineData.x = parentNodeData.x + parentNodeData.width - Config.Curve_Line_2_Node_Left;
                if (parentNodeData.width / 2 < Config.Curve_Line_2_Node_Left) {
                    lineData.x = parentNodeData.x + parentNodeData.width / 2 + Config.Curve_Line_Node_Left;
                }
            } else {
                lineData.x = parentNodeData.x + parentNodeData.width;
            }

            lineData.width = data.x - lineData.x;
            lineData.height = parentNodeData.y - lineData.y + parentNodeData.height / 2;
            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = lineLayout;

        } else {
            lineData.type = MindElementType.SON_LINE;
            let startX = parentNodeData.x + parentNodeData.width;
            if (parentNodeData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                startX = parentNodeData.x + parentNodeData.width - (parentNodeData.height/2.0 * Util.tan(30.0));
            }
            if (!this.isUnderline(parentNodeData)) {
                lineData.y = parentNodeData.y + parentNodeData.height;
                lineData.x = startX;
            } else {
                lineData.y = parentNodeData.y + parentNodeData.height / 2;
                lineData.x = startX;
            }

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (this.isUnderline(data)) {
                lineData.height = data.y - lineData.y + data.height;
                lineData.width = data.x - lineData.x;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
                lineData.width = data.x - lineData.x;
            }
            let space = this.UiUtil.dip2px(15);
            if (lineLayout == LineLayout.STRAIGHT_LINE ||
                    lineLayout == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - space,  lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE ||
                    lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    lineLayout == LineLayout.STRAIGHT_CURVE_LINE ) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width  - lineData.width/6 ,  lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE_2 ||
                    lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width,  lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - space,  lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = lineLayout;

            // lineData.type = MindElementType.SON_LINE;

            // if (parentNodeData.type == MindElementType.SON_SUBJECT) {
            //     lineData.y = data.y + data.height;
            //     lineData.x = parentNodeData.x + parentNodeData.width;
            //     lineData.width = data.x - lineData.x + data.width;
            //     lineData.height = (parentNodeData.y + parentNodeData.height) - lineData.y;
            // } else {
            //     if (!this.isUnderline(parentNodeData)) {
            //         lineData.y = data.y + data.height;
            //         lineData.x = parentNodeData.x + parentNodeData.width;
            //         lineData.width = data.x - lineData.x + data.width;
            //         lineData.height = (parentNodeData.y + parentNodeData.height) - lineData.y;
            //     } else {
            //         lineData.y = data.y + data.height;
            //         lineData.x = parentNodeData.x + parentNodeData.width;
            //         lineData.width = data.x - lineData.x + data.width;
            //         lineData.height = (parentNodeData.y + parentNodeData.height / 2) - lineData.y;
            //     }
            // }

            // let curveStartPoint = new Point(0, lineData.height);
            // let curveEndPoint = new Point(lineData.width - data.width, 0);
            // let straightEndPoint = new Point(lineData.width, 0);
            // if (lineData.lineContent == null) {
            //     lineData.lineContent = new LineElementContent();
            //     lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);

            // } else {
            //     lineData.lineContent.setCurveStartPoint(curveStartPoint);
            //     lineData.lineContent.setCurveEndPoint(curveEndPoint);
            //     lineData.lineContent.setStraightEndPoint(straightEndPoint);
            // }
            // lineData.lineContent.orientation = LineOrientation.RIGHT;
            // lineData.lineContent.lineLayout = lineLayout;
        }

        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineNodeContent.color = referenceLine.lineContent.color;
                lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
            let len = parentNode.children.length
            if (len > 1) {
                for (let index = 0; index < len; index++) {
                    let cell = parentNode.children[index]
                    if (cell.value.id != node.value.id) {
                        let brotherLine = this.getLineData(cell);
                        lineNodeContent.lineThicken = brotherLine.isEmpty() ? false : brotherLine.lineContent.lineThicken;
                        return
                    }
                }
            }
        }
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden) {
                continue
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let right = 0;
            MindElementCalculation.set(node.value).calcule();
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);
                if (targetNode.isEmpty()) {
                    continue
                }
                let targetLeft = targetNode.getNodeLeft();
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetLeft + this.getTreeWidth(targetNode) - this.getTreeWidth(node) -
                    Config.GeneralizationLineWidth - Config.GeneralizationSpacing * 2;

                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
            }
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            mindElementData.x = right + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2;
            this.setGeneralizationElementLine(node, isChange);

            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsPoint(node.children[childrenIndex], isChange);
            }
        }
    }

    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x - Config.GeneralizationLineWidth - Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;


        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineData.lineContent.color = referenceLine.lineContent.color;
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let height = this.getNodeHeight(node.value);
        let chlidHeight = 0;
        let nodeGeneralizationHeight = this.getNodeGeneralizationHeight(node);

        height = Math.max(height, nodeGeneralizationHeight);
        let len = node.children.length;
        for (let index = 0; index < len; index++) {
            let chlid = node.children[index];
            let verticalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            if (!chlid.children.length == 0) {
                chlidHeight += this.getTreeHeight(chlid) + (chlidHeight > 0 ? verticalSpacee : 0);
            } else {
                if (chlid.value.isHidden) {
                    continue
                }
                let nodeHeight = this.getNodeHeight(chlid.value);
                let chlidGeneralizationHeight = this.getNodeGeneralizationHeight(chlid);
                nodeHeight = Math.max(nodeHeight, chlidGeneralizationHeight);
                chlidHeight += nodeHeight + (chlidHeight > 0 ? verticalSpacee : 0);
            }
        }
        return Math.max(chlidHeight, height);
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let width = node.value.width;
        let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
        nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + this.GeneralizationLineWidth + this.GeneralizationSpacing * 2 : 0;
        width = width + nodeGeneralizationWidth;
        let siblingsMaxWidth = 0;
        for (let index = 0; index < node.children.length; index++) {
            let chlidWidth = 0;
            let horizontalSpacee = node.children[index].value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
            if (!node.children[index].children.isEmpty()) {
                chlidWidth += this.getTreeWidth(node.children[index]) + horizontalSpacee;
            } else {
                if (node.children[index].value.isHidden) {
                    continue
                }
                let nodeidth = node.children[index].value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(node.children[index]);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + this.GeneralizationLineWidth + this.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth + horizontalSpacee;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
        }
        return siblingsMaxWidth + width;
    }

    getSiblingsNodesHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        let height = this.getTreeHeight(this.getNodeById(nodes[0].id));
        if (nodes.length > 1) {
            for (var index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue;
                }
                height += this.getTreeHeight(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
            }
        }
        return height;
    }

    setSubjectNode() {
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }
        let rootChildrenSize = this.rootTreeNode.children.length;
        for (let index = 0; index < rootChildrenSize; index++) {
            let subjectNode = this.rootTreeNode.children[index];
            if (subjectNode.value.mindElementShape != MindElementShapeType.Corner) {
                subjectNode.value.mindElementOriginalShape = subjectNode.value.mindElementShape;
                subjectNode.value.mindElementShape = MindElementShapeType.Corner;
            }
            MindElementCalculation.set(subjectNode.value).calcule();
            let treeHeight = this.getTreeHeight(subjectNode) + this.UiUtil.dip2px(20);
            if (treeHeight > subjectNode.value.height) {
                this.setNodeHeight(subjectNode, treeHeight - subjectNode.value.height, true);
            }
        }

        let height = ((this.rootTreeNode.children[0].value.width / 2) * Math.tan(Util.toRadians(60))) + this.getMindBrotherAndSelfHeight(this.rootTreeNode.children[0].value);;
        let map = new HashMap();
                
        map.put(this.rootTreeNode.children[0].value.id, height);
        for (let index = 1; index < rootChildrenSize; index++) {
            let subjectNode = this.rootTreeNode.children[index];
            let brotherAndSelfHeight = this.getMindBrotherAndSelfHeight(subjectNode.value);
            let nodeTriangleheight = ((subjectNode.value.width / 2) * Math.tan(Util.toRadians(60))) + brotherAndSelfHeight;
            map.put(subjectNode.value.id, nodeTriangleheight);
            if (nodeTriangleheight > height) {
                height = nodeTriangleheight;
            }
        }
        this.rootTreeNode.value.layout = NodeLayoutType.LAYOUT_RIGHT;
        let rootNodeWidth = (height * Math.tan(Util.toRadians(30))) * 2;
        this.setNodeWidth(this.rootTreeNode.children[0], rootNodeWidth - this.rootTreeNode.children[0].value.width);
        let rootSpace = this.UiUtil.dip2px(20);
        for (let index = 0; index < rootChildrenSize; index++) {
            let subjectNode = this.rootTreeNode.children[index];
            let mindBrotherAndSelfHeight = this.getMindBrotherAndSelfHeight(subjectNode.value);
            let nodeWidth = ((height - mindBrotherAndSelfHeight + subjectNode.value.height) * Math.tan(Util.toRadians(30))) * 2;
            this.setNodeWidth(subjectNode, nodeWidth - subjectNode.value.width);
            if (index == rootChildrenSize - 1) {
                this.setNodeHeight(subjectNode, height - mindBrotherAndSelfHeight, false);
                subjectNode.value.y = this.rootTreeNode.value.y - (height - this.rootTreeNode.value.height) - rootSpace;
            } else {
                subjectNode.value.y = this.rootTreeNode.value.y - (mindBrotherAndSelfHeight - this.rootTreeNode.value.height) - rootSpace;
            }
            subjectNode.value.x = (this.rootTreeNode.value.x + this.rootTreeNode.value.width / 2) - subjectNode.value.width / 2;
            subjectNode.value.layout = NodeLayoutType.LAYOUT_TRIANGLE;
            for (let j = 0; j < subjectNode.children.length; j++) {
                this.setNodeElementsPoint(subjectNode.children[j], true);
            }
        }

        // if (this.rootTreeNode.value.mindElementShape != MindElementShapeType.Corner) {
        //     this.rootTreeNode.value.mindElementOriginalShape = this.rootTreeNode.value.mindElementShape;
        //     this.rootTreeNode.value.mindElementShape = MindElementShapeType.Corner;
        // }
        // let rootChildrenSize = this.rootTreeNode.children.length;
        // MindElementCalculation.set(this.rootTreeNode.value).calcule();
        // for (let index = 0; index < rootChildrenSize; index++) {
        //     let subjectNode = this.rootTreeNode.children[index];
        //     if (subjectNode.value.mindElementShape != MindElementShapeType.Corner) {
        //         subjectNode.value.mindElementOriginalShape = subjectNode.value.mindElementShape;
        //         subjectNode.value.mindElementShape = MindElementShapeType.Corner;
        //     }
        //     MindElementCalculation.set(subjectNode.value).calcule();
        //     let treeHeight = this.getTreeHeight(subjectNode);
        //     if (treeHeight > subjectNode.value.height) {
        //         this.setNodeHeight(subjectNode, treeHeight - subjectNode.value.height, true);
        //     }
        // }

        // let height = ((this.rootTreeNode.value.width / 2) * Math.tan(Util.toRadians(60))) + this.getMindBrotherAndSelfHeight(this.rootTreeNode.value);
        // let map = new HashMap();
        // map.put(this.rootTreeNode.value.id, height);
        // for (let index = 0; index < rootChildrenSize; index++) {
        //     let subjectNode = this.rootTreeNode.children[index];
        //     let brotherAndSelfHeight = this.getMindBrotherAndSelfHeight(subjectNode.value);
        //     let nodeTriangleheight = ((subjectNode.value.width / 2) * Math.tan(Util.toRadians(60))) + brotherAndSelfHeight;
        //     map.put(subjectNode.value.id, nodeTriangleheight);
        //     if (nodeTriangleheight > height) {
        //         height = nodeTriangleheight;
        //     }
        // }
        // this.rootTreeNode.value.layout = NodeLayoutType.LAYOUT_TRIANGLE;
        // let rootNodeWidth = (height * Math.tan(Util.toRadians(30))) * 2;
        // this.setNodeWidth(this.rootTreeNode, rootNodeWidth - this.rootTreeNode.value.width);
        // for (let index = 0; index < rootChildrenSize; index++) {
        //     let subjectNode = this.rootTreeNode.children[index];
        //     let mindBrotherAndSelfHeight = this.getMindBrotherAndSelfHeight(subjectNode.value);
        //     let nodeWidth = ((height - mindBrotherAndSelfHeight + subjectNode.value.height) * Math.tan(Util.toRadians(30))) * 2;
        //     this.setNodeWidth(subjectNode, nodeWidth - subjectNode.value.width);
        //     if (index == rootChildrenSize - 1) {
        //         this.setNodeHeight(subjectNode, height - mindBrotherAndSelfHeight, false);
        //         subjectNode.value.y = this.rootTreeNode.value.y - (height - this.rootTreeNode.value.height);
        //     } else {
        //         subjectNode.value.y = this.rootTreeNode.value.y - (mindBrotherAndSelfHeight - this.rootTreeNode.value.height);
        //     }
        //     subjectNode.value.x = (this.rootTreeNode.value.x + this.rootTreeNode.value.width / 2) - subjectNode.value.width / 2;
        //     subjectNode.value.layout = NodeLayoutType.LAYOUT_TRIANGLE;
        //     for (let j = 0; j < subjectNode.children.length; j++) {
        //         this.setNodeElementsPoint(subjectNode.children[j], true);
        //     }
        // }
    }

    getMindBrotherAndSelfHeight(mindElementData) {
        let height = this.rootTreeNode.value.height;
        if (this.rootTreeNode.value.id == mindElementData.id) {
            return height;
        }
        let rootChildrenSize = this.rootTreeNode.children.length;
        for (let index = 0; index < rootChildrenSize; index++) {
            height += this.rootTreeNode.children[index].value.height;
            if (this.rootTreeNode.children[index].value.id == mindElementData.id) {
                break;
            }
        }
        return height;
    }

    changeLayoutToTriangle(node) {
        if (!node.isEmpty()) {
            node.value.mindElementOriginalShape = node.value.mindElementShape;
            node.value.mindElementShape = MindElementShapeType.Corner;
            if (node.value.mindElementOriginalShape != MindElementShapeType.Corner) {
                MindElementCalculation.set(node.value).calcule();
            }
        }
        let childrenLen = node.children.length
        for (let index = 0; index < childrenLen; index++) {
            let item = node.children[index]
            this.changeLayoutToTriangle(item);
        }
    }

    changeLayoutFromTriangle(node) {
        if (!node.isEmpty()) {
            if (node.value.mindElementShape != node.value.mindElementOriginalShape) {
                node.value.mindElementShape = node.value.mindElementOriginalShape;
                MindElementCalculation.set(node.value).calcule();
            }
        }
        let childrenLen = node.children.length;
        for (let index = 0; index < childrenLen; index++) {
            let item = node.children[index]
            this.changeLayoutFromTriangle(item);
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_TRIANGLE;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default TriangleLayout