
import MapSaveData from "./MapSaveData";
import ImageFileData from "./ImageFileData";
import FileData from "./FileData";
import Config from "../../../../core/core/calcule/Config";
import MindMapZipConstant from "./MindMapZipConstant";
import JSZip from "jszip";
import MindType from "../../../datatype/MindType";
import MindContentData from "./MindContentData";
import MindMapSheetData from "./MindMapSheetData";
import HttpServerConfig from "../../../../common/HttpServerConfig";
import { md5 } from "../../../../common/netWork/base";
import HashMap from "../../base/HashMap";
import MindmapDataRouter from "../../../facade/MindmapDataRouter";
import Util from "../../../../utils/Util";
import LoadLocalXJMind from "./LoadLocalXJMind";
import { postMindmapNew, postMindmapNewSheet } from "../../../../common/netWork/mind_map_api";
import XJMindFIleImport from "../../../../core/core/dataformat/fileImport/XJMindFIleImport";

if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}

/**
 * ProjectName: MindMap
 * Created by tony on 2022/7/5
 * Copyright(c) 2022 mindyushu.com
 */

class SaveMindMapToLocal {
    constructor() {
        this.isGroupMindMap = false
        this.vueView = null
        this.MaxNameLength = 16
        this.saveData = new MapSaveData()
        this.sheetMap = new HashMap()
        this.isSaveingDataToLocal = false;
        this.sheetTotal = 0;
        this.mindServerId = "";
        this.mindSheetId = "";
        this.currentMindContent = "";
        this.currentMindType = MindType.LINE_MAP;
        this.isSaveAs = false;
        this.isUploadMindmap = false;
        this.openDirectoryDialogIndex = 0;
        this.name = "";
    }

    reset() {
        this.saveData = new MapSaveData();
        this.vueView = null;
        this.sheetMap = new HashMap()
        this.initData();
        this.isSaveingDataToLocal = false;
        this.mindServerId = "";
        this.mindSheetId = "";
        this.mindGroupServerId = "";
        this.isSaveAs = false
        this.isUploadMindmap = false;
        this.isGroupMindMap = false;
        this.name = "";
    }

    setNameAndPath(name, path) {
        this.initData();
        if (name == null || name.length == 0) {
            return;
        }
        if (name.length > this.MaxNameLength) {
            this.saveData.name = name.substring(0, this.MaxNameLength).replace(/\n|\r/g,"");
        } else {
            this.saveData.name = (name + "").replace(/\n|\r/g,"");;
        }
        this.saveData.path = path;
        this.name = name;
    }


    setTemporaryStorageName(name) {
        this.initData();
        if (name == null || name.length == 0) {
            return;
        }
        this.saveData.name = name;
        this.saveData.path = LoadLocalXJMind.getAppPath();
    }

    isLocalMind() {
        return this.getLocalPath() != null && this.getLocalPath().length > 0
    }

    getLocalPath() {
        return LoadLocalXJMind.getLocalPath()
    }

    save(view) {
        this.vueView = view
        let that = this;
        this.isSaveingDataToLocal = true;
        this.isUploadMindmap = false;
        return new Promise(resolve => {
            console.log("save-> start");
            that.getSheetPaginationList().then((self) => {
                self.getAllMindData(self).then((success) => {
                    self.isSaveingDataToLocal = false;
                    if (success) {
                        if (self.vueView != null && !self.isSaveAs) {
                            new XJMindFIleImport(null, null, self.vueView.$router.appPath).addHistory(
                                LoadLocalXJMind.getPath(), 
                                LoadLocalXJMind.getLocalPath(), 
                                self.saveData.name, 
                                self.mindServerId)
                        }
                    }
                    resolve(success)
                })
            })
        });
    }

    temporarySave(view) {
        this.vueView = view
        let that = this;
        this.isSaveingDataToLocal = true;
        this.isUploadMindmap = false;
        return new Promise(resolve => {
            let sheetData = new MindContentData();
            let fileName = that.mindServerId + ".json"
            sheetData.setName(that.name);
            sheetData.setFileName(fileName, false)
            sheetData.id = that.mindServerId;
            sheetData.mindType = that.currentMindType;
            sheetData.lastchangetime = Util.getCurrentTime()
            sheetData.content = that.currentMindContent

            
            const contentData = sheetData.getContentJson();
            if (contentData == null || contentData.length == 0) {
                resolve(false)
                return
            }
            if (that.vueView.$tools.isElectron()) {
                let path = LoadLocalXJMind.getAppPath() + MindMapZipConstant.MindZIPLocalFilesFolderName + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentTemporaryFolderName
                let contentDataLocalPath = path
                that.saveLocalContent(contentDataLocalPath, fileName, contentData)
                resolve(true)
            } else {
                try {
                    let currentKey = Util.getCurrentTime() + "|" + that.mindServerId
                    let currentStorageKey = ""
                    let cache = 0
                    let isContain = false
                    let list = []
                    for(let key in localStorage) {
                        if (localStorage.hasOwnProperty(key)) {
                            cache += localStorage.getItem(key).length
                        }
                        if (key.length == currentKey.length && key.indexOf("|") > 0) {
                            let keyArr = key.split("|")
                            if (keyArr.length != 2) {
                                continue
                            }
                            if (keyArr[1] == that.mindServerId) {
                                currentStorageKey = key
                                isContain = true
                            }
                            let time = parseInt(keyArr[0])
                            let cell = {key: key, time: time, id: keyArr[1]}
                            list.push(cell)
                        }
                    }
                    list.sort(function (data1, data2) {
                        if (data1.time == data2.time) {
                            return 0;
                        } else if (data1.time < data2.time) {
                            return -1;
                        } else {
                            return 1;
                        }
                    })

                    let size = (cache / 1024).toFixed(2)
                    if (currentStorageKey.length > 0) {
                        localStorage.removeItem(currentStorageKey)
                    }
                    if (size >= 1024 * 4) {
                        let limitSize = list.length > 6 ? list.length * 0.3 : list.length / 2
                        for (let index = 0; index < limitSize; index++) {
                            const cell = list[index];
                            if (currentStorageKey != cell.key) {
                                localStorage.removeItem(cell.key)
                            }
                        }
                        localStorage.setItem(currentKey, contentData)
                    } else {
                        localStorage.setItem(currentKey, contentData)
                    }
                } catch (error) {
                    console.log("localStorage save mind data->", error);
                }
            }
        });
    }

    uploadMindmap(view) {
        let that = this;
        this.vueView = view
        this.isSaveingDataToLocal = true;
        this.isUploadMindmap = true;
        return new Promise(resolve => {
            that.getSheetPaginationList().then((self) => {
                self.getAllMindData(self, false).then((success) => {
                    if (success) {
                        that.startMindmap().then((success) => {
                            if (success) {
                                self.uploadMindmapResources(()=> {
                                    let contentJson = self.saveData.getContentListJson();
                                    if (contentJson != null && contentJson.length > 0 && that.getLocalPath() != null && that.getLocalPath().length > 0) {
                                        var contentJsonPath = that.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentPath
                                        self.saveLocalContent(contentJsonPath, "", contentJson)
                                    }
                                    self.saveToPath(LoadLocalXJMind.getPath(), resolve)
                                    self.isSaveingDataToLocal = false;
                                })
                            } else {
                                self.isSaveingDataToLocal = false;
                                resolve(success)
                            }
                            
                        })
                    } else {
                        self.isSaveingDataToLocal = false;
                        console.log("getAllMindData-> fail");
                        resolve(false)
                    }
                })
            })
        });
    }

    startMindmap() {
        let that = this;
        return new Promise(async resolve => {
            let count = that.saveData.contentList.length
            if (count == 0) {
                resolve(false)
                return;
            }
            let mainMindmap = this.saveData.contentList[0];
            if (!LoadLocalXJMind.isCloudStorage(mainMindmap.id)) {
                that.newMindmap(mainMindmap).then(async (success) => {
                    if (success) {
                        if (count > 1) {
                            var error = false
                            for (let index = 1; index < count; index++) {
                                const element = that.saveData.contentList[index];
                                let isUpload = await that.newSheetMindmap(element, mainMindmap.id)
                                if (!isUpload) {
                                    error = true
                                    break
                                }
                            }
                            if (!error) {
                                that.updateRouter()
                                resolve(true)
                            } else {
                                resolve(false)
                            }

                        } else {
                            that.updateRouter()
                            resolve(true)
                        }
                    } else {
                        console.log("newMindmap -> fail");
                        resolve(false)
                    }
                })
            } else {
                resolve(true)
            }
            
        })
    }

    updateRouter() {
        if (this.vueView == null || this.mindServerId == null || this.mindServerId.length == 0) {
            return;
        }
        if (this.mindSheetId != null && this.mindSheetId.length > 0) {
            this.vueView.$router.replace({
                query: {
                  id: this.mindServerId,
                  sheetId: this.mindSheetId,
                  path: encodeURIComponent(LoadLocalXJMind.getPath()),
                  localpath: encodeURIComponent(LoadLocalXJMind.getLocalPath()),
                  isreload: false,
                },
              });
        } else {
            this.vueView.$router.replace({
                query: {
                  id: this.mindServerId,
                  path: encodeURIComponent(LoadLocalXJMind.getPath()),
                  localpath: encodeURIComponent(LoadLocalXJMind.getLocalPath()),
                  isreload: false,
                },
              });
        }
        
    }

    uploadMindmapResources(callback) {
        let that = this;
        return new Promise(async resolve => {
            let count = that.saveData.contentList.length
            for (let index = 0; index < count; index++) {
                const cell = that.saveData.contentList[index];
                let sheetData = that.getSheetData(cell.id)
                if (sheetData != null) {
                    var filePath = that.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName
                    var jsonFullPath = that.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentFolderName + MindMapZipConstant.FileSeparator + cell.fileName
                    let success = await sheetData.uploadMindmapResources(filePath, jsonFullPath)
                }
            }
            callback()
        });
    }

    newMindmap(data) {
        let that = this;
        return new Promise(resolve => {
            if (data.content == null || data.content.length == 0) {
                resolve(false)
            } else {
                const obj = {
                    parentEntityId: '',
                    name: data.name + "",
                    content: data.content,
                    type: data.mindType,
                    cover: '',
                    subtitle: '',
                }
                postMindmapNew(
                    obj,
                    (res) => {
                        let mindId = res.id;
                        if (that.vueView != null) {
                            if (that.vueView.initMindSheetDatas != null) {//更新分页ID
                                for (let index = 0; index < that.vueView.initMindSheetDatas.length; index++) {
                                    const sheet = that.vueView.initMindSheetDatas[index];
                                    if (sheet != null && sheet.id == LoadLocalXJMind.getRootId()) {
                                        sheet.id = mindId
                                    }
                                    sheet.rootId = mindId
                                }
                            }
                            if (LoadLocalXJMind.getPath() == MindMapZipConstant.MindZIPNewPathe) {
                                new XJMindFIleImport(null, null, that.vueView.$router.appPath).
                                removeHistory(LoadLocalXJMind.getPath(), LoadLocalXJMind.getLocalPath(), LoadLocalXJMind.getName())//移除本地历史记录ID
                            } else {
                                new XJMindFIleImport(null, null, that.vueView.$router.appPath).
                                changeHistoryItemId(LoadLocalXJMind.getPath(), LoadLocalXJMind.getLocalPath(), LoadLocalXJMind.getRootId(), mindId)//更新历史记录ID
                            }
                        }
                        LoadLocalXJMind.setRootId(mindId, true)//更新已加载本地缓存rootId
                        that.replaceSheetId(data.id, mindId)
                        resolve(true)
                    },
                    (error) => {
                        console.log("newMindmap-> ", error);
                        resolve(false) //TODO 空间不足提醒
                    }
                );
            }
        })
    }

    newSheetMindmap(data, rootId) {
        let that = this;
        return new Promise(resolve => {
            if (data.content == null || data.content.length == 0) {
                resolve(false)
            } else {
                let name = data.name
                let type = data.mindType;
                let mindDisplayType = 1
                let nodeLayoutType = 1
                let lineLayout = 1
                let styleIndex = 3
                let content = data.content;
                let imageCover = "";
                let subtitle = "";
                let modifyTime = Math.round(new Date().getTime() / 1000);
                let mindGlobalAudio = "";
                let beforeIndex = -1;
                let obj = {
                    rootId,
                    name,
                    beforeIndex,
                    type,
                    mindDisplayType,
                    nodeLayoutType,
                    lineLayout,
                    styleIndex,
                  };
                postMindmapNewSheet(
                    obj,
                    (res) => {
                        let mindId = res.id;
                        that.replaceSheetId(data.id, mindId)
                        let canvansData = { mindMapId:rootId, sheetId: mindId, name, content, cover: imageCover, 
                            subtitle, audio: mindGlobalAudio, modifyTime }

                        MindmapDataRouter.postUploadMindmap(canvansData, imageCover => {
                            resolve(true)
                        }, error => {
                            resolve(false) //TODO 空间不足提醒
                        })
                        resolve(true)
                    },
                    (error) => {
                        console.log("obj error-> ", error);
                        resolve(false) //TODO 空间不足提醒
                    }
                );
            }
        })
    }
    
    setContents(value, mindType, mindServerId, sheetId, isSaveAs = false, isGroupMindMap = false) {
        this.initData();
        if (value == null || value.length == 0) {
            return;
        }
        this.isSaveAs = isSaveAs;
        this.mindServerId = mindServerId;
        this.mindSheetId = sheetId;
        this.currentMindContent = value;
        this.currentMindType = mindType;
        this.isGroupMindMap = isGroupMindMap;
    }
    
    onComplete(success, self) {
        self.isSaveingDataToLocal = false;
    }

    getAllMindData(self, saveLocal = true) {
        return new Promise(resolve => {
            if (self.saveData.contentList.length == 0) {
                resolve(false, self)
            }
            self.loadMindMapData(self.saveData.contentList[0]).then((success) => {
                if (success) {
                    for (let index = 0; index < self.saveData.contentList.length; index++) {
                        const cell = self.saveData.contentList[index];
                        const element = self.getSheetData(cell.id)
                        if (element == null) {
                            continue
                        }
                        cell.content = element.getMindJson();
                    }
                    var resourceUrlList = [];
                    for (let index = 0; index < this.saveData.contentList.length; index++) {
                        const cell = this.saveData.contentList[index];
                        const element = this.getSheetData(cell.id)
                        if (element == null) {
                            continue
                        }
                        let list = element.getMindResourceList();
                        resourceUrlList = resourceUrlList.concat(list);
                    }
                    if (resourceUrlList.length > 0) {
                        this.loadResourceData(resourceUrlList).then((success) => {
                            if (saveLocal) {
                                this.saveToPath(LoadLocalXJMind.getPath(), resolve)
                            } else {
                                resolve(true)
                            }
                        });
                    } else {
                        if (saveLocal) {
                            this.saveToPath(LoadLocalXJMind.getPath(), resolve)
                        } else {
                            resolve(true)
                        }
                    }
                } else {
                    // console.log("this.mindSheetList error-> ", self.saveData.contentList);
                    resolve(false)
                }
            });
        });
    }
    
    loadResourceList() {

        var resourceUrlList = [];
        for (let index = 0; index < this.saveData.contentList.length; index++) {
            const cell = this.saveData.contentList[index];
            const element = this.getSheetData(cell.id)
            if (element == null) {
                continue
            }
            let list = element.getMindResourceList();
            resourceUrlList = resourceUrlList.concat(list);
        }

        if (resourceUrlList.length > 0) {
            this.loadResourceData(resourceUrlList).then((success) => {
                this.saveToPath(LoadLocalXJMind.getPath(), this.onComplete)
            });
        } else {
            this.saveToPath(LoadLocalXJMind.getPath(), this.onComplete)
        }
    }

    loadResourceData(list) {
        var that = this
        return new Promise(async resolve => {
            for (let index = 0; index < list.length; index++) {                
                const url = list[index];
                let srcHttp = HttpServerConfig.getMediaSrc(url)
                let blob = await LoadLocalXJMind.loadResourceData(url);
                if (blob == null) {
                    continue
                }
                let fileData = new FileData()
                let type = Util.getUrlType(url)
                
                if (type.length > 0) {
                    type = "." + type
                }
                fileData.name = md5(url) + type
                fileData.content = blob
                fileData.path = url
                that.saveData.files.push(fileData)
            }
            resolve(true);
        });
    }

    loadMindMapData(data) {
        var that = this
        return new Promise(resolve => {
            if (data == undefined || data == null) {
                return resolve(false)
            }
            let mindMapSheetData = that.getSheetData(data.id)
            if (mindMapSheetData == null) {
                return resolve(false)
            }
            if (!mindMapSheetData.isValid()) {
                return that.loadServerData(data).then((success) => {
                    resolve(success);
                })
            } else {
                let length = that.saveData.contentList.length;
                for (let index = 0; index < length; index++) {
                    const element = that.saveData.contentList[index];
                    if (element.id == data.id) {
                        if (index < length-1) {
                                this.loadMindMapData(that.saveData.contentList[index + 1]).then((success) => {
                                
                                if (!success) {
                                    return resolve(false);
                                } else {
                                    return resolve(true);
                                }
                            });
                        } else if (index == length-1) {
                            return resolve(true);
                        }
                        break;
                    }
                }
            }
        });
    }

    loadServerData(data) {
        var that = this
        return new Promise(resolve => {
            MindmapDataRouter.postGetMindmap({ id: that.mindServerId, sheetId: data.id, path: LoadLocalXJMind.getPath()}, (res) => {
                if (res.content != null && res.content.length > 0) {
                    let mindMapSheetData = that.getSheetData(data.id)
                    if (mindMapSheetData == null) {
                        return resolve(false)
                    }
                    let mindType = res.type
                    if (mindType == null && res.mindType != null) {
                        mindType = res.mindType
                    }
                    mindMapSheetData.mindType = mindType;
                    data.mindType = mindType
                    mindMapSheetData.loadData(res.content, mindType)
                    let length = that.saveData.contentList.length;
                    for (let index = 0; index < length; index++) {
                        const element = that.saveData.contentList[index];
                        if (element.id == data.id) {
                            if (index < length-1) {
                                    that.loadMindMapData(that.saveData.contentList[index + 1]).then((success) => {
                                    if (!success) {
                                        return resolve(false);
                                    } else {
                                        return resolve(true);
                                    }
                                });
                            } else if (index == length-1) {
                                return resolve(true);
                            }
                            break;
                        }
                    }
                } else {
                    return resolve(false)
                }
            }, (e) => {
                return resolve(false)
            })
        });
    }

    setImages(list) {
        if (list == null) {
            return;
        }
        this.initData();
        var images = []
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            if (element.name == null || 
                element.name.length == 0 || 
                element.cotent == null) {
                continue;
            }
            let cell = new ImageFileData()
            cell.name = element.name;
            cell.content = element.content;
            images.push(cell)
        }
        this.saveData.images = images;
        return this;
    }

    setFiles(list) {
        if (list == null) {
            return;
        }
        this.initData();
        var files = []
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            if (element.name == null || 
                element.name.length == 0 || 
                element.cotent == null) {
                continue;
            }
            let cell = new FileData()
            cell.name = element.name;
            cell.content = element.content;
            files.push(cell)
        }
        this.saveData.files = files;
        return this;
    }

    initData() {
        if (this.saveData == null ||
            this.saveData.version == -1.0) {
            this.saveData = new MapSaveData();
            this.sheetMap = new HashMap()
            this.saveData.version = Config.MindFormatVersion;
            this.sheetTotal = 0;
        }
    }

    saveToPath(path, callback) {
        this.initData();
        if (!this.isSaveAs && LoadLocalXJMind.isLoaclMind() && LoadLocalXJMind.isLoaclNotSaved()) {
            let id = this.mindSheetId
            if (id == null || id == "") {
                id = this.mindServerId
            }
            if (this.saveData.contentList != null && this.saveData.contentList.length > 0) {
                for (let index = 0; index < this.saveData.contentList.length; index++) {
                    const contentData = this.saveData.contentList[index];
                    if (contentData.name != null && contentData.content != null && contentData.id == id) {
                        let contentDataLocalPath = this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentFolderName
                        this.saveLocalContent(contentDataLocalPath, contentData.fileName, contentData.content)
                    }
                }
            }
            callback(true)
            return
        }
        let isSelectedPath = this.isSaveAs;
        if (arguments.length > 0) {
            this.saveData.path = path;
        }
        var that = this
        if (that.saveData.contentList == null ||
            that.saveData.contentList.length == 0 ||
            that.saveData.name == null ||
            that.saveData.name.length == 0) {
            callback(false)
            return;
        }
        let contentJson = that.saveData.getContentListJson(this.isSaveAs);
        if (contentJson.length <= 4) {
            callback(false)
            return;
        }
        if (!isSelectedPath && ((LoadLocalXJMind.isLoaclMind() && LoadLocalXJMind.isLoaclNotSaved()) || ipcRenderer.send == null)) {
            isSelectedPath = true
        }
        var generateAsyncType = "nodebuffer"
        if (!isSelectedPath) {
            if (!LoadLocalXJMind.existsLocalFile(that.saveData.path)) {
                isSelectedPath = true
            }
        }
        if (that.isUploadMindmap && isSelectedPath) {
            callback(true)
            return;
        }
        console.log("saveToPath-> start JSZip");
        const jszip = new JSZip();
        let contentFolder = jszip.folder(MindMapZipConstant.MindZIPContentFolderName)
        let imgFolder = jszip.folder(MindMapZipConstant.MindZIPImgFolderName)
        let filesFolder = jszip.folder(MindMapZipConstant.MindZIPFilesFolderName)

        contentFolder.file(MindMapZipConstant.MindZIPContentName, contentJson)
        if (that.saveData.contentList != null && that.saveData.contentList.length > 0) {
            for (let index = 0; index < that.saveData.contentList.length; index++) {
                const contentData = that.saveData.contentList[index];
                if (contentData.name != null && contentData.content != null) {
                    contentFolder.file(contentData.fileName, contentData.content)
                    
                    if (((this.mindSheetId != null && this.mindSheetId.length > 0 && this.mindSheetId == contentData.id) || 
                        ((this.mindSheetId == null || this.mindSheetId.length == 0) && this.mindServerId == contentData.id)) &&
                        this.getLocalPath() != null && this.getLocalPath().length > 0) {
                        let contentDataLocalPath = this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentFolderName
                        this.saveLocalContent(contentDataLocalPath, contentData.fileName, contentData.content)
                    }
                }
            }
        }

        if (that.saveData.images != null && that.saveData.images.length > 0) {
            for (let index = 0; index < that.saveData.images.length; index++) {
                const imageData = that.saveData.images[index];
                if (imageData.name != null && imageData.content != null) {
                    imgFolder.file(imageData.name, imageData.content)
                }
            }
        }
        if (that.saveData.files != null && that.saveData.files.length > 0) {
            for (let index = 0; index < that.saveData.files.length; index++) {
                const fileData = that.saveData.files[index];
                if (fileData.name != null && fileData.content != null) {
                    filesFolder.file(fileData.name, fileData.content)
                    filesFolder.file
                }
            }
        }

        if (isSelectedPath && !(that.vueView != null && that.vueView.$tools.isElectron())) {
            generateAsyncType = "blob"
        }
        jszip.generateAsync({
            type: generateAsyncType, // 压缩类型
            compression: "DEFLATE", // 压缩算法
            compressionOptions: { // 压缩级别
            level: 9
            }
        }).then(content => {
            // 删除旧包目录
            // 创建新包目录
            if (isSelectedPath) {
                if (that.vueView != null && that.vueView.$tools.isElectron()) {
                    let fileName = MindMapZipConstant.FileSeparator + that.saveData.name + ".xjmm";
                    ipcRenderer.send('open-directory-dialog', fileName);
                    ipcRenderer.once('selectedItem', function (e, files) {
                        if (files == undefined || files == null || files.length == 0) {
                            callback(false)
                        } else {
                            var path = files;
                            if (!files.toLowerCase().endsWith(".xjmm")) {
                                if (files.endsWith(".")) {
                                    path = files + "xjmm"
                                } else {
                                    path =  files + ".xjmm"
                                }
                            }
                            let obj = {};
                            obj.name = that.saveData.name;
                            obj.desc = path;
                            obj.content = content;
                            ipcRenderer.send("mind-write-file", obj);
                            if (LoadLocalXJMind.isLoaclMind() && LoadLocalXJMind.getPath() == MindMapZipConstant.MindZIPNewPathe) {
                                MindmapDataRouter.setPath(path, LoadLocalXJMind.getLocalPath())
                                new XJMindFIleImport(null, null, that.vueView.$router.appPath).changeHistoryItemPath(
                                    path, 
                                    LoadLocalXJMind.getLocalPath())
                                that.saveData.path = path
                            }
                            callback(true) 
                        }
                        
                    });
                } else {
                    saveAs(content, that.saveData.name + ".xjmm");
                    callback(true)
                }
            } else {
                let obj = {};
                obj.name = that.saveData.name;
                obj.desc = that.saveData.path;
                obj.content = content;
                ipcRenderer.send("mind-write-file", obj);
                callback(true)
            }
        });
    }

    saveLocalContent(path, name, content) {
        let that = this;
        return new Promise(resolve => {
            if (path == null) {
                resolve(false)
                return 
            }
            var fs = window.require('fs');
            if (name == null || name.length == 0) {
                fs.writeFile(path, content, function (err) {
                    if (err != null) {
                        // console.log(path, "-write-", err);
                        resolve(false)
                    } else {
                        resolve(true)
                    }
                });
            } else {
                this.createFolder(path);
                fs.writeFile(path + MindMapZipConstant.FileSeparator + name, content, function (err) {
                    if (err != null) {
                        resolve(false)
                    } else {
                        resolve(true)
                    }
                });
            }
        })
    }

    createFolder(dirpath, dirname) {
        var fs = window.require('fs');
        var path = window.require('path');
        if (typeof dirname === "undefined") {
          if (fs.existsSync(dirpath)) {
          } else {
            this.createFolder(dirpath, path.dirname(dirpath));
          }
        } else {
          if (dirname !== path.dirname(dirpath)) {
            this.createFolder(dirpath);
            return;
          }
          if (fs.existsSync(dirname)) {
            fs.mkdirSync(dirpath,{recursive:true})
          } else {
            this.createFolder(dirname, path.dirname(dirname));
            fs.mkdirSync(dirpath,{recursive:true});
          }
        }
    }

    getSheetPaginationList() {
        let that = this;
        return new Promise(resolve => {
            if (this.mindServerId != null && this.mindServerId.length > 0) {
                let obj = { rootId: this.mindServerId, lastIndex: 0 };
                if (this.isGroupMindMap) {
                    let sheetData = new MindContentData();
                    sheetData.setName(that.name);
                    sheetData.setFileName(that.name + 0)
                    sheetData.id = that.mindServerId;
                    sheetData.mindType = that.currentMindType;
                    sheetData.lastchangetime = Util.getCurrentTime()
                    let mindMapSheetData = new MindMapSheetData()
                    mindMapSheetData.id = that.mindServerId;
                    mindMapSheetData.name = that.name;
                    mindMapSheetData.loadData(that.currentMindContent, that.currentMindType)
                    that.sheetMap.put(that.mindServerId, mindMapSheetData);                    
                    that.saveData.contentList.push(sheetData)
                    this.sheetTotal = 0;
                    resolve(that)
                } else {
                    MindmapDataRouter.postMindmapSheets(obj, (res) => {
                        if (res.sheets == null || res.sheets == "") {
                            let sheetData = new MindContentData();
                            sheetData.setName(that.name);
                            sheetData.setFileName(that.name + 0)
                            sheetData.id = that.mindServerId;
                            sheetData.mindType = that.currentMindType;
                            sheetData.lastchangetime = Util.getCurrentTime()
                            let mindMapSheetData = new MindMapSheetData()
                            mindMapSheetData.id = that.mindServerId;
                            mindMapSheetData.name = that.name;
                            mindMapSheetData.loadData(that.currentMindContent, that.currentMindType)
                            that.sheetMap.put(that.mindServerId, mindMapSheetData);                    
                            that.saveData.contentList.push(sheetData)
                        } else {
                            if (res.sheets.length > 0) {
                                for (let index = 0; index < res.sheets.length; index++) {
                                    const item = res.sheets[index];
                                    let sheetData = new MindContentData();
                                    sheetData.setName(item.name);
                                    if (item.fileName != null && item.fileName.length > 0) {
                                        sheetData.setFileName(item.fileName, false);
                                    } else {
                                        sheetData.setFileName(item.name + index);
                                    }
                                    if (item.mindType != null) {
                                        sheetData.mindType = item.mindType;
                                    } else {
                                        sheetData.mindType = item.type;
                                    }
                                    
                                    sheetData.id = item.id;
                                    let mindMapSheetData = new MindMapSheetData()
                                    mindMapSheetData.id = item.id;
                                    mindMapSheetData.name = item.name;
                                    if ((this.mindSheetId != null && this.mindSheetId.length > 0 && this.mindSheetId == item.id) ||
                                        ((this.mindSheetId == null || this.mindSheetId.length == 0) && item.id == this.mindServerId)) {
                                        mindMapSheetData.loadData(this.currentMindContent, this.currentMindType)
                                        mindMapSheetData.isCurrentMindmap = true
                                        sheetData.mindType = this.currentMindType;
                                        sheetData.lastchangetime = Util.getCurrentTime()
                                    } else {
                                        if (item.lastchangetime == null || item.lastchangetime == 0) {
                                            sheetData.lastchangetime = Util.getCurrentTime()
                                        } else {
                                            sheetData.lastchangetime = item.lastchangetime
                                        }
                                    }
                                    this.sheetMap.put(item.id, mindMapSheetData);
                                    this.saveData.contentList.push(sheetData)
                                }
                            }
                        }
                        this.sheetTotal = res.totalNum;
                        resolve(that)
                    }, (error) => {
                        resolve(that)
                    });
                }
            }
        })
    }

    getSheetData(id) {
        return this.sheetMap.get(id);
    }

    replaceSheetId(oldId, newId) {
        let count = this.saveData.contentList.length
        for (let index = 0; index < count; index++) {
            const cell = this.saveData.contentList[index];
            if (cell.id == oldId) {
                cell.id = newId;
                if (index == 0) {
                    this.mindServerId = newId;
                }
            }
        }
        if (this.vueView != null) {//更新分页ID
            if (this.vueView.initMindSheetDatas != null) {
                for (let index = 0; index < this.vueView.initMindSheetDatas.length; index++) {
                    const sheet = this.vueView.initMindSheetDatas[index];
                    if (sheet.id == oldId) {
                        sheet.id = newId
                        break;
                    }
                }
            }
        }
        if (this.mindSheetId == oldId) {
            this.mindSheetId = newId
        }
        
        if (this.sheetMap.containsKey(oldId)) {
            let data = this.sheetMap.get(oldId)
            if (data != null) {
                data.id = newId;
                data.rootId = this.mindServerId;
                this.sheetMap.remove(oldId)
                this.sheetMap.put(newId, data)
            }
        }
        
    }
}

export default new SaveMindMapToLocal()
