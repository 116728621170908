// package mind.yushu.com.mindmap.main.uidata;

// import mind.yushu.com.mindmap.viewmodel.datatype.ConnectLineType;

import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/15
 * Copyright(c) 2020 mindyushu.com
 */

let StyleType = {
    GLOBAL:1,
    NODE:2

    // private final int value;

    // StyleType(final int value) {
    //     this.value = value;
    // }

    // public static StyleType from(final int value) {
    //     StyleType result = GLOBAL;
    //     for (final StyleType type : values()) {
    //         if (type.getValue() == value) {
    //             result = type;
    //         }
    //     }
    //     return result;
    // }

    // public int getValue() {
    //     return value;
    // }
}
export default StyleType
