import MindElementType from "../../datatype/MindElementType";
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode"
import LeftRightLayout from "./LeftRightLayout"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class RightLeftLayout extends LeftRightLayout {
    constructor() {
        super();
    }
    onLayout(isChange) {
        if (this.rightLayout != null && this.rightLayout.rootTreeNode != null) {
            this.rightLayout.layout(isChange);
        }
        if (this.leftLayout != null && this.leftLayout.rootTreeNode != null &&
            this.leftLayout.rootTreeNode.children.length > 0) {
            this.leftLayout.layout(true);
        }
    }

    callbackLeftAndRightLayoutResetDatas(
        rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict) {

        let leftNodes = new Array();
        rootTreeNode.children.forEach(node => {
            if (this.leftMainMindElementDataDict.containsKey(node.value.id)) {
                leftNodes.push(node);
            } else {
                this.leftRightRootTreeNode.children.push(node);
            }
        })

        if (!leftNodes.isEmpty()) {
            let leftNodesCount = leftNodes.length;
            for (let index = 0; index < leftNodesCount; index++) {
                this.leftRightRootTreeNode.children.push(leftNodes[index]);
            }
        }

        this.rootTreeNode = this.leftRightRootTreeNode;
        lineMindElementDataDict.keys().forEach(key => {
            this.lineMindElementDataDict.put(key, lineMindElementDataDict.get(key));
        });
        mainMindElementDataDict.keys().forEach(key => {
            this.mainMindElementDataDict.put(key, mainMindElementDataDict.get(key));
        });
        textElementLineMindElementDataDict.keys().forEach(key => {
            this.textElementLineMindElementDataDict.put(key, textElementLineMindElementDataDict.get(key));
        });
        this.mainMindElementDataDict.put(this.leftRightRootTreeNode.value.id, this.rootTreeNode);

        generalizationMindElementDataDict.keys().forEach(key => {
            let node = generalizationMindElementDataDict.get(key);
            this.leftRightGeneralizationMindElementDataDict.put(node.value.id, node);
        });
        generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = generalizationLineMindElementDataDict.get(key);
            this.leftRightGeneralizationLineMindElementDataDictInt.put(line.id, line);
        });
        this.generalizationMindElementDataDict = this.leftRightGeneralizationMindElementDataDict;
        this.generalizationLineMindElementDataDict = this.leftRightGeneralizationLineMindElementDataDictInt;
        this.explainMindElementDataDict = explainMindElementDataDict;
        // this.setEncirclePoint();
    }

    shuntData(isChange) {
        this.leftRightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        if (this.rootTreeNode.children.length <= 2) {
            this.rightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
            this.rightMainMindElementDataDict.put(this.rootTreeNode.value.id, this.rightRootTreeNode);
            for (let index = 0; index < this.rootTreeNode.children.length; index++) {
                this.rightRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setRightMainNode(this.rootTreeNode.children[index]);
            }
            this.lineMindElementDataDict.keys().forEach(key => {
                let line = this.lineMindElementDataDict.get(key);
                if (this.leftMainMindElementDataDict.containsKey(line.lineContent.targetId)) {
                    this.leftLineMindElementDataDict.put(line.id, line);
                    this.leftTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                } else {
                    this.rightLineMindElementDataDict.put(line.id, line);
                    this.rightTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            });
            this.generalizationMindElementDataDict.keys().forEach(key => {
                let item = this.generalizationMindElementDataDict.get(key);
                if (this.rightMainMindElementDataDict.containsKey(item.value.generalizationContent.targetIds[0])) {
                    item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, item.value.layout);
                    this.rightGeneralizationMindElementDataDict.put(item.value.id, item);
                    for (var index = 0; index < item.children.length; index++) {
                        this.setRightMainNode(item.children[index]);
                    }
                } else {
                    item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, item.value.layout);;
                    this.leftGeneralizationMindElementDataDict.put(item.value.id, item);
                    for (var index = 0; index < item.children.length; index++) {
                        this.setLeftMainNode(item.children[index]);
                    }
                }
            });
    
            this.generalizationLineMindElementDataDict.keys().forEach(key => {
                let line = this.generalizationLineMindElementDataDict.get(key);
                if (this.leftGeneralizationMindElementDataDict.containsKey(line.parentNodeId)) {
                    this.leftGeneralizationLineMindElementDataDict.put(line.id, line);
                } else {
                    this.rightGeneralizationLineMindElementDataDict.put(line.id, line);
                }
            });

            this.rightLayout.originalRootTreeNode = this.rootTreeNode;
            this.rightLayout.setDatas(
                this.rootTreeNode,
                this.lineMindElementDataDict,
                this.mainMindElementDataDict,
                this.textElementLineMindElementDataDict,
                this.generalizationLineMindElementDataDict,
                this.generalizationMindElementDataDict, 
                this.explainMindElementDataDict,
                this.mindMapStyleColor, this.mindBGColor,
                this.settingData);
            this.rightLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
            this.rightLayout.globalLayout = this.globalLayout;
            return;
        }

        this.leftRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        this.rightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        this.leftMainMindElementDataDict.put(this.rootTreeNode.value.id, this.leftRootTreeNode);
        this.rightMainMindElementDataDict.put(this.rootTreeNode.value.id, this.rightRootTreeNode);

        let rightCount = Math.ceil(this.rootTreeNode.children.length / 2);

        for (let index = 0; index < this.rootTreeNode.children.length; index++) {
            let subjectNode = this.rootTreeNode.children[index]
            if (this.settingData.branchNodeFreelayout.isValue() && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT &&
                subjectNode.value.isFreeLayoutNode && subjectNode.value.x > 0 && subjectNode.value.y > 0) {
                    if (subjectNode.value.x > this.rootTreeNode.value.x + this.rootTreeNode.value.width) {
                        this.rightRootTreeNode.children.push(subjectNode);
                        this.setRightMainNode(subjectNode);
                        continue
                    } else if (subjectNode.value.x + subjectNode.value.width < this.rootTreeNode.value.x) {
                        this.leftRootTreeNode.children.push(subjectNode);
                        this.setLeftMainNode(subjectNode);
                        continue
                    }
            }
            if (index < rightCount) {
                this.rightRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setRightMainNode(this.rootTreeNode.children[index]);
            } else {
                this.leftRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setLeftMainNode(this.rootTreeNode.children[index]);
            }
        }

        this.generalizationMindElementDataDict.keys().forEach(key => {
            let item = this.generalizationMindElementDataDict.get(key);
            if (this.rightMainMindElementDataDict.containsKey(item.value.generalizationContent.targetIds[0])) {
                item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, item.value.layout);
                this.rightGeneralizationMindElementDataDict.put(item.value.id, item);
                for (var index = 0; index < item.children.length; index++) {
                    this.setRightMainNode(item.children[index]);
                }
            } else {
                item.value.layout = NodeLayoutType.LAYOUT_LEFT;
                item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, item.value.layout);
                this.leftGeneralizationMindElementDataDict.put(item.value.id, item);
                for (var index = 0; index < item.children.length; index++) {
                    this.setLeftMainNode(item.children[index]);
                }
            }
        });

        this.generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = this.generalizationLineMindElementDataDict.get(key);
            if (this.leftGeneralizationMindElementDataDict.containsKey(line.parentNodeId)) {
                this.leftGeneralizationLineMindElementDataDict.put(line.id, line);
            } else {
                this.rightGeneralizationLineMindElementDataDict.put(line.id, line);
            }
        });

        this.lineMindElementDataDict.keys().forEach(key => {
            let line = this.lineMindElementDataDict.get(key);
            if (this.leftMainMindElementDataDict.containsKey(line.lineContent.targetId)) {
                this.leftLineMindElementDataDict.put(line.id, line);
                this.leftTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            } else {
                this.rightLineMindElementDataDict.put(line.id, line);
                this.rightTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            }
        });
        this.rightLayout.originalRootTreeNode = this.rootTreeNode;
        this.rightLayout.setDatas(
            this.rightRootTreeNode,
            this.rightLineMindElementDataDict,
            this.rightMainMindElementDataDict,
            this.rightTextElementLineMindElementDataDict,
            this.rightGeneralizationLineMindElementDataDict,
            this.rightGeneralizationMindElementDataDict, 
            this.explainMindElementDataDict,
            this.mindMapStyleColor, this.mindBGColor,
            this.settingData);
        this.rightLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.rightLayout.globalLayout = this.globalLayout;

        this.leftLayout.originalRootTreeNode = this.rootTreeNode;
        this.leftLayout.setDatas(
            this.leftRootTreeNode,
            this.leftLineMindElementDataDict,
            this.leftMainMindElementDataDict,
            this.leftTextElementLineMindElementDataDict,
            this.leftGeneralizationLineMindElementDataDict,
            this.leftGeneralizationMindElementDataDict, 
            this.explainMindElementDataDict,
            this.mindMapStyleColor, this.mindBGColor,
            this.settingData);
        this.leftLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.leftLayout.globalLayout = this.globalLayout;
    }

}
export default RightLeftLayout