import { render, staticRenderFns } from "./UserShareCollection.vue?vue&type=template&id=6e32d2cc&scoped=true"
import script from "./UserShareCollection.vue?vue&type=script&lang=js"
export * from "./UserShareCollection.vue?vue&type=script&lang=js"
import style0 from "./UserShareCollection.vue?vue&type=style&index=0&id=6e32d2cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e32d2cc",
  null
  
)

export default component.exports