<template>
  <!-- 插入二级菜单 -->
  <div id="edit-order-content" class="edit-order-content" ref="editOrderContent">
    <div class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataList"
          :key="index"
          :class="'complie-com-box'"
          @click="getCorrelationLineType(item)"
        >
          <div class="complie-left-icon" :title="getString(item.name)">
            <img v-if="mapEditDarkMode" class="menu-line-icon"  v-show="item.show" :src="item.iconWhite" />
            <img v-else class="menu-line-icon"  v-show="item.show" :src="item.iconUrl" />
            <span style="position: absolute; left: 32px">{{ getString(item.name) }}</span>
          </div>
        </div>        
      <!-- </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import OrderType from '../../../viewmodel/datatype/OrderType';
export default {
  name: "ComplieChildMenuNodeOpenHidde",
  data() {
    return {
      imgDataList: [
        {
          name: strings.Mind_Edit_Open_Hidde_1,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/selectIcon.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/selectIcon.svg"),
          show:false,
          id:0,
        },
        {
          name: strings.Mind_Edit_Open_Hidde_2,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/selectIcon.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/selectIcon.svg"),
          show:false,
          id:1,
        },
        {
          name: strings.Mind_Edit_Open_Hidde_3,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/selectIcon.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/selectIcon.svg"),
          show:false,
          id:2,
        },
        {
          name: strings.Mind_Edit_Open_Hidde_4, 
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/selectIcon.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/selectIcon.svg"),
          show:false,
          id:3,
        },
        {
         name: strings.Mind_Edit_Open_Hidde_5,  
         iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/selectIcon.svg"),
         iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/selectIcon.svg"),
         show:false,
         id:4,
        },
        {
          name: strings.Mind_Edit_Open_Hidde_All,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/selectIcon.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/selectIcon.svg"),
          show:false,
          id:5,
        }, 
      ],
      imgDataAccout: 0,
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {},
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations([""]),
    getString(i) {
      return getString(i);
    },
    getCorrelationLineType(item) {
      if (item.name == strings.Mind_Edit_Open_Hidde_1) {
        EditMindmapVM.showNodeChildsTrue(EditMindmapVM.selectedNodeId, 1)
      } else if (item.name == strings.Mind_Edit_Open_Hidde_2) {
        EditMindmapVM.showNodeChildsTrue(EditMindmapVM.selectedNodeId, 2)
      } else if (item.name == strings.Mind_Edit_Open_Hidde_3) {
        EditMindmapVM.showNodeChildsTrue(EditMindmapVM.selectedNodeId, 3)
      } else if (item.name == strings.Mind_Edit_Open_Hidde_4) {
        EditMindmapVM.showNodeChildsTrue(EditMindmapVM.selectedNodeId, 4)
      } else if (item.name == strings.Mind_Edit_Open_Hidde_5) {
        EditMindmapVM.showNodeChildsTrue(EditMindmapVM.selectedNodeId, 5)
      } else if (item.name == strings.Mind_Edit_Open_Hidde_All) {
        EditMindmapVM.showNodeChildsTrue(EditMindmapVM.selectedNodeId, 6)
      } else {
        return
      }
      this.$emit('clickMenuElement',item.name); 
    },
    onClickOrderDepth(item) {
      if (item.name == strings.Mind_Edit_Order_Level_1_Label) {
        EditMindmapVM.setOrderDepth(1)
      } else if (item.name == strings.Mind_Edit_Order_Level_2_Label) {
        EditMindmapVM.setOrderDepth(2)
      } else if (item.name == strings.Mind_Edit_Order_Level_3_Label) {
        EditMindmapVM.setOrderDepth(3)
      } else if (item.name == strings.Mind_Edit_Order_Level_4_Label) {
        EditMindmapVM.setOrderDepth(4)
      } else if (item.name == strings.Mind_Edit_Order_Remove_Label) {
        EditMindmapVM.clearOrder()
      }
      for (let index = 0; index < this.levelDataList.length; index++) {
        const element = this.levelDataList[index];
        element.show = item.name == element.name
      }
      this.$emit('clickMenuElement',item.name); 
    },
    onClickClearOrder(item) {
      if (item.name == strings.Mind_Edit_Order_Remove_Label) {
        EditMindmapVM.clearOrder()
      }
      this.$emit('clickMenuElement',item.name); 
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.editOrderContent;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.color = "#000";
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.edit-order-content {
  width: 130px;
  height: auto;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 6px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  font-size: 13px;
}
.complie-header-imgbox {
  margin: 3px 0;
}
.complie-com-box {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: var(--rightClickMenuBg);
    color: var(--rightClickMenuColor);
  }
}

.complie-left-icon {
  overflow: hidden;
  display: flex;
  align-items: center;
  .menu-line-icon{
    margin-left: 8px;
    margin-right: 8px;
  }
  span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.off-chart {
    border-top: 1px solid #d0cfcf;
    margin-top: 5px;
  }
</style>