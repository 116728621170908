/**
 * ProjectName: MindMap
 * Created by tony on 3/29/21
 * Copyright(c) 2020 mindyushu.com
 */

let MindMapPlayStatus = {
    NORMAL:"NORMAL", PLAY:"PLAY", PAUSE:"PAUSE"
}
export default MindMapPlayStatus
