<template>
  <!-- 插入二级菜单 -->
  <div id="edit-insert-content" class="edit-insert-content" ref="editInsertContent">
    <div class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataList"
          :key="index"
          :class="'complie-com-box'"
          @click="getCorrelationLineType(item)"
        >
          <div class="complie-left-icon" :title="getString(item.name)">
            <img v-if="mapEditDarkMode" class="menu-line-icon" :src="item.iconWhite" />
            <img v-else class="menu-line-icon" :src="item.iconUrl" />
            <span>{{ getString(item.name) }}</span>
          </div>
        </div>
      <!-- </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import QuickStyleType from "../../../viewmodel/datatype/QuickStyleType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
  name: "ComplieChildMenuNodeInsertContent",
  data() {
    return {
      imgDataList: [
        {
          name: strings.Mind_Usinghelp_9,//插入图片
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_img_af.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addImgWhite.svg"),
        },
        {
          name: strings.Mind_Add_Background_Image, //插入背景图
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_bg_img.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/add_bg_img.svg"),
        },
        {
          name: strings.Mind_Edit_Insert_Remarks,// 插入备注 
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_remark_af.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addRemarkWhite.svg"),
        },
        {
          name: strings.Mind_Edit_Insert_Link,// 插入链接 
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_insert_link_af.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/insertLinkWhite.svg"),
        },
        {
          name: strings.Global_Edit_Task,//编辑任务
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/edit_task.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/edit_task.svg"),
        },
        {
          name: strings.Mind_Edit_Latex, //编辑LaTeX
          iconUrl: require("../../../assets/img/canvasMenu/editLatex.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/editLatexWhite.svg"),
        },
        {
         name: strings.Mind_Edit_Insert_Video, //添加视频   
         iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_video_af.svg"),
         iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addVideoWhite.svg"),
        },
        {
          name: strings.Mind_add_Resource,// 添加附件 
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_enclosure.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addEnclosureWhite.svg"),
        },{
          name: strings.Mind_Edit_Subscript,// 下标 
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_subScript_icon.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addSubScriptWhite.svg"),
        },
        {
          name: strings.Mind_Scan_Extraction,// 扫描提取 
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/lightModeIcon/scanCreation.svg"),
          iconWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/scanCreationWhite.svg"),
        }, 
      ],
      imgDataAccout: 0,
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {},
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations([""]),
    getString(i) {
      return getString(i);
    },
    getCorrelationLineType(item) {
      this.$emit('clickMenuElement',item.name); 
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.editInsertContent;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.color = "#000";
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.edit-insert-content {
  width: 130px;
  height: auto;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.complie-header-imgbox {
  margin: 3px 0;
}
.complie-com-box {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: var(--rightClickMenuBg);
    color: var(--rightClickMenuColor);
  }
}

.complie-left-icon {
  overflow: hidden;
  display: flex;
  align-items: center;
  .menu-line-icon{
    margin-left: 15px;
    margin-right: 10px;
  }
  span{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>