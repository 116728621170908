import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";

class CheckNodeData {
    constructor(node = new LineMindTypeNode(), distance = 0) {
    this.node = node;
    this.distance = distance;
    }

    init(node, distance) {
        this.node = node
        this.distance = distance
    }
}

export default CheckNodeData