
import IdGenerator from "../../viewmodel/core/base/IdGenerator"
import MindElementData from "./MindElementData";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class TimeMindTypeNode {
    constructor() {
        this.id = IdGenerator.INVALID_ID;
        this.head = new MindElementData();
        this.title = new MindElementData();
        this.desc = new MindElementData();
        this.level = IdGenerator.INVALID_ID;
    }

    TimeMindTypeNode() {
    }

    emptyMindNode() {
        return new TimeMindTypeNode();
    }

    isEmpty() {
        return this.id == IdGenerator.INVALID_ID;
    }

    copy() {
        var timeNode = new TimeMindTypeNode();
        timeNode.id = this.id;
        timeNode.head = this.head.copy();
        timeNode.title = this.title.copy();
        timeNode.desc = this.desc.copy();
        timeNode.level = this.level;
        return timeNode;
    }
}

export default TimeMindTypeNode