
import HttpServerConfig from "../../../common/HttpServerConfig";
import { postMindmapUploadImage } from "../../../common/netWork/mind_map_api";
import imageToStyle from "../../../utils/ImageToolkit";
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
class DragImgFileAppendCanvas {
    constructor (bundingDragDom, delegate) {
        this.bundingDragDom = bundingDragDom;
        this.delegate = delegate;
        this.DragFunction;
        this.DropFunction;
        this.point;
        this.bunding();
    }

    bunding() {
        // console.log('绑定的方法');
        this.DragFunction = (e) => {
            e.stopPropagation();
            e.preventDefault();
            // console.log('DragFunction');
        }

        this.DropFunction = (e) => {
          e.stopPropagation();
          e.preventDefault();

          if (this.delegate.editmapScrollView != null &&
              (
                this.delegate.editmapScrollView.controllCircleDrag ||
                this.delegate.editmapScrollView.isShowGuideLine() ||
                (this.delegate.editmapScrollView.resetMindView != null &&
                   (this.delegate.editmapScrollView.resetMindView.DragImageFlag || 
                    this.delegate.editmapScrollView.resetMindView.DragFlag)))) {
              return
          }
          if (e.dataTransfer.items == null ||
            e.dataTransfer.items.length == 0) {
              return
          }
          for (let index = 0; index < e.dataTransfer.items.length; index++) {
            const item = e.dataTransfer.items[index];
            this.analysisDataTransferItem(item)
            break
          }
          
          let x =  e.pageX;
          let y = e.pageY - this.delegate.canvasTop

          let moveX = x * (1 / this.delegate.editmapScrollView.scale);
          let moveY = y * (1 / this.delegate.editmapScrollView.scale);

          let rect = this.delegate.editmapScrollView.getScreenRect()
          
          this.point =  new CGPoint(moveX + rect.x,moveY + rect.y);

        }
        this.bundingDragDom.addEventListener('dragenter', this.DragFunction, false);
        this.bundingDragDom.addEventListener("dragover", this.DragFunction, false);
        this.bundingDragDom.addEventListener("drop", this.DropFunction, false);


    }

    analysisDataTransferItem(item) {
      if (item.kind == "string") {
        item.getAsString(s => {
          let textHeader = s.substr(0,7)
          if (textHeader == "http://" || textHeader == "https:/") {
            this.addLink(s);
          } else {
            this.addText(s)
          }
        })
      } else if (item.kind == "file") {
        let starString = item.type.substr(0,5);
        if (starString == 'image') {  //如果是图片可以添加
          this.imgPreview(item.getAsFile());
        }
      }
    }



    imgPreview(file) {
      if (file == null) {
        return
      }
      var meMessage = JSON.parse(localStorage.getItem("meMessage"));
      if (!meMessage.isMember) {
        let that = this;
        if(this.delegate.generalConfigurationParameters != null && 
          this.delegate.generalConfigurationParameters != false && 
        !this.delegate.generalConfigurationParameters.uploadImageFree){
          this.delegate.$confirm({
            title: this.delegate.getString(this.delegate.strings.Mind_Tips),
            content: this.delegate.getString(this.delegate.strings.Member_Exclusive),
            centered: true,
            okText: this.delegate.getString(this.delegate.strings.Mind_Edit_Confirm),
            cancelText: this.delegate.getString(this.delegate.strings.Global_Cancel),
            onOk() {
              that.delegate.isShowShoppingModel(true);
            },
            onCancel() {

            },  
          });
          return true;
        } 
      }
      let that = this;
      let read = new FileReader();
      read.readAsDataURL(file);
      read.onload = function () {
        that.setUploadImg(read);

      };
    }


    setUploadImg(read) {   //本地图片上传。
      // console.log('read',read);
      let userImage = read.result;
        //转Blob
        function blobData() {
          var arr = userImage.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();
        this.delegate.spinning = true;
        if (blob.type.indexOf("image/") > -1) {
          let handler;
          //判断是否处在群组编辑页面。
          if(this.delegate.$router.history.current.name == 'gmap'  ) {
            handler = {
              "X-Type": "gmap-inner-image",
              "X-ID": this.delegate.$route.query.id
            }
          } else {
            handler = {
              "X-Type": "mindmap-inner-image",
              "X-ID": this.delegate.$route.query.id,
            }
          }
          postMindmapUploadImage(
            blob,
            (res) => {
              this.delegate.spinning = false;
              // this.delegate.showCropperImgUrl("");
              let imgUrlData = HttpServerConfig.getMediaSrc(res.imageUrl);
              new imageToStyle()
                .webToBase64(imgUrlData)
                .then((imageBase64) => {
                  let width;
                  let height;
                  let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
                  img.src = imgUrlData;
                  img.onload = () => {
                    width = img.width;
                    height = img.height;
                    // this.delegate.setDomImg({ imageBase64: imgUrlData, type: "storm", width: width, height: height});
                    // this.delegate.showCropperImg(false)
                    this.addImage(imgUrlData, width, height);
                    
                    // this.delegate.setDomImg({
                    //   imageBase64: imgUrlData,
                    //   type: "uploadImg",
                    //   width: width,
                    //   height: height,
                    // });
                    // setTimeout(() => {
                    //   this.delegate.setDomImg({
                    //     imageBase64: null,
                    //     type: "uploadImg",
                    //     width: 0,
                    //     height: 0,
                    //   });
                    // }, 100);



                  };
                });
              // this.delegate.showCropperImg(false);
            },
            (error) => {
              this.delegate.spinning = false;
              console.log(error);
            },
            null,
            handler
          );
        } else {
          this.delegate.spinning = false;
          console.log("file is not a image");
        }
    }

    addImage(imgUrlData,  width, height) {
      let element = this.delegate.getElemeViewInDrawingBoardByPoint(this.point);
      // console.log(element);
      if (element != null && element.mindElementData != null) {
        this.delegate.editMindmapContent.addImage(imgUrlData, element.mindElementData.id, width, height, null, this.point);
      } else {
        this.delegate.editMindmapContent.addImage(imgUrlData, null, width, height, null, this.point);
      }
    }

    addText(text) {
      let element = this.delegate.getElemeViewInDrawingBoardByPoint(this.point);
      if (element != null && element.mindElementData != null) {
        this.delegate.editMindmapContent.replaceNodeString(element.mindElementData.id, text)
      } else {
        this.delegate.editMindmapContent.stickString(-1, this.point, text);
      }
    }
    
    addLink(link) {
      if (link == null || link.length < 6) {
        return;
      }
      let element = this.delegate.getElemeViewInDrawingBoardByPoint(this.point);

      if (element != null && element.mindElementData != null) {
        this.delegate.editMindmapContent.addLink(link, element.mindElementData.id)
      } else {
        this.addText(link);
      }
    }
}

export default DragImgFileAppendCanvas