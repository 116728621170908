// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;

/**
 * ProjectName: MindMap
 * Created by tony on 1/12/21
 * Copyright(c) 2020 mindyushu.com
 */

class StatisticsCellData {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        this.color;
        this.startAngle = 0.0;
        this.endAngle = 0.0;
        this.angle = 0.0;
        this.value = 0.0;
        this.lineWidth = 1.5;
        this.lineColor = 0x555555;
        this.name = "";
        this.nameColor = 0x555555;
    
        this.explain = "";
        this.explainX = 0;
        this.explainY = 0;
        this.explainFontSize = 18;
    
        this.explainTitle = "";
        this.explainTitleX = 0;
        this.explainTitleY = 0;
        this.explainTitleFontSize = 20;
    
        this.explainColor = 0x555555;
        this.explainTitleColor = 0x555555;
        this.explainLineColor = 0x555555;
        this.explainLines = new Array();
        this.explainLineWidth = 1.0;
        this.isSelected = false;
    }

    copy() {
        let data = new StatisticsCellData();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;
        data.color = this.color;
        data.startAngle = this.startAngle;
        data.endAngle = this.endAngle;
        data.angle = this.angle;
        data.value = this.value;
        data.lineWidth = this.lineWidth;
        data.lineColor = this.lineColor;
        data.name = this.name;
        data.nameColor = this.nameColor;
        data.explain = this.explain;

        data.explainTitle = this.explainTitle;
        data.explainColor = this.explainColor;
        data.explainTitleColor = this.explainTitleColor;
        data.explainLineColor = this.explainLineColor;
        data.explainLines = this.explainLines;
        data.explainLineWidth = this.explainLineWidth;

        data.explainX = this.explainX;
        data.explainY = this.explainY;
        data.explainFontSize = this.explainFontSize;
        data.explainTitleX = this.explainTitleX;
        data.explainTitleY = this.explainTitleY;
        data.explainTitleFontSize = this.explainTitleFontSize;
        data.isSelected = this.isSelected;
        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.color != this.color;
        result = result || data.startAngle != this.startAngle;
        result = result || data.endAngle != this.endAngle;
        result = result || data.angle != this.angle;
        result = result || data.value != this.value;
        result = result || data.lineWidth != this.lineWidth;

        result = result || data.lineColor != this.lineColor;
        result = result || data.name != this.name;
        result = result || data.nameColor != this.nameColor;
        result = result || data.explain != this.explain;
        result = result || data.explainTitle != this.explainTitle;
        result = result || data.explainColor != this.explainColor;

        result = result || data.explainTitleColor != this.explainTitleColor;
        result = result || data.explainLineColor != this.explainLineColor;
        result = result || data.explainLineWidth != this.explainLineWidth;

        result = result || data.explainX != this.explainX;
        result = result || data.explainY != this.explainY;
        result = result || data.explainFontSize != this.explainFontSize;
        result = result || data.explainTitleX != this.explainTitleX;
        result = result || data.explainTitleY != this.explainTitleY;
        result = result || data.explainTitleFontSize != this.explainTitleFontSize;
        result = result || data.isSelected != this.isSelected;

        result = result || data.explainLines.length != this.explainLines.length;
        if (!result) {
            let count = data.explainLines.length;
            for (let index = 0; index < count; index++) {
                result = result || data.explainLines[index].x != this.explainLines[index].x;
                result = result || data.explainLines[index].y != this.explainLines[index].y;
            }
        }
        return !result;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.color = data.color;
        this.lineWidth = data.lineWidth;
        this.lineColor = data.lineColor;
        this.nameColor = data.nameColor;
        this.explainColor = data.explainColor;
        this.explainTitleColor = data.explainTitleColor;
        this.explainLineColor = data.explainLineColor;
        this.explainLineWidth = data.explainLineWidth;
        this.explainLines = data.explainLines;
        this.explainFontSize = data.explainFontSize;
        this.explainTitleFontSize = data.explainTitleFontSize;
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
        this.color = data.color;
        this.lineColor = data.lineColor;
        this.nameColor = data.nameColor;
        this.explainColor = data.explainColor;
        this.explainTitleColor = data.explainTitleColor;
        this.explainLineColor = data.explainLineColor;
    }
}
export default StatisticsCellData