// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/29
 * Copyright(c) 2020 mindyushu.com
 */

const MindDisplayType = {
    Unknown: -1,       //未定义
    MindMap: 0,        //导图模式
    Outline: 1,     //大纲模式

    // MindDisplayType(value) {
    //     this.value = value;
    // },

    // from: function (value) {
    //     for (let mealName of Object.values(this)) {
    //         if (mealName == value) {
    //             return value;
    //         }
    //     }
    //     return this.Unknown;
    // }
}
export default MindDisplayType
