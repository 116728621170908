// package mind.yushu.com.mindmap.viewmodel.delegate;

// import java.util.List;

// import mind.yushu.com.mindmap.viewmodel.defer.MindDataLayoutDelegate;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;

import MindDataLayoutDelegate from "../../viewmodel/defer/MindDataLayoutDelegate"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class DoubleBubbleDataLayoutDelegate extends MindDataLayoutDelegate {
    callbackDoubleBubbleResetDatas(rootTreeBrotherNode, commonElementDatas){};
}

export default DoubleBubbleDataLayoutDelegate
