<template>
  <div id="look-user-message">
    <div class="look-user-message-box">
      <div class="look-user-message-close">
        <a-icon
          type="close"
          class="icon-remove"
          :style="{ fontSize: '16px' }"
          @click="closeUserLookMessage"
        />
      </div>
      <div id="look-user-message-bg" v-if="userCompoennt">
        <div class="user-message">
          <div v-if="userData.isMember" class="user-avatar">
            <img :src="userData.userAvatar" onerror="this.src='../../../static/img/userData/avatar_yellow_man.png'" alt="" />
          </div>
          <div v-else class="user-avatar-nomember">
            <img :src="userData.userAvatar" onerror="this.src='../../../static/img/userData/avatar_yellow_man.png'" alt="" />
          </div>
          <div class="user-data-message">
            <div class="user-name">{{ userData.username }}</div>
            <div class="user-message">{{ userData.motto }}</div>
          </div>
        </div>
      </div>
      <div class="user-share" v-if="userComponentToCollection">
        <UserShareData
          :initUserData="userData"
          :initTitleAccout="titleAccout"
          :shareName="shareName"
          @userShareDetail="hideCollectionAndUser"
          @userShareDetailShow="showShareAndCollection"
        />
      </div>
      <div class="user-share" v-if="userComponentToShareDetial">
        <UserShareCollection
          :initUserData="userData"
          :initTitleAccout="titleAccout"
          @userCollectionDetail="hideShareAndUser"
          @userShareDetailShow="showShareAndCollection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { postUserProfile } from "../../common/netWork/base_api";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import dataTimeToFormats from "../../utils/timestampToTime";

import UserShareCollection from "./userMessageData/UserShareCollection";
import UserShareData from "./userMessageData/UserShareData";

export default {
  data() {
    return {
      userData: "",
      userCompoennt: true, //用户信息面板
      userComponentToShareDetial: true, //分享面板
      userComponentToCollection: true, //收藏面板
      titleAccout: false,
      shareName: "",
    };
  },
  components: {
    UserShareCollection,
    UserShareData,
  },
  computed: {
    ...mapState({
      userModelData: (state) => state.userModelData,
    }),
  },
  mounted() {
    this.postUserProfile(this.userModelData);
  },
  methods: {
    ...mapMutations(["showUserDataModel"]),
    //查看用户文件
    postUserProfile(userModelData) {
      postUserProfile(
        {
          userId: userModelData.userId,
          xfrom: userModelData.xfrom,
          fromId: userModelData.fromId,
        },
        (userData) => {
          this.userData = userData;
          this.userData.userAvatar = httpImageToPrefix(userData.userAvatar);
          //会员到期时间
          //const memberExpire = dataTimeToFormats.timestampToTime(this.userData.memberExpire);
          //this.userData.memberExpire = memberExpire;
        }
      );
    },
    //关闭查看用户信息
    closeUserLookMessage() {
      this.showUserDataModel({ accout: false });
    },
    //隐藏用户收藏
    hideCollectionAndUser(data) {
      this.userComponentToShareDetial = data.accout;
      this.userCompoennt = false;
      this.titleAccout = {accout:true,name:data.name};
    },
    //隐藏用户分享
    hideShareAndUser(data) {
      this.userComponentToCollection = data.accout;
      this.userCompoennt = false;
      this.titleAccout = {accout:true,name:data.name};
    },
    //显示收藏和分享
    showShareAndCollection(accout) {
      this.userComponentToCollection = accout;
      this.userCompoennt = true;
      this.userComponentToShareDetial = true;
      this.titleAccout = false;
      this.postUserProfile(this.userModelData);
    },
  },
};
</script>

<style lang="less" scoped>
#look-user-message {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 1200px;
  height: 774px;
  min-width: 1200px;
  min-height: 774px;
  border-radius: 4px;
  background: #ffffff;
  // background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .look-user-message-box {
    margin: 0px 104px 30px 104px;
    width: 994px;
    overflow: hidden;
    .look-user-message-close {
      position: absolute;
      right: 30px;
      top: 30px;
      .icon-remove {
        transform: rotate(0deg);
        transition: 0.5s;
      }
      .icon-remove:hover {
        transform: rotate(180deg);
        color: #fd492b;
        transition: 0.5s;
      }
    }
  }
  #look-user-message-bg {
    //   background-image: url("../../../static/img/userData/user_message_show_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 119px;
    display: flex;
    margin-top: 20px;
    .user-message {
      display: flex;
      align-items: center;
      .user-avatar {
        display: flex;
        justify-content: center;
        background-image: url(../../../static/img/userData/user_message_vip_bg.png);
        background-repeat: no-repeat;
        width: 90px;
        height: 119px;
        margin: 0 auto;
        position: relative;
        img {
          width: 86px;
          height: 86px;
          border-radius: 50%;
          position: absolute;
          bottom: 2px;
        }
      }
      .user-avatar-nomember {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        width: 90px;
        height: 119px;
        margin: 0 auto;
        position: relative;
        img {
          width: 86px;
          height: 86px;
          border-radius: 50%;
          position: absolute;
          bottom: 2px;
        }
      }
      .user-data-message {
        margin-left: 30px;
        .user-name {
          font-size: 18px;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 25px;
          height: 25px;
          color: #333333;
          opacity: 1;
          margin-top: 24px;
        }
        .user-message {
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 25px;
          height: 25px;
          color: #555555;
          opacity: 1;
          overflow: hidden;
        }
      }
    }
  }
  .user-share {
    margin: 20px 0px;
  }
}
</style>