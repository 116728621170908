import { render, staticRenderFns } from "./MailboxLogin.vue?vue&type=template&id=462201ff&scoped=true"
import script from "./MailboxLogin.vue?vue&type=script&lang=js"
export * from "./MailboxLogin.vue?vue&type=script&lang=js"
import style0 from "./MailboxLogin.vue?vue&type=style&index=0&id=462201ff&prod&scoped=true&lang=css"
import style1 from "./MailboxLogin.vue?vue&type=style&index=1&id=462201ff&prod&lang=css"
import style2 from "./MailboxLogin.vue?vue&type=style&index=2&id=462201ff&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462201ff",
  null
  
)

export default component.exports