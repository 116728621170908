import MindElementIconCellType from './delegate/MindElementIconCellType'
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil"
import MindIconsManager from "../../../viewmodel/core/tools/MindIconsManager"
import IconElementContent from "../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import imageToStyle from "../../../utils/ImageToolkit"
import Config from '../../../core/core/calcule/Config';
import Point from '../../../viewmodel/core/base/Point';
import { iconChangedColor, iconChangedHexColor } from "../../../assets/js/GlobalVariable.js";

class MindElementIconOutlineCell {

    constructor(viewCanvs, data, type = MindElementIconCellType.ICON, outlineVue) {
        this.deletage;
        this.root = viewCanvs;
        this.data = data;
        this.type = type;
        this.iconImage;     //装图片的盒子
        this.iconImageContent;  //图片标签
        this.iconImageContentShade; //图片遮罩
        this.iconTintColor;
        this.iconRightClick;    //图标右击事件
        this.iconSuspension;    //图标悬浮事件
        this.iconRemove;    //图标移出事件
        this.initView();
        this.isMacSafari;
        this.isMacComputed;
        this.iconSize;
        this.outlineVue = outlineVue;
    }

    mac() {
        return navigator.platform.toLowerCase().indexOf('mac') > -1
    }

    isEelctron() {
        return navigator.userAgent.indexOf('Electron') > -1
    }

    isSafari() {
        return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    }

    initView() {
        this.isMacComputed = this.mac()
        this.isMacSafari = this.isSafari()
        this.iconSize = Config.normalOutlineFontSize
        this.iconImage = document.createElement('div');
        this.iconImage.style.width = this.iconSize + 'px'//this.data.width;
        this.iconImage.style.height = this.iconSize + 'px'//this.data.height;
        this.iconImage.style.display = 'inline-block';
        this.iconImage.style.overflow = "hidden";
        this.iconImage.style.userSelect = 'none';
        this.iconImage.style.margin = '5px';
        this.root.appendChild(this.iconImage)
        this.iconImageContent = document.createElement('img');
        this.iconImageContent.setAttribute('width', this.iconSize + 'px');
        this.iconImageContent.setAttribute('height', this.iconSize + 'px');
        this.iconImageContent.style.display = 'block';
        this.iconImageContent.style.userSelect = 'none';

        let reqSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '.png')

        new imageToStyle().localhostToBase64(reqSrc).then(imageBase64 => {
            this.iconImageContent.setAttribute("src", imageBase64)
        })
        this.setIconBackgroundColor()
        this.iconImage.appendChild(this.iconImageContent)
        this.iconImageContentShade = document.createElement('div');
        this.iconImageContentShade.setAttribute('width', this.iconSize + 'px');
        this.iconImageContentShade.setAttribute('height', this.iconSize + 'px');
        this.iconImageContentShade.style.display = 'block';
        this.iconImageContentShade.style.userSelect = 'none';
        this.root.appendChild(this.iconImageContentShade)
        let dataTintColor = this.data.tintColor;
        if (typeof dataTintColor == 'number' && dataTintColor != 0) {     //判断图标是否是可以改颜色的
            let colorArray = [16711680, 2724553, 14737632, 14092573, 16215840, iconChangedColor.red, iconChangedColor.yellow, iconChangedColor.green, iconChangedColor.blue] //可改变颜色数值
            for (let index = 0, colorLength = colorArray.length; index < colorLength; index++) {
                const color = colorArray[index];
                if (dataTintColor == color) {
                    let dataStyleColor = iconChangedHexColor[dataTintColor];
                    if (!this.isMacComputed) {
                        this.iconImageContent.style.marginLeft = this.iconSize + 'px';
                        this.iconImageContent.style.filter = 'drop-shadow(' + dataStyleColor
                            + ' ' + (-this.iconSize) + 'px ' + 0 + 'px)';
                    }
                }
            }
        }

        let isDarkColor = Colors.isDarkColor(this.data.tintColor);
        if (this.data.key == "mind_link_icon" ||
            this.data.key == "remarks_icon" ||
            this.data.key == "lianxian_map_icon" ||
            this.data.key == "mind_element_more" ||
            this.data.key == "mind_element_xcl" ||
            this.data.key == "mind_element_pdf" ||
            this.data.key == "mind_element_word" ||
            this.data.key == "mind_element_txt" ||
            this.data.key == "mind_element_image" ||
            this.data.key == "mind_element_voide" ||
            this.data.key == "mind_element_audio" ||
            this.data.key == "mind_element_attchment") { //为摘要或者链接的图标
            if (!this.isMacSafari) {
                this.iconImageContent.style.marginLeft = this.iconSize + 'px';
                this.iconImageContent.style.filter = 'drop-shadow(' + Colors.getUiColor(this.data.tintColor) + ' ' + (-this.iconSize) + 'px ' + 0 + 'px)';
            } else {
                if (isDarkColor) {
                    let rSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '.png')

                    new imageToStyle().localhostToBase64(rSrc).then(imageBase64 => {
                        this.iconImageContent.setAttribute("src", imageBase64)
                    })
                } else {
                    let rSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '1.png')

                    new imageToStyle().localhostToBase64(rSrc).then(imageBase64 => {
                        this.iconImageContent.setAttribute("src", imageBase64)
                    })
                }
            }
        }

        //右击事件
        if (this.iconRightClick == null) {
            this.iconRightClick = (ev) => {
                if (ev.button == 2) {
                    window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                    this.onClick(ev)
                }
                if (ev.button === 0) {
                    this.onClick(ev, ev.button)
                }
            }
        }
        // 图标悬浮事件
        let is_icon_menu = true; //判断显示icon弹框的图标，优先级、进度、星星、星期
        let ShowIconMenuTime = null;
        this.iconSuspension = (ev) => {
            let mindMapingEditType = this.outlineVue.mindMapingType;//获取导图编辑状态
            if (is_icon_menu && this.outlineVue && mindMapingEditType && mindMapingEditType.value) {
                // this.outlineVue.mindMapingType判断是否显示icon弹框
                const iconNodeData = { nodeId: this.deletage.mindElementData.id, iconData: this.data };
                const pos = { x: ev.target.offsetLeft, y: ev.target.offsetTop - ev.target.offsetParent.scrollTop };
                //y坐标:偏移量减去滚动条到顶部的距离    
                clearTimeout(this.outlineVue.iconHideTime);
                this.outlineVue.showIconMenu = false; // 更新icon弹框类型
                ShowIconMenuTime = setTimeout(() => {
                    this.deletage.mindTextSizeChangedDelegate.onSelected(this.deletage.mindElementData, new Point(-1, -1));
                    // 判断icon弹框类型,不延时无法更新数据
                    for (let index = 0; index < MindIconsManager.mindIcons.length; index++) {
                        const iconArr = MindIconsManager.mindIcons[index].cells;
                        if (iconArr.find(ele => ele.key === this.data.key)) {
                            this.outlineVue.isShowIconMenu(index, pos, iconNodeData);
                            is_icon_menu = true
                            return  //找到icon弹框类型之后退出循环
                        } else {
                            is_icon_menu = false
                        }
                    }
                }, 200);
            }
        }
        // 图标移出事件
        this.iconRemove = (ev) => {
            clearTimeout(ShowIconMenuTime);
            if (is_icon_menu && this.outlineVue) {
                this.outlineVue.iconHideTime = setTimeout(() => {
                    this.outlineVue.showIconMenu = false;
                }, 3000);
            }
        }
        this.iconImage.addEventListener('mouseup', this.iconRightClick);
        this.iconImageContentShade.addEventListener('mouseup', this.iconRightClick);
        this.iconImage.addEventListener('mouseenter', this.iconSuspension);
        this.iconImageContentShade.addEventListener('mouseenter', this.iconSuspension);
        this.iconImage.addEventListener('mouseleave', this.iconRemove);
        this.iconImageContentShade.addEventListener('mouseleave', this.iconRemove);
    }

    remove() {
        if (this.iconImage != null || this.iconImage.parentNode != null) {
            this.iconImage.parentNode.removeChild(this.iconImage);
            this.iconImage.removeEventListener('mouseup', this.iconRightClick)
            this.iconImage = null;
        }
        if (this.iconImageContentShade != null || this.iconImageContentShade.parentNode != null) {
            this.iconImageContentShade.parentNode.removeChild(this.iconImageContentShade);
            this.iconImageContentShade.removeEventListener('mouseup', this.iconRightClick);
            this.iconImageContentShade = null;
        }
    }

    setIconColor() {
        let styleColor = this.data.tintColor;
        let isDarkColor = Colors.isDarkColor(styleColor);
        if (!this.isMacSafari) {
            this.iconImageContent.style.filter = 'drop-shadow(' + Colors.getUiColor(this.data.tintColor) + ' ' + (-this.iconSize) + 'px ' + 0 + 'px)';
        } else {
            if (isDarkColor) {
                let rSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '1.png')

                new imageToStyle().localhostToBase64(rSrc).then(imageBase64 => {
                    this.iconImageContent.setAttribute("src", imageBase64)
                })
            } else {
                let rSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '.png')

                new imageToStyle().localhostToBase64(rSrc).then(imageBase64 => {
                    this.iconImageContent.setAttribute("src", imageBase64)
                })
            }
        }
        this.setIconBackgroundColor()
    }

    setIconBackgroundColor() {
        if (MindIconsManager.isProgressIcon(this.data.key)) {
            this.iconImageContent.style.backgroundColor = Colors.getUiColor(Colors.white)
            this.iconImageContent.style.borderRadius = "50%"
        } else {
            this.iconImageContent.style.backgroundColor = ""
            this.iconImageContent.style.borderRadius = 0
        }
    }

    setData(data) {
        this.data = data;
        if (this.data != null) {
            this.refresh();
        }
    }

    onClick(event, clickType = null) {
        if (this.deletage != null) {
            this.deletage.onSelectedIcon(this.data, this, event, clickType);

        }
    }

    onLongClick(var1) {
        if (this.deletage != null) {
            this.deletage.onLongClick(var1);
            return true;
        }
        return false;
    }

    refresh() {

        if (Colors.isClear(this.data.styleColor)) {
            this.setBackground((new UiUtil).getDrawable(0, 0, 0, Colors.getUiColor(Colors.clear)));
        } else {
            this.setBackground((new UiUtil).getDrawable(this.iconSize / 2, 0, 0, Colors.getUiColor(this.data.styleColor)));
        }
        if ((!Colors.isClear(this.data.tintColor)) && (this.iconTintColor != this.data.tintColor)) {
            this.iconTintColor = this.data.tintColor;
        }
    }

    refreshSize() {
        this.setSize(this.iconSize, this.iconSize);
        this.setPoint(this.data.x, this.data.y);

        if (!Colors.isClear(this.data.tintColor) && this.iconTintColor != this.data.tintColor) {
            this.iconTintColor = this.data.tintColor;
        }
    }

    setSize(width, height) {
        this.iconImage.setAttribute('width', width);
        this.iconImage.setAttribute('height', height);
        this.iconImageContent.setAttribute('width', width);
        this.iconImageContent.setAttribute('height', height);
        if (this.iconImageContentShade != null) {
            this.iconImageContentShade.setAttribute('width', width);
            this.iconImageContentShade.setAttribute('height', height);
        }
        if (this.data.key == "mind_link_icon" || this.data.key == "remarks_icon") { //为摘要或者链接的图标
            if (!this.isMacSafari) {
                this.iconImageContent.style.marginLeft = this.data.width + 'px';
                this.iconImageContent.style.filter = 'drop-shadow(' + Colors.getUiColor(this.data.tintColor) + ' ' + (-this.data.width) + 'px ' + 0 + 'px)';
            }
        }
        this.setIconBackgroundColor()
    }

    setPoint(x, y) {
        this.iconImage.setAttribute('x', x);
        this.iconImage.setAttribute('y', y);

        if (this.iconImageContentShade != null) {
            this.iconImageContentShade.setAttribute('x', x);
            this.iconImageContentShade.setAttribute('y', y);
        }

        if (this.data.key == "mind_element_xcl" ||
            this.data.key == "mind_element_pdf" ||
            this.data.key == "mind_element_word" ||
            this.data.key == "mind_element_txt" ||
            this.data.key == "mind_element_image" ||
            this.data.key == "mind_element_voide" ||
            this.data.key == "mind_element_audio" ||
            this.data.key == "mind_element_attchment") { //为摘要或者链接的图标
            let reqSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '.png')
            new imageToStyle().localhostToBase64(reqSrc).then(imageBase64 => {
                this.iconImageContent.setAttribute("src", imageBase64)
            })
            let styleColor = this.data.tintColor;
            let isDarkColor = Colors.isDarkColor(styleColor);
            if (!this.isMacSafari) {
                this.iconImageContent.style.marginLeft = this.data.width + 'px';
                this.iconImageContent.style.filter = 'drop-shadow(' + Colors.getUiColor(this.data.tintColor) + ' ' + (-this.data.width) + 'px ' + 0 + 'px)';
            } else {
                if (isDarkColor) {
                    let rSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '.png')

                    new imageToStyle().localhostToBase64(rSrc).then(imageBase64 => {
                        this.iconImageContent.setAttribute("src", imageBase64)
                    })
                } else {
                    let rSrc = require('../../../../static/img/mapContent/assets/icons/' + this.data.key + '.imageset/' + this.data.key + '1.png')

                    new imageToStyle().localhostToBase64(rSrc).then(imageBase64 => {
                        this.iconImageContent.setAttribute("src", imageBase64)
                    })
                }
            }
        }
    }

    setIconTintColor(tintColor = -1) {
        if (arguments.length == 1) {
            this.iconTintColor = tintColor;
        }
        //TODO 更改突变颜色
    }
}

export default MindElementIconOutlineCell