<template>
  <div class="group-scroll-x-box menu-silder">
    <div
      class="mind-class-content clearfix"
      
    >
      <div
        v-for="(each, index) in data"
        :key="index"
        @click="clickCreated(each, index)"
        class="modal-box-children"
      >
        <div class="gray-box"></div>
        <div
          v-if="
            !(each.layoutType == 4 && each.mindType == 1 && each.isEmpty == true)
            
          "
          class=""
          :style="
            'width: 132px;height: 80px;background:url(' +
            each.icon +
            ') no-repeat;background-size: 132px 80px;background-position:center;overflow:hidden;'
          "
        ></div>
        <div
          v-else
          class=""
          :style="
            'width: 132px;height: 80px;background:url(' +
            each.icon +
            ') no-repeat;background-size: cover;background-position:center;overflow:hidden;'
          "
        >
          <div class="empty-text">{{getString(strings.My_Mind_Blank_Mind_Map)}}</div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="showBoxWidth < scrollXWidth"
      @click="scrollRight"
      class="left-scroll-button"
    >
      <a-icon type="left" />
    </div>
    <div
      v-if="showBoxWidth < scrollXWidth"
      @click="scrollLeft"
      class="right-scroll-button"
    >
      <a-icon type="right" />
    </div> -->
  </div>
</template>
<script>
// each.icon.indexOf('left_right_layout_empty') < 0


import CreatMindItmeData from '../../../views/uidata/CreatMindItmeData'

import { mapMutations } from "vuex";
import LineLayout from "../../../viewmodel/datatype/LineLayout"
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType"
import MindType from "../../../viewmodel/datatype/MindType"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import { postMindmapNewSheet } from "../../../common/netWork/mind_map_api";
  if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
    var {ipcRenderer} = window.require('electron')
    // var ipcRenderer = window.ipcRenderer
  }

import { postGroupNewGsheet } from "../../../common/netWork/group_api"
import LoadLocalXJMind from '../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import NewLocalMindParameter from '../../../core/core/dataformat/fileImport/NewLocalMindParameter';
import NewXJMindMap from '../../../core/core/dataformat/fileImport/NewXJMindMap';
// const {ipcRenderer} = window.require('electron')
export default {
  name: "CreatedFileScroll",
  props: {
    data: {
        type:Array,
    },
    groupGmapId: {
      type: String,
    },
    rootId: {
      type: String,
    },
    afterIndex:{
      type: Number,
    },
    sheetName:{
      type: String,
    },
  },
  data() {
    return {
      left: 0,
      fullWidth: document.documentElement.clientWidth,
      showBoxWidth: document.documentElement.clientWidth - 215,
      clickButton: true,
      src: require('../../../../static/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_5.jpg'),
      src1: '../../../../static/img/mapIcon/res/mipmap-xxhdpi/left_right_layout_5.jpg',
      befroeString: '../',
      urlPrefix: '../../../static/img/mapIcon/res/mipmap-xxhdpi/', //地址前缀
      strings:strings
    };
  },
  created() {
  },
  mounted() {
    this.bus.$on("fullWidth", (msg) => {
      this.fullWidth = msg;
    });

  },
  methods: {    
    ...mapMutations(["refreshMyMindList","showLoading"]),
    //语言包
    getString(i){
      return getString(i)
    },
    clickCreated(item, index) {
      
      this.$emit('clickCreated',{created: true })
      this.showLoading(true);
      // return;
      let groupId = this.groupGmapId;
      let rootId = this.rootId; //  父导图id,默认为空
      let name = this.sheetName;
      let beforeIndex = this.afterIndex;
      let type = item.mindType; //  导图类型
      let mindDisplayType = item.mindDisplayType; //  导图模式
      let nodeLayoutType = item.layoutType; //  导图布局
      let lineLayout = item.lineLayout; //  线条类型
      // let isEmpty = false; //  是否是空导图
      let styleIndex = item.styleIndex; //  styleIndex
      let data = {
        rootId,
        name,
        beforeIndex,
        type,
        mindDisplayType,
        nodeLayoutType,
        lineLayout,
        styleIndex,
      };
      let dataGmap = {
        groupId,
        rootId,
        name,
        beforeIndex,
        type,
        mindDisplayType,
        nodeLayoutType,
        lineLayout,
        styleIndex,
      };
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
          let newLocalMindParameter = new NewLocalMindParameter()
          newLocalMindParameter.parentEntityId = ""
          newLocalMindParameter.type = type
          newLocalMindParameter.mindDisplayType = mindDisplayType
          newLocalMindParameter.nodeLayoutType = nodeLayoutType
          newLocalMindParameter.lineLayout = lineLayout
          newLocalMindParameter.isEmpty = false
          newLocalMindParameter.styleIndex = styleIndex
          let parameter = newLocalMindParameter.getJson()
          let that = this;
          new NewXJMindMap(LoadLocalXJMind.getName(), type, this.$router.appPath, parameter).createLocalSheetMindMap(this.sheetName).then((creearData) => {
            if (creearData != null) {
                setTimeout(() => {
                  this.$router.replace({
                    query: {
                      id: creearData.rootId,
                      sheetId: creearData.id,
                      path: encodeURIComponent(creearData.path),
                      localpath: encodeURIComponent(creearData.localPath),
                      parameter: encodeURIComponent(creearData.parameter)
                    },
                  });
                }, 100);
                
            } else {
              that.showLoading(false);
              that.$message.error("创建失败！")
            }
          })
          return
      }

      if(this.$route.name == "Complie" || this.$route.name == "arvhiveMap"){
        postMindmapNewSheet(
          data,
          (res) => {
            this.$router.replace({
              query: {
                id: res.rootId,
                sheetId:res.id,
              },
            });

            // this.$emit('clickCreated',{created: true })
            
            
          },
          (error) => {
            this.showLoading(false);
            this.$message.error(error.desc)
          }
        );
      }else if(this.$route.name == "gmap" ){
        postGroupNewGsheet(
          dataGmap,
          (res) => {
            this.$router.replace({
              // path: "mindmap",
              query: {
                id: res.rootId,
                sheetId:res.id,
              },
            });


          },
          (error) => {
            this.showLoading(false);
            this.$message.error(error.desc)
          }
        );
      }



    },
    scrollLeft() {
      if (this.clickButton == false) {
        return;
      }
      this.clickButton = false;
      let count = 0;
      let timer = setInterval(() => {
        if (this.scrollXWidth - this.showBoxWidth < -this.left) {
          this.left = -(this.scrollXWidth - this.showBoxWidth);
          this.clickButton = true;
          clearInterval(timer);
        } else {
          this.left -= 13.5;
        }
        count++;
        if (count == 9) {
          clearInterval(timer);
          this.clickButton = true;
        }
      }, 50);
    },
    scrollRight() {
      if (this.clickButton == false) {
        return;
      }
      this.clickButton = false;
      let count = 0;
      let timer = setInterval(() => {
        if (0 <= this.left) {
          this.left = 0;
          this.clickButton = true;
          clearInterval(timer);
        } else {
          this.left += 13.5;
        }
        count++;
        if (count == 9) {
          clearInterval(timer);
          this.clickButton = true;
        }
      }, 50);
    },
  },
  watch: {
    fullWidth: function (val) {
      this.showBoxWidth = val - 215;
    },
  },
  computed: {
    scrollXWidth: function () {
      return this.data.length * 180;
    },
  },
};
</script>

<style lang="less" scoped>
.group-scroll-x-box {
  position: relative;
  // height: 176px;
  width: 780px;
  overflow: hidden;
  overflow-x: auto;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 2px;
  margin-left: 15px;
}
.left-scroll-button,
.right-scroll-button {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #666;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  box-shadow: 1px 1px 2px #999;
}
.left-scroll-button {
  left: 0;
  transform: translate(0, -50%);
}
.right-scroll-button {
  right: 0;
  transform: translate(0, -50%);
}
.mind-class-content {
  position: absolute;
  /* width: 600px;
    height: 135px; */
  display: contents; 
  /* justify-content: space-between; */
  /* flex-wrap:nowrap; */
}
.modal-box-children {
  float: left;
  /* width: 120px;
  height: 105px; */
  width: 132px;
  height: 80px;
  margin: 10px 0px 10px 20px;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 3px 0px #d2d2d2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  .empty-text {
    margin: 0 auto;
    margin-top: 90px;
    font-size: 16px;
    text-align: center;
  }
  .gray-box {
    display: none;
  }
}
.modal-box-children:hover{
  box-shadow: 0 0 12px #ccc;
}

.mind-class-content > i {
  width: 120px;
  margin-right: 20px;
  margin-left: 20px;
}

.menu-silder::-webkit-scrollbar {
  // width: 340px;
  height: 5px;
  scrollbar-arrow-color: rgba(0,0,0,0.1);
}
.menu-silder::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: rgba(0,0,0,0.1);
}
.menu-silder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #ffffff;
  border-radius: 16px;
  background: #ffffff;
}
</style>