import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import MindType from "../../../viewmodel/datatype/MindType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import TimeMindTypeNode from "../../../viewmodel/mindelementdata/TimeMindTypeNode"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import TimeDotElementContent from "../../../viewmodel/mindelementdata/mindcontent/TimeDotElementContent"
import HashMap from "../../../viewmodel/core/base/HashMap"
import NodeConnectLineHit from "./NodeConnectLineHit"
import Util from "../../../utils/Util"
import CGRect from "../../../viewmodel/core/base/basedata/Rect"
import UiUtil from "../../../utils/UiUtil"
import MindElementType from "../../../viewmodel/datatype/MindElementType"
import EncircleShapeType from "../../../viewmodel/datatype/EncircleShapeType"
import EncircleTitleLayoutType from "../../../viewmodel/datatype/EncircleTitleLayoutType"
import Colors from "../../../utils/Colors"
import Config from "./Config"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/10
 * Copyright(c) 2020 mindyushu.com
 */

class MindElementHitCalculation {
    constructor() {
        this.explainVagueHeight = new UiUtil().dip2px(25);
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.allEncircleLines = new Array();
    }

    setData(mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
    }

    hitByRect(rect) {
        let mainMindElementDataDictKeys = this.mainMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < mainMindElementDataDictKeys.length; index++) {
            let key = mainMindElementDataDictKeys[index]
            var node = this.mainMindNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHitByRect(rect, node.value)) {
                return node.value;
            }
        }

        let mainMindElementDataDict =  this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < mainMindElementDataDict.length; index++) {
            let key = mainMindElementDataDict[index]
            let  node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHitByRect(rect, node.value)) {
                return node.value;
            }
        }
        let commonGroundMindElementDatasLenght = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length
        for (let index = 0; index < commonGroundMindElementDatasLenght; index++) {
            let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
            if (this.matrixHitByRect(rect, data)) {
                return data;
            }
        }

        let generalizationMindElementDataDict = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()
        for (let index = 0; index < generalizationMindElementDataDict.length; index++ ) {
            let key = generalizationMindElementDataDict[index]
            let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
            if (this.matrixHitByRect(rect, node.value)) {
                return node.value;
            }
        }

        let explainMindElementDataDict = this.mainMindNodeUnit.explainMindElementDataDict.keys()
        let explainMindElementDataDictCount = explainMindElementDataDict.length;
        for (let index = 0; index < explainMindElementDataDictCount; index++ ) {
            let key = explainMindElementDataDict[index]
            let data = this.mainMindNodeUnit.explainMindElementDataDict.get(key);
            if (this.matrixHitByRect(rect, data)) {
                return data;
            }
        }

        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let mainMindElementDataDictArr = unit.mainMindElementDataDict.keys();
            for (let i = 0; i < mainMindElementDataDictArr.length; i++) {
                let key = mainMindElementDataDictArr[i];
                let  node = unit.mainMindElementDataDict.get(key);
                if (this.matrixHitByRect(rect, node.value)) {
                    return node.value;
                }
            }
            let generalizationMindElementDataDictArr = unit.generalizationMindElementDataDict.keys();
            for(let j = 0; j < generalizationMindElementDataDictArr.length; j++) {
                let key = generalizationMindElementDataDictArr[j];
                let node = unit.generalizationMindElementDataDict.get(key);
                if (this.matrixHitByRect(rect, node.value)) {
                    return node.value;
                }
            }

            let unitExplainMindElementDataDict = unit.explainMindElementDataDict.keys()
            let unitExplainMindElementDataDictCount = unitExplainMindElementDataDict.length;
            for (let k = 0; k < unitExplainMindElementDataDictCount; k++ ) {
                let key = unitExplainMindElementDataDict[k]
                let data = unit.explainMindElementDataDict.get(key);
                if (this.matrixHitByRect(rect, data)) {
                    return data;
                }
            }
        }

        let timeMindTypeNodeUnitMainMindElementDataDictArr = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys();
        for (let index = 0; index < timeMindTypeNodeUnitMainMindElementDataDictArr.length; index++) {
            let key = timeMindTypeNodeUnitMainMindElementDataDictArr[index]
            let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHitByRect(rect, node.head)) {
                return node.head;
            }
            if (this.matrixHitByRect(rect, node.title)) {
                return node.title;
            }
            if (this.matrixHitByRect(rect, node.desc)) {
                return node.desc;
            }
        }

        if (this.timeMindTypeNodeUnit.title != null && this.matrixHitByRect(rect, this.timeMindTypeNodeUnit.title)) {
            return this.timeMindTypeNodeUnit.title;
        }

        let nodeConnectLineDataDictArr = this.nodeConnectLineDataDict.keys();
        for (let index = 0; index < nodeConnectLineDataDictArr.length; index++) {
            let key = nodeConnectLineDataDictArr[index];
            let line = this.nodeConnectLineDataDict.get(key);
            if (this.matrixHitByRect(rect, line)) {
                return line;
            }
        }

        this.allEncircleLines = []
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let encircleLinesArr = unit.encircleMindElementDataDict.keys();
            for (let index =0; index < encircleLinesArr.length; index++) {
                let key = encircleLinesArr[index];
                let line = unit.encircleMindElementDataDict.get(key);
                this.allEncircleLines.push(line)
            }
        }
        if (this.mainMindNodeUnit.encircleMindElementDataDict != null && 
            !this.mainMindNodeUnit.encircleMindElementDataDict.isEmpty()) {
            let encircleLinesArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleLinesArr.length; index++) {
                let key = encircleLinesArr[index];
                let line = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
                this.allEncircleLines.push(line)
            }
        }
        if (this.allEncircleLines.length > 1) {
            this.allEncircleLines.sort(function (data1, data2) {
                if (data1.width * data1.height < data2.width * data2.height) {
                    return -1;
                } else if (data1.width * data1.height == data2.width * data2.height) {
                    return 0;
                } else {
                    return 1;
                }
            })
        }
        for (let index = 0; index < this.allEncircleLines.length; index++) {
            if (this.matrixHitByRect(rect, this.allEncircleLines[index])) {
                return this.allEncircleLines[index];
            }
        }

        return  new MindElementData().emptyMindNode();
    }

    hitRadiateData(point) {
        let data = this.hitNodeLineForRadiateNode(this.mainMindNodeUnit.lineMindElementDataDict, point)
        if (!data.isEmpty()) {
            return data
        }
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            data = this.hitNodeLineForRadiateNode(unit.lineMindElementDataDict, point)
            if (!data.isEmpty()) {
                return data
            }
        }
        return  new MindElementData().emptyMindNode();
    }

    hitNodeLineForRadiateNode(lineMindElementDataDict, point) {
        if (lineMindElementDataDict == null) {
            return  new MindElementData().emptyMindNode(); 
        }
        let lineMindElementDataDictKeys = lineMindElementDataDict.keys()
        for (let index = 0; index < lineMindElementDataDictKeys.length; index++) {
            let key = lineMindElementDataDictKeys[index]
            var line = lineMindElementDataDict.get(key);
            
            if (line.layout == NodeLayoutType.LAYOUT_RADIATE && !line.isHidden && line.lineContent != null) {
                let targetNode = this.getNodeById(line.lineContent.targetId)
                let parentNode = this.getNodeById(line.parentNodeId)
                if (parentNode.isEmpty() || targetNode.isEmpty() ||
                    parentNode.value.isHidden || targetNode.value.isHidden ||
                    parentNode.value.layout != NodeLayoutType.LAYOUT_RADIATE || targetNode.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                    continue;
                }
                
                let targetRadius = line.lineContent.cornerRadius
                let targetCenterPoint = new CGPoint(line.x + targetRadius, line.y + targetRadius)
                let targetPointSpace = Util.getPointSpacing(targetCenterPoint, point)
                if (targetPointSpace > targetRadius) {
                    continue
                }
                let targetIndex = 0;
                for (let index = 0; index < parentNode.children.length; index++) {
                    const cell = parentNode.children[index];
                    if (cell.value.id == targetNode.value.id) {
                        targetIndex = index;
                        break
                    }
                }
                let perCenterPoint = null
                let perRadius = 0
                if (targetIndex == 0) {
                    let perLines = this.getNodeLines(parentNode.value.id)
                    if (perLines.length == 0 || perLines[0].layout != NodeLayoutType.LAYOUT_RADIATE) {
                        continue;
                    }
                    perRadius = perLines[0].lineContent.cornerRadius
                    perCenterPoint = new CGPoint(perLines[0].x + perRadius, perLines[0].y + perRadius)
                } else {
                    let perLines = this.getNodeLines(parentNode.children[targetIndex-1].value.id)
                    if (perLines.length == 0 || perLines[0].layout != NodeLayoutType.LAYOUT_RADIATE) {
                        continue;
                    }
                    perRadius = perLines[0].lineContent.cornerRadius
                    perCenterPoint = new CGPoint(perLines[0].x + perRadius, perLines[0].y + perRadius)
                }
                if (perRadius == 0) {
                    continue;
                }
                let perLineSpace = Util.getPointSpacing(perCenterPoint, point)
                if (perLineSpace <= perRadius) {
                    continue;
                }
                if (targetNode.children.length == 0) {
                    return targetNode.value;
                }
                if (point.y < targetNode.value.y) {
                    return targetNode.children[0].value;
                } else {
                    return targetNode.children[targetNode.children.length - 1].value;
                }
            }
        }
        return  new MindElementData().emptyMindNode(); 
    }

    hit(point) {

        let nodeConnectLineDataDictArr = this.nodeConnectLineDataDict.keys();
        for (let index = 0; index < nodeConnectLineDataDictArr.length; index++) {
            let key = nodeConnectLineDataDictArr[index];
            let line = this.nodeConnectLineDataDict.get(key);
            if (this.lineHit(point, line)) {
                return line;
            }
        }
        
        let mainMindElementDataDictKeys = this.mainMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < mainMindElementDataDictKeys.length; index++) {
            let key = mainMindElementDataDictKeys[index]
            var node = this.mainMindNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHit(point, node.value)) {
                return node.value;
            }
        }

        let lineMindElementDataDictKeys = this.mainMindNodeUnit.lineMindElementDataDict.keys()
        for (let index = 0; index < lineMindElementDataDictKeys.length; index++) {
            let key = lineMindElementDataDictKeys[index]
            var line = this.mainMindNodeUnit.lineMindElementDataDict.get(key);
            if (line.type == MindElementType.LAYOUT_CUSTOM_LINE && this.lineHit(point, line)) {
                return line;
            }
        }

        let doubleBubbleMindNodeUnit =  this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < doubleBubbleMindNodeUnit.length; index++) {
            let key = doubleBubbleMindNodeUnit[index]
            let  node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHit(point, node.value)) {
                return node.value;
            }
        }

        let commonGroundMindElementDatasLenght = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length
        for (let index = 0; index < commonGroundMindElementDatasLenght; index++) {
            let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
            if (this.matrixHit(point, data)) {
                return data;
            }
        }

        let generalizationMindElementDataDict = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()
        for (let index = 0; index < generalizationMindElementDataDict.length; index++ ) {
            let key = generalizationMindElementDataDict[index]
            let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
            if (this.matrixHit(point, node.value)) {
                return node.value;
            }
        }

        let explainMindElementDataDict = this.mainMindNodeUnit.explainMindElementDataDict.keys()
        let explainMindElementDataDictCount = explainMindElementDataDict.length;
        for (let index = 0; index < explainMindElementDataDictCount; index++ ) {
            let key = explainMindElementDataDict[index]
            let data = this.mainMindNodeUnit.explainMindElementDataDict.get(key);
            if (this.matrixHit(point, data)) {
                return data;
            }
        }

        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let mainMindElementDataDictArr = unit.mainMindElementDataDict.keys();
            for (let i = 0; i < mainMindElementDataDictArr.length; i++) {
                let key = mainMindElementDataDictArr[i];
                let  node = unit.mainMindElementDataDict.get(key);
                if (this.matrixHit(point, node.value)) {
                    return node.value;
                }
            }

            let lineMindElementDataDictKeys = unit.lineMindElementDataDict.keys()
            for (let index = 0; index < lineMindElementDataDictKeys.length; index++) {
                let key = lineMindElementDataDictKeys[index]
                var line = unit.lineMindElementDataDict.get(key);
                if (line.type == MindElementType.LAYOUT_CUSTOM_LINE && this.lineHit(point, line)) {
                    return line;
                }
            }

            let generalizationMindElementDataDictArr = unit.generalizationMindElementDataDict.keys();
            for(let j = 0; j < generalizationMindElementDataDictArr.length; j++) {
                let key = generalizationMindElementDataDictArr[j];
                let node = unit.generalizationMindElementDataDict.get(key);
                if (this.matrixHit(point, node.value)) {
                    return node.value;
                }
            }

            let unitExplainMindElementDataDict = unit.explainMindElementDataDict.keys()
            let unitExplainMindElementDataDictCount = unitExplainMindElementDataDict.length;
            for (let k = 0; k < unitExplainMindElementDataDictCount; k++ ) {
                let key = unitExplainMindElementDataDict[k]
                let data = unit.explainMindElementDataDict.get(key);
                if (this.matrixHit(point, data)) {
                    return data;
                }
            }
        }

        let timeMindTypeNodeUnitMainMindElementDataDictArr = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys();
        for (let index = 0; index < timeMindTypeNodeUnitMainMindElementDataDictArr.length; index++) {
            let key = timeMindTypeNodeUnitMainMindElementDataDictArr[index]
            let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
            if (this.encircleHit(point, node.head)) {
                return node.head;
            }
            if (this.encircleHit(point, node.title)) {
                return node.title;
            }
            if (this.encircleHit(point, node.desc)) {
                return node.desc;
            }
        }

        if (this.timeMindTypeNodeUnit.title != null && this.matrixHit(point, this.timeMindTypeNodeUnit.title)) {
            return this.timeMindTypeNodeUnit.title;
        }
        if (this.timeMindTypeNodeUnit.baseLine != null && this.timeMindTypeNodeUnit.baseLine.timeLineContent != null &&
                this.matrixHit(point, this.timeMindTypeNodeUnit.baseLine)) {
            
            for (let index = 0; index < this.timeMindTypeNodeUnit.baseLine.timeLineContent.dots.length; index++) {
                let dot = this.timeMindTypeNodeUnit.baseLine.timeLineContent.dots[index];
                let dotGlobalX = dot.x + this.timeMindTypeNodeUnit.baseLine.x;
                let dotGlobalY = dot.y + this.timeMindTypeNodeUnit.baseLine.y;
                if ((point.x) > dotGlobalX - dot.radius * 2 && (point.x) < dotGlobalX + dot.radius * 2 &&
                        (point.y) > dotGlobalY - dot.radius * 2 && (point.y) < dotGlobalY + dot.radius * 2) {
                    dot.isSelected = true;
                    break;
                }
            }
            return this.timeMindTypeNodeUnit.baseLine;
        }

        this.allEncircleLines = []
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let encircleLinesArr = unit.encircleMindElementDataDict.keys();
            for (let index =0; index < encircleLinesArr.length; index++) {
                let key = encircleLinesArr[index];
                let line = unit.encircleMindElementDataDict.get(key);
                this.allEncircleLines.push(line)
            }
        }
        if (this.mainMindNodeUnit.encircleMindElementDataDict != null && 
            !this.mainMindNodeUnit.encircleMindElementDataDict.isEmpty()) {
            let encircleLinesArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleLinesArr.length; index++) {
                let key = encircleLinesArr[index];
                let line = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
                this.allEncircleLines.push(line)
            }
        }
        if (this.allEncircleLines.length > 1) {
            this.allEncircleLines.sort(function (data1, data2) {
                if (data1.width * data1.height < data2.width * data2.height) {
                    return -1;
                } else if (data1.width * data1.height == data2.width * data2.height) {
                    return 0;
                } else {
                    return 1;
                }
            })
        }
        for (let index = 0; index < this.allEncircleLines.length; index++) {
            if (this.matrixHit(point, this.allEncircleLines[index])) {
                return this.allEncircleLines[index];
            }
        }

        return  new MindElementData().emptyMindNode();
    };

    hitLine(point) {

        let nodeConnectLineDataDictArr = this.nodeConnectLineDataDict.keys();
        for (let index = 0; index < nodeConnectLineDataDictArr.length; index++) {
            let key = nodeConnectLineDataDictArr[index];
            let line = this.nodeConnectLineDataDict.get(key);
            if (this.lineHit(point, line)) {
                return line;
            }
        }

        this.allEncircleLines = []
        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            let encircleLinesArr = unit.encircleMindElementDataDict.keys();
            for (let index =0; index < encircleLinesArr.length; index++) {
                let key = encircleLinesArr[index];
                let line = unit.encircleMindElementDataDict.get(key);
                this.allEncircleLines.push(line)
            }
        }
        if (this.mainMindNodeUnit.encircleMindElementDataDict != null && 
            !this.mainMindNodeUnit.encircleMindElementDataDict.isEmpty()) {
            let encircleLinesArr = this.mainMindNodeUnit.encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleLinesArr.length; index++) {
                let key = encircleLinesArr[index];
                let line = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
                this.allEncircleLines.push(line)
            }
        }
        if (this.allEncircleLines.length > 1) {
            this.allEncircleLines.sort(function (data1, data2) {
                if (data1.width * data1.height < data2.width * data2.height) {
                    return -1;
                } else if (data1.width * data1.height == data2.width * data2.height) {
                    return 0;
                } else {
                    return 1;
                }
            })
        }
        for (let index = 0; index < this.allEncircleLines.length; index++) {
            if (this.matrixHit(point, this.allEncircleLines[index])) {
                return this.allEncircleLines[index];
            }
        }

        return  new MindElementData().emptyMindNode();
    };

    hitIncludeTextNode(point) {

        let mainMindElementDataDictKeys = this.mainMindNodeUnit.mainMindElementDataDict.keys()
        for (let index = 0; index < mainMindElementDataDictKeys.length; index++) {
            let key = mainMindElementDataDictKeys[index]
            var node = this.mainMindNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHit(point, node.value)) {
                return node.value;
            }
        }

        let doubleBubbleMindNodeUnit =  this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()
        let doubleBubbleMindNodeUnitCount = doubleBubbleMindNodeUnit.length;
        for (let index = 0; index < doubleBubbleMindNodeUnitCount; index++) {
            let key = doubleBubbleMindNodeUnit[index]
            let  node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHit(point, node.value)) {
                return node.value;
            }
        }
        for (let i = 0; i < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; i++) {
            let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[i];
            if (this.matrixHit(point, data)) {
                return data;
            }
        }
        let generalizationMindElementDataDict = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()
        let generalizationMindElementDataDictCount = generalizationMindElementDataDict.length;
        for (let index = 0; index < generalizationMindElementDataDictCount; index++ ) {
            let key = generalizationMindElementDataDict[index]
            let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
            if (this.matrixHit(point, node.value)) {
                return node.value;
            }
        }

        let explainMindElementDataDict = this.mainMindNodeUnit.explainMindElementDataDict.keys()
        let explainMindElementDataDictCount = explainMindElementDataDict.length;
        for (let index = 0; index < explainMindElementDataDictCount; index++ ) {
            let key = explainMindElementDataDict[index]
            let data = this.mainMindNodeUnit.explainMindElementDataDict.get(key);
            if (this.matrixHit(point, data)) {
                return data;
            }
        }

        let freeNodesCount = this.freeNodes.length;
        for (let index = 0; index < freeNodesCount; index++) {
            let unit = this.freeNodes[index];
            let mainMindElementDataDictArr = unit.mainMindElementDataDict.keys();
            let mainMindElementDataDictArrCount = mainMindElementDataDictArr.length;
            for (let i = 0; i < mainMindElementDataDictArrCount; i++) {
                let key = mainMindElementDataDictArr[i];
                let  node = unit.mainMindElementDataDict.get(key);
                if (this.matrixHit(point, node.value)) {
                    return node.value;
                }
            }
            let generalizationMindElementDataDictArr = unit.generalizationMindElementDataDict.keys();
            let generalizationMindElementDataDictArrCount = generalizationMindElementDataDictArr.length;
            for(let j = 0; j < generalizationMindElementDataDictArrCount; j++) {
                let key = generalizationMindElementDataDictArr[j];
                let node = unit.generalizationMindElementDataDict.get(key);
                if (this.matrixHit(point, node.value)) {
                    return node.value;
                }
            }

            let unitExplainMindElementDataDict = unit.explainMindElementDataDict.keys()
            let unitExplainMindElementDataDictCount = unitExplainMindElementDataDict.length;
            for (let k = 0; k < unitExplainMindElementDataDictCount; k++ ) {
                let key = unitExplainMindElementDataDict[k]
                let data = unit.explainMindElementDataDict.get(key);
                if (this.matrixHit(point, data)) {
                    return data;
                }
            }
        }

        let timeMindTypeNodeUnitMainMindElementDataDictArr = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys();
        let timeMindTypeNodeUnitMainMindElementDataDictArrCount = timeMindTypeNodeUnitMainMindElementDataDictArr.length;
        for (let index = 0; index < timeMindTypeNodeUnitMainMindElementDataDictArrCount; index++) {
            let key = timeMindTypeNodeUnitMainMindElementDataDictArr[index]
            let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
            if (this.encircleHit(point, node.head)) {
                return node.head;
            }
            if (this.encircleHit(point, node.title)) {
                return node.title;
            }
            if (this.encircleHit(point, node.desc)) {
                return node.desc;
            }
        }

        if (this.timeMindTypeNodeUnit.title != null && this.matrixHit(point, this.timeMindTypeNodeUnit.title)) {
            return this.timeMindTypeNodeUnit.title;
        }
        
        return  new MindElementData().emptyMindNode();
    }


    findEncircleLineHit(encircleLines, point) {
        let mainEncircleLineHit = new Array();
        let encircleLinesArr = encircleLines.keys();
        for (let index =0; index < encircleLinesArr.length; index++) {
            let key = encircleLinesArr[index];
            let line = encircleLines.get(key);
            if (this.encircleHit(point, line)) {
                mainEncircleLineHit.push(line);
            }
        }
        if (mainEncircleLineHit.length == 1) {
            return mainEncircleLineHit[0];
        } else if (mainEncircleLineHit.length > 1) {
            let minNode = new LineMindTypeNode();
            let findData = new MindElementData();

            for (let index = 0; index < mainEncircleLineHit.length; index++) {
                let hitData = mainEncircleLineHit[index];
                if (hitData.lineContent.targetIds.length > 0) {
                    for (let i = 0; i < hitData.lineContent.targetIds.length; i++) {
                        let id = hitData.lineContent.targetIds[i]
                        let node = this.getNodeById(id);
                        if (minNode.isEmpty()) {
                            minNode = node;
                            findData = hitData;
                        } else if (minNode.isChildNode(node)) {
                            minNode = node;
                            findData = hitData;
                        }
                    }
                } else if (hitData.lineContent.targetId > IdGenerator.INVALID_ID) {
                    let node = this.getNodeById(hitData.lineContent.targetId);
                    if (minNode.isEmpty()) {
                        minNode = node;
                        findData = hitData;
                    } else if (minNode.isChildNode(node)) {
                        minNode = node;
                        findData = hitData;
                    }
                } else if (hitData.parentNodeId > IdGenerator.INVALID_ID) {
                    let node = this.getNodeById(hitData.parentNodeId);
                    if (minNode.isEmpty()) {
                        minNode = node;
                        findData = hitData;
                    } else if (minNode.isChildNode(node)) {
                        minNode = node;
                        findData = hitData;
                    }
                }
            }
            if (findData.isEmpty()) {
                return mainEncircleLineHit[0];
            } else {
                return findData;
            }
        }
        return  new MindElementData();
    }

    encircleHit(point, data) {
        if (!this.matrixHit(point, data)) {
            return false;
        }
        return true;
    }

    lineHit(point, data) {
        return new NodeConnectLineHit().lineHit(point, data);
    }

    matrixHit(point, data) {
        if (data.isHidden) {
            return false;
        }
        if (data.type == MindElementType.BAOWEI_VIEW) {
            return this.matrixEncircleLinesHit(point, data);
        }
        if (point.x >= data.x && point.x <= data.x + data.width &&
                point.y >= data.y && point.y <= data.y + data.height + this.explainVagueHeight) {
            if (point.y <= data.y + data.height) {
                return true;
            } else if (data.isContainExplainContent()) {
                return data.hitExplain(point);
            }
            return false;
        } else {
            return false;
        }
    }

    matrixEncircleLinesHit(point, data) {
        if (data.type != MindElementType.BAOWEI_VIEW || data.lineContent == null) {
            return this.matrixHit(point, data);
        }
        if (data.hitEncircleTitle(point)) {
            return true;
        } else {
            var points = [];
            if (data.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID &&
                    data.lineContent.encircleNodesPoint.length > 0) {
                for (let index = 0; index < data.lineContent.encircleNodesPoint.length; index++) {
                    points.push(new CGPoint(data.lineContent.encircleNodesPoint[index].x + data.x,
                                    data.lineContent.encircleNodesPoint[index].y + data.y));
                }
            } else if ((data.lineContent.backgroundUrl != null && data.lineContent.backgroundUrl.length > 0) || !Colors.isClear(data.lineContent.color)) {
                let space = new UiUtil().dip2px(5);
                if (point.x >= data.x - space && point.x <= data.x + data.width + space &&
                        point.y >= data.y - space && point.y <= data.y + data.height + space) {
                    return true;
                } else {
                    return false;
                }
            } else {
                var lineTop = 0;
                if (!data.lineContent.isContainText()) {
                    lineTop = 0;
                } else if (data.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
                        data.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
                        data.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    lineTop = data.lineContent.height / 2;
                } else {
                    lineTop = data.lineContent.height;
                }
                points.push(new CGPoint(data.x, data.y + lineTop));
                points.push(new CGPoint(data.x + data.width, data.y + lineTop));
                points.push(new CGPoint(data.x + data.width, data.y + data.height));
                points.push(new CGPoint(data.x, data.y + data.height));
            }
            let space = new UiUtil().dip2px(10);
            if (points.length > 0) {
                for (let index = 0; index < points.length; index++) {
                    var line = [];
                    if (index == points.length - 1) {
                        line.push(points[index]);
                        line.push(points[0]);
                    } else {
                        line.push(points[index]);
                        line.push(points[index + 1]);
                    }
                    let intersectionVerticalPoint = Util.getStaticgetLineSegmentIntersectionVerticalPoint(line[0], line[1], point);
                    let spaceing = Util.getPointSpacing(intersectionVerticalPoint, point);
                    if (spaceing < space) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    matrixHitByRect(rect, data) {
        if (!data.isHidden && Util.isIntersectedForRect(rect, new CGRect(data.x, data.y, data.width, data.height))) {
            return true;
        } else {
            return false;
        }
    }

    getMapBlankSpace(rect) {
        var value = rect;
        let offset = 20;
        let width = rect.width();
        let height = rect.height();
        let widthHalf = rect.width() / 2;
        let heightHalf = rect.height() / 2;
        let changeAngle = 10;
        let scanRadius = offset;
        let centralPoint = new CGPoint(rect.x + widthHalf, rect.y + heightHalf);
        for (let index = 0; index < 60; index++) {
            var list = new Array();
            if (index == 0) {
                list.push(rect);
            } else {
                scanRadius = index * offset;
                changeAngle =  10 / index;
                let angleLangth = 360 /changeAngle;
                if (index <= 20) {
                    angleLangth = 360 /(changeAngle + (20 - index) * 5);
                    changeAngle = 360/angleLangth
                }
                for (let angleIndex = 0; angleIndex < angleLangth; angleIndex++) {
                    let angle = (360 - angleIndex * changeAngle + 270) % 360;
                    let x = centralPoint.x + ((scanRadius) * (Util).cosd(angle));
                    let y = centralPoint.y + ((scanRadius) *  (Util).sind(angle));
                    list.push(new CGRect(x - widthHalf, y - heightHalf, width, height));
                }
            }


            let listSize = list.length;
            if (listSize == 0) {
                continue;
            }
            for (let checkIndex = 0; checkIndex < listSize; checkIndex++) {
                let cell = list[checkIndex];
                if (this.hitByRect(cell).isEmpty()) {
                    return cell;
                }
            }
        }
        return value;
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    getNodeLines(nodeId) {
        let list = new Array();
        let line = this.mainMindNodeUnit.getNodeLine(nodeId);
        if (!line.isEmpty()) {
            list.push(line);
            return list;
        }
        let lines = this.doubleBubbleMindNodeUnit.getNodeLines(nodeId);
        if (lines.length > 0 && (lines.length > 1 || !lines[0])) {
            return lines;
        }
        let lineData = this.freeNodes
        let lineLength = lineData.length
        for (let index = 0; index < lineLength; index++) {
            const unit = lineData[index];
            line = unit.getNodeLine(nodeId);
            if (!line.isEmpty()) {
                list.push(line);
                return list;
            }
        }
        line = this.timeMindTypeNodeUnit.getNodeLine(nodeId);
        if (!line.isEmpty()) {
            list.push(line);
            return list;
        }
        if (this.mindType == MindType.TIME_MAP) {
            list.push(this.timeMindTypeNodeUnit.baseLine);
            return list;
        }
        return list;
    }
}

export default MindElementHitCalculation