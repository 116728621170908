<template>
  <div
    ref="menuIconDialog"
    class="ComplieRightMenuIconDialog"
    @mouseenter="$emit('continuousFontStyleShow')"
    >
    

  <div style="position:  relative;">
    <div class="menu-font-stlye" @click="hideFontStyle('fontSizePlus')">
      <img class="style-font" src="./../../../assets/img/FontStyle/fontSizePlus.png"/>
    </div>

    <div class="menu-font-stlye" @click="hideFontStyle('fontSizeReduce')">
      <img class="style-font" src="./../../../assets/img/FontStyle/fontSizeReduce.png"/>
    </div>

    <div class="menu-font-stlye" >
      <img @click="setTextColorAccout" v-if="textColorAccoutShow" class="style-font" src="./../../../assets/img/FontStyle/fontColor.png"/>
      <img @click="setTextColorAccoutShow" v-if="textColorAccout" class="style-font" src="./../../../assets/img/FontStyle/fontColor2.png"/>
      <div class="menu-right-bg" style="margin-top: -270px;">
          <transition name="slide-fade">
            <CommonBgColor v-if="textColorAccout" style="position: absolute;"/>
          </transition>
        </div>
    </div>    
    <div class="menu-font-stlye" >
      <img @click="setTextShadowColorAccout" v-if="textShadowColorAccoutShow" class="style-font" src="./../../../assets/img/FontStyle/fontShadowColor.png"/>
      <img @click="setTextShadowColorAccoutShow" v-if="textShadowColorAccout" class="style-font" src="./../../../assets/img/FontStyle/fontShadowColor.png"/>
      <div class="menu-right-bg" style="margin-top: -270px;">
          <transition name="slide-fade">
            <CommonBgColor v-if="textShadowColorAccout" style="position: absolute;"/>
          </transition>
        </div>
    </div>
    <div class="menu-font-stlye" >
      <img @click="setTextBgColorAccout" v-if="textBgColorAccoutShow" class="style-font" src="./../../../assets/img/FontStyle/fontBgColor.png"/>
      <img @click="setTextBgColorAccoutShow" v-if="textBgColorAccout" class="style-font" src="./../../../assets/img/FontStyle/fontBgColor.png"/>
      <div class="menu-right-bg" style="margin-top: -270px;">
          <transition name="slide-fade">
            <CommonBgColor v-if="textBgColorAccout" style="position: absolute;"/>
          </transition>
        </div>
    </div>
    <div class="menu-font-stlye" @click="hideFontStyle('fontWeight')">
      <img class="style-font" src="../../../assets/img/FontStyle/fontWeight.png"/>
    </div>

    <div class="menu-font-stlye" @click="hideFontStyle('fontItalic')">
      <img class="style-font" src="../../../assets/img/FontStyle/fontItalic.png"/>
    </div>

    <div class="menu-font-stlye" @click="hideFontStyle('fontUnderline')">
      <img class="style-font" src="../../../assets/img/FontStyle/fontUnderline.png"/>
    </div>

    <div class="menu-font-stlye" @click="hideFontStyle('clearStyle')">
      <img class="style-font" src="../../../assets/img/FontStyle/clearStyle.png"/>
    </div>
</div>
  </div>
</template> 

<script>
import { mapMutations } from "vuex";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import CommonBgColor from "../../common/CommonBgColor/CommonBgColor";
import colorChangeClass from "../../../utils/ColorToSystem";
import EditMindmapVM from '../../../viewmodel/mindelementdata/vm/EditMindmapVM';
export default {
  components: {
    CommonBgColor,
  },
  props: [
    "mind",
    "showFontStyleX",
    "showFontStyleY",
    "showFontStyleHeight",
    "screenRect",
    "showScale",
    "rightMenuAccout",
  ],
  data() {
    return {
      isMac: false,
      isEelctron: false,
      strings,
      fullWidth: document.documentElement.clientWidth,
      boxWidth: null,
      textColorAccout: false, //文字颜色组件是否显示
      textColorAccoutShow: true,
      textBgColorAccout: false, 
      textBgColorAccoutShow: true,
      textShadowColorAccout: false, 
      textShadowColorAccoutShow: true,
      textColor: null,
      palletTop:50,
    };
  },
  created() {
    //是否是mac
    this.isMac = this.$tools.isMacSafari();
    this.isEelctron = this.$tools.isElectron();
    
  },
  mounted() {
    this.boxWidth = this.$refs.menuIconDialog.clientWidth;
    let element = this.$refs.menuIconDialog;
    if (this.mind) {
      let elementLeft = 0;
      let root = element.parentNode
      let selectedData = EditMindmapVM.getSelectedNode().value;

      if (root != null && selectedData != null && !selectedData.isEmpty()) {
        let baseX = root.scrollLeft;
        let baseY = root.scrollTop;

        if (this.showFontStyleX < 260 + baseX) {
          elementLeft = this.showFontStyleX - 30
        } else if (this.showFontStyleX > this.fullWidth - 700 + baseX && this.rightMenuAccout){
          elementLeft = this.fullWidth - 330 - this.boxWidth + baseX
        } else if (this.showFontStyleX > this.fullWidth - 100 + baseX){
          elementLeft = this.fullWidth - 10 - this.boxWidth + baseX
        } else {
          elementLeft = this.showFontStyleX - this.boxWidth / 2 - 10
        }
        if (this.fullWidth + baseX  - this.showFontStyleX < this.boxWidth/2){
          elementLeft = this.showFontStyleX - this.boxWidth*5 / 6
        }
        element.style.left = elementLeft + "px";
        let elementTop = 0;
        if (this.screenRect != null && this.screenRect.y != null) {
          if (this.showFontStyleY - 50 - baseY < 0) {
            elementTop = (selectedData.y + selectedData.height) * this.showScale + 5
          } else {
            elementTop = this.showFontStyleY - 55;
          }
          element.style.top = elementTop + "px";
        }
      }
      
    } else {
      element.style.left = this.showFontStyleX - 30 + "px";
      element.style.top =
        (this.showFontStyleY < 60 ? 120 : this.showFontStyleY) - 60 + "px";
    }
  },
  methods: {
    ...mapMutations([
      "showChunkColor",
      "markColorAccout",

    ]),
    getString(i) {
      return getString(i);
    },
    hideFontStyle(val){
      this.textColorAccout = false; //文字颜色组件是否显示
      this.textColorAccoutShow = true;
      this.textBgColorAccout = false; 
      this.textBgColorAccoutShow = true;

      this.textShadowColorAccout = false; 
      this.textShadowColorAccoutShow = true; 
      let obj = {
        styleName: val,
      }
      this.$emit("hideFontStyle", obj);
    },
    //文字颜色
    setTextColorAccout() {
        this.setTextBgColorAccoutShow();
        this.setTextShadowColorAccoutShow();
        this.textColorAccout = true;
        this.textColorAccoutShow = false;
        this.showChunkColor(true);
        this.markColorAccout('TEXT_STYLE');
    },
    //文字背景颜色
    setTextBgColorAccout() {
        this.setTextColorAccoutShow();
        this.setTextShadowColorAccoutShow();
        this.textBgColorAccout = true;
        this.textBgColorAccoutShow = false;
        this.showChunkColor(true);
        this.markColorAccout('TEXT_BG_COLOR_STYLE');
    },
     //文字背景颜色
    setTextShadowColorAccout() {
        this.setTextColorAccoutShow();
        this.setTextBgColorAccoutShow();
        this.textShadowColorAccout = true;
        this.textShadowColorAccoutShow = false;
        this.showChunkColor(true);
        this.markColorAccout('TEXT_SHADOW_COLOR_STYLE');
    },
    //隐藏颜色框
    setTextBgColorAccoutShow(){
      this.textBgColorAccout = false;
      this.textBgColorAccoutShow = true;
      this.showChunkColor(false);
    },
    //隐藏颜色框
    setTextShadowColorAccoutShow(){
      this.textShadowColorAccout = false;
      this.textShadowColorAccoutShow = true;
      this.showChunkColor(false);
    },
    //隐藏颜色框
    setTextColorAccoutShow(){
      this.textColorAccout = false;
      this.textColorAccoutShow = true;
      this.showChunkColor(false);
    },
    hideFontStyleShow(){
      if (!this.textColorAccout || !this.textBgColorAccout || !this.textShadowColorAccout) {
        this.$emit('hideFontStyleShow')
      }
    }
  },
 
  watch: {
    "$store.state.spanTextColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.textColor = colorChangeClass.nodeUpdataBeforeColor(newval);
      let val = {
        styleName:'fontColor',
        textColor: this.textColor
      }
      // this.textColorAccout = false;
      // this.textColorAccoutShow = true;
      this.$emit("hideFontStyle", val);
    },
    "$store.state.spanTextBgColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.textBgColor = colorChangeClass.nodeUpdataBeforeColor(newval);
      let val = {
        styleName:'fontBgColor',
        textBgColor: this.textBgColor
      }
      // this.textBgColorAccout = false;
      // this.textBgColorAccoutShow = true;
      this.$emit("hideFontStyle", val);
    },
    "$store.state.spanTextShadowColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.textShadowColor = colorChangeClass.nodeUpdataBeforeColor(newval);
      let val = {
        styleName:'textShadowColor',
        textShadowColor: this.textShadowColor
      }
      // this.textShadowColorAccout = false;
      // this.textShadowColorAccoutShow = true;
      this.$emit("hideFontStyle", val);
    },
  },
};
</script>
<style scoped>
.ComplieRightMenuIconDialog {
  position: absolute;
  z-index: 5001;
  width: 387px;
  height: 50px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  border:solid 1px #b6b6b6;
}
.menu-font-stlye {
  position: relative;
  width: 22px;
  height: 22px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  margin: 0 0 2px 2px;
  cursor: pointer;
  margin-top: 15px;
  display: inline-block;
  line-height: 18px;
  margin-left: 20px;
}
.style-font{
  width: 22px;
  height: 22px;
}
 
</style>
