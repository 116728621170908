// package mind.yushu.com.mindmap.viewmodel.core.tools;

import Strings from "../../../utils/Strings";
import UiUtil from "../../../utils/UiUtil";
import IconElementContent from "../../mindelementdata/mindcontent/IconElementContent";
import HashMap from "../base/HashMap";

// import android.content.res.AssetManager;
// import android.graphics.Bitmap;
// import android.graphics.BitmapFactory;
// import android.service.autofill.ImageTransformation;

// import java.io.IOException;
// import java.io.InputStream;
// import java.security.AllPermission;
// import java.util.ArrayList;
// import java.util.HashMap;
// import java.util.List;
// import java.util.Map;

// import mind.yushu.com.R;
// import mind.yushu.com.mindmap.main.MindMapApplication;
// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors'
// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.viewmodel.core.base.MindIconTypeData;
import MindIconTypeData from '../base/MindIconTypeData'
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.IconElementContent;
import ZhCn from "../../../common/lang/zh-cn"
import StringsLanguage from '../../../common/lang/strings'

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/15
 * Copyright(c) 2020 mindyushu.com
 */

class MindIconsManagers {

    constructor() {
        this.mindIcons = new Array();
        this.iconSize = (new UiUtil).dip2px(20);
        this.tabDatas = new Array();
        this.iconMap = new HashMap();
        this.initData();
    }

    get() {
        return this;
    }

    initData() {
        this.setMindIcons();
        this.setTabDatas();
    }

    setTabDatas() {
        this.setBigIcon();
        this.setIcons4(); //学习
        this.setIcons5(); //工作
        this.setIcons1(); //人物
        this.setIcons2(); //动物
        this.setIcons3(); //食物
    }

    setBigIcon() {
        this.iconSize = (new UiUtil).dip2px(40);
        var icons = new Array();
        icons.push(this.creatIconCell("big_1_1"));
        icons.push(this.creatIconCell("big_1_2"));
        icons.push(this.creatIconCell("big_1_3"));
        icons.push(this.creatIconCell("big_1_4"));
        icons.push(this.creatIconCell("big_1_5"));
        icons.push(this.creatIconCell("big_1_6"));
        icons.push(this.creatIconCell("big_1_7"));
        icons.push(this.creatIconCell("big_1_8"));
        icons.push(this.creatIconCell("big_1_9"));
        icons.push(this.creatIconCell("big_1_10"));
        icons.push(this.creatIconCell("big_1_11"));
        icons.push(this.creatIconCell("big_1_12"));
        icons.push(this.creatIconCell("big_1_13"));
        icons.push(this.creatIconCell("big_1_14"));
        icons.push(this.creatIconCell("big_1_15"));
        icons.push(this.creatIconCell("big_1_16"));
        icons.push(this.creatIconCell("big_1_17"));
        icons.push(this.creatIconCell("big_1_18"));
        icons.push(this.creatIconCell("big_1_19"));

        icons.push(this.creatIconCell("big_1_20"));
        icons.push(this.creatIconCell("big_1_21"));
        icons.push(this.creatIconCell("big_1_22"));
        icons.push(this.creatIconCell("big_1_23"));
        icons.push(this.creatIconCell("big_1_24"));
        icons.push(this.creatIconCell("big_1_25"));
        icons.push(this.creatIconCell("big_1_26"));
        icons.push(this.creatIconCell("big_1_27"));
        icons.push(this.creatIconCell("big_1_28"));
        icons.push(this.creatIconCell("big_1_29"));

        icons.push(this.creatIconCell("big_1_30"));
        icons.push(this.creatIconCell("big_1_31"));
        icons.push(this.creatIconCell("big_1_32"));
        icons.push(this.creatIconCell("big_1_33"));
        icons.push(this.creatIconCell("big_1_34"));
        icons.push(this.creatIconCell("big_1_35"));
        icons.push(this.creatIconCell("big_1_36"));
        icons.push(this.creatIconCell("big_1_37"));
        icons.push(this.creatIconCell("big_1_38"));
        icons.push(this.creatIconCell("big_1_39"));

        icons.push(this.creatIconCell("big_1_40"));
        icons.push(this.creatIconCell("big_1_41"));
        icons.push(this.creatIconCell("big_1_42"));
        icons.push(this.creatIconCell("big_1_43"));
        icons.push(this.creatIconCell("big_1_44"));

        icons.push(this.creatIconCell("big_1_45"));
        icons.push(this.creatIconCell("big_1_46"));
        icons.push(this.creatIconCell("big_1_47"));
        icons.push(this.creatIconCell("big_1_48"));
        icons.push(this.creatIconCell("big_1_49"));

        icons.push(this.creatIconCell("big_1_50"));
        icons.push(this.creatIconCell("big_1_51"));
        icons.push(this.creatIconCell("big_1_52"));
        icons.push(this.creatIconCell("big_1_53"));
        icons.push(this.creatIconCell("big_1_54"));

        icons.push(this.creatIconCell("big_1_56"));

        icons.push(this.creatIconCell("big_1_58"));

        icons.push(this.creatIconCell("big_1_60"));

        icons.push(this.creatIconCell("big_1_62"));
        icons.push(this.creatIconCell("big_1_63"));
        icons.push(this.creatIconCell("big_1_64"));
        icons.push(this.creatIconCell("big_1_65"));
        icons.push(this.creatIconCell("big_1_66"));
        icons.push(this.creatIconCell("big_1_67"));
        icons.push(this.creatIconCell("big_1_68"));
        icons.push(this.creatIconCell("big_1_69"));

        icons.push(this.creatIconCell("big_1_70"));
        icons.push(this.creatIconCell("big_1_71"));
        icons.push(this.creatIconCell("big_1_72"));
        icons.push(this.creatIconCell("big_1_73"));
        icons.push(this.creatIconCell("big_1_74"));
        icons.push(this.creatIconCell("big_1_75"));
        icons.push(this.creatIconCell("big_1_76"));
        icons.push(this.creatIconCell("big_1_77"));
        icons.push(this.creatIconCell("big_1_78"));
        icons.push(this.creatIconCell("big_1_79"));

        icons.push(this.creatIconCell("big_1_81"));
        icons.push(this.creatIconCell("big_1_82"));
        icons.push(this.creatIconCell("big_1_83"));
        this.tabDatas.push(new MindIconTypeData("1", icons));
    }

    setIcons1() {
        this.iconSize = (new UiUtil).dip2px(26);
        var icons = new Array();
        icons.push(this.creatIconCell("icon_1_31"));
        icons.push(this.creatIconCell("icon_1_32"));
        icons.push(this.creatIconCell("icon_1_33"));
        icons.push(this.creatIconCell("icon_1_34"));
        icons.push(this.creatIconCell("icon_1_35"));
        icons.push(this.creatIconCell("icon_1_36"));
        icons.push(this.creatIconCell("icon_1_37"));
        icons.push(this.creatIconCell("icon_1_38"));
        icons.push(this.creatIconCell("icon_1_39"));
        icons.push(this.creatIconCell("icon_1_40"));
        icons.push(this.creatIconCell("icon_1_41"));
        icons.push(this.creatIconCell("icon_1_42"));
        icons.push(this.creatIconCell("icon_1_43"));
        icons.push(this.creatIconCell("icon_1_44"));
        icons.push(this.creatIconCell("icon_1_45"));
        icons.push(this.creatIconCell("icon_1_46"));
        icons.push(this.creatIconCell("icon_1_47"));
        icons.push(this.creatIconCell("icon_1_48"));
        icons.push(this.creatIconCell("icon_1_49"));
        icons.push(this.creatIconCell("icon_1_50"));
        icons.push(this.creatIconCell("icon_1_0"));
        icons.push(this.creatIconCell("icon_1_1"));
        icons.push(this.creatIconCell("icon_1_2"));
        icons.push(this.creatIconCell("icon_1_3"));
        icons.push(this.creatIconCell("icon_1_4"));
        icons.push(this.creatIconCell("icon_1_5"));
        icons.push(this.creatIconCell("icon_1_6"));
        icons.push(this.creatIconCell("icon_1_7"));
        icons.push(this.creatIconCell("icon_1_8"));
        icons.push(this.creatIconCell("icon_1_9"));
        icons.push(this.creatIconCell("icon_1_10"));
        icons.push(this.creatIconCell("icon_1_11"));
        icons.push(this.creatIconCell("icon_1_12"));
        icons.push(this.creatIconCell("icon_1_13"));
        icons.push(this.creatIconCell("icon_1_14"));
        icons.push(this.creatIconCell("icon_1_15"));
        icons.push(this.creatIconCell("icon_1_16"));
        icons.push(this.creatIconCell("icon_1_17"));
        icons.push(this.creatIconCell("icon_1_18"));
        icons.push(this.creatIconCell("icon_1_19"));
        icons.push(this.creatIconCell("icon_1_20"));
        icons.push(this.creatIconCell("icon_1_21"));
        icons.push(this.creatIconCell("icon_1_22"));
        icons.push(this.creatIconCell("icon_1_23"));
        icons.push(this.creatIconCell("icon_1_24"));
        icons.push(this.creatIconCell("icon_1_25"));
        icons.push(this.creatIconCell("icon_1_26"));
        icons.push(this.creatIconCell("icon_1_27"));
        icons.push(this.creatIconCell("icon_1_28"));
        icons.push(this.creatIconCell("icon_1_29"));
        icons.push(this.creatIconCell("icon_1_30"));

        this.tabDatas.push(new MindIconTypeData("1", icons));
    }

    setIcons2() {
        this.iconSize = (new UiUtil).dip2px(26);
        var icons = new Array();
        icons.push(this.creatIconCell("icon_2_30"));
        icons.push(this.creatIconCell("icon_2_31"));
        icons.push(this.creatIconCell("icon_2_32"));
        icons.push(this.creatIconCell("icon_2_33"));
        icons.push(this.creatIconCell("icon_2_34"));
        icons.push(this.creatIconCell("icon_2_35"));
        icons.push(this.creatIconCell("icon_2_36"));
        icons.push(this.creatIconCell("icon_2_37"));
        icons.push(this.creatIconCell("icon_2_38"));
        icons.push(this.creatIconCell("icon_2_39"));
        icons.push(this.creatIconCell("icon_2_40"));
        icons.push(this.creatIconCell("icon_2_41"));

        icons.push(this.creatIconCell("icon_2_0"));
        icons.push(this.creatIconCell("icon_2_1"));
        icons.push(this.creatIconCell("icon_2_2"));
        icons.push(this.creatIconCell("icon_2_3"));
        icons.push(this.creatIconCell("icon_2_4"));
        icons.push(this.creatIconCell("icon_2_5"));
        icons.push(this.creatIconCell("icon_2_6"));
        icons.push(this.creatIconCell("icon_2_7"));
        icons.push(this.creatIconCell("icon_2_8"));
        icons.push(this.creatIconCell("icon_2_9"));
        icons.push(this.creatIconCell("icon_2_10"));
        icons.push(this.creatIconCell("icon_2_11"));
        icons.push(this.creatIconCell("icon_2_12"));
        icons.push(this.creatIconCell("icon_2_13"));
        icons.push(this.creatIconCell("icon_2_14"));
        icons.push(this.creatIconCell("icon_2_15"));
        icons.push(this.creatIconCell("icon_2_16"));
        icons.push(this.creatIconCell("icon_2_17"));
        icons.push(this.creatIconCell("icon_2_18"));
        icons.push(this.creatIconCell("icon_2_19"));
        icons.push(this.creatIconCell("icon_2_20"));
        icons.push(this.creatIconCell("icon_2_21"));
        icons.push(this.creatIconCell("icon_2_22"));
        icons.push(this.creatIconCell("icon_2_23"));
        icons.push(this.creatIconCell("icon_2_24"));
        icons.push(this.creatIconCell("icon_2_25"));
        icons.push(this.creatIconCell("icon_2_26"));
        icons.push(this.creatIconCell("icon_2_27"));
        icons.push(this.creatIconCell("icon_2_28"));
        icons.push(this.creatIconCell("icon_2_29"));
        icons.push(this.creatIconCell("icon_2_42"));
        icons.push(this.creatIconCell("icon_2_43"));
        icons.push(this.creatIconCell("icon_2_44"));
        icons.push(this.creatIconCell("icon_2_45"));
        icons.push(this.creatIconCell("icon_2_46"));
        icons.push(this.creatIconCell("icon_2_47"));
        icons.push(this.creatIconCell("icon_2_48"));
        icons.push(this.creatIconCell("icon_2_49"));
        icons.push(this.creatIconCell("icon_2_50"));

        this.tabDatas.push(new MindIconTypeData("2", icons));
    }

    setIcons3() {
        this.iconSize = (new UiUtil).dip2px(26);
        var icons = new Array();
        icons.push(this.creatIconCell("icon_3_26"));
        icons.push(this.creatIconCell("icon_3_27"));
        icons.push(this.creatIconCell("icon_3_28"));
        icons.push(this.creatIconCell("icon_3_29"));
        icons.push(this.creatIconCell("icon_3_30"));
        icons.push(this.creatIconCell("icon_3_31"));
        icons.push(this.creatIconCell("icon_3_32"));
        icons.push(this.creatIconCell("icon_3_33"));
        icons.push(this.creatIconCell("icon_3_34"));
        icons.push(this.creatIconCell("icon_3_35"));
        icons.push(this.creatIconCell("icon_3_36"));
        icons.push(this.creatIconCell("icon_3_37"));
        icons.push(this.creatIconCell("icon_3_38"));
        icons.push(this.creatIconCell("icon_3_39"));
        icons.push(this.creatIconCell("icon_3_40"));
        icons.push(this.creatIconCell("icon_3_41"));
        icons.push(this.creatIconCell("icon_3_42"));

        icons.push(this.creatIconCell("icon_3_0"));
        icons.push(this.creatIconCell("icon_3_1"));
        icons.push(this.creatIconCell("icon_3_2"));
        icons.push(this.creatIconCell("icon_3_3"));
        icons.push(this.creatIconCell("icon_3_4"));
        icons.push(this.creatIconCell("icon_3_5"));
        icons.push(this.creatIconCell("icon_3_6"));
        icons.push(this.creatIconCell("icon_3_7"));
        icons.push(this.creatIconCell("icon_3_8"));
        icons.push(this.creatIconCell("icon_3_9"));
        icons.push(this.creatIconCell("icon_3_10"));
        icons.push(this.creatIconCell("icon_3_11"));
        icons.push(this.creatIconCell("icon_3_12"));
        icons.push(this.creatIconCell("icon_3_13"));
        icons.push(this.creatIconCell("icon_3_14"));
        icons.push(this.creatIconCell("icon_3_15"));
        icons.push(this.creatIconCell("icon_3_16"));
        icons.push(this.creatIconCell("icon_3_17"));
        icons.push(this.creatIconCell("icon_3_18"));
        icons.push(this.creatIconCell("icon_3_19"));
        icons.push(this.creatIconCell("icon_3_20"));
        icons.push(this.creatIconCell("icon_3_21"));
        icons.push(this.creatIconCell("icon_3_22"));
        icons.push(this.creatIconCell("icon_3_23"));
        icons.push(this.creatIconCell("icon_3_24"));
        icons.push(this.creatIconCell("icon_3_25"));

        this.tabDatas.push(new MindIconTypeData("3", icons));
    }

    setIcons4() {
        this.iconSize = (new UiUtil).dip2px(26);
        var icons = new Array();

        icons.push(this.creatIconCell("icon_4_0"));
        icons.push(this.creatIconCell("icon_4_1"));
        icons.push(this.creatIconCell("icon_4_2"));
        icons.push(this.creatIconCell("icon_4_3"));
        icons.push(this.creatIconCell("icon_4_4"));
        icons.push(this.creatIconCell("icon_4_5"));
        icons.push(this.creatIconCell("icon_4_6"));
        icons.push(this.creatIconCell("icon_4_7"));
        icons.push(this.creatIconCell("icon_4_8"));
        icons.push(this.creatIconCell("icon_4_9"));
        icons.push(this.creatIconCell("icon_4_10"));
        icons.push(this.creatIconCell("icon_4_11"));
        icons.push(this.creatIconCell("icon_4_12"));
        icons.push(this.creatIconCell("icon_4_13"));
        icons.push(this.creatIconCell("icon_4_14"));
        icons.push(this.creatIconCell("icon_4_15"));
        icons.push(this.creatIconCell("icon_4_16"));
        icons.push(this.creatIconCell("icon_4_17"));
        icons.push(this.creatIconCell("icon_4_18"));
        icons.push(this.creatIconCell("icon_4_19"));
        icons.push(this.creatIconCell("icon_4_20"));
        icons.push(this.creatIconCell("icon_4_21"));
        icons.push(this.creatIconCell("icon_4_22"));
        icons.push(this.creatIconCell("icon_4_23"));
        icons.push(this.creatIconCell("icon_4_24"));
        icons.push(this.creatIconCell("icon_4_25"));
        icons.push(this.creatIconCell("icon_4_26"));
        icons.push(this.creatIconCell("icon_4_27"));
        icons.push(this.creatIconCell("icon_4_28"));
        icons.push(this.creatIconCell("icon_4_29"));
        icons.push(this.creatIconCell("icon_4_30"));
        icons.push(this.creatIconCell("icon_4_31"));

        icons.push(this.creatIconCell("icon_4_32"));
        icons.push(this.creatIconCell("icon_4_33"));
        icons.push(this.creatIconCell("icon_4_34"));
        icons.push(this.creatIconCell("icon_4_35"));
        icons.push(this.creatIconCell("icon_4_36"));
        icons.push(this.creatIconCell("icon_4_37"));
        icons.push(this.creatIconCell("icon_4_38"));
        icons.push(this.creatIconCell("icon_4_39"));

        icons.push(this.creatIconCell("icon_4_40"));
        icons.push(this.creatIconCell("icon_4_41"));
        icons.push(this.creatIconCell("icon_4_42"));
        icons.push(this.creatIconCell("icon_4_43"));
        icons.push(this.creatIconCell("icon_4_44"));
        icons.push(this.creatIconCell("icon_4_45"));
        icons.push(this.creatIconCell("icon_4_46"));
        icons.push(this.creatIconCell("icon_4_47"));
        icons.push(this.creatIconCell("icon_4_48"));
        icons.push(this.creatIconCell("icon_4_49"));
        this.tabDatas.push(new MindIconTypeData("4", icons));
    }

    setIcons5() {
        this.iconSize = (new UiUtil).dip2px(26);
        var icons = new Array();

        icons.push(this.creatIconCell("icon_5_0"));
        icons.push(this.creatIconCell("icon_5_1"));
        icons.push(this.creatIconCell("icon_5_51"));
        icons.push(this.creatIconCell("icon_5_2"));
        icons.push(this.creatIconCell("icon_5_3"));
        icons.push(this.creatIconCell("icon_5_4"));
        icons.push(this.creatIconCell("icon_5_5"));
        icons.push(this.creatIconCell("icon_5_6"));
        icons.push(this.creatIconCell("icon_5_7"));
        icons.push(this.creatIconCell("icon_5_8"));
        icons.push(this.creatIconCell("icon_5_9"));
        icons.push(this.creatIconCell("icon_5_10"));
        icons.push(this.creatIconCell("icon_5_11"));
        icons.push(this.creatIconCell("icon_5_12"));
        icons.push(this.creatIconCell("icon_5_13"));
        icons.push(this.creatIconCell("icon_5_14"));
        icons.push(this.creatIconCell("icon_5_15"));
        icons.push(this.creatIconCell("icon_5_16"));
        icons.push(this.creatIconCell("icon_5_17"));
        icons.push(this.creatIconCell("icon_5_18"));
        icons.push(this.creatIconCell("icon_5_19"));
        icons.push(this.creatIconCell("icon_5_20"));

        icons.push(this.creatIconCell("icon_5_44"));
        icons.push(this.creatIconCell("icon_5_45"));
        icons.push(this.creatIconCell("icon_5_46"));
        icons.push(this.creatIconCell("icon_5_47"));
        icons.push(this.creatIconCell("icon_5_48"));
        icons.push(this.creatIconCell("icon_5_49"));
        icons.push(this.creatIconCell("icon_5_50"));
        icons.push(this.creatIconCell("icon_5_64"));

        icons.push(this.creatIconCell("icon_5_21"));
        icons.push(this.creatIconCell("icon_5_22"));
        icons.push(this.creatIconCell("icon_5_23"));
        icons.push(this.creatIconCell("icon_5_24"));
        icons.push(this.creatIconCell("icon_5_25"));
        icons.push(this.creatIconCell("icon_5_26"));
        icons.push(this.creatIconCell("icon_5_27"));
        icons.push(this.creatIconCell("icon_5_28"));

        icons.push(this.creatIconCell("icon_5_65"));
        icons.push(this.creatIconCell("icon_5_66"));
        icons.push(this.creatIconCell("icon_5_67"));
        icons.push(this.creatIconCell("icon_5_68"));

        icons.push(this.creatIconCell("icon_5_29"));
        icons.push(this.creatIconCell("icon_5_30"));
        icons.push(this.creatIconCell("icon_5_31"));
        icons.push(this.creatIconCell("icon_5_32"));
        icons.push(this.creatIconCell("icon_5_33"));
        icons.push(this.creatIconCell("icon_5_34"));
        icons.push(this.creatIconCell("icon_5_35"));
        icons.push(this.creatIconCell("icon_5_36"));
        icons.push(this.creatIconCell("icon_5_37"));
        icons.push(this.creatIconCell("icon_5_38"));
        icons.push(this.creatIconCell("icon_5_39"));
        icons.push(this.creatIconCell("icon_5_40"));
        icons.push(this.creatIconCell("icon_5_41"));
        icons.push(this.creatIconCell("icon_5_42"));
        icons.push(this.creatIconCell("icon_5_43"));

        icons.push(this.creatIconCell("icon_5_52"));
        icons.push(this.creatIconCell("icon_5_53"));
        icons.push(this.creatIconCell("icon_5_54"));
        icons.push(this.creatIconCell("icon_5_55"));
        icons.push(this.creatIconCell("icon_5_56"));
        icons.push(this.creatIconCell("icon_5_57"));
        icons.push(this.creatIconCell("icon_5_58"));

        icons.push(this.creatIconCell("icon_5_59"));
        icons.push(this.creatIconCell("icon_5_60"));
        icons.push(this.creatIconCell("icon_5_61"));
        icons.push(this.creatIconCell("icon_5_62"));
        icons.push(this.creatIconCell("icon_5_63"));

        icons.push(this.creatIconCell("icon_5_69"));
        icons.push(this.creatIconCell("icon_5_70"));
        icons.push(this.creatIconCell("icon_5_71"));
        icons.push(this.creatIconCell("icon_5_72"));
        icons.push(this.creatIconCell("icon_5_73"));
        icons.push(this.creatIconCell("icon_5_74"));
        icons.push(this.creatIconCell("icon_5_75"));
        icons.push(this.creatIconCell("icon_5_76"));
        icons.push(this.creatIconCell("icon_5_77"));
        icons.push(this.creatIconCell("icon_5_78"));

        icons.push(this.creatIconCell("icon_5_79"));
        icons.push(this.creatIconCell("icon_5_80"));
        icons.push(this.creatIconCell("icon_5_81"));
        icons.push(this.creatIconCell("icon_5_82"));
        icons.push(this.creatIconCell("icon_5_83"));
        icons.push(this.creatIconCell("icon_5_84"));
        icons.push(this.creatIconCell("icon_5_85"));
        icons.push(this.creatIconCell("icon_5_86"));
        icons.push(this.creatIconCell("icon_5_87"));
        icons.push(this.creatIconCell("icon_5_88"));
        icons.push(this.creatIconCell("icon_5_89"));
        icons.push(this.creatIconCell("icon_5_90"));
        icons.push(this.creatIconCell("icon_5_91"));
        icons.push(this.creatIconCell("icon_5_92"));
        icons.push(this.creatIconCell("icon_5_93"));
        icons.push(this.creatIconCell("icon_5_94"));
        icons.push(this.creatIconCell("icon_5_95"));
        icons.push(this.creatIconCell("icon_5_96"));
        icons.push(this.creatIconCell("icon_5_97"));
        icons.push(this.creatIconCell("icon_5_98"));
        icons.push(this.creatIconCell("icon_5_99"));
        icons.push(this.creatIconCell("icon_5_100"));

        this.tabDatas.push(new MindIconTypeData("5", icons));
    }

    setMindIcons(iconSize) {
        //优先级
        let mindIconTypeData1 = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Priority);
        mindIconTypeData1.addCells([
            new IconElementContent("mind_icon_number_1", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_2", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_3", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_4", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_5", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_6", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_7", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_8", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_9", iconSize, iconSize,
                ),
            new IconElementContent("mind_icon_number_10", iconSize, iconSize,
                )
        ]);
        this.mindIcons.push(mindIconTypeData1);

        //表情
        let emojis = new MindIconTypeData(StringsLanguage.Mind_Expression);
        emojis.addCells([
            new IconElementContent("mind_icon_emoji_0", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_1", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_2", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_3", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_4", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_5", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_6", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_7", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_8", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_emoji_9", iconSize, iconSize,
                Colors.white, Colors.clear)
        ]);
        this.mindIcons.push(emojis);

        //进度
        let mindIconTypeData2 = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Progress);
        mindIconTypeData2.addCells([
            new IconElementContent("progress_0_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_1_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_2_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_3_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_4_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_5_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_6_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_7_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_8_icon", iconSize, iconSize,
                Colors.white, 0xFC4757),
            new IconElementContent("progress_9_icon", iconSize, iconSize,
                Colors.white, 0xFC4757)
        ]);
        this.mindIcons.push(mindIconTypeData2);

        //旗帜
        let flagIcons = new MindIconTypeData(StringsLanguage.Mind_Flag);
        flagIcons.addCells([
            new IconElementContent("mind_icon_flag_0", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_1", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_2", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_3", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_4", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_5", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_6", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_7", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_8", iconSize, iconSize,
                Colors.white, Colors.clear),
            new IconElementContent("mind_icon_flag_9", iconSize, iconSize,
                Colors.white, Colors.clear)
        ]);
        this.mindIcons.push(flagIcons);

        //星星
        let starIcons = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Stars);
        starIcons.addCells([
            new IconElementContent("mind_icon_star_0", iconSize, iconSize,
                Colors.clear, 0xC02A23),
            new IconElementContent("mind_icon_star_1", iconSize, iconSize,
                Colors.clear, 0xFC6333),
            new IconElementContent("mind_icon_star_2", iconSize, iconSize,
                Colors.clear, 0xFFCB00),
            new IconElementContent("mind_icon_star_3", iconSize, iconSize,
                Colors.clear, 0x00A383),
            new IconElementContent("mind_icon_star_4", iconSize, iconSize,
                Colors.clear, 0x1E70FF),
            new IconElementContent("mind_icon_star_5", iconSize, iconSize,
                Colors.clear, 0x5C0BA8),
            new IconElementContent("mind_icon_star_6", iconSize, iconSize,
                Colors.clear, 0xD800AE),
            new IconElementContent("mind_icon_star_7", iconSize, iconSize,
                Colors.clear, 0xF0EAC5),
            new IconElementContent("mind_icon_star_8", iconSize, iconSize,
                Colors.clear, 0xffffff),
            new IconElementContent("mind_icon_star_9", iconSize, iconSize,
                Colors.clear, 0xffffff)
        ]);
        this.mindIcons.push(starIcons);
        // 星星老图片先不删，兼容老版本
        // let mindIconTypeData5 = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Stars);
        // mindIconTypeData5.addCells([
        //         new IconElementContent("mark_star_1_icon", iconSize, iconSize,
        //         Colors.clear, 0xC02A23),
        //         new IconElementContent("mark_star_2_icon", iconSize, iconSize,
        //         Colors.clear, 0xFC6333),
        //         new IconElementContent("mark_star_3_icon", iconSize, iconSize,
        //         Colors.clear, 0xFFCB00),
        //         new IconElementContent("mark_star_4_icon", iconSize, iconSize,
        //         Colors.clear, 0x00A383),
        //         new IconElementContent("mark_star_5_icon", iconSize, iconSize,
        //         Colors.clear, 0x1E70FF),
        //         new IconElementContent("mark_star_6_icon", iconSize, iconSize,
        //         Colors.clear, 0x5C0BA8),
        //         new IconElementContent("mark_star_7_icon", iconSize, iconSize,
        //         Colors.clear, 0xD800AE),
        //         new IconElementContent("mark_star_8_icon", iconSize, iconSize,
        //         Colors.clear, 0xF0EAC5),
        //         new IconElementContent("mark_star_9_icon", iconSize, iconSize,
        //         Colors.clear, 0xffffff)
        // ]);
        // this.mindIcons.push(mindIconTypeData5);

        //人像
        let avatarIcons = new MindIconTypeData(StringsLanguage.Mind_Portrait);
        avatarIcons.addCells([
            new IconElementContent("mind_icon_avatar_0", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_1", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_2", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_3", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_4", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_5", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_6", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_7", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_8", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_avatar_9", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(avatarIcons);

        //箭头
        let arrowIcons = new MindIconTypeData(StringsLanguage.Mind_Arrow);
        arrowIcons.addCells([
            new IconElementContent("mind_icon_arrow_0", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_1", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_2", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_3", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_4", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_5", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_6", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_7", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_8", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_arrow_9", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(arrowIcons);

        //学术
        let mathIcons = new MindIconTypeData(StringsLanguage.Mind_Learning);
        mathIcons.addCells([
            new IconElementContent("mind_icon_math_0", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_1", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_2", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_3", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_4", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_5", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_6", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_7", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_8", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_9", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_10", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_math_11", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(mathIcons);

        //社交
        let socialIcons = new MindIconTypeData(StringsLanguage.Mind_Social_Contact);
        socialIcons.addCells([
            new IconElementContent("mind_icon_shejiao_0", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_1", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_2", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_3", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_4", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_5", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_6", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_7", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_8", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_9", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_shejiao_10", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(socialIcons);

        //符号
        let symbolIcons = new MindIconTypeData(StringsLanguage.Mind_Symbol);
        symbolIcons.addCells([
            new IconElementContent("mind_icon_symbol_0", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_1", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_2", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_3", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_4", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_5", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_6", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_7", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_8", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_9", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_10", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_11", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_12", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_13", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_14", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_15", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_16", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_17", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_18", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_19", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_symbol_20", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(symbolIcons);

        //月份
        let monthIcons = new MindIconTypeData(StringsLanguage.Mind_Month_Icon);
        monthIcons.addCells([
            new IconElementContent("mind_icon_month_0", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_1", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_2", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_3", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_4", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_5", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_6", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_7", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_8", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_9", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_10", iconSize, iconSize,
                Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_month_11", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(monthIcons);

        //星期
        let weekIcons = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Week);
        weekIcons.addCells([
            new IconElementContent("mind_icon_week_0", iconSize, iconSize, Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_week_1", iconSize, iconSize, Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_week_2", iconSize, iconSize, Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_week_3", iconSize, iconSize, Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_week_4", iconSize, iconSize, Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_week_5", iconSize, iconSize, Colors.clear, Colors.clear),
            new IconElementContent("mind_icon_week_6", iconSize, iconSize, Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(weekIcons);
        // 星期头像老图片先不删，兼容老版本
        /*let mindIconTypeData3 = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Week);
        mindIconTypeData3.addCells([
            new IconElementContent("week_1_icon", iconSize, iconSize, Colors.clear, 0xFC4757),
            new IconElementContent("week_2_icon", iconSize, iconSize, Colors.clear, 0xFC4757),
            new IconElementContent("week_3_icon", iconSize, iconSize, Colors.clear, 0xFC4757),
            new IconElementContent("week_4_icon", iconSize, iconSize, Colors.clear, 0xFC4757),
            new IconElementContent("week_5_icon", iconSize, iconSize, Colors.clear, 0xFC4757),
            new IconElementContent("week_6_icon", iconSize, iconSize, Colors.clear, 0xFC4757),
            new IconElementContent("week_7_icon", iconSize, iconSize, Colors.clear, 0xFC4757)
        ]);
        this.mindIcons.push(mindIconTypeData3);

        let mindIconTypeData4 = new MindIconTypeData(StringsLanguage.Mind_Style_Add_Icon_Head_Portrait);
        mindIconTypeData4.addCells([
                new IconElementContent("headportrait_1_icon", iconSize, iconSize,
                Colors.white, 0xC02A23),
                new IconElementContent("headportrait_6_icon", iconSize, iconSize,
                Colors.white, 0xFC6333),
                new IconElementContent("woman_1_icon", iconSize, iconSize,
                Colors.clear, Colors.clear),
                new IconElementContent("woman_2_icon", iconSize, iconSize,
                Colors.clear, Colors.clear),
                new IconElementContent("woman_3_icon", iconSize, iconSize,
                Colors.clear, Colors.clear),
                new IconElementContent("man_1_iocn", iconSize, iconSize,
                Colors.clear, Colors.clear),
                new IconElementContent("man_2_iocn", iconSize, iconSize,
                Colors.clear, Colors.clear),
                new IconElementContent("man_3_iocn", iconSize, iconSize,
                Colors.clear, Colors.clear),
                new IconElementContent("duoren_icon", iconSize, iconSize,
                Colors.clear, Colors.clear)
        ]);
        this.mindIcons.push(mindIconTypeData4);*/
    }

    creatIconCell(key) {
        return new IconElementContent(key, this.iconSize, this.iconSize, Colors.clear, Colors.clear);
    }

    getIconPath(key) {
        if (new Strings().isNullOrEmpty(key)) {
            return "";
        }
        // let path = "../../assets/img/mapContent/assets/icons/big_1_1.imageset/big_1_1.png";
        //let path = "../../../static/img/mapContent/assets/icons/" + key + ".imageset/" + key + ".png";
        let path = location.protocol + '//' + location.host + "/static/img/mapContent/assets/icons/" + key + ".imageset/" + key + ".png";
        return path;
    }

    getStyleImageBitmap(key) {
        var bitmap = null;
        var assetManager = MindMapApplication.getSharedContext().getAssets();
        try {
            var fileName = "style/" + key + ".jpg";
            var inputStream = assetManager.open(fileName);
            bitmap = BitmapFactory.decodeStream(inputStream);
        } catch (e) {
            e.printStackTrace();
        }
        return bitmap;
    }

    isBigIcon(key) {
        let len = this.tabDatas[0].cells.length;
        for (let index = 0; index < len; index++) {
            let icon = this.tabDatas[0].cells[index];
            if (icon.key == key) {
                return true;
            }
        }
        // this.tabDatas[0].cells.forEach(icon => {
        //     if (icon.key == key) {
        //         return true;
        //     }
        // });
        return false;
    }

    isProgressIcon(key) {
        if (key == null || key.length <= 7) {
            return false
        }
        return key.indexOf("progress_") > -1;
    }
}
let MindIconsManager = new MindIconsManagers();
export default MindIconsManager
