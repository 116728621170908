class PlayBackground {
    constructor(key, alignment, textColor, darknessTextColor, lineColor, backgroundColor, borderColor, width = 1000, height = 1000) {
        this.key = key;
        this.alignment = alignment;
        this.textColor = textColor;
        this.darknessTextColor = darknessTextColor;
        this.lineColor = lineColor;
        this.backgroundColor = backgroundColor;        
        this.contentAlignment = "center"; // "center, left, top. right, bottom"
        this.contentLeft = 0.0; //百分比
        this.contentTop = 0.0; //百分比
        this.contentRight = 0.0; //百分比
        this.contentBottom = 0.0; //百分比
        this.isSelected = false;
        this.width = width;
        this.height = height;
        this.borderColor = borderColor;
    }

}

export default PlayBackground
