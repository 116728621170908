import CalculMindElementsRect from "../../core/core/calcule/CalculMindElementsRect"
import MindMapStyleColor from "../../viewmodel/core/base/MindMapStyleColor"

import NodeLayoutType from "../../viewmodel/datatype/NodeLayoutType"
import LineLayout from "../../viewmodel/datatype/LineLayout"
import MindElementData from "../../viewmodel/mindelementdata/MindElementData"
import HashMap from "../core/base/HashMap";
import IdGenerator from "../core/base/IdGenerator";
import TextContent from "./mindcontent/TextContent";
import UiUtil from "../../utils/UiUtil"
import ZhCn from "../../common/lang/zh-cn"
import MindElementCalculation from "../../core/core/calcule/elementCalculation/MindElementCalculation"
import TimeLineContent from "./mindcontent/TimeLineContent";
import MindElementType from "../datatype/MindElementType";
import Colors from "../../utils/Colors";
import TimeLineNodeType from "../datatype/TimeLineNodeType";
import TimeMindTypeNode from "../../viewmodel/mindelementdata/TimeMindTypeNode"
import LineColorMode from "../../viewmodel/core/base/LineColorMode"
import zhCn from "../../common/lang/zh-cn";
import OrientationType from "../datatype/OrientationType";
import TimeLineLayout from "../../viewmodel/core/layout/TimeLineLayout";
import TimeLineHorizontalLayout from "../../viewmodel/core/layout/TimeLineHorizontalLayout";
import TimeLineHorizontalSLayout from "../../viewmodel/core/layout/TimeLineHorizontalSLayout";
import TimeHorizontalCrisscrossLayout from "../../viewmodel/core/layout/TimeHorizontalCrisscrossLayout";
import TimeLadderLayout from "../../viewmodel/core/layout/TimeLadderLayout";

import CGRect from "../core/base/basedata/Rect"
import Strings from "../../utils/Strings"
import strings from "../../common/lang/strings"
import MindLayoutSelect from "../core/tools/setting/MindLayoutSelect"
import EditMindmapVM from "./vm/EditMindmapVM"
import TimeArcLayout from "../core/layout/TimeArcLayout"
import TimeLineBridgeLayout from "../core/layout/TimeLineBridgeLayout"
import TimeLineFramework from "./TimeLineFramework"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class TimeMindTypeNodeUnit {
    constructor() {
        this.delegate;
        this.mindMapStyleColor = new MindMapStyleColor(0);
        this.title = null; //顶部title
        this.baseLine = null; //基础线
        this.mainMindElementDataDict = new HashMap();
        this.elementLayout = new TimeLineLayout();
        this.elementLayout.mindLayoutSelect = MindLayoutSelect
        this.globalLayout = NodeLayoutType.LAYOUT_VERTICAL;
        this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        this.selfStaticDataType = false
        this.mindBGColor = this.mindMapStyleColor.getMindMapFillColor(); // mindBGColor
    }

    getRect(space = -1) {
        if (this.title == null || this.title.isEmpty()) {
            return new CGRect();
        }
        var allElements = new Array();
        allElements.push(this.title);
        allElements.push(this.baseLine);
        this.mainMindElementDataDict.keys().forEach(key => {
            var data = this.mainMindElementDataDict.get(key);
            allElements.push(data.head);
            allElements.push(data.title);
            allElements.push(data.desc);
        });
        if (space == -1) {
            return new CalculMindElementsRect(allElements).getIncludeMargin();
        } else {
            return new CalculMindElementsRect(allElements).getAbsolute();
        }
    }

    clearAllData() {
        this.title = new MindElementData().emptyMindNode();
        this.baseLine = new MindElementData().emptyMindNode();
        this.mainMindElementDataDict.clear();
    }

    setMindMapStyleColor(index) {
        this.mindMapStyleColor = new MindMapStyleColor(index);
    }

    setMindBGColor(value) {
        if (this.title == null || this.title.isEmpty()) {
            return
        }
        if (this.title.backgroundColor == this.mindBGColor) {
            this.title.backgroundColor = value
        }
        this.title.checkSetTextColor(value)
        if (this.title.borderColor == this.mindBGColor) {
            this.title.borderColor = value
        }
        if (this.title.isCardContent()) {
            this.title.checkShapeCard(value)
        }
        this.mainMindElementDataDict.keys().forEach(key => {
            var data = this.mainMindElementDataDict.get(key);
            if (data.head.backgroundColor == this.mindBGColor) {
                data.head.backgroundColor = value;
            }
            data.head.checkSetTextColor(value);
            if (data.head.borderColor == this.mindBGColor) {
                data.head.borderColor = value
            }
            if (data.head.isCardContent()) {
                data.head.checkShapeCard(value)
            }
            if (data.title.backgroundColor == this.mindBGColor) {
                data.title.backgroundColor = value
            }
            data.title.checkSetTextColor(value);
            if (data.title.borderColor == this.mindBGColor) {
                data.title.borderColor = value
            }
            if (data.title.isCardContent()) {
                data.title.checkShapeCard(value)
            }
            if (data.desc.backgroundColor == this.mindBGColor) {
                data.desc.backgroundColor = value
            }
            data.desc.checkSetTextColor(value);
            if (data.desc.borderColor == this.mindBGColor) {
                data.desc.borderColor = value
            }
            if (data.desc.isCardContent()) {
                data.desc.checkShapeCard(value)
            }
        })
        this.mindBGColor = value;
    }

    onSelectedRainbowr(lineColors) {
        if (this.title == null || this.title.isEmpty() || lineColors.length <= 2) {
            return;
        }
        this.setDataColorForRainbowrLine(this.title, lineColors[0])
        if (this.baseLine.timeLineContent != null && lineColors.length > 1) {
            this.baseLine.timeLineContent.color = lineColors[1]
        }
        var colors = []
        for (let index = 1; index < lineColors.length; index++) {
            colors.push(lineColors[index])
        }

        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let length = mainMindElementDataDict.length
        for (let index = 0; index < length; index++) {
            let data = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
            let point = index % colors.length
            this.setDataColorForRainbowrLine(data.head, lineColors[point])
            this.setDataColorForRainbowrLine(data.title, lineColors[point])
            this.setDataColorForRainbowrLine(data.desc, lineColors[point])
        }
    }

    isClearBackgorund(data) {
        return Colors.isClear(data.backgroundColor)
    }
    
    isBackgorundSameMindMapBG(data) {
        return data.backgroundColor == this.mindBGColor
    }
    
    isBorderSameMindMapBG(data) {
        return data.borderColor == this.mindBGColor
    }
    
    isClearBorder(data) {
        return Colors.isClear(data.borderColor)
    }
    
    setDataColorForRainbowrLine(data, lineColor) {
        if (data.isEmpty()) {
            return;
        }
        let isClearBackgorund = this.isClearBackgorund(data)
        let isClearBorder = this.isClearBorder(data)
        let isBackgorundSameMindMapBG = this.isBackgorundSameMindMapBG(data)
        let isBorderSameMindMapBG = this.isBorderSameMindMapBG(data)
        if (!isClearBackgorund && !isBackgorundSameMindMapBG) {
            data.backgroundColor = lineColor
            data.checkSetTextColor(this.mindBGColor)
        }
        if (!isClearBorder && !isBorderSameMindMapBG) {
            data.borderColor = lineColor
        }
    }

    removeElementNode(id) {
        var node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (id == this.baseLine.id) {
            for (var index = 0; index < this.baseLine.timeLineContent.dots.length; index++) {
                var dot = this.baseLine.timeLineContent.dots[index];
                if (dot.isSelected) {
                    this.mainMindElementDataDict.keys().forEach(key => {
                        var data = this.mainMindElementDataDict.get(key);
                        if (data.id == dot.targetId) {
                            this.delegate.removeViewByMindData(data.head);
                            this.delegate.removeViewByMindData(data.title);
                            this.delegate.removeViewByMindData(data.desc);
                            this.mainMindElementDataDict.remove(key);
                        }
                    });
                    this.baseLine.timeLineContent.dots.remove(index);
                    return;
                }
            }
        } else if (node.id != this.title.id) {
            for (var index = 0; index < this.baseLine.timeLineContent.dots.length; index++) {
                let mainMindElementDataDict = this.mainMindElementDataDict.keys()
                let mainMindElementDataDictLength = mainMindElementDataDict.length
                for (let index = 0; index < mainMindElementDataDictLength; index++) {
                    var data = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
                    if (data.head.id == node.id || data.title.id == node.id || data.desc.id == node.id) {
                        this.delegate.removeViewByMindData(data.head);
                        this.delegate.removeViewByMindData(data.title);
                        this.delegate.removeViewByMindData(data.desc);
                        this.mainMindElementDataDict.remove(mainMindElementDataDict[index]);
                        this.baseLine.timeLineContent.dots.remove(index);
                    }
                }
            }
        }
    }

    removeImage(id) {
        var node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }

        if (node.imageContent != null) {
            node.imageContent = null;
            MindElementCalculation.set(node).calcule();
        }

    }

    clearSelected() {
        if (this.title == null ||
            this.title.isEmpty() ||
            this.baseLine == null) {
            return;
        }
        this.title.isSelected = false;
        this.baseLine.isSelected = false;
        if (this.baseLine.timeLineContent != null ||
            this.baseLine.timeLineContent.dots != null) {
            this.baseLine.timeLineContent.dots.forEach(dot => {
                dot.isSelected = false;
            });
        }

        this.mainMindElementDataDict.keys().forEach(key => {
            var data = this.mainMindElementDataDict.get(key);
            data.head.isSelected = false;
            data.title.isSelected = false;
            data.desc.isSelected = false;
        });
    }

    setTimeLineType(type) {
        if (this.baseLine == null || this.baseLine.isEmpty()) {
            return;
        }
        this.baseLine.timeLineContent.nodeType = type;
    }

    setElementLayout(layout) {

        this.globalLayout = layout;
        if (this.title == null || this.title.isEmpty() || this.baseLine == null || this.baseLine.isEmpty()) {
            return;
        }
        if (layout == NodeLayoutType.LAYOUT_VERTICAL) {
            this.elementLayout = new TimeLineLayout();
            this.baseLine.timeLineContent.orientationType = OrientationType.VERTICAL;
        } else if (layout == NodeLayoutType.LAYOUT_HORIZONTAL) {
            this.elementLayout = new TimeLineHorizontalLayout();
            this.baseLine.timeLineContent.orientationType = OrientationType.HORIZONTAL;
        } else if (layout == NodeLayoutType.LAYOUT_BRIDGE) {
            this.elementLayout = new TimeLineBridgeLayout()
            this.baseLine.timeLineContent.orientationType = OrientationType.BRIDGE;
        } else if (layout == NodeLayoutType.LAYOUT_HORIZONTAL_S) {
            this.elementLayout = new TimeLineHorizontalSLayout();
            this.baseLine.timeLineContent.orientationType = OrientationType.HORIZONTAL_S;
        } else if (layout == NodeLayoutType.LAYOUT_LADDER) {
            this.elementLayout = new TimeLadderLayout()
            this.baseLine.timeLineContent.orientationType = OrientationType.LADDER;
        } else if (layout == NodeLayoutType.LAYOUT_HORIZONTAL_CRISSCROSS) {
            this.elementLayout = new TimeHorizontalCrisscrossLayout();
            this.baseLine.timeLineContent.orientationType = OrientationType.HORIZONTAL_CRISSCROSS;
        } else if (layout == NodeLayoutType.LAYOUT_TIME_ARC) {
            this.elementLayout = new TimeArcLayout();
            this.baseLine.timeLineContent.orientationType = OrientationType.SEMICIRCLE;
        } else {
            this.elementLayout = new TimeLineLayout();
            this.baseLine.timeLineContent.orientationType = OrientationType.VERTICAL;
        }
        if (this.elementLayout != null) {
            this.elementLayout.mindLayoutSelect = MindLayoutSelect
        }
    }

    creatTitle() {
        if (this.title != null && !this.title.isEmpty()) {
            return;
        }
        this.title = new MindElementData();
        this.title.id = IdGenerator.shared.getId();

        this.title.type = MindElementType.MAIN_SUBJECT;
        this.title.parentNodeId = IdGenerator.INVALID_ID;
        let text = new UiUtil().getString(strings.Mind_Time_Edit_Main_Idea)
        if (this.globalLayout == NodeLayoutType.LAYOUT_BRIDGE) {
            text = new UiUtil().getString(strings.Mind_Edit_Main_Idea)
        }
        let textContent = new TextContent(text);
        textContent.width = 145;
        textContent.height = 60;
        textContent.x = 0;
        textContent.y = 0;
        this.title.textContent = textContent;
        this.title.level = 0;

        if (this.globalLayout == NodeLayoutType.LAYOUT_BRIDGE) {
            this.title.backgroundColor = Colors.clear;
            this.title.borderColor = Colors.clear;
            this.title.textContent.textColor = Colors.black;
        } else {
            this.title.backgroundColor = this.mindMapStyleColor.getRootNodeFillColor();
            this.title.borderColor = this.mindMapStyleColor.getRootNodeBorderColor();
            this.title.textContent.textColor = this.mindMapStyleColor.getRootNodeTextColor();            
        }
        
        this.title.borderWidth = this.mindMapStyleColor.getRootNodeBorderWidth();        
        this.title.textContent.textFontSize = this.mindMapStyleColor.getrootNodeTextFontSize();
        this.title.textContent.textBold = true

        MindElementCalculation.set(this.title).caluleTextForData().calcule();

        this.baseLine = new MindElementData();
        this.baseLine.id = IdGenerator.shared.getId();
        this.baseLine.type = MindElementType.TIME_LINE_BASE_ELEMENT;
        this.baseLine.parentNodeId = IdGenerator.INVALID_ID;
        let lineContent = new TimeLineContent();
        this.baseLine.timeLineContent = lineContent;
        this.baseLine.level = 0;
        this.baseLine.backgroundColor = Colors.clear;
        this.baseLine.borderColor = Colors.clear;
        this.baseLine.borderWidth = 0;
        this.baseLine.timeLineContent.color = this.mindMapStyleColor.getLineColor();
        this.baseLine.timeLineContent.lineWidth = 1.5;
        this.baseLine.timeLineContent.nodeType = TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER;
        MindElementCalculation.set(this.baseLine).caluleTextForData().calcule();
        this.creatNodeToBottom();
        this.creatNodeToBottom();
        this.creatNodeToBottom();
        this.creatNodeToBottom();
        if (this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_S ||
            this.globalLayout == NodeLayoutType.LAYOUT_BRIDGE) {
            this.creatNodeToBottom();
        }
        if (this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_S ||
            this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_CRISSCROSS) {
            this.baseLine.timeLineContent.isShowArrow = true
        }
        this.setElementLayout(this.globalLayout);
    }

    onTextSizeChanged(text, width, height, id) {
        var data = this.getNodeById(id);
        if (data.isEmpty()) {
            return data;
        }

        var textContent = data.textContent;
        let widhtDifferenceValueAbs = Math.abs(textContent.width - width);
        let heigthDifferenceValueAbs = Math.abs(textContent.height - height);
        textContent.width = width;
        textContent.height = height;
        textContent.text = text;
        MindElementCalculation.set(data).caluleTextForData().calcule();
        if (widhtDifferenceValueAbs < 3 && heigthDifferenceValueAbs < 3) {
            return new MindElementData().emptyMindNode();
        }
        return data;
    }

    getNodeById(id) {
        if (this.title == null || this.title.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }
        if (this.title.id == id) {
            return this.title;
        }
        if (this.baseLine.id == id) {
            return this.baseLine;
        }
        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let length = mainMindElementDataDict.length
        for (let index = 0; index < length; index++) {
            let timeNode = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;
            if (head.id == id) {
                return head;
            } else if (title.id == id) {
                return title;
            } else if (desc.id == id) {
                return desc;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    setFramework(id, settingData) {
        if (this.title == null || this.title.isEmpty()) {
            return;
        }
        let mindMapFramework = new TimeLineFramework(id);

        let rootLayout = mindMapFramework.getMindMapRootLayout();
        let timeLineNodeType = mindMapFramework.getMindMapRootLineLayout();

        this.setElementLayout(rootLayout);
        this.setTimeLineType(timeLineNodeType);
        this.setLineWidth(mindMapFramework.getLineWidth());

        this.title.mindElementShape = mindMapFramework.getRootNodeShapeType();
        this.title.borderWidth = mindMapFramework.getRootBorderWidths();
        this.title.textContent.textFontSize = mindMapFramework.getRootNodeTextSize();
        MindElementCalculation.set(this.title).caluleTextForData();

        this.title.checkShapeCard(this.mindBGColor);

        if (mindMapFramework.isRootNodeShowFullColor()) {
            let borderColor = this.title.borderColor;
            if (Colors.isClear(this.title.backgroundColor) || this.title.backgroundColor == this.mindBGColor) {
                if (!Colors.isClear(borderColor) || borderColor == this.mindBGColor) {
                    this.title.backgroundColor = borderColor;
                } else {
                    if (Colors.isClear(this.mindBGColor)) {
                        if (Colors.isDarkColor(this.title.textContent.textColor)) {
                            this.title.backgroundColor = Colors.white;
                            this.title.borderColor = Colors.white;
                        } else {
                            this.title.backgroundColor = Colors.black;
                            this.title.borderColor = Colors.black;
                        }
                    } else {
                        this.title.backgroundColor = this.mindBGColor;
                        this.title.borderColor = this.mindBGColor;
                    }
                }
            } else {
                this.title.borderColor = this.title.backgroundColor;
            }
        } else {
            let borderColor = this.title.borderColor;
            let backgroundColor = this.title.backgroundColor;
            if (Colors.isClear(this.mindBGColor)) {
                if (Colors.isDarkColor(this.title.textContent.textColor)) {
                    this.title.backgroundColor = Colors.white;
                } else {
                    this.title.backgroundColor = Colors.black;
                }
            } else {
                this.title.backgroundColor = this.mindBGColor;
            }

            if (mindMapFramework.isRootNodeShowBorderColor()) {
                if (Colors.isClear(borderColor) || borderColor == this.mindBGColor) {
                    if (Colors.isClear(backgroundColor) || backgroundColor == this.mindBGColor) {
                        if (Colors.isClear(this.mindBGColor)) {
                            this.title.borderColor = this.title.textContent.textColor;
                        } else if (Colors.isDarkColor(this.mindBGColor)) {
                            this.title.borderColor = Colors.white;
                        } else {
                            this.title.borderColor = Colors.black;
                        }
                    } else {
                        this.title.borderColor = backgroundColor;
                    }
                }
                if (this.title.borderWidth == 0) {
                    this.title.borderWidth = 2.0;
                }
            } else {
                this.title.borderWidth = 0.0;
            }
        }
        this.title.checkSetTextColor(this.mindBGColor);

        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let length = mainMindElementDataDict.length
        for (let index = 0; index < length; index++) {
            let timeNode = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;

            head.mindElementShape = mindMapFramework.getHeadShapeType();
            title.mindElementShape = mindMapFramework.getTitleShapeType();
            desc.mindElementShape = mindMapFramework.getDescNodeShapeType();

            head.borderWidth = mindMapFramework.getHeadBorderWidths();
            title.borderWidth = mindMapFramework.getTitleBorderWidths();
            desc.borderWidth = mindMapFramework.getDescBorderWidths();

            if (mindMapFramework.isHeadShowFullColor()) {
                if (Colors.isClear(head.backgroundColor) || head.backgroundColor == this.mindBGColor) {
                    if (Colors.isClear(head.borderColor) || head.borderColor == this.mindBGColor) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            head.backgroundColor = Colors.white;
                        } else {
                            head.backgroundColor = Colors.black;
                        }
                    } else {
                        head.backgroundColor = head.borderColor;
                    }
                }
            } else if (!Colors.isClear(head.backgroundColor)) {
                head.backgroundColor = Colors.clear;
            }
            if (mindMapFramework.isHeadShowBorderColor()) {
                if (Colors.isClear(head.borderColor) || head.borderColor == this.mindBGColor) {
                    if (Colors.isClear(head.backgroundColor) || head.backgroundColor == this.mindBGColor) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            head.borderColor = Colors.white;
                        } else {
                            head.borderColor = Colors.black;
                        }
                    } else {
                        head.borderColor = head.backgroundColor;
                    }
                }
            } else if (!Colors.isClear(head.borderColor)) {
                head.borderColor = Colors.clear;
            }

            if (mindMapFramework.isTitleShowFullColor()) {
                if (Colors.isClear(title.backgroundColor) || title.backgroundColor == this.mindBGColor) {
                    if (Colors.isClear(title.borderColor) || title.borderColor == this.mindBGColor) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            title.backgroundColor = Colors.white;
                        } else {
                            title.backgroundColor = Colors.black;
                        }
                    } else {
                        title.backgroundColor = title.borderColor;
                    }
                }
            } else if (!Colors.isClear(title.backgroundColor)) {
                title.backgroundColor = Colors.clear;
            }
            if (mindMapFramework.isTitleShowBorderColor()) {
                if (Colors.isClear(title.borderColor) || title.borderColor == this.mindBGColor) {
                    if (Colors.isClear(title.backgroundColor) || title.backgroundColor == this.mindBGColor) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            title.borderColor = Colors.white;
                        } else {
                            title.borderColor = Colors.black;
                        }
                    } else {
                        title.borderColor = title.backgroundColor;
                    }
                }
            } else if (!Colors.isClear(title.borderColor)) {
                title.borderColor = Colors.clear;
            }

            if (mindMapFramework.isDescNodeFullColor()) {
                if (Colors.isClear(desc.backgroundColor) || desc.backgroundColor == this.mindBGColor) {
                    if (Colors.isClear(desc.borderColor) || desc.borderColor == this.mindBGColor) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            desc.backgroundColor = Colors.white;
                        } else {
                            desc.backgroundColor = Colors.black;
                        }
                    } else {
                        desc.backgroundColor = desc.borderColor;
                    }
                }
            } else if (!Colors.isClear(desc.backgroundColor)) {
                desc.backgroundColor = Colors.clear;
            }
            if (mindMapFramework.isDescShowBorderColor()) {
                if (Colors.isClear(desc.borderColor) || desc.borderColor == this.mindBGColor) {
                    if (Colors.isClear(desc.backgroundColor) || desc.backgroundColor == this.mindBGColor) {
                        if (Colors.isDarkColor (this.mindBGColor)) {
                            desc.borderColor = Colors.white;
                        } else {
                            desc.borderColor = Colors.black;
                        }
                    } else {
                        desc.borderColor = desc.backgroundColor;
                    }
                }
            } else if (!Colors.isClear(desc.borderColor)) {
                desc.borderColor = Colors.clear;
            }

            head.checkSetTextColor(this.mindBGColor);
            title.checkSetTextColor(this.mindBGColor);
            desc.checkSetTextColor(this.mindBGColor);
        }
    }

    getTitle() {
        if (this.title == null || (this.title.isEmpty())) {
            return "";
        }
        return this.title.textContent.text;
    }

    getSubtitle() {
        return this.getTitle();
    }

    setLineWidth(value) {
        if (this.baseLine == null || this.baseLine.isEmpty()) {
            return;
        }
        this.baseLine.timeLineContent.lineWidth = value;
    }

    setLineColor(value) {
        if (this.baseLine == null || this.baseLine.isEmpty()) {
            return;
        }
        this.baseLine.timeLineContent.color = value;
    }

    getGolbalLineWidth() {
        if (this.baseLine == null || this.baseLine.isEmpty()) {
            return 1.5;
        }
        return this.baseLine.timeLineContent.lineWidth;
    }

    getGolbalLineColor() {
        if (this.baseLine == null || this.baseLine.isEmpty()) {
            return new LineColorMode().timeLineColor;
        }
        return this.baseLine.timeLineContent.color;
    }

    getNodeLine(nodeId) {
        return this.baseLine != null ? this.baseLine : new MindElementData().emptyMindNode();
    }

    getNodeByLevel(level) {
        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let mainMindElementDataDictLength = mainMindElementDataDict.length
        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            var node = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
            if (node.level == level) {
                return node;
            }
        }
        return new TimeMindTypeNode().emptyMindNode();
    }

    addYoungerBrotherMindElement(targetId) {
        var level = this.getLevel(targetId);
        this.creatNodeToBottomByLevel(level, true);
    }

    addBrotherMindElement(targetId) {
        var level = this.getLevel(targetId);
        this.creatNodeToBottomByLevel(level, false);
    }

    creatNodeToBottom() {
        this.creatNodeToBottomByLevel(-1, true);
    }

    creatNodeToBottomByLevel(level, isBottom) {
        var node = new TimeMindTypeNode();
        node.id = IdGenerator.shared.getId();
        var targetLevel = level > -1 ? level : this.getBottomLevel();
        var brotherNode = this.getNodeByLevel(targetLevel);
        if (isBottom) {
            this.setNodeLevel(targetLevel + 1);
        } else {
            this.setNodeLevel(targetLevel);
        }
        node.level = targetLevel + (isBottom ? 1 : 0);
        this.mainMindElementDataDict.put(node.id, node);

        var head = new MindElementData();
        var title = new MindElementData();
        var desc = new MindElementData();

        node.head = head;
        node.title = title;
        node.desc = desc;

        head.id = IdGenerator.shared.getId();
        head.type = MindElementType.SUBJECT;
        head.parentNodeId = node.id;
        let text = new UiUtil().getString(strings.Mind_Time_Edit_Head)
        if (this.globalLayout == NodeLayoutType.LAYOUT_BRIDGE) {
            text = new UiUtil().getString(strings.Mind_Edit_Title)
        }
        var textContent = new TextContent(text);
        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 65;
        textContent.height = 38;
        head.textContent = textContent;
        head.level = 0;

        title.id = IdGenerator.shared.getId();
        title.type = MindElementType.SUBJECT;
        title.parentNodeId = node.id;
        let titleText = new UiUtil().getString(strings.Mind_Time_Edit_Title)
        if (this.globalLayout == NodeLayoutType.LAYOUT_BRIDGE) {
            titleText = new UiUtil().getString(strings.Mind_Edit_Title)
        }
        var titleTextContent = new TextContent(titleText);
        titleTextContent.x = 0;
        titleTextContent.y = 0;
        titleTextContent.width = 125;
        titleTextContent.height = 38;
        title.textContent = titleTextContent;
        title.level = 0;

        desc.id = IdGenerator.shared.getId();
        desc.type = MindElementType.SON_SUBJECT;
        desc.parentNodeId = node.id;
        let descText = new UiUtil().getString(strings.Mind_Edit_Details)
        if (this.globalLayout == NodeLayoutType.LAYOUT_BRIDGE) {
            descText = "as"
        }
        var descTextContent = new TextContent(descText);
        descTextContent.x = 0;
        descTextContent.y = 0;
        descTextContent.width = 78;
        descTextContent.height = 24;
        desc.textContent = descTextContent;
        desc.level = 0;


        if (brotherNode.isEmpty()) {
            var lineColorMode = new LineColorMode();

            head.backgroundColor = Colors.clear;
            head.borderColor = Colors.clear;
            head.borderWidth = 1.5;
            head.textContent.textColor = this.mindMapStyleColor.getSubjectTextColor();
            head.textContent.textFontSize = this.mindMapStyleColor.getSubjectTextFontSize();
            head.textContent.textBold = true;

            title.backgroundColor = Colors.clear;
            title.borderColor = Colors.clear;
            title.borderWidth = 1.5;
            title.textContent.textColor = this.mindMapStyleColor.getSubjectTextColor();
            title.textContent.textFontSize = this.mindMapStyleColor.getSubjectTextFontSize();
            title.textContent.textBold = true;

            desc.backgroundColor = Colors.clear;
            desc.borderColor = Colors.clear;
            desc.borderWidth = 1.5;
            desc.textContent.textColor = this.mindMapStyleColor.getSubjectTextColor();
            desc.textContent.textFontSize = lineColorMode.sonSubjectTextFontSize;
            desc.textContent.textBold = false;
        } else {
            head.backgroundColor = brotherNode.head.backgroundColor;
            head.backgroundFullType = brotherNode.head.backgroundFullType;
            head.borderColor = brotherNode.head.borderColor;
            head.borderWidth = brotherNode.head.borderWidth;
            head.isShadow = brotherNode.head.isShadow;
            head.textContent.textColor = brotherNode.head.textContent.textColor;
            head.textContent.textShadow = brotherNode.head.textContent.textShadow;
            head.textContent.bgColor = brotherNode.head.textContent.bgColor;
            head.textContent.textFontSize = brotherNode.head.textContent.textFontSize;
            head.textContent.textBold = brotherNode.head.textContent.textBold;
            head.mindElementShape = brotherNode.head.mindElementShape;

            title.backgroundColor = brotherNode.title.backgroundColor;
            title.backgroundFullType = brotherNode.title.backgroundFullType;
            title.borderColor = brotherNode.title.borderColor;
            title.borderWidth = brotherNode.title.borderWidth;
            title.isShadow = brotherNode.title.isShadow;
            title.textContent.textColor = brotherNode.title.textContent.textColor;
            title.textContent.textShadow = brotherNode.title.textContent.textShadow;
            title.textContent.bgColor = brotherNode.title.textContent.bgColor;
            title.textContent.textFontSize = brotherNode.title.textContent.textFontSize;
            title.textContent.textBold = brotherNode.title.textContent.textBold;
            title.mindElementShape = brotherNode.title.mindElementShape;

            desc.backgroundColor = brotherNode.desc.backgroundColor;
            desc.backgroundFullType = brotherNode.desc.backgroundFullType;
            desc.borderColor = brotherNode.desc.borderColor;
            desc.borderWidth = brotherNode.desc.borderWidth;
            desc.isShadow = brotherNode.desc.isShadow;
            desc.textContent.textColor = brotherNode.desc.textContent.textColor;
            desc.textContent.textShadow = brotherNode.desc.textContent.textShadow;
            desc.textContent.bgColor = brotherNode.desc.textContent.bgColor;
            desc.textContent.textFontSize = brotherNode.desc.textContent.textFontSize;
            desc.textContent.textBold = brotherNode.desc.textContent.textBold;
            desc.mindElementShape = brotherNode.desc.mindElementShape;

        }


        MindElementCalculation.set(head).caluleTextForData().calcule();
        MindElementCalculation.set(title).caluleTextForData().calcule();
        MindElementCalculation.set(desc).caluleTextForData().calcule();
    }

    getBottomLevel() {
        var level = -1;
        this.mainMindElementDataDict.keys().forEach(key => {
            var node = this.mainMindElementDataDict.get(key);
            if (node.level > level) {
                level = node.level;
            }
        });
        return level;
    }

    setNodeLevel(level) {
        this.mainMindElementDataDict.keys().forEach(key => {
            var node = this.mainMindElementDataDict.get(key);
            if (node.level >= level) {
                node.level += 1;
            }
        });
    }

    refreshLayout(isChange, settingData) {

        if (this.title == null || (this.title.isEmpty())) {
            return;
        }
        this.elementLayout.setDatas(this.title, this.baseLine, this.mainMindElementDataDict,
            this.mindMapStyleColor, EditMindmapVM.mindBGColor, settingData);
        this.elementLayout.layout(isChange);
    }

    moveNodePoint(moveX, moveY) {
        if (this.title != null && !this.title.isEmpty()) {
            this.title.x += moveX;
            this.title.y += moveY;
        }
    }

    callbackResetDatas(title, baseLine, mainMindElementDataDict) {
        this.title = title;
        this.baseLine = baseLine;
        this.mainMindElementDataDict = mainMindElementDataDict;
    }

    getLevel(id) {
        let keys = this.mainMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            var data = this.mainMindElementDataDict.get(keys[index]);
            if (data.id == id ||
                data.head.id == id ||
                data.title.id == id ||
                data.desc.id == id) {
                return data.level;
            }

        }
        return -1;
    }

    getTimeNodeByDataId(id) {
        let keys = this.mainMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            var data = this.mainMindElementDataDict.get(keys[index]);
            if (data.id == id ||
                data.head.id == id ||
                data.title.id == id ||
                data.desc.id == id) {
                return data;
            }
        }
        return new TimeMindTypeNode().emptyMindNode();
    }

    getNextNodeByLevel(level) {
        var data = new TimeMindTypeNode().emptyMindNode();
        this.mainMindElementDataDict.keys().forEach(key => {
            var cell = this.mainMindElementDataDict.get(key);
            if (cell.level > level) {
                if (data.isEmpty()) {
                    data = cell;
                } else if (cell.level < data.level && !data.isEmpty()) {
                    data = cell;
                }
            }
        });
        return data;
    }

    getPerNodeByLevel(level) {
        var data = new TimeMindTypeNode().emptyMindNode();
        this.mainMindElementDataDict.keys().forEach(key => {
            var cell = this.mainMindElementDataDict.get(key);
            if (cell.level < level) {
                if (data.isEmpty()) {
                    data = cell;
                } else if (cell.level > data.level && !data.isEmpty()) {
                    data = cell;
                }
            }
        });
        return data;
    }

    getTopTimeNode() {
        var data = new TimeMindTypeNode().emptyMindNode();

        this.mainMindElementDataDict.keys().forEach(key => {
            var cell = this.mainMindElementDataDict.get(key);
            if (data.isEmpty()) {
                data = cell;
            } else if (data.level > cell.level) {
                data = cell;
            }
        });
        return data;
    }

    copy() {
        var unit = new TimeMindTypeNodeUnit();
        if (this.title != null && !this.title.isEmpty()) {
            unit.elementLayout = this.elementLayout;
            unit.globalLayout = this.globalLayout;
            unit.globalLineLayout = this.globalLineLayout;
            unit.mindBGColor = this.mindBGColor;
            unit.title = this.title.copy();
            unit.baseLine = this.baseLine.copy();
            unit.delegate = this.delegate;
            this.mainMindElementDataDict.keys().forEach(key => {
                unit.mainMindElementDataDict.put(key, this.mainMindElementDataDict.get(key).copy());
            });
        }
        return unit;
    }

    changeSubjectTextSize(value) {
        let arr = this.mainMindElementDataDict.keys()
        let len = arr.length
        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let data = this.mainMindElementDataDict.get(key);
            if (!data.isEmpty()) {
                data.head.textContent.textFontSize = value;
                data.title.textContent.textFontSize = value;
                MindElementCalculation.set(data.head).caluleTextForData();
                MindElementCalculation.set(data.title).caluleTextForData();
            }
        }
    }

    changeSonSubjectTextSize(value) {
        let arr = this.mainMindElementDataDict.keys()
        let len = arr.length
        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let data = this.mainMindElementDataDict.get(key);
            if (!data.isEmpty()) {
                data.desc.textContent.textFontSize = value;
                MindElementCalculation.set(data.desc).caluleTextForData();
            }
        }
    }

    styleToPeer(node) {
        if (node.isEmpty() || node.value.type != MindElementType.SUBJECT && node.value.type != MindElementType.SON_SUBJECT) {
            return;
        }
        let target = this.getNodeById(node.value.id);
        if (target.isEmpty() || target.id == this.title.id) {
            return;
        }
        var isHead = false;
        var isTitle = false;
        var isDesc = false;
        let mainMindElementDataDict = this.mainMindElementDataDict.keys()
        let mainMindElementDataDictLength = mainMindElementDataDict.length
        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            var data = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
            if (data.head.id == target.id) {
                isHead = true;
                break;
            } else if (data.title.id == target.id) {
                isTitle = true;
                break;
            } else if (data.desc.id == target.id) {
                isDesc = true;
                break;
            }
        }

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            var data = this.mainMindElementDataDict.get(mainMindElementDataDict[index]);
            if (isHead && data.head.id != target.id) {
                data.head.stickStyle(target);
                MindElementCalculation.set(data.head).caluleTextForData();
            } else if (isTitle && data.title.id != target.id) {
                data.title.stickStyle(target);
                MindElementCalculation.set(data.title).caluleTextForData();
            } else if (isDesc && data.desc.id != target.id) {
                data.desc.stickStyle(target);
                MindElementCalculation.set(data.desc).caluleTextForData();
            }
        }
    }

    isRootNode(id) {
        if (this.title == null || this.title.isEmpty()) {
            return false;
        }
        return this.title.id == id;
    }
}

export default TimeMindTypeNodeUnit