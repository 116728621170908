import Strings from "../../../utils/Strings";
import MindElementContent from "./MindElementContent";

class LatexContent extends MindElementContent {
    constructor(text="") {
        super();
        this.text = text;
        this.textFontSize = 16;
    }
    
    copy() {
        let data = new LatexContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.text = this.text;
        data.textFontSize = this.textFontSize;
        return data;
    }

   equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || !new Strings().equals(data.text, this.text);
        result = result || data.textFontSize != this.textFontSize;
        return !result;
    }
    stickStyle( data) {
        if (data == null) {
            return;
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
    }

    stickStyle( data) {
        if (data == null) {
            return;
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
    }
}

export default LatexContent