import {apiRequest} from './base'

//M豆价格列表
export function postMemberMbeansPrices(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/mbeans-prices',req,onOk,onFail,eventually,headers)
}
//上传谷歌Play购买信息
export function postMemberUploadPlayPurchase(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/upload-play-purchase',req,onOk,onFail,eventually,headers)
}
//我的M豆页面信息+存储空间价格信息
export function postMemberMyMbeans(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/my-mbeans',req,onOk,onFail,eventually,headers)
}
//存储价格列表
export function postMemberStoragePrices(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/storage-prices',req,onOk,onFail,eventually,headers)
}
//M豆购买存储空间接口
export function postMemberBuyStorage(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/buy-storage',req,onOk,onFail,eventually,headers)
}
//共享组存储价格列表
export function postMemberGstoragePrices(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/gstorage-prices',req,onOk,onFail,eventually,headers)
}
//组成员上限价格列表
export function postMemberGmemberLimitPrices(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/gmember-limit-prices',req,onOk,onFail,eventually,headers)
}
//购买组成员上限
export function postMemberBuyGmemberLimit(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/buy-gmember-limit',req,onOk,onFail,eventually,headers)
}
//苹果M豆订单回调
export function postMemberAppleMbeansNotify(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/apple-mbeans-notify',req,onOk,onFail,eventually,headers)
}
//我的账户
export function postMemberMyAccount(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/my-account',req,onOk,onFail,eventually,headers)
}
//提现
export function postMemberWithdraw(req,onOk,onFail,eventually,headers){
    apiRequest('POST','/member/withdraw',req,onOk,onFail,eventually,headers)
}
