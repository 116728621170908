let ResourceType = {
    WORD:0,//word文档
    EXCEL:1,//Excel文档
    PDF:2,//pdf文档
    TXT:3,//txt文档
    IMAGE:4,//图片
    VIDEO:5,//视频
    AUDIO:6,//录音
    ATTCHMENT:7,//附件，其余任何文件

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.ATTCHMENT;
    },

    fromByStr:function(value) {
        var resoType = ResourceType.ATTCHMENT;
        if (value == "application/msword" ||
            value == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            resoType = ResourceType.WORD;
          } else if (value == "application/pdf") {
            resoType = ResourceType.PDF;
          } else if (value == "application/vnd.ms-excel" ||
                    value == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            resoType = ResourceType.EXCEL;
          } else if (value == "text/plain") {
            resoType = ResourceType.TXT;
          } else if (value.indexOf("image/") > -1) {
            resoType = ResourceType.IMAGE;
          } else if (value.indexOf("video/") > -1) {
            resoType = ResourceType.VIDEO;
          } else if (value.indexOf("audio/") > -1) {
            resoType = ResourceType.AUDIO;
          }
        return resoType;
    }
}

export default ResourceType