import Colors from "../../../utils/Colors"
import LinePath from "../../../viewmodel/core/base/LinePath"
import CGPoint from '../../../viewmodel/core/base/basedata/CGPoint'

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */
import BaseElementView from "./BaseElementView";



class FormLineView extends BaseElementView {
    constructor(viewCanvs, data) {
        super(viewCanvs, data)
        this.path;  //线条
        this.formLineArray = new Array(); //表格线条数组。
    }

    initView() {
        super.initView();
        this.onDraw();
    }

    refresh() {
        if (!this.isRefresh) {
            return;
        }
        super.refresh();
        this.onDraw();
    }

    onDraw() {
        this.drawLine();
    }

    drawLine() {

        if (this.mindElementData == null ||
            this.mindElementData.lineContent == null || this.mindElementData.lineContent.formPoint.length == 0) {
            return;
        }
        let lineMindElementContent = this.mindElementData.lineContent;
        this.lineWidth = lineMindElementContent.lineWidth;
        this.color = lineMindElementContent.color
        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
        }
        let linePath = new LinePath()
        var lastPoint = new CGPoint(0, 0)
        if (lineMindElementContent.formPoint.length >= 4) {
            let fristLineStartPoint = new CGPoint(0, 0)
            let fristLineEndPoint = new CGPoint(0, 0)
            for (let i = 0; i < 4; i++) {
                let point = lineMindElementContent.formPoint[i]
                let one = new CGPoint(point.startX, point.startY)
                let two = new CGPoint(point.endX, point.endY)
                if (i == 0) {
                    fristLineStartPoint = one;
                    fristLineEndPoint = two;
                }
                if (i > 0 && lastPoint.x == one.x && lastPoint.y == one.y) {
                    linePath.lineTo(one.x, one.y);
                } else {
                    linePath.moveTo(one.x, one.y);
                }
                linePath.lineTo(two.x, two.y);
                lastPoint = two
            }
            if (lastPoint.x == fristLineStartPoint.x && lastPoint.y == fristLineStartPoint.y) {
                linePath.lineTo(fristLineEndPoint.x, fristLineEndPoint.y);
                lastPoint = fristLineEndPoint;
            }
        }
        if (lineMindElementContent.formPoint.length > 4) {
            for (let i = 4; i < lineMindElementContent.formPoint.length; i++) {
                let point = lineMindElementContent.formPoint[i]
                let one = new CGPoint(point.startX, point.startY)
                let two = new CGPoint(point.endX, point.endY)
                if (i > 0 && lastPoint.x == one.x && lastPoint.y == one.y) {
                    linePath.lineTo(one.x, one.y);
                } else {
                    linePath.moveTo(one.x, one.y);
                }
                linePath.lineTo(two.x, two.y);
                lastPoint = two
            }
        }
       
        this.renderCanvas(linePath);
        this.setBackgroundView();
    }
    //自己添加的一个函数
    renderCanvas(path) {
        let lineMindElementContent = this.mindElementData.lineContent;
        this.path.setAttribute("d", path.getLine())
        if (this.isShadow()) {
            this.path.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.color))
            this.path.setAttribute("stroke-width", lineMindElementContent.lineWidth)
        }


        this.path.setAttribute("fill", "none")
    }

    createBackgroundView() {
    }
    isShadow() {

        return true
    }
}

export default FormLineView