// package mind.yushu.com.mindmap.main.book.ui;

import Config from "../../../core/core/calcule/Config";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import Colors from "../../../utils/Colors";
import Strings from "../../../utils/Strings";
import dataTimeToFormats from "../../../utils/timestampToTime";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import LinePath from "../../../viewmodel/core/base/LinePath";
import httpImageToPrefix from '../../../utils/httpImageToPrefix';

class BookView {
    constructor(context, book) {
        this.UiUtil = new UiUtil();
        this.Util = Util;
        this.Strings = new Strings();
        this.horizontalOffset = 1; //书倾斜度
        this.thickness = 2; //书厚度
        this.edgeHieght = this.UiUtil.dip2px(2); //书边缘高度
        this.corner = this.UiUtil.dip2px(4); //书正面矩形圆角
        this.margin = this.UiUtil.dip2px(4);
        this.frontBottomRectTop = 0;
        // this.titlePaint = new Paint();
        // this.paint = new Paint();
        // this.mPaint;
        this.mXfermode;
        this.frontLeft = 0;
        this.bitmap = null;
        this.frontBottomColors = 0xF7F7F7;
        this.book;

        this.context = context; //画布的父元素
        this.canvasTag; // 画布标签
        this.gTag; //g标签
        this.BookView(context, book);
    }


    BookView(context, book) {
        // super(context);
        this.book = book;
        this.thickness = this.book.height/19;
        this.horizontalOffset = this.thickness/2;
        this.frontBottomRectTop = this.book.height / 16 * 9;
        this.frontLeft = this.edgeHieght + this.horizontalOffset/2*3;
        this.onDraw();
    }

    setData( book) {
        this.book = book;
        this.bitmap = null;
        // readLoadImage();
        // invalidate();
    }

    getData() {
        return this.book;
    }

    // @Override
    onDraw() {
        let backRect = new CGRect(0, 0, this.book.width - this.horizontalOffset, this.book.height);
        //头部的矩形
        let frontRect = new CGRect(this.frontLeft, this.thickness + this.edgeHieght, this.book.width - this.frontLeft, this.book.height - this.edgeHieght - this.thickness);
        let interlayerRect = new CGRect(this.edgeHieght, this.edgeHieght, this.book.width - this.edgeHieght, this.thickness);
        //底部波浪矩形
        let frontBottomRect = new CGRect(this.frontLeft, this.frontBottomRectTop, this.book.width - this.frontLeft, this.book.height - this.frontBottomRectTop);
        let emptyWidth = (this.book.width - this.frontLeft)/3;
        let emptyRect = new CGRect(this.frontLeft + (this.book.width - this.frontLeft - emptyWidth)/2,
                this.thickness + (this.book.height - this.thickness - emptyWidth)/2,
                 emptyWidth,
                 emptyWidth);
    
        this.createdCanvas();

        if (Colors.getUiColor(this.book.color) == Colors.getUiColor(Colors.white)) {
            this.drawRect(backRect, "", 0, Colors.getNumberToRgbDark(Colors.black35), this.corner);
            this.drawInterlayer(interlayerRect);
            this.drawFrontRect(frontRect, "", 0, Colors.getUiColor(Colors.black35), this.corner);
        } else {
            this.drawRect(backRect, "", 0, Colors.getNumberToRgbDark(this.book.color), this.corner);
            this.drawInterlayer(interlayerRect);
            this.drawFrontRect(frontRect, "", 0, Colors.getUiColor(this.book.color), this.corner);
        }

        this.drawFrontBottomRect(frontBottomRect, "", 0, Colors.getUiColor(this.frontBottomColors), this.corner);

        //不为空时执行
        if (!this.book.isEmpty()) {
            this.drawTitleAndTime(frontRect, frontBottomRect);
            this.drawSheetNumber();
            this.drawImage();
        }

        this.drawEmptyPlus(emptyRect);
    }

    createdCanvas() {   //创建canvas和g标签
        if (this.canvasTag != null) {
            this.canvasTag.parentNode.removeChild(this.canvasTag);
            this.canvasTag = null
        }

        this.canvasTag = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        this.canvasTag.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        this.canvasTag.style.width = this.book.width + "px";
        this.canvasTag.style.height = this.book.height + "px";
        this.context.appendChild(this.canvasTag);

        this.gTag = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        this.canvasTag.appendChild(this.gTag);

    }
    drawEmptyPlus( inRect) {
        if (!this.book.isEmpty()) {
            return;
        }
        let path1 = new LinePath();
        path1.moveTo(inRect.x, inRect.y + inRect.height()/2);
        path1.lineTo(inRect.x + inRect.width(), inRect.y + inRect.height()/2);

        let path2 = new LinePath();

        path2.moveTo(inRect.x + inRect.width()/2, inRect.y);
        path2.lineTo(inRect.x + inRect.width()/2, inRect.y + inRect.height());

        this.renderObj({ dom: null,
            d: path1, 
            stroke: Colors.getUiColor(0xC7C7C7), 
            strokeWidth: this.UiUtil.dip2px(5), 
            // fill: , 
            strokeLinecap:'round' })

        
            this.renderObj({ dom: null,
                d: path2, 
                stroke: Colors.getUiColor(0xC7C7C7), 
                strokeWidth: this.UiUtil.dip2px(5), 
                // fill: , 
                strokeLinecap:'round' })
    }

    drawInterlayer(inRect) {  //夹层
        let path = new LinePath();
        let topLeft = new CGPoint(inRect.x + this.horizontalOffset/2, inRect.y);
        let bottomLeft = new CGPoint(topLeft.x + this.horizontalOffset, inRect.y + inRect.height());
        let middleLeft = new CGPoint(topLeft.x - this.horizontalOffset/4, inRect.y + inRect.height()/8 * 3);

        let topRight = new CGPoint(inRect.width() - this.horizontalOffset, inRect.y);
        let bottomRight = new CGPoint(inRect.width(),  inRect.y + inRect.height());
        let middleRight = new CGPoint(topRight.x - this.horizontalOffset/4, topRight.y + inRect.height()/8 * 3);

        let leftCircleCenter = this.Util.getCircleCenter(topLeft, middleLeft, bottomLeft);
        let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, bottomLeft);
        let leftStartAngle = this.Util.getCircleDegreesInPoint(leftCircleCenter, bottomLeft);
        let leftEndAngle = this.Util.getCircleDegreesInPoint(leftCircleCenter, topLeft);

        let rightCircleCenter = this.Util.getCircleCenter(topRight, middleRight, bottomRight);
        let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, topRight);
        let rightStartAngle = this.Util.getCircleDegreesInPoint(rightCircleCenter, topRight);
        let rightEndAngle = this.Util.getCircleDegreesInPoint(rightCircleCenter, bottomRight);

        path.moveTo(topLeft.x, topLeft.y);
        path.lineTo(topRight.x, topRight.y);

        path.circle(rightCircleRadius, rightCircleRadius, 0, 0, 0, bottomRight.x, bottomRight.y);
        path.lineTo(bottomLeft.x, bottomLeft.y);
        path.circle(leftCircleRadius, leftCircleRadius, 0, 0, 1, topLeft.x, topLeft.y);

        let fill = 'rgba(0,0,0,0)'; //填充色
        let stroke = 'rgba(0,0,0,0)'; //线条颜色
        let pathStrokeWidth;
        fill = Colors.getUiColor(Colors.black10);
        pathStrokeWidth = 0;
        this.renderObj({ dom: null,
            d: path, 
            stroke: stroke, 
            strokeWidth: pathStrokeWidth, 
            fill: fill, 
            strokeLinecap:'round' })
    }

    drawRect( inRect,
            strokeColor,
            strokeWidth,
            fullColor,
            cornerRadius) {

        let path = new LinePath();
        let x = inRect.x;
        let y = inRect.y;
        let width = inRect.width();
        let height = inRect.height();
        path.moveTo(x + cornerRadius, y);
        path.lineTo(x + width - cornerRadius, y);

        path.circle(cornerRadius,cornerRadius, 0, 0, 1, x + width, y + cornerRadius);

        path.lineTo(x + width, y + height - cornerRadius);

        path.circle(cornerRadius,cornerRadius, 0, 0, 1, x + width - cornerRadius, y + height - cornerRadius);

        path.lineTo(x + cornerRadius, y + height);
        path.circle(cornerRadius,cornerRadius, 0, 0, 1, x, y + height - cornerRadius);


        path.lineTo(x, y + cornerRadius);
        path.circle(cornerRadius,cornerRadius, 0, 0, 1, x + cornerRadius, y);

        let fill = 'rgba(0,0,0,0)'; //填充色
        let stroke = 'rgba(0,0,0,0)'; //线条颜色
        let pathStrokeWidth;
        if (!(new Strings()).isEmpty(fullColor)) {
            fill = fullColor;
            pathStrokeWidth = 0;
        }
        if (strokeWidth > 0 && !(new Strings()).isEmpty(strokeColor)) {
            pathStrokeWidth = strokeWidth;
            stroke = strokeColor
        }
        this.renderObj({ dom: null,
            d: path, 
            stroke: stroke, 
            strokeWidth: pathStrokeWidth, 
            fill: fill, 
            strokeLinecap:'round' })
    }

    drawFrontRect(inRect, strokeColor, strokeWidth, fullColor, cornerRadius) {
        let path = new LinePath();
        let x = inRect.x;
        let y = inRect.y;
        let width = inRect.width();
        let height = inRect.height();
        path.moveTo(x, y);
        path.lineTo(x + width - cornerRadius, y);
        path.circle(cornerRadius, cornerRadius, 0, 0, 1, x + width, y + cornerRadius);
        path.lineTo(x + width, y + height - cornerRadius);
        path.circle(cornerRadius, cornerRadius, 0, 0, 1, x + width - cornerRadius, y + height);
        path.lineTo(x, y + height);
        path.lineTo(x, y );

        let fill = 'rgba(0,0,0,0)'; //填充色
        let stroke = 'rgba(0,0,0,0)'; //线条颜色
        let pathStrokeWidth;
        if (!(new Strings()).isEmpty(fullColor)) {
            fill = fullColor;
            pathStrokeWidth = 0;
        }
        if (strokeWidth > 0 && !(new Strings()).isEmpty(strokeColor)) {
            pathStrokeWidth = strokeWidth;
            stroke = strokeColor
        }
        this.renderObj({ dom: null,
            d: path, 
            stroke: stroke, 
            strokeWidth: pathStrokeWidth, 
            fill: fill, 
            strokeLinecap:'round' })
    }

    //画底部白色的曲线图形。
    drawFrontBottomRect(inRect, strokeColor, strokeWidth, fullColor, cornerRadius) {
        let path = new LinePath();
        let x = inRect.x;
        let y = inRect.y;
        let width = inRect.width();
        let height = inRect.height();
        let circleHeight = width/7;
        let topLeft = new CGPoint(inRect.x, inRect.y + circleHeight);
        let topRight = new CGPoint(inRect.x + inRect.width(), inRect.y + circleHeight);
        let middle = new CGPoint(inRect.x + inRect.width()/2, inRect.y + circleHeight);

        let middleLeft = new CGPoint(topLeft.x + inRect.width()/4, inRect.y);
        let middleRight = new CGPoint(topLeft.x + inRect.width()/4 * 3, inRect.y + circleHeight * 2);

        let leftCircleCenter = this.Util.getCircleCenter(topLeft, middleLeft, middle);  //这三个坐标分别是左边圆弧的最左边，左边圆弧与矩形中心点的坐标，中心点（x轴为封面宽度的一半）的坐标。
        let leftCircleRadius = this.Util.getPointSpacingByCGPoint(leftCircleCenter, topLeft);
        let leftStartAngle = this.Util.getCircleDegreesInPoint(leftCircleCenter, topLeft);
        let leftEndAngle = this.Util.getCircleDegreesInPoint(leftCircleCenter, middle);

        let rightCircleCenter = this.Util.getCircleCenter(middle, middleRight, topRight);
        let rightCircleRadius = this.Util.getPointSpacingByCGPoint(rightCircleCenter, middle);
        let rightStartAngle = this.Util.getCircleDegreesInPoint(rightCircleCenter, middle);
        let rightEndAngle = this.Util.getCircleDegreesInPoint(rightCircleCenter, topRight);

        path.moveTo(topLeft.x, topLeft.y);
        path.circle(leftCircleRadius,leftCircleRadius,0,0,1,middle.x, middle.y);
        path.circle(rightCircleRadius,rightCircleRadius,0,0,0,topRight.x, topRight.y);
        path.lineTo(x + width, y + height - cornerRadius);
        path.circle(cornerRadius,cornerRadius,0,0,1,x + width - cornerRadius, y + height);
        path.lineTo(x , y + height );
        path.lineTo(topLeft.x, topLeft.y);

        let fill = 'rgba(0,0,0,0)'; //填充色
        let stroke = 'rgba(0,0,0,0)'; //线条颜色
        let pathStrokeWidth;
        if (!(new Strings()).isEmpty(fullColor)) {
            fill = fullColor;
            pathStrokeWidth = 0;
        }
        if (strokeWidth > 0 && !(new Strings()).isEmpty(strokeColor)) {
            pathStrokeWidth = strokeWidth;
            stroke = strokeColor
        }
        this.renderObj({ dom: null,
            d: path, 
            stroke: stroke, 
            strokeWidth: pathStrokeWidth, 
            fill: fill, 
            strokeLinecap:'round' })

        let borderPath = new LinePath();
        borderPath.moveTo(topRight.x, topRight.y);
        borderPath.lineTo(x + width, y + height - cornerRadius);
        borderPath.circle(cornerRadius,cornerRadius,0,0,1,x + width - cornerRadius, y + height);
        borderPath.lineTo(x, y + height);
        this.renderObj({ dom: null,
            d: borderPath,
            stroke: Colors.getUiColor(Colors.black35),
            strokeWidth: 1,
            fill: 'rgba(0,0,0,0)',
            strokeLinecap:'round'})

    }

    renderObj(obj = { dom, d, stroke, strokeWidth, strokeLinecap }) {  //设置path
        if ( obj.dom == null ) {
            obj.dom = document.createElementNS('http://www.w3.org/2000/svg', 'path')
            this.gTag.appendChild(obj.dom);
        }
        if (obj.d != null) {
            obj.dom.setAttribute("d", obj.d.getLine())
        }
        if (obj.stroke != null) {
            obj.dom.setAttribute("stroke", obj.stroke)
        }
        if (obj.strokeWidth != null) {
            obj.dom.setAttribute("stroke-width", obj.strokeWidth)
        }
        if (obj.fill != null) {
            obj.dom.setAttribute("fill", obj.fill)
        }


        if (obj.strokeLinecap != null ) {
            obj.dom.setAttribute("stroke-linecap", obj.strokeLinecap)
        }
    }

    drawTitleAndTime(frontRect, frontBottomRect) {
        if (this.Strings.isEmpty(this.book.name)) {
            return;
        }
        
        

        let textFontSize = 14;
        let textColor = Colors.isDarkColor(this.book.color) ? Colors.white : Colors.black55;
        let title = this.Strings.lineFeed(this.book.name);
        let titleSpace = this.UiUtil.dip2px(2);
        let titleMaxWidth = this.book.width - this.frontLeft - titleSpace * 2;
        let textSize = MindElementCalculation.caluleText(title, textFontSize, true, titleMaxWidth);
        let titleWidth = textSize.getWidth();
        let titleHeight = textSize.getHeight();
        //两行的高度
        let towLineHeight = (MindElementCalculation.caluleText('1234', textFontSize, true, textFontSize * 2, textFontSize + 2)).getHeight();
        if (titleHeight > towLineHeight) { 
            titleHeight  = towLineHeight;
        }

        let centerY = (frontBottomRect.y - frontRect.y) / 2;
        let titleY = frontRect.y +  centerY - titleHeight /2;
        let titleX = frontRect.x + titleSpace + (titleMaxWidth - titleWidth)/2;

        let obj = {innerText: title, x:titleX, y:titleY, width: parseInt(titleWidth),
            height: parseInt(titleHeight), color: Colors.getUiColor(textColor),
             fontSize: textFontSize, lineHeight: textFontSize * Config.textLineHeight,
            textAlign: 'center' };
       this.createdText(obj);


        textFontSize = 10;
        // String time = Util.getTimeFormatYearMonthDayByPointConnection(book.time);
        let time = dataTimeToFormats.timestampToTimeSeparator(parseInt(this.book.time/1000),'.');

        textSize = MindElementCalculation.caluleText(time, textFontSize, false);
        let timeWidth = textSize.getWidth();
        let timeHeight = textSize.getHeight();

        let timeX = this.book.width - textSize.getWidth() - this.margin + 2;
        let timeY = this.book.height - textSize.getHeight() - this.margin + 2;
        let timeObj = {innerText: time, x:timeX, y:timeY, width: parseInt(timeWidth),
            height: parseInt(timeHeight), color: Colors.getUiColor(Colors.black50),
             fontSize: this.UiUtil.dip2px(textFontSize), lineHeight: this.UiUtil.dip2px(textFontSize) * Config.textLineHeight,
            textAlign: 'center' };
        this.createdText(timeObj);
    }

    drawSheetNumber() {
        if (this.book.sheetNumber <= 0) {
            return;
        }
        let textFontSize = 12;
        let title = this.book.sheetNumber + "";

        let textSize = MindElementCalculation.caluleText(title, textFontSize, true);
        let titleWidth = textSize.getWidth();
        let titleHeight = textSize.getHeight();
        let titleSpace = this.UiUtil.dip2px(2);
        let radius = Math.sqrt(Math.pow(titleWidth,2) + Math.pow(titleHeight,2)) / 2 + titleSpace;
        let centralPointX = this.book.width - (this.book.width - this.frontLeft)/4;
        let centralPointY = this.frontBottomRectTop + radius;
        let rect = new CGRect(centralPointX - radius,
                centralPointY - radius,
                radius * 2,
                radius * 2);
        
        let textColor;
        let backgroundColor;
        if (Colors.isDarkColor(this.book.color)) {

            textColor = Colors.getUiColor(this.book.color)
            backgroundColor = Colors.getUiColor(Colors.white)
        } else {

            textColor = Colors.getUiColor(Colors.white)
            backgroundColor = Colors.getUiColor(Colors.black60)
        }

        

        let titleX = rect.x + (rect.width() - titleWidth)/2;
        let titleY = rect.y + (rect.height() - titleHeight)/2;

        let path = new LinePath();
        path.moveTo(centralPointX - radius, centralPointY);
        path.circle(radius, radius, 0, 0, 1,centralPointX + radius, centralPointY);
        path.circle(radius, radius, 0, 0, 1,centralPointX - radius, centralPointY);
        this.renderObj({ dom: null,
            d: path,
            stroke: null,
            strokeWidth: null,
            fill: backgroundColor,
            strokeLinecap:'round'})
        let obj = {innerText: title, x:titleX, y:titleY, width: parseInt(titleWidth),
             height: parseInt(titleHeight), color: textColor,
              fontSize: textFontSize , lineHeight:parseInt(titleHeight), 
              fontWeight: 700,
              textAlign: 'center'  };
        this.createdText(obj);
    }

    createdText( obj = { innerText, x, y, width, height, color, fontSize, lineHeight, textAlign, divWidth, divHeight }) {
        let foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.gTag.appendChild(foreignObject);
        foreignObject.setAttribute('x',obj.x);
        foreignObject.setAttribute('y',obj.y);
        foreignObject.setAttribute('width',obj.width);
        foreignObject.setAttribute('height',obj.height);

        let div = document.createElement('div');
        foreignObject.appendChild(div);

        if (obj.color != null) {
            div.style.color = obj.color;
        }
        if (obj.fontSize != null) {
            div.style.fontSize = obj.fontSize + 'px';
        }
        if(obj.lineHeight != null) {
            div.style.lineHeight = obj.lineHeight + 'px';
        }
        if (obj.innerText != null) {
            div.innerText = obj.innerText ;
        }
        if (obj.textAlign != null) {
            div.style.textAlign = 'center';
        }
        if (obj.fontWeight != null) {
            div.style.fontWeight = obj.fontWeight;
        }
        
        if (obj.divHeight != null) {
            div.style.height = obj.divHeight + 'px';
        } 
        if (obj.divWidth != null) {
            div.style.width = obj.divWidth + 'px';
        } 

        if (obj.borderRadius != null) {
            div.style.borderRadius = obj.borderRadius;
        } 

        if (obj.backgroundImage != null) {
            div.style.backgroundImage = 'url(' + obj.backgroundImage + ')';
        }

        if (obj.backgroundSize != null) {
            div.style.backgroundSize ='cover'
        }

        if (obj.backgroundPosition != null) {
            div.style.backgroundPosition ='center center'
        }

        if (obj.backgroundRepeat != null) {
            div.style.backgroundRepeat ='no-repeat'
        }

        // 默认的单词换行
        if (obj.wordBreak == null) {
            div.style.wordBreak ="break-word"
        }
        //默认的文字间距
        if (obj.whiteSpace == null) {
            div.style.whiteSpace ="pre-wrap"
        }

    }

    drawImage() {
        let width = this.book.width / 3;
        let height = width;
        let imageX = this.edgeHieght + this.horizontalOffset/2*3 + (this.book.width - this.horizontalOffset)/4 - width/2;
        let imageY = this.frontBottomRectTop + this.margin;
        let url = httpImageToPrefix(this.book.url);
        // let url = httpImageToPrefix('http://192.168.3.4:12300/r/mindmap/image/72900214a810de192fc4a4d9e6b483c5.png');
        let obj = { x:imageX, y:imageY, width: parseInt(width),
            height: parseInt(height), divWidth: parseInt(width),
             divHeight: parseInt(height), backgroundImage: url, 
             borderRadius: '50%',
             backgroundSize: 'cover', backgroundPosition:'center center', 
             backgroundRepeat: 'no-repeat' };
       this.createdText(obj);
    }

}

export default BookView
