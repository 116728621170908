import Config from "../../../core/core/calcule/Config";
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import OutlineInputView from "./OutlineInputView";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class MainOutlineInputView extends OutlineInputView {
    constructor(context, data, index, nextView, outlineVue) {
        super(context, data, index, nextView, outlineVue);
        this.bottomLine;
    }


    initView() {
        super.initView();
        let left = ((Config.MindOutlineImageSize - Config.MindOutlineRowMarkSize) / 2 + Config.MindInputText_lineFragmentPadding);
    }


    setRowMark() {
    }


    setRowMarkLayout() {
    }

    setIconLeft() {
        this.imageStartLeft = (Config.MindOutlineImageSize - Config.MindOutlineRowMarkSize) / 2;
    }


    setInputTextViewLayout() {
        if (this.inputTextView.parentNode == null || this.mindElementData == null) {
            return;
        }

        let textMindElementContent = null;
        if (this.mindElementData.textContent != null) {
            textMindElementContent = this.mindElementData.textContent;
        } else if (this.mindElementData.generalizationContent != null) {
            textMindElementContent = this.mindElementData.generalizationContent;
        }
        if (textMindElementContent == null) {
            return;
        }

        let textX = (textMindElementContent.x);
        let textY = (textMindElementContent.y);
        let textHeight = (textMindElementContent.height);
        let textWidth = new UiUtil().getScreenWidth() - textX - Config.MindOutlineRowRightMargin;

    }


    setTextStyleData() {
        super.setTextStyleData()
    }


    getTextColor() {
        let color = Colors.black80;
        if (this.mindElementData.textContent != null) {
            color = this.mindElementData.textContent.textColor;
        } else if (this.mindElementData.generalizationContent != null) {
            color = this.mindElementData.generalizationContent.textColor;
        }
        if (Colors.getUiColor(color) == -1 || Colors.getUiColor(color) == Colors.getUiColor(Colors.white)) {
            color = Colors.black80;
        }

        return color;
    }

    textViewShouldBeginEditing(textView) {
        super.textViewShouldBeginEditing(textView);
    }

    textViewShouldEndEditing(textView) {
        super.textViewShouldEndEditing(textView);
    }


    deleteBackward() {
    }

    getTextUIEdgeInsets() {
        return Config.MainOutlineInputUIEdgeInsets;
    }
}

export default MainOutlineInputView