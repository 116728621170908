/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/26
 * Copyright(c) 2020 mindyushu.com
 */

import MindElementData from "../../../viewmodel/mindelementdata/MindElementData";

class NodeConnectLineControlPoint {
    constructor() {
        this.originalStartPoint;
        this.originalEndPoint;
        this.startPoint;
        this.endPoint;
        this.startData = new MindElementData().emptyMindNode();
        this.endData = new MindElementData().emptyMindNode();
    }
}
export default NodeConnectLineControlPoint
