
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import BaseLayout from "./BaseLayout"
import MindElementData from "../../mindelementdata/MindElementData"
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode"
import HashMap from "../base/HashMap"
import TreeTopLeftLayout from "./TreeTopLeftLayout"
import TreeTopRightLayout from "./TreeTopRightLayout"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import Point from "../base/Point"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"
import RectsMergeCalculation from "./RectsMergeCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 7/5/21
 * Copyright(c) 2020 mindyushu.com
 */

class TreeTopLeftRightLayout extends BaseLayout {
    constructor() {
        super();
        this.EncircleNodesPointsCalculation = new EncircleNodesPointsCalculation();
        this.leftLayout = new TreeTopLeftLayout();
        this.rightLayout = new TreeTopRightLayout();
        this.leftRightRootTreeNode = new LineMindTypeNode();
        this.leftRightGeneralizationLineMindElementDataDictInt = new HashMap();
        this.leftRightGeneralizationMindElementDataDict = new HashMap();

        this.leftRootTreeNode = new LineMindTypeNode();
        this.leftLineMindElementDataDict = new HashMap();//主体线字典， Int为线ID data为line
        this.leftMainMindElementDataDict = new HashMap();//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.leftTextElementLineMindElementDataDict = new HashMap();//主体节点对应的线，Int为节点ID，data为line
        this.leftGeneralizationLineMindElementDataDict = new HashMap();//概要线字典， Int为线ID data为line
        this.leftGeneralizationMindElementDataDict = new HashMap();//概要字典， Int为概要ID data为概要节点
        this.leftEncircleMindElementDataDict = new HashMap();

        this.rightRootTreeNode = new LineMindTypeNode();
        this.rightLineMindElementDataDict = new HashMap();//主体线字典， Int为线ID data为line
        this.rightMainMindElementDataDict = new HashMap();//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.rightTextElementLineMindElementDataDict = new HashMap();//主体节点对应的线，Int为节点ID，data为line
        this.rightGeneralizationLineMindElementDataDict = new HashMap();//概要线字典， Int为线ID data为line
        this.rightGeneralizationMindElementDataDict = new HashMap();//概要字典， Int为概要ID data为概要节点
        this.rightEncircleMindElementDataDict = new HashMap();
    }



    initConfig() {
        super.initConfig();
        this.initData();
        this.leftLayout.isFromLeftRightLayout = true;
        this.rightLayout.isFromLeftRightLayout = true;
        this.leftLayout.isCalculeMindDataSize = this.isCalculeMindDataSize;
        this.rightLayout.isCalculeMindDataSize = this.isCalculeMindDataSize;
        this.leftLayout.mindLayoutSelect = this.mindLayoutSelect
        this.rightLayout.mindLayoutSelect = this.mindLayoutSelect

        this.leftLayout.delegate = this;
        this.rightLayout.delegate = this;
        this.shuntData(true);
    }

    setDatas(rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict,
        mindMapStyleColor, mindBGColor, settingData) {
        super.setDatas(rootTreeNode, lineMindElementDataDict,
            mainMindElementDataDict, textElementLineMindElementDataDict,
            generalizationLineMindElementDataDict, generalizationMindElementDataDict,
            explainMindElementDataDict,
            mindMapStyleColor, mindBGColor, settingData);
        this.initConfig();
    }

    onLayout(isChange) {
        if (this.leftLayout != null && this.leftLayout.rootTreeNode != null) {
            this.leftLayout.layout(isChange);
        }
        if (this.rightLayout != null && this.rightLayout.rootTreeNode != null) {
            this.rightLayout.layout(isChange);
        }
    }

    initData() {
        this.leftLayout.delegate = null;
        this.rightLayout.delegate = null;

        this.leftLayout = new TreeTopLeftLayout();
        this.rightLayout = new TreeTopRightLayout();

        this.leftRightRootTreeNode = new LineMindTypeNode();
        this.leftRootTreeNode = new LineMindTypeNode()
        this.leftLineMindElementDataDict = new HashMap();//主体线字典， Int为线ID data为line
        this.leftMainMindElementDataDict = new HashMap();//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.leftTextElementLineMindElementDataDict = new HashMap();//主体节点对应的线，Int为节点ID，data为line
        this.leftGeneralizationLineMindElementDataDict = new HashMap();//概要线字典， Int为线ID data为line
        this.leftGeneralizationMindElementDataDict = new HashMap();//概要字典， Int为概要ID data为概要节点
        this.leftEncircleMindElementDataDict = new HashMap();

        this.rightRootTreeNode = new LineMindTypeNode();
        this.rightLineMindElementDataDict = new HashMap();
        this.rightMainMindElementDataDict = new HashMap();//主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.rightTextElementLineMindElementDataDict = new HashMap();//主体节点对应的线，Int为节点ID，data为line
        this.rightGeneralizationLineMindElementDataDict = new HashMap();//概要线字典， Int为线ID data为line
        this.rightGeneralizationMindElementDataDict = new HashMap();//概要字典， Int为概要ID data为概要节点
        this.rightEncircleMindElementDataDict = new HashMap();

        this.leftRightGeneralizationLineMindElementDataDictInt = new HashMap();
        this.leftRightGeneralizationMindElementDataDict = new HashMap();
    }

    callbackLeftAndRightLayoutResetDatas(rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict,) {

        rootTreeNode.children.forEach(node => {
            this.leftRightRootTreeNode.children.push(node);
        })


        this.rootTreeNode = this.leftRightRootTreeNode;

        lineMindElementDataDict.keys().forEach(key => {
            this.lineMindElementDataDict.put(key, lineMindElementDataDict.get(key));
        })

        mainMindElementDataDict.keys().forEach(key => {
            this.mainMindElementDataDict.put(key, mainMindElementDataDict.get(key));
        })

        textElementLineMindElementDataDict.keys().forEach(key => {
            this.textElementLineMindElementDataDict.put(key, textElementLineMindElementDataDict.get(key));
        })

        this.mainMindElementDataDict.put(this.leftRightRootTreeNode.value.id, this.rootTreeNode);

        generalizationMindElementDataDict.keys().forEach(key => {
            let node = generalizationMindElementDataDict.get(key);
            this.leftRightGeneralizationMindElementDataDict.put(node.value.id, node);
        })

        generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = generalizationLineMindElementDataDict.get(key);
            this.leftRightGeneralizationLineMindElementDataDictInt.put(line.id, line);
        })


        this.generalizationMindElementDataDict = this.leftRightGeneralizationMindElementDataDict;
        this.generalizationLineMindElementDataDict = this.leftRightGeneralizationLineMindElementDataDictInt;
        this.explainMindElementDataDict = explainMindElementDataDict;
        // this.setEncirclePoint();
    }

    shuntData(isChange) {
        this.leftRightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        if (this.rootTreeNode.children.length <= 1) {
            this.rightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
            this.rightMainMindElementDataDict.put(this.rootTreeNode.value.id, this.rightRootTreeNode);
            for (let index = 0; index < this.rootTreeNode.children.length; index++) {
                this.rightRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setRightMainNode(this.rootTreeNode.children[index]);
            }
            this.lineMindElementDataDict.keys().forEach(key => {
                let line = this.lineMindElementDataDict.get(key);
                if (this.leftMainMindElementDataDict.containsKey(line.lineContent.targetId)) {
                    this.leftLineMindElementDataDict.put(line.id, line);
                    this.leftTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                } else {
                    this.rightLineMindElementDataDict.put(line.id, line);
                    this.rightTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            });
            this.generalizationMindElementDataDict.keys().forEach(key => {
                let item = this.generalizationMindElementDataDict.get(key);
                if (this.rightMainMindElementDataDict.containsKey(item.value.generalizationContent.targetIds[0])) {
                    item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, item.value.layout);
                    this.rightGeneralizationMindElementDataDict.put(item.value.id, item);
                    for (var index = 0; index < item.children.length; index++) {
                        this.setRightMainNode(item.children[index]);
                    }
                } else {
                    item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, item.value.layout);;
                    this.leftGeneralizationMindElementDataDict.put(item.value.id, item);
                    for (var index = 0; index < item.children.length; index++) {
                        this.setLeftMainNode(item.children[index]);
                    }
                }
            });
    
            this.generalizationLineMindElementDataDict.keys().forEach(key => {
                let line = this.generalizationLineMindElementDataDict.get(key);
                if (this.leftGeneralizationMindElementDataDict.containsKey(line.parentNodeId)) {
                    this.leftGeneralizationLineMindElementDataDict.put(line.id, line);
                } else {
                    this.rightGeneralizationLineMindElementDataDict.put(line.id, line);
                }
            });

            this.rightLayout.originalRootTreeNode = this.rootTreeNode;
            this.rightLayout.setDatas(this.rootTreeNode,
                this.lineMindElementDataDict,
                this.mainMindElementDataDict,
                this.textElementLineMindElementDataDict,
                this.generalizationLineMindElementDataDict,
                this.generalizationMindElementDataDict,
                this.explainMindElementDataDict,
                this.mindMapStyleColor, this.mindBGColor, this.settingData);
            this.rightLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
            this.rightLayout.globalLayout = this.globalLayout;
            return;
        }

        this.leftRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        this.rightRootTreeNode = new LineMindTypeNode(this.rootTreeNode.value);
        this.leftMainMindElementDataDict.put(this.rootTreeNode.value.id, this.leftRootTreeNode);
        this.rightMainMindElementDataDict.put(this.rootTreeNode.value.id, this.rightRootTreeNode);

        let rightCount = Math.ceil(this.rootTreeNode.children.length / 2);

        for (let index = 0; index < this.rootTreeNode.children.length; index++) {
            if (index < rightCount) {
                this.leftRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setLeftMainNode(this.rootTreeNode.children[index]);
            } else {
                this.rightRootTreeNode.children.push(this.rootTreeNode.children[index]);
                this.setRightMainNode(this.rootTreeNode.children[index]);
            }
        }

        this.generalizationMindElementDataDict.keys().forEach(key => {
            let item = this.generalizationMindElementDataDict.get(key);
            if (this.rightMainMindElementDataDict.containsKey(item.value.generalizationContent.targetIds[0])) {
                item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, item.value.layout);
                this.rightGeneralizationMindElementDataDict.put(item.value.id, item);
                item.children.forEach(cell => {
                    this.setRightMainNode(cell);
                })
            } else {
                item.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, item.value.layout);
                this.leftGeneralizationMindElementDataDict.put(item.value.id, item);
                item.children.forEach(cell => {
                    this.setLeftMainNode(cell);
                })
            }
        })



        this.generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = this.generalizationLineMindElementDataDict.get(key);
            if (this.leftGeneralizationMindElementDataDict.containsKey(line.parentNodeId)) {
                this.leftGeneralizationLineMindElementDataDict.put(line.id, line);
            } else {
                this.rightGeneralizationLineMindElementDataDict.put(line.id, line);
            }
        })

        this.lineMindElementDataDict.keys().forEach(key => {
            let line = this.lineMindElementDataDict.get(key);
            if (this.leftMainMindElementDataDict.containsKey(line.lineContent.targetId)) {
                this.leftLineMindElementDataDict.put(line.id, line);
                this.leftTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            } else {
                this.rightLineMindElementDataDict.put(line.id, line);
                this.rightTextElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            }
        })
        this.rightLayout.originalRootTreeNode = this.rootTreeNode;
        this.rightLayout.setDatas(this.rightRootTreeNode,
            this.rightLineMindElementDataDict,
            this.rightMainMindElementDataDict,
            this.rightTextElementLineMindElementDataDict,
            this.rightGeneralizationLineMindElementDataDict,
            this.rightGeneralizationMindElementDataDict,
            this.explainMindElementDataDict,
            this.mindMapStyleColor, this.mindBGColor, this.settingData);
        this.rightLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.rightLayout.globalLayout = this.globalLayout;
        
        this.leftLayout.originalRootTreeNode = this.rootTreeNode;
        this.leftLayout.setDatas(this.leftRootTreeNode,
            this.leftLineMindElementDataDict,
            this.leftMainMindElementDataDict,
            this.leftTextElementLineMindElementDataDict,
            this.leftGeneralizationLineMindElementDataDict,
            this.leftGeneralizationMindElementDataDict,
            this.explainMindElementDataDict,
            this.mindMapStyleColor, this.mindBGColor, this.settingData);
        this.leftLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.leftLayout.globalLayout = this.globalLayout;
    }

    setLeftMainNode(node) {
        node.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_TOP_TREE_LEFT, NodeLayoutType.LAYOUT_TOP_TREE_LEFT, node.value.layout);
        this.leftMainMindElementDataDict.put(node.value.id, node);
        node.children.forEach(child => {
            this.setLeftMainNode(child);
        })
    }

    setRightMainNode(node) {
        node.value.layout = this.mindLayoutSelect.getLayout(NodeLayoutType.LAYOUT_TOP_TREE_RIGHT, NodeLayoutType.LAYOUT_TOP_TREE_RIGHT, node.value.layout);
        this.rightMainMindElementDataDict.put(node.value.id, node);
        node.children.forEach(child => {
            this.setRightMainNode(child);
        })
    }

    setEncirclePoint() {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictCount = encircleMindElementDataDict.length
        for (let index = 0; index < encircleMindElementDataDictCount; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);

        // for (Integer key : encircleMindElementDataDict.keySet()) {
            // MindElementData mind = encircleMindElementDataDict.get(key);

            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                mind.lineContent.textContent.x = this.EncircleNodesPointsCalculation.space;
                mind.lineContent.textContent.y = 0;
            }
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }

                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;

                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = rect.x - this.EncircleNodesPointsCalculation.space - padding;
                mind.y = rect.y - this.EncircleNodesPointsCalculation.space - padding - textHeight;
                mind.width = rect.width() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2;
                mind.height = rect.height() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2 + textHeight;

                let encircleNodesPoint = new Array();
                encircleNodesPoint.push(new Point(0, 0));
                encircleNodesPoint.push(new Point(mind.width, 0));
                encircleNodesPoint.push(new Point(mind.width, mind.height));
                encircleNodesPoint.push(new Point(0, mind.height));
                encircleNodesPoint.push(new Point(0, 0));

                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                        EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                let Count = targetIds.length;
                for (let index = 0; index < Count; index++) {
                    let id = targetIds[index];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - this.EncircleNodesPointsCalculation.space - padding;
                mind.y = rect.y - this.EncircleNodesPointsCalculation.space - padding - textHeight;
                mind.width = rect.width() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2;
                mind.height = rect.height() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - this.EncircleNodesPointsCalculation.space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = this.EncircleNodesPointsCalculation.space;
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    getLeftTreeWidth(node) {
        if (this.leftLayout == null || this.leftLayout.rootTreeNode == null) {
            return 0;
        }
        return this.leftLayout.getTreeWidth(node);
    }

    getRightTreeWidth(node) {
        if (this.rightLayout == null || this.rightLayout.rootTreeNode == null) {
            return 0;
        }
        return this.rightLayout.getTreeWidth(node);
    }
    getTreeWidth(node) {
        if ((this.leftLayout == null || this.leftLayout.rootTreeNode == null) &&
            (this.rightLayout == null || this.rightLayout.rootTreeNode == null)) {
            return node.value.width;
        } else if (this.leftLayout == null || this.leftLayout.rootTreeNode == null) {
            return this.rightLayout.getTreeWidth(node);
        } else if (this.rightLayout == null || this.rightLayout.rootTreeNode == null) {
            return this.leftLayout.getTreeWidth(node);
        } else {
            return this.leftLayout.getTreeWidth(node) + this.rightLayout.getTreeWidth(node) - node.value.width;
        }
    }
}

export default TreeTopLeftRightLayout