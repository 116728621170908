import Config from "../../../core/core/calcule/Config"
import UiUtil from "../../../utils/UiUtil"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import TimeLineLayout from "./TimeLineLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType"
import Util from "../../../utils/Util"
import Point from "../base/Point"
import OrientationType from "../../datatype/OrientationType"


/**
 * ProjectName: MindMap
 * Created by tony on 5/27/21
 * Copyright(c) 2020 mindyushu.com
 */

class TimeArcLayout extends TimeLineLayout {
    constructor() {
        super();
    }

    initConfigInfo() {
        this.radius = this.getRadius();
        this.baseLineWidth = this.radius * 2 + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2;
        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);

        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
                this.baseLineWidth += this.UiUtil.dip2px(2);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineWidth += this.UiUtil.dip2px(4);
        } else {
            this.baseLineWidth += this.UiUtil.dip2px(0);
        }
        this.baseLineWidth = this.addSubjectSpcaeHorizontal(this.baseLineWidth);
        this.space = (this.baseLineWidth - this.radius) / 2;
        this.subjectAndLineSpace = 0;
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.baseLine.timeLineContent.lineThicken ?  this.UiUtil.dip2px(30) : this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.baseLine.timeLineContent.lineThicken ?  this.UiUtil.dip2px(40) : this.UiUtil.dip2px(20);
                break;
            default:
        }
    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.BaseLayout.getNodeHeight(title) + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
        }

        this.initConfigInfo();

        let nodeList = this.getOrderNodes();

        let lineStartHeight = this.UiUtil.dip2px(20);
        let totalHeight = this.getHeight(true);

        let lastNodeBottomHeight = 0;
        if (nodeList.length > 0) {
            lastNodeBottomHeight = this.getTimeNodeDesHeight(nodeList[nodeList.length - 1]);
            if (lastNodeBottomHeight > 0) {
                lastNodeBottomHeight += this.titleDescSpace;
            }
        }
        let lineTotalHeight = totalHeight - lastNodeBottomHeight + lineStartHeight * 2;
        let lineStartPoint = new Point();
        let lineEndPoint = new Point();
        let middlePoint = new Point();

        lineStartPoint.x = this.title.x + this.title.width/2 + this.baseLineWidth / 2;
        lineStartPoint.y = this.title.y + this.title.height/2 - lineTotalHeight/2;
        lineEndPoint.x = this.title.x + this.title.width/2 + this.baseLineWidth / 2;
        lineEndPoint.y = this.title.y + this.title.height/2 + lineTotalHeight/2;
        middlePoint.x = this.title.x + this.title.width + this.baseLineWidth / 2 + this.UiUtil.dip2px(40);
        middlePoint.y = this.title.y + this.title.height/2;
        let circleCenterPoint = Util.getCircleCenter(lineStartPoint, middlePoint ,lineEndPoint);
        let startAngle = Util.getCircleDegreesInPoint(circleCenterPoint, lineStartPoint);
        if (startAngle < 300) {
            let leftSpace = (this.title.width * 0.5 * (90.0 - (360.0 - startAngle)) / 90.0);
            let middleLeft = this.UiUtil.dip2px(40);
            if (leftSpace < 0 && lineTotalHeight < this.UiUtil.dip2px(160)) {
                leftSpace = 0;
                middleLeft = this.UiUtil.dip2px(20);
            } else {
                leftSpace = Math.max(this.UiUtil.dip2px(10), leftSpace);
            }

            lineStartPoint.x = this.title.x + (this.title.width) + this.baseLineWidth / 2 - leftSpace;
            lineStartPoint.y = this.title.y + this.title.height/2 - lineTotalHeight/2;
            lineEndPoint.x = this.title.x + (this.title.width) + this.baseLineWidth / 2 - leftSpace;
            lineEndPoint.y = this.title.y + this.title.height/2 + lineTotalHeight/2;
            middlePoint.x = this.title.x + this.title.width + this.baseLineWidth / 2 + middleLeft;
            middlePoint.y = this.title.y + this.title.height/2;
            circleCenterPoint = Util.getCircleCenter(lineStartPoint, middlePoint ,lineEndPoint);
        }
        let radius = Util.getPointSpacing(circleCenterPoint, lineStartPoint);

        this.baseLine.x = lineStartPoint.x;
        this.baseLine.y = lineStartPoint.y;

        this.baseLine.width = (middlePoint.x - lineStartPoint.x);
        this.baseLine.height = (lineEndPoint.y - lineStartPoint.y);
        this.baseLine.timeLineContent.lineContentWidth = this.baseLineWidth;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLine.height;
        this.baseLine.timeLineContent.orientationType = OrientationType.SEMICIRCLE;

        let dots = [];

        let top = this.title.y + this.BaseLayout.getNodeHeight(this.title)/2 - (totalHeight - lastNodeBottomHeight)/2;

        let nodeListLength = nodeList.length;
        for (let index = 0; index < nodeListLength; index++) {
            let timeNode = nodeList[index];
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;

            let height = this.getTimeNodeHeight(timeNode);
            head.isHidden = true;
            title.isHidden = false;
            desc.isHidden = false;
            let topAngle = 0.0;
            let bottomAngle = 0.0;
            let bottomY = top + height;
            title.y = top;
            topAngle = Util.asind((Math.abs(top - circleCenterPoint.y)) / radius);
            if (top < circleCenterPoint.y) {
                topAngle = 360 - topAngle;
            }
            let titleMiddleAngle = Util.asind((Math.abs(top + title.height/2 - circleCenterPoint.y)) / radius);
            if (top + title.height/2 < circleCenterPoint.y) {
                titleMiddleAngle = 360 - titleMiddleAngle;
            }

            bottomAngle = Util.asind((Math.abs(bottomY - circleCenterPoint.y)) / radius);
            if (bottomY < circleCenterPoint.y) {
                bottomAngle = 360 - bottomAngle;
            }
            let topPoint = Util.getCirclePoint(circleCenterPoint, topAngle, radius);
            let bottomPoint = Util.getCirclePoint(circleCenterPoint, bottomAngle, radius);

            let titleMiddlePoint = Util.getCirclePoint(circleCenterPoint, titleMiddleAngle, radius);
            let space = this.UiUtil.dip2px(14);
            if (titleMiddlePoint.x < bottomPoint.x) {
                if (bottomAngle > 270) {
                    space = Math.max(space/4, space - ((space) * (360 - bottomAngle)/45.0));
                } else {
                    space = this.UiUtil.dip2px(10);
                }
                title.x = bottomPoint.x + this.radius + space - this.getDataBackgroundAndBorderPadding(title);
            } else {
                if (this.isBackgroundAndBorder(title)) {
                    title.x = topPoint.x + this.radius + space - this.getDataBackgroundAndBorderPadding(title);
                } else {
                    title.x = titleMiddlePoint.x + this.radius + space - this.getDataBackgroundAndBorderPadding(title);
                }
            }
            if (this.timeNodeLayoutType == TimeNodeLayoutType.TITLE_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM) {
                desc.isHidden = true;
            } else {
                desc.isHidden = false;
            }

            if (!desc.isHidden) {
                desc.x = title.x + this.getDesAndTitleHSpace(timeNode);
                desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.getDesAndTitleVSpace(timeNode);

                top = desc.y + this.BaseLayout.getNodeHeight(desc) + this.subjectSpace;
            } else if (!title.isHidden) {
                top = title.y + this.BaseLayout.getNodeHeight(title) + this.subjectSpace;
            }

            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;

            dotElementContent.x = titleMiddlePoint.x - this.baseLine.x;
            dotElementContent.y = titleMiddlePoint.y - this.baseLine.y;

            if (this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM) {
                dotElementContent.radius = head.height/2;
            } else {
                dotElementContent.radius = this.radius;
            }
            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dots.push(dotElementContent);
        }
        this.baseLine.timeLineContent.dots = this.getOrderDot(dots);

    }

    getOrderDot(dots) {
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1.y == data2.y) {
                return 0;
            } else if (data1.y < data2.y) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;
    }

    getDataMinBottom(data) {
        return (this.Util.tan(this.ladderAngle) * data.width / 2);
    }

    getMaxBevelWidth(list) {
        let result = 0;
        if (list.isEmpty()) {
            return result;
        }
        let size = list.length;
        let lastNodeWidth = 0;
        for (let index = 0; index < size; index++) {
            let node = list[index];
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(node);
            let width = lastNodeWidth / 2 + nodeWidth / 2;
            result = this.addSubjectSpcaeHorizontal(Math.max(result, width));
            lastNodeWidth = nodeWidth;
        }
        return result;
    }

    getTimeNodeHeight(node) {
        let result = 0;
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                result = node.title.height + this.getDesAndTitleVSpace(node) + node.desc.height ;
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                result = node.title.height;
                break;
            default:
        }
        return result;
    }

    getHeight(includeTitle) {
        let nodeList = this.getOrderNodes();
        let titleHeight = includeTitle ? this.title.height : 0;
        if (nodeList.isEmpty()) {
            return titleHeight;
        }
        let nodeSize = nodeList.length;
        let height = 0;
        for (let index = 0; index < nodeSize; index++) {
            let timeNode = nodeList[index];
            let timeNodeHeight = this.getTimeNodeHeight(timeNode);
            if (index > 0) {
                height += (timeNodeHeight + this.subjectSpace);
            } else {
                height = timeNodeHeight;
            }
        }
        return Math.max(titleHeight, height);
    }

    getTimeNodeDesHeight(node) {
        let result = 0;
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                result = node.desc.height;
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                result = 0;
                break;
            default:
        }
        return result;
    }
}

export default TimeArcLayout