<template>
  <div id="mindmap-play-setup-box">
    <div class="over" @click="mindmapSetUp('OVER')">
      <span class="iconfont-mindmap-play-over">&#58890;</span>
    </div>
    <div class="play" @click="mindmapSetUp('PLAY')">
      <span class="iconfont-mindmap-play">&#58888;</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["mindmapPlayPauseShowSetup"]),
    mindmapSetUp(type) {
      if (type == "OVER") {
        this.mindmapPlayPauseShowSetup({
          showSetup: false,
          playAccout: "OVER",
        });
      } else if (type == "PLAY") {
        this.mindmapPlayPauseShowSetup({
          showSetup: false,
          playAccout: "PLAY",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 导图播放Icon
@font-face {
  font-family: "iconfontMindmapPlay";
  src: url("../../../assets/font-icon/mindmap_play/iconfont.eot");
  src: url("../../../assets/font-icon/mindmap_play/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/font-icon/mindmap_play/iconfont.woff2") format("woff2"),
    url("../../../assets/font-icon/mindmap_play/iconfont.woff") format("woff"),
    url("../../../assets/font-icon/mindmap_play/iconfont.ttf")
      format("truetype"),
    url("../../../assets/font-icon/mindmap_play/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-mindmap-play {
  font-family: "iconfontMindmapPlay" !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 导图播放结束Icon
@font-face {
  font-family: "iconfontMindmapPlayOver";
  src: url("../../../assets/font-icon/mindmap_play_over/iconfont.eot");
  src: url("../../../assets/font-icon/mindmap_play_over/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/font-icon/mindmap_play_over/iconfont.woff2")
      format("woff2"),
    url("../../../assets/font-icon/mindmap_play_over/iconfont.woff")
      format("woff"),
    url("../../../assets/font-icon/mindmap_play_over/iconfont.ttf")
      format("truetype"),
    url("../../../assets/font-icon/mindmap_play_over/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-mindmap-play-over {
  font-family: "iconfontMindmapPlayOver" !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#mindmap-play-setup-box {
  z-index: 9999;
  position: fixed;
  right: 22px;
  top: 11px;
  display: flex;
  div {
    width: 56px;
    height: 56px;
    background: #fcfcfc;
    border-radius: 50%;
    margin: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.16);
  }
}
@media screen and (min-width: 200px) and (max-width: 1500px) {
  #mindmap-play-setup-box {
    z-index: 9999;
    position: fixed;
    right: 50%;
    top: 6%;
    transform: translate(50%, -50%);
    display: flex;
    div {
      width: 56px;
      height: 56px;
      background: #fcfcfc;
      border-radius: 50%;
      margin: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.16);
    }
  }
}
</style>