import BaseInputView from "./BaseInputView";
/**
 * ProjectName: MindMap
 * Created by tony on 5/2/21
 * Copyright(c) 2020 mindyushu.com
 */

class FloatExplainView extends BaseInputView {
    constructor(context, data,vuethis) {
        super(context, data,vuethis)
    }

}

export default FloatExplainView
