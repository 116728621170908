<template>
  <!-- 移动端登录弹窗 -->
  <section class="moble-login" v-if="isMobleShowLoginModel">
    <div class="row">
      <div class="lright-cont">
        <div class="Login-header">
          <div class="Login-container">
            <div class="lc-titel">
              <!-- 设置密码 -->
              <h2 v-if="ShowLogin == 'SetPassworld'">
                {{ getString(strings.Mind_Set_Password) }}
              </h2>
              <h2 v-else>
                {{ getString(strings.User_Lolgin_Welcome_To_Login) }}
              </h2>
            </div>
          </div>
          <div @click.stop="HideUserModel()" class="moble-close-modal">
            <a-icon type="close-circle" />
          </div>
        </div>
        <div class="login-content">
          <!-- 设置密码 -->
          <div class="login" v-if="ShowLogin == 'SetPassworld'">
            <a-form :form="form" @submit="handleMobleSignIn">
              <div class="drop-down">
                <img src="../../assets/img/code/pwd_input_logo.png" alt="" />
              </div>
              <a-form-item has-feedback>
                <a-input
                  allow-clear
                  oninput="if(value.length>24)value=value.slice(0,24)"
                  :placeholder="
                    getString(strings.Input_Please_Enter_The_Password)
                  "
                  ref="newPassword"
                  @blur="newPasswordBlurForValidate"
                  v-decorator="[
                    'newPassword',
                    {
                      rules: [
                        {
                          required: true,
                          message: getString(
                            strings.Input_Please_Enter_A_New_Password
                          ),
                        },
                        {
                          validator: validateToNextPassword,
                        },
                      ],
                    },
                  ]"
                  type="password"
                >
                </a-input>
              </a-form-item>
              <div class="drop-down">
                <img src="../../assets/img/code/pwd_input_logo.png" alt="" />
              </div>
              <a-form-item has-feedback>
                <a-input
                  allow-clear
                  oninput="if(value.length>24)value=value.slice(0,24)"
                  :placeholder="getString(strings.User_Lolgin_Confirm_Password)"
                  ref="confirm"
                  @blur="confirmBlurForValidate"
                  v-decorator="[
                    'confirm',
                    {
                      rules: [
                        {
                          required: true,
                          message: getString(
                            strings.Input_Confirm_The_New_Password
                          ),
                        },
                        {
                          validator: compareToFirstPassword,
                        },
                      ],
                    },
                  ]"
                  type="password"
                >
                </a-input>
              </a-form-item>
              <a-form-item v-bind="tailFormItemLayout" style="margin-bottom: 0">
                <a-button
                  class="Login-Button"
                  type="primary"
                  html-type="submit"
                >
                  {{ getString(strings.User_Lolgin_Login) }}
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <!-- 验证码登录 -->
          <div
            class="login"
            v-else-if="mobileTerminalLogin == 'mobileVerificationLogin'"
          >
            <a-form :form="form" @submit="handleSubmitLogin">
              <div class="drop-down d-quhao" style="margin-top: 11px">
                <img src="../../assets/img/code/phone_mobel_logo.png" alt="" />

                <div class="country-code">+86</div>
              </div>
              <div class="modal-backdrop fade in" style="z-index: 1"></div>
              <a-form-item>
                <a-input
                  class="login-phone-number"
                  oninput="if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\d]/g,'')"
                  :placeholder="
                    getString(strings.User_Lolgin_Please_Enter_Phone_Number)
                  "
                  @blur="phoneNumberBlurForValidate"
                  @input="inputForValidatePhoneNumber"
                  ref="phoneNumber"
                  allow-clear
                  v-decorator="[
                    'phoneNumber',
                    {
                      rules: [{ validator: phoneNumber }],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
              <div class="drop-down">
                <img
                  src="../../assets/img/code/verification_code_logo.png"
                  alt=""
                />
              </div>
              <a-form-item>
                <div class="dr-agroup">
                  <a-input
                    allow-clear
                    oninput="if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\d]/g,'')"
                    :placeholder="
                      getString(
                        strings.User_Lolgin_Please_Enter_Verification_Code
                      )
                    "
                    ref="CodeValue"
                    v-decorator="[
                      'captcha',
                      {
                        rules: [
                          {
                            required: false,
                            message: getString(
                              strings.Input_Please_Enter_Verification_Code
                            ),
                          },
                          {
                            validator: validataToCode,
                          },
                        ],
                      },
                    ]"
                  >
                  </a-input>
                  <div @click="handleSubmitCode">
                    <a-button
                      class="dra-btn scode"
                      disabled
                      v-if="showTimeData"
                    >
                      {{ timeData }}
                      <span>s</span>
                    </a-button>
                    <a-button
                      class="dra-btn"
                      :class="
                        canGetVerificationCode ? 'can-use' : 'out-of-commission'
                      "
                      v-else
                    >
                      {{ getString(strings.Get_Verification_Code) }}
                    </a-button>
                  </div>
                </div>
              </a-form-item>
              <section class="enter-invitation-code" v-if="enterInvitationCode">
                <label @click="passwordLogin()">
                  {{ getString(strings.User_Lolgin_Password_Login) }}
                </label>
                <span @click="enterInvitationCode = !enterInvitationCode">
                  {{
                    getString(strings.Mind_Group_Enter_Invitation_Code)
                  }}</span
                >
              </section>
              <section v-else>
                <div class="drop-down">
                  <img
                    src="../../assets/img/code/Invitation_code_logo.png"
                    alt=""
                  />
                </div>
                <div
                  class="drop-down-before"
                  @click="enterInvitationCode = !enterInvitationCode"
                >
                  {{ getString(strings.User_Lolgin_Optional) }}
                </div>
                <a-form-item style="margin-bottom: 0">
                  <div class="dr-agroup">
                    <a-input
                      allow-clear
                      :placeholder="
                        getString(
                          strings.User_Lolgin_Please_Enter_Invitation_Code
                        )
                      "
                      ref="invitation"
                      v-decorator="[
                        'invitation',
                        {
                          rules: [
                            {
                              required: false,
                              message: getString(
                                strings.User_Lolgin_Please_Correct_Enter_Invitation_Code
                              ),
                            },
                            {
                              validator: invitationToCode,
                            },
                          ],
                        },
                        (maxLength = 12),
                      ]"
                    >
                    </a-input>
                  </div>
                </a-form-item>
              </section>
              <a-form-item v-bind="tailFormItemLayout" style="margin-bottom: 0">
                <a-button
                  class="Login-Button"
                  type="primary"
                  html-type="submit"
                >
                  {{ getString(strings.User_Lolgin_Login) }}
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <!-- 密码登录 -->
          <div
            class="login"
            v-else-if="mobileTerminalLogin == 'mobilePasswordLogin'"
          >
            <a-form :form="form" @submit="moblePasswordSubmitLogin">
              <div class="drop-down d-quhao" style="margin-top: 11px">
                <img src="../../assets/img/code/phone_mobel_logo.png" alt="" />

                <div class="country-code">+86</div>
              </div>
              <div class="modal-backdrop fade in" style="z-index: 1"></div>
              <a-form-item>
                <a-input
                  class="login-phone-number"
                  oninput="if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\d]/g,'')"
                  :placeholder="
                    getString(strings.User_Lolgin_Please_Enter_Phone_Number)
                  "
                  @blur="phoneNumberBlurForValidate"
                  ref="phoneNumber"
                  allow-clear
                  v-decorator="[
                    'phoneNumber',
                    {
                      rules: [{ validator: phoneNumber }],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
              <div class="drop-down">
                <img src="../../assets/img/code/pwd_input_logo.png" alt="" />
              </div>
              <a-form-item>
                <a-input-password
                  oninput="if(value.length>24)value=value.slice(0,24)"
                  :placeholder="
                    getString(strings.User_Lolgin_Please_Enter_Password)
                  "
                  ref="password"
                  @blur="passwordBlurForValidate"
                  v-decorator="[
                    'moble-password',
                    {
                      rules: [
                        {
                          required: true,
                          message: getString(
                            strings.Input_Please_Enter_The_Password
                          ),
                        },
                        {
                          validator: validateToNextPassword,
                        },
                      ],
                    },
                  ]"
                  type="password"
                >
                  <!-- <a-icon type="lock" slot="addonBefore" /> -->
                </a-input-password>
              </a-form-item>
              <section class="enter-invitation-code">
                <label @click="mailboxLoginFun()">
                  {{ getString(strings.Mailbox_Login) }}
                </label>
                <label @click="verificationCodeLogin()">
                  {{ getString(strings.User_Lolgin_Verification_Code_Login) }}
                </label>
              </section>
              <a-form-item v-bind="tailFormItemLayout" style="margin-bottom: 0">
                <a-button
                  class="Login-Button"
                  type="primary"
                  html-type="submit"
                >
                  {{ getString(strings.User_Lolgin_Login) }}
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <!-- 邮箱登录 -->
          <div
            class="login"
            v-else-if="mobileTerminalLogin == 'mobileMailboxLogin'"
          >
            <a-form :form="form" @submit="mobleMailboxSubmitLogin">
              <div class="drop-down d-quhao">
                <img src="../../assets/img/code/mailbox_login.png" alt="" />
              </div>
              <a-form-item>
                <a-input
                  allow-clear
                  ref="userEmailInput"
                  type="email"
                  :placeholder="getString(strings.Contact_Us_Email)"
                  v-decorator="[
                    'moble-email',
                    {
                      rules: [
                        {
                          required: true,
                          message: getString(strings.Input_Email_isNull),
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
              <div class="drop-down">
                <img src="../../assets/img/code/pwd_input_logo.png" alt="" />
              </div>
              <a-form-item>
                <a-input-password
                  oninput="if(value.length>24)value=value.slice(0,24)"
                  :placeholder="
                    getString(strings.User_Lolgin_Please_Enter_Password)
                  "
                  ref="password"
                  @blur="passwordBlurForValidate"
                  v-decorator="[
                    'moble-password',
                    {
                      rules: [
                        {
                          required: true,
                          message: getString(
                            strings.Input_Please_Enter_The_Password
                          ),
                        },
                        {
                          validator: validateToNextPassword,
                        },
                      ],
                    },
                  ]"
                  type="password"
                >
                  <!-- <a-icon type="lock" slot="addonBefore" /> -->
                </a-input-password>
              </a-form-item>
              <section class="enter-invitation-code">
                <label @click="verificationCodeLogin()">
                  {{ getString(strings.User_Lolgin_Verification_Code_Login) }}
                </label>
                <label @click="mailboxRegistration()">
                  {{ getString(strings.Mailbox_Registration) }}
                </label>
              </section>
              <a-form-item v-bind="tailFormItemLayout" style="margin-bottom: 0">
                <a-button
                  class="Login-Button"
                  type="primary"
                  html-type="submit"
                >
                  {{ getString(strings.User_Lolgin_Login) }}
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <!-- 邮箱注册 -->
          <div
            class="login"
            v-else-if="mobileTerminalLogin == 'mobileMailboxRegistration'"
          >
            <a-form :form="form" @submit="handleMailboxRegistration">
              <div class="drop-down d-quhao">
                <img src="../../assets/img/code/mailbox_login.png" alt="" />
              </div>
              <a-form-item>
                <a-input
                  allow-clear
                  ref="userEmailInput"
                  type="email"
                  :placeholder="getString(strings.Contact_Us_Email)"
                  v-decorator="[
                    'moble-email',
                    {
                      rules: [
                        {
                          required: true,
                          message: getString(strings.Input_Email_isNull),
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
              <div class="drop-down">
                <img
                  src="../../assets/img/code/verification_code_logo.png"
                  alt=""
                />
              </div>
              <a-form-item>
                <div class="dr-agroup">
                  <a-input
                    allow-clear
                    oninput="if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\d]/g,'')"
                    :placeholder="
                      getString(
                        strings.User_Lolgin_Please_Enter_Verification_Code
                      )
                    "
                    ref="CodeValue"
                    v-decorator="[
                      'moble-captcha',
                      {
                        rules: [
                          {
                            required: false,
                            message: getString(
                              strings.Input_Please_Enter_Verification_Code
                            ),
                          },
                          {
                            validator: validataToCode,
                          },
                        ],
                      },
                    ]"
                  >
                  </a-input>
                  <div @click="handleMailboxCode">
                    <a-button
                      class="dra-btn scode"
                      disabled
                      v-if="showTimeData"
                    >
                      {{ timeData }}
                      <span>s</span>
                    </a-button>
                    <a-button class="dra-btn can-use" v-else>
                      {{ getString(strings.Get_Verification_Code) }}
                    </a-button>
                  </div>
                </div>
              </a-form-item>
              <section class="enter-invitation-code">
                <label @click="verificationCodeLogin()">
                  {{ getString(strings.User_Lolgin_Verification_Code_Login) }}
                </label>
                <label @click="mailboxLoginFun()">
                  {{ getString(strings.Mailbox_Login) }}
                </label>
              </section>
              <a-form-item v-bind="tailFormItemLayout" style="margin-bottom: 0">
                <a-button
                  class="Login-Button"
                  type="primary"
                  html-type="submit"
                >
                  {{ getString(strings.User_Lolgin_Login) }}
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- pc端登录弹窗 -->
  <div v-else class="Login-content" ref="loginContent">
    <aside class="login-left-text" v-if="ShowLogin !== 'BindingMobile'">
      <div class="logo-img">
        <img src="../../assets/img/code/logoWhite.png" />
      </div>
      <ul class="login-left-container">
        <li v-for="(item, index) in loginLeftContainer" :key="index">
          {{ item.label }}
        </li>
      </ul>
    </aside>
    <div
      ref="loginRightContent"
      v-if="ShowCode || hideLogo"
      class="login-right-content"
      :class="{ 'border-radius-8': ShowLogin == 'BindingMobile' }"
    >
      <section class="login-tab" ref="loginTab">
        <template v-if="ShowLogin == 'Login' || ShowLogin == 'Verifications'">
          <!-- 账号密码登录 -->
          <div
            class="login-tab-item"
            @click="ShowLogin = 'Login'"
            :class="{ active: ShowLogin == 'Login', 'font-size-16': isChinese }"
          >
            {{ getString(strings.Account_Password_Login) }}
            <div v-if="ShowLogin == 'Login'" class="login-tab-line"></div>
          </div>
          <!-- 验证码登录/注册 -->
          <div
            class="login-tab-item"
            @click="ShowLogin = 'Verifications'"
            :class="{
              active: ShowLogin == 'Verifications',
              'font-size-16': isChinese,
            }"
          >
            {{ getString(strings.Authentication_code_Login_registration) }}
            <div
              v-if="ShowLogin == 'Verifications'"
              class="login-tab-line"
            ></div>
          </div>
          <!-- 扫码登录 -->
          <div
            class="login-tab-item"
            @click="ClickShowCodeOther()"
            :class="{ 'font-size-16': isChinese }"
            v-if="isChinese"
          >
            {{ getString(strings.User_Lolgin_Scan_Code_Login) }}
          </div>
        </template>
        <!-- 重置密码 -->
        <template v-else-if="ShowLogin == 'FindPassworld'">
          <div
            class="login-tab-item cursor-auto"
            :class="{ 'font-size-16': isChinese }"
          >
            {{ getString(strings.Reset_Password) }}
          </div>
        </template>
        <!-- 重置邮箱密码 -->
        <template v-else-if="ShowLogin == 'ResetEmailPassworld'">
          <div
            class="login-tab-item cursor-auto"
            :class="{ 'font-size-16': isChinese }"
          >
            {{ getString(strings.Reset_Mailbox_Password) }}
          </div>
        </template>
        <!-- 邮箱注册 -->
        <template v-else-if="ShowLogin == 'EmailVerificationCodeTable'">
          <div
            class="login-tab-item cursor-auto"
            :class="{ 'font-size-16': isChinese }"
            style="flex-direction: row"
          >
            <a-icon
              class="acc-ion cursor-pointer"
              type="left"
              @click="
                ClickLoginShow('Verifications');
                currentThirdPartyCode = {};
              "
              style="margin-right: 12px"
            />
            {{ getString(strings.Mailbox_Registration) }}
          </div>
        </template>
        <!-- 邮箱登录 -->
        <template v-else-if="ShowLogin == 'MailboxLogin'">
          <div
            class="login-tab-item cursor-auto"
            :class="{ 'font-size-16': isChinese }"
            style="flex-direction: row"
          >
            <a-icon
              class="acc-ion cursor-pointer"
              type="left"
              @click="
                ClickLoginShow('Login');
                currentThirdPartyCode = {};
              "
              style="margin-right: 12px"
            />
            {{ getString(strings.Mailbox_Login) }}
          </div>
        </template>
        <template v-else-if="ShowLogin == 'SetPassworld'">
          <div
            class="login-tab-item cursor-auto"
            :class="{ 'font-size-16': isChinese }"
          >
            <!-- 设置密码 -->
            {{ getString(strings.Mind_Set_Password) }}
          </div>
        </template>
        <template v-else-if="ShowLogin == 'ThirdPartyCode'">
          <div
            class="login-tab-item cursor-auto"
            :class="{ 'font-size-16': isChinese }"
            style="flex-direction: row"
          >
            <a-icon
              class="acc-ion cursor-pointer"
              type="left"
              @click="
                ClickLoginShow('Login');
                currentThirdPartyCode = {};
              "
              style="margin-right: 12px"
            />
            请使用{{ currentThirdPartyCode.label }}扫描二维码登录
          </div>
        </template>
        <template v-else-if="ShowLogin == 'BindingMobile'">
          <div
            :class="{ 'font-size-20': isChinese }"
            class="login-tab-item cursor-auto"
            style="flex-direction: row"
          >
            扫码成功
            <img
              class="scan-code-successfully"
              src="../../assets/img/code/scanCodeSuccessfully.svg"
              style="margin-left: 8px"
              alt=""
            />
          </div>
        </template>
        <!-- internationalization -->
        <a-icon type="close-circle" class="get-back" @click="HideUserModel" />
      </section>

      <template v-if="otherLoginMethods">
        <section
          class="other-login"
          v-if="
            ShowLogin == 'Login' ||
            ShowLogin == 'Verifications' ||
            ShowLogin == 'ThirdPartyCode'
          "
        >
          <div class="other-login-methods">
            <div class="other-login-line"></div>
            <div>
              <div class="other-login-text">其它登录方式</div>
              <div class="other-login-text font-size-12">
                首次登录请绑定手机号
              </div>
            </div>
            <div class="other-login-line"></div>
          </div>
          <div class="other-login-item">
            <!-- <img :src="require('../../assets/img/code/otherLoginMethods/wechat.svg')" @click="ShowLogin = 'ThirdPartyCode'" alt=""> -->
            <template v-for="(item, index) in thirdPartyCodeArr">
              <!-- v-if="item.id != currentThirdPartyCode.id" -->
              <img
                :key="index"
                :src="
                  require(`../../assets/img/code/otherLoginMethods/${item.img}`)
                "
                :title="item.label"
                @click="setThirdPartyCode(item)"
              />
            </template>
          </div>
          <div class="consent-clause">
            <label for="accept-checkbox" class="accept-box">
              <img v-if="accept" src="../../assets/img/code/accept.svg" />
              <span v-else class="accept-checkbox"></span>
            </label>
            <input type="checkbox" id="accept-checkbox" v-model="accept" />
            <div class="consent-clause-text">
              同意<a
                href="https://www.mindyushu.com/agreement.html"
                target="_self"
                >{{
                  getString(strings.Mind_Edit_Left_Menu_Terms_Of_Service)
                }}</a
              >
              和<a
                href="https://www.mindyushu.com/privacy.html"
                target="_self"
                >{{ getString(strings.Mind_Edit_Left_Menu_Privacy_Policy) }}</a
              >
            </div>
          </div>
          <div
            class="Login-code"
            @click="ClickShowCodeOther"
            :title="getString(strings.User_Lolgin_Scan_Code_Login)"
          >
            <img src="../../assets/img/code/qrcode9.png" alt />
          </div>
          <!-- internationalization -->
        </section>
      </template>

      <!-- 登录 -->
      <div v-if="ShowLogin == 'Login'" class="hello">
        <div class="login-content-icon">
          <Login
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
            @loginSucceededFun="loginSucceededFun"
          />
        </div>
      </div>
      <!-- 邮箱登录 -->
      <div v-if="ShowLogin == 'MailboxLogin'" class="hello">
        <div class="login-content-icon">
          <MailboxLogin
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
            @loginSucceededFun="loginSucceededFun"
          />
        </div>
      </div>
      <!-- 忘记密码 -->
      <div v-else-if="ShowLogin == 'FindPassworld'" class="hello">
        <div class="login-content-icon">
          <FindPassworld
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
          />
        </div>
      </div>
      <!-- 重置邮箱密码 -->
      <div v-else-if="ShowLogin == 'ResetEmailPassworld'" class="hello">
        <div class="login-content-icon">
          <ResetEmailPassworld
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
          />
        </div>
      </div>
      <!-- 设置密码 -->
      <div v-else-if="ShowLogin == 'SetPassworld'" class="hello">
        <div class="login-content-icon">
          <SetPassworld
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
          />
        </div>
      </div>
      <!-- 注册 -->
      <div v-else-if="ShowLogin == 'Sign'" class="hello">
        <div class="login-content-icon">
          <Sign
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ClickLoginOkShow="ClickLoginOkShow"
            @ShowHome="ShowHome"
          />
          <a-icon type="close-circle" class="get-back" @click="HideUserModel" />
        </div>
      </div>
      <!-- 验证码 -->
      <div v-else-if="ShowLogin == 'Verifications'" class="hello">
        <div class="login-content-icon">
          <Verifications
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ClickLoginOkShow="ClickLoginOkShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
            @loginSucceededFun="loginSucceededFun"
          />
        </div>
      </div>
      <!-- 邮箱注册 -->
      <div v-else-if="ShowLogin == 'EmailVerificationCodeTable'" class="hello">
        <div class="login-content-icon">
          <EmailVerificationCode
            class="father-login"
            @ClickLoginShow="ClickLoginShow"
            @ClickLoginOkShow="ClickLoginOkShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
            @loginSucceededFun="loginSucceededFun"
          />
        </div>
      </div>
      <!-- 第三方登录 -->
      <div v-else-if="ShowLogin == 'ThirdPartyCode'" class="hello">
        <div class="login-content-icon">
          <ThirdPartyCode
            class="father-login"
            :currentThirdPartyCode="currentThirdPartyCode"
            @ClickLoginShow="ClickLoginShow"
            @ClickLoginOkShow="ClickLoginOkShow"
            @ShowHome="ShowHome"
            @getThirdPartyLoginUser="getThirdPartyLoginUser"
            @loginSucceededFun="loginSucceededFun"
          />
        </div>
      </div>
      <!-- 绑定手机号 -->
      <div v-else-if="ShowLogin == 'BindingMobile'" class="hello">
        <div class="login-content-icon">
          <BindingMobile
            class="father-login"
            :thirdPartyLoginUser="thirdPartyLoginUser"
            :currentThirdPartyCode="currentThirdPartyCode"
            @ClickLoginShow="ClickLoginShow"
            @ClickLoginOkShow="ClickLoginOkShow"
            @ShowHome="ShowHome"
            @ClickShowCode="ClickShowCode"
            @loginSucceededFun="loginSucceededFun"
          />
        </div>
      </div>
    </div>
    <!-- 扫码登录 -->
    <div v-else ref="loginRightContent" class="hello">
      <div class="login-content-icon">
        <Code
          class="father-login"
          @ClickLoginShow="ClickLoginShow"
          @ClickShowCode="ClickShowCode"
          @ShowHome="ShowHome"
          @loginSucceededFun="loginSucceededFun"
        />
        <a-icon
          type="close-circle"
          class="get-back"
          style="top: 28px"
          @click="HideUserModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./ChildLogin/Login";
import MailboxLogin from "./ChildLogin/mailboxLogin/MailboxLogin";
import ResetEmailPassworld from "./ChildLogin/mailboxLogin/ResetEmailPassworld";
import EmailVerificationCode from "./ChildLogin/mailboxLogin/EmailVerificationCode";
import Code from "./ChildLogin/Code";
import ThirdPartyCode from "./ChildLogin/ThirdPartyCode";
import Sign from "./ChildLogin/Sign";
import FindPassworld from "./ChildLogin/FindPassworld";
import SetPassworld from "./ChildLogin/SetPassworld";
import Verifications from "./ChildLogin/Verifications";
import BindingMobile from "./ChildLogin/BindingMobile";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import {
  postUserCheckPassword,
  postUserLogin,
  postUserCaptcha,
  postUserMe,
  postUserPasswordLogin,
  postUserEmailLogin,
  postUserEmailCaptcha,
  postUserEmailRegister,
  postUserChangePassword,
} from "../../common/netWork/base_api";
import { doLogin, setJwt } from "../../common/netWork/base";
import regExp from "../../components/loginComponents/regular.js";
const { phoneNumberRegExp } = regExp;

import { mapMutations } from "vuex";
import MindMe from '../../viewmodel/facade/MindMe';
import Config from '../../core/core/calcule/Config';
import Util from '../../utils/Util';
export default {
  name: "childLogin",
  inject: ["reload"],
  data() {
    return {
      ShowLogin: "Login",
      // ShowLogin: "SetPassworld",
      ShowCode: true,
      HideModel: false,
      hideLogo: false,
      strings,
      accept: true,
      loginLeftContainer: [
        {
          label: getString(strings.One_End_Creation_Multi_End_Synchronization),
        },
        {
          label: getString(strings.Rich_And_Diverse_Mapping_Structure),
        },
        {
          label: getString(strings.Mind_Edit_Page_Sharing_Group),
        },
        {
          label: getString(strings.Massive_And_ExquisiteTemplateLibrary),
        },
        {
          label: getString(strings.Map_Review_Play),
        },
        {
          label: getString(strings.CommunityLearningAndSharingPlatform),
        },
      ],
      //第三方登录只在国内显示，不需要国际化
      thirdPartyCodeArr: [
        {
          id: "wechat",
          label: "微信",
          img: "wechat.svg",
          type: 4,
          notice: "微信登录请关注公众号",
        },
        // {
        //   id: "qq",
        //   label: "QQ",
        //   img: "qq.svg",
        //   type:2
        // },
      ],
      currentThirdPartyCode: {}, //选中的第三方登录方式
      thirdPartyLoginUser: {
        userName: "",
        headPortrait: "",
      }, //第三方登录用户
      loginContentObj: {}, //登录弹框的位置
      otherLoginMethods: false,
      isChinese: false,
      //移动端登录------------start
      mobileTerminalLogin: "mobileVerificationLogin", //移动端登录方式
      isMobleShowLoginModel: false,
      tailFormItemLayout: {
        wrapperCol: {},
      },
      SignInData: {
        areaCode: "",
        phoneNumber: "",
        invitation: "",
      },
      timeData: "",
      showTimeData: false,
      countryCode: 86,
      phoneNumberValidateCallback: null,
      passwordValidateCallback: null,
      newPasswordValidateCallback: null,
      confirmValidateCallback: null,
      formItemLayout: {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      },
      form: this.$form.createForm(this, {
        phoneNumber: "",
      }),
      enterInvitationCode: true, //输入邀请码
      canGetVerificationCode: false, //能够获取验证码
      //移动端登录------------end
    };
  },
  components: {
    Login,
    MailboxLogin,
    ResetEmailPassworld,
    EmailVerificationCode,
    Code,
    ThirdPartyCode,
    Sign,
    FindPassworld,
    Verifications,
    BindingMobile,
    SetPassworld,
  },
  mounted() {
    this.isMoble();

    window.onresize = () => {
      return (() => {
        this.isMoble();
      })();
    };

    localStorage.setItem("accept", this.accept);
    {
      const loginContent = window.getComputedStyle(this.$refs.loginContent);
      //  this.loginContentObj.left = loginContent.left;
      //  this.loginContentObj.top = loginContent.top;
    }
    this.IsChinese();
    this.getShowLogin();
  },
  methods: {
    ...mapMutations(["setUserIfLogin", "showLoginModel", "reloadStorageSpace"]),
    getString(i) {
      return getString(i);
    },
    getShowLogin() {
      postUserCheckPassword(
        "",
        (res) => {
          const myMessage = MindMe.getMeMessage();
          if (myMessage) {
            if (!(myMessage.hasPassword == undefined)) {
              if (!myMessage.hasPassword) {
                // 验证码登录或绑定手机号如果没有设置密码，显示设置密码
                this.ShowLogin = "SetPassworld";
                // console.log(myMessage.hasPassword);
              }
            }
          } else {
            if (res.hasPassword) {
              this.ShowLogin = "Login";
            } else {
              this.ShowLogin = "Verifications";
            }
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //登录注册切换
    ClickLoginShow(ShowLogin) {
      this.ShowLogin = ShowLogin;
      this.IsChinese();
    },
    // 二维码界面
    ClickShowCodeOther() {
      this.ShowCode = !this.ShowCode;
    },
    ClickShowCode(ShowCode) {
      this.ShowCode = ShowCode;
    },
    HideUserModel() {
      this.$emit("HideUserModel", this.HideModel);
      this.showLoginModel(false);
    },
    //注册成功
    ClickLoginOkShow(Account) {
      this.ShowLogin = !this.ShowLogin;
    },
    //登录状态
    ShowHome(Account) {
      this.hideLogo = Account;
      this.$emit("ShowHome", false);
    },
    //登录成功
    loginSucceededFun() {
      let routerData = this.$router.history.current.name; //路由状态
      if (
        routerData == "Complie" ||
        routerData == "gmap" ||
        routerData == "arvhiveMap"
      ) {
        let editMindmapVM = window.windowEditMindmapVM;
        if (editMindmapVM) {
          editMindmapVM.coverMindData(); //保存导图
          this.showLoginModel(false);
        }
      } else if (routerData == "s") {
        //分享
        this.bus.$emit("saveToMyMap", true); //保存到我的导图
      } else {
        this.reload();
        setTimeout(() => {
          this.reloadStorageSpace(true);
        }, 100);
        // this.mindmapRightMenu(false);//隐藏导图右侧菜单
      }
      this.showLoginModel(false); //隐藏登录弹框
    },
    setThirdPartyCode(item) {
      if (item.id == "qq") {
        this.toQQLogin();
        return;
      }
      this.currentThirdPartyCode = item;
      this.ShowLogin = "ThirdPartyCode";
      //  console.log( this.currentThirdPartyCode);
    },

    toQQLogin() {
      this.loginContentObj.top = (window.screen.height - 30 - 520) / 2; //获得窗口的垂直位置;
      this.loginContentObj.left = (window.screen.width - 10 - 700) / 2;
      const redirect_uri = encodeURIComponent(
        "https://web.mindyushu.com/redirect"
      );
      const client_id = 101951211;
      // const a1 = "https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id="+client_id+"&redirect_uri="+redirect_uri+"&state=test";
      const a2 =
        "https://graph.qq.com/oauth2.0/authorize?display=pc&response_type=code&client_id=" +
        client_id +
        "&redirect_uri=" +
        redirect_uri +
        "&state=test";

      //以下为按钮点击事件的逻辑。注意这里要重新打开窗口
      //否则后面跳转到QQ登录，授权页面时会直接缩小当前浏览器的窗口，而不是打开新窗口
      var A = window.open(
        a2,
        "TencentQQLogin",
        "width=700,height=520,top=" +
          this.loginContentObj.top +
          ",left=" +
          this.loginContentObj.left +
          ", menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
      );
    },
    IsChinese() {
      const jsSrc = (
        navigator.language || navigator.browserLanguage
      ).toLowerCase();
      let loginRightContentNode = this.$refs.loginRightContent;
      if (jsSrc.indexOf("zh") >= 0) {
        // 假如浏览器语言是中文，显示第三方登录
        this.otherLoginMethods = this.isChinese = true;
        if (loginRightContentNode) {
          loginRightContentNode.style.width = "423px"; //中文字数少，宽度设小一点
        }
      } else {
        if (loginRightContentNode) {
          loginRightContentNode.style.width = "463px";
        }
        this.mobileTerminalLogin = "mobileMailboxLogin";
      }
    },
    isMoble() {
      let client_width = document.documentElement.clientWidth;
      if (client_width < 682) {
        this.isMobleShowLoginModel = true;
      } else {
        this.isMobleShowLoginModel = false;
      }
    },
    getThirdPartyLoginUser(data) {
      this.thirdPartyLoginUser = data;
    },

    // 移动端登录----------start
    handleSubmitLogin(e) {
      e.preventDefault();
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;

      //输入邀请码
      if (!this.enterInvitationCode) {
        this.SignInData.invitation = this.$refs.invitation.value;
      }

      this.form.validateFieldsAndScroll(
        ["phoneNumber", "captcha"],
        {
          first: true,
        },
        (err, values) => {
          if (!err) {
            values.areaCode = this.SignInData.areaCode;
            if (!this.enterInvitationCode) {
              values.invitationCode = this.$refs.invitation.value;
            }
            this.loginRequest(values)
              .then((data) => {
                setJwt(data.jwt);
                this.$message.success(
                  getString(strings.Message_Tips_Login_Success)
                );
                this.loginSucceededFun();
                if (!data.hasPassword) {
                  setTimeout(() => {
                    this.showLoginModel(true);
                  }, 100);
                }
                return this.userMessage();
              })
              .then((data) => {
                this.HideUserModel();
                this.setUserIfLogin(true);
              });
          }
        }
      );
    },

    loginRequest(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserLogin(
          obj,
          (res) => {
            doLogin(res);
            resolve(res);
            try {
              if (res.registerTime && 
                ((res.serverTime && Math.abs(res.registerTime - res.serverTime) < 30) ||
                (Math.abs(Util.getCurrentTimeInsecond() - res.serverTime) < 30))) {
                Config.registerBz()
              }
            } catch (e) { 
              // console.log(e);
            }
            
            
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    userMessage(obj = {}) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserMe(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    // 获取验证码
    handleSubmitCode(e) {
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      const SignInData = this.SignInData;
      // console.log(SignInData);
      this.form.validateFieldsAndScroll(
        ["phoneNumber"],
        {
          first: true,
        },
        (err, values) => {
          if (!err) {
            postUserCaptcha(
              SignInData,
              (res) => {
                if (res == false) {
                  this.showTimeData = res;
                } else {
                  this.showTimeData = true;
                  this.timeData = 60;
                  let countDown = setInterval(() => {
                    if (this.timeData < 1) {
                      this.timeData = 0;
                      this.showTimeData = false;
                      clearInterval(countDown);
                    } else {
                      this.timeData--;
                    }
                  }, 1000);
                }
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    // 手机号验证
    phoneNumber(rule, value, callback) {
      this.phoneNumberValidateCallback = callback;
      if (value.substring(0, 2) == 90) {
        callback();
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!(!iphoneNumber.test(value) && value)) {
          callback();
        }
      } else {
        if ((value + "").length > 4 && (value + "").length < 20) {
          callback();
        }
      }
    },
    setGetVerificationCode(val) {
      this.canGetVerificationCode = val;
    },
    inputForValidatePhoneNumber() {
      let phoneNumberNode = document.getElementById("phoneNumber");
      if (phoneNumberNode) {
        let value = phoneNumberNode.value;
        if (value) {
          if ("" + this.countryCode == "86") {
            let iphoneNumber = phoneNumberRegExp;
            if (iphoneNumber.test(value)) {
              this.setGetVerificationCode(true);
            } else {
              this.setGetVerificationCode(false);
            }
          }
        } else {
          this.setGetVerificationCode(false);
        }
      }
    },
    //手机号验证焦点离开判断
    phoneNumberBlurForValidate(e) {
      let phoneNumberNode = this.$refs.phoneNumber;
      if (!phoneNumberNode) {
        return;
      }
      let value = phoneNumberNode.value;
      if (this.phoneNumberValidateCallback == null) {
        return;
      }
      if (!value) {
        this.phoneNumberValidateCallback(
          getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
        );
        this.setGetVerificationCode(false);
        return;
      }
      if (value.substring(0, 2) == 90) {
        this.phoneNumberValidateCallback();
        this.setGetVerificationCode(true);
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!iphoneNumber.test(value) && value) {
          this.phoneNumberValidateCallback(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
          );
          this.setGetVerificationCode(false);
        } else {
          this.phoneNumberValidateCallback();
          this.setGetVerificationCode(true);
        }
      } else {
        if ((value + "").length <= 4 || (value + "").length >= 20) {
          this.phoneNumberValidateCallback(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
          );
          this.setGetVerificationCode(false);
        } else {
          this.phoneNumberValidateCallback();
          this.setGetVerificationCode(true);
        }
      }
    },
    // 验证码验证
    validataToCode(rule, value, callback) {
      const iCaptcha = /^[0-9]*$/;
      if (!iCaptcha.test(value) && value) {
        callback("验证码错误");
      } else {
        callback();
      }
    },
    //邀请码验证
    invitationToCode(rule, value, callback) {
      const iCaptcha = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
      if (!iCaptcha.test(value) && value) {
        callback("邀请码输入错误");
      } else {
        callback();
      }
    },
    //  验证码登录
    verificationCodeLogin() {
      this.mobileTerminalLogin = "mobileVerificationLogin";
    },
    //密码登录
    passwordLogin() {
      this.mobileTerminalLogin = "mobilePasswordLogin";
    },
    //邮箱登录
    mailboxLoginFun() {
      this.mobileTerminalLogin = "mobileMailboxLogin";
    },
    //邮箱注册
    mailboxRegistration() {
      this.mobileTerminalLogin = "mobileMailboxRegistration";
    },
    //密码验证焦点离开判断
    passwordBlurForValidate(e) {
      if (this.passwordValidateCallback == null) {
        return;
      }
      let value = this.$refs.password.value;
      const PassWordValue =
        /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
      if (!PassWordValue.test(value) && value) {
        this.passwordValidateCallback(
          getString(strings.Input_The_Password_Format_Is_Incorrect)
        );
      } else {
        this.passwordValidateCallback();
      }
    },
    // 密码验证
    validateToNextPassword(rule, value, callback) {
      this.passwordValidateCallback = callback;
      // LoginData.yz(rule, value, callback);
      // const form = this.form;
      const PassWordValue =
        /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
      if (!(!PassWordValue.test(value) && value)) {
        callback();
      }
    },
    moblePasswordSubmitLogin(e) {
      e.preventDefault();
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      this.phoneNumberBlurForValidate();
      this.form.validateFieldsAndScroll(
        ["phoneNumber", "moble-password"],
        (err, values) => {
          if (!err) {
            const obj = {
              areaCode: this.SignInData.areaCode,
              phoneNumber: values["phoneNumber"],
              password: values["moble-password"],
            };
            // console.log(obj, values);
            postUserPasswordLogin(
              obj,
              (res) => {
                doLogin(res);
                setJwt(res.jwt);
                this.$message.success(
                  getString(strings.Message_Tips_Login_Success)
                );
                this.loginSucceededFun();
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    mobleMailboxSubmitLogin(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(
        ["moble-email", "moble-password"],
        (err, values) => {
          if (!err) {
            const obj = {
              email: values["moble-email"],
              password: values["moble-password"],
            };
            // console.log(obj, values);
            postUserEmailLogin(
              obj,
              (res) => {
                doLogin(res);
                setJwt(res.jwt);
                this.$message.success(
                  getString(strings.Message_Tips_Login_Success)
                );
                this.loginSucceededFun();
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    // 邮箱获取验证码
    handleMailboxCode(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(["moble-email"], (err, values) => {
        // console.log('走入了获取验证码',values)
        if (!err) {
          const obj = {
            email: values["moble-email"],
          };
          postUserEmailCaptcha(
            obj,
            (res) => {
              if (res == false) {
                this.showTimeData = res;
              } else {
                this.showTimeData = true;
                this.timeData = 60;
                let countDown = setInterval(() => {
                  if (this.timeData < 1) {
                    this.timeData = 0;
                    this.showTimeData = false;
                    clearInterval(countDown);
                  } else {
                    this.timeData--;
                  }
                }, 1000);
              }
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      });
    },
    handleMailboxRegistration(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(
        ["moble-email", "moble-captcha"],
        (err, values) => {
          if (!err) {
            const obj = {
              email: values["moble-email"],
              captcha: values["moble-captcha"],
            };
            // console.log(obj, values);
            postUserEmailRegister(
              obj,
              (res) => {
                doLogin(res);
                setJwt(res.jwt);
                this.$message.success(
                  getString(strings.Message_Tips_Login_Success)
                );
                this.loginSucceededFun();
                if (!res.hasPassword) {
                  setTimeout(() => {
                    this.showLoginModel(true);
                  }, 100);
                }
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    newPasswordBlurForValidate(e) {
      if (this.newPasswordValidateCallback == null) {
        return;
      }
      let value = this.$refs.newPassword.value;
      // console.log("密码", value);
      if (this.newPassword != "") {
        const PassWordValue =
          /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
        if (!PassWordValue.test(value) && value) {
          this.newPasswordValidateCallback(
            getString(strings.Input_The_Password_Format_Is_Incorrect)
          );
        } else {
          this.newPasswordValidateCallback();
        }
      } else {
        this.newPasswordValidateCallback(
          getString(strings.Input_Please_Enter_The_Password)
        );
      }
    },
    confirmBlurForValidate(e) {
      if (this.confirmValidateCallback == null) {
        return;
      }
      let value = this.$refs.confirm.value;
      if (value != this.$refs.newPassword.value) {
        this.confirmValidateCallback(
          getString(strings.Input_The_Passwords_Are_Inconsistent)
        );
      } else {
        this.confirmValidateCallback();
      }
      return;
    },
    // 确认密码验证
    compareToFirstPassword(rule, value, callback) {
      this.confirmValidateCallback = callback;
      // const form = this.form;
      if (this.confirm != "") {
        if (!(value != this.$refs.newPassword.value)) {
          callback();
        }
      } else {
        callback();
      }
    },
    handleMobleSignIn(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(
        ["newPassword", "confirm"],
        (err, values) => {
          if (!err) {
            const obj = {
              newPassword: values["newPassword"],
              captcha: "",
            };
            // console.log(obj, values);
            postUserChangePassword(
              obj,
              (res) => {
                this.showLoginModel(false);
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    // 移动端登录----------end
  },
  watch: {
    accept() {
      localStorage.setItem("accept", this.accept);
    },
    ShowLogin() {
      this.IsChinese();
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0;
  margin: 0;
}
.get-back:hover {
  font-size: 25px;
  color: red;
  transform: rotate(180deg);
  transition: 0.3s;
}
.login-content-icon {
  width: 100%;
  max-width: 1200px;
  position: relative;
}
.father-login {
  position: relative;
}
.get-back {
  position: absolute;
  z-index: 300;
  top: 0px;
  right: 1em;
  z-index: 2;
  font-size: 20px;
  transition: 0.3s;
}
.get-back:hover {
  font-size: 20px;
  color: red;
  transform: rotate(180deg);
  transition: 0.3s;
}
.Login-content {
  /* width: 682px; */
  height: 505px;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1001;
  display: flex !important;
}
.login-left-text {
  width: 261px;
  height: 505px;
  background: linear-gradient(134deg, #f84d4d 0%, #ff9185 100%);
  opacity: 1;
  border-radius: 8px 0px 0px 8px;
  padding: 30px;
}
.logo-img {
  margin-top: -10px;
}
.login-left-container {
  width: 100%;
  margin-top: 15px;
}
.login-left-container li {
  font-size: 15px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
  margin-top: 30px;
  margin-left: 20px;
  list-style: disc;
}
.login-right-content {
  width: 463px;
  background-color: #ffffff;
  position: relative;
  border-radius: 0px 8px 8px 0px;
}
.hello {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.login-tab {
  width: 100%;
  height: 30px;
  margin-top: 28px;
  display: flex;
  position: absolute;
  z-index: 200;
  z-index: 200;
}
.login-tab-item {
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  margin-left: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-tab .active {
  color: #ec705a;
}
.login-tab-line {
  width: 68px;
  height: 2px;
  background: #ec705a;
  opacity: 1;
  margin-top: 5px;
}

.other-login {
  width: 100%;
  padding: 0 40px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
}
.other-login-methods {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.other-login-text {
  /* width: 84px; */
  height: 20px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #999999;
}
.other-login-line {
  width: 98px;
  height: 1px;
  background-color: #cccccc;
  opacity: 1;
}
.other-login-item {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.other-login-item img {
  cursor: pointer;
}
.other-login-item img:nth-child(2n + 2) {
  margin-left: 16px;
}
.consent-clause {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}
.consent-clause-text {
  font-size: 12px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 17px;
  color: #666666;
  margin-left: 8px;
}
.accept-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accept-checkbox {
  width: 15px;
  height: 15px;
  display: inline-block;
  cursor: pointer;
  border: 1px solid #555555;
  border-radius: 50%;
}
#accept-checkbox {
  display: none;
}
.Login-code {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
}
.border-radius-8 {
  border-radius: 8px;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
</style>

<style lang="less" scoped>
@media screen and(min-width:250px) and(max-width:500px) {
  .lc-titel {
    display: none;
  }
  .login-content-icon {
    width: 100%;
    max-width: 500px;
    position: relative;
  }
  .Login-content {
    width: 100%;
    padding: 10px;
    // background-size: cover;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
    // z-index: 9999;
  }
  .hello {
    width: 100%;
    min-width: 0;
    max-width: 500px;
    .login-right {
      padding: 0;
      margin-top: 0;
      margin: 0 auto;
    }
  }
  .get-back {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .get-back:hover {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .login-content-icon-img {
    display: none;
  }
}
.moble-login {
  width: 342px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border-radius: 5px;

  .row {
    width: 100%;

    .lright-cont {
      width: 100%;

      .moble-close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 16px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        color: #333;
        border-radius: 0 10px 0 0;
        transition: 0.3s;
      }

      .lc-titel {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          line-height: 22px;
          color: #333;
          opacity: 1;
          margin-bottom: 20px;
        }
      }

      .login-content {
        margin: auto;

        .login {
          height: 100%;
          position: relative;
          margin-top: 0px;

          .ant-form {
            width: 100%;

            .drop-down {
              position: absolute;
              z-index: 5;
              margin-top: 13px;
              margin-left: 16px;
              display: flex;
              align-items: center;
              .country-code {
                width: 25px;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
                color: #333;
                margin-left: 8px;
              }
            }

            .drop-down-before {
              position: absolute;
              z-index: 5;
              margin-top: 10px;
              margin-right: 30px;
              right: 0;
              color: #b3b3b3;
              cursor: pointer;
            }

            .enter-invitation-code {
              width: 100%;
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: #fd492b;
              display: flex;
              justify-content: space-between;
              label {
                color: #666666;
              }
            }

            .ant-form-item {
              margin-bottom: 16px;

              .ant-col {
                .ant-form-item-control {
                  .ant-form-item-children {
                    .ant-input-affix-wrapper {
                      /deep/.ant-input {
                        height: 42px;
                        padding-left: 15%;
                        &:hover {
                          border-color: transparent;
                          border-right-width: 1px !important;
                          outline: 0;
                          box-shadow: 0 0 0 2px rgba(70, 46, 253, 0.8);
                        }
                      }

                      /deep/#phoneNumber {
                        padding-left: 30%;
                      }
                      /deep/#moble-password {
                        padding-left: 15%;
                      }

                      /deep/#captcha,
                      /deep/#moble-captcha {
                        width: 192px;
                        padding-left: 20%;
                      }

                      /deep/#invitation {
                        padding-left: 16%;
                      }
                    }

                    .dr-agroup {
                      width: 100%;
                      position: relative;
                      display: flex;
                      align-items: center;
                      .dra-btn {
                        width: 102px;
                        height: 42px;
                        font-size: 14px;
                        border-radius: 2px;
                        overflow: hidden;
                      }
                      .can-use {
                        background: #fd492b;
                        color: #fff;
                        border: none;
                      }
                      .out-of-commission {
                        border: 1px solid rgb(217 217 217);
                        color: #414141;
                      }
                    }

                    .Login-Button {
                      width: 100%;
                      height: 42px;
                      margin-top: 10px;
                      background-color: #fc492b !important;
                      border-color: #fc492b !important;
                      border-radius: 4px;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
