import Colors from "../../../utils/Colors"
import LineLayout from "../../../viewmodel/datatype/LineLayout"
import MindBGStripesType from "../../../viewmodel/datatype/MindBGStripesType"
import MindType from "../../../viewmodel/datatype/MindType"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import StyleType from "./StyleType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/15
 * Copyright(c) 2020 mindyushu.com
 */

class StylePanelData {

    constructor() {
        this.styleType = StyleType.GLOBAL;
        this.mindBGColor = Colors.white;
        this.mindBGStripesType = MindBGStripesType.NonExistent;
        this.mindGlobalLineWith = 1.5;
        this.mindGlobalLineColor = Colors.black60;
        this.node = new MindElementData().emptyMindNode();
        this.parentNode = new MindElementData().emptyMindNode();
        this.changeNodeLayout = true;
        this.changeNodeLineLayout = true;
        this.lineLayout = LineLayout.RIGHT_ANGLE_LINE;
        this.lineNode = new Array();
        this.lineThicken = false;
        this.lineEndThicken = false;
        this.lineDot = false;
        this.mindType = MindType.LINE_MAP;
    }

    isHaveLineNode() {
        if (lineNode.length == 0) {
            return false;
        }
        lineNode.forEach(line => {
            if (line.isEmpty()) {
                return;
            }
            if (line.lineContent != null || line.timeLineContent != null) {
                return true;
            }
        });
        return false;
    }

    changeLineNodeColor(color) {
        if (lineNode.length == 0) {
            return;
        }
        lineNode.forEach(line => {
            if (line.isEmpty()) {
                return;
            }
            if (line.lineContent != null) {
                line.lineContent.color = color;
                line.lineContent.strokeColor = color;
            } else if (line.timeLineContent != null) {
                line.timeLineContent.color = color;
            }
        });
    }


    getLineNodeColor() {
        if (lineNode.length == 0) {
            return -1;
        }
        lineNode.forEach(line => {
            if (line.isEmpty()) {
                return;
            }
            if (line.lineContent != null) {
                return line.lineContent.color;
            } else if (line.timeLineContent != null) {
                return line.timeLineContent.color;
            }
        });
        return -1;
    }

    changeLineNodeWidth(width) {
        if (lineNode.length == 0) {
            return;
        }
        lineNode.forEach(line => {
            if (line.isEmpty()) {
                return;
            }
            if (line.lineContent != null) {
                line.lineContent.lineWidth = width;
            } else if (line.timeLineContent != null) {
                line.timeLineContent.lineWidth = width;
            }
        });
    }

    getLineNodeWidth() {
        if (lineNode.length == 0) {
            return 1.5;
        }
        lineNode.forEach(line => {
            if (line.isEmpty()) {
                return;
            }
            if (line.lineContent != null) {
                return line.lineContent.lineWidth;
            } else if (line.timeLineContent != null) {
                return line.timeLineContent.lineWidth;
            }
        });
        return 1.5;
    }

    getTextColor() {
        if (node.textContent != null) {
            return node.textContent.textColor;
        } else if (node.generalizationContent != null) {
            return node.generalizationContent.textColor;
        } else {
            return Colors.black20;
        }
    }
}
export default StylePanelData