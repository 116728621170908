let PlayType =  {
    AnimationReview:0,
    SpeechMode:1,
    
    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.AnimationReview;
    }
}

export default PlayType