
if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}
import MindMapZipConstant from '../../../../viewmodel/core/tools/mapfile/MindMapZipConstant';
import Util from '../../../../utils/Util';
import MapSaveData from '../../../../viewmodel/core/tools/mapfile/MapSaveData';
import Config from '../../calcule/Config';
import MindContentData from '../../../../viewmodel/core/tools/mapfile/MindContentData';
import { md5 } from '../../../../common/netWork/base';
import LoadLocalXJMind from '../../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import UiUtil from '../../../../utils/UiUtil';
import MindmapDataRouter from '../../../../viewmodel/facade/MindmapDataRouter';

class NewXJMindMap {
    constructor(name, mindType, appPath, parameter) {

        this.parameter = parameter;
        this.appPath = appPath;
        this.originalName = name; 
        this.mindType = mindType;
        this.name = name;
        this.xjmindContentJson = "";
        this.isNewMindmap = false;
    }

    createLocalSheetMindMap(sheetName, jsonContent, id) {
        let that = this;
        return new Promise(resolve => {
            let localPath = LoadLocalXJMind.getLocalPath()
            let path = LoadLocalXJMind.getPath()
            let contentPath = that.getLocalContentPath()
            let filePath = that.getLocalFilesPath()
            let contentJSPath = that.getLocalContentJsonPath()
            LoadLocalXJMind.loadJson(contentJSPath).then((data) => {
                let json = data;
                let saveData = new MapSaveData().format(json)
                if (saveData.contentList == null || saveData.contentList.length == 0) {
                    resolve(null)
                    return;
                }
                let sheetData = new MindContentData();
                sheetData.name = sheetName;
                sheetData.id = id != null && id.length > 0 ? id : "" + saveData.contentList.length;
                sheetData.fileName = md5(sheetName + Util.getCurrentTime()) + ".json";
                sheetData.mindType = that.mindType;
                saveData.contentList.push(sheetData)
                let contentJson = saveData.getContentListJson()
                that.saveLocalContent(contentJSPath, "", contentJson)
                if (jsonContent != null) {
                    that.saveLocalContent(contentPath, sheetData.fileName, jsonContent)
                } else {
                    that.saveLocalContent(contentPath, sheetData.fileName, "")
                }
                

                resolve({path: path, 
                        localPath:localPath,
                        parameter: that.parameter, 
                        rootId: saveData.contentList[0].id,
                        id: sheetData.id,
                        index: saveData.contentList.length})
            })
        })
    }

    copyLocalSheetMindMap(id) {
        let that = this;
        return new Promise(resolve => {
            let localPath = LoadLocalXJMind.getLocalPath()
            let path = LoadLocalXJMind.getPath()
            let contentPath = that.getLocalContentPath()
            let filePath = that.getLocalFilesPath()
            let contentJSPath = that.getLocalContentJsonPath()
            MindmapDataRouter.postGetMindmap(
                { id: LoadLocalXJMind.getRootId(), sheetId: id, path: LoadLocalXJMind.getPath() },
                (res) => {
                    let jsonContent = res.content
                    let name = res.name + "_1";
                    let mindType = res.type
                    LoadLocalXJMind.loadJson(contentJSPath).then((data) => {
                        let json = data;
                        let saveData = new MapSaveData().format(json)
                        if (saveData.contentList == null || saveData.contentList.length == 0) {
                            resolve(null)
                            return;
                        }
                        let sheetData = new MindContentData();
                        sheetData.name = name;
                        sheetData.id = saveData.contentList.length;
                        sheetData.fileName = md5(name + Util.getCurrentTime()) + ".json";
                        sheetData.mindType = mindType;
                        saveData.contentList.push(sheetData)
                        let contentJson = saveData.getContentListJson()
                        that.saveLocalContent(contentJSPath, "", contentJson)
                        if (jsonContent != null) {
                            that.saveLocalContent(contentPath, sheetData.fileName, jsonContent)
                        } else {
                            that.saveLocalContent(contentPath, sheetData.fileName, "")
                        }
                        resolve({path: path, 
                                localPath:localPath,
                                rootId: saveData.contentList[0].id,
                                id: sheetData.id,
                                index: saveData.contentList.length})
                    })
                },
                (error) => {
                    resolve(null)
                }
            );
        })
    }

    removeLocalSheetMindMap(id) {
        let that = this;
        return new Promise(resolve => {
            let contentPath = that.getLocalContentPath()
            let contentJSPath = that.getLocalContentJsonPath()
            LoadLocalXJMind.loadJson(contentJSPath).then((data) => {
                let json = data;
                let saveData = new MapSaveData().format(json)
                if (saveData.contentList == null || saveData.contentList.length == 0) {
                    resolve(true)
                    return;
                }
                for (let index = 0; index < saveData.contentList.length; index++) {
                    if (saveData.contentList[index].id == id && index > 0) {
                        let fileName = saveData.contentList[index].fileName
                        that.removeLocalFile(contentPath + MindMapZipConstant.FileSeparator + fileName, "")
                        saveData.contentList.splice(index, 1)
                        break;
                    }
                }
                let contentJson = saveData.getContentListJson()
                that.saveLocalContent(contentJSPath, "", contentJson)

                resolve(true)
            })
        })
    }

    renameLocalSheetMindMap(id, name) {
        let that = this;
        return new Promise(resolve => {
            let contentPath = that.getLocalContentPath()
            let contentJSPath = that.getLocalContentJsonPath()
            LoadLocalXJMind.loadJson(contentJSPath).then((data) => {
                let json = data;
                let saveData = new MapSaveData().format(json)
                if (saveData.contentList == null || saveData.contentList.length == 0) {
                    resolve(true)
                    return;
                }
                for (let index = 0; index < saveData.contentList.length; index++) {
                    if (saveData.contentList[index].id == id) {
                        saveData.contentList[index].name = name;
                        break;
                    }
                }
                let contentJson = saveData.getContentListJson()
                that.saveLocalContent(contentJSPath, "", contentJson)

                resolve(true)
            })
        })
    }

    createLocalMindMap() {
        this.isNewMindmap = true;
        this.setName();
        let that = this;
        return new Promise(resolve => {
            let contentPath = that.getLocalContentPath()
            let filePath = that.getLocalFilesPath()
            that.createFolder(contentPath, this.name) //创建缓存路径
            that.createFolder(filePath, this.name) //创建缓存路径

            let sheetData = new MindContentData();
            sheetData.name = that.originalName;
            sheetData.fileName = md5(that.originalName) + ".json";
            sheetData.id = "0";
            sheetData.mindType = that.mindType;

            
            let saveData = new MapSaveData();
            saveData.version = Config.MindFormatVersion;
            saveData.contentList = [];
            saveData.contentList.push(sheetData);
            saveData.name = that.originalName;
            let contentJson = saveData.getContentListJson();
            let contentJsonPath = that.getLocalContentJsonPath();

            that.saveLocalContent(contentJsonPath, "", contentJson)
            that.saveLocalContent(contentPath, sheetData.fileName, "")
            that.open();
        })
    }

    setName() {
        if (this.name == null || this.name.length == 0) {
            this.name = "MindMap"
        }
        this.name = this.name + MindMapZipConstant.MindZIPLocalFilesPrefixName + Util.getCurrentTime() 
    }

    open() {
        ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(MindMapZipConstant.MindZIPNewPathe) + 
        "&localpath=" + encodeURIComponent(this.getLocalPath()) + 
        "&parameter=" + encodeURIComponent(this.parameter));
    }

    saveLocalContent(path, name, content) {
        var fs = window.require('fs');
        if (name == null || name.length == 0) {
            fs.writeFile(path, content, function (err) {
            });
        } else {
            fs.writeFile(path + MindMapZipConstant.FileSeparator + name, content, function (err) {
            });
        }
    }

    removeLocalFile(path, name) {
        var fs = window.require('fs');
        if (name == null || name.length == 0) {
            fs.remo
            fs.unlink(path, function (err) {
            });
        } else {
            fs.unlink(path + MindMapZipConstant.FileSeparator + name, function (err) {
            });
        }
    }

    getLocalPath() {
        if (!this.isNewMindmap) {
            let path = LoadLocalXJMind.getLocalPath();
            if (path != null && path.length > 0) {
                return path
            }
        }
        var path = this.appPath + MindMapZipConstant.MindZIPLocalFilesFolderName + MindMapZipConstant.FileSeparator + this.name
        let specialSymbolList = ["?", "\r", "\n"]
        for (let index = 0; index < specialSymbolList.length; index++) {
            const tag = specialSymbolList[index];
            if (path.indexOf(tag) > -1) {
                path = path.replace(tag, "")
            }
        }
        return path
    }

    getLocalContentPath() {
        return this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentFolderName
    }

    getLocalFilesPath() {
        return this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName
    }

    getLocalContentJsonPath() {
        return this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentPath
    }
    
    setTempFile() {
        
    }

    createFolder(dirpath, dirname) {
        var fs = window.require('fs');
        var path = window.require('path');
  
        if (typeof dirname === "undefined") {
          if (fs.existsSync(dirpath)) {
          } else {
            this.createFolder(dirpath, path.dirname(dirpath));
          }
        } else {
          if (dirname !== path.dirname(dirpath)) {
            this.createFolder(dirpath);
            return;
          }
          if (fs.existsSync(dirname)) {
            fs.mkdirSync(dirpath,{recursive:true})
          } else {
            this.createFolder(dirname, path.dirname(dirname));
            fs.mkdirSync(dirpath,{recursive:true});
          }
        }
    }
}

export default NewXJMindMap