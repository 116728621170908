import Config from '../../../core/core/calcule/Config';
import Colors from '../../../utils/Colors'
import UiUtil from '../../../utils/UiUtil';
import Util from '../../../utils/Util';
import Point from '../../../viewmodel/core/base/Point';
import CGRect from '../../../viewmodel/core/base/basedata/Rect';
import MindDisplayType from '../../../viewmodel/datatype/MindDisplayType';
import MindElementShapeType from '../../../viewmodel/datatype/MindElementShapeType';
import MindElementType from '../../../viewmodel/datatype/MindElementType';
import MindType from '../../../viewmodel/datatype/MindType';
import NodeLayoutType from '../../../viewmodel/datatype/NodeLayoutType';
import EditMindmapVM from '../../../viewmodel/mindelementdata/vm/EditMindmapVM';
import MindNodeBackgroundView from './MindNodeBackgroundView'
import MindNodeUpNumberView from './MindNodeUpNumberView';

class BaseElementView {
    constructor(viewCanvs, data, vuethis) {
        this.root = viewCanvs;
        this.context;
        this.contextPointX = -1;
        this.contextPointY = -1;
        this.setContentPointLastTime = -1;
        this.contextStart;
        this.contextEnd;
        this.iconAndImageContent; //大纲模式放置 图标图片
        this.mindTextSizeChangedDelegate = vuethis;
        this.mindElementData = data;
        this.referenceData = data.copy();
        this.isRefresh = true;
        this.isSelected = false;
        this.isHover = false
        this.isSetHitStyle = false;
        this.isClearText = false;
        this.backgroundGTag; //装背景形状的dom
        this.backgroundView;
        this.backgroundView2;
        this.setRect;   //选中的矩形框。
        this.addChildIcon; //添加子节点的div
        this.fillAddDom; //放在addChildIcon标签里面的。为了兼容safari。因为如果forginObjec里面没有东西那么这个forginObjec不会触发任何的绑定事件。
        this.addChildIconImg; //添加子节点的div里面的img标签
        this.addNodeViewMouesDown = false;
        // this.addChild1Icon; //添加子节点的div
        // this.fillAdd1Dom; //放在addChildIcon标签里面的。为了兼容safari。因为如果forginObjec里面没有东西那么这个forginObjec不会触发任何的绑定事件。
        // this.addChild1IconImg; //添加子节点的div里面的img标签

        // this.addChild2Icon; //添加子节点的div
        // this.fillAdd2Dom; //放在addChildIcon标签里面的。为了兼容safari。因为如果forginObjec里面没有东西那么这个forginObjec不会触发任何的绑定事件。
        // this.addChild2IconImg; //添加子节点的div里面的img标签

        // this.addChild3Icon; //添加子节点的div
        // this.fillAdd3Dom; //放在addChildIcon标签里面的。为了兼容safari。因为如果forginObjec里面没有东西那么这个forginObjec不会触发任何的绑定事件。
        // this.addChild3IconImg; //添加子节点的div里面的img标签

        this.mindNodeBackgroundView;    //new出来的背景实例
        this.isTemplatePage = false;
        this.isSharePage = false;
        this.clipPathBox;       //裁剪图像
        this.clipPathGraph;     //具体裁剪的图像
        this.mindBackgroundImage; //背景图片
        this.setPointByAnimationInterval = -1;
        this.UiUtil = new UiUtil();
        this.initView();
        this.setStyle();
    }

    initView() {
        this.context = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        this.addConnectLinePointView();
        this.context.setAttribute("class", "mindElementView")
        if (this.mindElementData.width <= 0) {
            this.context.style.width = 4 + 'px';
        } else {
            this.context.style.width = this.mindElementData.width + 'px';
        }
        if (this.mindElementData.height <= 0) {
            this.context.style.height = 4 + 'px';
        } else {
            this.context.style.height = this.mindElementData.height + 'px';
        }

        this.context.style.transform = "translate(" + this.mindElementData.x + 'px,' + this.mindElementData.y + 'px)'
        this.root.appendChild(this.context)
        if (!this.mindElementData.isStatisticsContent()) {  //如果是表格不渲染背景
            this.createBackgroundView();
        }
    }

    addConnectLinePointView() {
        if (this.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
            this.contextStart = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            this.context.appendChild(this.contextStart);
            this.contextEnd = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            this.context.appendChild(this.contextEnd);
        }
    }

    addSelectedView() {
        if (this.setRect == null) {
            this.setRect = document.createElementNS('http://www.w3.org/2000/svg', "path")
            if (this.clipPathBox != null && this.clipPathBox.parentNode != null) {
                this.context.insertBefore(this.setRect, this.clipPathBox)
            } else if (this.clipPathBox != null && this.clipPathBox.parentNode != null) {
                this.context.insertBefore(this.setRect, this.clipPathBox)
            } else {
                this.context.appendChild(this.setRect)
            }
            
        }
    }

    removeSelectedView() {
        if (this.setRect != null && this.setRect.parentNode != null) {
            this.setRect.parentNode.removeChild(this.setRect)
            this.setRect = null
        }
    }

    bundingContextMouse() {        //用于绑定鼠标放上去显示添加子节点图标的函数
    }

    bringToFront() {
        if (this.root == null || this.context == null || this.context.parentNode != this.root) {
            return;
        }
        this.root.appendChild(this.context)
    }

    setAnimation(animation, duration) {
    }

    removeView() {
        if (this.mindElementData.textContent != null) {
        }
        this.root.removeChild(this.context)
    }

    setStyle() {
        if (this.mindElementData == null) {
            return;
        }
        this.setBackgroundView();
    }

    setData(data) {
        this.mindElementData = data;
        if (this.referenceData != null) {
            let ignorePointEqual = data.equal(this.referenceData, true)
            if (ignorePointEqual) {
                if (data.x != this.referenceData.x || data.y != this.referenceData.y) {
                    this.referenceData.x = data.x
                    this.referenceData.y = data.y
                    this.setPoint(data.x, data.y)
                }
                this.isRefresh = false;
                return;
            } else {
                this.isRefresh = true;
            }
        } else {
            this.isRefresh = true; 
        }
        this.referenceData = data.copy();
    }

    createBackgroundView() {    //这里是创建背景

        this.clipPathBox = document.createElementNS('http://www.w3.org/2000/svg', "clipPath")
        this.context.appendChild(this.clipPathBox)
        this.clipPathGraph = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.clipPathBox.appendChild(this.clipPathGraph)
        this.clipPathBox.setAttribute('id', "backgrundClip" + this.mindElementData.id);
        this.createdBackgroundImage()

        this.backgroundGTag = document.createElementNS('http://www.w3.org/2000/svg', "g");
        this.context.appendChild(this.backgroundGTag)

        this.mindBackgroundImageClipPathGraph = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.mindBackgroundImageClipPathGraph.setAttribute("clip-path", "url(#backgrundClip" + this.mindElementData.id + ")");
        this.backgroundGTag.appendChild(this.mindBackgroundImageClipPathGraph)

        this.mindNodeBackgroundView = new MindNodeBackgroundView(
            this.mindElementData,
            this.backgroundGTag,
            this.clipPathGraph,
            this.mindBackgroundImage,
            this.mindBackgroundImageChild,
            this.mindBackgroundImageClipPathGraph,
            false,
            0,
            this.mindTextSizeChangedDelegate,
            this.context)
    }

    createdBackgroundImage() {
    }

    setBackgroundView() {   //这里是刷新背景
        if (this.backgroundGTag != null) {
            return;
        }
    }

    setSelected(value) {

    }

    setSelectedHoverLayer(value) {
        if (this.isSelected) {
            return
        }
        if (this.mindElementData == null) {
            return;
        }
    }

    refresh() {
        if (!this.isRefresh && !this.isSetHitStyle && !this.isClearText) {
            return;
        }
        this.isSetHitStyle = false;
        this.isClearText = false;
        if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
            this.setOutlineStyle()
        }
        this.setStyle();
    }

    refreshBackgroundLayout() {
        if (this.mindElementData == null || this.backgroundGTag == null || this.mindNodeBackgroundView == null) {
            return;
        }
        this.mindNodeBackgroundView.setData(this.mindElementData)
        this.mindNodeBackgroundView.onDraw()
    }

    setViewRect(width, height, x, y) {
        this.context.style.width = width + 'px';
        this.context.style.height = height + 'px';
        this.setPoint(x, y);
    }

    setPoint(x, y) {
        let angle = this.mindElementData.angle;
        if (angle == null) {
            angle = 0;
        }
        this.context.style.transform = "translate(" + x + 'px,' + y + 'px)' + ' rotate(' + (this.mindElementData.angle) + 'deg)'
        this.context.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"
        if (this.mindElementData.mindElementShape == MindElementShapeType.Circular_Right_Top) {
            this.backgroundGTag.style.transform = "translate(" + 0 + 'px,' + 0 + 'px)' + ' rotate(' + (this.mindElementData.angle) + 'deg)'
            this.backgroundGTag.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"

            this.clipPathGraph.style.transform = "translate(" + 0 + 'px,' + 0 + 'px)' + ' rotate(' + (this.mindElementData.angle) + 'deg)'
            this.clipPathGraph.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"

            this.context.style.transform = "translate(" + x + 'px,' + y + 'px)'
            this.context.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"
        } else {
            if (this.backgroundGTag != null && this.backgroundGTag.style != null) {
                this.backgroundGTag.style.transform = "translate(" + 0 + 'px,' + 0 + 'px)' + ' rotate(' + 0 + 'deg)'
                this.backgroundGTag.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"
            }
            
            if (this.clipPathGraph != null && this.clipPathGraph.style != null) {
                this.clipPathGraph.style.transform = "translate(" + 0 + 'px,' + 0 + 'px)' + ' rotate(' + 0 + 'deg)'
                this.clipPathGraph.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"
            }            

            this.context.style.transform = "translate(" + x + 'px,' + y + 'px)' + ' rotate(' + (this.mindElementData.angle) + 'deg)'
            this.context.style.transformOrigin = this.mindElementData.width/2 + "px " + this.mindElementData.height/2 + "px"
        }
        if (this.contextPointX != x && this.contextPointY != y) {
            this.setContentPointLastTime = Util.getCurrentTime()
        }
        this.contextPointX = x;
        this.contextPointY = y;
    }

    setPointByAnimation(point, toPoint, speed) {
        if (this.setPointByAnimationInterval > -1) {
            clearInterval(this.setPointByAnimationInterval);
        }
        if (speed <= 50) {
            this.setPoint(toPoint.x, toPoint.y)
            return;
        }
        let rate = 50
        let times = Math.ceil(speed / rate)

        let spaceX = (toPoint.x - point.x) / times
        let spaceY = (toPoint.y - point.y) / times
        let moveToX = point.x
        let moveToY = point.y
        let runTime = 0
        this.setPoint(moveToX, moveToY)
        let that = this
        this.setPointByAnimationInterval = setInterval(function() {
            moveToX = moveToX + spaceX
            moveToY = moveToY + spaceY
            that.setPoint(moveToX, moveToY)
            runTime += 1;
            if (runTime >= times) {
                clearInterval(that.setPointByAnimationInterval);
                that.setPoint(toPoint.x, toPoint.y)
            }
            
        }, rate);
    }

    bundingContextMouse() {        //用于绑定鼠标放上去显示添加子节点图标的函数
        if (this.isTemplatePage || this.isSharePage) {
            //return;
        }
        
        if (this.removeHideAddNodeIcon != null && this.removeHideAddNodeIcon > -1) {
            clearTimeout(this.removeHideAddNodeIcon)
            this.removeHideAddNodeIcon = -1
        }
        if (this.mouseoverShowIcon != null) {
            return
        }
        this.mouseoverShowIcon = (ev) => {
            if (this.mindTextSizeChangedDelegate == null) {
                return
            }
            this.mindTextSizeChangedDelegate.editmapScrollView.setHiddenButton(this.mindElementData);
            this.mindTextSizeChangedDelegate.editmapScrollView.setDataAngleButton(this.mindElementData);
            this.mindTextSizeChangedDelegate.editmapScrollView.setAddFlowChartView(this.mindElementData);
            if (!this.mindTextSizeChangedDelegate.isEditStatus()) {
                return
            }
            if (this.removeHideAddNodeIcon != null && this.removeHideAddNodeIcon > -1) {
                clearTimeout(this.removeHideAddNodeIcon)
                this.removeHideAddNodeIcon = -1
            }
            
            //表格不显示添加子节点按钮
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_VERTICAL_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_LEFT ||
                // this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BUBBLE || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_FREE_TREE ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE ||
                (this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE && this.mindElementData.type != MindElementType.EXPLAIN)) {
                if (!this.isFlowChart()) {
                    this.mindTextSizeChangedDelegate.hideAddNodeIcon(this.mindElementData.id);
                    this.initAddIcon()
                    this.setAddIcon()
                    this.setViewVisibility(this.addChildIcon, 'hidden');
                    this.setViewVisibility(this.addChildIconImg, 'visible');
                }
                

            } else {
                this.hideAddNodeIcon();
            }
            //显示隐藏子节点按钮
        }
        this.context.addEventListener('mouseover', this.mouseoverShowIcon)
        this.mouseoutHiddenIcon = (ev) => {
            if (Util.getCurrentTime() - this.setContentPointLastTime <= 1) {
                return
            }
            let hidden = this.checkHiddeViewMouseout(ev, this.addChildIconImg)            
            if (hidden) {
                clearTimeout(this.removeHideAddNodeIcon)
                this.hideAddNodeIcon();
                if (this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton != null) {
                    if (this.checkHiddeViewMouseout(ev, this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton.lineView)) {
                        this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton.movePoint(this.mindElementData, false);
                    } else {
                        this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton.movePoint(this.mindElementData);
                    }
                }
                this.setSelectedHoverLayer(false)
                return
            }
            
            clearTimeout(this.removeHideAddNodeIcon)
            this.removeHideAddNodeIcon = setTimeout(() => {
                this.hideAddNodeIcon();
                this.setAddIcon();
            }, 1000);
            //隐藏显示子节点按钮
            if (this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton != null) {
                if (this.checkHiddeViewMouseout(ev, this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton.lineView)) {
                    this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton.movePoint(this.mindElementData, false);
                } else {
                    this.mindTextSizeChangedDelegate.editmapScrollView.mindChildshowButton.movePoint(this.mindElementData);
                }  
            }       
            this.setSelectedHoverLayer(false)
        }
        this.context.addEventListener('mouseout', this.mouseoutHiddenIcon)        
    }

    computedBoardSize(mindElementDataWidth, mindElementDataHeight) {   //选中边框的大小,自己拆出来的
        let space = this.getSelectedSpace();
        let width;
        let height;
        let x = -space;
        let y = -space;
        width = mindElementDataWidth + space * 2;
        height = mindElementDataHeight + space * 2;

        return {
            x: x,
            y: y,
            width: width,
            height: height
        }
    }

    initAddIcon() {
        if (this.addChildIcon == null) {
            this.removeAddIcon()
            this.addChildIcon = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.addChildIconImg = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.fillAddDom = document.createElement("div");
            this.context.appendChild(this.addChildIcon);
            this.context.appendChild(this.addChildIconImg);
            this.addChildIcon.appendChild(this.fillAddDom);
            this.addNodeViewMouesDown = false;
            if (this.addChildIconImg != null) {
                this.addChildIconImg.addEventListener('mouseup', this.imgClick);
                this.addChildIconImg.addEventListener('dblclick', this.imgDblclick);    //主要是为了防止触发双击编辑框的内容。
                this.addChildIconImg.addEventListener('mousedown', this.addNodeDown);
            }            
            this.bundingContextMouse();
        }        
    }

    setViewVisibility(view, value = "visible") {
        if (view == null || view.style == null) {
            return;
        }
        view.style.visibility = value;
    }

    setAddIcon() {
        if (!this.isDisplayAddNodeIcon() || this.isFlowChart()) {
            return;
        }
        let borderObj = this.computedBoardSize(this.mindElementData.width, this.mindElementData.height)
        let width = borderObj.width
        let height = borderObj.height
        let space = this.getSelectedSpace();
        
        let newX = 0;   //大盒子的x左边
        let newY = 0;   //大盒子的y坐标
        let domWidth = 20;  //大盒子的默认宽度
        let domHeight = 20; //大盒子的默认高度
        let imgurl = '';    //
        let imgLeft = 0;    //图片距离左边
        let imgTop = 0;  //图片距离上边
        let text = '';
        let fontSize = '19';   //字体大小
        let iconHeight = 20;    //图片的高度
        let iconWidth = 20;     //图片的宽度
        let bouttonAndMindSpace = this.UiUtil.dip2px(5);// 节点与按钮间距
        this.addNodeViewMouesDown = false;
        
        //左
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT || 
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            newY = 0

            //判断是否有隐藏子节点的数字图标。
            if (this.mindElementData.hiddenNumber == 0) {  //没有隐藏子节点的数字图标
                domWidth = domWidth
                newX = - domWidth
                imgLeft = - iconWidth - bouttonAndMindSpace
            } else { //有隐藏子节点的数字图标
                domWidth = domWidth * 2;
                newX = - domWidth
                imgLeft = - iconWidth - this.mindNodeUpNumberViewWidth - bouttonAndMindSpace
                if (this.mindElementData.mindElementShape == MindElementShapeType.Underline ||
                    this.mindElementData.mindElementShape == MindElementShapeType.Two_Underline) {
                    imgLeft += 4
                }
            }
            if (this.mindElementData.mindElementShape == MindElementShapeType.Underline ||
                this.mindElementData.mindElementShape == MindElementShapeType.Two_Underline) {
                imgTop = height - iconHeight / 2 - space * 2
            } else {
                imgTop = height / 2 - iconHeight / 2 - space
            }
            
            domHeight = height
            text = '&#xe606;'
            this.addChildIconStyle(this.addChildIcon, this.addChildIconImg, domWidth, domHeight, newX, newY, imgLeft, imgTop, text, fontSize, iconWidth, iconHeight)
        } else if (
            this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) { //右

            //判断是否有隐藏子节点的数字图标。
            if (this.mindElementData.hiddenNumber == 0) { //没有隐藏子节点的数字图标
                domWidth = domWidth;
                newX = width - space * 2;
                imgLeft = width - space * 2 + bouttonAndMindSpace;
            } else { //有隐藏子节点的数字图标
                domWidth = domWidth * 2;
                newX = width - space * 2;
                imgLeft = width - space * 2 + bouttonAndMindSpace ;
                if (this.mindElementData.mindElementShape == MindElementShapeType.Underline ||
                    this.mindElementData.mindElementShape == MindElementShapeType.Two_Underline) {
                    imgLeft -= 4
                }
            }
            
            newY = 0            
            if (this.mindElementData.mindElementShape == MindElementShapeType.Underline ||
                this.mindElementData.mindElementShape == MindElementShapeType.Two_Underline) {
                imgTop = height - iconHeight / 2 - space * 2
            } else {
                imgTop = height / 2 - iconHeight / 2 - space
            }
            domHeight = height
            text = '&#xe606;'
            this.addChildIconStyle(this.addChildIcon, this.addChildIconImg, domWidth, domHeight, newX, newY, imgLeft, imgTop, text, fontSize, iconWidth, iconHeight)
        } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) { //下
            domWidth = width - space * 2;
            domHeight = 24;
            iconHeight = 24;
            iconWidth = 24;
            newX = 0
            newY = height - space * 2
            imgLeft = (width - space * 2 - 24) / 2
            imgTop = height - space * 2 + bouttonAndMindSpace
            text = '&#xe606;'
            this.addChildIconStyle(this.addChildIcon, this.addChildIconImg, domWidth, domHeight, newX, newY, imgLeft, imgTop, text, fontSize, iconWidth, iconHeight)
        } else if(this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {  //上布局

            //判断是否有隐藏子节点的数字图标。
            if (this.mindElementData.hiddenNumber == 0) {  //没有隐藏子节点的数字图标
                imgTop =   - bouttonAndMindSpace - iconHeight;
            } else { //有隐藏子节点的数字图标
                imgTop =   - bouttonAndMindSpace - iconHeight - this.mindNodeUpNumberViewWidth;
            }
            domWidth = width - space * 2;
            domHeight = 24;
            iconHeight = 24;
            iconWidth = 24;
            newX = 0
            newY = height - space * 2
            imgLeft = (width - space * 2 - 24) / 2
            text = '&#xe606;'
            this.addChildIconStyle(this.addChildIcon, this.addChildIconImg, domWidth, domHeight, newX, newY, imgLeft, imgTop, text, fontSize, iconWidth, iconHeight)

        } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BUBBLE || 
            this.mindElementData.layout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE || 
            this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE) { //气泡图
            newX = width - space * 2
            newY = 0
            imgLeft = width - space * 2 + bouttonAndMindSpace
            imgTop = height / 2 - iconHeight / 2 - space
            domHeight = height
            text = '&#xe606;'
            this.addChildIconStyle(this.addChildIcon, this.addChildIconImg, domWidth, domHeight, newX, newY, imgLeft, imgTop, text, fontSize, iconWidth, iconHeight)
        };
    }

    hideAddNodeIcon(animation = true, forceRemove = false) {       
        if (this.removeHideAddNodeIcon != null && this.removeHideAddNodeIcon > -1) {
            clearTimeout(this.removeHideAddNodeIcon)
            this.removeHideAddNodeIcon = -1
        }
        this.hideNodeIcon(this.addChildIcon, this.addChildIconImg, this.isFlowChart() ? false : animation, forceRemove)
        this.addNodeViewMouesDown = false;
    }

    checkAddChildIconPoint(preX, preY, x, y) {
        if (preX == -1 || preY == -1 || 
            this.addChildIcon == null ||
            this.addChildIconImg == null || this.addChildIconImg.style.visibility != 'visible' ||
            (preX == x && preY == y)) {
            return;
        }
        
        let point = this.UiUtil.getViewPoint(this.addChildIcon);
        let imgPoint = this.UiUtil.getViewPoint(this.addChildIconImg);
        let addIconX = point.x - (x - preX)
        let addIconY = point.y - (y - preY)
        let data = EditMindmapVM.getMindElementDataByRect(new CGRect(this.mindElementData.x + addIconX, this.mindElementData.y + addIconY, 22, 22))
        if (data != null && !data.isEmpty() && !data.isHidden && 
            data.id != this.mindElementData.id && 
            (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.EXPLAIN)) {
            this.hideAddNodeIcon()
            return
        }

        this.addChildIcon.setAttribute('x', point.x - (x - preX));
        this.addChildIcon.setAttribute('y', point.y - (y - preY));
        this.addChildIconImg.setAttribute('x', imgPoint.x - (x - preX));
        this.addChildIconImg.setAttribute('y', imgPoint.y - (y - preY));
    }

    hideNodeIcon(icon, iconImg, animation = true, forceRemove = false) {
        if (icon == null ||
            icon.style == null ||
            (this.isSelected && !forceRemove)) {
            return
        }
        icon.style.visibility = 'hidden';
        if (animation) {
            var that = this
            let elem = iconImg;
            this.UiUtil.scaleDownAnimation(iconImg, function() {
                elem.style.visibility = 'hidden';
                elem.style.transform = "scale(1.0)"
                that.removeAddIcon()
            })
        } else {
            iconImg.style.visibility = 'hidden';
            iconImg.style.transform = "scale(1.0)"
            this.removeAddIcon()
        }
    }

    setSelectedBorder(mindElementDataWidth, mindElementDataHeight, paintingAddX = 0) {

    }

    removeAddIcon() {
        if (this.addChildIcon != null && this.addChildIcon.parentNode != null) {
            this.addChildIcon.parentNode.removeChild(this.addChildIcon)
            this.addChildIcon = null
        }
        if (this.addChildIconImg != null && this.addChildIconImg.parentNode != null) {
            this.addChildIconImg.addEventListener('mousedown', null);
            this.addChildIconImg.addEventListener('dblclick', null);  
            this.addChildIconImg.parentNode.removeChild(this.addChildIconImg)
            this.addChildIconImg = null
        }
        this.addNodeViewMouesDown = false;
    }

    addChildIconStyle(addChildIcon, addChildIconImg, domWidth, domHeight, newX, newY, imgLeft, imgTop, text, fontSize, iconWidth, iconHeight) {
        if (!this.isDisplayAddNodeIcon() || addChildIcon == null || addChildIconImg == null) {
            return;
        }
        addChildIcon.setAttribute('width', domWidth);  
        addChildIcon.setAttribute('height', domHeight);    
        addChildIcon.style.WebkitUserSelect = 'none'
        addChildIcon.style.userSelect = 'none'
        addChildIcon.setAttribute('x', newX);
        addChildIcon.setAttribute('y', newY);
        addChildIconImg.classList.add("iconfont-add")
        addChildIconImg.innerHTML = text;
        addChildIconImg.style.fontSize = fontSize + 'px';
        addChildIconImg.style.fontWeight = 700;
        addChildIconImg.style.textAlign = 'center'
        addChildIconImg.style.lineHeight = iconHeight + 'px';
        addChildIconImg.style.borderRadius = '50%';
        addChildIconImg.setAttribute('x', imgLeft);
        addChildIconImg.setAttribute('y', imgTop);
        addChildIconImg.setAttribute('width', iconWidth);
        addChildIconImg.setAttribute('height', iconHeight);
        addChildIconImg.style.WebkitUserSelect = 'none'
        addChildIconImg.style.userSelect = 'none'
        addChildIconImg.style.cursor = 'pointer';
        this.fillAddDom.style.width = '100%';   //设置填充区域，要填满foreginObjec标签，因为只有填充的区域才能触发事件。
        this.fillAddDom.style.height = '100%';
        let canvasColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor;
        let isDarkColor;
        let color = "";
        if (canvasColor == '' || canvasColor == null) {    //如果颜色值为空直接用灰色。
            isDarkColor = true;
        } else {
            isDarkColor = Colors.isLightColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            addChildIconImg.style.color = Colors.getUiColor(this.getTextContent().textColor)            
            addChildIconImg.style.backgroundColor = Colors.getUiColor(Colors.clear)
        } else {
            if (Colors.isClear(this.mindElementData.borderColor) || this.mindElementData.borderWidth == 0) {
                //如果没有设置线条颜色，隐藏图标的颜色就取画布的相反颜色。
                if (isDarkColor) {
                    color = Colors.getUiColor(Colors.black80)
                } else {
                    color = Colors.getUiColor(Colors.white)
                }
            } else {
                color = Colors.getUiColor(this.mindElementData.borderColor)
            }
            addChildIconImg.style.color = color
            addChildIconImg.style.backgroundColor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)
        }
        
    } 

    checkHiddeViewMouseout(ev, targetView) {
        if (this.setRect != null && this.setRect.parentNode != null && 
            (ev.target == this.setRect || ev.relatedTarget == this.setRect) && 
            targetView != null && 
            targetView.parentNode != null) {

            let clientPoint = new Point(ev.clientX, ev.clientY)
            let addImgRect = targetView.getBoundingClientRect()
            let imgCenterPoint = new Point(addImgRect.width / 2 + addImgRect.x, addImgRect.height / 2 + addImgRect.y)
            let distance = Util.getPointSpacing(imgCenterPoint, clientPoint)
            if (distance > addImgRect.width * 3) {
                return true
            }
        }
        return false
    }

    isHitViewMouseout(ev, targetView) {
        if (ev != null && ev.clientX != null &&
            targetView != null && 
            targetView.parentNode != null) {

            let clientPoint = new Point(ev.clientX, ev.clientY)
            let rect = targetView.getBoundingClientRect()
            let space = 4
            if (Util.containsInRectForPoint(new CGRect(rect.x - space, rect.y - space, 
                                            rect.width + space * 2, rect.heigth + space * 2) , 
                                            clientPoint)) {
                return true
            }
        }
        return false
    }

    isFlowChart() {
        if (this.mindTextSizeChangedDelegate == null) {
            return false;
        }
        if (this.mindTextSizeChangedDelegate.getMindType() == MindType.FLOW_CHART) {
            return true;
        }
        return false;
    }

    isDisplayAddNodeIcon() {
        return this.addChildIconImg != null && this.addChildIconImg.parentNode != null && this.addChildIconImg.style.visibility == 'visible'
    }

    getSelectedSpace() {
        if (this.mindElementData == null) {
            return 4;
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return 1;
        }
        if (this.mindElementData.backgroundColor == Colors.clear &&
            (this.mindElementData.borderWidth <= 0 || this.mindElementData.borderColor == Colors.clear)) {
            return 0;
        }
        return 4;
    }
}

export default BaseElementView