<template>
  <div class="template">
    <div class="bg-color" v-if="showBg"></div>
    <div class="complie-box">
      <div class="complie-box-model">
        <Complie-AddModel-Remark
          :class="modelAccout ? 'modelAccoutShow' : 'modelAccoutHide'"
        />
        <!-- 个性化模态框 -->
        <!-- <Complie-Left-Menu-Share
          :class="
            hideModelAccout
              ? 'modelLeftMenuShareShow'
              : 'modelLeftMenuShareHide'
          "
        /> -->
        <!-- <ComplieLeftMenuShareTIps
          :class="
            hideDgModelAccout ? 'modeldgMenuShareShow' : 'modeldgMenuShareHide'
          "
        /> -->
        <LookUserMessage v-if="userDataModel" />
        <LoginComponents v-if="isShowLoginModel" />
      </div>
      <div class="comlie-content">
        <Complie-Header :shareUserData="shareData" />
      </div>
      <div class="comlie-center-content">
        <a-layout id="components-layout-demo-side">
          <a-layout>
            <a-layout-content >
              <div class="comlie-left-content">
                <!-- 密码输入框 -->
                <div v-show="isShowSharePwd" class="share-password">
                  <div class="share-title">
                    <span>提示</span>
                  </div>
                  <div class="share-clink-hide" @click="shareModelHide">
                    <img
                      src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/click_hide.png"
                      alt=""
                    />
                  </div>
                  <div class="share-form">
                    <div class="share-pwd-title">请输入密码</div>
                    <a-form
                      :form="form"
                      :label-col="{ span: 5 }"
                      :wrapper-col="{ span: 24 }"
                      @submit="handleSubmit"
                    >
                      <a-form-item>
                        <a-input
                          v-decorator="[
                            'password',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入密码',
                                },
                              ],
                            },
                          ]"
                          :maxLength="4"
                        />
                      </a-form-item>
                      <a-form-item>
                        <a-button type="primary" html-type="submit">
                          确定
                        </a-button>
                      </a-form-item>
                    </a-form>
                  </div>
                </div>
                <!-- 打开链接失效 -->
                <div class="open-link" v-if="isShowShareError">
                  <div class="open-link-logo">
                    <img
                      src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/open_link.png"
                      alt=""
                    />
                  </div>
                  <div class="open-link-text">此导图链接已失效！</div>
                </div>
                <transition name="slide-fade" mode="out-in">
                  <ComplieLeftContent
                    v-show="!hideDgModelAccout"
                    :shareMindData="shareData"
                  />
                </transition>
                <transition name="slide-fade" mode="out-in">
                  <ComplieLeftOutline v-show="hideDgModelAccout" />
                </transition>
                <!-- 领取会员入口 -->
                <!-- <div class="go-activtiy" @click="activityModelClose">
                  <img
                    src="../../../static/img/activity/receive_activity.png"
                    alt=""
                  />
                </div> -->
                <!-- 会员模态 -->
                <!-- activtiy-model activityModelAccout activityModelClose-->
                <div class="activtiy-model" v-if="activityModelAccout">
                  <div class="activtiy-header-banner">
                    <img
                      src="../../../static/img/activity/activity_login.png"
                      alt=""
                    />
                    <div class="activtiy-header-titel">
                      <span>—海量模板随心用—</span>
                    </div>
                  </div>
                  <div class="activity-model-close" @click="activityModelClose">
                    <img
                      src="../../../static/img/activity/close_model.png"
                      alt=""
                    />
                  </div>
                  <div class="activtiy-form">
                    <a-form
                      :form="formActivity"
                      :label-col="{ span: 5 }"
                      :wrapper-col="{ span: 24 }"
                      @submit="activitySubmit"
                    >
                      <a-form-item class="phone-input">
                        <CountryCodePicker v-model="countryCode" />
                        <a-input
                          v-decorator="[
                            'phoneNumber',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入正确的手机号',
                                  pattern: phoneNumberRegExp,
                                },
                              ],
                            },
                          ]"
                          allow-clear
                          :maxLength="11"
                          placeholder="请输入手机号"
                          @change="handlePhoneNumberChange"
                        >
                        </a-input>
                      </a-form-item>
                      <div class="code-input-content">
                        <a-form-item class="get-vftion-input">
                          <a-input
                            v-decorator="[
                              'code',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入验证码',
                                  },
                                ],
                              },
                            ]"
                            allow-clear
                            :maxLength="6"
                            placeholder="请输入验证码"
                          />
                        </a-form-item>
                        <a-form-item class="get-vftion-button">
                          <a-button
                            class="dra-btn scode"
                            disabled
                            v-if="showTimeData"
                          >
                            <span>重新获取</span>
                            {{ timeData }}
                            <span>s</span>
                          </a-button>
                          <a-button class="dra-btn" v-else @click="activityCode"
                            >获取验证码</a-button
                          >
                        </a-form-item>
                      </div>
                      <a-form-item class="submit-activtiy">
                        <a-button type="primary" html-type="submit">
                          立即领取
                        </a-button>
                      </a-form-item>
                    </a-form>
                  </div>
                </div>
                <!-- 音频 -->
                <div class="complie-left-audio" v-if="httpAudio != ''">
                  <ComplieAudioLeft :httpAudioData="httpAudio" />
                </div>
              </div>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </div>
    </div>
    <!-- 个性化加载封面 -->
    <ComplieShowImg />
  </div>
</template>
<script>
import {
  postTemplateGet,
  postTemplateUseTemplate,
  postMindmapView,
} from "../../common/netWork/mind_map_api";
import {
  postBaseWxSignature,
  postUserCaptcha,
  postUserLogin,
  postUserMe,
} from "../../common/netWork/base_api";

import share from "../../assets/css/Share/share.less";
import CountryCodePicker from "../../components/loginComponents/CountryCodePicker.vue";


import ComplieHeader from "../../components/complieComponents/ComplieHeader";
import ComplieLeftContent from "../../components/complieComponents/ComplieLeftContent";
import ComplieLeftOutline from "../../components/complieComponents/ComplieLeftOutline";
import ComplieLeftMenuShare from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShare";
import ComplieLeftMenuShareTIps from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShareTIps";
import LookUserMessage from "../../components/userMessage/LookUserMessage";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import { doLogin, doLogout, setJwt } from "../../common/netWork/base";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import ComplieShowImg from "../../components/complieComponents/ComplieShowImg/ComplieShowImg";
import ComplieAddModelRemark from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelRemark";
import ComplieAudioLeft from "../../components/complieComponents/ComplieHeader/ComplieAudioLeft";
import LoginComponents from "../../components/loginComponents/LoginComponents";
import regExp from "../../components/loginComponents/regular.js";
const { phoneNumberRegExp } = regExp;
export default {
  data() {
    return {
      shareId: "",
      shareToken:'',
      shareSheeId:'',
      shareData: {},
      isShowShareMind: false,
      // hideModelAccout: false,
      isShowSharePwd: false,
      isShowShareError: false,
      hideDgModelAccout: false,
      mindMapId: null,
      form: this.$form.createForm(this),
      showBg: false,
      activityModelAccout: false,
      formActivity: this.$form.createForm(this),
      timeData: 0,
      showTimeData: false,
      countryCode: 86,
      areaCode: 123456,
      userDataModel: false,
      modelAccout: false,
      httpAudio: "", //音频
      isShowLoginModel: false, //登陆
      phoneNumberRegExp
    };
  },
  components: {
    ComplieHeader,
    ComplieLeftContent,
    ComplieLeftMenuShare,
    ComplieLeftMenuShareTIps,
    CountryCodePicker,
    LookUserMessage,
    ComplieShowImg,
    ComplieAddModelRemark,
    ComplieAudioLeft,
    LoginComponents,
    ComplieLeftOutline,
  },
  created() {
    this.shareId = this.$router.history.current.params.id;
    this.shareToken = this.$router.history.current.query.token;
    this.shareSheeId = this.$router.history.current.query.sheetId;
    if (this.$store.state.mp3Blob == "del") {
      this.httpAudio = "";
    } else {
      if (
        this.$store.state.canvanDatas == null ||
        this.$store.state.canvanDatas.audio == undefined
      ) {
        return;
      }
      this.httpAudio = this.$store.state.canvanDatas.audio;
    }
  },
  mounted() {
    var that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState == "visible") {
        let userData = localStorage.getItem("meMessage");
        if (userData != null) {
          that.postUserMe();
          return;
        }
      }
    });
    if(this.$route.query.sheetId == null || this.$route.query.sheetId == ""){
      this.postNoPwdMindmapView();
    }else{
      this.postNoPwdMindSheetmapView();
    }
    this.getTitle();
  },
  methods: {
    postUserMe() {
      postUserMe(
        {},
        (res) => {
          doLogin(res);
          localStorage.setItem("meMessage", JSON.stringify(res));
        },
        (e) => {
        }
      );
    },
    onSuccess(res) {
      this.shareData = res;
      this.shareData.userAvatar = httpImageToPrefix(res.userAvatar);
      this.isShowSharePwd = false;
      if (res) {
        let rootId = res.rootId;
        if (rootId) {
          sessionStorage.setItem('mindRootId',rootId)
        }
      }
      postBaseWxSignature(
        {
          url: location.href,
        },
        (rp) => {
          if (window.setupWxShare){
            window.setupWxShare(rp, {
              title: res.name.replace("\n", ""),
              desc: "寻简思维导图",
              link: location.href,
              imgUrl: this.shareData.userAvatar,
            });
          }
        }
      );
    },
    postNoPwdMindmapView() {
      let password = localStorage.getItem("share-password-" + this.shareId);
      if (!password) {
        password = "";
      }

      postMindmapView(
        { link: this.shareId,sheetId:this.shareSheeId, password: password ,connectMapToken:this.shareToken},
        this.onSuccess,
        (error) => {
          if (error.code == 4) {
            localStorage.removeItem("jwt");
            this.isShowSharePwd = false;
            this.isShowShareError = false;
            return;
          }
          if (error.code == 19) {
            this.isShowSharePwd = true;
            this.isShowShareError = false;
            return;
          }
          if (error.code == 90) {
            this.isShowSharePwd = false;
            this.isShowShareError = true;
            return;
          }
          this.$message.error(error.desc);
        }
      );
    },
    //切换分页获取导图数据
    postNoPwdMindSheetmapView() {
      let password = localStorage.getItem("share-password-" + this.shareId);
      let sheetShareId = this.$route.query.sheetId;
      if (!password) {
        password = "";
      }

      postMindmapView(
        { link: this.shareId,sheetId:sheetShareId, password: password ,connectMapToken:this.shareToken},
        this.onSuccess,
        (error) => {
          if (error.code == 4) {
            localStorage.removeItem("jwt");
            this.isShowSharePwd = false;
            this.isShowShareError = false;
            return;
          }
          if (error.code == 19) {
            this.isShowSharePwd = true;
            this.isShowShareError = false;
            return;
          }
          if (error.code == 90) {
            this.isShowSharePwd = false;
            this.isShowShareError = true;
            return;
          }
          this.$message.error(error.desc);
        }
      );
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, pwd) => {
        if (!err) {
          postMindmapView(
            { link: this.shareId, password: pwd.password,connectMapToken:this.shareToken },
            (res) => {
              localStorage.setItem(
                "share-password-" + this.shareId,
                pwd.password
              );
              this.onSuccess(res);
            },
            (error) => {
              if (error.code == 90) {
                this.isShowShareError = true;
                return;
              }
              this.$message.error(error.desc);
            }
          );
        }
      });
    },
    shareModelHide() {
      this.isShowSharePwd = false;
    },
    activityModelClose() {
      this.activityModelAccout = !this.activityModelAccout;
      this.showBg = !this.showBg;
    },
    activityCode(e) {
      e.preventDefault();
      this.formActivity.validateFields(
        ["phoneNumber"],
        { first: true },
        (errors, values) => {
          if (errors) {
            return;
          } else {
            this.showTimeData = true;
            this.timeData = 60;
            let countDown = setInterval(() => {
              if (this.timeData < 1) {
                this.timeData = 0;
                this.showTimeData = false;
                clearInterval(countDown);
              } else {
                this.timeData--;
              }
            }, 1000);
            //验证码请求
            let areaAndPhoneNumber = {
              areaCode: JSON.stringify(this.countryCode),
              phoneNumber: values.phoneNumber,
            };
            postUserCaptcha(areaAndPhoneNumber, (res) => {
              this.areaCode = res;
            });
          }
        }
      );
    },
    activitySubmit(e) {
      e.preventDefault();
      this.formActivity.validateFields(
        ["phoneNumber", "code"],
        { first: true },
        (errors, values) => {
          if (!errors) {
            let areaAndPhoneNumber = {
              areaCode: JSON.stringify(this.countryCode),
              phoneNumber: values.phoneNumber,
              captcha: values.code,
            };
            postUserLogin(
              areaAndPhoneNumber,
              (res) => {
                doLogin(res);
                localStorage.setItem("meMessage", JSON.stringify(res));
                this.$message.success(
                  getString(strings.Message_Tips_Login_Success)
                );

                this.showBg = false;
                this.activityModelAccout = false;
                let newWindow = window.open();
                const routerData = this.$router.resolve({
                  path: "/Home/MyModal",
                });
                if (
                  window.navigator.userAgent.match(/iphone|ipod|iOS|ipad/gi)
                ) {
                  window.location.href =
                    "https://apps.apple.com/cn/app/id1476852025";
                } else if (window.navigator.userAgent.match(/Android/gi)) {
                  newWindow.location =
                    "https://r.mindyushu.com/dl/release/android/mindmap_1.8.1.apk";
                } else if (window.navigator.userAgent.match(/Windows/gi)) {
                  newWindow.location = routerData.href;
                } else {
                  newWindow.location = routerData.href;
                }
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    handlePhoneNumberChange(value) {
      if (value.target.value.length >= 1) {
      }
    },
    getTitle() {
      setTimeout(() => {
        document.title = EditMindmapVM.getTitle() + " - 寻简思维导图";
      }, 100);
    },
  },
  watch: {
    $route: {
        handler() {
          // this.postSheetCrispsView();
          if(this.$route.query.sheetId == null || this.$route.query.sheetId == ""){
            this.postNoPwdMindmapView();
          }else{
          this.postNoPwdMindSheetmapView();
          }
        },
        deep: true,
    },
    "$store.state.hideModel"(newHideModel, olHideModel) {
      this.hideModelAccout = newHideModel;
    },
    "$store.state.showDaGangModels"(newshowDaGangModels, olshowDaGangModels) {
      this.hideDgModelAccout = newshowDaGangModels;
      // this.showBg = newshowDaGangModels;
    },
    "$store.state.userModelData.accout"(newShowUserModelData) {
      this.userDataModel = newShowUserModelData;
      this.showBg = newShowUserModelData;
    },
    "$store.state.showAddRemark"(newAddRemarkAccout, olAddRemarkAccout) {
      this.modelAccout = newAddRemarkAccout;
    },
    "$store.state.canvanDatas.audio"(newHttpAudioData) {
      if (newHttpAudioData != "") {
        this.httpAudio = newHttpAudioData;
      }
    },
    "$store.state.showLogin"(newLoginShowLogin, olLoginShowLogin) {
      this.isShowLoginModel = newLoginShowLogin;
      this.showBg = newLoginShowLogin;
      this.$store.state.useShortcut = !newLoginShowLogin;//是否可以使用快捷键

    },
    shareData(newShareData, olShareData) {
      if (newShareData != null || newShareData != undefined) {
        this.isShowShareMind = true;
      }
    },
  },
};
</script>

<style scoped>
/**背景 */
.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.modeldgMenuShareShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modeldgMenuShareHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.template {
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  user-select: none;
}
.comlie-content-menu {
  width: 100%;
  height: 100%;
  height: 68px;
  position: relative;
}
.comlie-content {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  z-index: 100;
  width: 100%;
  position: absolute;
}

.comlie-center-content .tempalte_model {
  z-index: 9999;
  position: absolute;
  right: 0px;
  width: 132px;
  height: 46px;
  background: #fd492b;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 36px;
  top: 100px;
}
.comlie-center-content .tempalte_model:hover {
  background: #ff765e;
  transition: 0.5s;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
}
.complie-box-model {
  transition: 0.5s;
}
.cropperImgAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.cropperImgAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLinkAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLinkAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLeftMenuShareShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLeftMenuShareHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
</style>
<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
.share-password {
  width: 340px;
  height: 290px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background: #ffffff;
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 50%);
  z-index: 9999;
  .share-clink-hide {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .share-title {
    border-bottom: 1px solid #e8e8e8;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 36px;
      height: 25px;
      font-size: 18px;
      /*font-weight: bold;*/
      line-height: 25px;
      color: #333333;
      opacity: 1;
    }
    button {
      width: 100%;
    }
  }
  .share-form {
    width: 198px;
    height: 170px;
    margin: auto;
    margin-top: 16px;
    .share-pwd-title {
      width: 100%;
      text-align: center;
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #333333;
      opacity: 1;
    }
    .ant-form {
      margin-top: 20px;
    }
    .ant-form-item-children {
      input {
        height: 42px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
      }
    }
    .ant-btn-primary {
      width: 100%;
      height: 42px;
      border-radius: 21px;
      font-size: 16px;
    }
  }
}
.open-link {
  width: 340px;
  height: 290px;
  position: absolute;
  right: 50%;
  top: 25%;
  transform: translate(50%, 50%);
  z-index: 9999;
  .open-link-logo {
    display: flex;
    justify-content: center;
  }
  .open-link-text {
    margin-top: 60px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #333333;
    display: flex;
    justify-content: center;
    font-family: Noto Sans SC;
  }
}
.complie-box {
  .comlie-center-content {
    .comlie-left-content {
      .go-activtiy {
        cursor: pointer;
        position: absolute;
        right: 42px;
        top: calc(67px + 80px + 20px);
        animation: mymove 5s infinite;
        -webkit-animation: mymove 5s infinite; /*Safari and Chrome*/
        animation-direction: alternate; /*轮流反向播放动画。*/
        animation-timing-function: ease-in-out; /*动画的速度曲线*/
        /* Safari 和 Chrome */
        -webkit-animation: mymove 5s infinite;
        -webkit-animation-direction: alternate; /*轮流反向播放动画。*/
        -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
        img {
          width: 90px;
          height: 64px;
        }
      }
      @keyframes mymove {
        0% {
          transform: scale(1); /*开始为原始大小*/
        }
        25% {
          transform: scale(1.1); /*放大1.1倍*/
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
      }
      @-webkit-keyframes mymove /*Safari and Chrome*/ {
        0% {
          transform: scale(1); /*开始为原始大小*/
        }
        25% {
          transform: scale(1.1); /*放大1.1倍*/
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(1.1);
        }
      }
      .activtiy-model {
        width: 524px;
        height: 501px;
        border-radius: 20px;
        background: #ffffff;
        opacity: 1;
        position: absolute;
        z-index: 1000;
        right: 50%;
        top: 25%;
        transform: translate(50%, 0%);
        display: flex;
        justify-content: center;
        .activity-model-close {
          position: absolute;
          top: 30px;
          right: 30px;
          cursor: pointer;
        }
        .activtiy-header-banner {
          position: absolute;
          top: -112px;
          .activtiy-header-titel {
            position: absolute;
            bottom: -10px;
            span {
              font-size: 32px;
              font-family: Noto Sans SC;
              /*font-weight: bold;*/
              color: #ff765e;
              opacity: 1;
            }
          }
        }
        .activtiy-form {
          position: absolute;
          bottom: 40px;
          margin: 40px;
        }
      }
      .complie-left-audio {
        z-index: 10;
        position: absolute;
        top: 211px;
        left: 0px;
        height: 46px;
        opacity: 1;
        border-radius: 61px;
        transition: 0.5s;
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .complie-left-audio input {
        border: none;
      }
    }
  }
}
@media screen and(min-width:250px) and(max-width:1400px) {
  .complie-box {
    .comlie-center-content {
      .comlie-left-content {
        .go-activtiy {
          cursor: pointer;
          position: absolute;
          right: 9px;
          // bottom: calc(67px + 80px + 20px);
          top: 80%;
          animation: mymove 5s infinite;
          -webkit-animation: mymove 5s infinite; /*Safari and Chrome*/
          animation-direction: alternate; /*轮流反向播放动画。*/
          animation-timing-function: ease-in-out; /*动画的速度曲线*/
          /* Safari 和 Chrome */
          -webkit-animation: mymove 5s infinite;
          -webkit-animation-direction: alternate; /*轮流反向播放动画。*/
          -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
          img {
            width: 76px;
            height: 55px;
          }
        }
        @keyframes mymove {
          0% {
            transform: scale(1); /*开始为原始大小*/
          }
          25% {
            transform: scale(1.1); /*放大1.1倍*/
          }
          50% {
            transform: scale(1);
          }
          75% {
            transform: scale(1.1);
          }
        }
        @-webkit-keyframes mymove /*Safari and Chrome*/ {
          0% {
            transform: scale(1); /*开始为原始大小*/
          }
          25% {
            transform: scale(1.1); /*放大1.1倍*/
          }
          50% {
            transform: scale(1);
          }
          75% {
            transform: scale(1.1);
          }
        }
        .activtiy-model {
          width: 335px;
          height: 333px;
          border-radius: 20px;
          background: #ffffff;
          opacity: 1;
          position: absolute;
          z-index: 1000;
          right: 50%;
          top: 25%;
          transform: translate(50%, 0%);
          display: flex;
          justify-content: center;
          .activity-model-close {
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
          }
          .activtiy-header-banner {
            position: absolute;
            top: -112px;
            img {
              width: 216px;
              height: 183px;
            }
            .activtiy-header-titel {
              width: 100%;
              position: absolute;
              bottom: -10px;
              display: flex;
              justify-content: center;
              span {
                font-size: 19px;
                font-family: Noto Sans SC;
                /*font-weight: bold;*/
                color: #ff765e;
                opacity: 1;
              }
            }
          }
          .activtiy-form {
            position: absolute;
            bottom: 0px;
            padding: 22px;
            width: 335px;
            margin: 0;
            .area-code {
              position: absolute;
              z-index: 1000;
              height: 46px;
              display: flex;
            }
          }
        }
        .complie-left-audio {
          z-index: 10;
          position: absolute;
          top: 92px;
          left: 0px;
          height: 46px;
          opacity: 1;
          border-radius: 61px;
          transition: 0.5s;
          font-size: 16px;
          color: #666666;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .complie-left-audio input {
          border: none;
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }

  .comlie-content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }

  .share-password {
    width: 275px;
    height: 280px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background: #ffffff;
    position: absolute;
    right: 50%;
    top: 25%;
    transform: translate(50%, 0%);
    z-index: 9999;
    .share-clink-hide {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .share-title {
      border-bottom: 1px solid #e8e8e8;
      height: 81px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 36px;
        height: 25px;
        font-size: 16px;
        /*font-weight: bold;*/
        line-height: 23px;
        color: #333333;
        opacity: 1;
      }
      button {
        width: 100%;
      }
    }
    .share-form {
      width: 198px;
      height: 170px;
      margin: auto;
      margin-top: 16px;
      .share-pwd-title {
        width: 100%;
        text-align: center;
        height: 22px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
        opacity: 1;
      }
      .ant-form {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .ant-form-item-children {
        input {
          width: 170px;
          height: 42px;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #e8e8e8;
        }
      }
      .ant-btn-primary {
        width: 170px;
        height: 42px;
        border-radius: 21px;
        font-size: 16px;
      }
    }
  }
  .open-link {
    width: 130px;
    height: 140px;
    position: absolute;
    right: 50%;
    top: 25%;
    transform: translate(50%, 50%);
    z-index: 9999;
    .open-link-logo {
      display: flex;
      justify-content: center;
      img {
        width: 106px;
        height: 76px;
      }
    }
    .open-link-text {
      margin-top: 29px;
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      color: #333333;
      display: flex;
      justify-content: center;
      font-family: Noto Sans SC;
    }
  }
}
</style>