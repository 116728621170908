<template>
<div class="storage-space-upgrade-file" @click.stop="spaceUpgradeCklickModal(false)" v-if="spaceUpgradeModalShow">
  <div class="group-setting-rights-administrator" @click.stop="spaceUpgradeCklickModal(true)">

    <div v-if="gropuMemberGPricesRequest != null" class="group-rights-admin-list-content">
      <div class="group-rights-admin-list-content-children">
          <div class="admin-rights-title" style=" height: 32px;font-size: 14px;
    line-height: 32px;">{{ $getStringObj.getString($Strings.Mind_Edit_Page_Sharing_Group) }}{{ $getStringObj.getString($Strings.Used_Space) }}</div>
          <div class="group-rights-admin-list-box">
            <div style="position:relative;z-index:1;width: 350px;height: 100px;float:left;line-height: 100px;text-align: center;margin-top: -21px;margin-left: -31px;" 
            class="group-rights-admin-list-box" v-if="gropuMemberGPricesRequest != null && gropuMemberGPricesRequest.currentUse != null && gropuMemberGPricesRequest.currentLimit != null " >
              <span class="group-rights-admin-list-box-name" >{{ $getStringObj.getString($Strings.Current_Storage) }}</span>
               <span class="group-rights-admin-list-box-member-count" > {{ this.gropuMemberGPricesRequest.currentUse }}/</span>
              <span class="group-rights-admin-list-box-member-limit" >{{ this.gropuMemberGPricesRequest.currentLimit}}</span>
            </div>

            <div v-if="this.gropuMemberGPricesRequest.currentPercent!=null" class="group-storage_percentage">
                    <div style="position: absolute;margin-top: -5px;
                  margin-left: -4px;">
                      <a-progress type="circle" :percent="percentageIndex" :strokeColor="'#FD492B'" :showInfo="false" :width="88" />
                     
                    </div>
                  <span style="font-size: 24px;width: 88px;height: 44px;
                  line-height: 44px;display:block;
                  color: #FD492B;">{{ this.gropuMemberGPricesRequest.currentPercent }}%</span>
                  <span style="width: 88px;color: #FD492B;">已使用</span>
            </div>
          </div>
      </div>

      <div class="group-rights-admin-list-content-children" style="clear:both;">
          <div class="admin-rights-title" style=" height: 32px;font-size: 14px;
    line-height: 32px;">{{ $getStringObj.getString($Strings.Mind_Edit_Upgrade) }}{{ $getStringObj.getString($Strings.Used_Space) }}</div>
          <div  v-if=" this.gropuMemberGPricesRequest != null && this.gropuMemberGPricesRequest.prices != null" class="group-rights-admin-list-box" style="">
            <template>
              <div style="position: relative;margin-left:20px"
                class="price-modal-box-children"
                v-for="(each,index) in this.gropuMemberGPricesRequest.prices"
                :key="index"
                :class="showIndex==index?'classCheck':''"
                @click="checkedPrice(each,index)"
              >
                <img
                  v-show="showIndex==index"
                  class="group-confirm-selection"
                  style="left:150px;position:absolute;z-index:5;top: 10px;"
                  src="../../../assets/img/groupImg/checkM.png" 
                  alt=""
                />
                <div class="gray-box" 
                style="position:absolute;z-index:4;margin-left: 74px; margin-top: 5px;">
                  {{each.title}}
                </div>
                <div class="gray-box1" 
                style="position:absolute;z-index:4;margin-top: 35px;margin-left: 35px;">
                  {{ $getStringObj.getString($Strings.Mind_Edit_Page_Sharing_Group) }}{{ $getStringObj.getString($Strings.Used_Space) }}
                </div>
                <div class="modal-icon-box-hen" 
                style="">
                </div>
                <div class="modal-icon-box" 
                style="position:absolute;z-index:4;margin-top: 75px;margin-left: 70px;">
                  {{each.mbeansCost}}{{ $getStringObj.getString($Strings.Mind_MBean) }}
                </div>
                <img
                  v-show="showIndex==index"
                  class="group-confirm-selection"
                  style="position:absolute;z-index:2;margin-top: -7px; margin-left: -9px;"
                  src="../../../assets/img/groupImg/storagePriceBackground.png" 
                  alt=""
                />
              </div>
            </template>
          </div>
      </div>
      <div v-if="this.gropuMemberGPricesRequest!=null && this.gropuMemberGPricesRequest.currentMBeans!=null" style="clear:both;margin-left: 20px;margin-top: 14%;">
        <span style="font-size:20px">{{ $getStringObj.getString($Strings.Mind_My_MBean) }}：</span>
        <span style="font-size:24px;font-weight:bold;">{{this.gropuMemberGPricesRequest.currentMBeans}}</span><img style="margin-top: -9px;margin-left: 6px;" src="../../../assets/img/groupImg/bean.png"/>
        <span @click="clickGlobalPurchase" class="global-purchase"
        style="">{{ $getStringObj.getString($Strings.Global_Purchase) }}</span>
      </div>
      <div
            class="confirm-payment-button"
            style="margin-left: 32px;margin-top: 44px;
            text-align:center;width: 416px;height: 40px;
            line-height: 40px;background: #999999;opacity: 1;border-radius: 36px;
            cursor:pointer;display: inline-block;"
            @click.stop="spaceUpgradeCklickModal(false)"
          >
            <a-button class="confirm-payment-button-a" style="background: #999999;color:#ffffff;font-size:16px;border:0px" >
              {{$getStringObj.getString($Strings.Global_Cancel)}}
            </a-button>
      </div>
      <div
            class="confirm-payment-button"
            style="display: inline-block;margin-top: 44px;
            text-align:center;width: 416px;height: 40px;
            line-height: 40px;background: #FD492B;opacity: 1;
            border-radius: 36px;cursor:pointer;
            margin-left: 20px;"
            @click="confirmPaymentStorageModal"
          >
            <a-button class="confirm-payment-button-a" style="background: #FD492B;color:#ffffff;font-size:16px;border:0px"  @click="confirmPaymentStorageModal">
              {{this.confirmPayment}}
            </a-button>
      </div>
    </div>
    <AlterStorageSpaceUpgradeTipsModal :title=" $getStringObj.getString($Strings.Mind_Group_The_Dissolution_Of_Group) " 
        :content="$getStringObj.getString($Strings.Mind_Tips) "
        :show="showTag" @storeCallbackAlter="storeCallbackAlter"  />
  </div>
</div>
</template>

<script>
import {postMemberGstoragePrices,postMemberBuyStorage } from "../../../common/netWork/member_api";
import AlterStorageSpaceUpgradeTipsModal from '../groupSettingPage/groupSettingPageChildren/AlterStorageSpaceUpgradeTipsModal';
import { postGroupMembers } from "../../../common/netWork/group_api";
// import ChickcheckModal from './ChickcheckModal'
export default {
    props: [
        "spaceUpgradeModalShow","groupGmapId"
    ],
    components: {
      // ChickcheckModal,
      AlterStorageSpaceUpgradeTipsModal
    },
    data() {
        return {
          groupDataDetail: null,
          gropuMemberGPricesRequest:null,//组成员上限价格列表
          showTag:{showAlter: false} ,
          showIndex : -1,
          prices: null,
          confirmPayment : this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment),
          percentageIndex: 0,
        }
    },
    created() {

      this.pullMemberGPricesRequest({ groupId: this.groupGmapId }).then(res => {
        this.gropuMemberGPricesRequest = res;
        if( this.gropuMemberGPricesRequest.currentPercent!=null){
         
           this.percentageIndex= this.gropuMemberGPricesRequest.currentPercent;
           this.checkedPrice(this.gropuMemberGPricesRequest.prices[0],0);
        }

      })
  
     

    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        spaceUpgradeCklickModal(val) {
                this.spaceUpgradeCklickAlter(val)
        },
        spaceUpgradeCklickAlter(obj){
            this.$emit("spaceUpgradeCklickAlter",obj);
        },
        clickGlobalPurchase(){
          this.bus.$emit('shoppingGmpModel',true);
        },
        storeCallbackAlter(obj) {
          
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showTag = false;
                    // this.showIndex = -1;
                    this.confirmPayment = this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment)

                    this.checkedPrice(this.prices,this.showIndex);

                    if (obj.confrim == true && obj.contentName==this.$getStringObj.getString(this.$Strings.Mind_Whether_Confirm_Payment_Purchase)) {
                      this.setMemberBuyStorage({storagePriceId:this.prices.id,amount:1,groupId:this.groupGmapId})
                      .then(res => {
                        
                       this.spaceUpgradeCklickAlter(false);
                      })
                      // location.reload();
                    }
                    if (obj.confrim == true && obj.contentName==this.$getStringObj.getString(this.$Strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean)) {
                      this.bus.$emit('shoppingGmpModel',true);
                      // this.$store.state.showShopping = true;
                    }
                }
            }
        },
        //选中M豆价格
        checkedPrice(each,index) { 
          this.confirmPayment = this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment);
          if(each != null){
            this.showIndex = index
            this.confirmPayment = this.confirmPayment  + each.mbeansCost + this.$getStringObj.getString(this.$Strings.Mind_MBean);
            this.prices = each;

          }

          return null
        },
        //确认支付跳提示框
        confirmPaymentStorageModal() {

          let obj;
          if(parseInt(this.prices.mbeansCost) <= parseInt(this.gropuMemberGPricesRequest.currentMBeans)){
            obj = {
              title : this.$getStringObj.getString(this.$Strings.Mind_Tips) ,
              content : this.$getStringObj.getString(this.$Strings.Mind_Whether_Confirm_Payment_Purchase) ,
              showAlter: true,
            }
          }else{
            obj = {
              title : this.$getStringObj.getString(this.$Strings.Mind_Tips) ,
              content : this.$getStringObj.getString(this.$Strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean) ,
              showAlter: true,
            }
          }
          this.showTag = obj

        },

        //获取共享组全部成员信息
       pullGroupMembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupMembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        //查看组存储空间价格列表
        pullMemberGPricesRequest(obj){
          return new Promise((resolve, reject) => {
                postMemberGstoragePrices(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        //成功购买组成员上限
        setMemberBuyStorage(obj){
          return new Promise((resolve, reject) => {
                postMemberBuyStorage(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        }
    },
    watch: {
        "$store.state.showShopping"(newShowShopping, olShowShopping) {
             this.pullMemberGPricesRequest({ groupId: this.groupGmapId }).then(res => {
                this.gropuMemberGPricesRequest = res;
                          
              })
        },
    }
}
</script>

<style lang="less" scoped>
.storage-space-upgrade-file{
  width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
        background-color: rgba(0,0,0,.3);
}

// .score-circle-left-if{transform(data.percentageLeftIndex) }
.group-storage_percentage{
  position:relative;
  z-index:1;
  float:left;
  width: 88px;
  height: 88px;
  border: 0px solid #F9A99A;
  border-radius: 50% ;
  text-align: center;
  display: inline-block;
  opacity: 1;
}
.global-purchase:hover{
  background: rgb(245, 127, 108);
}
.global-purchase{
  font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
    display: inline-block;
    margin-top: 44px;
    text-align: center;
    width: 116px;
    height: 35px;
    line-height: 35px;
    background: rgb(253, 73, 43);
    opacity: 1;
    border-radius: 18px;
    color: #ffffff;
}

.group-setting-rights-administrator {
    position: absolute;
    width: 921px;
    min-height: 520px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 4px;
  .group-rights-admin-list-content {
    padding-top: 20px;
    .group-rights-admin-list-content-children {
      .admin-rights-title {
        color: #999;
        font-size: 10px;
        padding-left: 20px;
        height: 22px;
        line-height: 22px;
        background-color: #f2f2f2;
      }
      .group-rights-admin-list-box {
        padding-top: 10px;
        padding-bottom: 10px;
        .group-rights-admin-list-box {
          margin-left:10px;
          height:40px;
          margin-top:-10px;
          .group-rights-admin-list-box-name {
            font-size: 20px;
          }
          .group-rights-admin-list-box-member-count{
            font-size:24px;
          }
          .group-rights-admin-list-box-member-limit{
            font-size:24px;
            color: red;
          }
          .each-check-content-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            .each-check-content-button-text {
              margin-right: 8px;
            }        
          }
        }
        .price-modal-box-children{
          border-radius:10px;
          width:186px;height:110px;
          border:1px solid #EBEBEB;
          margin-left:10px;
          margin-top:10px;
          float:left;
          position:relative;z-index:1;
          cursor:pointer;
          .gray-box{
            text-align:center; 
            color:#333333;
            font-size: 18px;
          }
          .gray-box1{
            text-align:center; 
            color:#333333;
            font-size: 16px;
          }
          .modal-icon-box{
          margin: 0 auto;
            text-align:center; 
            color:#333333;
            font-size: 14px;
          }
          .modal-icon-box-hen{
            position:absolute;
            z-index:1;
            margin-top: 65px;
            width: 186px;
            border-top: 1px solid #EBEBEB;
          }
          
        }
        .price-modal-box-children:hover {
          background-color: #FD492B;
          border:1px solid #FD492B;   
        }
        .price-modal-box-children:hover .modal-icon-box-hen{
            border-top:1px solid #f86045;  
        }
        .price-modal-box-children:hover .gray-box{
            color:#ffffff;
            }
        .price-modal-box-children:hover .gray-box1{
            color:#ffffff;
            }
        .price-modal-box-children:hover .modal-icon-box{
          color: #ffffff;
        }

        .classCheck {
          .gray-box{
            color:#ffffff;
            } 
          .gray-box1{
            color:#ffffff;
            }
            .modal-icon-box{
              color: #ffffff;
            }
        }
        
      }
    }
  }

}



</style>