// package mind.yushu.com.mindmap.core.calcule;

import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import CGRect from "../../../viewmodel/core/base/basedata/Rect"
import HashMap from "../../../viewmodel/core/base/HashMap"
import MindElementType from "../../../viewmodel/datatype/MindElementType"
import MindType from "../../../viewmodel/datatype/MindType"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import MoveMindElementDataInfo from "../basemode/MoveMindElementDataInfo"
import CheckNodeData from "./CheckNodeData"

/**
 * ProjectName: MindMap
 * Created by tony on 2/2/21
 * Copyright(c) 2020 mindyushu.com
 */


class MoveMindElementDataByPoint {
    constructor() {
        this.spaceH = new UiUtil().dip2px(40);
        this.spaceV = new UiUtil().dip2px(5);
        this.width = new UiUtil().dip2px(80);
        this.height = new UiUtil().dip2px(30);
        this.space = new UiUtil().dip2px(20);

        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.orderCheckDataList = [];
        this.util = Util
    }


    setData(mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
        this.orderCheckDataList = [];
    }

    hit(point, targetNode) {
        let info = new MoveMindElementDataInfo();
        let targetCentrePoint = new CGPoint((targetNode.value.x + targetNode.value.width / 2),
                                                   (targetNode.value.y + targetNode.value.height / 2));

        let mainMindNodeUnitArr = this.mainMindNodeUnit.mainMindElementDataDict.values();
        let mainMindNodeUnitLen = mainMindNodeUnitArr.length
        for (let index = 0; index < mainMindNodeUnitLen; index++) {
            let node = mainMindNodeUnitArr[index];
            if (node.value.isHidden) {
                continue
            }
            let nodeCentrePoint = new CGPoint((node.value.x + node.value.width / 2),
            (node.value.y + node.value.height / 2));
            let distance = this.util.getPointSpacing(targetCentrePoint, nodeCentrePoint)
            let checkNode = new CheckNodeData(node, distance);
            this.orderCheckDataList.push(checkNode)
        }
        this.freeNodes.forEach(unit=>{
            unit.mainMindElementDataDict.values().forEach(node=> {
                if (!node.value.isHidden) {
                    let nodeCentrePoint = new CGPoint((node.value.x + node.value.width / 2),
                                                           (node.value.y + node.value.height / 2));
                    let distance = this.util.getPointSpacing(targetCentrePoint, nodeCentrePoint)
                    this.orderCheckDataList.push(new CheckNodeData(node, distance))
                }
            });
        });


        let doubleBubbleMindNodeUnitArr = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.values();
        let doubleBubbleMindNodeUnitLen = doubleBubbleMindNodeUnitArr.length;
        for (let index = 0; index < doubleBubbleMindNodeUnitLen; index++) {
            let node = doubleBubbleMindNodeUnitArr[index];
            if (node.value.isHidden) {
                continue
            }
            let nodeCentrePoint = new CGPoint((node.value.x + node.value.width / 2),
                                                        (node.value.y + node.value.height / 2));
            let distance = this.util.getPointSpacing(targetCentrePoint, nodeCentrePoint)
            this.orderCheckDataList.push(new CheckNodeData( node, distance))
        }
        if (this.orderCheckDataList.length > 1) {
            this.orderCheckDataList.sort(function (data1, data2) {
                if (data1.distance == data2.distance) {
                    return 0;
                } else if (data1.distance < data2.distance) {
                    return -1;
                } else {
                    return 1;
                }
            })
        }
        if (this.orderCheckDataList.length > 0) {
            for (let index = 0; index < this.orderCheckDataList.length; index++) {
                let data = this.orderCheckDataList[index];
                if (index > 20 || data.distance > new UiUtil().dip2px(500)) {
                    break;
                }
                let node = data.node
                let parentNode = this.getNodeById(node.value.id)
                let layout = node.children.isEmpty && !parentNode.isEmpty() ? parentNode.value.layout : node.value.layout
                info = this.matrixHit(point, node.value, targetNode, layout);
                if (info.index > -1 && !info.node.isEmpty() ) {
                    return info;
                }
            }
        }
        return new MoveMindElementDataInfo();
    }

    lineHit(point, data) {
        return new NodeConnectLineHit().lineHit(point, data);
    }

    hitPoint(rect, point) {
        return point.x > rect.x && point.x < rect.x + rect.width() &&
            point.y > rect.y && point.y < rect.y + rect.height();
    }

    hitNodeLeft(data, targetData) {
        return this.hitPoint(new CGRect(targetData.x, targetData.y, targetData.width, targetData.height),
            new CGPoint(data.x, data.y)) ||
            this.hitPoint(new CGRect(targetData.x, targetData.y,
                targetData.width, targetData.height), new CGPoint(data.x, data.y + data.height));
    }

    hitNodeRight(data, targetData) {
        return this.hitPoint(new CGRect(targetData.x, targetData.y,
            targetData.width, targetData.height),
            new CGPoint(data.x + this.width, data.y)) ||
            this.hitPoint(new CGRect(targetData.x, targetData.y,
                targetData.width, targetData.height),
                new CGPoint(data.x + data.width, data.y + data.height));
    }

    hitNodeTop(data, targetData) {
        return this.hitPoint(new CGRect(targetData.x, targetData.y,
            targetData.width, targetData.height),
            new CGPoint(data.x, data.y)) ||
            this.hitPoint(new CGRect(targetData.x, targetData.y,
                targetData.width, targetData.height),
                new CGPoint(data.x + data.width, data.y));
    }

    hitNodeBottom(data, targetData) {
        return this.hitPoint(new CGRect(targetData.x, targetData.y,
            targetData.width, targetData.height),
            new CGPoint(data.x, data.y + data.height)) ||
            this.hitPoint(new CGRect(targetData.x, targetData.y,
                targetData.width, targetData.height),
                new CGPoint(data.x + data.width, data.y + data.height));
    }

    hitData(data, target) {
        let rightCrossArea = this.util.getCrossArea(new CGRect(data.x, data.y, data.width, data.height),
            new CGRect(target.x - this.space, target.y - this.space, target.width + this.space * 2, target.height + this.space * 2));
        return rightCrossArea > 0;
    }

    checkParentChildMiddle( parentNode, target) {
        var info = new  MoveMindElementDataInfo();
        if (parentNode.isEmpty() || parentNode.children.length <= 1){
            return info;
        }
        if (parentNode.value.layout == NodeLayoutType.LAYOUT_LEFT_RIGHT  ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_LEFT  ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT  ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_RIGHT  ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT  ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE) {
            let leftList = new Array();
            let leftStartIndex = -1;
            let rightList = new Array();
            let rightStartIndex = -1;
            for (var  index=0; index<parentNode.children.length; index++) {
                let cell = parentNode.children[index];
                if (cell.value.layout == NodeLayoutType.LAYOUT_LEFT ||
                        cell.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        cell.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        cell.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    leftList.push(cell);
                    leftStartIndex = leftStartIndex == -1 ? index : leftStartIndex;
                } else {
                    rightList.push(cell);
                    rightStartIndex = rightStartIndex == -1 ? index : rightStartIndex;
                }
            }
            info = this.checkNodeList(leftList, parentNode, target, leftStartIndex);
            if (info.isHit()) {
                return info;
            }
            info = this.checkNodeList(rightList, parentNode, target, rightStartIndex);
            if (info.isHit()) {
                return info;
            }
        
            var previewNode = parentNode.children[0];
            let lastNode = parentNode.children[parentNode.children.length - 1];
            let hitPreviewNode = this.hitDataTo(previewNode.value, parentNode,target);
            let hitLastNode = this.hitDataTo(lastNode.value, parentNode, target);
            if (hitPreviewNode) {
                info.node = parentNode;
                info.index = 0;
                info.startPoint = new CGPoint((parentNode.value.x + parentNode.value.width / 2),
                        (parentNode.value.y + parentNode.value.height / 2));
                if (previewNode.value.x + previewNode.value.width < parentNode.value.x) { //左侧
                    info.endPoint = new CGPoint((previewNode.value.x + previewNode.value.width),
                            (previewNode.value.y + previewNode.value.height/2));
                    info.rect = new CGRect(info.endPoint.x - this.width,
                            info.endPoint.y - this.height/2,
                            this.width,  this.height);
                } else { //右侧
                    info.endPoint = new CGPoint((previewNode.value.x),
                            (previewNode.value.y + previewNode.value.height/2));
                    info.rect = new CGRect(info.endPoint.x,
                            info.endPoint.y - this.height/2,
                            this.width, this.height);
                }
                return info;
            } else if (hitLastNode) {
                info.node = parentNode;
                info.index = parentNode.children.length - 1;
                info.startPoint = new CGPoint((parentNode.value.x + parentNode.value.width / 2),
                        (parentNode.value.y + parentNode.value.height / 2));
                if (lastNode.value.x + lastNode.value.width < parentNode.value.x) { //左侧
                    info.endPoint = new CGPoint((lastNode.value.x + lastNode.value.width),
                            (lastNode.value.y + lastNode.value.height/2));
                    info.rect = new CGRect(info.endPoint.x - this.width,
                             info.endPoint.y - this.height/2,
                             this.width, this.height);
                } else { //右侧
                    info.endPoint = new CGPoint((lastNode.value.x),
                            (lastNode.value.y + lastNode.value.height/2));
                    info.rect = new CGRect(info.endPoint.x,
                             info.endPoint.y - this.height/2,
                             this.width, this.height);
                }
                return info;
            }
            for(var index = 1,j = parentNode.children.length; index < j; index++)
            {
                let child  = parentNode.children[index];
                if (child.value.id == target.id) {
                    continue;
                }
                if ((child.value.x + child.value.width < previewNode.value.x) ||
                        (previewNode.value.x + previewNode.value.width < child.value.x)) {
                    previewNode = child;
                    continue;
                }
                if ((previewNode.value.x > target.x + target.width ||
                        previewNode.value.x + parentNode.value.width < target.x) ||
                        (child.value.x > target.x + target.width ||
                                child.value.x + child.value.width < target.x)) {
                    previewNode = child;
                    continue;
                }
                if ((previewNode.value.y + previewNode.value.height < target.y &&
                        child.value.y > target.y + target.height) ||
                        child.value.y + child.value.height < target.y &&
                                previewNode.value.y > target.y + target.height) {
                    info.node = parentNode;
                    info.index = index;
                    info.startPoint = new CGPoint(parentNode.value.x + parentNode.value.width / 2,
                            parentNode.value.y + parentNode.value.height / 2);
                    if (previewNode.value.x + previewNode.value.width < parentNode.value.x) { //左侧
                        info.endPoint = new CGPoint(previewNode.value.x + previewNode.value.width,
                                previewNode.value.y + parentNode.value.height + (child.value.y - (previewNode.value.y + parentNode.value.height))/2);
                        info.rect = new CGRect((info.endPoint.x - this.width),
                                (info.endPoint.y - this.height/2),
                                this.width, this.height);
                    } else { //右侧
                        info.endPoint = new CGPoint(previewNode.value.x,
                                previewNode.value.y + parentNode.value.height + (child.value.y - (previewNode.value.y + parentNode.value.height))/2);
                        info.rect = new CGRect(info.endPoint.x,
                                info.endPoint.y - this.height/2,
                                this.width, this.height);
                    }
                    return info;
                }
                previewNode = child;
            }
        }
        return info;
    }

    checkNodeList( list,  parentNode, target, startIndex){
        let info = new MoveMindElementDataInfo();
        if(list.isEmpty() || list.length <= 1) {
            return info;
        }
        let previewNode = list[0];
        let lastNode = list[list.length - 1];
        let hitPreviewNode = this.hitDataTo(previewNode.value, parentNode, target);
        let hitLastNode = this.hitDataTo(lastNode.value, parentNode, target);
        if (hitPreviewNode) {
            info.node = parentNode;
            info.index = 0 + startIndex;
            info.startPoint = new CGPoint((parentNode.value.x + parentNode.value.width / 2),
                    (parentNode.value.y + parentNode.value.height / 2));
            if (previewNode.value.x + previewNode.value.width < parentNode.value.x) { //左侧
                info.endPoint = new CGPoint((previewNode.value.x + previewNode.value.width),
                       (previewNode.value.y + previewNode.value.height/2));
                info.rect = new CGRect(info.endPoint.x - this.width,
                        info.endPoint.y - this.height/2,
                        this.width, this.height);
            } else { //右侧
                info.endPoint = new CGPoint((previewNode.value.x),
                        (previewNode.value.y + previewNode.value.height/2));
                info.rect = new CGRect(info.endPoint.x,
                        info.endPoint.y - this.height/2,
                        this.width, this.height);
            }
            return info;
        } else if (hitLastNode) {
            info.node = parentNode;
            info.index = list.length - 1 + startIndex;
            info.startPoint = new CGPoint((parentNode.value.x + parentNode.value.width / 2),
                    (parentNode.value.y + parentNode.value.height / 2));
            if (lastNode.value.x + lastNode.value.width < parentNode.value.x) { //左侧
                info.endPoint = new CGPoint((lastNode.value.x + lastNode.value.width),
                        (lastNode.value.y + lastNode.value.height/2));
                info.rect = new CGRect(info.endPoint.x - this.width,
                        info.endPoint.y - this.height/2,
                        this.width, this.height);
            } else { //右侧
                info.endPoint = new CGPoint((lastNode.value.x),
                        (lastNode.value.y + lastNode.value.height/2));
                info.rect = new CGRect(info.endPoint.x,
                        info.endPoint.y - this.height/2,
                        this.width,  this.height);
            }
            return info;
        }
        for(var index = 1; index < list.length; index++) {
            let child  = list[index];
            if (child.value.id == target.id) {
                continue;
            }
            if ((child.value.x + child.value.width < previewNode.value.x) ||
                    (previewNode.value.x + previewNode.value.width < child.value.x)) {
                previewNode = child;
                continue;
            }
            if ((previewNode.value.x > target.x + target.width ||
                    previewNode.value.x + parentNode.value.width < target.x) ||
                    (child.value.x > target.x + target.width ||
                            child.value.x + child.value.width < target.x)) {
                previewNode = child;
                continue;
            }
            if ((previewNode.value.y + previewNode.value.height < target.y &&
                    child.value.y > target.y + target.height) ||
                    child.value.y + child.value.height < target.y &&
                            previewNode.value.y > target.y + target.height) {
                info.node = parentNode;
                info.index = index + startIndex;
                info.startPoint = new CGPoint((parentNode.value.x + parentNode.value.width / 2),
                        (parentNode.value.y + parentNode.value.height / 2));
                if (previewNode.value.x + previewNode.value.width < parentNode.value.x) { //左侧
                    info.endPoint = new CGPoint((previewNode.value.x + previewNode.value.width),
                            (previewNode.value.y + parentNode.value.height + (child.value.y - (previewNode.value.y + parentNode.value.height))/2));
                    info.rect = new CGRect(info.endPoint.x - this.width,
                            info.endPoint.y - this.height/2,
                            this.width,  this.height);
                } else { //右侧
                    info.endPoint = new CGPoint((previewNode.value.x),
                            (previewNode.value.y + parentNode.value.height + (child.value.y - (previewNode.value.y + parentNode.value.height))/2));
                    info.rect = new CGRect(info.endPoint.x,
                             info.endPoint.y - this.height/2,
                             this.width, this.height);
                }
                return info;
            }
            previewNode = child;
        }
        return info;
    }

    hitDataTo( data, parentNode, target) {
        let rect1 = new CGRect(data.x, data.y, data.width, data.height);
        let rect2 = new CGRect(target.x - this.space , (target.y - this.space),
                (target.width + this.space * 2), target.height + this.space * 2);
        let rightCrossArea = this.util.getCrossArea(rect1,rect2);
        return rightCrossArea > 0;
    }

    matrixHit(point, data, targetNode, globalLayout) {

        let info = new MoveMindElementDataInfo();
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden || data.id == targetNode.value.id) {
            return info;
        }
        if (this.isChildNode(node, targetNode)) {
            return info;
        }

        info = this.checkParentChildMiddle( node,  targetNode.value);
        if (info.isHit()) {
            return info;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let hit = this.hitDataTo(data, parentNode,targetNode.value);

        let targetCentrePoint = new CGPoint(targetNode.value.x + targetNode.value.width / 2,
            targetNode.value.y + targetNode.value.height / 2);
        let nodeCentrePoint = new CGPoint(node.value.x + node.value.width / 2,
            node.value.y + node.value.height / 2);
        let parentNodeCentrePoint = new CGPoint(parentNode.value.x + parentNode.value.width / 2,
            parentNode.value.y + parentNode.value.height / 2);
        if (!hit) {
            return info;
        }
        let targetRect = new CGRect(point.x, point.y, targetNode.value.width, targetNode.value.height);
        let left = new CGRect(data.x, data.y, data.width / 2, data.height);
        let top = new CGRect(data.x, data.y, data.width, data.height / 2);
        let right = new CGRect(data.x + data.width / 2, data.y, data.width / 2, data.height);
        let bottom = new CGRect((data.x), data.y + data.height / 2, data.width, data.height / 2);

        if (globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(node.value.x + node.value.width + this.spaceH, nodeCentrePoint.y);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2, this.width, this.height);
                } else if (node.children.length == 1) {
                    info.node = node;
                    info.index = 1;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(node.value.x - this.spaceH, nodeCentrePoint.y);
                    info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2, this.width, this.height);
                } else {
                    let leftCrossArea = this.util.getCrossArea(left, targetRect);
                    let rightCrossArea = this.util.getCrossArea(right, targetRect);
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    if (leftCrossArea > rightCrossArea) {
                        return this.setNodeToChild(node, targetNode, NodeLayoutType.LAYOUT_LEFT);
                    } else {
                        return this.setNodeToChild(node, targetNode, NodeLayoutType.LAYOUT_RIGHT);
                    }
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(node.value.x - this.spaceH, nodeCentrePoint.y);
                    info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2, this.width, this.height);
                } else {
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    return this.setNodeToChild(node, targetNode, globalLayout);
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(node.value.x + node.value.width + this.spaceH, nodeCentrePoint.y);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2, this.width, this.height);
                } else {
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    return this.setNodeToChild(node, targetNode, globalLayout);
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_BOTTOM ||
            globalLayout == NodeLayoutType.LAYOUT_TOP) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(nodeCentrePoint.x, node.value.y + node.value.height + this.spaceH);
                    info.rect = new CGRect(info.endPoint.x - this.width / 2, info.endPoint.y, this.width, this.height);
                } else {
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    return this.setNodeToChild(node, targetNode, globalLayout);
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(nodeCentrePoint.x, node.value.y + node.value.height + this.spaceH);
                    info.rect = new CGRect(info.endPoint.x - this.width / 2, info.endPoint.y, this.width, this.height);
                } else {
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    return this.setNodeToChild(node, targetNode, globalLayout);
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(nodeCentrePoint.x + this.spaceH, node.value.y + node.value.height + this.height / 2 + this.spaceV);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                        this.width, this.height);
                } else {
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    return this.setNodeToChild(node, targetNode, globalLayout);
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            if (parentNode.isEmpty()) {
                if (node.children.isEmpty()) {
                    info.node = node;
                    info.index = 0;
                    info.startPoint = nodeCentrePoint;
                    info.endPoint = new CGPoint(nodeCentrePoint.x + this.spaceH, node.value.y + node.value.height + this.height / 2 + this.spaceV);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                        this.width, this.height);
                } else {
                    info.node = node;
                    info.startPoint = nodeCentrePoint;
                    return this.setNodeToChild(node, targetNode, globalLayout);
                }
            } else {
                return this.setNodeToParent(node, targetNode, globalLayout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_FORM || globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {

        }
        return info;
    }

    setNodeToParent(node, targetNode, globalLayout) {
        let parentNode = this.getNodeById(node.value.parentNodeId);
        let info = new MoveMindElementDataInfo();
        if (parentNode.isEmpty()) {
            return new MoveMindElementDataInfo();
        }

        let data = node.value;
        let targetRect = new CGRect(targetNode.value.x - this.space,
            targetNode.value.y - this.space,
            targetNode.value.width + this.space * 2,
            targetNode.value.height + this.space * 2);
        let top = new CGRect(data.x, data.y, data.width, data.height / 2);
        let bottom = new CGRect(data.x, data.y + data.height / 2, data.width, data.height / 2);
        let left = new CGRect(data.x, data.y, data.width / 2, data.height);
        let right = new CGRect(data.x + data.width / 2, data.y, data.width / 2, data.height);

        info.node = parentNode;
        info.startPoint = new CGPoint(parentNode.value.x + parentNode.value.width / 2,
            parentNode.value.y + parentNode.value.height / 2);
        let parentNodeCentrePoint = new CGPoint(parentNode.value.x + parentNode.value.width / 2,
            parentNode.value.y + parentNode.value.height / 2);
        if (globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {

            let leftCrossArea = this.util.getCrossArea(left, targetRect);
            let rightCrossArea = this.util.getCrossArea(right, targetRect);

            if ((data.layout == NodeLayoutType.LAYOUT_LEFT ||
                data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) &&
                leftCrossArea > rightCrossArea * 4) {
                return this.setNodeToChild(node, targetNode, data.layout);
            } else if ((data.layout == NodeLayoutType.LAYOUT_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_CIRCLE) &&
                leftCrossArea * 4 < rightCrossArea) {
                return this.setNodeToChild(node, targetNode, data.layout);
            }
            let topCrossArea = this.util.getCrossArea(top, targetRect);
            let bottomCrossArea = this.util.getCrossArea(bottom, targetRect);
            info.node = parentNode;
            info.startPoint = parentNodeCentrePoint;
            let index = this.getNodeIndex(node);
            if (topCrossArea > bottomCrossArea || (topCrossArea == bottomCrossArea && targetRect.y + targetRect.height() / 2 <= data.y + data.height / 2)) {
                info.index = index;
                if (parentNode.value.x < node.value.x) {
                    info.endPoint = new CGPoint(node.value.x,
                        node.value.y - this.spaceV - this.height / 2);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                        this.width, this.height);
                } else {
                    info.endPoint = new CGPoint(node.value.x + node.value.width,
                        node.value.y - this.spaceV - this.height / 2);
                    info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                        this.width, this.height);
                }

            } else if (topCrossArea < bottomCrossArea || (topCrossArea == bottomCrossArea && targetRect.y + targetRect.height() / 2 > data.y + data.height / 2)) {
                info.index = index + 1;
                if (parentNode.value.x < node.value.x) {
                    info.endPoint = new CGPoint(node.value.x,
                        node.value.y + node.value.height + this.spaceV + this.height / 2);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                        this.width, this.height);
                } else {
                    info.endPoint = new CGPoint(node.value.x + node.value.width,
                        node.value.y + node.value.height + this.spaceV + this.height / 2);
                    info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                        this.width, this.height);
                }
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
            let leftCrossArea = this.util.getCrossArea(left,targetRect);
            let rightCrossArea = this.util.getCrossArea(right,targetRect);

            if ((data.layout == NodeLayoutType.LAYOUT_LEFT ||
                    data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) && leftCrossArea > rightCrossArea * 4) {
                return this.setNodeToChild(node, targetNode, LAYOUT_LEFT);
            } else if ((data.layout == NodeLayoutType.LAYOUT_RIGHT  ||
                    data.layout == NodeLayoutType.LAYOUT_BUBBLE  ||
                    data.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    data.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                    data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                    data.layout == NodeLayoutType.LAYOUT_CIRCLE) && leftCrossArea * 4 < rightCrossArea) {
                return this.setNodeToChild(node, targetNode, LAYOUT_RIGHT);
            }
            let topCrossArea = this.util.getCrossArea(top, targetRect);
            let bottomCrossArea = this.util.getCrossArea(bottom, targetRect);
            info.node = parentNode;
            info.startPoint = parentNodeCentrePoint;
            let index = this.getNodeIndex(node);
            if (topCrossArea > bottomCrossArea ||
                    (topCrossArea == bottomCrossArea && targetRect.y + targetRect.height() / 2 <= data.y + data.height/2)) {
                info.index = index;
                if (parentNode.value.x < node.value.x) {
                    info.endPoint = new CGPoint(node.value.x,
                            (node.value.y) - this.spaceV - this.height/2);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height/2,
                        this.width, this.height);
                } else {
                    info.endPoint = new CGPoint(node.value.x + node.value.width,
                            (node.value.y) - this.spaceV - this.height/2);
                    info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height/2,
                        this.width, this.height);
                }

            } else if (topCrossArea < bottomCrossArea ||
                    (topCrossArea == bottomCrossArea && targetRect.y + targetRect.height() / 2 > (data.y + data.height/2))) {
                info.index = index + 1;
                if (parentNode.value.x < node.value.x) {
                    info.endPoint = new CGPoint((node.value.x),
                            (node.value.y + node.value.height) + this.spaceV + this.height/2);
                    info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height/2,
                        this.width, this.height);
                } else {
                    info.endPoint = new CGPoint((node.value.x + node.value.width),
                            (node.value.y + node.value.height) + this.spaceV + this.height/2);
                    info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height/2,
                        this.width, this.height);
                }
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_BOTTOM ||
            globalLayout == NodeLayoutType.LAYOUT_TOP) {
            let topCrossArea = this.util.getCrossArea(top, targetRect);
            let bottomCrossArea = this.util.getCrossArea(bottom, targetRect);
            if (bottomCrossArea > topCrossArea * 4) {
                return this.setNodeToChild(node, targetNode, globalLayout);
            }
            let leftCrossArea = this.util.getCrossArea(left, targetRect);
            let rightCrossArea = this.util.getCrossArea(right, targetRect);
            info.node = parentNode;
            info.startPoint = parentNodeCentrePoint;
            let index = this.getNodeIndex(node);
            if (leftCrossArea > rightCrossArea) {
                info.index = index;
                info.endPoint = new CGPoint(node.value.x - this.width / 2 - this.spaceV,
                    node.value.y);

            } else if (leftCrossArea < rightCrossArea) {
                info.index = index + 1;
                info.endPoint = new CGPoint(node.value.x + node.value.width + this.spaceV,
                    node.value.y);
            }
            info.rect = new CGRect(info.endPoint.x - this.width / 2, info.endPoint.y,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            let leftCrossArea = this.util.getCrossArea(left, targetRect);
            let rightCrossArea = this.util.getCrossArea(right, targetRect);

            if ((data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) && leftCrossArea > rightCrossArea * 4) {
                return this.setNodeToChild(node, targetNode, data.layout);
            } else if ((data.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                data.layout == NodeLayoutType.LAYOUT_FISH_LEFT) && leftCrossArea * 4 < rightCrossArea) {
                return this.setNodeToChild(node, targetNode, data.layout);
            } else if (data.layout != NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT &&
                data.layout != NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                this.setNodeToParent(node, targetNode, data.layout);
            }
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            let topCrossArea = this.util.getCrossArea(top, targetRect);
            let bottomCrossArea = this.util.getCrossArea(bottom, targetRect);
            let leftCrossArea = this.util.getCrossArea(left, targetRect);
            let rightCrossArea = this.util.getCrossArea(right, targetRect);

            if (leftCrossArea > rightCrossArea * 4) {
                return this.setNodeToChild(node, targetNode, globalLayout);
            }

            info.node = parentNode;
            info.startPoint = parentNodeCentrePoint;
            let index = this.getNodeIndex(node);
            if (topCrossArea > bottomCrossArea) {
                info.index = index;
                info.endPoint = new CGPoint(node.value.x + node.value.width,
                    node.value.y - this.height / 2 - this.spaceV);

            } else if (topCrossArea < bottomCrossArea) {
                info.index = index + 1;
                info.endPoint = new CGPoint(node.value.x + node.value.width,
                    node.value.y + node.value.height + this.height / 2 + this.spaceV);
            }
            if (info.startPoint.x < info.endPoint.x) {
                info.startPoint.x = info.endPoint.x + this.spaceV;
            }
            info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT) {
            let topCrossArea = this.util.getCrossArea(top, targetRect);
            let bottomCrossArea = this.util.getCrossArea(bottom, targetRect);
            let leftCrossArea = this.util.getCrossArea(left, targetRect);
            let rightCrossArea = this.util.getCrossArea(right, targetRect);

            if (leftCrossArea * 4 < rightCrossArea) {
                return this.setNodeToChild(node, targetNode, globalLayout);
            }

            info.node = parentNode;
            info.startPoint = parentNodeCentrePoint;
            let index = this.getNodeIndex(node);
            if (topCrossArea > bottomCrossArea) {
                info.index = index;
                info.endPoint = new CGPoint(node.value.x,
                    node.value.y - this.height / 2 - this.spaceV);

            } else if (topCrossArea < bottomCrossArea) {
                info.index = index + 1;
                info.endPoint = new CGPoint(node.value.x,
                    node.value.y + node.value.height + this.height / 2 + this.spaceV);
            }
            if (info.startPoint.x > info.endPoint.x) {
                info.startPoint.x = info.endPoint.x - this.spaceV;
            }
            info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_FORM || globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {

        }
        return info;
    }

    setNodeToChild(node, target, globalLayout) {

        let targetCentrePoint = new CGPoint(target.value.x + target.value.width / 2,
            target.value.y + target.value.height / 2);
        let nodeCentrePoint = new CGPoint(node.value.x + node.value.width / 2,
            node.value.y + node.value.height / 2);

        let info = new MoveMindElementDataInfo();
        info.node = node;
        info.startPoint = nodeCentrePoint;

        if (globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE) {
            let findNode = new LineMindTypeNode();
            let findIndex = -1;
            if (node.children.length == 0) {
                info.node = node;
                info.index = 0;
                info.startPoint = nodeCentrePoint;
                info.endPoint = new CGPoint(node.value.x + this.spaceH, nodeCentrePoint.y);
                info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                    this.width, this.height);
                return info;
            }
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index];
                if (cell.value.x < node.value.x) {
                    continue;
                }
                if (findNode.isEmpty()) {
                    findNode = cell;
                    findIndex = index;
                } else if (Math.abs(cell.value.y - targetCentrePoint.y) < Math.abs(findNode.value.y - targetCentrePoint.y)) {
                    findNode = cell;
                    findIndex = index;
                }
            }
            if (findNode.isEmpty()) {
                return info;
            }
            if (findNode.value.y > targetCentrePoint.y) {
                info.index = findIndex;
                info.endPoint = new CGPoint(findNode.value.x,
                    findNode.value.y - this.spaceV - this.height / 2);
            } else {
                info.index = findIndex + 1;
                info.endPoint = new CGPoint(findNode.value.x,
                    findNode.value.y + findNode.value.height + this.spaceV + this.height / 2);
            }
            info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
            let findNode = new LineMindTypeNode();
            let findIndex = -1;
            if (node.children.length == 0) {
                info.node = node;
                info.index = 0;
                info.startPoint = nodeCentrePoint;
                info.endPoint = new CGPoint(node.value.x - this.spaceH, nodeCentrePoint.y);
                info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                    this.width, this.height);
                return info;
            }
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index];
                if (cell.value.x > node.value.x) {
                    continue;
                }
                if (findNode.isEmpty()) {
                    findNode = cell;
                    findIndex = index;
                } else if (Math.abs(cell.value.y - targetCentrePoint.y) < Math.abs(findNode.value.y - targetCentrePoint.y)) {
                    findNode = cell;
                    findIndex = index;
                }
            }

            if (findNode.isEmpty()) {
                return info;
            }
            if (findNode.value.y > targetCentrePoint.y) {
                info.index = findIndex;
                info.endPoint = new CGPoint(findNode.value.x + findNode.value.width,
                    findNode.value.y - this.spaceV - this.height / 2);
            } else {
                info.index = findIndex + 1;
                info.endPoint = new CGPoint(findNode.value.x + findNode.value.width,
                    findNode.value.y + findNode.value.height + this.spaceV + this.height / 2);
            }
            info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_RIGHT || 
            globalLayout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
            let findNode = new LineMindTypeNode()
            let findIndex = -1;
            if (node.children.length == 0) {
                info.node = node;
                info.index = 0;
                info.startPoint = nodeCentrePoint;
                info.endPoint = new CGPoint(node.value.x + node.value.width + this.spaceH, nodeCentrePoint.y);
                info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                    this.width, this.height);
                return info;
            }
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index];
                if (cell.value.x < node.value.x) {
                    continue;
                }
                if (findNode.isEmpty()) {
                    findNode = cell;
                    findIndex = index;
                } else if (Math.abs(cell.value.y - targetCentrePoint.y) < Math.abs(findNode.value.y - targetCentrePoint.y)) {
                    findNode = cell;
                    findIndex = index;
                }
            }

            if (findNode.isEmpty()) {
                return info;
            }
            if (findNode.value.y > targetCentrePoint.y) {
                info.index = findIndex;
                info.endPoint = new CGPoint(findNode.value.x,
                    findNode.value.y - this.spaceV - this.height / 2);
            } else {
                info.index = findIndex + 1;
                info.endPoint = new CGPoint(findNode.value.x,
                    findNode.value.y + findNode.value.height + this.spaceV + this.height / 2);
            }
            info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_BOTTOM ||
            globalLayout == NodeLayoutType.LAYOUT_TOP) {
            let findNode = new LineMindTypeNode()
            let findIndex = -1;
            if (node.children.length == 0) {
                info.node = node;
                info.index = 0;
                info.startPoint = nodeCentrePoint;
                info.endPoint = new CGPoint(nodeCentrePoint.x, node.value.y + node.value.height + this.spaceH);
                info.rect = new CGRect(info.endPoint.x - this.width / 2, info.endPoint.y,
                    this.width, this.height);
                return info;
            }
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index];
                if (cell.value.y < node.value.y) {
                    continue;
                }
                if (findNode.isEmpty()) {
                    findNode = cell;
                    findIndex = index;
                } else if (Math.abs(cell.value.x - targetCentrePoint.x) < Math.abs(findNode.value.x - targetCentrePoint.x)) {
                    findNode = cell;
                    findIndex = index;
                }
            }

            if (findNode.isEmpty()) {
                return info;
            }
            if (findNode.value.x > targetCentrePoint.x) {
                info.index = findIndex;
                info.endPoint = new CGPoint(findNode.value.x - this.width / 2 - this.spaceV,
                    findNode.value.y);
            } else {
                info.index = findIndex + 1;
                info.endPoint = new CGPoint(findNode.value.x + findNode.value.width + this.spaceV + this.width / 2,
                    findNode.value.y);
            }
            info.rect = new CGRect(info.endPoint.x - this.width / 2, info.endPoint.y,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            let findNode = new LineMindTypeNode()
            let findIndex = -1;
            if (node.children.length == 0) {
                info.node = node;
                info.index = 0;
                info.startPoint = nodeCentrePoint;
                info.endPoint = new CGPoint(nodeCentrePoint.x - this.spaceH, node.value.y + node.value.height + this.spaceV);
                info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y,
                    this.width, this.height);
                return info;
            }
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index];
                if (cell.value.x + cell.value.width > node.value.x + node.value.width) {
                    continue;
                }
                if (findNode.isEmpty()) {
                    findNode = cell;
                    findIndex = index;
                } else if (Math.abs(cell.value.y - targetCentrePoint.y) < Math.abs(findNode.value.y - targetCentrePoint.y)) {
                    findNode = cell;
                    findIndex = index;
                }
            }

            if (findNode.isEmpty()) {
                return info;
            }
            if (findNode.value.y > targetCentrePoint.y) {
                info.index = findIndex;
                info.endPoint = new CGPoint(findNode.value.x + findNode.value.width,
                    findNode.value.y - this.height / 2 - this.spaceV);
            } else {
                info.index = findIndex + 1;
                info.endPoint = new CGPoint(findNode.value.x + findNode.value.width,
                    findNode.value.y + findNode.value.height + this.height / 2 + this.spaceV);
            }
            info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT) {
            let findNode = new LineMindTypeNode()
            let findIndex = -1;
            if (node.children.length == 0) {
                info.node = node;
                info.index = 0;
                info.startPoint = nodeCentrePoint;
                info.endPoint = new CGPoint(nodeCentrePoint.x + this.spaceH, node.value.y + node.value.height + this.height / 2 + this.spaceV);
                info.rect = new CGRect(info.endPoint.x, info.endPoint.y - this.height / 2,
                    this.width, this.height);
                return info;
            }
            for (let index = 0; index < node.children.length; index++) {
                let cell = node.children[index];
                if (cell.value.x < node.value.x) {
                    continue;
                }
                if (findNode.isEmpty()) {
                    findNode = cell;
                    findIndex = index;
                } else if (Math.abs(cell.value.y - targetCentrePoint.y) < Math.abs(findNode.value.y - targetCentrePoint.y)) {
                    findNode = cell;
                    findIndex = index;
                }
            }

            if (findNode.isEmpty()) {
                return info;
            }
            if (findNode.value.y > targetCentrePoint.y) {
                info.index = findIndex;
                info.endPoint = new CGPoint(findNode.value.x,
                    findNode.value.y - this.height / 2 - this.spaceV);
            } else {
                info.index = findIndex + 1;
                info.endPoint = new CGPoint(findNode.value.x,
                    findNode.value.y + findNode.value.height + this.height / 2 + this.spaceV);
            }
            info.rect = new CGRect(info.endPoint.x - this.width, info.endPoint.y - this.height / 2,
                this.width, this.height);
        } else if (globalLayout == NodeLayoutType.LAYOUT_FORM || globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {

        }
        return info;
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }

        for (let index = 0; index < this.freeNodes.length; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        // if (node.isEmpty() && nodeConnectLineDataDict.keySet().contains(id)) {
        if (node.isEmpty() && (this.nodeConnectLineDataDict.containsKey(id))) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    getNodeIndex(node) {
        let parentNode = this.getNodeById(node.value.parentNodeId);
        for (let index = 0; index < parentNode.children.length; index++) {
            let cell = parentNode.children[index];
            if (cell.value.id == node.value.id) {
                return index;
            }
        }
        return -1;
    }

    isChildNode(node, parentNode) {
        let reslut = node.value.parentNodeId == parentNode.value.id;
        if (reslut) {
            return reslut;
        }

        if (node.value.type == MindElementType.CONTENT_GENERALIZATION &&
            node.value.generalizationContent != null) {
            if (node.value.generalizationContent.targetIds.length > 0) {
                for (let index = 0; index < node.value.generalizationContent.targetIds.length; index++) {
                    let id = node.value.generalizationContent.targetIds[index]
                    if (id == parentNode.value.id || parentNode.isChildNodeById(id)) {
                        return true
                    }
                }
            }
        }
        if (!this.mainMindNodeUnit.generalizationMindElementDataDict.isEmpty() ) {
            let generalizationMindElementDataDictKey = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()
            for (let index = 0; index < generalizationMindElementDataDictKey.length; index++) {
                let itemNode = this.mainMindNodeUnit.generalizationMindElementDataDict.get(generalizationMindElementDataDictKey[index]);
                if (itemNode.value.generalizationContent == null || itemNode.value.generalizationContent.targetIds.length == 0) {
                    continue
                }
                for (let j = 0; j < itemNode.value.generalizationContent.targetIds.length; j++) {
                    let id = itemNode.value.generalizationContent.targetIds[j]
                    if ((id == parentNode.value.id || parentNode.isChildNodeById(id)) && itemNode.isChildNodeById(node.value.id)) {
                        return true
                    }
                }
            }
        }

        for (let freeIndex = 0; freeIndex < this.freeNodes.length; freeIndex++) {
            let freeNode = this.freeNodes[freeIndex]
            let generalizationMindElementDataDictKey = freeNode.generalizationMindElementDataDict.keys()
            for (let index = 0; index < generalizationMindElementDataDictKey.length; index++) {
                let itemNode = freeNode.generalizationMindElementDataDict.get(generalizationMindElementDataDictKey[index]);                
                if (itemNode.value.generalizationContent == null || itemNode.value.generalizationContent.targetIds.length == 0) {
                    continue
                }
                for (let j = 0; j < itemNode.value.generalizationContent.targetIds.length; j++) {
                    let id = itemNode.value.generalizationContent.targetIds[j]
                    if ((id == parentNode.value.id || parentNode.isChildNodeById(id)) && itemNode.isChildNodeById(node.value.id)) {
                        return true
                    }
                }
            }
        }

        for (let index = 0; index < parentNode.children.length; index++) {
            let cell = parentNode.children[index]
            reslut = this.isChildNode(node, cell);
            if (reslut) {
                return true;
            }
        }
        return false;
    }

    isBrotherNode(node1, node2) {
        let parentNode = this.getNodeById(node1.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return false;
        }
        return node1.value.parentNodeId == node2.value.parentNodeId;
    }

}


export default MoveMindElementDataByPoint