<template>
  <!-- 快速样式 -->
  <div id="quick-style-box" class="ComplieHeaderImg" ref="quickStyleBox">
    <div class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataList"
          :key="index"
          :class=" 'complie-com-box-'+index"
          @click="getCorrelationLineType(item)">
          <img :src="item.bgUrl" v-show="item.bgUrl.length > 0"
          style="position:absolute object-fit:fill" width="138px" height="32px"/>
          <div class="complie-left-icon" style="position:absolute">
            {{ getString(item.name) }}
          </div>
        </div>
      <!-- </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import QuickStyleType from "../../../viewmodel/datatype/QuickStyleType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
export default {
  name: "ComplieChildMenuNodeQuickStyle",
  data() {
    return {
      imgDataList: [
        {
          name: strings.Mind_Extremely_Important,
          styleType: QuickStyleType.EXTREMELY_IMPORTANT,
          bgUrl:"",
        },
        {
          name: strings.Mind_Important,
          styleType: QuickStyleType.IMPORTANT,
          bgUrl:"",
        },
        {
          name: strings.Mind_Commonly,
          styleType: QuickStyleType.COMMONLY,
          bgUrl:"",
        },
        {
          name: strings.Mind_Mindmap_Deleted_Forever,
          styleType: QuickStyleType.DELETE,
          bgUrl:"",
        },
        {
          name: strings.Mind_Ordinary,
          styleType: QuickStyleType.ORDINARY,
          bgUrl:"",
        },
        {
          name: strings.Mind_Extremely_DrawOblique,
          styleType: QuickStyleType.YELLOW_BG_FULL_DrawOblique,
          bgUrl:require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_extremely_drawoblique_bg.png"),
        },
        {
          name: strings.Mind_Extremely_Gradient,
          styleType: QuickStyleType.BLUE_BG_FULL_Gradient,
          bgUrl:"",
        },
        {
          name: strings.Mind_Extremely_Shadow,
          styleType: QuickStyleType.SHADOW_GREEN,
          bgUrl:"",
        },
      ],
      imgDataAccout: 0,
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
    };
  },
  created() {},
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["correlationLineStyleLayout"]),
    getString(i) {
      return getString(i);
    },
    getCorrelationLineType(item) {
      EditMindmapVM.setNodeFastStyle(item.styleType); 
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.quickStyleBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.color = "#666666";
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.ComplieHeaderImg {
  width: 198px;
  height: 322px;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  z-index: 9998;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.complie-header-imgbox {
  margin: 7px 25px;
}
.complie-com-box {
  width: 138px;
  height: 38px;
  border-bottom: 1px dashed var(--exportMenuDashedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}
.complie-com-box-0 {
  background: red;
  color: #ffffff;
}
.complie-com-box-1 {
  background: #FD833C;
  color: #ffffff;
}
.complie-com-box-2 {
  background: #FEFF00;
  color: #666666;

}
.complie-com-box-3 {
  text-decoration: line-through;
}
.complie-com-box-5 {
  background: #ffffff;
  color: #ffffff;
  text-shadow: 2px 2px #6f3e02;
}
.complie-com-box-6 {
  background: linear-gradient(#70abf3,#078bf7);
  color: #ffffff;
  text-shadow: 2px 2px #3b2dc0;
}
.complie-com-box-7 {
  background: #45D977;
  color: #ffffff;
  text-shadow: 2px 2px #178903;
}
/* .complie-com-box-4 {
} */
.complie-com-box-0:hover{
  background-color: #ce0000;
}
.complie-com-box-1:hover{
  background-color: #fd6e1c;
}
.complie-com-box-2:hover{
  background-color: #e4e400;
}
.complie-com-box-3:hover,.complie-com-box-4:hover{
  background-color: var(--rightClickMenuBg);
}
.complie-com-box-5:hover {
  background: #cbbead;
  color: #ffffff;
  text-shadow: 2px 2px #6f3e02;
}
.complie-com-box-6:hover {
  background: linear-gradient(#60a4f8,#4a5cfb);
  color: #ffffff;
  text-shadow: 2px 2px #3b2dc0;
}
.complie-com-box-7:hover {
  background: #39b662;
  color: #ffffff;
  text-shadow: 2px 2px #178903;
}
.complie-com-box-last {
  border-bottom: transparent;
}
.complie-left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}
</style>