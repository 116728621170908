var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CommonBgColor"},[_c('header',{staticClass:"color-mode-title"},[(_vm.colorGRB)?[_vm._v(" RGB ")]:[_vm._v(" CMYK ")]],2),_c('div',{staticClass:"color-content-box"},[(_vm.colorGRB)?[_c('div',{ref:"styleColor",staticClass:"no-color",on:{"click":_vm.clickBusColor}},[_c('img',{attrs:{"src":require("../../../../assets/img/color/nocolor.png"),"alt":""}})]),_vm._l((_vm.noNeedMoneyColors),function(item,index){return _c('div',{key:index,staticClass:"color-content-header"},[_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small color-content-samll-header",style:({
            'background-color': 'rgb(' + item.headerColor.headerColor + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(
              index,
              item.headerColor.headerColorId,
              item.headerColor.isMember
            )},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.headerColor.headerColorId,
              item.headerColor.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)]),_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({
            'background-color':
              'rgb(' + item.smallColor.smallColorOne.smallColorOne + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(
              index,
              item.smallColor.smallColorOne.smallColorId,
              item.isMember
            )},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.smallColor.smallColorOne.smallColorId,
              item.smallColor.smallColorOne.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)]),_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({
            'background-color':
              'rgb(' + item.smallColor.smallColorTwo.smallColorTwo + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(index, item.smallColor.smallColorTwo.smallColorId)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.smallColor.smallColorTwo.smallColorId,
              item.smallColor.smallColorTwo.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)]),_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({
            'background-color':
              'rgb(' + item.smallColor.smallColorThree.smallColorThree + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(index, item.smallColor.smallColorThree.smallColorId)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.smallColor.smallColorThree.smallColorId,
              item.smallColor.smallColorTwo.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)]),_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({
            'background-color':
              'rgb(' + item.smallColor.smallColorFour.smallColorFour + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(index, item.smallColor.smallColorFour.smallColorId)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.smallColor.smallColorFour.smallColorId,
              item.smallColor.smallColorTwo.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)]),_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({
            'background-color':
              'rgb(' + item.smallColor.smallColorFive.smallColorFive + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(index, item.smallColor.smallColorFive.smallColorId)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.smallColor.smallColorFive.smallColorId,
              item.smallColor.smallColorTwo.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)]),_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({
            'background-color':
              'rgb(' + item.smallColor.smallColorSix.smallColorSix + ')',
          }),on:{"mouseover":function($event){return _vm.mouseover(index, item.smallColor.smallColorSix.smallColorId)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(
              index,
              item.smallColor.smallColorSix.smallColorId,
              item.smallColor.smallColorTwo.isMember
            )}}},[_c('div',{staticClass:"color-need-money"},[(item.headerColor.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.headerColor.needMoney}}):_vm._e()],1)])])})]:[_c('section',{staticClass:"cmyk-color-box"},_vm._l((_vm.cmykColors),function(item,index){return _c('div',{key:index,staticClass:"cmyk-color-item"},_vm._l((item.colorsValue),function(sitem,sindex){return _c('section',{key:sindex,staticClass:"cmyk-show-color",style:({
              'background-color': 'rgb' + sitem.rgbValue,
            }),on:{"click":function($event){return _vm.setColor('rgb' + sitem.rgbValue)}}})}),0)}),0)],_c('div',{staticClass:"color-content-max-titel flex-between",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"standard-colors"},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Standard_Colors))+" ")]),_c('section',{staticClass:"color-mode"},[_c('a-checkbox',{attrs:{"checked":_vm.colorGRB},on:{"change":_vm.onChangeColorMode}},[_c('span',{staticClass:"color-style"},[_vm._v(" GRB ")])]),_c('a-checkbox',{attrs:{"checked":_vm.colorCMYK},on:{"change":_vm.onSwitchColorMode}},[_c('span',{staticClass:"color-style"},[_vm._v(" CMYK ")])])],1)]),_vm._l((_vm.noNeedMoneyColorNorm),function(item,index){return _c('div',{key:item.ColorNormColorId,staticClass:"color-content-max-box color"},[_c('div',{ref:"styleColor",refInFor:true,staticClass:"color-content-small",style:({ 'background-color': 'rgb(' + item.ColorNormColor + ')' }),on:{"mouseover":function($event){return _vm.mouseover(index, item.ColorNormColorId)},"mouseleave":_vm.mouseleave,"click":function($event){return _vm.clickBusColor(index, item.ColorNormColorId, item.isMember)}}},[_c('div',{staticClass:"color-need-money"},[(item.needMoney != '')?_c('a-icon',{staticClass:"need-lock",attrs:{"type":item.needMoney}}):_vm._e()],1)])])}),_c('div',{staticClass:"color-content-max-titel",on:{"click":function($event){$event.stopPropagation();return _vm.moreColor.apply(null, arguments)}}},[_c('span',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.More_Colors))+" ")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }